import { Box } from '@material-ui/core'
import { Skeleton } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    downloadRoiMetrics,
    downloadRoiPdf,
    downloadUtmLinks,
    getAllCampaignProfiles,
    getCampaignRoi,
    getCreatorAccounts
} from '../../api/CampaignTrackingAPI/CampaignTrackingAPI'
import {
    ContextualMenu,
    CreatorAccountGrid,
    EditCreatorDrawer,
    ExportLoadingModal,
    FilterMenu,
    Loader,
    PerformanceMetric,
    PrimaryButton,
    TabSwitch,
    Typography,
    UnlockFullAccessPopUp
} from '../../components'
import {
    allCreatorsAccounts,
    allPlatforms,
    averageOrder,
    averageOrderTooltip,
    CAMPAIGN_PROFILES_CREATED_VIA,
    costLPV,
    costLPVTooltip,
    CREATE_UTM_LINKS_EDIT_FLOW,
    creatorAccounts,
    ctr,
    ctrTooltip,
    DOWNLOAD_UTM_LINKS,
    EDIT_CREATOR_ACCOUNTS,
    EDIT_CREATOR_COSTS,
    landingPageViews,
    landingPageViewsTooltip,
    MANAGE_UTM,
    performance,
    performanceTabValues,
    SIDE_DRAWER_TITLE,
    totalAddToCarts,
    totalAddToCartsTooltip,
    totalCheckouts,
    totalCheckoutsTooltip,
    totalCreatorCost,
    totalCreatorCostTooltip,
    totalLinkClicks,
    totalLinkClickTooltip,
    totalOrderPlace,
    totalOrderPlaceTooltip,
    totalROI,
    totalROITooltip,
    totalSales,
    totalSalesTooltip,
    unlockAccessPopupDetails,
    userAccounts
} from '../../constants/campaignTrackingConstants'
import {
    DATE_TIME_FORMAT_STATUS,
    EXPORT_REPORT,
    exportCSVReport,
    exportingCsv,
    exportingPdf,
    exportPDFReport
} from '../../constants/constants'
import { CampaignTrackingContext, WarehouseContext } from '../../storage/context'
import { formatNumber, getWorkPlatformIdByPlatformName } from '../../utils/profileHelpers'
import {
    convertTimeToLocale,
    downloadFile,
    getDeletableProfilePromises,
    getNonDeletedCreators,
    isCampaignEnded,
    isEmpty,
    setAddedProfiles,
    workPlatformsDropdownOptions
} from '../../utils/util'
import "./MeasureRoiPage.scss";
import { SET_CAMPAIGN_FORM, SET_TRACKED_CREATORS } from "../../storage/reducers/types";
import CampaignSyncStatus from "../../components/CampaignSyncStatus/CampaignSyncStatus";
import { Colors } from '../../styles/colors'
import { trackEvent, TRACKING_CAMPAIGN_CSV_EXPORTED, TRACKING_CAMPAIGN_PDF_EXPORTED } from "../../analytics";
import moment from 'moment-timezone';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const performanceTabs = [
    {
        label: performanceTabValues.roi,
    },
    {
        label: performanceTabValues.salesFunnel,
    },

];


const roiMetrics = [
    {
        name: totalSales,
        path: "total_sales",
        variant: "infoMetricIcon",
        tooltip: totalSalesTooltip,
        isPercentage: false,
        isPrice: true,
        metricIcon: <i className="ri-money-dollar-circle-line metric-icon"></i>,
    },
    {
        name: totalCreatorCost,
        path: "total_creator_cost",
        variant: "infoMetricIcon",
        tooltip: totalCreatorCostTooltip,
        isPercentage: false,
        isPrice: true,
        metricIcon: <i className="ri-group-line metric-icon"></i>
    },
    {
        name: totalROI,
        path: "return_on_investment",
        variant: "infoMetricIcon",
        tooltip: totalROITooltip,
        isPercentage: true,
        isPrice: false,
        metricIcon: <i className="ri-exchange-dollar-line metric-icon"></i>
    },
    {
        name: ctr,
        path: "click_through_rate",
        variant: "infoIcon",
        tooltip: ctrTooltip,
        isPercentage: true,
        isPrice: false
    },
    {
        name: costLPV,
        path: "cost_per_landing_page_view",
        variant: "infoIcon",
        tooltip: costLPVTooltip,
        isPercentage: false,
        isPrice: true
    },
    {
        name: averageOrder,
        path: "average_order_value",
        variant: "infoIcon",
        tooltip: averageOrderTooltip,
        isPercentage: false,
        isPrice: true
    },
];

const salesFunnelMetrics = [
    {
        name: totalLinkClicks,
        path: "total_link_clicks",
        variant: "infoIcon",
        tooltip: totalLinkClickTooltip,
    },
    {
        name: landingPageViews,
        path: "total_landing_page_views",
        variant: "infoIcon",
        tooltip: landingPageViewsTooltip,
    },
    {
        name: totalAddToCarts,
        path: "total_add_to_carts",
        variant: "infoIcon",
        tooltip: totalAddToCartsTooltip,
    },
    {
        name: totalCheckouts,
        path: "total_checkouts",
        variant: "infoIcon",
        tooltip: totalCheckoutsTooltip,
    },
    {
        name: totalOrderPlace,
        path: "total_orders_placed",
        variant: "infoIcon",
        tooltip: totalOrderPlaceTooltip,
    },
]

export default function MeasureRoiPage({ campaignByIdResponse, campaignId, setProfileCount }) {
    const [currPerformanceTab, setCurrPerformanceTab] = useState(performanceTabValues.roi)
    const [selectedPlatform, setSelectedPlatform] = useState({
        [allPlatforms]: true,
        [workPlatformsDropdownOptions[0].value]: true,
        [workPlatformsDropdownOptions[1].value]: true,
        [workPlatformsDropdownOptions[2].value]: true,
    });
    const [selectedAccounts, setSelectedAccounts] = useState({})
    const navigate = useNavigate()
    const [filterBody, setFilterBody] = useState({});
    const { warehouse } = useContext(WarehouseContext);
    // Todo : set state needs to update while api integration
    const [creatorAccountsMenu, setCreatorAccountsMenu] = useState([])
    const [allCreatorsClicked, setAllCreatorsClicked] = useState(false)
    const [performanceMetrics, setPerformanceMetrics] = useState(null);
    const [isEditMenuOpen, setEditMenuOpen] = useState(false);

    const { campaignTrackingWarehouse, dispatchCampaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const { trackedCreators } = campaignTrackingWarehouse;
    const [filteredCreators, setFilteredCreators] = useState([]);
    const [isExportLoadingModalVisible, setExportLoadingModalVisible] = useState(false);
    const [isDownloadUtmLinksVisible, setIsDownloadUtmLinkVisible] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [creatorSortModel, setCreatorSortModel] = useState([{ field: "total_sales", sort: "desc" }]);
    const [modalDetails, setModalDetails] = useState({
        isModalVisible: false,
        modalInfo: "",
    });
    const [exportModalState, setExportModalState] = useState({ key: '', value: '' });

    const exportCsvState = {
        key: 'CSV',
        value: exportingCsv,
    };
    const exportPdfState = {
        key: 'PDF',
        value: exportingPdf,
    };

    const exportReportMenu = [{
        text: exportPDFReport,
        onClick: () => onExportClick(exportPdfState),
    }, {
        text: exportCSVReport,
        onClick: () => onExportClick(exportCsvState),
    }];

    function onExportClick(modalState) {
        if (campaignByIdResponse?.is_trial) {
            setModalDetails({
                ...modalDetails,
                isModalVisible: true,
                modalInfo: unlockAccessPopupDetails.exportCampaign,
            });
        } else {
            setExportModalState(modalState);
            setExportLoadingModalVisible(true);
        }
    }

    function onSearch(searchText) {
        if (isEmpty(searchText)) {
            setFilteredCreators(trackedCreators?.filter(getNonDeletedCreators));
            return;
        }
        setFilteredCreators(trackedCreators?.filter((tc) => tc["platform_username"].includes(searchText)));
    }

    useEffect(onSearch, [trackedCreators]);

    useEffect(() => {
        if (!isEditMenuOpen) {
            getDeletableProfilePromises(warehouse, campaignId, trackedCreators).then((r) => {
                if (r && r.toString() === '204') {
                    dispatchCampaignTrackingWarehouse({
                        type: SET_TRACKED_CREATORS,
                        payload: trackedCreators?.filter(getNonDeletedCreators),
                    });
                }
            }).catch((e) => {
                console.error(`Could not delete creators`, e);
            });
        }
    }, [isEditMenuOpen]);

    useEffect(() => {
        if (!isExportLoadingModalVisible) {
            return;
        }
        const { profile_ids: campaign_profile_ids, work_platform_ids } = filterBody;
        const { field: sort_by, sort } = creatorSortModel[0];
        if (exportModalState.key === 'CSV') {
            downloadRoiMetrics({
                warehouse,
                campaignId,
                campaign_profile_ids,
                work_platform_ids,
                sort_desc: String(sort).toUpperCase() === 'DESC',
                sort_by,
            })
                .then((response) => {
                    if (!response) {
                        throw new Error('Could not download UTM CSV');
                    }
                    downloadFile(response, 'measure-roi-report.csv');
                    trackEvent(TRACKING_CAMPAIGN_CSV_EXPORTED, {
                        name_of_campaign: campaignByIdResponse?.name,
                        last_updated_at: convertTimeToLocale(campaignByIdResponse?.synced_at, DATE_TIME_FORMAT_STATUS),
                        last_refreshed_time: convertTimeToLocale(campaignByIdResponse?.synced_at, DATE_TIME_FORMAT_STATUS)
                    });
                })
                .catch(console.error)
                .finally(() => setExportLoadingModalVisible(false));
        } else if (exportModalState.key === 'PDF') {
            downloadRoiPdf({
                warehouse,
                campaignId,
                campaign_profile_ids,
                work_platform_ids,
                sort_desc: String(sort).toUpperCase() === 'DESC',
                sort_by,
                search_string: searchText,
                end_user_timezone: moment.tz.guess(true),
            }).then((response) => {
                if (!response) {
                    throw new Error('Could not download campaign ROI PDF');
                }
                downloadFile(response, `campaign-roi-pdf-report-${campaignId}.pdf`, 'application/pdf');
                trackEvent(TRACKING_CAMPAIGN_PDF_EXPORTED, {
                    name_of_campaign: campaignByIdResponse?.name,
                    last_updated_at: convertTimeToLocale(campaignByIdResponse?.synced_at, DATE_TIME_FORMAT_STATUS),
                    last_refreshed_time: convertTimeToLocale(campaignByIdResponse?.synced_at, DATE_TIME_FORMAT_STATUS)
                });
            })
                .catch(console.error)
                .finally(() => setExportLoadingModalVisible(false));
        }

    }, [isExportLoadingModalVisible]);

    useEffect(() => {
        if (isDownloadUtmLinksVisible) {
            downloadUtmLinks({ warehouse, campaignId })
                .then((response) => {
                    if (!response) {
                        throw new Error('Could not download UTM CSV');
                    }
                    downloadFile(response, 'utm-links.csv');
                })
                .catch(console.error)
                .finally(() => setIsDownloadUtmLinkVisible(false));
        }
    }, [isDownloadUtmLinksVisible]);

    const manageCreatorMenu = [
        !isCampaignEnded(campaignByIdResponse) && {
            icon: <i className="ri-group-line"></i>,
            text: EDIT_CREATOR_ACCOUNTS,
            onClick: () => setEditMenuOpen(true),
        },
        {
            icon: <i className="ri-money-dollar-circle-line"></i>,
            text: EDIT_CREATOR_COSTS,
            onClick: () => navigate(`/creators/campaigns/manage-creator-cost/${campaignId}`),
        },
    ];

    useEffect(() => {
        if (!campaignId) {
            return;
        }
        getCampaignRoi({ warehouse, campaignId }).then(res => {
            setPerformanceMetrics(res);
        }).catch((e) => {
            console.error(`Could not display ROI metrics`, e);
            throw e;
        });

        if (isEmpty(trackedCreators)) {
            getAllCampaignProfiles({
                warehouse,
                limit: 100,
                campaignId,
                created_via: CAMPAIGN_PROFILES_CREATED_VIA.DIRECT
            }).then((res) => {
                const { data } = res;
                if (!isEmpty(data)) {
                    data.forEach(setAddedProfiles);
                    dispatchCampaignTrackingWarehouse({
                        type: SET_TRACKED_CREATORS,
                        payload: data,
                    });
                }
            });
        }
    }, [campaignId]);

    const handleAccountClick = (accountValue) => {
        setSelectedAccounts((prevState) => ({
            ...prevState,
            [accountValue]: !prevState[accountValue],
            [allCreatorsAccounts]: false,
        }));
    };

    // check all platform are selected
    const allPlatformsSelected = () => {
        for (const platform in selectedPlatform) {
            if (platform !== allPlatforms && !selectedPlatform[platform]) {
                return false;
            }
        }
        return true;
    };

    const prevAllPlatformsSelected = useRef(allPlatformsSelected());

    useEffect(() => {
        // Update "All Platforms" selection based on the individual platforms
        const isAllPlatformSelected = allPlatformsSelected();
        if (isAllPlatformSelected !== prevAllPlatformsSelected.current) {
            setSelectedPlatform((prevState) => ({
                ...prevState,
                [allPlatforms]: isAllPlatformSelected,
            }));
        }

        prevAllPlatformsSelected.current = isAllPlatformSelected;

    }, [selectedPlatform]);


    // Todo - after demo
    // const allProfilesSelected = () => {
    //   for (const account in selectedAccounts) {
    //     if (account !== allCreatorsAccounts && !selectedAccounts[account]) {
    //       return false;
    //     }
    //   }
    //   return true;
    // };

    // const prevAllProfilesSelected = useRef(allProfilesSelected());

    //     useEffect(() => {
    //   // Update "All Platforms" selection based on the individual platforms
    //   const isAllProfilesSelected = allProfilesSelected();
    //   if (isAllProfilesSelected !== prevAllProfilesSelected.current) {
    //     setSelectedPlatform((prevState) => ({
    //       ...prevState,
    //       [allCreatorsAccounts]: isAllProfilesSelected,
    //     }));
    //   }

    //   prevAllProfilesSelected.current = isAllProfilesSelected;

    // }, [selectedAccounts]);

    useEffect(() => {
        if (isEmpty(creatorAccountsMenu)) {
            return;
        }
        const updatedCreatorAccountsMenu = [...creatorAccountsMenu];
        updatedCreatorAccountsMenu.forEach((account) => {
            if (account.work_platform_id) account.checked = selectedAccounts[account.id];
        });
        setCreatorAccountsMenu(updatedCreatorAccountsMenu);
    }, [selectedAccounts])


    useEffect(() => {
        if (!allCreatorsClicked) return
        const allChecked = !selectedAccounts[allCreatorsAccounts];
        const updatedSelection = { ...selectedAccounts };
        for (const account in selectedAccounts) {
            updatedSelection[account] = allChecked;
        }
        setSelectedAccounts(updatedSelection);

        const updatedCreatorAccountsMenu = [...creatorAccountsMenu]; // Create a copy of the array

        if (updatedCreatorAccountsMenu.length > 0) {
            updatedCreatorAccountsMenu[0].checked = allChecked; // Update the checked value of the first object
        }

        setCreatorAccountsMenu(updatedCreatorAccountsMenu);
        setAllCreatorsClicked(false)
    }, [allCreatorsClicked])

    async function fetchData(search_string) {
        try {
            const res = await getCreatorAccounts({
                campaignId,
                warehouse,
                limit: 100,
                search_string
            });

            const { data } = res;

            const creatorAccountOptions = [];
            const selectedAccountsState = {
                [allCreatorsAccounts]: true
            };

            const allCreatorAccount = {
                icon: <></>,
                text: allCreatorsAccounts,
                checked: selectedAccounts[allCreatorsAccounts] ? selectedAccounts[allCreatorsAccounts] : true,
                isVisible: true,
                onClick: () => {
                    setAllCreatorsClicked(true)
                },
            }

            data.forEach((obj) => {
                const {
                    thumbnail_url,
                    platform_username,
                    fullname: name,
                    is_verified,
                    work_platform_id,
                    id,
                } = obj;

                const newObj = {
                    thumbnail_url,
                    username: platform_username,
                    name,
                    is_verified,
                    work_platform_id,
                    id,
                    text: id,
                    checked: !!!selectedAccounts[id],
                    onClick: () => {
                        handleAccountClick(id)
                    }
                };

                creatorAccountOptions.push(newObj);
                selectedAccountsState[id] = true;
            });
            setCreatorAccountsMenu([allCreatorAccount, ...creatorAccountOptions])
            setSelectedAccounts(selectedAccountsState);

            const profiles = await getAllCampaignProfiles({
                warehouse,
                campaignId,
                created_via: CAMPAIGN_PROFILES_CREATED_VIA.DIRECT
            });
            !isEmpty(profiles) && setProfileCount(Number(profiles['total_count']));
            return data;
        } catch (e) {
            // TODO show error screen
            console.error("Error fetching data:", e);
        } finally {
        }
    }

    useEffect(() => {
        fetchData().then(() => {
        });
    }, []);

    function onExportRoiCsvClick() {
        if (campaignByIdResponse?.is_trial) {
            setModalDetails({
                ...modalDetails,
                isModalVisible: true,
                modalInfo: unlockAccessPopupDetails.exportReport,
            });
        } else {
            setExportLoadingModalVisible(true);
        }
    }

    function MeasureRoiHeader() {
        return (
            <div className='measure-roi-header'>
                <div>
                    <div className='sub-section-heading'>
                        {campaignByIdResponse?.store_link_title ?? "-"}
                    </div>
                    <a href={campaignByIdResponse?.store_link} target={"_blank"}>
                        <div className='body-r header-shopify-link-container'>
                            <div className='header-shopify-link'>
                                {campaignByIdResponse?.store_link ?? "-"}
                            </div>
                            {campaignByIdResponse?.store_link &&
                                <i className="ri-arrow-right-up-line header-shopify-link-arrow"></i>}
                        </div>
                    </a>
                </div>
                <div>
                    {(isCampaignEnded(campaignByIdResponse) || campaignByIdResponse?.is_trial) ? null : <PrimaryButton
                        label={<><i className="ri-edit-line header-icon"/> {MANAGE_UTM}</>}
                        className="secondary-button"
                        onClick={() => navigate(`/creators/campaigns/manage-utm-links/${campaignId}`)}
                    />}
                    {isCampaignEnded(campaignByIdResponse) ?
                        <PrimaryButton
                            icon={<i className="body-m ri-download-2-line" style={{ color: Colors.white }}></i>}
                            label={<span className={'body-m'}
                                         style={{ color: Colors.white }}> {DOWNLOAD_UTM_LINKS}</span>}
                            onClick={() => setIsDownloadUtmLinkVisible(true)}
                            className={'download-utm-button'}/>
                        : null}
                </div>
            </div>

        )
    }

    function getSelectedPostType(selectedPostTypes) {
        const postTypeValues = Object.values(selectedPostTypes);
        const selectedCount = postTypeValues.reduce((acc, val) => acc + (val ? 1 : 0), 0);

        if (selectedCount === postTypeValues.length) {
            return "All";
        } else if (selectedCount === 0) {
            return null;
        } else if (selectedCount === 1) {
            const selectedType = Object.keys(selectedPostTypes).find((key) => selectedPostTypes[key]);
            if (selectedPostTypes === selectedAccounts) {
                const foundAccount = creatorAccountsMenu.find(account => account.id === selectedType);

                if (foundAccount) {
                    return foundAccount.username;
                } else {
                    return "-"
                }
            }
            return selectedType;
        } else {
            const firstSelectedType = Object.keys(selectedPostTypes).find((key) => selectedPostTypes[key]);

            if (selectedPostTypes === selectedAccounts) {
                const foundAccount = creatorAccountsMenu.find(account => account.id === firstSelectedType);

                if (foundAccount) {
                    return `${foundAccount.username} + ${selectedCount - 1}`;
                } else {
                    return "-"
                }
            }
            return `${firstSelectedType} + ${selectedCount - 1}`;
            // }
        }
    }


    const handlePlatformClick = (platformValue) => {
        setSelectedPlatform((prevState) => ({
            ...prevState,
            [platformValue]: !prevState[platformValue],
            [allPlatforms]: false,
        }));
    };

    const platformTypeMenu = {
        firstName: "Platforms",
        selectedValueText: getSelectedPostType(selectedPlatform),
        options: [
            {
                icon: <></>,
                text: allPlatforms,
                checked: selectedPlatform[allPlatforms],
                isVisible: true,
                onClick: () => {
                    const allChecked = !selectedPlatform[allPlatforms];
                    const updatedSelection = { ...selectedPlatform };
                    for (const platform in selectedPlatform) {
                        updatedSelection[platform] = allChecked;
                    }
                    setSelectedPlatform(updatedSelection);
                },
            },
            {
                icon: workPlatformsDropdownOptions[0].icon,
                text: workPlatformsDropdownOptions[0].value,
                isVisible: true,
                checked: selectedPlatform[workPlatformsDropdownOptions[0].value],
                onClick: () => handlePlatformClick(workPlatformsDropdownOptions[0].value),
            },
            {
                icon: workPlatformsDropdownOptions[1].icon,
                text: workPlatformsDropdownOptions[1].value,
                isVisible: true,
                checked: selectedPlatform[workPlatformsDropdownOptions[1].value],
                onClick: () => handlePlatformClick(workPlatformsDropdownOptions[1].value),
            },
            {
                icon: workPlatformsDropdownOptions[2].icon,
                text: workPlatformsDropdownOptions[2].value,
                isVisible: true,
                checked: selectedPlatform[workPlatformsDropdownOptions[2].value],
                onClick: () => handlePlatformClick(workPlatformsDropdownOptions[2].value),
            },
        ],
    };

    const handleFilterChange = () => {
        const payload = {};
        const selectedPlatforms = Object.keys(selectedPlatform).filter((platform) => platform !== allPlatforms && selectedPlatform[platform]);
        if (selectedPlatforms.length > 0) {
            const workPlatformIds = selectedPlatforms.map((selectedPlatform) => getWorkPlatformIdByPlatformName(selectedPlatform));
            payload.work_platform_ids = workPlatformIds;
        }
        const selectedCreatorAccounts = Object.keys(selectedAccounts).filter((account) => account !== allCreatorsAccounts && selectedAccounts[account]);
        if (selectedCreatorAccounts.length > 0) {
            // const profile_ids = selectedCreatorAccounts.map((selectedAccount) => selectedAccount);

            payload.profile_ids = selectedCreatorAccounts;
        }
        setFilterBody(payload);


        if (!isEmpty(campaignByIdResponse?.synced_at)) {
            getCampaignRoi({ warehouse, campaignId, filterBody: payload }).then(res => {
                setPerformanceMetrics(res);
            })
        }
    }
    const MeasurePerformanceReport = () => {
        if (!performanceMetrics) return
        return (
            <Box className="campaign-performance-metrics-container">
                <Box className="section-heading">{performance}</Box>
                <Box className="tab-switch-main-div">
                    <TabSwitch
                        handleTabChange={(ev, value) => {
                            setCurrPerformanceTab(value);
                        }}
                        aria-label="icon position tabs example"
                        currentTab={currPerformanceTab}
                        tabs={performanceTabs}
                        height="55px"
                        width="fit-content"
                    />
                </Box>
                <TabPanel index={performanceTabValues.roi} value={currPerformanceTab}
                          sx={{ margin: "0px", padding: "0px" }} className="search-type-tab">
                    <Box className="campaignPerformanceMetrics">
                        {roiMetrics?.map((metric, index) => {
                            const { variant, path, name, tooltip, isPercentage, isPrice, metricIcon } = metric;
                            let value = performanceMetrics[path];
                            if (value === null || value === undefined) {
                                value = "-"
                            } else {
                                if (isPrice) {
                                    value = `$${(formatNumber(performanceMetrics[path]))}`
                                } else if (isPercentage) {
                                    value = `${(performanceMetrics[path]).toFixed(2)}%`
                                }
                            }
                            return (
                                <Box>
                                    <PerformanceMetric metricIcon={metricIcon} key={index} variant={variant}
                                                       content={value} metricName={name} tooltip={tooltip}/>
                                </Box>
                            );
                        })}
                    </Box>
                </TabPanel>
                <TabPanel index={performanceTabValues.salesFunnel} value={currPerformanceTab}
                          sx={{ margin: "0px", padding: "0px" }} className="search-type-tab">
                    <Box className="campaignPerformanceMetrics">
                        {salesFunnelMetrics?.map((metric, index) => {
                            const { variant, path, name, tooltip } = metric;
                            let value = performanceMetrics[path];
                            if (value === null || value === undefined) value = "-"
                            else value = formatNumber(value)

                            return (
                                <Box>
                                    <PerformanceMetric key={index} variant={variant} content={value} metricName={name}
                                                       tooltip={tooltip}/>
                                </Box>
                            );
                        })}
                    </Box>
                </TabPanel>
            </Box>
        );
    };

    function onAddMoreAccounts() {
        if (!campaignByIdResponse) {
            return;
        }
        const { name } = campaignByIdResponse;
        dispatchCampaignTrackingWarehouse({
            type: SET_CAMPAIGN_FORM,
            payload: {
                ...campaignTrackingWarehouse.campaignForm,
                name,
            },
        });
        navigate(`/creators/campaigns/select-creators`, {
            state: {
                navigateDoneRoute: `/creators/campaigns/utm-links/${campaignId}`,
                doneButton: CREATE_UTM_LINKS_EDIT_FLOW,
                storeId: campaignByIdResponse.store_id,
                store_link: campaignByIdResponse.store_link,
            }
        });
    }

    async function updateCreatorAccountFilterFromSearch(searchString) {
        fetchData(searchString).then(() => {
        });
    }

    return (
        <Box className='measure-roi-container'>
            <Box className='campaign-roi-main-container'>
                <CampaignSyncStatus campaignByIdResponse={campaignByIdResponse}/>
                <MeasureRoiHeader/>

                <Box className="filter-menu-container">
                    <Box>
                        <FilterMenu
                            variant={userAccounts}
                            firstItem={creatorAccounts}
                            menuItems={creatorAccountsMenu}
                            selectedValueText={getSelectedPostType(selectedAccounts)}
                            handleFilterChange={handleFilterChange}
                            enableSearch={true}
                            onSearch={(searchString) => updateCreatorAccountFilterFromSearch(searchString)}
                            isMultiSelect
                        />
                        <FilterMenu
                            firstItem={platformTypeMenu.firstName}
                            menuItems={platformTypeMenu.options}
                            selectedValueText={platformTypeMenu.selectedValueText}
                            handleFilterChange={handleFilterChange}
                            isMultiSelect
                        />
                    </Box>
                    <div>
                        <ContextualMenu
                            variant={"text-button"}
                            menuItems={exportReportMenu}
                            buttonLabel={EXPORT_REPORT}
                        />
                        <ExportLoadingModal
                            open={isExportLoadingModalVisible}
                            title={exportModalState.value}
                            onClose={() => setExportLoadingModalVisible(false)}/>
                    </div>
                </Box>
                {(!isEmpty(performanceMetrics) && (!!campaignByIdResponse.synced_at)) ? (
                    <Box>
                        <MeasurePerformanceReport/>
                    </Box>
                ) : (
                    <ContentLoading/>
                )}
                {
                    isEmpty(campaignByIdResponse) || isEmpty(campaignByIdResponse.synced_at) ? null :
                        <CreatorAccountGrid
                            searchModel={{ searchText, setSearchText }}
                            sortModel={{ creatorSortModel, setCreatorSortModel }}
                            campaignId={campaignId}
                            filterBody={filterBody}
                            hasCampaignEnded={isCampaignEnded(campaignByIdResponse)}
                            manageCreatorMenu={manageCreatorMenu}
                            isTrialCampaign={campaignByIdResponse?.is_trial}
                        />
                }
                <EditCreatorDrawer
                    onClose={() => setEditMenuOpen(false)}
                    isOpen={isEditMenuOpen}
                    heading={SIDE_DRAWER_TITLE(filteredCreators?.length)}
                    onAddMoreAccounts={onAddMoreAccounts}
                    onSearch={onSearch}
                    rows={filteredCreators}
                />
            </Box>
            <UnlockFullAccessPopUp
                open={modalDetails.isModalVisible}
                onClose={() => {
                    setModalDetails({ ...modalDetails, isModalVisible: false });
                }}
                header={modalDetails.modalInfo.header}
                description={modalDetails.modalInfo.description}
                actionButtonLabel="Contact us"
                height="264px"
                padding="52px 52px 48px 48px"
                isModal
            />
        </Box>
    )
}

const ContentLoading = () => {
    return (
        <>
            <Box className="campaign-content-loading content-loading">
                <Box className="section-heading">
                    <Loader className={"campaign-loader-container"}/>
                    {"Getting the details"}
                </Box>
                <Box className="body-m">{"We're working behind the scenes. We’ll be done in a few minutes."}</Box>
            </Box>
            <Box className="skeleton-loading">
                <Skeleton variant="text" height={"246px"} width={"100%"} className={"loading-skeleton"}
                          animation={"pulse"}/>
                <hr/>
                <Skeleton variant="text" height={"100%"} width={"100%"} className={"loading-skeleton"}
                          animation={"pulse"}/>
            </Box>
        </>
    );
};
