import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAmberfloUsageUrl } from "../../api/usage/usage";
import { isEmpty } from "../../utils/util";
import { IntermediateLoader } from "../../components";
import { UserContext } from "../../storage/context";
import { trackEvent, USAGE_VIEW_CLICKED } from "../../analytics";

export default function RedirectAmberfloUsagePage({ prodTenantAppId }) {
    const navigate = useNavigate();
    const { userProfile } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [usageInfo, setUsageInfo] = useState({});

    async function getUsageData() {
        try {
            const amberfloUsageUrl = await getAmberfloUsageUrl({
                prodTenantAppId,
            });
            setUsageInfo((prevUsageInfo) => {
                return {
                    ...prevUsageInfo,
                    amberfloUsageUrl,
                }
            });
        } catch (e) {
            navigate('/developers');
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getUsageData().then(() => {
        });
    }, [navigate]);

    useEffect(() => {
        if (!isEmpty(userProfile?.user?.email)) {
            setUsageInfo((prevUsageInfo) => {
                return {
                    ...prevUsageInfo,
                    email: userProfile?.user?.email,
                }
            });
        }
    }, [userProfile]);

    useEffect(() => {
        const { amberfloUsageUrl, email } = usageInfo || {};
        if (!isEmpty(amberfloUsageUrl)) {
            window.location.href = amberfloUsageUrl;
            trackEvent(USAGE_VIEW_CLICKED, {
                tenant_app_id: prodTenantAppId,
                email,
            });
        }
    }, [usageInfo]);

    if (isLoading) {
        return (
            <div className="loader-container" style={{ height: "100vh", width: "100vw" }}>
                <IntermediateLoader/>
            </div>
        );
    }
    return null;
}
