import React, { useReducer, useState } from "react";
// import useForm from "../../hooks/useForm";
// import { useForm, Controller } from "react-hook-form";
import { Form } from "antd";

import { InputField, PrimaryButton, Card, Icons, CheckBox, Layout, Background, Typography, TextLink, PasswordField, Loader } from "../../components";
import { signupApi } from "../../api/index";
import { Helmet } from "react-helmet";

import "./RegistrationPage.scss";
import {
  EMPTY_REQUIRED_FIELD_MESSAGE,
  INVALID_EMAIL_MESSAGE,
  AGREE_TERMS_AND_CONDITIONS,
  INVALID_PASSWORD_MESSAGE,
  PASSWORD_REGEX,
  getPageTitle,
  UPPER_LETTER_CHECK_REGEX,
  LOWER_LETTER_CHECK_REGEX,
  NUMBER_CHECK_REGEX,
  DESCRIPTION,
  COMPANY_NAME_EXCEED_LIMIT_MESSAGE,
  NON_WORK_EMAIL_MESSAGE,
  RECAPTCHA_INCOMPLETE,
} from "../../constants/constants";
import useProfile from "../../hooks/useSetUserProfile";
import { EMAIL_SUPPORT_LINK_CLICKED, NON_WORK_EMAIL_ADDED, REGISTRATION_PAGE_VIEW, SendEvent, SIGN_IN_LINK_CLICK, trackEvent } from "../../analytics";
import { useMemo } from "react";
import useFormSubmitDisable from "../../hooks/useFormSubmitDisable";

import { registerErrorMessage } from "../../constants/errorMessages";
import { useEffect } from "react";
import { useContext } from "react";
import { AnalyticsContext } from "../../storage/context";
import factorsai from "factorsai";
import { isValidEmail } from "../../api/authentication/emailValidationsApi";
import ReCAPTCHA from "react-google-recaptcha";
import { analyticsReducer } from "../../storage/reducers";
import { isEmpty } from "../../utils/util";

// There are the required form fields, based on which register button will be hidden
const requiredFormFields = ["email", "password", "firstName", "companyName", "consent", "recaptcha"];
const passwordRules = [
  {
    validator: (_, value) => {
      if (!value) {
        return Promise.reject(EMPTY_REQUIRED_FIELD_MESSAGE);
      }
      const errors = [];
      if (!UPPER_LETTER_CHECK_REGEX.test(value)) {
        errors.push("one uppercase letter");
      }
      if (!LOWER_LETTER_CHECK_REGEX.test(value)) {
        errors.push("one lowercase letter");
      }
      if (!NUMBER_CHECK_REGEX.test(value)) {
        errors.push("one number");
      }
      if (!(value.length >= 8)) {
        errors.push("at least 8 characters");
      }
      if (errors.length > 0) {
        let errorMessage = `The password should contain at least `;
        if (errors.length === 1) {
          if (errors[0].indexOf("8 characters") !== -1) errorMessage += "8 characters";
          else errorMessage += `${errors[0]} `;
        } else {
          errors.forEach((e, i) => {
            if (i === errors.length - 1) {
              errorMessage = errorMessage.slice(0, -2);
              errorMessage += ` and ${e}`;
            } else {
              errorMessage += `${e}, `;
            }
          });
        }
        return Promise.reject(errorMessage);
      }
      return Promise.resolve();
    },
  },
];

const exceedCharacterLimitHandler = (exceedCharacterLimitMessage) => {
  return {
    validator: (_, value) => {
      if (value && value.length > 100) {
        return Promise.reject(exceedCharacterLimitMessage);
      }
      return Promise.resolve();
    },
  };
};

export default function RegistrationPage() {
  const [formInstance] = Form.useForm();
  const { setUserProfile, verifyEmailIfNot } = useProfile();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    errorMessage: "",
  });
  const disableSubmit = useFormSubmitDisable(formInstance, requiredFormFields);
  const { analytics } = useContext(AnalyticsContext);

  const onSubmitRegistration = async () => {
    setLoading(true);
    const { firstName, companyName, email, password, lastName = "", consent, recaptcha } = formInstance.getFieldsValue();
    try {
      const response = await signupApi.signUpUser({
        firstName,
        companyName,
        email,
        password,
        lastName,
        consent,
        recaptcha,
      });
      setUserProfile(response);
      // Adding FAI tracker
      JSON.parse(process.env.REACT_APP_HUBSPOT_SCRIPT_ENABLED) &&
        factorsai.track("$form_submitted", { email: email, first_name: firstName, last_name: lastName, company_name: companyName });
      formInstance.resetFields();
      verifyEmailIfNot(response, email, password);
    } catch (e) {
      setErrors({
        email: true,
        errorMessage: registerErrorMessage[e.error_code],
      });
      setLoading(false);
    }
  };

  const sendUserAction = () => {
    trackEvent(SIGN_IN_LINK_CLICK);
  };

  const resetError = (name) => {
    setErrors({ ...errors, [name]: false });
  };

  useEffect(() => {
    if (!isEmpty(analytics)) trackEvent(REGISTRATION_PAGE_VIEW);
  }, [analytics]);
  return (
    <Background
      className="registration-page"
      // leftLeaves
      // rightLeaves
      // rightScratched
    >
      <div className="insightiq-feature-intro">
        <Icons.insight_iq_icon_with_name name="insight_iq_icon_with_name" width="197.8" height="56" />
        <div className="insightiq-feature-text">
          <Typography
            h1
            style={{
              marginTop: "47px",
              marginBottom: "40px",
              fontWeight: "600",
            }}
          >
            One dashboard for all your influencer marketing needs
          </Typography>
          <div>
            <IconListItem icon={<i className="ri-search-line"></i>} text="Filter and select the best influencers to partner with" />
            <IconListItem icon={<i className="ri-line-chart-line"></i>} text="Get deep insights into audience and campaign data" />
            <IconListItem icon={<i className="ri-code-s-slash-line"></i>} text="Explore our APIs and integrate them into your product" />
            <IconListItem icon={<i className="ri-customer-service-2-line"></i>} text="Reach out to us for questions, 24x7" />
          </div>
        </div>
      </div>
      <div className="registration-parent-card-container">
        <Card className="registration-card-container">
          <Form className="insightiq-form" form={formInstance}>
            <Typography h2 style={{ marginBottom: "32px", fontWeight: "600" }}>
              Create your account
            </Typography>
            <p className="error-message-text signin-error-text">{errors.errorMessage}</p>
            <div className="registration-name-container">
              <Form.Item
                rules={[
                  { required: true, message: EMPTY_REQUIRED_FIELD_MESSAGE },
                  // exceedCharacterLimitHandler(NAME_EXCEED_LIMIT_MESSAGE),
                ]}
                name="firstName"
              >
                <InputField
                  placeholder="Nick"
                  inputLabel="First name"
                  showRequiredIcon
                  autoFocus
                  onChange={(e) => formInstance.setFieldsValue({ firstName: e.target.value })}
                  // onChange={onChange}
                  // onBlur={onBlur}
                  // selected={value}
                  // value={first_name}
                  // {...register("first_name")}
                  // defaultValue="nick"
                  // onChange={setFormData}
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[
                  { required: false },
                  // exceedCharacterLimitHandler(NAME_EXCEED_LIMIT_MESSAGE),
                ]}
              >
                <InputField placeholder="Fury" inputLabel="Last name" />
              </Form.Item>
            </div>
            <Form.Item
              rules={[
                { required: true, message: EMPTY_REQUIRED_FIELD_MESSAGE },
                // exceedCharacterLimitHandler(COMPANY_NAME_EXCEED_LIMIT_MESSAGE),
              ]}
              name="companyName"
            >
              <InputField
                placeholder="S.H.I.E.L.D"
                inputLabel="Company"
                showRequiredIcon
                onChange={(e) => formInstance.setFieldsValue({ companyName: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              name="email"
              validateFirst
              validateTrigger="onBlur"
              rules={[
                { required: true, message: EMPTY_REQUIRED_FIELD_MESSAGE },
                {
                  type: "email",
                  message: INVALID_EMAIL_MESSAGE,
                },
                {
                  validator: async (_, value) => {
                    // const emailDomain = value.split("@")[1];
                    // if (nonWorkEmails.includes(emailDomain)) {
                    const isAllowed = await isValidEmail(value);
                    if (!isAllowed) {
                      trackEvent(NON_WORK_EMAIL_ADDED, { email: value });
                      return Promise.reject(
                        <div style={{ display: "flex", gap: "5px" }}>
                          <div>
                            <Icons.caution_red />
                          </div>
                          <div>
                            {NON_WORK_EMAIL_MESSAGE}{" "}
                            <a href="mailto:rev@insightiq.ai" style={{ color: "var(--main-primary)" }} target="_blank">
                              <u
                                onClick={() => {
                                  trackEvent(EMAIL_SUPPORT_LINK_CLICKED, { source: "REGISTRATION_PAGE" });
                                }}
                              >
                                Email us
                              </u>
                            </a>{" "}
                            if you don’t have a work email.
                          </div>
                        </div>
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <InputField
                placeholder="nickfury@shield.com"
                inputLabel="Email"
                showRequiredIcon
                hasError={errors.email}
                onChange={() => {
                  resetError("email");
                }}
                // {...register("email")}
                // onChange={setFormData}
              />
            </Form.Item>
            <Form.Item name="password" rules={passwordRules}>
              <PasswordField
                placeholder="G00se_took_my_eye"
                inputLabel="password"
                showRequiredIcon
                tagContainerStyle={{ marginBottom: "6px" }}
                // {...register("password")}
                // onChange={setFormData}
              />
            </Form.Item>

            <Form.Item
              name="consent"
              valuePropName="checked"
              // {formInstance.isFieldTouched("consent") && checked === true? "checked" : ""}

              rules={[
                {
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error(AGREE_TERMS_AND_CONDITIONS))),
                },
              ]}
            >
              <div className="consent-text-with-checkbox">
                <CheckBox
                  onClick={async (checked) => {
                    formInstance.setFieldsValue({ consent: checked });
                    await formInstance.validateFields(["consent"]);
                  }}
                />
                <span className="consent-text">
                  I understand and agree to the{" "}
                  <u
                    onClick={() =>
                      window.open("https://flawless-colony-de7.notion.site/Terms-of-service-5a7f2bd83351469799d836bf624b8df3?pvs=4", "_blank")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    terms of usage
                  </u>{" "}
                  and{" "}
                  <u
                    onClick={() =>
                      window.open("https://flawless-colony-de7.notion.site/Privacy-policy-de5e82d600aa4b498cdd1d69de8d7ef2?pvs=4", "_blank")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    privacy policy
                  </u>{" "}
                  of insightIQ. I agree to receive email communication (can customize it later).
                </span>
              </div>
            </Form.Item>
            <Form.Item
              name="recaptcha"
              rules={[
                {
                  validator: async (_, value) => (value ? Promise.resolve() : Promise.reject()),
                },
              ]}
            >
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={(recaptcha) => {
                  formInstance.setFieldsValue({ recaptcha });
                  formInstance.validateFields(["recaptcha"]);
                }}
              />
            </Form.Item>
            <div className="register-submit-button-container">
              <Form.Item shouldUpdate noStyle>
                {() => (
                  <PrimaryButton
                    disabled={disableSubmit()}
                    label="Register"
                    onClick={onSubmitRegistration}
                    htmlType="submit"
                    style={{
                      width: "156px",
                      marginTop: "12px",
                      marginBottom: "30px",
                    }}
                    loading={loading}
                  />
                )}
              </Form.Item>

              <span className="sign-in-button-text">
                Have an account?{" "}
                <TextLink to="/sign-in" onClick={sendUserAction}>
                  Sign in
                </TextLink>
              </span>
            </div>
          </Form>
        </Card>
      </div>
      <Helmet>
        <title>{getPageTitle("REGISTRATION_PAGE", "")}</title>
        {/* Hubspot initialization only on registration screen */}
        {JSON.parse(process.env.REACT_APP_HUBSPOT_SCRIPT_ENABLED) && (
          <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/21487726.js"></script>
        )}
      </Helmet>
    </Background>
  );
}

const IconListItem = ({ icon, text, ...otherProps }) => {
  return (
    <div className="icon-list-item" {...otherProps}>
      <div className="list-icon-container">{icon}</div>
      <span className="list-item-text">{text}</span>
    </div>
  );
};
