import React, { useContext, useEffect, useState } from "react";
import Icons from "../Icons/Icons";
import { useNavigate } from "react-router-dom";
import "./SearchProfileHeader.scss";
import { Box } from "@mui/system";
import { Avatar, Button } from "@mui/material";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {
  CONTACT_ICONS_MAPPING,
  IDENTITY_ATTRIBUTES,
  IDENTITY_WORKPLATFORMS_MAPPING,
  PROFILE_ANALYTICS_ERROR_MAPPING,
  SEARCH_ATTRIBUTES_TOOLTIP_TEXT,
  WORKPLATFORM_ID_MAPPING,
} from "../../constants/constants";
import { convertDate, creatorContactType, formatNumber } from "../../utils/profileHelpers";
import InvertedPrimaryButton from "../InvertedPrimaryButton/InvertedPrimaryButton";
import SearchProfileIdentity from "../SearchProfileIdentity/SearchProfileIdentity";
import SearchProfileHeaderSkeleton from "./SearchProfileHeaderSkeleton";
import { SEARCH_CONTACT_INFORMATION_CLICKED, SEARCH_PLATFORM_PROFILE_VIEWED, SEARCH_PROFILE_DOWNLOADED, trackEvent } from "../../analytics";
import { Tooltip } from "@material-ui/core";
import { contactIconsUrl } from "../../constants/urlConstants";
import { useToast } from "../../hooks/useToast";
import { snakeCaseToSentenceCase, workPlatformsDropdownOptions } from "../../utils/util";
import DropdownIconMenu from "../DropdownIconMenu/DropdownIconMenu";
import ExportPDFPopUp from "../ExportPDFPopUp/ExportPDFPopUp";
import { unlockPopupDetails } from "../../constants/creatorDiscovery";
import CreatorBioDetails from "../CreatorBioDetails/CreatorBioDetails";
import { unlockAccessPopupDetails } from "../../constants/campaignTrackingConstants";

const SearchProfileHeader = ({
  identityData,
  loading = false,
  handleProfileExport,
  errorReason,
  exportButtonLoading,
  contactMenuItems,
  creatorBioItems,
  workPlatformLogos,
  isExportPDFModalOpen,
  setIsExportPDFModalOpen,
  exportPDFMenuItems,
  isTrialMode,
  createListPopUpState,
  setCreateListPopUpState,
  handleMultipleAddToCreatorList,
  modalDetails,
  setModalDetails,
  showBasicDetails
}) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const toast = useToast();
  const [contactOptionsDropdownAnchorEl, setContactOptionsDropdownAnchorEl] = React.useState(null);
  const [exportPDFDropdownOptionsAnchorEl, setExportPDFDropdownOptionsAnchorEl] = React.useState(null);
  const isContactOptionsDropdownOpen = Boolean(contactOptionsDropdownAnchorEl);
  const isExportPDFDropdownOptionsOpen = Boolean(exportPDFDropdownOptionsAnchorEl);

  const handleContactOptionsDropDownClick = (event) => {
    setContactOptionsDropdownAnchorEl(event.currentTarget);
  };

  const handleExportPDFDropDownClick = (event) => {
    setExportPDFDropdownOptionsAnchorEl(event.currentTarget);
  };

  const handleContactOptionsDropDownClose = () => {
    setContactOptionsDropdownAnchorEl(null);
  };

  const handleExportPDFDropDownClose = () => {
    setExportPDFDropdownOptionsAnchorEl(null);
  };

  const redirectToProfileUrl = () => {
    trackEvent(SEARCH_PLATFORM_PROFILE_VIEWED, {
      username: identityData?.profile?.platform_username,
      col: identityData,
      platform: identityData?.work_platform?.name,
      source: "profile_page",
    });
  };
  if (loading) {
    return <SearchProfileHeaderSkeleton />;
  }

  if (errorReason) {
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    const identifier = searchParams.get("identifier");
    const work_platformId = searchParams.get("workplatformId");
    let selectedCreatorDetails = showBasicDetails?.selectedCreatorDetails;
    if (
      showBasicDetails?.show
    ) {
      return (
        <div>
          <Box className="search-profile-container" paddingBottom={"0!important"}>
            <Box className="profile-identity-section" marginBottom={"40px!important"} sx={{marginBottom:"40px!important"}}>
              <Box height={"160px"} width={"160px"}>
                <a target="_blank" href={selectedCreatorDetails?.profileUrl}>
                  <Avatar alt="Profile Pic" src={selectedCreatorDetails?.image_url} className="profilePic" />
                </a>
              </Box>
              <Box className="username-logo-container">
                <Box className="username-container">
                  <Box display={"flex"} flexDirection={"column"} gap="12px">
                    <Box className="fullName">
                      <Box>
                        {selectedCreatorDetails?.full_name !== null && selectedCreatorDetails?.full_name !== "None"
                          ? selectedCreatorDetails?.full_name
                          : selectedCreatorDetails?.platform_username}
                      </Box>
                      {selectedCreatorDetails?.is_verified && (
                        <div>
                          <Icons.verified_logo />
                        </div>
                      )}
                    </Box>
                    <Box className="error_userName_logo" style={{justifyContent: "start", alignItems:"center", marginTop:"0px"}}>
                      {work_platformId && <img width="100%" src={WORKPLATFORM_ID_MAPPING[work_platformId].platformLogo} />}
                      {selectedCreatorDetails?.platform_username !== null ? (
                        <a target="_blank" href={selectedCreatorDetails?.url} style={{ color: "var(--neutrals-primary-grey)!important" }}>
                          @{selectedCreatorDetails?.platform_username}
                        </a>
                      ) : (
                        "-"
                      )}
                    </Box>
                  </Box>
                  <Box className="userName">
                    <div><Icons.users_icon /></div>
                    {selectedCreatorDetails?.follower_count} Followers 
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      );
    } else {
    return (
      <div>
        <Box className="search-profile-container">
          <Box className="profile-identity-section">
            <Box>
              <div className="profilePic"></div>
            </Box>
            <Box className="username-logo-container">
              <Box className="username-container">
                <Box className="error_userName_logo">
                  {identifier !== null ? <p>@{identifier}</p> : `-`}
                  {work_platformId && <img width="100%" src={WORKPLATFORM_ID_MAPPING[work_platformId].platformLogo} />}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
  }

  const creatorsListsHandler = () => {
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    const work_platformId = searchParams.get("workplatformId");
    if (isTrialMode) {
      setModalDetails({
        ...modalDetails,
        isModalVisible: true,
        modalInfo: unlockPopupDetails.addCreatorToList,
      });
    } else {
      if (identityData?.is_part_of_creator_list) {
        handleMultipleAddToCreatorList(identityData?.profile, work_platformId);
      } else {
        setCreateListPopUpState({
          ...createListPopUpState,
          visible: true,
          isVerified: identityData?.profile?.is_verified,
          full_name: identityData?.profile?.full_name,
          image_url: identityData?.profile?.image_url,
          platform_username: identityData?.profile?.platform_username,
          profile_url: identityData?.profile?.url,
          workplatform_id: identityData?.work_platform?.id,
        });
      }
    }
  };

  return (
    identityData &&
    !errorReason && (
      <>
        <Box
          sx={{
            height: "max-content !important",
            backgroundColor: "#ffffff",
            padding: "8px 8px 0px 8px",
          }}
        >
          <Box className="profile-identity-section">
            <Box height={"160px"} width={"160px"}>
              <a target="_blank" href={identityData.profile.url} onClick={redirectToProfileUrl}>
                <Avatar alt="Profile Pic" src={identityData.profile.image_url} className="profilePic" />
              </a>
            </Box>
            <Box className="username-logo-container">
              <Box className="username-container">
                <Box display={"flex"} flexDirection={"column"} gap="12px">
                  <Box className="fullName">
                    <Box>
                      {identityData.profile?.full_name !== null && identityData.profile?.full_name !== "None"
                        ? identityData.profile?.full_name
                        : identityData.profile?.platform_username}
                    </Box>
                    {identityData.profile.is_verified && (
                      <div>
                        <Icons.verified_logo />
                      </div>
                    )}
                  </Box>
                  <Box className="userName">
                    {identityData.profile.platform_username !== null ? (
                      <a target="_blank" href={identityData.profile.url} onClick={redirectToProfileUrl}>
                        @{identityData.profile.platform_username}
                      </a>
                    ) : (
                      "-"
                    )}
                    <div className="work-platform-logos-container">
                      {workPlatformLogos.map((workPlatform) => (
                        <a href={workPlatform.handle} target="_blank">
                          <div className="work-platform-logos-container">
                            <img width="24px" height="24px" alt={workPlatform.name} src={workPlatform.logo} />
                          </div>
                        </a>
                      ))}
                    </div>
                  </Box>
                </Box>
                {identityData?.profile?.introduction && (
                  <Box className="profile-introduction">
                    Bio: {/* {!showMore ? ( */}
                    {/* <> */}
                    {identityData.profile?.introduction?.length > 130
                      ? identityData.profile?.introduction?.slice(0, 105) + "..."
                      : identityData.profile?.introduction}
                    {/* {identityData.profile?.introduction?.length > 110 && (
                          <span className="read-more" onClick={() => setShowMore(true)}>
                            <a>read more.</a>
                          </span>

                        )} */}
                    {/* </>
                    ) : (
                      <>
                        {identityData.profile.introduction}
                        {showMore && (
                          <span className="read-more" onClick={() => setShowMore(false)}>
                            <a>read less.</a>
                          </span>
                        )}
                      </>
                    )} */}
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              onClick={() => {
                trackEvent(SEARCH_PROFILE_DOWNLOADED, {
                  username: identityData.profile.platform_username,
                  platform: identityData?.work_platform?.name,
                });
              }}
              className="identity-right-header"
            >
              {/* {identityData.profile.report_generated_at && <label>Report generated on {convertDate(identityData.profile.report_generated_at)}</label>} */}
              {/* <Box className="identity-right-header-favorite-container">
                <i className="ri-heart-line heart-icon"></i>
                Add to favorites
              </Box> */}
              <Box className="identity-right-header-favorite-container" onClick={creatorsListsHandler}>
                {identityData?.is_part_of_creator_list ? (
                  <div className="profile-header-icon-container selected">
                    <Icons.created_list />
                  </div>
                ) : (
                  <div>
                    <i className="ri-play-list-add-line heart-icon"></i>
                  </div>
                )}
                {identityData?.is_part_of_creator_list ? <div className="selected">Added to list</div> : <div>Add to list</div>}
              </Box>
              <Box className="identity-right-header-button-container">
                <Button
                  startIcon={<i className="ri-share-forward-line share-icon" />}
                  endIcon={<i className="ri-arrow-down-s-line"></i>}
                  id="demo-customized-button"
                  aria-controls={isExportPDFDropdownOptionsOpen ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={isExportPDFDropdownOptionsOpen ? "true" : undefined}
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={handleExportPDFDropDownClick}
                >
                  Share
                </Button>
                <DropdownIconMenu
                  open={isExportPDFDropdownOptionsOpen}
                  anchorEl={exportPDFDropdownOptionsAnchorEl}
                  handleClose={handleExportPDFDropDownClose}
                  menuOptions={exportPDFMenuItems}
                  width={"180px"}
                />
              </Box>
              {contactMenuItems.length > 0 && (
                <Box className="identity-right-header-contact-button">
                  <Button
                    id="demo-customized-button"
                    aria-controls={isContactOptionsDropdownOpen ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={isContactOptionsDropdownOpen ? "true" : undefined}
                    disableElevation
                    onClick={handleContactOptionsDropDownClick}
                    endIcon={<i className="ri-arrow-down-s-line"></i>}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                  >
                    Contact
                  </Button>
                  <DropdownIconMenu
                    open={isContactOptionsDropdownOpen}
                    anchorEl={contactOptionsDropdownAnchorEl}
                    handleClose={handleContactOptionsDropDownClose}
                    menuOptions={contactMenuItems}
                    isLogoContainsImage
                    width={"305px"}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <CreatorBioDetails creatorBioItems={creatorBioItems} />
          </Box>
        </Box>
      </>
    )
  );
};

export default SearchProfileHeader;
