import { Box } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getCreatorAccounts, updateProfileCost } from '../../api/CampaignTrackingAPI/CampaignTrackingAPI';
import { EmptyTableScreen, Icons, PrimaryButton } from '../../components';
import CreatorCostList from '../../components/CreatorCostList/CreatorCostList';
import TextBox from '../../components/InsightIQ/TextBox/TextBox';
import {
    campaignPageTabValues,
    cost_usd,
    creator_accounts,
    creator_cost_info_detail,
    creatorCostPageHeader,
    DONE,
    manageCostPageHeader,
    SELECT_CREATOR_PLACEHOLDER,
    totalCostCountText,
    will_do_later_text,
    noCreatorAdded,
    startAddingCreator,
    CREATE_UTM_LINKS_EDIT_FLOW
} from '../../constants/campaignTrackingConstants';
import { WarehouseContext } from '../../storage/context';
import campaignTrackingContext from '../../storage/context/campaignTrackingContext';
import { SET_CAMPAIGN_ACTIVE_TAB } from '../../storage/reducers/types';
import "./CreatorCostPage.scss"
import { clearCampaignTrackingWarehouse, isEmpty } from "../../utils/util";


const CreatorCostPage = () => {
    const navigate = useNavigate();
    const [accountDetails, setAccountDetails] = useState([])
    const [totalCostCount, setTotalCostCount] = useState(0)
    const { warehouse } = useContext(WarehouseContext);
    const { campaignId } = useParams()
    const [loading, setLoading] = useState(false)
    const { dispatchCampaignTrackingWarehouse, campaignTrackingWarehouse } = useContext(campaignTrackingContext);
    const [isManageCostPage, setIsManageCostPage] = useState(false)
    const [inputSearch, setInputSearch] = useState("")
    const onlyOnce = useRef(true)
    const renderInfoBox = () => {
        return (
            <Box className='render-info-box'>
                <Box><Icons.creator_cost_icon /></Box>
                <Box className='info-box-text'>
                    <Box className='sub-section-heading'>{creator_cost_info_detail.title}</Box>
                    <Box className='body-r'>{creator_cost_info_detail.desc}</Box>
                </Box>
            </Box>)
    }

    useEffect(() => {
        let pathArray = window.location.pathname.split("/")
        if ((pathArray[3] === "creator-cost-page")) {
            setIsManageCostPage(false)
        } else if ((pathArray[3] === "manage-creator-cost")) {
            setIsManageCostPage(true);
        } else {
            navigate("/creators/campaigns");
        }
        return () => {
            clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
        }
    }, [window.location]);

    const handleCostChange = (id, cost) => {

        setAccountDetails(prevState => {
            const updatedAccountDetail = prevState.map(item => {
                if (item.id === id) {
                    if (cost === '') {
                        return { ...item, cost: 0 };
                    }
                    return { ...item, cost: +cost };
                }
                return item;
            });

            return [...updatedAccountDetail];
        });
    }

    function getTotalCost(detail) {
        if (!detail || detail.length === 0) return
        let cost = 0;
        detail.map((creator) => {
            cost += +creator.cost;
        });
        return cost;
    }

    const fetchCampaignProfile = async (searchString = "") => {
        setLoading(true)
        const response = await getCreatorAccounts({ warehouse, campaignId, search_string: searchString, limit: 100 });
        setAccountDetails(response.data)
        setLoading(false)
    }

    useEffect(() => {

        if (onlyOnce.current) {
            fetchCampaignProfile().then(() => {
            })
            onlyOnce.current = false
        }

        let totalCost = getTotalCost(accountDetails)
        setTotalCostCount(totalCost)
    }, [accountDetails])

    const updateCostClick = async () => {
        try {

            const payload = accountDetails.map(item => ({
                "id": item.id,
                "cost": item.cost
            }));
            const updateCost = await updateProfileCost({ warehouse, campaignId, payload })
        } catch (error) {
            console.error(error)
        }
        navigate(`/creators/campaigns/campaign-view/${campaignId}`);
        dispatchCampaignTrackingWarehouse({
            type: SET_CAMPAIGN_ACTIVE_TAB,
            payload: campaignPageTabValues.measureROI,
        });
    }

    function onSelectCreatorClick() {
        let selectCreatorsState = {
            navigateDoneRoute: `/creators/campaigns/utm-links/${campaignId}`,
            doneButton: CREATE_UTM_LINKS_EDIT_FLOW,
        };
        navigate(`/creators/campaigns/select-creators`, { state: selectCreatorsState });
    }

    return (
        <Box className='creator-cost-page'>
            <Box>
                <i className="ri-arrow-left-line back-icon" onClick={() => {
                    navigate(-1);
                }}></i>
            </Box>
            <Box className='creator-cost-body'>
                <Box className='title'> {isManageCostPage ? manageCostPageHeader : creatorCostPageHeader}</Box>
                <Box>{renderInfoBox()}</Box>

                
               {(accountDetails.length!==0 || loading || !isEmpty(inputSearch))? <>
               <Box className='creator-search-input'>
                    <TextBox
                        variant={'short'}
                        placeholder={SELECT_CREATOR_PLACEHOLDER}
                        value={inputSearch}
                        onChange={(e) => setInputSearch(e.target.value)}
                        containerClass={'creator-search-input-container'}
                        onEnter={() => fetchCampaignProfile(inputSearch).then(() => {})}
                        onClear={() => {
                            setInputSearch("")
                            fetchCampaignProfile("").then(() => {});
                        }}
                    />
                </Box>
                <Box>
                    <Box className='creator-search-table-header'>
                        <Box className='body-r'>{creator_accounts}</Box>
                        <Box className='body-r'>{cost_usd}</Box>
                    </Box>

                    <Box>
                        <CreatorCostList
                            rows={accountDetails}
                            handleCostChange={handleCostChange}
                            loading={loading}
                        />
                    </Box>
                </Box>
                </>:
                <Box className='campaign-error-content campaign-comming-soon'>
                <EmptyTableScreen
                    icon={null}
                    header={noCreatorAdded}
                    content={startAddingCreator}
                    actionButtonContent={
                        <>
                            <i className="ri-add-line"></i> Select creator accounts
                        </>
                    }
                    actionButtonHandler={()=>onSelectCreatorClick()}
                    isActionButton={true}
                />
                
            </Box>}


            </Box>
            <Box className='cost-page-footer'>
                <Box className='body-r total-cost-count'>{totalCostCountText} <span
                    className='body-m'>$ {totalCostCount}</span></Box>
                <Box>
                    {totalCostCount === 0 &&
                        <Box onClick={() => navigate(`/creators/campaigns/campaign-view/${campaignId}`)}
                            className='body-r skip-text'>{will_do_later_text}</Box>}
                    <PrimaryButton
                        label={DONE}
                        className="body-m"
                        onClick={updateCostClick}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default CreatorCostPage