import { Chip, Skeleton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  ANALYZING_COMMENTS,
  COMMENTS_ANALYSIS_MESSAGE_SUCCESS,
  LEARN_HOW_AI,
  LEARN_HOW_AI_TEXT,
  THIS_MIGHT_TAKE_SOME_TIME,
  OOPS_POST_HAS_NO_COMMENTS_MESSAGE,
  OOPS_POST_HAS_NO_COMMENTS,
  STATUS,
  FAILURE_COMMENTS,
  FAILURE_MESSAGE,
  NEW_DATA_LOADING_MESSAGE,
  NEW_DATA_NOT_AVAILABLE_MESSAGE,
} from "../../constants/creatorDiscovery";
import { convertTimeToLocale, isEmpty } from "../../utils/util";
import { DonutChart, DonutChartLegend, EmptyTableScreen, Icons, Loader } from "../../components";
import {
  AUDIENCE_SENTIMENT,
  AUDIENCE_SENTIMENT_MESSAGE,
  COMMENTS_ANALYSIS_TITLE_SUCCESS,
  DATE_FORMAT,
  INTENT_TO_BUY_PRODUCT, INTENT_TO_BUY_PRODUCT_MESSAGE,
  NEGATIVE,
  NEUTRAL,
  POSITIVE
} from "../../constants/constants";
import { formatNumber, percentFormatter } from "../../utils/profileHelpers";
import { INTERESTED, NOT_INTERESTED } from "../../constants/campaignTrackingConstants";
import { Box } from "@material-ui/core";
import { Colors } from "../../styles/colors";
import { UserContext } from "../../storage/context";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

export function Outline({ title, subTitle, showTooltip, children }) {
  return (
    <div className={"div-comments-analysis"}>
      <div className={"div-comments-analysis-header"}>
        <div className={"div-left-header"}>
          {title ? <div className="mainHeading">{title}</div> : null}
          <div className="subTitle">{subTitle}</div>
        </div>
        {showTooltip ? (
          <Tooltip placement={"top-start"} title={LEARN_HOW_AI_TEXT}>
            <Chip
              icon={<i className="ri-information-line tooltip-icon"></i>}
              label={<div className={"body-r tooltip-label"}>{LEARN_HOW_AI}</div>}
              tabIndex={-1}
              classes={{
                root: "platform-btn",
              }}
              className={"comments-chip"}
            />
          </Tooltip>
        ) : null}
      </div>
      {children}
    </div>
  );
}

function isNullOrZero(val) {
  return val === null || val === 0;
}

const CreatorDiscoveryCommentAnalysis = (props) => {
  const { profileAnalytics } = props;
  const { userProfile } = useContext(UserContext);
  const [isCommentAnalysisContainerVisible, setIsCommentAnalysisContainerVisible] = useState(false);


  if (!profileAnalytics) {
    return null;
  }

  let {
    total_comment_count,
    positive_comment_count,
    negative_comment_count,
    neutral_comment_count,
    purchase_intent_comment_count,
    status,
    error_type,
    error_code,
    error_message,
    updated_at,
  } = profileAnalytics?.profile_comment_analytics || {};

  let title = null,
    subTitle = null;
  let audienceSentimentChart = null,
    purchaseIntentChart = null;

  const commentAnalysis_someThingWentWrong = isNullOrZero(total_comment_count) && status === STATUS.FAILED && isNullOrZero(error_code);
  const commentAnalysis_inProgress = isNullOrZero(total_comment_count) && status === STATUS.IN_PROGRESS;
  const commentAnalysis_success = !isNullOrZero(total_comment_count) && status === STATUS.SUCCESS;
  const commentAnalysis_noCommentsFound = isNullOrZero(total_comment_count) && (status === STATUS.FAILED && error_code === "comments_not_found" || status === STATUS.SUCCESS);
  const commentAnalysis_newDataLoading = !isNullOrZero(total_comment_count) && status === STATUS.IN_PROGRESS;
  const commentAnalysis_newDataNotAvailable = !isNullOrZero(total_comment_count) && status === STATUS.FAILED;

  if (commentAnalysis_someThingWentWrong) {
    title = <div className={"section-heading"}>{COMMENTS_ANALYSIS_TITLE_SUCCESS}</div>;
  } else if (commentAnalysis_inProgress) {
    title = (
      <div className={"section-heading div-row"} style={{ gap: 16 }}>
        <Loader className={"campaign-loader-container"} />
        {ANALYZING_COMMENTS}
      </div>
    );
    subTitle = <div className={"body-r subtitle"}>{THIS_MIGHT_TAKE_SOME_TIME(userProfile?.user?.email)}</div>;
  } else if (commentAnalysis_noCommentsFound) {
    title = <div className={"section-heading"}>{COMMENTS_ANALYSIS_TITLE_SUCCESS}</div>;
  } else {
    title = <div className={"section-heading"}>{COMMENTS_ANALYSIS_TITLE_SUCCESS}</div>;
    if (commentAnalysis_success) {
      subTitle = (
        <div className={"body-r subtitle"}>
          {COMMENTS_ANALYSIS_MESSAGE_SUCCESS(formatNumber(total_comment_count), convertTimeToLocale(updated_at, DATE_FORMAT))}
        </div>
      );
    } else if (commentAnalysis_newDataLoading) {
      subTitle = (
        <div className={"body-r subtitle with-icon"}>
          <Loader className={"campaign-loader-container"} />
          {NEW_DATA_LOADING_MESSAGE(userProfile?.user?.email, convertTimeToLocale(updated_at, DATE_FORMAT))}
        </div>
      );
    } else if (commentAnalysis_newDataNotAvailable) {
      subTitle = (
        <div className={"body-r subtitle with-icon"}>
          <Box>
            <i class="ri-alert-line" style={{ fontSize: "24px", color: "#926100" }}></i>
          </Box>
          {NEW_DATA_NOT_AVAILABLE_MESSAGE(userProfile?.user?.email, convertTimeToLocale(updated_at, DATE_FORMAT))}
        </div>
      );
    }

    if (isNullOrZero(total_comment_count)) {
      audienceSentimentChart = null;
    } else {
      const audienceSentimentData = getAudienceSentimentData();
      audienceSentimentChart = (
        <div className={"div-cell div-chart-audience-sentiment"} style={{ gap: "32px" }}>
          {isCommentAnalysisContainerVisible&&<DonutChart
            data={audienceSentimentData.data}
            labels={audienceSentimentData.labels}
            variant={"triple-disconnected"}
            height={160}
            width={160}
            isActive={isCommentAnalysisContainerVisible}
          />}
          <div className={"div-donut-chart-legend-profile"}>
            {audienceSentimentData.legendData.map((legend) => {
              const { color, title, subTitle, value } = legend;
              return (
                <div className={"div-donut-chart-legend"}>
                  <div className={"row-1"}>
                    <svg width={16} height={16}>
                      <circle cx={8} cy={8} r={8} fill={color} />
                    </svg>
                    <div className={"sub-section-heading legend-title"}>{title}</div>
                  </div>
                  <div className={"body-r legend-subtitle"}>{subTitle}</div>
                  <div className={"body-r legend-subtitle"}>
                    ({formatNumber(value)} {formatNumber(value) > 1 ? "comments" : "comment"})
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (isNullOrZero(total_comment_count) || purchase_intent_comment_count === null) {
      purchaseIntentChart = null;
    } else {
      const purchaseIntentData = getPurchaseIntentData();
      purchaseIntentChart = (
        <div className={"div-cell"}>
          {isCommentAnalysisContainerVisible && <DonutChart data={purchaseIntentData.data} labels={purchaseIntentData.labels} variant={"dual-connected-primary"} height={160} width={160} isActive={isCommentAnalysisContainerVisible}/>}
        </div>
      );
    }
  }

  function Content() {
    if (commentAnalysis_someThingWentWrong) {
      return <FailureMessage />;
    }
    if (commentAnalysis_inProgress) {
      return <CommentAnalysisLoading />;
    }
    if (commentAnalysis_noCommentsFound) {
      return <NoCommentAnalysis />;
    }
    return <SuccessContent />;
  }

  function getPurchaseIntentData() {
    const label = percentFormatter.format(purchase_intent_comment_count / total_comment_count);
    return {
      data: [
        { name: INTERESTED, value: purchase_intent_comment_count / total_comment_count },
        { name: NOT_INTERESTED, value: 1 - purchase_intent_comment_count / total_comment_count },
      ],
      labels: [label],
    };
  }

  function getAudienceSentimentData() {
    const rawData = [
      {
        name: POSITIVE,
        color: Colors.accentCaribbeanGreen,
        value: positive_comment_count,
      },
      {
        name: NEGATIVE,
        color: Colors.accentAmaranth,
        value: negative_comment_count,
      },
      {
        name: NEUTRAL,
        color: Colors.neutralsBorderGrey,
        value: neutral_comment_count,
      },
    ];

    const data = rawData.map((d) => {
      const { name, color, value } = d;
      return { name, color, value: value / total_comment_count };
    });

    function getLabelFields() {
      if (negative_comment_count > positive_comment_count) {
        return {
          value: negative_comment_count,
          name: NEGATIVE,
        };
      }
      return {
        value: positive_comment_count,
        name: POSITIVE,
      };
    }

    const { value: labelValue, name: labelName } = getLabelFields();

    const labels = [percentFormatter.format(labelValue / total_comment_count), labelName];

    const legendData = [
      {
        color: Colors.accentCaribbeanGreen,
        title: percentFormatter.format(positive_comment_count / total_comment_count),
        subTitle: POSITIVE,
        value: positive_comment_count,
      },
      {
        color: Colors.neutralsBorderGrey,
        title: percentFormatter.format(neutral_comment_count / total_comment_count),
        subTitle: NEUTRAL,
        value: neutral_comment_count,
      },
      {
        color: Colors.accentAmaranth,
        title: percentFormatter.format(negative_comment_count / total_comment_count),
        subTitle: NEGATIVE,
        value: negative_comment_count,
      },
    ];

    return {
      data,
      labels,
      legendData,
    };
  }

  function SuccessContent() {
    const { chartContainerRef: chartContainerRef, isVisible: isChartContainerVisible } = useIntersectionObserver(0);

    useEffect(() => {
      if(isChartContainerVisible){
        setIsCommentAnalysisContainerVisible(true)
      }
    },[isChartContainerVisible])

    return (
      <div className={"div-comments-analysis-content"} ref={chartContainerRef}>
        <div className={"div-row div-first-row"} style={{ gap: "60px" }}>
          <Box display={"flex"} sx={{ gap: 24 }} flexDirection={"column"} width={"520px"} >
            {purchaseIntentChart}
            {purchaseIntentChart ? (
              <div className={"div-cell div-chart-audience-sentiment-cell"}>
                <div className={"sub-section-heading"}>{INTENT_TO_BUY_PRODUCT}</div>
                <div className={"body-r subtitle comments-message"}>
                  {formatNumber(purchase_intent_comment_count)}/{formatNumber(total_comment_count)} comments
                </div>
                <div className={"body-r subtitle comments-message"}>{INTENT_TO_BUY_PRODUCT_MESSAGE}</div>
              </div>
            ) : null}
          </Box>
          <Box display={"flex"} sx={{ gap: 24 }} flexDirection={"column"} width={"520px"}>
            {audienceSentimentChart}
            {audienceSentimentChart ? (
              <div className={"div-cell div-chart-audience-sentiment-cell"}>
                <div className={"sub-section-heading"}>{AUDIENCE_SENTIMENT}</div>
                <div className={"body-r subtitle comments-message"}>{AUDIENCE_SENTIMENT_MESSAGE}</div>
              </div>
            ) : null}
          </Box>
        </div>
      </div>
    );
  }

  function CommentAnalysisLoading() {
    return (
      <div className={"div-row"} style={{ gap: 48 }}>
        <div className={"div-cell purchase-intent-loading"}>
          <Box width={"160px"} height={"160px"}>
            <Skeleton variant="rectangular" width={"160px"} height={"160px"} sx={{ borderRadius: "6px" }} />
          </Box>
          <Box display={"flex"} flexDirection={"column"} sx={{ gap: 8 }}>
            <div className={"sub-section-heading"}>{INTENT_TO_BUY_PRODUCT}</div>
            <div className={"body-r subtitle comments-message"}>{INTENT_TO_BUY_PRODUCT_MESSAGE}</div>
          </Box>
        </div>
        <div className={"div-cell purchase-intent-loading"}>
          <Box width={"160px"} height={"160px"}>
            <Skeleton variant="rectangular" width={"160px"} height={"160px"} sx={{ borderRadius: "6px" }} />
          </Box>
          <Box display={"flex"} flexDirection={"column"} sx={{ gap: 8 }}>
            <div className={"sub-section-heading"}>{AUDIENCE_SENTIMENT}</div>
            <div className={"body-r subtitle comments-message"}>{AUDIENCE_SENTIMENT_MESSAGE}</div>
          </Box>
        </div>
      </div>
    );
  }

  function FailureMessage() {
    return (
      <Box className="campaign-error-content no-comment-analysis">
        <EmptyTableScreen icon={<Icons.post_has_no_comments />} header={FAILURE_COMMENTS} content={FAILURE_MESSAGE} isActionButton={false} />
      </Box>
    );
  }

  function NoCommentAnalysis() {
    return (
      <Box className="campaign-error-content no-comment-analysis">
        <EmptyTableScreen
          icon={<Icons.post_has_no_comments />}
          header={OOPS_POST_HAS_NO_COMMENTS}
          content={OOPS_POST_HAS_NO_COMMENTS_MESSAGE}
          isActionButton={false}
        />
      </Box>
    );
  }

  return (
    <Outline title={title} subTitle={subTitle} showTooltip={true}>
      <Content />
    </Outline>
  );
};

export default React.memo(CreatorDiscoveryCommentAnalysis);
