import React, { useContext, useState } from 'react';
import { compareCreatorProductID, COMPARE_CREATOR_PAGE_HEADER, DATE_FORMAT, DOWNLOAD_PDF, SHARE_BUTTON_TEXT, SHARE_LINK, TRIAL } from '../../constants/constants';
import { AppBar, Button, Toolbar } from '@mui/material';
import DropdownIconMenu from '../DropdownIconMenu/DropdownIconMenu';
import './CompareCreatorsPageHeader.scss';
import { useNavigate } from 'react-router-dom';
import Skeleton from "@mui/material/Skeleton";
import { COMPARE_CREATOR_REPORT_STATUS, COPY_LINK, CREATED_ON, GENERATING_LINK, SHARE_CREATOR_COMPARISON } from '../../constants/creatorDiscovery';
import { WarehouseContext } from '../../storage/context';
import { exportCreatorComparisonData } from '../../api/compareCreatorAPI/compareCreatorAPI';
import Loader from '../Loader/Loader';
import ExportPDFPopUp from '../ExportPDFPopUp/ExportPDFPopUp';
import { useToast } from '../../hooks/useToast';


function CompareCreatorsPageHeader({ isComparisonLoading, numberOfCreatorsToCompare, status, onDeleteButtonClick, isSampleReport, showAppBar, created_at, comparisonId }) {
  const [exportComparisonReportAnchorEl, setExportComparisonReportAnchorEl] = useState(null);
  const isExportComparisonReportDropdownOptionsOpen = Boolean(exportComparisonReportAnchorEl);
  const handleExportComparisonReportDropDownClick = (event) => {
    setExportComparisonReportAnchorEl(event.currentTarget);
  };
  const { SUCCESS } = COMPARE_CREATOR_REPORT_STATUS;
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const [exportPDFLink, setexportPDFLink] = useState("");
  const toast = useToast();
  const handleExportComparisonReportDropDownClose = () => {
    setExportComparisonReportAnchorEl(null);
  };

  const exportComparisonReportDropdownOptions = [
    {
      startIcon: <i class='ri-links-line menu-icon' />,
      label: SHARE_LINK,
      actionHandler: () => {
        handleProfileExport().then(() => {
          setIsExportModalOpen(true);
        });
      },
    },
    {
      startIcon: <i class='ri-file-list-3-line menu-icon' />,
      label: DOWNLOAD_PDF,
      actionHandler: () => {
        let isPDFDownloadRequired = true;
        handleProfileExport(isPDFDownloadRequired).then(() => { });
      },
    },
  ];


  async function handleProfileExport(isPDFDownloadRequired = false) {
    setIsPDFLoading(true);
    const payload = {
      id: comparisonId
    };
    try {
      const response = await exportCreatorComparisonData({ payload });
      if (!response.url) return;
      const url = response.url;
      setexportPDFLink(url);
      // Provide a filename for the downloaded PDF
      if (isPDFDownloadRequired) {
        const fileName = response.filename;
        // take url from response and click to download it
        const pdfDownloadUrl = document.createElement("a");
        pdfDownloadUrl.href = url;
        pdfDownloadUrl.download = fileName;
        document.body.appendChild(pdfDownloadUrl);
        pdfDownloadUrl.click();
        document.body.removeChild(pdfDownloadUrl);
      }
    } catch (error) {
      throw error;
    } finally {
      setIsPDFLoading(false);
    }
  };


  if (isComparisonLoading) {
    return (
      <div className='compare-creator-header-loader' >
        <Skeleton variant="text" height={"50px"} width={"280px"} />
        <Skeleton variant="text" height={"75px"} width={"180px"} />
      </div>
    );
  }

  function RenderStickyHeader() {
    return (
      showAppBar ? (
        <>
          <AppBar position="fixed" sx={{ transform: showAppBar ? "translateY(0)" : "translateY(-100%)" }} className="creator-discovery-profile-app-bar">
            <Toolbar disableGutters className="app-bar-container">
              <div>
                <i className="ri-arrow-left-line back-icon" onClick={() => window.history.back()} />
              </div>
              <div>
                {status === SUCCESS ? <div className='compare-creators-header-actions-container'>
                  <div className='compare-creators-header-actions'>
                    <Button
                      startIcon={<i className='ri-share-forward-line' />}
                      endIcon={<i className='ri-arrow-down-s-line'></i>}
                      id='demo-customized-button'
                      aria-controls={isExportComparisonReportDropdownOptionsOpen ? 'demo-customized-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={isExportComparisonReportDropdownOptionsOpen ? 'true' : undefined}
                      disableElevation
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      className='compare-creators-sticky-share-button button'
                      onClick={handleExportComparisonReportDropDownClick}
                    >
                      {SHARE_BUTTON_TEXT}
                    </Button>
                    <DropdownIconMenu
                      open={isExportComparisonReportDropdownOptionsOpen}
                      anchorEl={exportComparisonReportAnchorEl}
                      handleClose={handleExportComparisonReportDropDownClose}
                      menuOptions={exportComparisonReportDropdownOptions}
                      width={'180px'}
                      showAppBar={showAppBar}
                    />
                  </div>
                  {!isComparisonLoading ? (
                    <div className={isSampleReport ? 'compare-creator-limit-reached compare-creators-delete-button' : 'compare-creators-delete-button'} onClick={() => onDeleteButtonClick()} >
                      <i className='ri-delete-bin-line color-neutrals-secondary-grey ri-1x' />
                    </div>
                  ) : null}
                </div> : null}
              </div>
            </Toolbar>
          </AppBar>
          <ExportLoadingModal open={isPDFLoading} />
          <ExportPDFPopUp
            open={isExportModalOpen}
            onClose={() => setIsExportModalOpen(false)}
            header={SHARE_CREATOR_COMPARISON}
            actionButtonLabel={COPY_LINK}
            actionButtonHandler={() => {
              navigator.clipboard.writeText(exportPDFLink);
              setIsExportModalOpen(false);
              toast("Link copied to clipboard");
            }}
            exportPDFLink={exportPDFLink}
          />
        </>
      ) : null
    );
  };

  if (showAppBar) {
    return <RenderStickyHeader />;
  }

  return (
    <>
      <div className='compare-creators-back-icon'  >
        <i className='ri-arrow-left-line back-icon' onClick={() => window.history.back()} />
      </div>
      <div className='compare-creators-header-container'>
        <div className='compare-creators-header-title '>
          <div className='title' >{COMPARE_CREATOR_PAGE_HEADER(numberOfCreatorsToCompare)}</div>
          {status === SUCCESS ? <div className='body-r' >{CREATED_ON(created_at, DATE_FORMAT)}</div> : null}
        </div>
        {status === SUCCESS ? <div className='compare-creators-header-actions-container'>
          <div className='compare-creators-header-actions'>
            <Button
              startIcon={<i className='ri-share-forward-line' />}
              endIcon={<i className='ri-arrow-down-s-line'></i>}
              id='demo-customized-button'
              aria-controls={isExportComparisonReportDropdownOptionsOpen ? 'demo-customized-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={isExportComparisonReportDropdownOptionsOpen ? 'true' : undefined}
              disableElevation
              disableRipple
              disableFocusRipple
              disableTouchRipple
              className='compare-creators-share-button'
              onClick={handleExportComparisonReportDropDownClick}
            >
              {SHARE_BUTTON_TEXT}
            </Button>
            <DropdownIconMenu
              open={isExportComparisonReportDropdownOptionsOpen}
              anchorEl={exportComparisonReportAnchorEl}
              handleClose={handleExportComparisonReportDropDownClose}
              menuOptions={exportComparisonReportDropdownOptions}
              width={'180px'}
            />
          </div>
          {!isComparisonLoading ? (
            <div className={isSampleReport ? 'compare-creator-limit-reached compare-creators-delete-button' : 'compare-creators-delete-button'} onClick={() => onDeleteButtonClick()} >
              <i className='ri-delete-bin-line color-neutrals-secondary-grey ri-1x' />
            </div>
          ) : null}
        </div> : null}
      </div>
      <ExportLoadingModal open={isPDFLoading} />
      <ExportPDFPopUp
        open={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        header={SHARE_CREATOR_COMPARISON}
        actionButtonLabel={COPY_LINK}
        actionButtonHandler={() => {
          navigator.clipboard.writeText(exportPDFLink);
          setIsExportModalOpen(false);
          toast("Link copied to clipboard");
        }}
        exportPDFLink={exportPDFLink}
      />
    </>
  );
}

const ExportLoadingModal = ({ open, onClose }) => {
  if (!open) {
    return null;
  }
  return (
    <div className="modal">
      <div className="export-results-overlay-loading">
        <div className="export-result-loader-container">
          <Loader className={"campaign-loader-container"} />
          <div className="export-result-loader-header">{GENERATING_LINK}</div>
        </div>
      </div>
    </div>
  );
};

export default CompareCreatorsPageHeader;
