import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CampaignTrackingContext, WarehouseContext } from "../../storage/context";
import {
    CampaignPageHeader,
    ConnectShopifyMainContent,
    TabSwitch,
    TrackedPostsMainContent,
    Typography
} from "../../components";
import { ADD_CREATORS_ENTRY_SOURCE, campaignPageTabValues, } from "../../constants/campaignTrackingConstants";
import { Box } from "@material-ui/core";
import "./AutomaticallyAddCreatorsPage.scss";
import { getIdentifiers, getNonDeletedCreators, isEmpty } from "../../utils/util";
import moment from "moment/moment";
import { SET_CSV_CREATORS_LIST, SET_POST_IDENTIFIERS, SET_PROFILES_CSV, SET_TRACKED_CREATORS } from "../../storage/reducers/types";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function AutomaticallyAddCreatorsPage() {
    const campaignPageTabs = [{
        label: campaignPageTabValues.trackPosts,
    }, {
        label: campaignPageTabValues.measureROI,
    }];
    const { campaignId } = useParams();
    const { dispatchCampaignTrackingWarehouse, campaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const [pageCurrTab, setPageCurrTab] = useState(campaignTrackingWarehouse.campaignPageActiveTab ?? campaignPageTabValues.trackPosts);
    const [hasUnsavedContent, setHasUnsavedContent] = useState(false);
    const [shopifyStores, setShopifyStores] = useState([]);
    const navigate = useNavigate();
    const {
        trackedCreators,
        postIdentifiers,
        csvCreatorsList,
        campaignForm: {
            brand: { name: brand_name },
            name,
            cost,
            date: { fromDate: start_date, toDate: end_date },
        },
    } = campaignTrackingWarehouse;
    const isStoreFetched = useRef(true)
    const campaignFormData = {
        name,
        cost,
        start_date: moment.utc(start_date).format("YYYY-MM-DDT00:00:00"),
        end_date: moment.utc(end_date).format("YYYY-MM-DDT00:00:00"),
        created_at: moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
    };

    useEffect(() => {
        const { hashtags, keywords, mentions } = getIdentifiers(postIdentifiers);
        setHasUnsavedContent(
            (trackedCreators?.filter(getNonDeletedCreators).length !== 0 || csvCreatorsList.length!==0)
            && (!isEmpty(hashtags) || !isEmpty(keywords) || !isEmpty(mentions)));
    }, [postIdentifiers, trackedCreators]);

    function onBack() {
        dispatchCampaignTrackingWarehouse({
            type: SET_TRACKED_CREATORS,
            payload: [],
        });
        dispatchCampaignTrackingWarehouse({
            type: SET_POST_IDENTIFIERS,
            payload: null,
        });
        dispatchCampaignTrackingWarehouse({
            type: SET_CSV_CREATORS_LIST,
            payload: [],
        });
        dispatchCampaignTrackingWarehouse({
            type: SET_PROFILES_CSV,
            payload: null,
        });
        navigate(-1);
    }

    let header, tabBox = null, trackPostsSection, measureRoiSection = null;

    // Create flow variant
    if (isEmpty(campaignId)) {
        header = <CampaignPageHeader campaignData={campaignFormData} onBack={onBack} campaignBrandName={brand_name}/>;
        tabBox = (
            <Box className="page-tab-switch-main-div">
                <TabSwitch
                    handleTabChange={(ev, value) => {
                        setPageCurrTab(value);
                    }}
                    aria-label="icon position tabs example"
                    currentTab={pageCurrTab}
                    tabs={campaignPageTabs}
                    height="55px"
                    width="fit-content"
                    variant={"underline"}
                />
            </Box>
        );
        trackPostsSection = (
            <TabPanel index={campaignPageTabValues.trackPosts} value={pageCurrTab}
                sx={{ margin: "0px", padding: "0px" }}>
                <TrackedPostsMainContent
                    campaignId={campaignId}
                    entrySource={ADD_CREATORS_ENTRY_SOURCE.CREATE_CAMPAIGN}
                    hasUnsavedContent={hasUnsavedContent}
                />
            </TabPanel>
        );
        measureRoiSection = (
            <TabPanel index={campaignPageTabValues.measureROI} value={pageCurrTab}
                      sx={{ margin: "0px", padding: "0px" }}>
                <ConnectShopifyMainContent campaignId={campaignId}
                    entrySource={ADD_CREATORS_ENTRY_SOURCE.CREATE_CAMPAIGN}
                    isStoreFetched={isStoreFetched}
                    shopifyStores={shopifyStores}
                    setShopifyStores={setShopifyStores}
                />
            </TabPanel>
        );
    } else {
        header = (
            <Box>
                {" "}
                <i className="ri-arrow-left-line back-icon" onClick={onBack}></i>
            </Box>
        );
        trackPostsSection = (
            <TrackedPostsMainContent
                campaignId={campaignId}
                entrySource={ADD_CREATORS_ENTRY_SOURCE.EDIT_CAMPAIGN}
                hasUnsavedContent={hasUnsavedContent}
            />
        );
    }

    return (
        <div>
            {header}
            {tabBox}
            {trackPostsSection}
            {measureRoiSection}
        </div>
    );
}
