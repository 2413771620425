import { getBasicAuthInstance } from "../axiosInstance";
import { getClientIDAndSecret, isEmpty } from "../../utils/util";
import {
  URL_GET_BRANDS,
  URL_GET_INTERESTS,
  URL_GET_LANGUAGES,
  URL_GET_FILTERS_PAYLOAD,
  URL_GET_PROFILE_ANALYTICS,
  URL_GET_BRAND_SAFETY,
  URL_GET_RECENT_SEARCHES,
  currentConnectURL,
  URL_GET_FEATURED_SEARCHES,
  URL_POST_FILTERS_SEARCH,
  URL_SEARCH_RESULT_EXPORT,
  URL_EXPORT_PROFILE_ANALYTICS,
  URL_GET_LOCATION_BY_ID,
  URL_USERS_HANDLE,
  URL_TOPIC_TAGS,
  URL_GET_ALL_CREATOR_LIST,
  URL_GET_FAVORITE_LIST,
  URL_SEARCH_RESULT_ASYNC_EXPORT,
  cgfAPIPrefix,
  currentDashboardURL,
} from "../endpoints";
import axiosInstance from "../axiosInstance";

/**
 * Gets profile analytics using the provided environment, warehouse, and payload.
 *
 * @param {Object} environment - The environment to use.
 * @param {Object} warehouse - The warehouse to use.
 * @param {Object} payload - The payload to use. - {identifier: string, workplatform: string}
 * @returns {Promise} A promise that resolves to the profile analytics.
 * @throws {Error} If an error occurs while getting the profile analytics.
 */
const getProfileAnalytics = async ({ payload }) => {
  try {
    let response = await axiosInstance.post(`${cgfAPIPrefix}/${URL_GET_PROFILE_ANALYTICS}`, payload);
    return response.data;
  } catch (e) {
    throw e;
  }
};

const postBrandSafety = async ({ payload }) => {
  try {
    let response = await axiosInstance.post(`${cgfAPIPrefix}${URL_GET_BRAND_SAFETY}`, payload);
    return response.data;
  } catch (e) {
    throw e;
  }
};


const getFiltersPayload = async () => {
  try {
    let response = await axiosInstance.get(`${cgfAPIPrefix}/${URL_GET_FILTERS_PAYLOAD}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

const postFiltersSearch = async ({ payload }) => {
  try {
    let response = await axiosInstance.post(`${cgfAPIPrefix}/${URL_POST_FILTERS_SEARCH}`, payload);
    return response?.data;
    // return filterSearchRes.data.slice(payload.offset, payload.offset + payload.limit);
    // return { languages: languages };
  } catch (e) {
    throw e;
  }
};
const getLanguages = async () => {
  try {
    let response = await axiosInstance.get(`${cgfAPIPrefix}/${URL_GET_LANGUAGES}`);
    return { languages: response.data.languages };
  } catch (e) {
    throw e;
  }
};

const getInterests = async () => {
  try {
    let response = await axiosInstance.get(`${cgfAPIPrefix}/${URL_GET_INTERESTS}`);
    return { interests: response.data.interests };
    // return response.data;
  } catch (e) {
    throw e;
  }
};
const getBrands = async () => {
  try {
    let response = await axiosInstance.get(`${cgfAPIPrefix}/${URL_GET_BRANDS}`);
    return { brands: response.data.brands };
    // return response.data;
  } catch (e) {
    throw e;
  }
};
const getLocationByID = async ({ id }) => {
  try {
    let response = await axiosInstance.get(`${cgfAPIPrefix}/${URL_GET_LOCATION_BY_ID}/${id}`);
    return response.data;
    // return response.data;
  } catch (e) {
    throw e;
  }
};

const getRecentSearches = async ({ workplatformId, creatorSearchType, limit = 5 }) => {
  try {
    let response = await axiosInstance.get(
      `${cgfAPIPrefix}/${URL_GET_RECENT_SEARCHES}?work_platform_id=${workplatformId}&search_type=${creatorSearchType}&limit=${limit}`
    );
    return response.data;
    // return { languages: languages };
  } catch (e) {
    throw e;
  }
};

const getFeaturedSearches = async ({ workplatformId, limit = 10 }) => {
  try {
    let response = await axiosInstance.get(`${cgfAPIPrefix}/${URL_GET_FEATURED_SEARCHES}?work_platform_id=${workplatformId}&limit=${limit}`);
    return response.data;
    // return { languages: languages };
  } catch (e) {
    throw e;
  }
};

const exportProfileAnalytics = async ({ payload }) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const options = {
      headers: headers,
    };
    let response = await axiosInstance.post(`${cgfAPIPrefix}/${URL_EXPORT_PROFILE_ANALYTICS}`, payload, options);
    return response;
  } catch (e) {
    throw e;
  }
};

const postCreatorSearchResultsExport = async ({ payload }) => {
  try {
    let response = await axiosInstance.post(`${cgfAPIPrefix}/${URL_SEARCH_RESULT_EXPORT}`, payload);
    return response;
  } catch (e) {
    throw e;
  }
};

/**
 * Retrieves user handles based on search query, work platform id, and type.
 *
 * @param {object} params - An object containing the search query, work platform id, and type.
 * @param {string} searchQuery - The search query used to find user handles.
 * @param {string} work_platform_id - The work platform id used to filter user handles.
 * @param {string} type - The type of user handle to retrieve.
 * @return {Promise<Array>} - An array of user handles that match the search query, work platform id, and type.
 * @throws {Error} - Throws an error if unable to retrieve user handles.
 */
const getUsersHandles = async ({ searchQuery, workplatformId, type, newCancelToken, is_search, limit }) => {
  const config = {};
  if (newCancelToken) {
    config.cancelToken = newCancelToken.token;
  }
  try {
    const url = new URL(`${currentDashboardURL}${cgfAPIPrefix}/${URL_USERS_HANDLE}`);
    url.searchParams.append("query_text", searchQuery);
    url.searchParams.append("work_platform_id", workplatformId);
    url.searchParams.append("type", type);
    url.searchParams.append("is_search", is_search);
    !isEmpty(limit) && url.searchParams.append("limit", limit);

    let response = await axiosInstance.get(url.href, config);
    return response.data;
  } catch (e) {
    throw e;
  }
};
const getUserLocations = async ({ searchQuery, newCancelToken, workplatformId }) => {
  const config = {};
  if (newCancelToken) {
    config.cancelToken = newCancelToken.token;
  }
  try {
    const response = await axiosInstance.get(
      `${cgfAPIPrefix}/${URL_GET_LOCATION_BY_ID}?search_string=${searchQuery}&work_platform_id=${workplatformId}&limit=50`,
      config
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getTopicTags = async ({ searchQuery, newCancelToken, workplatformId }) => {
  const config = {};
  if (newCancelToken) {
    config.cancelToken = newCancelToken.token;
  }
  try {
    const limit = 50;
    const url = new URL(`${currentDashboardURL}${cgfAPIPrefix}/${URL_TOPIC_TAGS}`);
    url.searchParams.append("identifier", searchQuery);
    url.searchParams.append("work_platform_id", workplatformId);
    !isEmpty(limit) && url.searchParams.append("limit", limit);

    let response = await axiosInstance.get(url.href, config);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

const removeRecentSearchCard = async ({ id }) => {
  try {
    let response = await axiosInstance.delete(`${cgfAPIPrefix}/${URL_GET_RECENT_SEARCHES}?recent_creator_search_id=${id}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

const getAllCreatorList = async (platform_username = "", work_platform_id = "") => {
  let getAllListsURL = URL_GET_ALL_CREATOR_LIST;
  if (!isEmpty(platform_username) && !isEmpty(work_platform_id)) {
    getAllListsURL = `${getAllListsURL}?platform_username=${platform_username}&work_platform_id=${work_platform_id}`;
  }
  try {
    let response = await axiosInstance.get(`${cgfAPIPrefix}/${getAllListsURL}`);
    return response?.data;
  } catch (e) {
    throw e;
  }
};
const getAllCreatorsInList = async ({ listId }) => {
  try {
    let response = await axiosInstance.get(`${cgfAPIPrefix}/${URL_GET_ALL_CREATOR_LIST}/${listId}/creators`);

    return response?.data;
  } catch (e) {
    throw e;
  }
};

const getAllFavouriteCreators = async () => {
  try {
    let response = await axiosInstance.get(`${cgfAPIPrefix}/${URL_GET_ALL_CREATOR_LIST}/favourite`);

    return response?.data;
  } catch (e) {
    throw e;
  }
};

export const deleteCreatorsLists = async ({ ids }) => {
  try {
    const response = await axiosInstance.delete(`${cgfAPIPrefix}/${URL_GET_ALL_CREATOR_LIST}`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ids: ids,
      },
    });
    return response?.data;
  } catch (e) {
    throw e;
  }
};
export const deleteCreatorsFromLists = async ({ listId, creatorIds: ids }) => {
  try {
    const response = await axiosInstance.delete(`${cgfAPIPrefix}/${URL_GET_ALL_CREATOR_LIST}/${listId}/creators`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ids: ids,
      },
    });
    return response?.data;
  } catch (e) {
    throw e;
  }
};
export const deleteCreatorsFromFavourites = async ({ creatorIds: ids }) => {
  try {
    const response = await axiosInstance.delete(`${cgfAPIPrefix}/${URL_GET_ALL_CREATOR_LIST}/favourite`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        ids: ids,
      },
    });
    return response?.data;
  } catch (e) {
    throw e;
  }
};

const createList = async ({ payload }) => {
  try {
    let response = await axiosInstance.post(`${cgfAPIPrefix}/${URL_GET_ALL_CREATOR_LIST}`, payload);
    return response;
  } catch (e) {
    throw e;
  }
};

const exportFavouriteList = async ({ payload }) => {
  try {
    let response = await axiosInstance.post(`${cgfAPIPrefix}/${URL_GET_ALL_CREATOR_LIST}/favourite/export`, payload);
    return response;
  } catch (e) {
    throw e;
  }
};

const addCreatorToFavoriteList = async ({ payload }) => {
  try {
    let response = await axiosInstance.post(`${cgfAPIPrefix}/${URL_GET_FAVORITE_LIST}`, payload);
    return response;
  } catch (e) {
    throw e;
  }
};

const exportCreatorList = async ({ listId, payload }) => {
  try {
    let response = await axiosInstance.post(`${cgfAPIPrefix}/${URL_GET_ALL_CREATOR_LIST}/${listId}/export`, payload);
    return response;
  } catch (e) {
    throw e;
  }
};

const addCreatorToList = async ({ listId, payload }) => {
  try {
    let response = await axiosInstance.post(`${cgfAPIPrefix}/${URL_GET_ALL_CREATOR_LIST}/${listId}/creators`, payload);
    return response;
  } catch (e) {
    throw e;
  }
};

const postCreatorSearchResultsAsyncExport = async ({ payload }) => {
  try {
    let response = await axiosInstance.post(`${cgfAPIPrefix}/${URL_SEARCH_RESULT_ASYNC_EXPORT}`, payload);
    return response;
  } catch (e) {
    throw e;
  }
};



export {
  getLanguages,
  getInterests,
  getBrands,
  addCreatorToList,
  getFiltersPayload,
  getRecentSearches,
  getFeaturedSearches,
  postFiltersSearch,
  getProfileAnalytics,
  postCreatorSearchResultsExport,
  exportProfileAnalytics,
  getLocationByID,
  getUsersHandles,
  getUserLocations,
  removeRecentSearchCard,
  getAllCreatorList,
  createList,
  addCreatorToFavoriteList,
  getAllCreatorsInList,
  getAllFavouriteCreators,
  exportFavouriteList,
  exportCreatorList,
  postCreatorSearchResultsAsyncExport,
  postBrandSafety
};
