import React, { useEffect, useState } from 'react';
import "./Measurement.scss";
import { docs, productsTabText, profilesTabText } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import { Icons, ProductPageHeader, ProductsCard, TabSwitch, Typography } from "../../components/index";
import { getMeasurementProductsList, getProduct } from '../../api/products/products';
import { isEmpty } from "../../utils/util";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (<Typography>{children}</Typography>)}
        </div>
    );
}

export const measurementTabValues = {
    products: productsTabText,
    profiles: profilesTabText,
};

const Measurement = () => {
    const navigate = useNavigate();
    const [pageCurrTab, setPageCurrTab] = useState(measurementTabValues.products);
    const [product, setProduct] = useState({});
    const [isProductLoading, setProductLoading] = useState(false);
    const [productList, setProductList] = useState([]);
    const measurementsTabs = [{
        label: measurementTabValues.products,
    }];

    useEffect(() => {
        const urlParts = window.location.pathname.replace(/\/+$/, '').split("/");
        const productId = urlParts[urlParts.length - 1];
        if (!isEmpty(productId)) {
            setProductLoading(true);
            getProduct(productId).then(setProduct).finally(() => setProductLoading(false));
        }
        getMeasurementProductsList().then(setProductList);
    }, []);

    return (
        <div>
            <div className='measurement-back-icon'>
                <i className="ri-arrow-left-line back-icon" onClick={() => {
                    navigate('/developers/products');
                }}></i>
            </div>
            <ProductPageHeader
                isLoading={isProductLoading}
                title={product?.title}
                additionalSubtitle={product?.description}
                docsLink={product?.pathToPage}
                buttonLabel={docs}
            />
            <div>
                <div className='measurement-page-tabs-container'>
                    <TabSwitch
                        handleTabChange={(ev, value) => {
                            setPageCurrTab(value);
                        }}
                        aria-label="icon position tabs example"
                        currentTab={pageCurrTab}
                        tabs={measurementsTabs}
                        height="55px"
                        width="fit-content"
                        variant={"underline"}
                    />
                </div>
            </div>
            <TabPanel className="measurement-products-tab" index={measurementTabValues.products} value={pageCurrTab}>
                {productList && <div className='measurement-products-data'>
                    {productList?.map((product, index) => (
                        <ProductsCard
                            key={index}
                            product={product}
                        />
                    ))}
                </div>}

            </TabPanel>
        </div>
    );
};

export default Measurement;
