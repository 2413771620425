import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import Icons from "../Icons/Icons";
import InvertedPrimaryButton from "../InvertedPrimaryButton/InvertedPrimaryButton";
import "./OverlayFilterBtn.scss";
const OverlayFilterBtn = ({ label, onClick, Filter, isSelected }) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange} classes={{ root: "overlay-filter-accordion-container" }} disableGutters={true}>
      <AccordionSummary classes={{ root: "overlay-filter-accordion-summary", content: "overlay-filter-accordion-summary-content" }}>
        <InvertedPrimaryButton
          className={`overlay-filter-btn ${isSelected ? "active" : ""} ${expanded ? "active" : ""}`}
          icon={isSelected ? <Icons.tick_icon /> : <Icons.add_icon />}
          label={label}
          onClick={() => {}}
        />
      </AccordionSummary>
      <AccordionDetails classes={{ root: "overlay-filter-accordion-details" }}>{Filter}</AccordionDetails>
    </Accordion>
  );
};

export default OverlayFilterBtn;
