import { RESET_PASSWORD_NEW_PASSWORD_SUBMIT_FAILURE, RESET_PASSWORD_NEW_PASSWORD_SUBMIT_SUCCESS, trackEvent } from "../../analytics";

const setNewPassword = ({ password, passwordResponseHandler, analytics }) => {
  try {
    window.addEventListener("message", passwordResponseHandler, false);
    window.top.postMessage(
      {
        reset_insightiq_password: password,
      },
      "*"
    );
    trackEvent(RESET_PASSWORD_NEW_PASSWORD_SUBMIT_SUCCESS);
    return new Promise((resolve) => setTimeout(resolve, 2000));
  } catch (e) {
    console.log(e);
    trackEvent(RESET_PASSWORD_NEW_PASSWORD_SUBMIT_FAILURE, { failure_reason: e });
    throw e;
  }
};

export { setNewPassword };
