import { message } from "antd";

export const useToast = () => {
  return (content) => {
    message.success({
      content: content,
      style: {
        marginTop: "5vh",
      },
      icon: <></>,
      duration: 1.5,
      maxCount: 1,
    });
  };
};
