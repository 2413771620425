import React, { useState, useRef, useEffect, useContext } from "react";
import Icons from "../Icons/Icons";
import "./FilterWrapper.scss";
import { PropTypes } from "prop-types";
import { Tooltip } from "@material-ui/core";
import { CreatorDiscoveryWarehouseContext } from "../../storage/context";
import { SET_SHOULD_RENDER_TRUE, UPDATE_LAST_INTERACTED_FILTER } from "../../storage/reducers/types";
import { CONTACT_TYPE_FILTER_KEY } from "../../constants/creatorDiscovery";

const FilterWrapper = ({
  filterComponent,
  filterHeader,
  isSelected = false,
  width,
  height,
  isPrimaryFilter,
  id,
  type,
  filterDescription,
  filterKey,
  lastInteractedFilter,
  isOverlayOpen,
  isSuperPrimaryFilter,
  removeParentContainer,
}) => {
  const [isOpen, setIsOpen] = useState(lastInteractedFilter === filterKey);
  const [isValueChanged, setIsValueChanged] = useState(isSelected);
  const [selectedFilterValue, setSelectedFilterValue] = useState({});
  const filterContainerRef = useRef(null);
  const { dispatchCreatorDiscoveryWarehouse } = useContext(CreatorDiscoveryWarehouseContext);

  const isActiveRef = useRef(isOpen);

  isActiveRef.current = isOpen;

  function hideDropDownOnOuterClick(ev) {
    ev.stopPropagation();
    if (isActiveRef.current === false) {
      return;
    }

    if (filterContainerRef.current.contains(ev.target)) {
      return;
    }
    // console.log(filterContainerRef, ev.target);
    setIsOpen(false);

    dispatchCreatorDiscoveryWarehouse({
      type: UPDATE_LAST_INTERACTED_FILTER,
      payload: {
        filterKey: filterKey,
        state: false,
      },
    });

    dispatchCreatorDiscoveryWarehouse({
      type: SET_SHOULD_RENDER_TRUE,
    });
  }

  const handleGetFilterValue = (value) => {
    setSelectedFilterValue({ ...selectedFilterValue, [type]: { selectedValue: value } });
  };

  useEffect(() => {
    document.addEventListener("click", hideDropDownOnOuterClick);

    return () => {
      document.removeEventListener("click", hideDropDownOnOuterClick);
    };
  }, []);

  useEffect(() => {
    const STATIC_WIDTH_TO_REMOVE = 403;
    const element = document.querySelector('.filter-without-wrapper');
    const item = element.querySelector('.api-searchable-dropdown').querySelector('.my-searchable-textfield');
    let isTextfieldFocused = false;

    const updateWidth = () => {
      const containerWidth = document.querySelector(".creator-search-content-container").offsetWidth;
      item.style.width = `${containerWidth - STATIC_WIDTH_TO_REMOVE}px`;
    };

    updateWidth();
  
    window.addEventListener('resize', updateWidth);
  
    function handleMouseOver() {
        item.style.outline = '2px solid var(--main-primary)';
    }
  
    function handleFocusIn() {
      isTextfieldFocused = true;
      item.style.outline = '2px solid var(--main-primary)';
    }
  
    function handleFocusOut() {
      isTextfieldFocused = false;
      item.style.outline = '1px solid var(--neutrals-border-grey)';
    }
  
    function handleMouseOut() {
      if (!isTextfieldFocused) {
        item.style.outline = '1px solid var(--neutrals-border-grey)';
      }
    }
  
    element.addEventListener('mouseover', handleMouseOver);
    element.addEventListener('focusin', handleFocusIn);
    element.addEventListener('focusout', handleFocusOut);
    element.addEventListener('mouseout', handleMouseOut);
  
    return () => {
      element.removeEventListener('mouseover', handleMouseOver);
      element.removeEventListener('focusin', handleFocusIn);
      element.removeEventListener('focusout', handleFocusOut);
      element.removeEventListener('mouseout', handleMouseOut);
      window.removeEventListener('resize', updateWidth);
    };
  }, []);
  

  const handleOpenFilter = (ev) => {
    if (!isPrimaryFilter && !isSuperPrimaryFilter) ev.stopPropagation();
    // console.log(filterKey, isOpen);
    const popUp = document.querySelector(`#filter-primary-container-${id}`);
    const accordion = document.querySelector(`#filter-container-${id}`);
    if (popUp && popUp.contains(ev.target)) {
      return;
    }
    if (accordion && accordion.contains(ev.target)) {
      return;
    }
    setIsOpen(!isOpen);

    dispatchCreatorDiscoveryWarehouse({
      type: UPDATE_LAST_INTERACTED_FILTER,
      payload: {
        filterKey: filterKey,
        state: !isOpen,
      },
    });

    // call on close of filterwrapper !!isOpen is isOpen
    if (isOpen) {
      dispatchCreatorDiscoveryWarehouse({
        type: SET_SHOULD_RENDER_TRUE,
      });
    }
  };

  // Special Handling
  if (isSuperPrimaryFilter && removeParentContainer) {
    return (
      <div className="filter-without-wrapper" id={`filter-primary-container-${id}`}>
        {React.cloneElement(filterComponent, { isSuperPrimaryFilter: true })}
      </div>
    );
  }

  // Super Primary Filter UI
  if (isSuperPrimaryFilter) {
    return (
      <div
        ref={filterContainerRef}
        id={`primary-filters-wrapper-${id}`}
        className="super-primary-filters-wrapper"
        onClick={(e) => {
          handleOpenFilter(e);
          e.preventDefault();
        }}
      >
        <div className="filter-wrapper" id={`filter-wrapper-${id}`} style={{ padding: 0 }}>
          <div className="fliter-header">
            <div className="filter-heading" style={{ justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: "12px" }}>
                <div className={(isOpen || isValueChanged) && "filter-heading-text"}>{filterHeader}</div>
              </div>
              {isSuperPrimaryFilter && (
                <div
                  className="filter-icon-container"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenFilter(e);
                  }}
                >
                  {isOpen ? (
                    <i className="ri-arrow-up-s-line" style={{ color: "var(--main-primary)" }}></i>
                  ) : isValueChanged ? (
                    <i className="ri-check-line" style={{ color: "var(--main-primary)" }}></i>
                  ) : (
                    <i className="ri-arrow-down-s-line"></i>
                  )}
                </div>
              )}
            </div>
          </div>
          {isSuperPrimaryFilter && isOpen && !isOverlayOpen && (
            <div className="filter-primary-container" id={`filter-primary-container-${id}`}>
              {filterComponent}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={isPrimaryFilter ? "primary-filters-wrapper" : ""}
      id={`primary-filters-wrapper-${id}`}
      ref={filterContainerRef}
      // style={isPrimaryFilter ? { border: (isOpen || isValueChanged) && "1.5px solid var(--main-primary)" } : {}}
      style={isSuperPrimaryFilter ? { border: (isOpen || isValueChanged) && "1.5px solid var(--main-primary)" } : {}}
      onClick={(e) => {
        handleOpenFilter(e);
        e.preventDefault();
      }}
    >
      <div
        className="filter-wrapper"
        style={{ width: isPrimaryFilter ? "auto" : "280px", height: height, paddingBottom: isPrimaryFilter && "16px" }}
        id={`filter-wrapper-${id}`}
      >
        <div className="fliter-header" style={{ padding: !isPrimaryFilter && "7px 5px 7px 0px", marginBottom: !isPrimaryFilter && "8px" }}>
          <div
            className="filter-heading"
            style={
              !isPrimaryFilter
                ? {
                    color: "var(--neutrals-primary-grey)",
                    fontWeight: "500",
                    gap: "12px"
                    // justifyContent: "space-between",
                  }
                : {
                    justifyContent: "space-between",
                  }
            }
          >
            <div style={{ display: "flex", gap: "12px" }}>
              {!isPrimaryFilter && (
                <div
                  className="filter-icon-container"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenFilter(e);
                  }}
                >
                  {isOpen ? (
                    <i className="ri-add-line" style={{ color: "var(--main-primary)" }}></i>
                  ) : isValueChanged ? (
                    <i className="ri-check-line" style={{ color: "var(--main-primary)" }}></i>
                  ) : (
                    <i className="ri-add-line"></i>
                  )}
                </div>
              )}
              {/* <div className={!isPrimaryFilter && (isOpen || isValueChanged) && "filter-heading-text"}>{filterHeader}</div> */}
              <div className={(isOpen || isValueChanged) && "filter-heading-text"}>{filterHeader}</div>
            </div>
            {isPrimaryFilter && (
              <div
                className="filter-icon-container"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenFilter(e);
                }}
              >
                {isOpen ? (
                  <i className="ri-arrow-up-s-line" style={{ color: "var(--main-primary)" }}></i>
                ) : isValueChanged ? (
                  <i className="ri-check-line" style={{ color: "var(--main-primary)" }}></i>
                ) : (
                  <i className="ri-arrow-down-s-line"></i>
                )}
              </div>
            )}
            {!isPrimaryFilter && (
              <Tooltip placement="top-start" title={!isPrimaryFilter ? filterDescription : ""} color="#222838" zIndex={10000}>
                <div className="info-icon">
                  <i className="ri-information-line"></i>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        {isPrimaryFilter
          ? isOpen &&
            !isOverlayOpen && (
              <>
                <div
                  className="filter-primary-container"
                  id={`filter-primary-container-${id}`}
                  style={filterKey === CONTACT_TYPE_FILTER_KEY ? { all: "unset" } : {}}
                >
                  {filterComponent}
                </div>
              </>
            )
          : isOpen &&
            isOverlayOpen && (
              <>
                <div className="filter-container" id={`filter-container-${id}`} style={filterKey === CONTACT_TYPE_FILTER_KEY ? { all: "unset" } : {}}>
                  {filterComponent}
                </div>
                {/* <hr style={{ width: "100%", border: "1px solid #EBEBEF" }} /> */}
              </>
            )}
      </div>
    </div>
  );
};

FilterWrapper.propTypes = {
  filterComponent: PropTypes.element,
  filterHeader: PropTypes.string,
  prefilled: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  isPrimaryFilter: PropTypes.bool,
};

export default FilterWrapper;
