import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";
import { Colors } from "../../styles/colors";

function BarChartDistribution({ data, width, height, currentTab }) {
  if (!data) return;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="MuiTooltip-tooltip">
          <div className="label">{currentTab + " " + label}</div>
          <div className="desc">{Math.ceil(payload[0].value)}%</div>
        </div>
      );
    }

    return null;
  };

  return (
    <BarChart width={width} height={height} data={data}>
      <CartesianGrid stroke={Colors.neutralsBorderGrey} strokeDasharray="5" vertical={false} />
      <XAxis
        dataKey="name"
        tickLine={false}
        tick={{ fontSize: 12, fill: Colors.neutralsPrimaryGrey }}
        axisLine={{ stroke: Colors.neutralsBorderGrey, strokeWidth: 1 }}
      />
      <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 12, fill: Colors.neutralsPrimaryGrey }} />
      <Tooltip content={<CustomTooltip />} />
      <Bar dataKey="value" fill={Colors.accentCaribbeanGreen} maxBarSize={64} radius={[4, 4, 0, 0]} />
    </BarChart>
  );
}
export default BarChartDistribution;
