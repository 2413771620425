import React, { useState } from "react";
import { Route, useRouteMatch, Routes, Navigate } from "react-router-dom";
import CheckYourEmailNewPassword from "../pages/CheckYourEmailNewPassword/CheckYourEmailNewPassword";
import { LoginPage, RegistrationPage, EmailVerificationPage, SetNewPasswordPage, ResetPasswordPage, SetupGuidePage } from "../pages/index";

export default function UnAuthenticatedRoutes() {
  return (
    <Routes path="">
      <Route path="verification" element={<EmailVerificationPage />} />
      <Route path="set-password" element={<SetNewPasswordPage />} />
      <Route path="password-reset-verification" element={<CheckYourEmailNewPassword />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route path="sign-in" element={<LoginPage />} />
      <Route path="registration" element={<RegistrationPage />} index />
      <Route path="*" element={<Navigate to="registration" />} />
    </Routes>
  );
}
