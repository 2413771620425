import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MainLayout } from "../components";
import CreatorLinkageRoutes from "../routes/CreatorLinkageRoutes";
import CreatorDiscoveryRoutes from "../routes/CreatorDiscoveryRoutes";
import { WarehouseContext } from "../storage/context";
import CampaignTrackingRoutes from "../routes/CampaignTrackingRoutes";
import { TRIAL, campaignTrackingProductID, creatorLinkageProductID, creatorSearchProductID } from "../constants/constants";
export default function CreatorModule() {
  const {
    warehouse: { tenantAppProducts },
  } = useContext(WarehouseContext);

  // The condition is set in the order of the precedence of Creator Products
  const defaultRoute =
    tenantAppProducts[creatorSearchProductID] !== TRIAL
      ? "creator-search/"
      : tenantAppProducts[creatorLinkageProductID] !== TRIAL
      ? "connected-accounts/"
      : tenantAppProducts[campaignTrackingProductID] !== TRIAL
      ? "campaigns/"
      : "creator-search/";
  return (
    <MainLayout>
      <Routes path="">
        <Route path="connected-accounts/*" element={<CreatorLinkageRoutes />} />
        <Route path="creator-search/*" element={<CreatorDiscoveryRoutes />} />
        <Route path="campaigns/*" element={<CampaignTrackingRoutes />} />
        <Route path="*" element={<Navigate to={defaultRoute} />} />
      </Routes>
    </MainLayout>
  );
}
