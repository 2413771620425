import { trackEvent, TRACKING_UPGRADE_BANNER_CLICKED } from "../analytics";
import { convertTimeToLocale } from "../utils/util";
import { DATE_FORMAT, DATE_TIME_FORMAT_SMALL, MEETING_SCHEDULE_LINK } from "./constants";

export const trackingPage = {
  titleDesc: "Get detailed insights on any social media post",
  subTitle: "Analyze engagement stats, audience reaction, and more",
  inputPlaceholder: "Paste a post link (URL) from Instagram, TikTok, or YouTube  ⏎",
  buttonCTA: "Analyze",
};

export const performance = "Performance";
export const posts = "posts";
export const contentGridHeader = (postCount) => (postCount === 1) ? `${postCount} post` : `${postCount} posts`;
export const creatorAccounts = "Creator accounts";

export const URL_ERROR_MESSAGES = {
  URL_Validation: "We couldn't recognize the post link. Make sure it belongs to Instagram, YouTube, or TikTok and try again.",
};

export const yourCampaigns = "Your campaigns";
export const newCampaign = "New campaign";

export const posted = "Posted";
export const likes = "likes";
export const comments = "comments";
export const views = "views";
export const shares = "shares";
export const total = "Total";
export const average = "average";
export const engagement_rate = "Engagement rate";
export const total_engagement = "Total engagement";
export const estimated_reach = "Reach";
export const impressions = "Impressions";
export const view_post = "View post";
export const campaign_coming_soon_title = "Campaigns coming soon!";
export const campaign_coming_soon_desc =
  "Campaigns help easily manage and track multiple posts for a brand and to stay on top of their overall performance.";
export const perPost = "per post";
export const engagements = "engagements";

export const analysis_loading_msg = {
  title: `Hang tight!`,
  desc: "This might take a few seconds.",
};
export const estimated = "Estimated";

export const campaign_view_loading_msg = {
  title: `Getting posts details`,
  desc: "We're working behind the scenes. Please check back in about 5 minutes.",
};

export const getFormatedCampaignURL = (url) => {
  // Check if the URL contains "instagram" and the UTM source part
  if (url.includes("instagram") || url.includes("instagr.am")) {
    const index = url.indexOf("?");
    if (index !== -1) {
      return url.substring(0, index);
    }
    return url;
  }
  return url; // If the URL doesn't contain "instagram" or the UTM source part, return the original URL
};

export const somethingWentWrongMsgTitle = "Uh-oh, something is wrong on our side";
export const somethingWentWrongMsgDesc = (
  <span>
    {"Please try again in some time or "}
    <a href={"https://getphyllo.freshdesk.com/support/home"}>contact our support team</a>
    {"."}
  </span>
);
export const someExistenceErrorMsgTitle = "This post doesn't exist";
export const someExistenceErrorMsgDesc = "Please check the post URL and try again.";

export const campaignLoaderTexts = ["Getting post details", "Analyzing comments", "Calculating performance"];
export const campaignLoaderInterval = 1500;

export const campaignPageData = {
  createCampaign: {
    title: "Create a new campaign",
    addPost: {
      title: "Add posts",
      desc: "You can add, modify, or delete the posts later.",
    },
    buttonCTA: "Next",
  },
  campaignNameInput: "Campaign name",
  brandNameInput: "Brand name",
  editCampaign: {
    title: "Edit campaign",
    addPost: {
      title: "Posts",
    },
    buttonCTA: "Save changes",
  },
  postInputPlaceholder: "Paste one or more post links (URL) from Instagram, TikTok, or Youtube  ⏎",
  addPostButtonCTA: "Add",
};

export const addPostPageData = {
  addPostLinks: {
    addPost: {
      title: "Add post links",
      desc: "You can add, modify, or delete the posts later.",
    },
    buttonCTA: "Add posts to campaign",
  },
  campaignNameInput: "Campaign name",
  brandNameInput: "Brand name",
  editPostLinks: {
    addPost: {
      title: "Edit post links",
    },
    buttonCTA: "Save changes",
  },
  postInputPlaceholder: "Paste one or more post links (URL) from Instagram, TikTok, or Youtube  ⏎",
  addPostButtonCTA: "Add",
};

export const clearAll = "Clear all";

export const getTotalPostLinks = (postUrls) => {
  let invalidUrls = 0;
  for (let i = 0; i < postUrls.length; i++) {
    if (!postUrls[i].isValid) {
      invalidUrls++;
    }
  }
  if (invalidUrls > 0) {
    return { msg: `${invalidUrls} of ${postUrls.length} post links have issues and won’t be added to the campaign.`, invalidUrls: invalidUrls };
  } else {
    return { msg: `${postUrls.length} post links`, invalidUrls: invalidUrls };
  }
};

export const csvUploadContent = {
  title: "You can also upload a CSV file containing post links",
  desc: "Drag and drop or upload your file here",
  buttonCTA: "Upload CSV",
  fileUploaded: "File uploaded",
  removeFile: "Remove file",
};

export const dragAndDropFile = "or simply drag and drop your file here ";
export const dragAndDropButtonCTA = "Upload CSV";

export const getCSVfileDetails = (errorUrls, totalUrls) => {
  if (errorUrls > 0) {
    return `${errorUrls} out of ${totalUrls} post links have issues and won’t be added to the campaign.`;
  } else {
    return `${totalUrls} post links found in the file. `;
  }
};

export const contentPages = {
  contentView: "content-view",
  campaignContentView: "campaign-content-view",
};

export const csvUploadErrors = {
  fileTypeIncorrect: "File type is incorrect. Upload a .csv file.",
  emptyFile: "File is empty.",
  structureError: "File structure is incorrect. Use our ",
  csvLimitExceded: "100 post links per CSV file are allowed at once.",
};

export const ADD_NEW_POST_TO_CAMPAIGN = "Add new post to the campaign";

export const retry_later = {
  errorCode: "retry_later",
  title: "We are updating the post details.",
  desc: "Please check back after some time.",
};
export const privateProfile = {
  errorCode: "entity_is_private",
  title: "This post is set to private",
  desc: "Info for private posts is unavailable. Please check the post link and try again.",
};
export const privatePost = {
  errorCode: "profile_is_private",
  title: "This post belongs to a private profile",
  desc: "Please check the post link and try again.",
};
export const postNotSupported = {
  errorCode: "invalid_request_parameters",
  title: "Oops! We don't support this post type yet",
  desc: "Our team is hard at work to bring support for this post type soon.",
};

export const duplicate_campaign = "duplicate_campaign";

export const allPlatforms = "All Platforms";
export const PostTypes = {
  allTypes: "All types",
  video: "Video",
  post: "Post",
  reels: "Reels",
  shorts: "Shorts",
  feed: "Feed",
  image: "Image",
  story: "Story",
  reel: "Reel",
};

export const dublicateCampaginError = "A campaign with this name already exists. Please choose a unique campaign name.";

export const REMOVE_FROM_CAMPAIGN = "Remove from campaign";
export const EDIT_CAMPAIGN_DETAILS = "Edit campaign details";
export const ADD_TO_CAMPAIGN = "+ Add to Campaign";
export const EDIT = "Edit";
export const TOP_HEADER_INFO_UPDATED = (infoUpdatedAt, updateFrequencyHours) => `Info last updated on ${infoUpdatedAt}. Updates every ${updateFrequencyHours} hours.`;
export const TOP_HEADER_INFO_ERROR = (infoUpdatedAt) => `Failed to update info for this post. Info last updated on ${infoUpdatedAt}.`;

export const CAMPAIGN_ENDED_INFO_ERROR = (infoUpdatedAt) => `Campaign has ended. Info last updated on ${infoUpdatedAt}.`;

export const SYNC_STATUS = {
  NOT_SYNCED: "NOT_SYNCED",
  IN_PROGRESS: "IN_PROGRESS",
  SYNCED: "SYNCED",
  PARTIALLY_SYNCED: "PARTIALLY_SYNCED",
  FAILED: "FAILED",
};

export const TOP_HEADER_INFO_CAMPAIGN_TRIAL_MODE = (
  <>
    Create a campaign and explore our sample campaigns.{" "}
    <a
      onClick={() => trackEvent(TRACKING_UPGRADE_BANNER_CLICKED, { page_name: "landing page" })}
      href={MEETING_SCHEDULE_LINK}
      target="_blank"
      rel="noreferrer"
    >
      Contact us to upgrade
    </a>{" "}
    to create more campaigns!
  </>
);

export const TOP_HEADER_INFO_TRIAL_MODE_CAMPAIGN = (
  <>
    This is a sample campaign.{" "}
    <a
      onClick={() => trackEvent(TRACKING_UPGRADE_BANNER_CLICKED, { page_name: "campaign_page" })}
      href={MEETING_SCHEDULE_LINK}
      target="_blank"
      rel="noreferrer"
      style={{ textDecorationColor: "#ffffff" }}
    >
      <font color="#ffffff">Contact us to upgrade</font>
    </a>{" "}
    to create more campaigns!
  </>
);

export const TOP_HEADER_INFO_TRIAL_MODE_CAMPAIGN_CONTENT = (
  <>
    This is a sample post.{" "}
    <a
      onClick={() => trackEvent(TRACKING_UPGRADE_BANNER_CLICKED, { page_name: "content_page" })}
      href={MEETING_SCHEDULE_LINK}
      target="_blank"
      rel="noreferrer"
      style={{ textDecorationColor: "#ffffff" }}
    >
      <font color="#ffffff">Contact us to upgrade</font>
    </a>{" "}
    and analyze any post!
  </>
);

export const campaignPostUrlError =
  "We couldn't recognize the post link. Please make sure it belongs to Instagram, YouTube, or TikTok and try again.";
export const START_YOUR_FIRST_CAMPAIGN = "Start your first campaign!";
export const START_YOUR_FIRST_CAMPAIGN_MESSAGE =
  "Create a campaign to easily manage and track multiple posts for a brand and to stay on top of their overall performance.";
export const OOPS_POST_HAS_NO_COMMENTS = "Oops, the post has no comments";
export const OOPS_POST_HAS_NO_COMMENTS_MESSAGE =
  "We analyze the comments to understand the relevance and the sentiment behind the comments of the post by using AI.";
export const AUTOMATICALLY_ADD_TITLE = "Automatically add posts to this campaign";
export const SIMPLY_ADD_CREATOR_ACCOUNTS = "Simply add creator accounts and campaign keywords. We’ll do the rest.";
export const HAVE_POST_LINKS_HANDY = "Have post links handy?";
export const ADD_THEM_DIRECTLY_OR_CSV = "Add them directly or via CSV";

export const STEP = "Step";
export const AUTO_ADD_CREATORS_TITLE = "Select creator accounts";
export const AUTO_ADD_CREATOR_MESSAGE = "This will help us know which creators to fetch posts from.";
export const POST_IDENTIFIERS_TITLE = "Enter post identifiers";
export const POST_IDENTIFIERS_TITLE_MESSAGE =
  "Identifiers like keywords, hashtags, and mentions that will go in the creators’ posts. They will help us identify which posts to fetch.";
export const POST_IDENTIFIERS_PLACEHOLDER = "Enter keywords, #hashtags, @mentions  ⏎";

export const campaignDurationTitle = "Campaign duration";
export const campaignDurationDesc = "We actively update the campaign performance details in this duration.";

export const costInputPlaceholder = "Enter campaign cost";
export const usd = "$USD";

export const campaignCostInputTitle = "Campaign cost (optional)";
export const campaignCostInputDesc = "We use it to calculate performance metrics (cost per view, and more)";

export const campaignPageTabValues = {
  trackPosts: "Track posts",
  measureROI: "Measure ROI",
};
export const performanceTabValues = {
  social: "Social",
  monetary: "Monetary",
  comments: "Comments",
  roi: "ROI",
  salesFunnel: "Sales funnel",
};

export const cpe = {
  name: "Cost per engagement (CPE)",
  tooltip: "Calculated as cost of campaign / total engagement of campaign.",
};

export const cpm = {
  name: "Cost per mille (CPM)",
  tooltip: "Amount spent per 1000 impressions. Calculated as cost of the campaign * 1000 / total number of impressions of the campaign.",
};

export const cpv = {
  name: "Cost per view (CPV)",
  tooltip: "Calculated as cost of campaign / total views of campaign.",
};

export const tmv = {
  name: "Total media value (TMV)",
  tooltip: "Sum of values of each like, share, comment, view, and impression.",
};

export const editCreatorsAndKeywordsText = "Edit creators and keywords";
export const editPostLinksText = "Edit post links";
export const automaticallyAddPostsText = "Automatically add posts";
export const manuallyAddPostText = "Manually add post links";

export const IDENTIFIER_EXAMPLES = "e.g. #visitlondon, @townsquare";
export const IDENTIFIER_TYPES = {
  PROFILE_URL: "PROFILE_URL",
  ACCOUNT_ID: "ACCOUNT_ID",
};

export const ADD_CREATORS_ENTRY_SOURCE = {
  CREATE_CAMPAIGN: "CREATE_CAMPAIGN",
  EDIT_CAMPAIGN: "EDIT_CAMPAIGN",
  EDIT_CAMPAIGN_CAMPAIGNPAGE: "EDIT_CAMPAIGN_CAMPAIGNPAGE",
};
export const MANAGE = "Manage";
export const REMOVE_ACCOUNT_FROM_CAMPAIGN = "Remove account from campaign?";
export const REMOVE_ACCOUNT_FROM_CAMPAIGN_MESSAGE =
  "Once you remove the creator account from the campaign, we won’t fetch the details and posts for that creator account.";
export const PROFILE_ADD_STATUS = {
  NEW: "NEW",
  ADDED: "ADDED",
  TO_BE_REMOVED: "TO_BE_REMOVED",
};
export const GET_RESULTS = "Get results";
export const SEARCH_AND_ADD_ACCOUNTS = "Search and add accounts";
export const RECENTLY_WORKED_WITH_TITLE = "Or select creators who you recently worked with";
export const RECENTLY_WORKED_WITH_MESSAGE = "You can select multiple accounts from the lists";
export const SELECT_CREATOR_PAGE_TITLE = (campaignName) => `Select creator accounts for ${campaignName}`;
export const ADD_MORE_CREATOR_ACCOUNTS = "+ Add more creator accounts";
export const SIDE_DRAWER_TITLE = (count) => `Accounts part of the campaign (${count})`;
export const SELECT_CREATOR_PLACEHOLDER = "Enter username or handle";

export const topRelevantComments = "Top relevant comments";

export const audienceReportStates = {
  NOT_GENERATED: {
    title: "Discover the insights for audience who liked this post",
    desc: "Get in-depth audience insights - Discover detailed profiles and demographics of the post's admirers.",
    buttonCTA: "Get audience report",
  },
  IN_PROGRESS: {
    title: "Generating your audience insights report",
    desc: "Your audience insights report is being prepared. Please allow some time for it. We'll send it to your email once it's ready.",
    buttonCTA: "Generating report",
  },
  GENERATED: {
    title: "Your audience insights report is ready!",
    desc: "View to get in-depth audience insights - Discover detailed profiles and demographics of the post's admirers.",
    buttonCTA: "View audience report",
  },
  NOT_SUPPORTED: {
    title: "Discover in-depth audience insights",
    desc: "Contact us to get in-depth audience insights - Discover detailed profiles and demographics of the post's admirers.",
    buttonCTA: 'Contact us <i className="ri-arrow-right-line"></i>',
  },
  TRIAL_MODE: {
    title: "Discover the insights for audience who liked this post",
    desc: "Get in-depth audience insights - Discover detailed profiles and demographics of the post's admirers.",
    buttonCTA: "Get audience report",
  },
};

export const DurationTooltip = () => {
  return (
    <>
      <div>• Only content posted during the campaign duration will be considered.</div>
      <div>• Select a campaign start date ranging from the last month to future dates.</div>
      <div>• The campaign duration cannot exceed 60 days.</div>
      <div>• Once the campaign starts, the start date cannot be modified.</div>
      <div>• You can also manually add posts to the campaign after it starts.</div>
    </>
  );
};

export const campaignComingSoon = {
  title: "Campaign starts soon!",
  desc: "Share the generated links with your creators. We’ll show the ROI metrics from ",
};

export const TrackPostCampaignComingSoon = {
  title: "Campaign starts soon!",
  desc: "We’ll automatically start adding post and performance details from ",
};
export const getCampaignDateTooltip = (start_date, created_date) => {
  return `We’ll automatically start adding posts from ${convertTimeToLocale(start_date, DATE_FORMAT)}. 
Campaign created on ${convertTimeToLocale(created_date, DATE_TIME_FORMAT_SMALL)}.`;
};

export const NOT_GENERATED = "NOT_GENERATED";
export const IN_PROGRESS = "IN_PROGRESS";
export const GENERATED = "GENERATED";
export const NOT_SUPPORTED = "NOT_SUPPORTED";
export const TRIAL_MODE = "TRIAL_MODE";
export const SEARCH_SELECT_CREATOR_PLACEHOLDER = "Search by username, handle, or profile URL  ⏎";
export const SELECT = "Select";
export const DONE = "Done";
export const CREATE_CAMPAIGN = "Create campaign";
export const AUTO_ADD_STEP1_TEXT = (creatorCount) => `Added ${creatorCount} creator accounts`;
export const SELECTED_ACCOUNTS = (creatorCount) => `Selected accounts (${creatorCount})`;

export const unlockAccessPopupDetails = {
  exportReport: {
    header: "Upgrade to export results",
    description: "Share details of the campaign ROI with your team or clients.",
  },
  newCampaign: {
    header: "Upgrade to create a campaign",
    description: "Easily track multiple creators and posts, and stay on top of their overall performance.",
  },
  addPost: {
    header: "Upgrade to add post to campaigns",
    description: "Easily manage multiple posts for a brand and stay on top of their overall performance.",
  },
  editCampaign: {
    header: "Upgrade to edit campaigns",
    description: "Easily manage multiple posts for a brand and stay on top of their overall performance.",
  },
  removePost: {
    header: "Upgrade to remove post from campaigns",
    description: "Easily manage multiple posts for a brand and stay on top of their overall performance.",
  },
  managePosts: {
    header: "Upgrade to manage posts in campaigns",
    description: "Easily manage multiple posts for a brand and stay on top of their overall performance.",
  },
  addCreatorList: {
    header: "Upgrade to add creators lists",
    description: "Create lists to shortlist creators for a campaign, share with your team, or just to bookmark for later.",
  },
  exportPost: {
    header: 'Upgrade to export post report',
    description: 'Share the complete performance report of the post with your team.',
  },
  exportCampaign: {
    header: 'Upgrade to export report',
    description: 'Share the complete report of the campaign performance and post details with your team.',
  }
};

export const tooltip = {
  total_views: "Views are only available for videos.",
  shareOnTikTok: "Shares count available for TikTok posts only at the moment.",
  total_engagement: "The total number of interactions or actions taken by users, including likes, comments, and shares.",
  estimated_impressions: "The estimate count of times all posts in the campaign have been shown to users.",
  engagement_rate: "Average engagement rate per post.",
};

export const analysisPageTooltip = {
  engagement_rate:
    "The percentage of people who actively engaged with the post after seeing it. Calculated as total engagement divided by total followers.",
  estimated_reach: "The estimated number of people who have seen this post.",
  estimated_impressions: "The estimated number of times this post has been shown to users.",
  storyEstimatedImpressions: "The estimated number of times this story was seen by users",
  storyEngagementRate: "An estimated number of people who engaged with this story. Calculated based on followers, engagement on recent posts etc",
};

export const CONTENT_AUDIENCE_DEMOGRAPHICS = "CONTENT_AUDIENCE_DEMOGRAPHICS";

export const START_DATE = "Start date";
export const END_DATE = "End date";
export const NO_ACCOUNTS_SELECTED_TITLE = "No accounts selected";
export const NO_ACCOUNTS_SELECTED_MESSAGE = "Select accounts by uploading a CSV file, searching, or by picking from the list.";

export const defaultCampaignApiLimit = 10;

export const oneBillion = 1000000000;
export const MANAGE_POSTS = "Manage posts";
export const NO_CAMPAIGNS_FOUND = "We couldn't find any campaigns.";
export const NO_POSTS_FOUND = "We couldn't find any posts.";
export const NO_CREATORS_FOUND = "We couldn't find any creators.";
export const NO_DATA = 'No data';

export const ADD_POST_TO_CAMPAIGN = "Add post to campaign";
export const SEARCH_CAMPAIGN_NAME = "Search campaign name";
export const SAVE_CHANGES = "Save changes";
export const CREATE_NEW_CAMPAIGN = "Create new campaign";
export const MORE_OPTIONS = "More options";
export const SEARCH_CAMPAIGNS_BRANDS = "Search campaigns, brands ⏎";
export const INFO_ACTION_BANNER_EMPTY_TITLE = "Automatically analyze comments by adding the brand’s social accounts";
export const INFO_ACTION_BANNER_ADD_TITLE = (count, name) => `Added ${count} social accounts for ${name}`;
export const ADD_ACCOUNTS = "Add accounts";
export const ADD_BRAND_ACCOUNTS = "Add brand accounts";
export const EDIT_BRAND_ACCOUNTS = "Edit brand accounts";

export const ADD_BRAND_PROFILES_HEADER = (brandName) => `Add social accounts for ${brandName}`;
export const ADD_BRAND_PROFILES_DESCRIPTION = `Brand’s social accounts are used to get info like relevant comments, purchase intent, and audience sentiment for the posts using AI.`;
export const ADD_BRAND_PROFILES_ERROR = (platformName) => `Can't recognize this ${platformName} account link. Please try again.`;
export const INTERESTED = "Interested";
export const NOT_INTERESTED = "Not interested";
export const CAMPAIGN_BRAND_PROFILES_TITLE =
  "Add your brand's social accounts to get info like total relevant comments, purchase intent, and audience sentiment for the campaign.";
export const CAMPAIGN_BRAND_PROFILES_DONE_TITLE =
  "Thanks for adding your brand's social accounts. We'll update the comments analysis info within 24 hours.";
export const CAMPAIGN_BRAND_PROFILES_PARTIAL = (brandSocialsCount) =>
  `${3 - brandSocialsCount}/3 social accounts for your brand are missing. Add them now to get the complete picture across all platforms. `;
export const CreatorGridColumns = {
  ID: { header: 'ID', field: 'id' },
  CREATOR_ACCOUNT: { header: "Creator account", field: 'platform_username' },
  TOTAL_CLICKS: { header: "Total clicks", field: 'total_link_clicks' },
  TOTAL_ORDERS: { header: "Total orders", field: 'total_orders_placed' },
  TOTAL_SALES: { header: "Total sales", field: 'total_sales' },
  CREATOR_COST: { header: "Creator cost", field: 'cost' },
  ROI: { header: "ROI", field: 'return_on_investment' },
};

export const CreatorUtmGridHeaders = {
  CREATOR_ACCOUNT: "Creator",
  FULL_LINK: "Full link",
  SHORT_LINK: "Short link",
};

export const MEASURE_ROI_TITLE = "Start tracking your return on investment";
export const MEASURE_ROI_DESC = "Just connect your Shopify store and create links. We’ll do the rest.";

export const measureROISteps = {
  step1: {
    title: "Connect your Shopify store",
    desc: "We'll use Shopify data to capture metrics like sales, add to carts, checkouts etc. ",
    inputPlaceholder: "Shopify store link",
    buttonCTA: "Connect Shopify",
    sampleInput: "e.g. yourstore.myshopify.com",
  },
  step2: {
    title: "Create UTM links for creator accounts",
    desc: "Share these links with your creators and measure ROI. ",
    inputPlaceholder: "Shopify store link",
    buttonCTA: "+ Create UTM links",
  },
};

export const CREATE_UTM_LINKS_HEADER = "Create UTM links for creator accounts";
export const EDIT_UTM_LINKS_HEADER = "Edit UTM links for creator accounts";
export const CREATE_UTM_LINKS_DESC = `Campaign name:`;

export const create_utm_link = {
  subsectionHeading: "Which link do you want to track?",
  subsectionDesc:
    "Paste the link to a page, product, or collection from your Shopify website. This link will not be editable once the campaign starts.",
  inputLabel: "Link to track",
  buttonCTA: "Select creator accounts",
  selectCreatorHeader: "Select creator accounts to generate links",
  selectCreatorsDesc: "Links will be automatically generated for these creator accounts.",
};

export const creatorCostPageHeader = "Enter creator costs";
export const manageCostPageHeader = "Manage creator costs";
export const creator_cost_info_detail = {
  title: "Why does this matter?",
  desc: "We use it to show you ROI metrics at a creator account level and at a platform level.",
};

export const creator_accounts = "Creator accounts";
export const cost_usd = "Cost ($ USD)";
export const totalCostCountText = "Total creator cost: ";

export const will_do_later_text = "I’ll do it later";

export const COPY_TO_CLIPBOARD = "Copy to clipboard";
export const LINK_COPIED = "Link copied!";
export const UTM_LINK_TITLE_SUCCESS = "UTM links successfully created";
export const UTM_LINK_TITLE_MANAGE = "Manage UTM links";
export const UTM_LINK_SUBTITLE_SUCCESS = "Share these links with the creators to track ROI for each creator account.";
export const EDIT_UTM = "Edit UTM";
export const DOWNLOAD_UTM_LINKS = "Download UTM links";

export const ADD_SELECTED_ACCOUNTS = {
  action: "ADD_SELECTED_ACCOUNTS",
  text: "Add selected accounts to campaign",
};
export const CREATE_UTM_LINKS = {
  action: "CREATE_UTM_LINKS",
  text: "Create UTM links",
};

export const CREATE_UTM_LINKS_EDIT_FLOW = {
  action: "CREATE_UTM_LINKS_EDIT_FLOW",
  text: "Create UTM links",
};
export const CAMPAIGN_EDIT_FLOW = {
  TRACK_POSTS: "TRACK_POSTS",
  MEASURE_ROI: "MEASURE_ROI",
};

export const totalSales = "Total sales";
export const totalCreatorCost = "Total creator cost";
export const totalROI = "Total ROI";
export const ctr = "CTR (Click Through Rate)";
export const costLPV = "Cost per LPV";
export const averageOrder = "Average order value";
export const totalLinkClicks = "Total Link Clicks";
export const landingPageViews = "Landing Page Views";
export const totalAddToCarts = "Total add to carts";
export const totalCheckouts = "Total checkouts";
export const totalOrderPlace = "Total orders placed";
export const UTM_Campaign_title = "UTM Campaign";
export const MANAGE_UTM = "Manage UTM";

export const totalSalesTooltip = "Total value of the products purchased by users who clicked your links";
export const totalCreatorCostTooltip = "Total amount spent on creators part of the campaign.";
export const totalROITooltip =
  "Return on Investment for this campaign, also called Return on Ad Spend (ROAS). ROI = (Sales / Total creator cost) * 100.";
export const ctrTooltip = "Ratio of checkout initiations to link clicks expressed as a percentage.";
export const costLPVTooltip = "Cost spent for each successful landing page visit.";
export const averageOrderTooltip = "Average spend by users who clicked your links.";
export const totalLinkClickTooltip = "Number of times your UTM links were clicked";
export const landingPageViewsTooltip = "Number of users who viewed your site through the UTM link";
export const totalAddToCartsTooltip = "Number of users who visited your site through the UTM link and added at least one item to cart";
export const totalCheckoutsTooltip = "Number of users who visited your site through the UTM link and initiated checkout";
export const totalOrderPlaceTooltip = "Number of orders made by users who clicked your links";

export const MANAGE_CREATOR_DETAILS = "Manage creator details";
export const EDIT_CREATOR_ACCOUNTS = "Edit creator accounts";
export const EDIT_CREATOR_COSTS = "Edit creator costs";

export const setupGuideDetails = {
  title: "Welcome, ",
  subTitle: "Let’s install InsightIQ UTM Analytics",
  desc: "Follow the steps to ensure your theme extension is installed correctly for efficient ROI tracking.",
};

export const setupGuideSteps = {
  step1: {
    title: "Install theme extension",
    desc: "Open theme extension and make sure the script toggle is 'On'. Click ‘Save’ and come back to this screen once you are done.",
    buttonCTA: "Open theme extension",
  },
  step2: {
    title: "Verify theme extension",
    desc: "You should see an alert box saying that it was successfully verified. If not, repeat the first step.",
    buttonCTA: "Verify",
  },
  step3: {
    title: "Go to Insight IQ",
    desc: "Create tracking links and measure ROI data on InsightIQ.",
    buttonCTA: "Open InsightIQ",
  },
};

export const shopifyStoreWorkplatformId = "e910501e-d7b3-4bc7-a69e-ba1e240b7974";
export const insightIqSetupGuideRedirectUrl = "https://dashboard.insightiq.ai/setup-guide";

export const shopifyLinkErrorMessage = "Please enter a link that belongs to your Shopify store.";

export const allCreatorsAccounts = "All creator accounts";

export const creatorAccountSelected = "creator accounts selected";

export const invalidUrlMsg = "Please enter a valid link";

export const noCreatorAdded = "No creators added!";
export const startAddingCreator = "Start adding creator accounts to manage costs";

export const NUMBER_CREATOR_ACCOUNTS = (num) => (num === 1 ? `${num} creator account` : `${num} creator accounts`);

export const updateUTMLink = "Update UTM links";
export const createUTMLink = "Create UTM links";

export const CAMPAIGN_PROFILES_CREATED_VIA = {
  DIRECT: "DIRECT",
};

export const Sample = "Sample";

export const Add = "Add";

export const ITEMS_ADDED = (num) => `${num} items added`;

export const MEASURE_ROI_CAMPAIGN_ENDED = {
  title: "You didn’t measure ROI for this campaign",
  desc: "This campaign has ended. For your other campaigns, you can start measuring ROI by connecting Shopify.",
};
export const TRACK_POST_CAMPAIGN_ENDED = {
  title: "You didn’t track any posts for this campaign",
  desc: "This campaign has ended. For your other campaigns, you can start tracking posts to learn the content performance, purchase intent, audience sentiment, and more.",
};

export const checkExample = "Or check an example";

export const LAST_UPDATE_FAILED = "Last update failed";
export const CONTACT_US = "Contact us";
export const ContentGridColumns = {
  ID: { header: 'ID' },
  POSTED_ON: { header: "Posted on", field: "published_at" },
  LIKES: { header: "Likes", field: "like_count" },
  COMMENTS: { header: "Comments", field: "comment_count" },
  VIEWS: { header: "Views", field: "view_count" },
  ENGAGEMENT_RATE: { header: "Engagement rate", field: "engagement_rate" },
  ACTIONS: { header: "Actions", field: "actions" },
};

export const sampleAnalysePost = "https://www.instagram.com/p/CvPtbFvgD9g/";

export const totalRelevantComments = "total relevant comments";
export const totalPositiveComments = "total positive comments";
export const purchaseIntentCount = "comments showed purchase intent";

export const commentsAnalyzedText = (analysedComments) => {
  return `${analysedComments} comments analyzed. This doesn’t include replies to the comments.`;
};

export const uploadAccountList = "Upload CSV";
export const uploadAccountListDesc = "Upload a file with creator account links ";

export const searchSelectAccounts = "Search accounts";

export const selectAccountFromList = "Select accounts from your lists";

export const waysToSelectAccounts = "Use one or more ways to select accounts";

export const addPostCSVHeader = "post links";

export const creatorProfileLinkCSVHeader = "creator profile links";

export const invalidLink = "Incorrect link / couldn't find account";
export const issue = "Issue";

export const userAccounts = "userAccounts";

export const story = "STORY"
export const PaidPartnershipWith = "Paid partnership with "
export const dotSymbol = ' • '

export const collaboratorTooltipText = "This post was created in collaboration with the following accounts";
export const sampleReportModalDetails = {
  header: "Please note, this is a sample audience report",
  desc: "Since this is a sample campaign, audience details in this report belong to a sample post, not the post you just saw. You will get authentic audience reports in the campaigns you create.",
  buttonCTA: "Download report",
};

export const getStarted = "Get started";

export const sampleCsvUrls = [
  {
    url: "https://www.instagram.com/leomessi/",
  },
  {
    url: "https://www.instagram.com/@GMTK/",
  },
  {
    url: "https://www.instagram.com/mrbeast/",
  },
];

export const yourCreatorListsTitle = "Your creator lists";

export const listSelected = (listsCheckedCount) => {
  return listsCheckedCount > 1 ? "lists selected" : "list selected";
};
export const creatorsSelected = (creatorsCheckedCount) => {
  return creatorsCheckedCount > 1 ? "creators selected" : "creator selected";
};

export const clearSelection = "Clear selection";
;

export const getFractionsText = (numerator, denominator) => `${numerator}/${denominator} comments`;
