import React from "react";
import { FilterMenu } from "../../index";

export default function GridDropdown({ label, menuItems, onItemSelect, selectedValueText, rowRenderer }) {
    const items = menuItems.map((menuItem) => {
        return {
            onClick: () => onItemSelect(menuItem),
            isVisible: true,
        };
    });

    return (
        <FilterMenu
            variant={'single-select-custom-render'}
            menuItems={items}
            selectedValueText={selectedValueText}
            firstItem={label}
            rowRenderer={rowRenderer}
        />
    );
}
