import { Box } from "@material-ui/core";
import Typography from "../Typography/Typography";
import "./HeaderTopNav.scss";
import { TOP_BANNER_CLICKED, trackEvent } from "../../analytics";

const HeaderTopNav = ({ title, backgroundColor, textClass = "", ...otherProps }) => {
  return (
    <Box sx={{ backgroundColor }} {...otherProps} className="header-top-nav-container">
      <Typography
        className={`header-top-nav-title ${textClass}`}
        onClick={() => trackEvent(TOP_BANNER_CLICKED, { page_name: window.location.pathname.substring(1).toUpperCase() })}
      >
        {title}
      </Typography>
    </Box>
  );
};
export default HeaderTopNav;
