import {
  currentDashboardURL,
  URL_API_INDIVIDUAL_INVITE,
  URL_API_RESEND_INVITATION,
  URL_CREATOR_BULK_INVITE,
  URL_GET_ALL_INVITATIONS,
  URL_GET_CONNECTED_ACCOUNTS,
  URL_GET_CONNECTION_ATTEMPTS,
  URL_API_EXPORT_TO_CSV,
  phylloAPIPrefix,
} from "../endpoints";
import axiosInstance, { getBasicAuthInstance } from "../axiosInstance";
import { RECORDS_PER_PAGE, sort_by_keys } from "../../constants/constants";
import moment from 'moment-timezone';
import { getClientIDAndSecret } from "../sdkConnect/sdkConnectApi";

const getAllInvitations = async (offset, search_string = "", sort_desc = true, warehouse, work_platform_ids, account_status, dateRange) => {
  const url = new URL(`${currentDashboardURL}${phylloAPIPrefix}/${URL_GET_ALL_INVITATIONS}`);
  url.searchParams.append("offset", offset);
  url.searchParams.append("limit", RECORDS_PER_PAGE);
  !!search_string && url.searchParams.append("search_string", search_string);
  url.searchParams.append("sort_by", "created_at");
  url.searchParams.append("sort_desc", `${sort_desc}`);
  work_platform_ids && work_platform_ids.length > 0 && url.searchParams.append("work_platform_ids", work_platform_ids.join(","));
  account_status && account_status.length > 0 && url.searchParams.append("account_status", account_status.join(","));

  if (dateRange) {
    const { fromDate, toDate } = dateRange;
    if (fromDate) {
      url.searchParams.append("created_at_start_date", fromDate.toISOString(true).split("+")[0].replace("Z", ""));
    }
    if (toDate) {
      // To take the entire toDate into consideration for the filter
      // Also works for PLATFORM-3370
      toDate.set("hour", 23);
      toDate.set("minute", 59);
      toDate.set("second", 59);
      toDate.set("millisecond", 999);
      url.searchParams.append("created_at_end_date", toDate.toISOString(true).split("+")[0].replace("Z", ""));
    }
  }

  try {
    const response = await axiosInstance.get(url.href);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("No API credentials found");
    }
  } catch (error) {
    throw error.message;
  }
};

const sendIndividualInvite = async (name, email, environment) => {
  try {
    const response = await axiosInstance.post(
      `${currentDashboardURL}/${URL_API_INDIVIDUAL_INVITE}`,
      {
        name,
        email,
        environment: environment.toUpperCase(),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    return { status: error.response.status, message: error.message };
  }
};

const getConnectedAccounts = async (offset, search_string = "", sort_desc = true, warehouse, work_platform_ids, account_status, dateRange) => {
  try {
    const url = new URL(`${currentDashboardURL}${phylloAPIPrefix}/${URL_GET_CONNECTED_ACCOUNTS}`);
    url.searchParams.append("offset", offset);
    url.searchParams.append("limit", RECORDS_PER_PAGE);
    !!search_string && url.searchParams.append("search_string", search_string);
    url.searchParams.append("sort_by", "connected_at");
    url.searchParams.append("sort_desc", `${sort_desc}`);
    work_platform_ids && work_platform_ids.length > 0 && url.searchParams.append("work_platform_ids", work_platform_ids.join(","));
    account_status && account_status.length > 0 && url.searchParams.append("account_status", account_status.join(","));

    if (dateRange) {
      const { fromDate, toDate } = dateRange;
      if (fromDate) {
        url.searchParams.append("connected_at_start_date", fromDate.toISOString(true).split("+")[0].replace("Z", ""));
      }
      if (toDate) {
        // To take the entire toDate into consideration for the filter
        // Also works for PLATFORM-3370
        toDate.set("hour", 23);
        toDate.set("minute", 59);
        toDate.set("second", 59);
        toDate.set("millisecond", 999);
        url.searchParams.append("connected_at_end_date", toDate.toISOString(true).split("+")[0].replace("Z", ""));
      }
    }

    const response = await axiosInstance.get(url.href);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("No API credentials found");
    }
  } catch (error) {
    throw error.message;
  }
};

const postBulkInvites = async (file, environment) => {
  let response;
  try {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("environment", environment.toUpperCase());
    response = await axiosInstance.post(`${currentDashboardURL}/${URL_CREATOR_BULK_INVITE}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.info(response);
    return response;
  } catch (error) {
    console.error(error);
    return {
      status: error.response.status,
      message: error.message,
      res: error.response,
    };
  }
};

const resendInvitation = async (creatorInviteId, environment) => {
  try {
    const response = await axiosInstance.post(
      `${currentDashboardURL}/${URL_API_RESEND_INVITATION}/${creatorInviteId}/resend`,
      {
        environment: environment.toUpperCase(),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    throw error.message;
  }
};

const getConnectionAttempts = async (
  warehouse,
  limit,
  offset,
  search_string = "",
  sort_desc = true,
  core_schema_user_id,
  sort_by = sort_by_keys.created_at
) => {
  try {
    const url = new URL(`${currentDashboardURL}${phylloAPIPrefix}/${URL_GET_CONNECTION_ATTEMPTS}`);
    // url.searchParams.append("environment", environment?.toUpperCase());
    url.searchParams.append("offset", offset);
    url.searchParams.append("limit", limit);
    url.searchParams.append("user_id", core_schema_user_id);
    url.searchParams.append("sort_by", sort_by);
    url.searchParams.append("sort_desc", sort_desc);

    const response = await axiosInstance.get(url.href);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("No API credentials found");
    }
  } catch (error) {
    throw error.message;
  }
};

const profileExportToCSV = async (environment, creatorInviteId, accountId) => {
  try {
    const response = await axiosInstance.post(
      `${currentDashboardURL}/${URL_API_EXPORT_TO_CSV}`,
      {
        environment: environment.toUpperCase(),
        creator_invite_id: creatorInviteId,
        account_id: accountId,
        current_timestamp: moment().format("DD/MM/YYYY, HH:mm:ss"),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    throw error.message;
  }
};

export {
  getAllInvitations,
  getConnectedAccounts,
  postBulkInvites,
  sendIndividualInvite,
  resendInvitation,
  getConnectionAttempts,
  profileExportToCSV,
};
