import axiosInstance from "../axiosInstance";
import { currentDashboardURL, signUpEndpoint } from "../endpoints";
import { logUser, REGISTRATION_FAILURE, REGISTRATION_SUCCESS, trackEvent } from "../../analytics";

const signUpUser = async ({ firstName, lastName, companyName, email, password, consent, recaptcha, analytics }) => {
  try {
    const response = await axiosInstance.post(`${currentDashboardURL}/${signUpEndpoint}`, {
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      email,
      password,
      tnc_accepted: consent,
      recaptcha_value: recaptcha,
    });
    // 201 created
    if (response.status === 201) {
      logUser(email.toLowerCase(), response.email);
      trackEvent(REGISTRATION_SUCCESS, {
        first_name: firstName,
        last_name: lastName,
        company_name: companyName,
        email,
      });
      return response;
    }
    trackEvent(REGISTRATION_FAILURE, {
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      email,
      failure_reason: response?.data?.error,
    });
    throw new Error(response.statusText);
  } catch (e) {
    trackEvent(REGISTRATION_FAILURE, {
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      email,
      failure_reason: e?.response?.data?.error,
    });
    throw e.response.data.error;
  }
};

export { signUpUser };
