import React, { useState } from "react";

import { Background, Card, Typography, OTPInputField, PrimaryButton, TextLink, InputField, NavigationHeader, Icons } from "../../components";
import "./ResetPasswordPage.scss";
import { Form } from "antd";
import { EMPTY_REQUIRED_FIELD_MESSAGE, EMAIL_REGEX, INVALID_EMAIL_MESSAGE, pageTitle, getPageTitle } from "../../constants/constants";

import { resetPasswordApi } from "../../api/index";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import { AnalyticsContext, UserContext } from "../../storage/context";
import {
  RESET_PASSWORD_EMAIL_RESEND_CLICK,
  RESET_PASSWORD_EMAIL_RESEND_FAILURE,
  RESET_PASSWORD_EMAIL_RESEND_SUCCESS,
  RESET_PASSWORD_PAGE_VIEW,
  trackEvent,
} from "../../analytics";
import { useEffect } from "react";
export default function ResetPasswordPage() {
  // const [showOtpInput, setShowOtpInput] = useState(false);
  const navigate = useNavigate();
  const [formInstance] = Form.useForm();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleProceed = async () => {
    // if (showOtpInput === false) {
    //   setShowOtpInput(true);
    //   return;
    // }
    const { email } = formInstance.getFieldsValue();
    try {
      setIsButtonLoading(true);
      const response = await resetPasswordApi.resetPassword(email);
      if (response.status === 200) {
        formInstance.resetFields();
        return navigate("/password-reset-verification", {
          state: { email },
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsButtonLoading(false);
    }
  };
  useEffect(() => {
    trackEvent(RESET_PASSWORD_PAGE_VIEW);
  }, []);
  return (
    <Background className="reset-password-page">
      <Helmet>
        <title>{getPageTitle("RESET_PASSWORD_PAGE", "")}</title>
      </Helmet>
      <NavigationHeader isAuthenticated={false} />
      <div className="reset-password-container">
        <Form form={formInstance}>
          <Card className="reset-password-card">
            <div className="reset-card-header">
              <Icons.back_arrow onClick={() => navigate(-1)} className="icon-button" />
              <Typography h2 className="reset-password-title">
                Reset password
              </Typography>
            </div>
            <p className="reset-password-description">Enter your email and we’ll send a reset password link if the email is registered with us.</p>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: EMPTY_REQUIRED_FIELD_MESSAGE },
                {
                  pattern: EMAIL_REGEX,
                  message: INVALID_EMAIL_MESSAGE,
                },
              ]}
              // noStyle
            >
              <InputField
                inputLabel="email"
                // type="email"
                placeholder="Enter your registered email"
                // hidden={setShowOtpInput}
                // onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            {/* {showOtpInput && (
            <div className="otp-input-section">
              <OTPInputField
                labelName="code"
                containerProps={{
                  style: { marginTop: "20px", marginBottom: "12px" },
                }}
              />
              <TextLink to="/">Resend code</TextLink>
            </div>
          )} */}
            <Form.Item noStyle shouldUpdate>
              {/* Disabled button depends on the state of form input */}
              {() => (
                <PrimaryButton
                  label="Proceed"
                  className="reset-proceed-button"
                  onClick={handleProceed}
                  loading={isButtonLoading}
                  htmlType="submit"
                  disabled={!formInstance.isFieldsTouched(true) || formInstance.getFieldsError().filter(({ errors }) => errors.length).length > 0}
                />
              )}
            </Form.Item>
          </Card>
        </Form>
      </div>
    </Background>
  );
}
