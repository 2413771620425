import { Box, Chip, Grid } from "@mui/material";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING,
  SEARCH_ATTRIBUTES_TOOLTIP_TEXT,
  SEARCH_IDENTITY_ATTRIBUTES,
} from "../../constants/constants";
import { followerTypes, formatNumber, timeConvert } from "../../utils/profileHelpers";
import Icons from "../Icons/Icons";
import "./SearchProfileIdentity.scss";
import SearchProfileIdentitySkeleton from "./SearchProfileIdentitySkeleton";
import PerformanceMetric from "../PerformanceMetric/PerformanceMetric";
import { calculateEngagementRateDifference, capitalizeFirstLetter } from "../../utils/util";
import parse from "html-react-parser";

const SearchProfileIdentity = ({ workPlatformMapping, identityData, loading, majorityFollowerType, followerTypeActionHandler, credibilityScore, descriptionMapping = SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING }) => {
  const [currWorkPlatformMetrics, setCurrWorkPlatformMetrics] = useState(null);
  const [engagementRateChipData, setEngagementRateChipData] = useState({});
  const _ = require("lodash");
  useEffect(() => {
    if (identityData) {
      const work_platform_name = identityData.work_platform.name;
      if (workPlatformMapping.hasOwnProperty(work_platform_name)) {
        setCurrWorkPlatformMetrics(workPlatformMapping[work_platform_name]);
      }
      const [textValue, chipClass] = calculateEngagementRateDifference(identityData?.profile?.engagement_rate, identityData?.profile?.engagement_rate_histogram);
      setEngagementRateChipData({textValue:textValue, chipClass: chipClass})
    }
  }, [identityData]);

  if (loading) {
    return <SearchProfileIdentitySkeleton />;
  }

  return (
    <Box className="identity-main-container">
      <Box className="profile_heading">
        <div>Performance</div>
      </Box>
      <Box>
        <Box className="data_Grid">
          <Box className="data-grid-performance-box" display={"flex"} gap={"20px"}>
            {currWorkPlatformMetrics?.includes(SEARCH_IDENTITY_ATTRIBUTES.follower_count) && (
              <Box className="data-grid-performance" width={"260px"} height={"100px"} padding={"0px 102px 13px 0px"}>
                <PerformanceMetric
                  variant={"metricIcon"}
                  content={identityData.profile.follower_count !== null ? formatNumber(identityData.profile.follower_count) : "-"}
                  metricName={
                    <Box display={"flex"} gap={"8px"} flexDirection={"column"}>
                      <Box>{descriptionMapping.follower_count}</Box>
                      {currWorkPlatformMetrics?.includes(SEARCH_IDENTITY_ATTRIBUTES.follower_types) && majorityFollowerType != null && (
                          <Chip
                              label={credibilityScore + "% " + capitalizeFirstLetter(followerTypes[majorityFollowerType.name].label)}
                            classes={{ root: `metric-chip ${followerTypes[majorityFollowerType.name].class}` }}
                          />
                      )}
                    </Box>
                  }
                  metricIcon={<Icons.users_icon />}
                />
              </Box>
            )}
            {currWorkPlatformMetrics?.includes(SEARCH_IDENTITY_ATTRIBUTES.subscriber_count) && (
              <Box className="data-grid-performance" width={"260px"} height={"100px"} padding={"0px 102px 13px 0px"}>
                <PerformanceMetric
                  variant={"metricIcon"}
                  content={identityData.profile.subscriber_count !== null ? formatNumber(identityData.profile.subscriber_count) : "-"}
                  metricName={descriptionMapping.subscriber_count}
                  metricIcon={<Icons.users_icon />}
                />
              </Box>
            )}
            {currWorkPlatformMetrics?.includes(SEARCH_IDENTITY_ATTRIBUTES.engagement_rate) && (
              <Box className="data-grid-performance" width={"260px"} height={"100px"} padding={"0px 102px 13px 0px"}>
                <PerformanceMetric
                  content={
                    identityData.profile.engagement_rate !== null
                      ? identityData.profile.engagement_rate !== 0
                        ? (identityData.profile.engagement_rate * 100).toFixed(2) + "%"
                        : "-"
                      : "-"
                  }
                  metricName={
                    <div style={{display:"flex", gap:"8px", flexDirection:"column"}}>
                    <div>
                      {descriptionMapping.engagement_rate}{" "}
                      <Tooltip title={parse(SEARCH_ATTRIBUTES_TOOLTIP_TEXT.engagement_rate)} placement="top-start">
                        <i className="ri-information-line"></i>
                      </Tooltip>
                    </div>
                      {currWorkPlatformMetrics?.includes(SEARCH_IDENTITY_ATTRIBUTES.engagement_rate) && identityData?.profile?.engagement_rate_histogram != null && identityData.profile.engagement_rate !== 0 && (
                          <Chip
                              label={engagementRateChipData.textValue}
                            classes={{ root: `metric-chip ${engagementRateChipData.chipClass}` }}
                          />
                      )}
                    </div>
                  }
                />
              </Box>
            )}
            {currWorkPlatformMetrics?.includes(SEARCH_IDENTITY_ATTRIBUTES.sponsored_posts_performance) && (
              <Box className="data-grid-performance" height={"100px"} width={"260px"} padding={"0px 100px 13px 0px"}>
                <PerformanceMetric
                  content={
                    identityData.profile.sponsored_posts_performance !== null
                      ? identityData.profile.sponsored_posts_performance !== 0
                        ? (identityData.profile.sponsored_posts_performance * 100).toFixed(2) + "%"
                        : "-"
                      : "-"
                  }
                  metricName={
                    <div style={{ display: "flex", alignItems: "center", gap:"4px" }}>
                      {descriptionMapping.sponsored_posts_performance}{" "}
                      <Tooltip title={SEARCH_ATTRIBUTES_TOOLTIP_TEXT.sponsored_posts_performance} placement="top-start">
                        <i className="ri-information-line"></i>
                      </Tooltip>
                    </div>
                  }
                />
              </Box>
            )}
          </Box>
          <Box className="data-grid-performance-box" display={"flex"} gap={"20px"}>
            {currWorkPlatformMetrics?.includes(SEARCH_IDENTITY_ATTRIBUTES.average_likes) && (
              <Box className="data-grid-performance" width={"260px"} height={"58px"}>
                <PerformanceMetric
                  variant={"metricIcon"}
                  content={
                    identityData.profile.average_likes !== null
                      ? identityData.profile.average_likes !== 0
                        ? formatNumber(identityData.profile.average_likes)
                        : "-"
                      : "-"
                  }
                  metricName={descriptionMapping.average_likes}
                  metricIcon={<i className="ri-heart-3-line metric-icon"></i>}
                />
              </Box>
            )}
            {currWorkPlatformMetrics?.includes(SEARCH_IDENTITY_ATTRIBUTES.average_comments) && (
              <Box className="data-grid-performance" width={"260px"} height={"58px"}>
                <PerformanceMetric
                  variant={"metricIcon"}
                  content={
                    identityData.profile.average_comments !== null
                      ? identityData.profile.average_comments !== 0
                        ? formatNumber(identityData.profile.average_comments)
                        : "-"
                      : "-"
                  }
                  metricName={descriptionMapping.average_comments}
                  metricIcon={<i className="ri-chat-3-line metric-icon"></i>}
                />
              </Box>
            )}
            {currWorkPlatformMetrics?.includes(SEARCH_IDENTITY_ATTRIBUTES.average_views) && (
              <Box className="data-grid-performance" width={"260px"} height={"58px"}>
                <PerformanceMetric
                  variant={"metricIcon"}
                  content={
                    identityData.profile.average_views !== null
                      ? identityData.profile.average_views !== 0
                        ? formatNumber(identityData.profile.average_views)
                        : "-"
                      : "-"
                  }
                  metricName={descriptionMapping.average_views}
                  metricIcon={<i className="ri-eye-line metric-icon"></i>}
                />
              </Box>
            )}
            {currWorkPlatformMetrics?.includes(SEARCH_IDENTITY_ATTRIBUTES.average_reels_views) && (
              <Box className="data-grid-performance" width={"260px"} height={"58px"}>
                <PerformanceMetric
                  variant={"metricIcon"}
                  content={
                    identityData.profile.average_reels_views !== null
                      ? identityData.profile.average_reels_views !== 0
                        ? formatNumber(identityData.profile.average_reels_views)
                        : "-"
                      : "-"
                  }
                  metricName={descriptionMapping.average_reels_views}
                  metricIcon={<i className="ri-eye-line metric-icon"></i>}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchProfileIdentity;
