import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CREDENTIALS_GENERATE_FAILURE, CREDENTIALS_GENERATE_SUCCESS, trackEvent } from "../analytics";
import { tenantInfoApi } from "../api";
import { UserContext, WarehouseContext } from "../storage/context";
import { ADD_TENANT_INFORMATION } from "../storage/reducers/types";

export const useFetchTenantInformation = () => {
  const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
  const navigate = useNavigate();
  return async () => {
    try {
      if (!warehouse.environment.current) return;
      const data = await tenantInfoApi.getTenantInfo(warehouse.environment.current);
      dispatchWarehouse({
        type: ADD_TENANT_INFORMATION,
        payload: data,
      });
      trackEvent(CREDENTIALS_GENERATE_SUCCESS, { client_id: data[0]?.apps[0]?.id });
      return data;
    } catch (e) {
      console.log(e);
      trackEvent(CREDENTIALS_GENERATE_FAILURE, { failure_reason: e });
      navigate("/sign-in");
    }
  };
};
