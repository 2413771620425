import React from "react";
import "./Typography.scss";
import PropTypes from 'prop-types';

export default function Typography({
  children,
  h1 = false,
  h2 = false,
  h3 = false,
  h4 = false,
  ...otherProps
}) {
  if (h1) {
    return (
      <h1 className="typography-h1" {...otherProps}>
        {children}
      </h1>
    );
  }
  if (h2) {
    return (
      <h2 className="typography-h2" {...otherProps}>
        {children}
      </h2>
    );
  }
  if (h3) {
    return (
      <h3 className="typography-h3" {...otherProps}>
        {children}
      </h3>
    );
  }
  return (
    <h4 className="typography-h4" {...otherProps}>
      {children}
    </h4>
  );
}


Typography.propTypes = {
  children: PropTypes.node.isRequired,
  h1: PropTypes.bool,
  h2: PropTypes.bool,
  h3: PropTypes.bool,
  h4: PropTypes.bool
};

Typography.defaultProps = {
  h1: false,
  h2: false,
  h3: false,
  h4: false
};