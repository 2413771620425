import { RESET_PASSWORD_EMAIL_SUBMIT_FAILURE, RESET_PASSWORD_EMAIL_SUBMIT_SUCCESS, trackEvent } from "../../analytics";
import axiosInstance from "../axiosInstance";
import { currentDashboardURL, forgotPasswordEndpoint } from "../endpoints";

const resetPassword = async (email, analytics) => {
  try {
    const response = await axiosInstance.post(`${currentDashboardURL}/${forgotPasswordEndpoint}`, {
      email,
    });

    trackEvent(RESET_PASSWORD_EMAIL_SUBMIT_SUCCESS, { email });
    return response;
  } catch (e) {
    trackEvent(RESET_PASSWORD_EMAIL_SUBMIT_FAILURE, {
      email,
      failure_reason: e?.response?.data?.error,
    });
    console.log(e);
    throw e;
  }
};

export { resetPassword };
