import { ADD_ENVIRONMENT, ADD_USER_PROFILE, REMOVE_USER_PROFILE } from "./types";

const USER_PROFILE_DEFAULT_STATE = {
  user: {},
};

const userProfileReducer = (state, action) => {
  switch (action.type) {
    case ADD_USER_PROFILE:
      return {
        ...state,
        user: action.payload,
        // token: action.payload.token,
      };
    case REMOVE_USER_PROFILE:
      return {
        ...state,
        user: {},
      };
    default:
      return state;
  }
};

export { USER_PROFILE_DEFAULT_STATE, userProfileReducer };
