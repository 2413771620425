import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { emailVerificationApi } from "../../api/index";

import { Background, Card, Typography, OTPInputField, PrimaryButton, NavigationHeader, TextLink } from "../../components";
import "./EmailVerificationPage.scss";
import { resetPasswordApi } from "../../api/index";
import { getPageTitle, pageTitle } from "../../constants/constants";
import { Helmet } from "react-helmet";
import { useToast } from "../../hooks/useToast";
import { EMAIL_RESEND_MESSAGE } from "../../constants/toastMessages";
import { AnalyticsContext, UserContext } from "../../storage/context";
import {
  trackEvent,
  VERIFY_EMAIL_PAGE_VIEW,
  VERIFY_EMAIL_RESEND_CLICK,
  VERIFY_EMAIL_RESEND_FAILURE,
  VERIFY_EMAIL_RESEND_SUCCESS,
} from "../../analytics";

export default function EmailVerificationPage(props) {
  // dont remove the useRef, when a react component mounts, it might update state and re render the dom
  // at that time, location.state will be lost
  // to store it we used it
  let navState = useRef(useLocation().state);
  const [userEmail, setUserEmail] = useState("");
  const toast = useToast();
  const navigate = useNavigate();

  // let {
  //   state: { email: userEmail, password: userPassword = "password" },
  // } = {
  //   state: { email: "test@test.com", userPassword: "password" },
  // };

  const resentVerificationLinkToEmail = async () => {
    try {
      await emailVerificationApi.sendEmailVerificationLink(userEmail);
      toast(EMAIL_RESEND_MESSAGE);
      trackEvent(VERIFY_EMAIL_RESEND_CLICK, { email: userEmail });
      trackEvent(VERIFY_EMAIL_RESEND_SUCCESS, { email: userEmail });
    } catch (e) {
      console.log(e);
      trackEvent(VERIFY_EMAIL_RESEND_FAILURE, { email: userEmail, failure_reason: e });
      throw e;
    }
  };
  useEffect(() => {
    if (!navState.current) navigate("/sign-in");
    else {
      trackEvent(VERIFY_EMAIL_PAGE_VIEW, { email: navState.current.email });
      setUserEmail(navState.current.email);
    }
  }, []);

  return (
    <Background className="email-verification-page">
      <Helmet>
        <title>{getPageTitle("EMAIL_VERIFICATION_PAGE", "")}</title>
      </Helmet>
      <NavigationHeader />
      <div className="email-verification-container">
        <div className="content-card-container">
          <Typography h1>Verify your email</Typography>
          <p>
            To proceed, please verify your email by clicking the verification link sent on {/* TODO: Replace with actual user email */}
            <b>{userEmail || "email"}</b>. Don’t forget to check the spam folder 😉
          </p>
          {/* <OTPInputField /> */}
          <span onClick={resentVerificationLinkToEmail} className="text-link">
            Resend verification link
          </span>
        </div>
      </div>
    </Background>
  );
}
