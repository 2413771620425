import { Box, LinearProgress, Skeleton } from "@mui/material";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import "./LinearDistribution.scss";
import Icons from "../Icons/Icons";

function LinearDistribution({ loading = true, title, data, tooltip }) {
  if (loading) {
    return <SkeletonComp />;
  } else
    return (
      <Box className="linear-distribution-container">
        {/* <Box className="header">
          <label className="title">{title}</label>
          <Tooltip title={tooltip} placement="top">
            <Icons.info_icon style={{ cursor: "pointer" }} />
          </Tooltip>
        </Box> */}
        <Box className="linear-distribution-content-container">
          {data &&
            data.length > 0 &&
            data.map((item, index) => (
              <div className="linear-distribution-main-container">
                <div className="linear-distribution-value-container">
                  <Box className="name" sx={{ minWidth: "62px", justifyContent: "center", fontWeight: "500" }}>
                    {item.name}
                  </Box>
                  <Box className="percentage-value">{Number(item.value).toFixed(1) + "%"}</Box>
                </div>
                <Box sx={{ flexGrow: "1" }}>
                  <LinearProgress
                    sx={{
                      borderRadius: "1000px",
                      height: "12px",
                      backgroundColor: "var(--neutrals-background-grey)",
                    }}
                    variant="determinate"
                    value={item.value}
                  />
                </Box>
              </div>
            ))}
        </Box>
        {data?.length > 4 &&
        <div className="linear-distribution-gradient-bottom-container">
        </div>
        }
      </Box>
    );
}

export default LinearDistribution;

LinearDistribution.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      argument: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  tooltip: PropTypes.string,
};

function SkeletonComp() {
  return (
    <Box className="linear-distribution-container">
      <Box className="header">
        <Skeleton animation="wave" width={"40%"} height={14} sx={{ marginBottom: "20px" }} />
        <Skeleton animation="wave" variant="circular" width={16} height={16} />
      </Box>

      {[1, 2, 3, 4].map(() => {
        return (
          <Box className="row" sx={{ marginBottom: "28px" }}>
            <Box className="name" sx={{ minWidth: "62px", justifyContent: "center" }}>
              <Skeleton animation="wave" variant="text" width={50} height={20} />
            </Box>
            <Box sx={{ width: "132px", margin: "0px 10px" }}>
              <Skeleton animation="wave" variant="rounded" width={"100%"} height={6} />
            </Box>
            <Box
              sx={{
                color: "var(--main-primary)",
                width: "40px",
                fontWeight: "600",
              }}
            >
              <Skeleton animation="wave" variant="text" width={40} height={20} />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
