import { CampaignTrackingContext, WarehouseContext } from "../../storage/context";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { ADD_USAGE_QUOTA, SET_CSV_CREATORS_LIST, SET_PROFILES_CSV, SET_TRACKED_CREATORS, SHOW_CAMPAIGN_ERROR } from "../../storage/reducers/types";
import './SelectCreatorsPage.scss';
import {
    EmptyTableScreen,
    Icons,
    PrimaryButton,
    SelectableCreatorGrid,
    SelectedCreatorList,
    Separator,
    Step,
    CsvUpload
} from "../../components";
import {
    ADD_SELECTED_ACCOUNTS,
    creatorAccountSelected,
    duplicate_campaign,
    IDENTIFIER_TYPES,
    NO_ACCOUNTS_SELECTED_MESSAGE,
    NO_ACCOUNTS_SELECTED_TITLE,
    PROFILE_ADD_STATUS,
    SEARCH_AND_ADD_ACCOUNTS,
    SELECT_CREATOR_PAGE_TITLE,
    SELECTED_ACCOUNTS,
    uploadAccountList,
    uploadAccountListDesc,
    searchSelectAccounts,
    selectAccountFromList,
    waysToSelectAccounts,
    creatorProfileLinkCSVHeader
} from "../../constants/campaignTrackingConstants";
import SearchAndAddCreators from "../../components/SearchAndAddCreators/SearchAndAddCreators";
import { trackEvent, TRACKING_CAMPAIGN_ADD_ACCOUNTS_TO_CAMPAIGN_CLICKED } from "../../analytics";
import { getRowId } from "../../components/SelectableCreatorGrid/SelectableCreatorGrid";
import { Colors } from "../../styles/colors";
import { WORKPLATFORM_ID_MAPPING, WORKPLATFORM_USER_HANDLE_MAPPING } from "../../constants/constants";
import { createCampaign } from "../../api/CampaignTrackingAPI/CampaignTrackingAPI";
import { clearCampaignTrackingWarehouse, convertDateToUtc, createAddableProfiles, editProfiles, extractUsername, formatProfileLink, getNonDeletedCreators } from "../../utils/util";
import { Box } from '@material-ui/core';
import { useFetchTenantUsageInfo } from "../../hooks/useFetchTenantUsageInfo";
import { getValidLinks, getWorkPlatformIDFromURL } from "../../utils/profileHelpers";
import { func } from "prop-types";

export default function SelectCreatorsPage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { dispatchCampaignTrackingWarehouse, campaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
    const fetchTenantUsageInformation = useFetchTenantUsageInfo();
    const [stepOpen, setStepOpen] = useState({
        1: false,
        2: false,
        3: false,
    })
    const [createUtmButtonLoading, setCreateUtmButtonLoading] = useState(false)
    let {
        trackedCreators,
        csvCreatorsList,
        profilesCsv,
        campaignForm: {
            name: campaignName,
            brand: {
                value: brand_id,
                name: brand_name
            },
            cost,
            date: {
                fromDate: start_date,
                toDate: end_date,
            }
        }
    } = campaignTrackingWarehouse;
    const [selectedCreators, setSelectedCreators] = useState(trackedCreators);
    const [csvCreatorUrls, setCsvCreatorUrls] = useState(csvCreatorsList)
    const [csvFile, SetCSVFile] = useState(profilesCsv);

    function onSelectCreator(row) {

        function getRowId_WorkPlatform(row) {
            return `${row.work_platform_id}-${row.platform_username}`;
        }

        // Match result from userhandles with result from recently worked with
        function alreadySelectedCreator(row, selectedCreator) {
            return getRowId(selectedCreator) === getRowId(row)
                || (row['platform_username'] !== null
                    && getRowId_WorkPlatform(selectedCreator) === getRowId_WorkPlatform(row));
        }

        if (selectedCreators.findIndex(selectedCreator => {
            return alreadySelectedCreator(row, selectedCreator) && selectedCreator.addStatus !== PROFILE_ADD_STATUS.TO_BE_REMOVED;
        }) !== -1) {
            return;
        }
        setSelectedCreators([{ ...row, addStatus: PROFILE_ADD_STATUS.NEW }, ...selectedCreators]);
    }

    useEffect(() => {
        const creatorList = [];
        if (csvCreatorUrls.length !== 0) {
            csvCreatorUrls
                .filter((creator) => creator.isValid)
                .map((creator, index) => {
                    let creatorDetail = {
                        platform_username: extractUsername(formatProfileLink(creator.url)),
                        work_platform_id: getWorkPlatformIDFromURL(creator.url),
                        thumbnail_url: "",
                        is_verified: false,
                        url: formatProfileLink(creator.url),
                        isValid: true
                    };
                    creatorList.push(creatorDetail)
                });
        }
        if (creatorList.length !== 0 || csvFile) {
            dispatchCampaignTrackingWarehouse({
                type: SET_CSV_CREATORS_LIST,
                payload: creatorList,
            });
        } 
        dispatchCampaignTrackingWarehouse({
            type: SET_PROFILES_CSV,
            payload: csvFile,
        });

    }, [csvCreatorUrls]);

    function onDeleteCreator(id) {
        const creatorsToBeDeleted = [...selectedCreators];
        creatorsToBeDeleted.forEach(trackedCreator => {
            if (getRowId(trackedCreator) === id) {
                trackedCreator.addStatus = PROFILE_ADD_STATUS.TO_BE_REMOVED
            }
        });

        setSelectedCreators(creatorsToBeDeleted);
    }

    const MeasureRoiCreateCampaign = async () => {
        setCreateUtmButtonLoading(true)
        const newProfiles = selectedCreators
            .filter(getNonDeletedCreators)
            .map(createAddableProfiles)
            .map(obj => obj['identifier']);

        const { storeId, store_link } = state;

        try {
            const response = await createCampaign({
                warehouse,
                name: campaignName,
                cost,
                brand_id,
                profile_urls: newProfiles,
                start_date: convertDateToUtc({ date: start_date }),
                end_date: convertDateToUtc({ date: end_date, endDate: true }),
                storeId,
                store_link,
                profileFile: getValidLinks(csvCreatorUrls, creatorProfileLinkCSVHeader)
            });

            if (response.id) {
                // clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
                navigate(`/creators/campaigns/utm-links/${response.id}`);
                fetchTenantUsageInformation().then((usageInfo) => {
                    dispatchWarehouse({ type: ADD_USAGE_QUOTA, payload: usageInfo });
                });
                clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
            } else {
                if (response.error?.code === duplicate_campaign) {
                    dispatchCampaignTrackingWarehouse({
                        type: SHOW_CAMPAIGN_ERROR,
                        payload: duplicate_campaign,
                    })
                    navigate(`/creators/campaigns/create-campaign`)
                }
            }
        } catch (e) {
            console.error('Could not create a campaign', e);
        } finally {
            setCreateUtmButtonLoading(false)
        }

    }

    function onAddCreatorsClick() {
        const listOfAccounts = selectedCreators.map((creator) => {
            const { platform_username, work_platform_id } = creator;
            return { handle: platform_username, platform_name: WORKPLATFORM_ID_MAPPING[work_platform_id].platformName };
        });

        trackEvent(TRACKING_CAMPAIGN_ADD_ACCOUNTS_TO_CAMPAIGN_CLICKED, { list_of_selected_accounts: listOfAccounts });

        switch (state?.doneButton.action) {
            case 'ADD_SELECTED_ACCOUNTS':
                dispatchCampaignTrackingWarehouse({
                    type: SET_TRACKED_CREATORS,
                    payload: selectedCreators,
                });
                navigate(-1);
                break;
            case 'CREATE_UTM_LINKS':
                MeasureRoiCreateCampaign().then(() => {
                });
                break;
            case 'CREATE_UTM_LINKS_EDIT_FLOW': {
                // Save creators to campaign
                // navigate to state.navigateDoneRoute
                setCreateUtmButtonLoading(true)
                const campaignId = state?.navigateDoneRoute.split('/')[4];
                editProfiles(warehouse, campaignId, selectedCreators, csvCreatorsList).then(() => {
                     navigate(state.navigateDoneRoute);
                     clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
                 }).catch((e) => {
                     console.error(`Could not update profiles in campaign`, e);
                   });
                setCreateUtmButtonLoading(false);
                break;
            }
            default:
                break;
        }
    }

    function constructRow(creator, workPlatformId) {
        const { is_verified, picture, user_id, username } = creator;
        return {
            id: user_id,
            identifier_type: IDENTIFIER_TYPES.ACCOUNT_ID,
            identifier: user_id,
            platform_username: username,
            work_platform_id: workPlatformId,
            thumbnail_url: picture,
            is_verified
        };
    }

    const selectedCreatorsToShow = selectedCreators?.filter(selectedCreator => selectedCreator.addStatus !== PROFILE_ADD_STATUS.TO_BE_REMOVED);

    const uploadAccountListComp = () => {
        return (
            <div>
                <SearchAndAddCreators onSelectorClick={({ creator, workPlatformId }) => {
                    const constructedRow = constructRow(creator, workPlatformId);
                    onSelectCreator(constructedRow);
                }} />
            </div>
        )
    }

    const csvUploadComp = () => {
        return (
            <>
                <Icons.creatorProfileLinks style={{ width: "100%", marginTop: "24px" }} />
                <CsvUpload csvUrls={csvCreatorUrls} setCsvUrls={setCsvCreatorUrls} csvFile={csvFile} SetCSVFile={SetCSVFile} fileHeader={creatorProfileLinkCSVHeader} />
            </>
        )
    }

    const deleteCreatorFromCsvList = (url) => {
        const updatedList = csvCreatorsList.filter((creator) => creator.url !== url);
        dispatchCampaignTrackingWarehouse({
            type: SET_CSV_CREATORS_LIST,
            payload: updatedList,
        });
    };

    return (
        <div className="select-creators-page-container">
            <div className={'div-select-creators-page-header'}>
                <i className="ri-arrow-left-line back-icon" onClick={() => {
                    dispatchCampaignTrackingWarehouse({
                        type: SET_CSV_CREATORS_LIST,
                        payload: [],
                    });
                    dispatchCampaignTrackingWarehouse({
                        type: SET_PROFILES_CSV,
                        payload: null,
                    });
                    navigate(-1);
                }}></i>
                <span className={'title span-header-text'}>{SELECT_CREATOR_PAGE_TITLE(campaignName)}</span>
                <Box className="body-m" style={{ marginBottom: "32px" }}>{waysToSelectAccounts}</Box>
            </div>
            <div className="split-page">
                <div className="left-side">

                    <Step
                        variant={"DropdownStepWithIcon"}
                        title={uploadAccountList}
                        message={uploadAccountListDesc} stepCount={1}
                        stepOpenState={stepOpen}
                        updateStep={(step) => setStepOpen({ ...stepOpen, [step]: !stepOpen[step] })}
                        content={csvUploadComp()}
                        icon={<Icons.upload_icon_grey_bg />}
                    />
                    <hr className="step-dividing-line" />

                    <Step variant={"DropdownStepWithIcon"} title={searchSelectAccounts} stepCount={2} stepOpenState={stepOpen} updateStep={(step) => setStepOpen({ ...stepOpen, [step]: !stepOpen[step] })} content={uploadAccountListComp()} icon={<Icons.search_icon_grey_bg />} />

                    <hr className="step-dividing-line" />

                    <Step variant={"DropdownStepWithIcon"} title={selectAccountFromList} stepCount={3} stepOpenState={stepOpen} updateStep={(step) => setStepOpen({ ...stepOpen, [step]: !stepOpen[step] })} content={<SelectableCreatorGrid onSelectCreator={onSelectCreator} />} icon={<Icons.menu_icon_grey_bg />} />

                </div>
                <div className="right-side">
                    <div className={'div-select-creators-side-subsection'}>
                        <span
                            className={'sub-section-heading'}>{SELECTED_ACCOUNTS(selectedCreatorsToShow?.length + csvCreatorsList?.length)}
                        </span>
                        {selectedCreatorsToShow?.length === 0 && (!csvFile || csvCreatorsList?.length === 0) ? <EmptyTableScreen
                            icon={<Icons.users_96x96 />}
                            header={NO_ACCOUNTS_SELECTED_TITLE}
                            content={NO_ACCOUNTS_SELECTED_MESSAGE}
                        /> :
                            <>
                                {selectedCreatorsToShow?.length !== 0 &&
                                    <SelectedCreatorList
                                        rows={selectedCreatorsToShow}
                                        onDeleteRow={onDeleteCreator} />
                                }
                                {csvCreatorsList?.length !== 0 &&
                                    <SelectedCreatorList
                                        rows={csvCreatorsList}
                                        onDeleteRow={(row) => deleteCreatorFromCsvList(row.url)}
                                    />
                                }
                            </>
                        }
                    </div>
                </div>
            </div>

            <div className={'add-creators-to-campaign-container'}>
                <PrimaryButton className={'add-creators-to-campaign-button'}
                    label={<span className={'body-m'}
                        style={{ color: Colors.white }}>{state?.doneButton.text}</span>}
                    loading={createUtmButtonLoading}
                    onClick={onAddCreatorsClick}
                    disabled={selectedCreators?.length === 0 && !csvFile}
                />
                <Box className="body-m ">
                    {selectedCreators?.filter(creator => creator.addStatus === "ADDED" || creator.addStatus === "NEW").length + csvCreatorsList?.length} {creatorAccountSelected}
                </Box>
            </div>
        </div>
    );
}
