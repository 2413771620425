import React from "react";
import "./Background.scss";
import { Link } from "react-router-dom";
import { FOOTER_LINK_CLICK, trackEvent } from "../../analytics";
import { MEETING_SCHEDULE_LINK } from "../../constants/constants";

// const footerLinksObj = {
//   Pricing: "https://www.getphyllo.com/pricing",
//   "Developer Docs": "https://docs.insightiq.ai/docs/api-reference",
//   "Contact Us": "https://www.getphyllo.com/sign-up",
//   Help: "https://www.getphyllo.com/sign-up",
// };
const FooterLink = ({ link, name }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" onClick={() => trackEvent(FOOTER_LINK_CLICK, { name, link })}>
      {name}
    </a>
  );
};
export default function Background({ children, className = "", leftLeaves = false, rightLeaves = false, rightScratched = false, ...otherProps }) {
  return (
    <div className="entire-page-background">
      <div className={`page-background ${className}`} {...otherProps}>
        {children}
        {/* {scratchedLeft && <img src={ScratchedLeft} className="scratched-left" />}
      {scratchedRight && <div className="right-background-images"></div>} */}
      </div>
      <div className="background-images">
        {leftLeaves && <div className="left-background-images" />}
        {(rightLeaves || rightScratched) && (
          <div className="right-background-images">
            {rightScratched && <div className="scratched-right" />}
            {rightLeaves && <div className="leaves-right"></div>}
          </div>
        )}
      </div>
      {/* <div className="footer-links">
        {Object.keys(footerLinksObj).map((footer) => {
          return <FooterLink name={footer} link={footerLinksObj[footer]} />;
        })}
      </div> */}
    </div>
  );
}
