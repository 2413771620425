import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Drawer, Link, Skeleton } from "@mui/material";
import "./FiltersOverlay.scss";
import Icons from "../Icons/Icons";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import InvertedPrimaryButton from "../InvertedPrimaryButton/InvertedPrimaryButton";
import FilterWrapper from "../FilterWrapper/FilterWrapper";
import RangeFilter from "../RangeFilter/RangeFilter";
import { deleteAllQueryParamsAndFilterPayload } from "../../pages/CreatorDiscoveryPage/CreatorDiscoveryPageUtils";
import { CreatorDiscoveryWarehouseContext } from "../../storage/context";
import { SET_LAST_INTERACTED_FILTER_NULL, UPDATE_LAST_INTERACTED_FILTER } from "../../storage/reducers/types";

function FiltersOverlay({ loading, open, onClose, creatorsFilters, audienceFilters, performanceFilters, handleClickShowResults, setShowTable }) {
  if (loading) {
    return <SkeltonComp open={open} onClose={onClose} />;
  } else
    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            boxShadow: "none",
          },
        }}
      >
        <OverlayAllFilters
          open={open}
          onClose={onClose}
          creatorsFilters={creatorsFilters}
          audienceFilters={audienceFilters}
          performanceFilters={performanceFilters}
          handleClickShowResults={handleClickShowResults}
          setShowTable={setShowTable}
        />
      </Drawer>
    );
}

export default FiltersOverlay;

FiltersOverlay.propTpes = {
  loading: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  creatorsFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
      isSelected: PropTypes.bool,
      Filter: PropTypes.node,
    })
  ),
  audienceFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
      isSelected: PropTypes.bool,
      Filter: PropTypes.node,
    })
  ),
  performanceFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
      isSelected: PropTypes.bool,
      Filter: PropTypes.node,
    })
  ),
};

// content inside overlay
const OverlayAllFilters = ({ open, onClose, performanceFilters, audienceFilters, creatorsFilters, handleClickShowResults, setShowTable }) => {
  const [lastInteraction, SetLastInteraction] = useState(null);

  const updateLastInteraction = ({ state, uuid }) => {
    if (state === true) {
      SetLastInteraction(uuid);
    } else if (state === false) {
      SetLastInteraction((prev) => {
        if (prev === uuid) return null;
        else return prev;
      });
    }
  };

  return (
    <div className="overlay-all-filters">
      {/* header */}
      <header className="overlay-filters-header">
        <div className="overlay-filters-header-title">All filters</div>

        <div className="overlay-filters-header-close" onClick={onClose}>
          <i className="ri-close-line"></i>
        </div>
      </header>
      {/* body  */}
      <main className="overlay-filters-body">
        <div className="creators">
          <div className="title">Creator</div>
          {creatorsFilters &&
            creatorsFilters.map((filter) => {
              return (
                <FilterWrapper
                  filterComponent={filter.Filter}
                  filterHeader={filter.label}
                  id={filter.id}
                  type={filter?.type}
                  filterDescription={filter?.description}
                  handleGetFilterValue={filter.handleGetFilterValue}
                  key={filter.id}
                  filterKey={filter.filterKey}
                  lastInteractedFilter={filter.lastInteractedFilter}
                  isOverlayOpen={open}
                  isSelected={filter.isSelected}
                />
              );
            })}
        </div>
        <div className="audience">
          <div className="title"> Audience</div>
          {audienceFilters &&
            audienceFilters.map((filter) => {
              return (
                <FilterWrapper
                  filterComponent={filter.Filter}
                  filterHeader={filter.label}
                  id={filter.id}
                  type={filter?.type}
                  filterDescription={filter?.description}
                  handleGetFilterValue={filter.handleGetFilterValue}
                  key={filter.id}
                  filterKey={filter.filterKey}
                  lastInteractedFilter={filter.lastInteractedFilter}
                  isOverlayOpen={open}
                  isSelected={filter.isSelected}
                />
              );
            })}
        </div>
        <div className="performance">
          <div className="title">Performance</div>
          {performanceFilters &&
            performanceFilters.map((filter) => {
              return (
                <FilterWrapper
                  filterComponent={filter.Filter}
                  filterHeader={filter.label}
                  id={filter.id}
                  type={filter?.type}
                  filterDescription={filter?.description}
                  handleGetFilterValue={filter.handleGetFilterValue}
                  key={filter.id}
                  filterKey={filter.filterKey}
                  lastInteractedFilter={filter.lastInteractedFilter}
                  isOverlayOpen={open}
                  isSelected={filter.isSelected}
                />
              );
            })}
        </div>
      </main>
      {/* footer */}
      <OverlayFooter handleClickShowResults={handleClickShowResults} onClose={onClose} setShowTable={setShowTable} />
    </div>
  );
};

// footer
const OverlayFooter = ({ handleClickShowResults, onClose, setShowTable }) => {
  const { creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse } = useContext(CreatorDiscoveryWarehouseContext);
  const handleRemoveAllFilters = () => {
    deleteAllQueryParamsAndFilterPayload(creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse);
    // This is only to trigger the event after clearing the tileCount & sourceOfFilter being passed in the segment
    dispatchCreatorDiscoveryWarehouse({
      type: UPDATE_LAST_INTERACTED_FILTER,
      payload: {
        filterKey: "random string",
        state: true,
      },
    });
    setTimeout(() => {
      dispatchCreatorDiscoveryWarehouse({ type: SET_LAST_INTERACTED_FILTER_NULL });
    }, 500);
  };
  return (
    <footer className="overlay-filters-footer">
      <PrimaryButton
        style={{ height: "56px", width: "133px" }}
        label="Get results"
        onClick={() => {
          handleClickShowResults();
          onClose();
        }}
      />

      <Link
        underline="always"
        onClick={() => {
          handleRemoveAllFilters();
          setShowTable(false);
          handleClickShowResults();
        }}
      >
        Remove all filters
      </Link>
    </footer>
  );
};

// skeletons
const SkeltonComp = ({ open, onClose }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          boxShadow: "none",
        },
      }}
    >
      <SkeltonOverlayAllFilters />
    </Drawer>
  );
};

const SkeltonOverlayAllFilters = () => {
  return (
    <div className="overlay-all-filters">
      {/* header */}
      <header className="overlay-filters-header">
        <Skeleton variant="text" width={100} height={40} />

        <Skeleton variant="circular" width={20} height={20} />
      </header>
      {/* body  */}
      <main className="overlay-filters-body">
        <div className="creators">
          <Skeleton variant="text" width={100} height={40} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
        </div>
        <div className="audience">
          <Skeleton variant="text" width={100} height={40} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
        </div>
        <div className="performance">
          <Skeleton variant="text" width={100} height={40} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: "12px" }} />
        </div>
      </main>
      {/* footer */}
      <SkeletonOverlayFooter />
    </div>
  );
};

const SkeletonOverlayFooter = () => {
  return (
    <footer className="overlay-filters-footer">
      <Skeleton variant="rectangular" width={100} height={40} />

      <Skeleton variant="rectangular" width={100} height={40} />
    </footer>
  );
};
