import React, { useState, useEffect } from 'react';
import './TextSwitchingLoader.scss'; 

const TextSwitchingLoader = ({ texts, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, interval);

    return () => clearInterval(timer);
  }, [texts.length, interval]);

  return <div className="text-switching-loader section-heading">{texts[currentIndex]}</div>;
};

export default TextSwitchingLoader;
