import _ from "lodash";
import { FOLLOWERS, SUBSCRIBERS } from "./creatorDiscovery";

// messages
export const EMPTY_REQUIRED_FIELD_MESSAGE = "This is a required field.";
export const INVALID_EMAIL_MESSAGE = "That doesn't seem right, please enter a valid email.";
export const NON_WORK_EMAIL_MESSAGE =
  "Sorry, we’re only allowing emails from work domains currently. Please enter your work email. e.g. nickfury@shield.com.";
export const AGREE_TERMS_AND_CONDITIONS = "You need to agree to the terms of usage and privacy policy to be able to proceed further.";
export const INVALID_PASSWORD_MESSAGE =
  "The password should contain at least one lowercase letter, one uppercase letter, one number and at least 8 characters.";
export const COMPANY_NAME_EXCEED_LIMIT_MESSAGE = "Company Name should not exceed the limit of more than 100 characters.";
export const WEBHOOK_NAME_EXCEED_LIMIT_MESSAGE = "The webhook name can be maximum 100 characters long.";

export const REMOVE_TEXT = "Remove";
export const REMOVE_MODAL_TITLE = "Remove account?";
export const REMOVE_MODAL_BUTTON_TITLE = "Remove account";
export const REMOVE_MODAL_MESSAGE = "Are you sure you want to remove the following account?";
export const REMOVE_MODAL_WARNING_MESSAGE =
  "Removing the account means losing all data. Ask the account creator to reconnect in order to regain data access";

export const CONNECTED_TOOLTIP_MESSAGE = "The account connection is live. All good here.";
export const EXPIRED_TOOLTIP_MESSAGE_1 = "The connection to this account has expired.";
export const EXPIRED_TOOLTIP_MESSAGE_2 = "Ask the account creator to reconnect in order to regain data access.";
export const DISCONNECTED_TOOLTIP_MESSAGE_1 = "This account has been disconnected by the creator.";
export const DISCONNECTED_TOOLTIP_MESSAGE_2 = "Ask the account creator to reconnect in order to regain data access.";
export const REMOVED_TOOLTIP_MESSAGE_1 = "This account has been removed.";
export const REMOVED_TOOLTIP_MESSAGE_2 = "Ask the account creator to reconnect in order to regain data access.";

export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const youtube_URL_REGEX = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
export const instagram_URL_REGEX = /^(http(s)?:\/\/)?((w){3}.)?instagr(am|.am)?(\.com)?\/.+/;
export const tiktok_URL_REGEX = /^(http(s)?:\/\/)?((w){3}.)?tiktok(.com)?\/.+$/;

export const youtube_profile_URL_REGEX = /^(?:(?:https?:)?\/\/)?(?:www\.)?youtube\.com\/(?:c\/|channel\/|user\/|@)?([a-zA-Z0-9\-]{1,})/;
export const instagram_profile_URL_REGEX = /^(?:(?:https?:)?\/\/)?(?:www\.)?instagram\.com\/(@?[A-Za-z0-9_.-]+)\/?(?:\?.*)?$/;
export const tiktok_profile_URL_REGEX = /^(?:(?:https?:)?\/\/)?(?:www\.)?tiktok\.com\/(@?[A-Za-z0-9_.-]+)\/?(?:\?.*)?$/;

export const HTTPS_REGEX = /^https:\/\//;
// export const LOCALHOST_REGEX = /^^http(s)?:\/\/localhost/;
export const LOCALHOST_REGEX = /^https:\/\/localhost:/;
// /^https?:\/\/(localhost:([0-9]+\.)+[a-zA-Z0-9]{1,6})?$/;
// export const LOCALHOST_REGEX = /^http(s)?:\/\/localhost/;
//   /^https:\/\/localhost:[0-9]{1,5}\/([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/;

export const URL_REGEX =
  /^(?!.*https:\/\/.*https:\/\/)(https?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'()*+,;=%]+(\?[\w\-\._~:/?#[\]@!\$&'()*+,;=%]*)?$/;
export const IP_REGEX = /^https:\/\/(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[0-9a-zA-Z\/:]*$/;
// export const IP_REGEX =
//   /^https:\/\/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)[0-9a-zA-Z\/:]*$/;

export const NUMBER_CHECK_REGEX = /[0-9]+/; // checks if at least one number exists
export const UPPER_LETTER_CHECK_REGEX = /[A-Z]+/; // checks if at least one capital letter exist
export const LOWER_LETTER_CHECK_REGEX = /[a-z]+/; // checks if at least one lower letter exist

export const platformHandleRegex = /^@?[A-Za-z0-9._]+$/; // check if it's a platform handle

export const products = ["Identity", "Audience demographics", "Engagement", "Comments", "Income"];
export const sandboxProducts = ["Identity", "Audience demographics", "Engagement", "Comments", "Income", "Activity contents"];

export const WORKPLATFORMS = {
  YOUTUBE: "YouTube",
  INSTAGRAM: "Instagram",
  TIKTOK: "TikTok",
};
export const WORKPLATFORM_IDS = {
  YouTubePlatformId: "14d9ddf5-51c6-415e-bde6-f8ed36ad7054",
  InstagramPlatformId: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
  TikTokPlatformId: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
  TwitterPlatformId: "7645460a-96e0-4192-a3ce-a1fc30641f72",
  FacebookPlatformId: "ad2fec62-2987-40a0-89fb-23485972598c",
};

export const WORKPLATFROMPRODUCTMAPPING = {
  "identity.is_supported": "Identity",
  "engagement.is_supported": "Engagement",
  "identity.audience.is_supported": "Audience demographics",
  "engagement.audience.is_supported": "Comments",
  "income.is_supported": "Income",
  "activity.is_supported": "Activity contents",
};

export const WORKPLATFORM_ID_MAPPING = {
  "9bb8913b-ddd9-430b-a66a-d74d846e6c66": {
    platformName: "Instagram",
    platformLogo: "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram.png",
  },
  "de55aeec-0dc8-4119-bf90-16b3d1f0c987": {
    platformName: "TikTok",
    platformLogo: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  },
  "14d9ddf5-51c6-415e-bde6-f8ed36ad7054": {
    platformName: "YouTube",
    platformLogo: "https://cdn.insightiq.ai/platforms_logo/logos/logo_youtube.png",
  },
  "7645460a-96e0-4192-a3ce-a1fc30641f72": {
    platformName: "Twitter",
    platformLogo: "https://cdn.insightiq.ai/platforms_logo/logos/logo_twitter.png",
  },
  "e4de6c01-5b78-4fc0-a651-24f44134457b": {
    platformName: "Twitch",
    platformLogo: "https://cdn.insightiq.ai/platforms_logo/logos/logo_twitch.png",
  },
  "ad2fec62-2987-40a0-89fb-23485972598c": {
    platformName: "Facebook",
    platformLogo: "https://cdn.insightiq.ai/platforms_logo/logos/logo_facebook.png",
  },
  "fbf76083-710b-439a-8b8c-956f607ef2c1": {
    platformName: "Substack",
    platformLogo: "https://cdn.insightiq.ai/platforms_logo/logos/logo_substack.png",
  },
  "fb7cafcf-60ed-468a-ad6d-6bb6899d0871": {
    platformName: "Instagram Lite",
    platformLogo: "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram_lite.png",
  },
  "3e6f4cdd-2119-4125-aa0d-86eee4e20fcc": {
    platformName: "AdSense",
    platformLogo: "https://cdn.insightiq.ai/platforms_logo/logos/logo_adsense.png",
  },
  "36410629-f907-43ba-aa0d-434ca9c0501a": {
    platformName: "LinkedIn",
    platformLogo: "https://cdn.insightiq.ai/platforms_logo/logos/logo_linkedin.png",
  },
  "1db0b86c-a547-4119-9834-da927171a685": {
    platformName: "Spotify",
    platformLogo: "https://cdn.insightiq.ai/platforms_logo/logos/logo_spotify.png",
  },
};

export const FAILEDREASONMAPPING = {
  FAILED: {
    title: "Failed",
    reason: "Account type or permissions issue",
    toolTipText:
      "<div>Account type issue - User connected an inactive or private account. Or they did not select the correct page / channel. <br><br>Permissions issue - User did not provide all the required permissions.<div>",
  },
  CANCELLED: {
    title: "Failed",
    reason: "Abandoned",
    toolTipText: "The user chose not to connect the account OR did not connect in time.",
  },
  EXPIRED: {
    title: "Failed",
    reason: "Abandoned",
    toolTipText: "The user chose not to connect the account OR did not connect in time.",
  },
  SUCCESS: {
    title: "Success",
    reason: "",
    toolTipText: "",
  },
};

export const SUPPORTED_PLATFORMS = [
  "ad2fec62-2987-40a0-89fb-23485972598c", // FACEBOOK
  "9bb8913b-ddd9-430b-a66a-d74d846e6c66", // INSTAGRAM
  "de55aeec-0dc8-4119-bf90-16b3d1f0c987", // TIKTOK
  "e4de6c01-5b78-4fc0-a651-24f44134457b", // TWITCH
  "7645460a-96e0-4192-a3ce-a1fc30641f72", // TWITTER
  "14d9ddf5-51c6-415e-bde6-f8ed36ad7054", // YOUTUBE
];

export const PHYLLO_SDK_DONE_CLICKED = "DONE_CLICKED";

export const DOMAIN_NOT_HTTPS = 'The URL should have "https" scheme.';
export const NOT_A_VALID_DOMAIN = "The domain of the URL should be a domain name.";
export const Sandbox = "sandbox";
export const Staging = "staging";
export const Production = "production";
export const SUBMITTED = "SUBMITTED";
export const getPageTitle = (key, environment) => {
  environment = _.startCase(environment);
  const pageTitle = {
    API_CREDENTIAL_PAGE: "API Credentials — insightIQ [" + environment + "]",
    CREATOR_INVITATION_PAGE: "Your creators — insightIQ",
    CREATOR_PROFILE_PAGE: "Creator Profile — insightIQ",
    CREATOR_SEARCH_PAGE: "Discover Creator — insightIQ",
    EMAIL_VERIFICATION_PAGE: "Email Verification — insightIQ",
    GETTING_STARTED_PAGE: "Getting Started — insightIQ",
    HELP_PAGE: "Support & sales — insightIQ [" + environment + "]",
    LOGIN_PAGE: "Sign-in — insightIQ",
    PASSWORD_RESET_VERIFICATION: "Password Reset Verification — insightIQ",
    PRODUCTS_PAGE: `Products — insightIQ [${environment}]`,
    REGISTRATION_PAGE: "Registration — insightIQ",
    RESET_PASSWORD_PAGE: "Reset Password — insightIQ",
    SET_NEW_PASSWORD_PAGE: "Set New Password — insightIQ",
    TRACKING_CAMPAIGN_PAGE: "Campaigns - insightIQ",
    WEBHOOK_CREATION_PAGE: "Create New Webhook — insightIQ [" + environment + "]",
    WEBHOOK_PAGE: "Webhooks — insightIQ [" + environment + "]",
    WEBHOOK_UPDATE_PAGE: "Edit Webhook — insightIQ [" + environment + "]",
  };
  return pageTitle[key];
};

export const INSIGHTIQ_USERS_API_TIMEOUT = 40000;
export const INSIGHTIQ_USERS_POLLING_INTERVAL = 1500;
export const INSIGHTIQ_TENANT_API_POLLING_INTERVAL = 3000;
export const INSIGHTIQ_TENANT_API_MAX_ATTEMPTS = 20;
export const GETTING_STARTED_AUTO_SCROLLING_TIMEOUT = 0;
export const GETTING_STARTED_STEP_1_CHANGE_INTERVAL = 5000;
export const GETTING_STARTED_STEP_2_CHANGE_INTERVAL = 3800;
export const GETTING_STARTED_API_RESPONSE_INTERVAL = 4000;

export const CALENDLY_MEETING_SCHEDULED_EVENT = "calendly.event_scheduled";

export const Creators = "Creators";
export const Developers = "Developers";
export const CONNECTED = "CONNECTED";

export const TabValues = {
  all: "All",
  connectedAccounts: "Connected accounts",
};

export const statusContentMapping = {
  ACTIVE: { label: "Active", color: "success" },
  ACTION_NEEDED: { label: "Action needed", color: "error" },
  INVITED: { label: "Invite sent", color: "default" },
};

export const DisconnectionSource = {
  DEVELOPER_API: "DEVELOPER_API",
  INTERNAL_API: "INTERNAL_API",
};

export const failedReasons = ["NOT_CONNECTED", "SESSION_EXPIRED"];

export const failedReasonsTooltipContentMapping = {
  NOT_CONNECTED: "Disconnected",
  SESSION_EXPIRED: "Session expired",
};

/**
 * Enum-like object for account status. Not a 1:1 mapping with backend, but useful for frontend
 * @type {{NOT_CONNECTED: {label: string, key: string}, SESSION_EXPIRED: {label: string, key: string}, CONNECTED: {label: string, key: string}, REMOVED: {label: string, key: string}}}
 */
export const AccountStatus = {
  CONNECTED: { key: "CONNECTED", label: "Connected" },
  NOT_CONNECTED: { key: "NOT_CONNECTED", label: "Disconnected" },
  SESSION_EXPIRED: { key: "SESSION_EXPIRED", label: "Session expired" },
  REMOVED: { key: "REMOVED", label: "Removed" },
};

export const connectionsStatus = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  IN_PROGRESS: "IN_PROGRESS",
};
export const CREATOR_COLUMNS = {
  NAME: "Name",
  EMAIL: "Email",
  STATUS: "Status",
  PLATFORM: "Platform",
  INVITED_ON: "Invited on",
  CONNECTED_ON: "Connected on",
  USERNAME: "Username",
  ACTIONS: "Actions",
};
export const FROM_DATE = "From date";
export const TO_DATE = "To date";
export const export_profile = "Export profile";
export const last_updated = "Last updated";

export const SOMETHING_WRONG_ERROR = {
  title: "Something is wrong on our end.",
  message: "Please try again in some time.",
};

export const Connection_attempts = "Connection attempts";
export const clickSortMsg = "Click to sort";

export const sort_by_keys = {
  created_at: { key: "created_at", fieldName: "Attempted at" },
  work_platform_id: { key: "work_platform_id", fieldName: "Platform" },
};

export const APPLY = "Apply";
export const RESET = "Reset";
export const DATE_FORMAT = "MMM DD, YYYY";
export const DATE_TIME_FORMAT = "MMM DD, YYYY [at] hh:mm A";
export const DATE_TIME_FORMAT_STATUS = "MMM DD, YYYY[,] hh:mm a";
export const DATE_TIME_FORMAT_SMALL = "MMM D, YYYY h:mm a";
export const DATE_TIME_FORMAT_WITHOUT_TIME = "DD MMM, YYYY";

export const ACCOUNT_STATUS = "Account status";
export const RECORDS_PER_PAGE = 10;
export const DEBOUNCE_TIME = 800;
export const SEARCH_BY_COUNTRY_PLACEHOLDER = "Search country";
export const SEARCH_BY_CITY_OR_COUNTRY_PLACEHOLDER = "Search country or city";

export const WIDTH_CONTENT_CARD = 283;
export const GAP_BETWEEN_CONTENT_CARDS = 8;
export const CONTENTS_PER_PAGE = 8;

export const MEETING_SCHEDULE_LINK = "https://www.insightiq.ai/demo-from-dashboard";
export const INSIGHTIQ_DOCS_LINK = "https://docs.insightiq.ai/docs/api-reference/introduction/introduction";
export const INSIGHTIQ_API_STATUS = "https://insightiq.statuspage.io/";

export const SCREEN_HEIGHT_FOR_LARGE_SCREEN = 900;
export const YOFFSET_FOR_LARGE_SCREEN = 1000;
export const YOFFSET_FOR_SMALL_SCREEN = 350;

export const SEARCHED_RESULTS_RECORDS_LIMIT = 5;

export const IDENTITY_ATTRIBUTES = {
  profile_pic: "profile_pic",
  platform_profile_name: "platform_profile_name",
  subscriber_count: "subscriber_count",
  name: "name",
  email_id: "email_id",
  username: "username",
  playlists: "playlists",
  videos: "videos",
  total_watch_time: "total_watch_time",
  follower_count: "follower_count",
  posts: "posts",
  tweets: "tweets",
};

export const SEARCH_IDENTITY_ATTRIBUTES = {
  platform_account_type: "platform_account_type",
  gender: "gender",
  age_group: "age_group",
  language: "language",
  follower_count: "follower_count",
  subscriber_count: "subscriber_count",
  average_likes: "average_likes",
  average_comments: "average_comments",
  average_views: "average_views",
  average_reels_views: "average_reels_views",
  engagement_rate: "engagement_rate",
  country: "country",
  state: "state",
  city: "city",
  follower_types: "follower_types",
  sponsored_posts_performance: "sponsored_posts_performance",
};

export const SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING = {
  platform_account_type: "Account types such as PERSONAL, CREATOR, BUSINESS, etc.",
  gender: "Creator's gender",
  age_group: "Creator's age group",
  language: "Creator's language",
  follower_count: "Followers",
  subscriber_count: "Subscribers",
  average_likes: "Average likes per post",
  average_comments: "Average comments per post",
  average_views: "Average views per video",
  average_reels_views: "Average views per reel",
  engagement_rate: "Engagement rate",
  country: "Creator's country",
  state: "Creator's state",
  city: "Creator's city",
  gender_distribution: "Gender of the audience and its value. Allowed values: MALE, FEMALE, OTHERS.",
  age_distribution: "Age range and what percent of the audience belong to the age range",
  popular_hashtags: "Hashtags frequently used by the creator in their content",
  popular_tags: "Accounts frequently tagged by the creator in their content",
  interests: "Interests and topics that the creator posts about",
  brands: "tool tip not defined",
  follower_count_graph: "Monthly follower count",
  subscriber_count_graph: "Monthly subscriber count",
  following_count_graph: "Monthly following count",
  average_likes_graph: "Monthly average likes count",
  sponsored_posts_performance: "Sponsored posts performance",
  creator_brand_affinity: "Brands creator interact with",
};

export const SEARCH_ATTRIBUTES_TOOLTIP_TEXT = {
  engagement_rate: "Total likes on all posts divided by number of followers.",
  sponsored_posts_performance:
    "This is derived by engagement on sponsored posts(including posts with #ad, #sponsored, #paid) divided by the average engagement on non sponsored posts. 50% means that the sponsored posts perform half as good as the normal posts.",
  interests: "Determined by brand mentions in captions, hashtags and mentions along with location.",
  creator_brand_affinity: "Determined by brand mentions in captions, hashtags and mentions along with location.",
  audience_location: "Location name and what percentage of the audience belong to the location",
  audience_interest: "Determined by brand mentions in captions, hashtags and mentions along with location.",
  location: "Location of the creator based on the location tags, language and recent posts.",
};

export const IDENTITY_WORKPLATFORMS_MAPPING = {
  YouTube: ["profile_pic", "platform_profile_name", "subscriber_count", "name", "email_id", "username", "playlists", "videos", "total_watch_time"],
  Instagram: ["profile_pic", "platform_profile_name", "follower_count", "name", "email_id", "username", "posts"],
  "Instagram Lite": ["profile_pic", "platform_profile_name", "follower_count", "name", "email_id", "username", "posts"],
  TikTok: ["profile_pic", "platform_profile_name", "follower_count", "email_id", "username", "posts"],
  Twitter: ["profile_pic", "platform_profile_name", "follower_count", "name", "username", "tweets"],
  Facebook: ["profile_pic", "platform_profile_name", "follower_count", "name", "email_id", "username"],
  others: ["profile_pic", "platform_profile_name", "subscriber_count", "name", "email_id", "username"],
};

export const SEARCH_IDENTITY_WORKPLATFORMS_MAPPING = {
  YouTube: ["subscriber_count", "average_likes", "average_comments", "average_views", "engagement_rate", "sponsored_posts_performance"],
  Instagram: [
    "follower_count",
    "average_likes",
    "average_comments",
    "average_views",
    "average_reels_views",
    "engagement_rate",
    "follower_types",
    "sponsored_posts_performance",
  ],
  TikTok: ["follower_count", "average_likes", "average_comments", "average_views", "engagement_rate", "sponsored_posts_performance"],
};

export const COMPARE_CREATORS_WORKPLATFORMS_MAPPING = {
  YouTube: ["average_likes", "average_comments", "average_views", "engagement_rate", "sponsored_posts_performance"],
  Instagram: [
    "average_likes",
    "average_comments",
    "average_views",
    "average_reels_views",
    "engagement_rate",
    "follower_types",
    "sponsored_posts_performance",
  ],
  TikTok: ["average_likes", "average_comments", "average_views", "engagement_rate", "sponsored_posts_performance"],
};

// profile analytics analytics mapping
export const PROFILE_ANALYTICS_ERROR_MAPPING = {
  profile_not_found: "This account is not present on the social network, there is a possibility that it's been removed or made private.",
  profile_data_removed: "Profile information not available. The profile information has been removed at the request of the profile owner.",
  profile_removed: "Profile information not available. This account has been removed from the social network.",
  profile_is_private: "Profile information not available. This account has been made private.",
  empty_audience: "The audience data is not available for this profile due to factors like profile size, vintage of profile, and others.",
  empty_audience_data: "The audience data is not available for this profile due to factors like profile size, vintage of profile, and others.",
  retry_later: "Data is being updated for this profile, please retry after 20 minutes.",
  internal_server_error: "We could not fetch the profile at this time, please try again after a few minutes.",
  server_error: "We could not fetch the profile at this time, please try again after a few minutes.",
  something_wrong_on_our_end: "There seems to be some issue on our side. We will resolve it ASAP. Please try after some time",
  account_not_found: "We were unable to find a profile with this username",
  max_limit_reached: "Maximum number of requests for the api has exceeded the limit: 3",
};

export const UPDATING_CREATORS_DETAIL_ERROR = {
  title: "We’re updating this creator’s details...",
  desc: "This may take up to 20 mins. You can visit this profile later, and we should have the info ready for you.",
  top_header_details: {
    title: "Update in progress...",
    backgroundColor: "#FDEDBE",
    textClass: "profile-header-status-text top-header-status-text-alert",
  },
};

export const creatorLinkageProductID = "6299f6a3-df58-45bd-9cd1-310e8197c252";
export const creatorSearchProductID = "33bd4062-8844-4b39-aba4-bdb6b6d25184";
export const campaignTrackingProductID = "deedf80f-e766-4a4f-a3bc-f0dab2f70581";
export const compareCreatorProductID = "7f56d4a0-839b-448e-aef7-2688987a0779";
export const brandSafetyProductID = "c47dc829-11fc-4138-8749-825449b85e51";

export const creatorModuleProductMapping = {
  "6299f6a3-df58-45bd-9cd1-310e8197c252": "Connected accounts",
  "33bd4062-8844-4b39-aba4-bdb6b6d25184": "Creator search",
  "deedf80f-e766-4a4f-a3bc-f0dab2f70581": "Campaigns",
};

export const CONTACT_ICONS_MAPPING = {
  email: "https://cdn.insightiq.ai/profile/contact_icons/email.png",
  facebook: "https://cdn.insightiq.ai/platforms_logo/logos/logo_facebook.png",
  instagram: "https://cdn.insightiq.ai/platforms_logo/logos/logo_instagram.png",
  itunes: "https://cdn.insightiq.ai/profile/contact_icons/itunes.png",
  kakao: "https://cdn.insightiq.ai/profile/contact_icons/kakao.png",
  kik: "https://cdn.insightiq.ai/profile/contact_icons/kik.png",
  lineid: "https://cdn.insightiq.ai/profile/contact_icons/line.png",
  linktree: "https://cdn.insightiq.ai/profile/contact_icons/linktree.png",
  phone: "https://cdn.insightiq.ai/profile/contact_icons/phone.png",
  pinterest: "https://cdn.insightiq.ai/profile/contact_icons/pinterest.png",
  skype: "https://cdn.insightiq.ai/profile/contact_icons/skype.png",
  snapchat: "https://cdn.insightiq.ai/profile/contact_icons/snapchat.png",
  telegram: "https://cdn.insightiq.ai/profile/contact_icons/telegram.png",
  tiktok: "https://cdn.insightiq.ai/platforms_logo/logos/logo_tiktok.png",
  tumblr: "https://cdn.insightiq.ai/profile/contact_icons/tumblr.png",
  twitchtv: "https://cdn.insightiq.ai/platforms_logo/logos/logo_twitch.png",
  twitter: "https://cdn.insightiq.ai/platforms_logo/logos/logo_twitter.png",
  viber: "https://cdn.insightiq.ai/profile/contact_icons/viber.png",
  vk: "https://cdn.insightiq.ai/profile/contact_icons/vk.png",
  wechat: "https://cdn.insightiq.ai/profile/contact_icons/wechat.png",
  weibo: "https://cdn.insightiq.ai/profile/contact_icons/weibo.png",
  whatsapp: "https://cdn.insightiq.ai/profile/contact_icons/whatsapp.png",
  youtube: "https://cdn.insightiq.ai/platforms_logo/logos/logo_youtube.png",
};

export const PERMITTED_FILTER_SORTING_MAPPING = {
  AVERAGE_LIKES: "AVERAGE_LIKES",
  FOLLOWER_COUNT: "FOLLOWER_COUNT",
  SUBSCRIBER_COUNT: "SUBSCRIBER_COUNT",
  ENGAGEMENT_RATE: "ENGAGEMENT_RATE",
  DESCRIPTION: "DESCRIPTION",
  AVERAGE_VIEWS: "AVERAGE_VIEWS",
  CONTENT_COUNT: "CONTENT_COUNT",
  REELS_VIEWS: "REELS_VIEWS",
  FOLLOWER_GROWTH: "FOLLOWER_GROWTH",
  TOTAL_VIEWS_GROWTH: "TOTAL_VIEWS_GROWTH",
  TOTAL_LIKES_GROWTH: "TOTAL_LIKES_GROWTH",
  AUDIENCE_LOCATIONS: "AUDIENCE_LOCATIONS",
  AUDIENCE_LANGUAGE: "AUDIENCE_LANGUAGE",
  AUDIENCE_BRAND_AFFINITIES: "AUDIENCE_BRAND_AFFINITIES",
  AUDIENCE_INTEREST_AFFINITIES: "AUDIENCE_INTEREST_AFFINITIES",
  AUDIENCE_AGE: "AUDIENCE_AGE",
  CREATOR_LOOKALIKES: "CREATOR_LOOKALIKES",
  AUDIENCE_LOOKALIKES: "AUDIENCE_LOOKALIKES",
};

export const WORKPLATFORM_USER_HANDLE_MAPPING = {
  Instagram: "https://www.instagram.com/",
  TikTok: "https://www.tiktok.com/@",
  YouTube: "https://www.youtube.com/@",
};
export const TALK_TO_US = "Talk to us";
export const SIDEBAR_MENUS = {
  TALK_TO_US,
  HELP: "Help",
  FOR_DEVELOPERS: "For developers",
  YOUR_ACCOUNT: "Your account",
  DOCS: "Docs",
  FOR_MARKETERS: "For marketers",
  API_STATUS: "API status",
  USAGE: 'Usage',
  USAGE: 'Usage',
};

export const COMMENTS_ANALYSIS_TITLE_SUCCESS = "Comments analysis";
export const ANALYZING_COMMENTS = "Analyzing comments";
export const THIS_MIGHT_TAKE_SOME_TIME = "This might take some time. Please check back.";
export const COMMENTS_ANALYSIS_MESSAGE_SUCCESS = (numComments) =>
  `We analyzed ${numComments} comments. This doesn't include replies to the comments.`;
export const RELEVANT_COMMENTS = "Relevant comments";
export const RELEVANT_COMMENTS_MESSAGE = "Comments relevant to what the creator is talking about in their post.";
export const RELEVANT_COMMENTS_TOOLTIP = "Average comments per post, which are relevant.";
export const POSITIVE_AUDIENCE_SENTIMENT = "Positive audience sentiment";
export const POSITIVE_AUDIENCE_SENTIMENT_TOOLTIP = "Average comments per post, which have shown positive sentiment.";
export const PURCHASE_INTENT = "Purchase intent";
export const PURCHASE_INTENT_TOOLTIP = "Average comments per post, which have shown purchase intent.";
export const INTENT_TO_BUY_PRODUCT = "Intent to buy product";
export const INTENT_TO_BUY_PRODUCT_MESSAGE = "Comments in which people have shown intent to buy the product/service.";
export const AUDIENCE_SENTIMENT = "Audience sentiment";
export const AUDIENCE_SENTIMENT_MESSAGE = "Comments classified on the basis of the opinions and emotions behind them.";
export const RELEVANT = "Relevant";
export const NOT_RELEVANT = "Not relevant";
export const POSITIVE = "Positive";
export const NEUTRAL = "Neutral";
export const NEGATIVE = "Negative";
export const JOB_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  SUCCESS: "SUCCESS",
  IN_PROGRESS: "IN_PROGRESS",
  FAILURE: "FAILURE",
};
export const COMMENT_FAILURE_TITLE = "Couldn't get these details at the moment";
export const LEARN_HOW_AI = "Learn how we calculate this with AI";
export const LEARN_HOW_AI_TEXT =
  "We use AI to evaluate all comments on the post for their relevance to the post/brand, and the sentiment expressed within them.";
export const COMMENT_ANALYSIS_ADD_POST_INSTAGRAM = "Add post to a campaign for comment analysis data";
export const COMMENT_ANALYSIS_ADD_POST_INSTAGRAM_MESSAGE =
  "Comments analysis helps you understand the relevance and the sentiment behind the comments of the post by using AI.";
export const DELETE_CAMPAIGN_TITLE = "Delete campaign?";
export const DELETE = "Delete campaign";
export const REMOVE_POST_TITLE = "Remove post from campaign?";
export const REMOVE_POST_MESSAGE =
  "Once you remove this post, the associated post data will be lost. You can add back the post to the campaign later.";
export const DONT_REMOVE = "Don't remove";
export const csvRowsLimit = 101;
export const exportCSVReport = "Export CSV report";
export const exportPDFReport = "Export PDF report";
export const FILTER_SORTING_MAP = {
  FROM: "From",
  TO: "To",
};

// TRIAL MESSAGES
export const TRIAL = "TRIAL";
export const PAID = "PAID";

export const trialTopHeaderInfoCreator = {
  creatorSearchProductID: {
    intro: `
        You can analyze up to MAX_LIMIT creators in detail.
        <a href=${MEETING_SCHEDULE_LINK} target="_blank" rel="noreferrer">Contact us to upgrade</a>
        and get full access to 230M+ creators!
    `,
    currentUsage: `
        You can analyze USAGE in detail.
        <a href=${MEETING_SCHEDULE_LINK} target="_blank" rel="noreferrer">Contact us to upgrade</a>
        and get full access to 230M+ creators!
    `,
    fullUsage: `
        <a href=${MEETING_SCHEDULE_LINK} target="_blank" rel="noreferrer">Contact us to upgrade</a>
        and get full access to 230M+ creators!
    `,
  },
  creatorLinkageProductID: {
    intro: `
        You can connect up to MAX_LIMIT creator profiles.
        <a href=${MEETING_SCHEDULE_LINK} target="_blank" rel="noreferrer">Contact us to upgrade</a>
        and invite all the creators you need.
    `,
    currentUsage: `
        You can connect USAGE profiles.
        <a href=${MEETING_SCHEDULE_LINK} target="_blank" rel="noreferrer">Contact us to upgrade</a>
        and invite all the creators you need.
    `,
    fullUsage: `
        <a href=${MEETING_SCHEDULE_LINK} target="_blank" rel="noreferrer">Contact us to upgrade</a>
        and connect all the creators you need.
    `,
  },
  campaignTrackingProductID: {
    intro: `Create a campaign and explore our sample campaigns. Contact us to upgrade to create more campaigns!`,
  },
  compareCreatorProductID: {
    intro: `Explore our sample comparison below. <a href=${MEETING_SCHEDULE_LINK} target="_blank" rel="noreferrer">Contact us to upgrade</a> and to compare creators of your choice.`,
  },
};

export const trialTopHeaderInfoCreatorDeveloper = {
  Staging: (
    <>
      Enjoy your free access in the staging environment.{" "}
      <a href={MEETING_SCHEDULE_LINK} target="_blank" rel="noreferrer">
        Contact us for production access
      </a>
    </>
  ),
  Sandbox: (
    <>
      Sandbox gives you access to sample data.{" "}
      <a href={MEETING_SCHEDULE_LINK} target="_blank" rel="noreferrer">
        Contact us for production access
      </a>
    </>
  ),
};
export const creatorModuleModalContent = [
  "Discover unlimited creators, analyze up to 3 of them in detail.",
  "Connect up to 25 creator profiles and get updates on their profile and content metrics.",
  "Explore campaigns and analyze the performance of any social media post.",
];

export const developerModuleModalContent = ["25 connected accounts for authenticated data", "10 API calls for public data"];

export const nonWorkEmails = [
  "protonmail.com",
  "outlook.com",
  "gmail.com",
  "yahoo.com",
  "icloud.com",
  "aim.com",
  "aol.com",
  "gmx.us",
  "gmx.com",
  "yandex.com",
  "zoho.com",
  "mailinator.com",
  "opa.marketing",
];

export const FAILED = "FAILED";
export const exportingCsv = "Exporting CSV...";
export const exportingPdf = "Exporting PDF...";
export const fileDownloadWillStart = "File download will start in a few seconds";
export const DEFAULT_CREATOR_IDENTIFIER = "skinlovebyd";
export const EXPORT_REPORT = "Export report";
export const HTTPS_TEXT = "https://www.";
export const PLATFORM_DOMAIN_MAPPING = {
  "14d9ddf5-51c6-415e-bde6-f8ed36ad7054": "youtube.com",
  "9bb8913b-ddd9-430b-a66a-d74d846e6c66": "instagram.com",
  "de55aeec-0dc8-4119-bf90-16b3d1f0c987": "tiktok.com",
};
export const PLATFORM_REGEX = (domain) => new RegExp(`^(https?:\/\/)?(?:www\.)?${domain}(?:\/@?)([A-Za-z0-9_.-]+)(.*)$`);
export const CREATOR_PROFILE_LINK = (identifier, workplatformId, hash = "") =>
  `/creators/creator-search/profile?identifier=${identifier}&workplatformId=${workplatformId}${hash}`;

export const createNewListButtonText = (
  <>
    <i class="ri-add-line"></i> Create new list
  </>
);
export const createNewList = "Create new list";
export const NoCreatorListTittle = "Save all your favorite creators in one place";
export const NoCreatorListDesc = "Create lists to shortlist creators for a campaign, share with your team, or just to bookmark for later.";

export const NoCreatorsTitle = "Start adding creators";
export const NoCreatorsDesc = "Add creators directly from the results or from their profile analysis page.";

export const creators = "creators";
export const DONT_DELETE = "Don’t delete";

export const getHeaderDescription = (isCreatorOnboarded) => {
  if (isCreatorOnboarded === null) {
    return "Onboard creators and monitor their first-party account stats.";
  } else {
    return "This dashboard will show creator accounts that you onboard by sending email invitations. ";
  }
};

export const creatorLinkageInfoText = () => {
  return (
    <div className="info-card-tooltip-container">
      <div className="info-card-content-container">
        <div className="info-card-header">
          <div>
            <i class="ri-information-line info-icon"></i>
          </div>
          <div className="info-card-header-text">Please note</div>
        </div>
        <div className="info-card-description">
          <ul>
            <li>This dashboard will show creator accounts that you onboard by sending email invitations from here.</li>
            <li>
              It will <b className="bold-font">NOT</b> show accounts connected via <b className="bold-font">Connect APIs.</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export const CUSTOM_DATE_LABEL = 'Custom';
export const calendarTabSwitchLabels = (customLabel) => {
  return [{
    label: "7D",
  },
  {
    label: "30D",
  },
  {
    label: "3M",
  },
  {
    label: "6M",
  },
  {
    label: "12M",
  },
  {
    label: customLabel,
    icon: <i className="ri-calendar-line" style={{ fontSize: "20px" }}></i>,
  },
  ];
};


export const DATE_PICKER_LABELS = {
  START_DATE: "Start date",
  END_DATE: "End date",
  DATE_FORMAT: "MMM DD, YYYY",
  DATE_PLACEHOLDER: "DD / MM / YY",
  ACTION_LABELS: ["clear"],
  START_DATE_TYPE: "start-date",
  END_DATE_TYPE: "end-date",
};

export const productsPageTitle = "Go to market faster with our social APIs";
export const productsPageSubTitle = "Choose from social APIs & SDKs and integrate them into your product ";
export const searchProducts = "Search products";
export const BETA = "BETA";
export const COMING_SOON = "COMING SOON";
export const betaProductClassName = "beta-product";
export const comingSoonClassName = "coming-soon-product";
export const MEASUREMENT = "Measurement";
export const measurementDataType = "Authenticated data";
export const measurementDescription = "Generate UTM links, promo codes, view analytics and track ROI metrics";
export const docs = "Docs";
export const productsTabText = "Products";
export const profilesTabText = "Profiles";
export const AUTHENTICATED_DATA = "Authenticated data";
export const PUBLIC_DATA = "Public data";
export const TALK_TO_US_DESC = "For any help, issues, product demos, or production access";
export const IMPORTANT_LINKS = "Important links";

export const PLAYGROUND = "Playground";
export const ENVIRONMENT = {
  PRODUCTION: "Production",
  STAGING: "Staging",
  SANDBOX: "Sandbox",
};
export const PRODUCTION_ACCESS_LABEL = {
  REQUEST_ACCESS: "Request access",
  REQUESTED: "Requested",
};

export const environmentSelectionColorPallets = (environment, isTextColorPalletRequired) => {
  if (isTextColorPalletRequired) {
    switch (environment) {
      case ENVIRONMENT.STAGING:
        return {
          color: "#2262ED",
        };
      case ENVIRONMENT.PRODUCTION:
        return {
          color: "#014550",
        };
      default:
        return {
          color: "#926100",
        };
    }
  } else {
    switch (environment) {
      case ENVIRONMENT.STAGING:
        return {
          borderColor: "#80A5F4",
          backgroundColor: "#DEE8FC",
        };
      case ENVIRONMENT.PRODUCTION:
        return {
          borderColor: "#04DB8E",
          backgroundColor: "#E0F9EC",
        };
      default:
        return {
          borderColor: "#C8AB71",
          backgroundColor: "#FFF5E2",
        };
    }
  }
};

export const HELP_LABEL = "Support & sales";
export const EMAIL_US = "Email us";
export const SCHEDULE_A_CALL = "Schedule a call";
export const SHORT_LINK = 'Short link';
export const API_KEYS_PAGE_HEADER = "API keys";
export const WEBHOOK_PAGE_HEADER = "Webhooks";
export const TAGS = 'Tags';
export const SHORT_LINK_COPIED = 'Short link copied to clipboard';

export const campaignLinksGenerated = "Campaign links generated";
export const totalLinkClicks = "Total link clicks";
export const SEARCH_LINKS_PLACEHOLDER = "Search campaigns, links";
export const CAMPAIGN_LINKS_TITLE = "Campaign links";
export const CAMPAIGN_LINKS_DESC = "Generate UTM-based links, track link usage and analytics";
export const CAMPAIGN_LINKS_HEADERS = {
  id: "ID",
  link: "Link",
  short_link: "Short link",
  clicks: "Clicks",
  group: "Group",
  tags: "Tags",
  created_on: "Created on",
};
export const linkHeader = "Link";
export const shortLinkHeader = "Short link";
export const clicksHeader = "Clicks";
export const groupHeader = "Group";
export const tagsHeader = "Tags";
export const createdOnHeader = "Created on";


export const PROMOCODES_PLACEHOLDER = "Search promo codes";
export const PROMOCODES_GENERATED_TEXT = "Promo codes generated";
export const PROMOCODES_USED_TEXT = "Promo codes used";
export const TOTAL_SALES_TEXT = "Total sales";
export const PROMOCODES_TABLE_CONTENT = {
  PROMOCODE_HEADER: "Promo code",
  SHOP_URL_HEADER: "Shop URL",
  STATUS_HEADER: "Status",
  GROUP_HEADER: "Group",
  TAGS_HEADER: "Tags",
};
export const PROMOCODE_DROPDOWN_LABEL = "Store";
export const getTagsAdditionalText = (tagsLength) => {
  if (tagsLength < 1) return;
  return `+${tagsLength} more`;
};

export const userLogsAnalyticsTabValues = {
  campaignLinks: "Campaign links",
  promocodes: "Promo codes"
};

export const userLogsCurrentTabSearchPlaceholder = {
  "Campaign links": "Search campaigns, links",
  "Promo codes": "Search promo codes"
};

export const EXPORT_CSV = 'Export CSV';
export const NO_CAMPAIGN_LINKS = 'No campaign links found.';
export const CSV_EXPORT_LIMIT = 1000;
export const SHORT_LINK_DOMAINS = 'Short link domains';
export const ALL = 'All';

export const oncePerCustomer = "One use per customer";
export const MIN_QTY = "Min qty";
export const MINIMUM_QUANTITY = "Minimum quantity";
export const EXPIRED = "EXPIRED";
export const PROMOCODE_STATUS_MAPPING = {
  ENABLED: "Active",
  DISABLED: "Expired",
  EXPIRED: "Expired"
};
export const AMOUNT = "AMOUNT";
export const PERCENTAGE = "PERCENTAGE";

export function formatCurrency(value, currencyCode) {
  // It's recommended to match the locale with the currency for proper format,
  // but 'en-US' can be used as a generic locale that supports all currencies.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol'
  });

  return formatter.format(value);
}
export const COLLECTIONS = (collectionsLength) => { return collectionsLength > 1 ? 'collections' : 'collection'; };
export const ITEM = (itemLength) => { return itemLength > 1 ? 'items' : 'item'; };
export function AMOUNT_TYPE_DESC(value) { return `${value} off`; }
export function PERCENTAGE_TYPE_DESC(value, collectionLen, productLen) { return `${value}% off ${collectionLen} ${productLen}`; }
export const DEFAULT = 'Default';
export const UPDATED_PERIODICALLY = 'Details are refreshed periodically. Reach out to your Account Manager for more information.';
export const MEASUREMENT_DATE_FORMAT = 'DD MMM, YYYY';
export const NO_CAMPAIGN_LINKS_AVAILABLE = 'No campaign links available yet';
export const WE_WILL_SHOW_THEM = 'We will show them here as soon as we get the data.';
export const NO_PROMOCODES_AVAILABLE = 'No promo codes available yet';
export const DATE_PICKER_POP_UP_HEADER = 'Select your custom date range';
export const TODAY_DATE_FORMAT = 'dd MMM, yyyy';
export const ONLY_END_DATE_MESSAGE = 'Showing all data till';

export const WE_COULDNT_FIND_ANY_CAMPAIGN_LINKS = 'We couldn\'t find any campaign links.';
export const WE_COULDNT_FIND_ANY_PROMOCODES = 'We couldn\'t find any promo codes.';

export const MEASUREMENT_PRODUCTS_API_DATE_FORMAT = 'yyyy-MM-dd';
export const CURR_SELECTED_ENV_KEY = "CURR_SELECTED_ENV";

export const WEBHOOK_CLEAR_SELECTED_EVENTS = "Clear selected events";
export const WEBHOOK_DEFAULT_EXPANDED_KEYS = ["root", "ACCOUNTS"];
export const WEBHOOK_EVENT = "event";
export const WEBHOOK_SELECTED = "selected";
export const WEBHOOK_EVENTS_LABEL = "Events";
export const WEBHOOK_REQUIRED_FIELDS = ["name", "url"];
export const WEBHOOK_SEARCH_PLACEHOLDER = "Search events";
export const WEBHOOK_ROOT_KEY = "root";
export const WEBHOOK_INFO_HEADER = "Please note";
export const WEBHOOK_INFO_CONTENT = "Immediately upon receiving the webhook, first thing you should do is verify the signature, then return a succesful response (200 OK) as acknowledgement.";
export const WEBHOOK_DOCS_LINK = "https://docs.insightiq.ai/docs/api-reference/API/webhooks-guide#timeout";
export const WEBHOOK_HEADER_STICKY_SCROLL_TOP = 150;
export const WEBHOOK_HEADER_STICKY_SCROLL_OFFSET = 40;
export const WEBHOOK_CANCEL_BUTTON_TEXT = "Cancel";
export const WEBHOOK_SAVE_BUTTON_TEXT = "Save changes";
export const WEBHOOK_CREATE_BUTTON_TEXT = "Create webhook";
export const LEARN_MORE_TEXT = "Learn more";

export const RESET_PASSWORD_MODAL = {
  title: 'Reset password via email',
  description: (email) => <span>
    {`We've sent you a link on `}
    <span className={'color-neutrals-primary-grey resend-email-id'}>{email}</span>
    {` to reset your password.`}
  </span>,
  bullet1: `Don't forget to check the spam folder`,
  bullet2: `Link will be valid for 15 minutes only`,
  didntReceive: `Didn't receive the email?`,
  resend: `Resend it`,
  emailSent: (email) => `Resent the email to ${email}`,
};

export const LOGOUT = 'Logout';
export const RESET_PASSWORD = 'Reset password';

export const EMAIL_COPIED = 'Email copied to clipboard';
export const DROP_US_AN_EMAIL = 'or email us at ';
export const IF_QUESTIONS_DROP_US_AN_EMAIL = 'If you have questions,  email us at ';

export const LINEID = 'LINEID';
export const LINKEDIN = 'LinkedIn';
export const TWITTER = 'TWITTER';
export const XCOM = 'X.com';
export const LINE = 'Line';
export const SUPPORT_EMAIL_ID = 'support@insightiq.ai';
export const EMAIL_ERROR_MSG = 'Please enter a valid email';
export const URL_AMBERFLO_USAGE = 'https://app.amberflo.io/session';

export function formatNumberWithCommas(x) {
  // Ensure the input is a string
  x = x.toString();

  // Split the input into integer and decimal parts
  let parts = x.split('.');
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? '.' + parts[1] : '';

  // Add commas to the integer part (Indian numbering system)
  let lastThree = integerPart.slice(-3);
  let otherNumbers = integerPart.slice(0, -3);

  if (otherNumbers !== '') {
    lastThree = ',' + lastThree;
  }

  let formattedInteger = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

  return formattedInteger + decimalPart;
}

export const COMPARE_CREATOR_PAGE_HEADER = (numberOfComparison) => `Comparison: ${numberOfComparison} ${numberOfComparison > 1 ? 'creators' : 'creator'}`;
export const SHARE_BUTTON_TEXT = 'Share';
export const FULL_PROFILE_LINK_TEXT = 'Full profile';
export const PROFILE_BIO_TEXT = (profileBio) => `Bio: ${profileBio}`;
export const SHARE_LINK = 'Share link';
export const DOWNLOAD_PDF = 'Download PDF';

export const creatorSearchTabs = {
  filters: "Search by filters",
  profileSearch: "Search by profile",
  compareCreators: "Compare creators",
};

export const creatorSearchTabLabels = [
  {
    label: creatorSearchTabs.filters,
    icon: <i class="ri-filter-line ri-lg" />,
  },
  {
    label: creatorSearchTabs.profileSearch,
    icon: <i class="ri-user-search-line ri-lg" />,
  },
  {
    label: creatorSearchTabs.compareCreators,
    icon: <i class="ri-arrow-left-right-line ri-lg" />
  }
];

export const COMPARE_CREATORS_SUBHEADING = "Enter creators' name, handle, or profile URL";
export const COMPARE_CREATORS_SEARCH_TEXT = {
  creator1: 'Creator 1',
  creator2: 'Creator 2',
  creator3: 'Creator 3',
};

export const COMPARE_CREATORS_BUTTON_TEXT = (
  <div className='compare-creators-button-text'>
    Compare <i class='ri-arrow-right-line' />
  </div>
);

export const COMPARE_CREATOR_INPUT_DEFAULT_STATE = [
  { placeholder: COMPARE_CREATORS_SEARCH_TEXT.creator1, label: COMPARE_CREATORS_SEARCH_TEXT.creator1, defaultSelectedCreator: null },
  { placeholder: COMPARE_CREATORS_SEARCH_TEXT.creator2, label: COMPARE_CREATORS_SEARCH_TEXT.creator2, defaultSelectedCreator: null },
  { placeholder: COMPARE_CREATORS_SEARCH_TEXT.creator3, label: COMPARE_CREATORS_SEARCH_TEXT.creator3, defaultSelectedCreator: null }
];

export const CREATOR_AUDIENCE_ATTRIBUTE_MAPPING = {
  YouTube: {
    attributeName: "subscriber_count",
    metricName: SUBSCRIBERS
  },
  Instagram: {
    attributeName: "follower_count",
    metricName: FOLLOWERS
  },
  TikTok: {
    attributeName: "follower_count",
    metricName: FOLLOWERS
  }
};

export const COMPARE_CREATOR_DISCRIPTION_MAPPING = {
  platform_account_type: "Account types such as PERSONAL, CREATOR, BUSINESS, etc.",
  gender: "Creator's gender",
  age_group: "Creator's age group",
  language: "Creator's language",
  follower_count: "Followers",
  subscriber_count: "Subscribers",
  average_likes: "Average likes per post",
  average_comments: "Average comments per post",
  average_views: "Average views per video",
  average_reels_views: "Average views per reel",
  engagement_rate: "Engagement rate",
  country: "Creator's country",
  state: "Creator's state",
  city: "Creator's city",
  gender_distribution: "Gender of the audience and its value. Allowed values: MALE, FEMALE, OTHERS.",
  age_distribution: "Age range and what percent of the audience belong to the age range",
  popular_hashtags: "Hashtags frequently used by the creator in their content",
  popular_tags: "Accounts frequently tagged by the creator in their content",
  interests: "Interests and topics that the creator posts about",
  brands: "tool tip not defined",
  follower_count_graph: "Monthly follower count",
  subscriber_count_graph: "Monthly subscriber count",
  following_count_graph: "Monthly following count",
  average_likes_graph: "Monthly average likes count",
  sponsored_posts_performance: "Engagement rate (sponsored posts)",
  creator_brand_affinity: "Brands creator interact with",
};

export const COMPARE_CREATOR_LINK = (comparisonId) =>
  `/creators/creator-search/compare-creators/${comparisonId}`;