import { Avatar, Box } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
    AddToCampaignDialog,
    CommentAnalysisNotSupported,
    CommentsAnalysis,
    EmptyTableScreen,
    ExportLoadingModal,
    Icons,
    InfoActionBanner,
    InputField,
    Loader,
    PerformanceMetric,
    PrimaryButton,
    Separator,
    TextSwitchingLoader,
    UnlockFullAccessPopUp,
} from "../../components";
import {
    campaignTrackingProductID,
    COMMENTS_ANALYSIS_TITLE_SUCCESS,
    DATE_TIME_FORMAT_STATUS,
    exportingPdf,
    exportPDFReport,
    FAILED,
    MEETING_SCHEDULE_LINK,
    TRIAL,
    WORKPLATFORM_ID_MAPPING,
    WORKPLATFORM_IDS,
} from "../../constants/constants";
import "./AnalysisPage.scss";
import parse from "html-react-parser";
import {
    ADD_BRAND_ACCOUNTS,
    ADD_TO_CAMPAIGN,
    analysis_loading_msg,
    analysisPageTooltip,
    audienceReportStates,
    CAMPAIGN_BRAND_PROFILES_DONE_TITLE,
    CAMPAIGN_BRAND_PROFILES_TITLE,
    campaignLoaderInterval,
    campaignLoaderTexts,
    comments,
    CONTENT_AUDIENCE_DEMOGRAPHICS,
    contentPages,
    dotSymbol,
    EDIT_BRAND_ACCOUNTS,
    engagement_rate,
    estimated,
    estimated_reach,
    GENERATED,
    getFormatedCampaignURL,
    impressions,
    IN_PROGRESS,
    likes,
    NOT_GENERATED,
    NOT_SUPPORTED,
    PaidPartnershipWith,
    performance,
    posted,
    postNotSupported,
    privatePost,
    privateProfile,
    retry_later,
    sampleReportModalDetails,
    shares,
    someExistenceErrorMsgDesc,
    someExistenceErrorMsgTitle,
    somethingWentWrongMsgDesc,
    somethingWentWrongMsgTitle,
    story,
    SYNC_STATUS,
    tooltip,
    TOP_HEADER_INFO_ERROR,
    TOP_HEADER_INFO_TRIAL_MODE_CAMPAIGN_CONTENT,
    TOP_HEADER_INFO_UPDATED,
    topRelevantComments,
    total_engagement,
    trackingPage,
    TRIAL_MODE,
    unlockAccessPopupDetails,
    URL_ERROR_MESSAGES,
    view_post,
    views,
} from "../../constants/campaignTrackingConstants";
import { CampaignTrackingContext, WarehouseContext } from "../../storage/context";
import {
    addContentToCampaign,
    createAudienceReport,
    downloadCampaignContentPdf,
    getBrandByID,
    getCampaignById,
    getCampaignContentById,
    getCampaignContentData,
    validateFeatures,
} from "../../api/CampaignTrackingAPI/CampaignTrackingAPI";
import {
    formatNumber,
    getNewImageUrl,
    getWorkPlatformIdByPlatformName,
    getWorkPlatformIDFromURL,
    percentFormatter,
    validatePostUrl
} from "../../utils/profileHelpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    ADD_USAGE_QUOTA,
    HIDE_TOP_HEADER,
    SET_CAMPAIGN_URL,
    SHOW_TOP_HEADER,
    UPDATE_FEATURE
} from "../../storage/reducers/types";
import { Colors } from "../../styles/colors";
import { convertTimeToLocale, downloadFile, generateCampaignTitle, isEmpty } from "../../utils/util";
import {
    trackEvent,
    TRACKING_CAMPAIGN_AUDIENCE_REPORT_CLICKED,
    TRACKING_CONTENT_BACK_CLICKED,
    TRACKING_CONTENT_VIEW_POST_CLICKED,
    TRACKING_UPGRADE_POPUP_CLOSED,
    TRACKING_URL_ADD_TO_CAMPAIGN_CLICKED,
    TRACKING_URL_ANALYSIS_ERROR,
    TRACKING_URL_ANALYSIS_SUCCESS,
    TRACKING_URL_SAVED_TO_CAMPAIGNS_CLICKED
} from "../../analytics";
import AddBrandProfilesPopup from "../../components/AddBrandProfilesPopup/AddBrandProfilesPopup";
import { Outline } from "../../components/CommentsAnalysis/CommentsAnalysis";
import { useFetchTenantUsageInfo } from "../../hooks/useFetchTenantUsageInfo";
import GenericPopup from "../../components/GenericPopup/GenericPopup";
import moment from 'moment-timezone';

const formatSentence = (sentence) => {
    const words = sentence?.split(" ");
    if (!words) return "";
    return words
        .map((word) => {
            if (word.startsWith("@") || word.startsWith("#") || word.includes("#") || word.includes("@")) {
                return `<span style="color: var(--main-primary);">${word}</span>`;
            } else {
                return word;
            }
        })
        .join(" ");
};

const engagementMetrics = [
    {
        name: likes,
        icon: <i className="ri-heart-3-line metric-icon"></i>,
        path: "like_count",
        variant: "metricIcon",
    },
    {
        name: comments,
        icon: <i className="ri-chat-3-line metric-icon"></i>,
        path: "comment_count",
        variant: "metricIcon",
    },
    {
        name: views,
        icon: <i className="ri-eye-line metric-icon"></i>,
        path: "view_count",
        variant: "metricIcon",
    },
    {
        name: shares,
        icon: <i className="ri-share-forward-line metric-icon"></i>,
        path: "share_count",
        variant: "metricIcon",
    },
];

const performanceMetrics = [
    {
        name: engagement_rate,
        path: "engagement_rate",
        variant: "infoIcon",
        tooltip: analysisPageTooltip.engagement_rate,
    },
    {
        name: total_engagement,
        path: "total_engagement",
        variant: "infoIcon",
        tooltip: tooltip.total_engagement,
    },
    {
        name: estimated_reach,
        path: "estimated_reach",
        variant: "infoIcon",
        tooltip: analysisPageTooltip.estimated_reach,
    },
    {
        name: impressions,
        path: "estimated_impressions",
        variant: "infoIcon",
        tooltip: analysisPageTooltip.estimated_impressions,
    },
];

const storyPerformanceMetrics = [
    {
        name: engagement_rate,
        path: "engagement_rate",
        variant: "infoIcon",
        tooltip: analysisPageTooltip.storyEngagementRate,
        isPercentage: true
    },
    {
        name: `${estimated + ' ' + impressions}`,
        path: "estimated_impressions",
        variant: "infoIcon",
        tooltip: analysisPageTooltip.storyEstimatedImpressions,
        isPercentage: false
    },
];

function AnalysisPage() {
    const { campaignId, contentId } = useParams();
    const { dispatchCampaignTrackingWarehouse, campaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const [campaignContentData, setCampaignContentData] = useState(null);
    const [profileFollowerCount, setProfileFollowerCount] = useState(null);
    const [contentLoading, setContentLoading] = useState(true);
    const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
    const [inputErrorState, setInputErrorState] = useState({
        hasError: false,
        errorMessage: "",
    });
    const location = useLocation();
    const [showCampaignError, setshowCampaignError] = useState({
        hasError: false,
        errorMessage: "",
    });
    const [pageName, setPageName] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [reportStatus, setReportStatus] = useState(NOT_GENERATED);
    const isTrialMode = warehouse.tenantAppProducts[campaignTrackingProductID] === TRIAL;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [reportStatusButtonLoading, setReportStatusButtonLoading] = useState(false);
    const [brandSocialsCount, setBrandSocialsCount] = useState(null);
    const [showAddBrandProfilesPopup, setShowAddBrandProfilesPopup] = useState(false);
    const [socialsJustAdded, setSocialsJustAdded] = useState(false);
    const [campaignBrand, setCampaignBrand] = useState(null);
    const [isTrialCampaign, setIsTrialCampaign] = useState(false);
    const [isTrialGenerateReportModalOpen, setIsTrialGenerateReportModalOpen] = useState(false)
    const { campaignURL } = campaignTrackingWarehouse;
    const fetchTenantUsageInformation = useFetchTenantUsageInfo();
    const {
        usage: campaignsCreated,
    } = warehouse.tenantUsageQuota?.quotaInfoCreatorModule?.CAMPAIGNS_CREATED || {};
    const [modalDetails, setModalDetails] = useState({
        isModalVisible: false,
        modalInfo: "",
    });
    const [exportModalState, setExportModalState] = useState({ key: '', value: '' });
    const [isExportLoadingModalVisible, setExportLoadingModalVisible] = useState(false);

    const exportPdfState = {
        key: 'PDF',
        value: exportingPdf,
    };

    function setCampaignURL(payload) {
        dispatchCampaignTrackingWarehouse({
            type: SET_CAMPAIGN_URL,
            payload,
        });
    }

    const fetchContentData = async () => {
        setshowCampaignError({
            hasError: false,
            errorMessage: "",
        });
        setContentLoading(true);
        try {
            const workPlatformID = getWorkPlatformIDFromURL(campaignURL);
            // remove the utm source from instagram url
            let formatedCampaignURL = getFormatedCampaignURL(campaignURL);

            const responseData = await getCampaignContentData(workPlatformID, formatedCampaignURL, warehouse);
            // check response.data obj is empty or not
            if (isEmpty(responseData) || isEmpty(responseData.data)) {
                handleContentFetchError(responseData);
                trackEvent(TRACKING_URL_ANALYSIS_ERROR, { url_name: campaignURL, error_message: responseData.message })
            } else {
                const platformID = getWorkPlatformIDFromURL(campaignURL)
                trackEvent(TRACKING_URL_ANALYSIS_SUCCESS, {
                    url_name: campaignURL,
                    platform_name: WORKPLATFORM_ID_MAPPING[platformID].platformName
                });
            }
            setCampaignContentData(responseData?.data);
            setProfileFollowerCount(responseData?.profile_follower_count);
            setContentLoading(false);
        } catch (error) {
            console.error("error", error);
            handleContentFetchError(error);
        }
    };
    const handleContentFetchError = (error) => {
        if (error.error_code === retry_later.errorCode) {
            setshowCampaignError({
                hasError: true,
                errorMessage: {
                    icon: <Icons.details_not_found/>,
                    title: retry_later.title,
                    desc: retry_later.desc,
                },
            });
        } else if (error.error_code === privatePost.errorCode) {
            setshowCampaignError({
                hasError: true,
                errorMessage: {
                    icon: <Icons.existence_error/>,
                    title: privatePost.title,
                    desc: privatePost.desc,
                },
            });
        } else if (error.error_code === privateProfile.errorCode) {
            setshowCampaignError({
                hasError: true,
                errorMessage: {
                    icon: <Icons.existence_error/>,
                    title: privateProfile.title,
                    desc: privateProfile.desc,
                },
            });
        } else if (error.status_code === postNotSupported.errorCode) {
            setshowCampaignError({
                hasError: true,
                errorMessage: {
                    icon: <Icons.post_not_supported/>,
                    title: postNotSupported.title,
                    desc: postNotSupported.desc,
                },
            });
        } else if (error.status_code === 404 || error.errorCode === 'entity_not_found') {
            setshowCampaignError({
                hasError: true,
                errorMessage: {
                    icon: <Icons.existence_error/>,
                    title: someExistenceErrorMsgTitle,
                    desc: someExistenceErrorMsgDesc,
                },
            });
        } else {
            setshowCampaignError({
                hasError: true,
                errorMessage: {
                    icon: <Icons.something_went_wrong/>,
                    title: somethingWentWrongMsgTitle,
                    desc: somethingWentWrongMsgDesc,
                },
            });
        }
        setContentLoading(false);
    };
    const fetchCampaignContentData = async () => {
        setshowCampaignError({
            hasError: false,
            errorMessage: "",
        });
        setContentLoading(true);
        try {
            const responseData = await getCampaignContentById({ warehouse, campaignId, contentId });
            // check response.data obj is empty or not
            if (isEmpty(responseData) || isEmpty(responseData.data)) {
                handleContentFetchError(responseData);
                return;
            }
            setCampaignContentData(responseData?.data);

            const isAudienceReportSupported = await validateCampaignFeatures();

            if (isTrialMode && responseData?.data?.audience_report?.status === GENERATED) {
                setReportStatus(TRIAL_MODE);
                return;
            }
            if (!isAudienceReportSupported) {
                setReportStatus(NOT_SUPPORTED);
                return;
            }

            const { audience_report } = responseData?.data;
            if (isEmpty(audience_report)) {
                return;
            }
            let { status } = audience_report;
            if (status === FAILED) {
                status = NOT_GENERATED
            }
            setReportStatus(status);
        } catch (error) {
            console.error("e", error);
            handleContentFetchError(error);
        } finally {
            setContentLoading(false);
        }
    };


    const validateCampaignFeatures = async () => {
        try {
            let feature = CONTENT_AUDIENCE_DEMOGRAPHICS;
            const response = await validateFeatures({ warehouse, feature });
            if (response[CONTENT_AUDIENCE_DEMOGRAPHICS]) {
                const payload = { ...campaignTrackingWarehouse.features, [CONTENT_AUDIENCE_DEMOGRAPHICS]: true };
                dispatchCampaignTrackingWarehouse({
                    type: UPDATE_FEATURE,
                    payload,
                });
                return true;
            }
            return false;
        } catch (error) {
        }
    };

    useEffect(() => {
        let pathArray = location.pathname.split("/");
        if (pathArray[3] === contentPages.contentView) {
            if (!campaignURL) navigate("/creators/campaigns");
            dispatchWarehouse({ type: HIDE_TOP_HEADER });
            setPageName(contentPages.contentView);
        } else {
            // Todo : addd condition for undefined and id
            setPageName(contentPages.campaignContentView);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (!!!pageName) {
            return;
        }
        switch (pageName) {
            case contentPages.contentView:
                fetchContentData().then(() => {
                });
                break;
            case contentPages.campaignContentView:
                fetchCampaignContentData().then(() => {
                    validateCampaignFeatures().then(() => {
                    });
                });
                break;
            default:
                break;
        }
    }, [pageName]);

    const calcTotalEngagement = () => {
        const { like_count = 0, comment_count = 0, share_count = 0 } = campaignContentData?.engagement || {};
        return Number(like_count) + Number(comment_count) + Number(share_count);
    };

    function handleAnalyze() {
        setInputErrorState({ hasError: false, errorMessage: "" });
        setInputErrorState({ hasError: false, errorMessage: "" });
        if (validatePostUrl(campaignURL)) {
            fetchContentData().then(() => {
            });
        } else {
            setInputErrorState({ hasError: true, errorMessage: URL_ERROR_MESSAGES.URL_Validation });
        }
    }

    const handleEnterKeyPress = (event) => {
        if (event.key === "Enter") {
            const analyzeButton = document.querySelector(".analyse-button");
            analyzeButton.click();
        }
    };

    useEffect(() => {
        fetchTenantUsageInformation().then((usageInfo) => {
            dispatchWarehouse({ type: ADD_USAGE_QUOTA, payload: usageInfo });
        });
        dispatchWarehouse({
            type: HIDE_TOP_HEADER,
        });
    }, []);

    useEffect(() => {
        if (isEmpty(campaignContentData) || isEmpty(campaignId)) {
            return;
        }

        if (!isFromCampaignView()) {
            return;
        }

        getCampaignBrandDetails().then(() => {
        });

    }, [campaignContentData, campaignId]);

    useEffect(() => {

        if (isTrialCampaign) {
            dispatchWarehouse({
                type: SHOW_TOP_HEADER,
                payload: {
                    scrollable: true,
                    title: TOP_HEADER_INFO_TRIAL_MODE_CAMPAIGN_CONTENT,
                    backgroundColor: Colors.accentMidnightGreen,
                    textClass: "top-header-status-text top-header-status-text-success",
                },
            });
            return;
        }

        if (isEmpty(campaignContentData)) {
            return;
        }
        const { synced_at, sync_status } = campaignContentData;
        if (synced_at) {
            if (sync_status === SYNC_STATUS.FAILED) {
                dispatchWarehouse({
                    type: SHOW_TOP_HEADER,
                    payload: {
                        scrollable: true,
                        title: TOP_HEADER_INFO_ERROR(convertTimeToLocale(synced_at, DATE_TIME_FORMAT_STATUS)),
                        backgroundColor: Colors.semanticsSecondaryError,
                        textClass: "top-header-status-text top-header-status-text-error",
                    },
                });
            } else {
                dispatchWarehouse({
                    type: SHOW_TOP_HEADER,
                    payload: {
                        scrollable: true,
                        title: TOP_HEADER_INFO_UPDATED(convertTimeToLocale(synced_at, DATE_TIME_FORMAT_STATUS), 12),
                        backgroundColor: Colors.lightGreen,
                        textClass: "top-header-status-text top-header-status-grey-text-success",
                    },
                });
            }
        } else {
            // Just a fail-safe. This should not happen
            dispatchWarehouse({
                type: HIDE_TOP_HEADER,
            });
        }
    }, [isTrialCampaign, campaignContentData]);

    async function getCampaignBrandDetails() {
        const campaign = await getCampaignById({ warehouse, campaignId });

        const { brand_id, is_trial } = campaign;
        setIsTrialCampaign(!!is_trial);

        const { id, name, profiles } = await getBrandByID({ warehouse, brand_id });
        setCampaignBrand({ name, value: id });

        const { work_platform_id } = campaignContentData;
        // If this post's platform has a social linked under the brand
        if (profiles.some(profile => profile.work_platform_id === work_platform_id && !isEmpty(profile.profile_url))) {
            setBrandSocialsCount(1);
        } else {
            setBrandSocialsCount(0);
        }
    }

    function isFromCampaignView() {
        return pageName === contentPages.campaignContentView;
    }

    function CampaignContentViewTopBar() {
        return (
            <Box sx={{ marginBottom: "24px" }}>
                <i
                    className="ri-arrow-left-line campaign-view-back-icon"
                    onClick={() => {
                        setCampaignURL("");
                        const { url, work_platform_id } = campaignContentData
                        trackEvent(TRACKING_CONTENT_BACK_CLICKED, {
                            url_name: url,
                            platform_name: WORKPLATFORM_ID_MAPPING[work_platform_id].platformName
                        })
                        navigate(-1);
                    }}
                ></i>
            </Box>
        );
    }

    function ContentViewHeader({
                                   workPlatformId,
                                   type,
                                   platformUsername,
                                   is_verified,
                                   published_at,
                                   collaborators,
                                   sponsors
                               }) {

        useEffect(() => {
            if (!isExportLoadingModalVisible) {
                return;
            }
            if (exportModalState.key === 'PDF') {
                downloadCampaignContentPdf({
                    warehouse,
                    campaignId,
                    contentId,
                    end_user_timezone: moment.tz.guess(true),
                })
                    .then((response) => {
                        if (!response) {
                            throw new Error('Could not download campaign content PDF');
                        }
                        downloadFile(response, `campaign-content-pdf-report-${campaignId}-${contentId}.pdf`, 'application/pdf');
                    })
                    .catch(console.error)
                    .finally(() => setExportLoadingModalVisible(false));
            }
        }, [isExportLoadingModalVisible]);


        const handleOpenModal = () => {
            setIsModalOpen(true)
            const platformID = getWorkPlatformIDFromURL(campaignURL)
            trackEvent(TRACKING_URL_ADD_TO_CAMPAIGN_CLICKED, {
                url_name: campaignURL,
                platform_name: WORKPLATFORM_ID_MAPPING[platformID].platformName
            });
        };

        const handleCloseModal = () => {
            setIsModalOpen(false);
        };

        function addUrlToCampaigns(campaignURL, selectedCampaigns) {
            Promise.all(
                selectedCampaigns.map((selectedCampaign) => {
                    return addContentToCampaign({
                        warehouse,
                        content_urls: [campaignURL],
                        campaignId: selectedCampaign,
                    });
                })
            ).catch((e) => {
                console.error(`Could not add URL ${campaignURL} to one of the campaigns ${selectedCampaigns}`);
                console.error(e);
            });
        }


        function handleSaveChanges(selectedCampaignIds, selectedCampaignNames) {
            addUrlToCampaigns(getFormatedCampaignURL(campaignURL), selectedCampaignIds);
            trackEvent(TRACKING_URL_SAVED_TO_CAMPAIGNS_CLICKED, {
                url_name: campaignURL,
                name_of_campaigns: selectedCampaignNames
            });
        }

        function AddToCampaignDialogButton() {
            if (isTrialMode) {
                if (isEmpty(campaignsCreated) || campaignsCreated === 0) {
                    return null;
                }
            }
            return (
                <PrimaryButton
                    label={
                        <span className={"body-m"} style={{ color: Colors.mainPrimary }}>
                            {ADD_TO_CAMPAIGN}
                        </span>
                    }
                    onClick={handleOpenModal}
                    className={"secondary-button add-to-campaign-button"}
                    style={{ height: 56 }}
                />
            );
        }

        function onExportRoiPdfClick() {
            if (isTrialCampaign) {
                setModalDetails({
                    isModalVisible: true,
                    modalInfo: unlockAccessPopupDetails.exportPost,
                });
            } else {
                setExportModalState(exportPdfState);
                setExportLoadingModalVisible(true);
            }
        }

        return (
            <div className={"content-view-header"}>
                <div className="campaign-header">
                    <Avatar alt={WORKPLATFORM_ID_MAPPING[workPlatformId].platformName}
                            src={WORKPLATFORM_ID_MAPPING[workPlatformId].platformLogo}
                            className="platform-logo"/>
                    <div className="username-date-logo-container">
                        <div className="username-date-container">
                            <div className="username">
                                <div>{generateCampaignTitle(workPlatformId, type, platformUsername, collaborators)}</div>
                                {is_verified && (
                                    <Icons.verified_logo/>
                                )}
                            </div>
                            <div className="posted-date">
                                {published_at !== null ? (
                                    <div>
                                        {sponsors?.length > 0 && <span className="dollar-icon"> <i
                                            className="ri-money-dollar-circle-line"></i></span>}
                                        {sponsors?.length > 0 ? (PaidPartnershipWith + sponsors.map((sponsor) => sponsor.platform_username) + dotSymbol) : null}
                                        {posted} {convertTimeToLocale(published_at)}
                                    </div>
                                ) : (
                                    "-"
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {isFromCampaignView() ? (
                    <div className={'body-m export-csv-container'}>
                        <div style={{ cursor: "pointer" }} onClick={onExportRoiPdfClick}>
                            <i className="ri-share-forward-line header-icon"></i> {exportPDFReport}
                        </div>
                        <ExportLoadingModal
                            open={isExportLoadingModalVisible}
                            title={exportModalState.value}
                            onClose={() => setExportLoadingModalVisible(false)}/>
                        <UnlockFullAccessPopUp
                            open={modalDetails.isModalVisible}
                            onClose={() => {
                                setModalDetails({
                                    ...modalDetails,
                                    isModalVisible: false,
                                })
                            }}
                            header={modalDetails.modalInfo.header}
                            description={modalDetails.modalInfo.description}
                            actionButtonLabel="Contact us"
                            height="264px"
                            padding="52px 52px 48px 48px"
                            isModal
                        />
                    </div>
                ) : (
                    <Box>
                        <AddToCampaignDialogButton/>
                        <AddToCampaignDialog open={isModalOpen} handleClose={handleCloseModal}
                                             handleSave={handleSaveChanges}/>
                        <UnlockFullAccessPopUp
                            open={isModalVisible}
                            onClose={() => {
                                setIsModalVisible(false);
                                trackEvent(TRACKING_UPGRADE_POPUP_CLOSED, { trigger_event: unlockAccessPopupDetails.addPost.header })
                            }}
                            header={unlockAccessPopupDetails.addPost.header}
                            description={unlockAccessPopupDetails.addPost.description}
                            actionButtonLabel="Contact us"
                            height="264px"
                            padding="52px 52px 48px 48px"
                            isModal
                        />
                    </Box>
                )}
            </div>
        );
    }

    function ContentViewEngagementMetrics({
                                              comments,
                                              likes,
                                              shares,
                                              type,
                                              views,
                                              workPlatformId,
                                          }) {
        const engagementData = {
            like_count: likes,
            comment_count: comments,
            view_count: views,
            share_count: shares,
        };

        function getImage() {
            const { thumbnail_url, persistent_thumbnail_url, work_platform } = campaignContentData || {};
            let { work_platform_id } = campaignContentData;
            if (isEmpty(work_platform_id)) {
                work_platform_id = work_platform?.id;
            }
            if (work_platform_id === WORKPLATFORM_IDS.YouTubePlatformId) {
                return thumbnail_url;
            }
            return isEmpty(persistent_thumbnail_url) ? getNewImageUrl(thumbnail_url) :
                getNewImageUrl(persistent_thumbnail_url);
        }


        return (
            <a onClick={(event) => {
                if (type !== story) {
                    trackEvent(TRACKING_CONTENT_VIEW_POST_CLICKED, {
                        url_name: campaignContentData.url,
                        platform_name: WORKPLATFORM_ID_MAPPING[campaignContentData.work_platform_id]?.platformName
                    });
                }
            }}
               className="link-tag-container"
               href={type !== story ? campaignContentData?.url : null}
               target="_blank">
                <div className={`content-details ${type !== story && 'content-details-hover'}`}>
                    <div className="content-img">
                        <img
                            alt=""
                            src={getImage()}
                        />
                    </div>
                    {type === story ?
                        <div className=" story-performance-metrics">
                            <div className="metrics-container">

                                {storyPerformanceMetrics?.map((metric, index) => {
                                    const { name, icon, variant, path, tooltip, isPercentage } = metric;
                                    const value = campaignContentData[path] ? isPercentage ? percentFormatter.format(campaignContentData[path]) : campaignContentData[path] : "-"
                                    return (<PerformanceMetric
                                        key={index}
                                        variant={variant}
                                        content={value}
                                        metricName={name}
                                        metricIcon={icon}
                                        tooltip={tooltip}
                                    />)
                                })}
                            </div>
                        </div>

                        :
                        <>
                            <div className="content-description">
                                <div>
                                    {workPlatformId === getWorkPlatformIdByPlatformName("Youtube") &&
                                        <h3 className="section-heading">{campaignContentData?.title}</h3>}
                                    <p
                                        style={
                                            workPlatformId === getWorkPlatformIdByPlatformName("Youtube") ? { WebkitLineClamp: campaignContentData?.title?.length > 53 ? 2 : 3 } : {}
                                        }
                                    >
                                        {parse(formatSentence(campaignContentData?.description))}
                                    </p>
                                </div>
                                {campaignContentData && (
                                    <div className="metrics-container">
                                        {engagementMetrics?.map((metric, index) => (
                                            <span key={index}>
                                                {workPlatformId === getWorkPlatformIdByPlatformName("TikTok") ? (
                                                    <PerformanceMetric
                                                        variant={metric.variant}
                                                        content={formatNumber(engagementData[metric.path])}
                                                        metricName={metric.name}
                                                        metricIcon={metric.icon}
                                                    />
                                                ) : metric.name !== "shares" ? (
                                                    <PerformanceMetric
                                                        variant={metric.variant}
                                                        content={formatNumber(engagementData[metric.path])}
                                                        metricName={metric.name}
                                                        metricIcon={metric.icon}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="hover-arrow-icon">
                                <p className="body-m">{view_post}</p> <i className="ri-arrow-right-up-line"></i>{" "}
                            </div>
                        </>
                    }
                </div>
            </a>
        );
    }

    function renderPerformanceMetrics() {
        // removed performance metrics if type is story
        if (campaignContentData?.type === story) return;

        function getContent(metric) {
            // WORKPLATFORM_ID_MAPPING[campaignContentData?.work_platform_id]?.platformName?.toLowerCase() !== "instagram"
            if (metric.path === 'estimated_reach' &&
                String(campaignContentData['type']).toLowerCase() === 'video') {
                return null;
            }
            return metric.path === 'engagement_rate' && campaignContentData[metric.path] !== null
                ? `${campaignContentData[metric.path]}%`
                : `${formatNumber(campaignContentData[metric.path])}`;
        }

        function getEngagementRateForSingleContent() {
            let denominator = profileFollowerCount;

            if (denominator === 0) {
                return '-';
            }

            if (isEmpty(denominator)) {
                denominator = campaignContentData?.view_count;
            }

            if (isEmpty(denominator) || denominator === 0) {
                return '-';
            }

            return percentFormatter.format(calcTotalEngagement() / denominator);
        }

        return isFromCampaignView() ? (
            <div className="performance-metrics-container">
                <div className="section-heading">{performance}</div>
                <div className="metrics-container">
                    {performanceMetrics?.map((metric, index) => (
                        <PerformanceMetric
                            key={index}
                            variant={metric.variant}
                            content={getContent(metric)}
                            metricName={metric.name}
                            metricIcon={metric.icon}
                            tooltip={metric.tooltip}
                        />
                    ))}
                </div>
            </div>
        ) : (
            <div className="performance-metrics-container">
                <div className="section-heading">{performance}</div>
                <div className="metrics-container">
                    {/*Engagement rate*/}
                    <PerformanceMetric
                        variant={performanceMetrics[0].variant}
                        content={getEngagementRateForSingleContent()}
                        metricName={performanceMetrics[0].name}
                        metricIcon={performanceMetrics[0].icon}
                        tooltip={performanceMetrics[0].tooltip}
                    />
                    {/*Total engagement*/}
                    <PerformanceMetric
                        variant={performanceMetrics[1].variant}
                        content={formatNumber(calcTotalEngagement())}
                        metricName={performanceMetrics[1].name}
                        metricIcon={performanceMetrics[1].icon}
                        tooltip={performanceMetrics[1].tooltip}
                    />
                    {/*Reach*/}
                    <PerformanceMetric
                        variant={performanceMetrics[2].variant}
                        content={
                            String(campaignContentData['type']).toLowerCase() === 'video' ?
                                null : formatNumber(campaignContentData?.engagement?.view_count)}
                        metricName={performanceMetrics[2].name}
                        metricIcon={performanceMetrics[2].icon}
                        tooltip={performanceMetrics[2].tooltip}
                    />
                    {/*Impressions*/}
                    <PerformanceMetric
                        variant={performanceMetrics[3].variant}
                        content={formatNumber(campaignContentData?.engagement?.view_count)}
                        metricName={performanceMetrics[3].name}
                        metricIcon={performanceMetrics[3].icon}
                        tooltip={performanceMetrics[3].tooltip}
                    />
                </div>
            </div>
        );
    }

    const shouldHide = (index) => {
        var element = document?.getElementsByClassName(`comment-${index}`)[0];
        if (element?.offsetHeight < element?.scrollHeight || element?.offsetHeight > 48) {
            return true
        }
        return false
    }

    function renderTopComments() {
        if (campaignContentData?.top_positive_comments?.length === 0 && campaignContentData?.top_critical_comments?.length === 0) return;
        return (
            <div className="positive-negative-comments">
                <div className="section-heading">{topRelevantComments}</div>
                <div>
                    <div>
                        <div className="comments-title sub-section-heading">
                            <i className="ri-heart-3-line"></i> Positive comments
                        </div>
                        <div className="top-comments-container">
                            {campaignContentData?.top_positive_comments?.map((comment, index) => (
                                <>
                                    <div>
                                        <div key={index}
                                             className={`comment comment-${index} ${expandedComments.includes(index) ? "expanded" : ""}`}>
                                            {comment}
                                        </div>
                                        {shouldHide(index) && <button
                                            onClick={() => toggleCommentExpansion(index)}>{expandedComments.includes(index) ? "Show less" : "Read more"}</button>}
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className="negative-comment comments-title sub-section-heading">
                            <i className="ri-alert-line"></i> Negative comments
                        </div>
                        <div className="top-comments-container">
                            {campaignContentData?.top_critical_comments?.map((comment, index) => (
                                <>
                                    <div className="negative-comments-box">
                                        <div key={index + 3}
                                             className={`comment comment-${index + 3} ${expandedComments.includes(index + 3) ? "expanded" : ""}`}>
                                            {comment}
                                        </div>
                                        {shouldHide(index + 3) && <button
                                            onClick={() => toggleCommentExpansion(index + 3)}>{expandedComments.includes(index + 3) ? "Show less" : "Read more"}</button>}
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const handleGenerateReport = async () => {
        if (reportStatus === NOT_GENERATED) {
            setReportStatus(IN_PROGRESS);
            setReportStatusButtonLoading(true);
            trackEvent(TRACKING_CAMPAIGN_AUDIENCE_REPORT_CLICKED, {
                platform_name: WORKPLATFORM_ID_MAPPING[campaignContentData.work_platform_id].platformName,
                content_url: campaignContentData.url,
                last_updated_at: convertTimeToLocale(campaignContentData?.synced_at, DATE_TIME_FORMAT_STATUS)
            });
            await createAudienceReport({ warehouse, campaignId, contentId });
            setReportStatusButtonLoading(false);
        } else if (reportStatus === GENERATED) {
            const {
                audience_report: { report_url },
            } = campaignContentData;
            if (report_url) {
                window.open(report_url, "_blank");
            }
        } else if (reportStatus === TRIAL_MODE) {
            setIsTrialGenerateReportModalOpen(true)
        } else if (reportStatus === NOT_SUPPORTED) {
            window.open(MEETING_SCHEDULE_LINK, "_blank");
        }
    };

    function renderAudienceReport() {

        if (WORKPLATFORM_ID_MAPPING[campaignContentData?.work_platform_id]?.platformName !== "Instagram" || campaignContentData?.type === story) return;
        return (
            <Box className="audience-report-container">
                <Box>
                    {" "}
                    <Icons.audience_white_icon/>{" "}
                </Box>
                <Box>
                    <Box className="title">{audienceReportStates[reportStatus].title}</Box>
                    <Box lineHeight={"150%"} className="body-r">{audienceReportStates[reportStatus].desc}</Box>
                </Box>
                <Box>
                    <PrimaryButton
                        loading={reportStatusButtonLoading}
                        onClick={handleGenerateReport}
                        label={parse(audienceReportStates[reportStatus].buttonCTA)}
                        className="button secondary-button body-m"
                        disabled={reportStatus === IN_PROGRESS}
                    />
                </Box>

            </Box>
        );
    }

    function renderContentViewHeader() {
        const {
            collaborators,
            profile,
            profile_is_verified,
            profile_username,
            published_at,
            sponsors,
            type,
            work_platform: { id: workPlatformId } = {},
            work_platform_id,
        } = campaignContentData || {};

        const { platform_username, is_verified } = profile || {};

        return isFromCampaignView() ? (
            <ContentViewHeader
                workPlatformId={work_platform_id}
                type={type}
                platformUsername={profile_username}
                is_verified={profile_is_verified}
                published_at={published_at}
                collaborators={collaborators}
                sponsors={sponsors}
            />
        ) : (
            <ContentViewHeader
                workPlatformId={workPlatformId}
                type={type}
                platformUsername={platform_username}
                is_verified={is_verified}
                published_at={published_at}
                collaborators={collaborators}
                sponsors={sponsors}
            />
        );
    }

    function renderContentViewMetrics() {
        const {
            work_platform: { id: workPlatformId } = {},
            engagement,
            type
        } = campaignContentData || {};

        const like_count = engagement?.like_count;
        const comment_count = engagement?.comment_count;
        const view_count = engagement?.view_count;
        const share_count = engagement?.share_count;

        const {
            like_count: engagementLikes,
            comment_count: engagementComments,
            view_count: engagementViews,
            share_count: engagementShares,
            work_platform_id,
        } = campaignContentData || {};

        return isFromCampaignView() ? (
            <ContentViewEngagementMetrics
                comments={engagementComments}
                likes={engagementLikes}
                shares={engagementShares}
                type={type}
                views={engagementViews}
                workPlatformId={work_platform_id}
            />
        ) : (
            <ContentViewEngagementMetrics
                comments={comment_count}
                likes={like_count}
                shares={share_count}
                type={type}
                views={view_count}
                workPlatformId={workPlatformId}
            />
        );
    }

    function RenderCommentAnalysis() {
        if (campaignContentData?.type === story) return

        function onAddBrandSocialsClick() {
            // getCampaignById({ warehouse, campaignId }).then((res) => {
            //     const {is_trial} = res;
            // });
            setShowAddBrandProfilesPopup(true);
        }

        if (!isTrialCampaign) {
            if (socialsJustAdded) {
                return (
                    <div style={{ marginBottom: 32 }}>
                        <Outline
                            title={<div className={'section-heading'}>{COMMENTS_ANALYSIS_TITLE_SUCCESS}</div>}
                            showTooltip={true}
                        >
                            <InfoActionBanner
                                title={<span className={'body-m'}
                                             style={{ width: 580 }}>{CAMPAIGN_BRAND_PROFILES_DONE_TITLE}</span>}
                                variant={'brand-profiles-grey'}
                                icon={<Icons.clock_icon/>}
                                actionText={EDIT_BRAND_ACCOUNTS}
                                actionIcon={<i className="ri-edit-line"></i>}
                                actionClick={() => setShowAddBrandProfilesPopup(true)}
                            />
                        </Outline>
                    </div>
                );
            }
            if (brandSocialsCount === 0) {
                return (
                    <div style={{ marginBottom: 32 }}>
                        <Outline
                            title={<div className={'section-heading'}>{COMMENTS_ANALYSIS_TITLE_SUCCESS}</div>}
                            showTooltip={true}
                        >
                            <InfoActionBanner
                                title={<span className={'body-m'}
                                             style={{ width: 580 }}>{CAMPAIGN_BRAND_PROFILES_TITLE}</span>}
                                variant={'brand-profiles'}
                                icon={<Icons.comment_icon/>}
                                actionText={ADD_BRAND_ACCOUNTS}
                                actionIcon={<i className="ri-add-line"></i>}
                                actionClick={onAddBrandSocialsClick}
                            />
                        </Outline>
                    </div>
                );
            }
        }

        return <CommentsAnalysis campaignContentData={campaignContentData}/>;
    }

    const ContentLoading = () => {
        return (
            <>
                <Box className="content-loading">
                    <Box className="section-heading">
                        <Loader className={"campaign-loader-container"}/>
                        {analysis_loading_msg.title} <TextSwitchingLoader texts={campaignLoaderTexts}
                                                                          interval={campaignLoaderInterval}/>
                    </Box>
                    <Box className="body-m">{analysis_loading_msg.desc}</Box>
                </Box>
                <Box className="skeleton-loading">
                    <Skeleton variant="text" height={"60px"} width={"100%"} className={"loading-skeleton"}
                              animation={"pulse"}/>
                    <Skeleton variant="text" height={"300px"} width={"100%"} className={"loading-skeleton"}
                              animation={"pulse"}/>
                    <Skeleton variant="text" height={"118px"} width={"100%"} className={"loading-skeleton"}
                              animation={"pulse"}/>
                </Box>
            </>
        );
    };

    const [expandedComments, setExpandedComments] = useState([]);

    const toggleCommentExpansion = (index) => {
        if (expandedComments.includes(index)) {
            setExpandedComments(expandedComments.filter((i) => i !== index));
        } else {
            setExpandedComments([...expandedComments, index]);
        }
    };

    function onSocialsDone() {
        setSocialsJustAdded(true);
    }

    return (
        <Box>
            {isFromCampaignView() ? (
                <CampaignContentViewTopBar/>
            ) : (
                <Box className="input-back-button-container">
                    <i
                        className="ri-arrow-left-line back-icon"
                        onClick={() => {
                            setCampaignURL("");
                            navigate("/creators/campaigns");
                        }}
                    ></i>
                    <InputField
                        placeholder={trackingPage.inputPlaceholder}
                        // maxLength={200}
                        value={campaignURL}
                        onChange={(e) => {
                            setCampaignURL(e.target.value.trim());
                            setInputErrorState({ hasError: false, errorMessage: "" });
                        }}
                        onKeyPress={handleEnterKeyPress}
                        ref={inputRef}
                        hasError={inputErrorState.hasError}
                        errorMessage={
                            <>
                                {inputErrorState.hasError ? <Icons.caution_red/> : null} {inputErrorState.errorMessage}
                            </>
                        }
                    />
                    <PrimaryButton
                        disabled={contentLoading || campaignURL === campaignContentData?.url}
                        onClick={handleAnalyze}
                        label={trackingPage.buttonCTA}
                        className="analyse-button body-m"
                    />
                </Box>
            )}
            {contentLoading ? (
                <ContentLoading/>
            ) : (
                !showCampaignError.hasError && (
                    <Box>
                        {renderContentViewHeader()}
                        {renderContentViewMetrics()}
                        {renderPerformanceMetrics()}
                    </Box>
                )
            )}

            {showCampaignError.hasError && (
                <Box className="campaign-error-content">
                    <EmptyTableScreen
                        icon={showCampaignError.errorMessage.icon}
                        header={showCampaignError.errorMessage.title}
                        content={showCampaignError.errorMessage.desc}
                        isActionButton={false}
                    />
                </Box>
            )}
            {contentLoading || showCampaignError?.hasError ? <div style={{ marginBottom: 32 }}></div> : <Separator/>}
            {contentLoading || showCampaignError?.hasError || campaignContentData?.type === story ? null : (isFromCampaignView() ?
                <RenderCommentAnalysis/> :
                <CommentAnalysisNotSupported/>)}
            {contentLoading || showCampaignError?.hasError || campaignContentData?.type === story ? null : (isFromCampaignView() ?
                <Separator/> :
                <div style={{ marginBottom: 32 }}></div>)}
            {contentLoading || showCampaignError?.hasError ? null : isFromCampaignView() ? (
                <>
                    <Box> {renderTopComments()}</Box>
                    <Box sx={{ paddingBottom: "48px" }}> {renderAudienceReport()} </Box>
                </>
            ) : null}
            {showAddBrandProfilesPopup && !isEmpty(campaignBrand) && <AddBrandProfilesPopup
                brand={campaignBrand}
                onClose={() => {
                    setShowAddBrandProfilesPopup(false);
                }}
                onDone={onSocialsDone}
            />}
            <GenericPopup
                closeOnOutsideClick={false}
                open={isTrialGenerateReportModalOpen}
                description={sampleReportModalDetails.desc}
                header={sampleReportModalDetails.header}
                onClose={() => setIsTrialGenerateReportModalOpen(false)}
                actionButtonLabel={<> <Icons.download_icon/> {sampleReportModalDetails.buttonCTA}</>}
                icon={<Icons.info_icon_purple_bg/>}
                height="340px"
                padding={"48px"}
                handleButtonClick={() => {
                    const {
                        audience_report: { report_url },
                    } = campaignContentData;
                    if (report_url) {
                        window.open(report_url, "_blank");
                    }
                    setIsTrialGenerateReportModalOpen(false)
                }}
            />
        </Box>
    );
}

export default AnalysisPage;
