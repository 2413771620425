import React, { useState } from "react";
import "./CustomDatePicker.scss";
import Icons from "../Icons/Icons";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    calendarTabSwitchLabels,
    CUSTOM_DATE_LABEL,
    DATE_PICKER_LABELS,
    DATE_PICKER_POP_UP_HEADER,
    MEASUREMENT_DATE_FORMAT, MEASUREMENT_PRODUCTS_API_DATE_FORMAT,
    ONLY_END_DATE_MESSAGE,
    TODAY_DATE_FORMAT
} from "../../constants/constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { isEmpty } from "../../utils/util";
import { format, subDays, subMonths, parse, isValid } from 'date-fns';

export default function CustomDatePicker({ open, onClose, onValueChanged }) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDatePopUp, setStartDatePopUp] = useState(true);
    const [endDatePopUp, setEndDatePopUp] = useState(false);
    const {
        START_DATE,
        END_DATE,
        DATE_PLACEHOLDER,
        ACTION_LABELS,
        START_DATE_TYPE,
        END_DATE_TYPE
    } = DATE_PICKER_LABELS;

    const actionBarProps = {
        actions: ACTION_LABELS,
        classes: {
            root: "custom-action-bar",
        }
    }

    function getDateLabel([startDate, endDate]) {
        if (isEmpty(startDate) && isEmpty(endDate)) {
            return CUSTOM_DATE_LABEL;
        } else if (!isEmpty(startDate) && isEmpty(endDate)) {
            return `${startDate?.format(MEASUREMENT_DATE_FORMAT)} - ${format(new Date(), TODAY_DATE_FORMAT)}`;
        } else if (isEmpty(startDate) && !isEmpty(endDate)) {
            return `${ONLY_END_DATE_MESSAGE} ${endDate?.format(MEASUREMENT_DATE_FORMAT)}`;
        } else {
            return `${startDate?.format(MEASUREMENT_DATE_FORMAT)} - ${endDate?.format(MEASUREMENT_DATE_FORMAT)}`;
        }
    }

    function manualTriggerDateSelection(type) {
        if (type === START_DATE_TYPE) {
            if (endDatePopUp) {
                setEndDatePopUp(false);
                setStartDatePopUp(true);
            }
        } else if (type === END_DATE_TYPE) {
            if (startDatePopUp) {
                setStartDatePopUp(false);
                setEndDatePopUp(true);
            }
        }
    }

    function handleDateRangeChange(newValue) {
        onValueChanged(getDateLabel([startDate, newValue]));
        onClose();
        setStartDatePopUp(true);
        setEndDatePopUp(false);
    }

    function CustomCalendarIcon(props) {
        return (
            <div onClick={() => manualTriggerDateSelection(props.type)}>
                <i className="ri-calendar-line custom-date-icon"/>
            </div>
        )
    }

    function handleOnClosePopUp() {
        onValueChanged(getDateLabel([startDate, endDate]));
        onClose();
    }

    if (!open) {
        return null;
    }

    return (
        <div className="modal" onClick={handleOnClosePopUp}>
            <div className="custom-date-picker-container" onClick={(e) => e.stopPropagation()}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="section-heading">
                        <div className="export-pop-up-info-header">{DATE_PICKER_POP_UP_HEADER}</div>
                    </div>
                    <div className="custom-date-fields">
                        <DatePicker
                            value={startDate}
                            format={MEASUREMENT_DATE_FORMAT}
                            open={startDatePopUp}
                            label={START_DATE}
                            disableFuture
                            maxDate={endDate}
                            slotProps={{
                                actionBar: actionBarProps,
                                popper: {
                                    className: "custom-start-date-popper",
                                    disablePortal: true,
                                    anchorEl: document.querySelector(".custom-date-picker-container"),
                                },
                                textField: {
                                    placeholder: DATE_PLACEHOLDER,
                                    onClick: () => manualTriggerDateSelection(START_DATE_TYPE)
                                }
                            }}
                            slots={{
                                inputAdornment: (props) => <CustomCalendarIcon {...props} type={START_DATE_TYPE}/>,
                            }}
                            onChange={setStartDate}
                            onAccept={() => {
                                setStartDatePopUp(false);
                                setEndDatePopUp(true);
                            }}
                        />
                        <DatePicker
                            value={endDate}
                            format={MEASUREMENT_DATE_FORMAT}
                            open={endDatePopUp}
                            label={END_DATE}
                            minDate={startDate}
                            disableFuture
                            slotProps={{
                                actionBar: actionBarProps,
                                popper: {
                                    className: "custom-end-date-popper",
                                    disablePortal: true,
                                    anchorEl: document.querySelector(".custom-date-picker-container"),
                                },
                                textField: {
                                    placeholder: DATE_PLACEHOLDER,
                                    onClick: () => manualTriggerDateSelection(END_DATE_TYPE)
                                }
                            }}
                            slots={{
                                inputAdornment: (props) => <CustomCalendarIcon {...props} type={END_DATE_TYPE}/>,
                            }}
                            onChange={setEndDate}
                            onAccept={handleDateRangeChange}
                        />
                    </div>
                </LocalizationProvider>
                <div className="close-icon-container">
                    <Icons.close_icon onClick={handleOnClosePopUp} cursor="pointer"/>
                </div>
            </div>
        </div>
    );
}

export function getDateRange(selectedDayTab) {

    function check(str, labels) {
        for (let i = 0; i < labels.length; i++) {
            if (str.startsWith(labels[i])) {
                return true;
            }
        }
        return false;
    }

    function isDateStringValid(dateString) {
        // Parse the date string
        const parsedDate = parse(dateString, 'dd MMM, yyyy', new Date());

        // Check if the parsed date is valid and if the formatted date matches the original input
        return isValid(parsedDate) && format(parsedDate, 'dd MMM, yyyy') === dateString;
    }

    const labelsExceptLast = calendarTabSwitchLabels(null).slice(0, -1).map(item => item.label);
    if (check(selectedDayTab, labelsExceptLast)) {
        // Initialize toDate as today's date
        const toDate = new Date();

        // Initialize fromDate as today's date (it will be adjusted based on the label)
        let fromDate;

        // Map labels to specific time period adjustments
        switch (selectedDayTab.split(' - ')[0]) {
            case "7D":
                fromDate = subDays(toDate, 7);
                break;
            case "30D":
                fromDate = subDays(toDate, 30);
                break;
            case "3M":
            default:
                fromDate = subMonths(toDate, 3);
                break;
            case "6M":
                fromDate = subMonths(toDate, 6);
                break;
            case "12M":
                fromDate = subMonths(toDate, 12);
                break;
        }

        // Format the dates in 'YYYY-MM-DD' format
        return [format(fromDate, MEASUREMENT_PRODUCTS_API_DATE_FORMAT), format(toDate, MEASUREMENT_PRODUCTS_API_DATE_FORMAT)];
    } else {
        let fromDateStr = selectedDayTab.slice(0, 12);
        const toDateStr = selectedDayTab.slice(-12);

        let fromDate, toDate;
        if (isDateStringValid(fromDateStr)) {
            fromDate = format(parse(fromDateStr, "dd MMM, yyyy", new Date()), MEASUREMENT_PRODUCTS_API_DATE_FORMAT);
        } else {
            fromDate = null;
        }
        if (isDateStringValid(toDateStr)) {
            toDate = format(parse(toDateStr, "dd MMM, yyyy", new Date()), MEASUREMENT_PRODUCTS_API_DATE_FORMAT);
        } else {
            toDate = null;
        }
        return [
            fromDate,
            toDate
        ];
    }
}
