import React, { useState } from 'react';
import { DROP_US_AN_EMAIL, EMAIL_COPIED, IF_QUESTIONS_DROP_US_AN_EMAIL, SUPPORT_EMAIL_ID } from "../../constants/constants";
import { Icons, ToastMessage } from "../index";
import './SupportEmail.scss';
import { EMAIL_SUPPORT_LINK_CLICKED, trackEvent } from "../../analytics";
import { ADD_LIST_TOAST_MESSAGE_DURATION } from "../../constants/creatorDiscovery";

export default function SupportEmail({ variant }) {
    const [isToastOpen, setToastOpen] = useState(false);
    function handleCopyAction(copyText) {
        navigator.clipboard.writeText(copyText);
        setToastOpen(true);
        trackEvent(EMAIL_SUPPORT_LINK_CLICKED, { source: "SIDEBAR" });
    }
    let drop_email_text;
    switch (variant) {
        case "IF_QUESTIONS_DROP_US_EMAIL_TEXT":
            drop_email_text = IF_QUESTIONS_DROP_US_AN_EMAIL;
            break;
        default:
            drop_email_text = DROP_US_AN_EMAIL;
            break;
    }

    return (
        <>
            <span className={'span-email-us body-m color-neutrals-secondary-grey'}>
                <span>
                    <span className={'span-drop-email-text'}>{drop_email_text}</span>
                    <a className={'body-m'} href={`mailto:${SUPPORT_EMAIL_ID}`}>{SUPPORT_EMAIL_ID}</a>
                </span>
                <Icons.copy_icon_transparent
                    className={'copy-icon'}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCopyAction(SUPPORT_EMAIL_ID);
                    }}
                    cursor='pointer'
                />
            </span>
            <ToastMessage
                openToast={isToastOpen}
                message={EMAIL_COPIED}
                duration={ADD_LIST_TOAST_MESSAGE_DURATION}
                onCloseToast={() => {
                    setToastOpen(false);
                }}
                type={'success'}
            />
        </>
    );
}
