import { Avatar, Box, fabClasses, Skeleton, TextField } from "@mui/material";
import { Checkbox, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { SEARCH_PLATFORM_PROFILE_VIEWED, SEARCH_PROFILE_SEARCHED, SEARCH_PROFILE_VIEWED, trackEvent } from "../../analytics";
import { getProfileAnalytics, getRecentSearches, getUsersHandles, removeRecentSearchCard } from "../../api/creatorDiscoveryAPI/creatorDiscoveryAPI";
import {
  EmptyTableScreen,
  ErrorScreen,
  ExportResultOverlay,
  FeaturedSearchCard,
  Icons,
  InputField,
  IntermediateLoader,
  PlatformSelectionDropdown,
  PrimaryButton,
  RecentSearchCard,
  SearchCardSkeleton,
  TableListingRecords,
  TableSearchSkeleton,
  UnlockFullAccessPopUp,
} from "../../components";
import { CREATOR_PROFILE_LINK, platformHandleRegex, URL_REGEX, WORKPLATFORM_ID_MAPPING, WORKPLATFORM_USER_HANDLE_MAPPING } from "../../constants/constants";
import "./ProfileSearchPage.scss";
import { useNavigate } from "react-router-dom";
import { convertToShortNumber, workPlatformsDropdownOptions } from "../../utils/util";
import { tableErrorMessages } from "../../constants/errorMessages";
import { convertDate } from "../../utils/profileHelpers";
import { upsertPlatformToQueryParams } from "../CreatorDiscoveryPage/CreatorDiscoveryPageUtils";
import { CreatorDiscoveryWarehouseContext, WarehouseContext } from "../../storage/context";
import { BASIC_DETAILS_OF_SELECTED_PROFILE } from "../../storage/reducers/types";
const ProfileSearchPage = ({
  options,
  currentTab,
  selectedPlatform,
  setSelectedPlatform,
  selectedPlatformID,
  setSelectedPlatformID,
  isTrialMode,
}) => {
  const [showTableErrorScreen, setShowTableErrorScreen] = useState({ flag: false, status: 200 });
  const [searchedProfileResponse, setSearchedProfileResponse] = useState([]);
  const { creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse } = useContext(CreatorDiscoveryWarehouseContext);
  const columns = [
    {
      dataIndex: "key",
      key: "key",
      title: (record) => (
        <Checkbox
          checked={titleCheckboxChecked}
          onClick={(e) => {
            e.stopPropagation();
            const trElements = document.querySelectorAll(".ant-table-row");
            trElements.forEach((trElement) => {
              if (titleCheckboxChecked) {
                if (trElement.classList.contains("active-row")) {
                  trElement.className = trElement.className.replace(" active-row", "");
                } else {
                  trElement.className += " active-row";
                }
                trElement.className = trElement.className.replace(" active-row", "");
              } else {
                trElement.className += " active-row";
              }
            });
            const newSelectedRowKeys = titleCheckboxChecked ? [] : searchedProfileData.slice(0, tableOffset).map((record) => record.key);
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(newSelectedRowKeys.map((key) => searchedProfileData.slice(0, tableOffset).find((record) => record.key === key)));
            setTitleCheckboxChecked(!titleCheckboxChecked);
          }}
        />
      ),
      width: 20,
      render: (text, record) => {
        let isCheckboxEnabledForRow = selectedRowKeys.findIndex((key) => key === record.key) !== -1;
        return (
          <Checkbox
            checked={isCheckboxEnabledForRow}
            onClick={(e) => {
              e.stopPropagation();
              const trElement = document.querySelector(`tr[data-row-key="${record.key.toString()}"]`);
              if (isCheckboxEnabledForRow) {
                trElement.className = trElement.className.replace(" active-row", "");
              } else {
                trElement.className += " active-row";
              }
              const selectedRowKeyIndex = selectedRowKeys.indexOf(record.key);
              let newSelectedRowKeys = [];

              if (selectedRowKeyIndex === -1) {
                newSelectedRowKeys = [...selectedRowKeys, record.key];
              } else {
                newSelectedRowKeys = selectedRowKeys.filter((key) => key !== record.key);
              }

              setSelectedRowKeys(newSelectedRowKeys);
              setSelectedRows(newSelectedRowKeys.map((key) => searchedProfileData.slice(0, tableOffset).find((record) => record.key === key)));
            }}
          />
        );
      },
    },
    {
      dataIndex: "account",
      key: "account",
      title: "Account",
      width: 150,
    },
    {
      dataIndex: "followers",
      key: "followers",
      title: `${options.filter((option) => option.value === selectedPlatform)[0].columnName}`,
      width: 300,
    },
  ];
  let [searchInput, setSearchInput] = useState("");
  const [cardsLoading, setCardsLoading] = useState(false);
  const [recentSearchProfileResponse, setRecentSearchProfileResponse] = useState([]);
  const [titleCheckboxChecked, setTitleCheckboxChecked] = useState(false);
  const [searchedProfileData, setSearchedProfileData] = useState([]);
  const [tableOffset, setTableOffset] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [hoveredRowKey, setHoveredRowKey] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { warehouse } = useContext(WarehouseContext);
  const navigate = useNavigate();

  const CREATOR_SEARCH_TABLE_ICONS = {
    Instagram: {
      icon: <Icons.insta_table_icon />,
    },
    YouTube: {
      icon: <Icons.youtube_table_icon />,
    },
    TikTok: {
      icon: <Icons.tiktok_table_icon />,
    },
  };

  const onMouseEnterRow = (record) => {
    setHoveredRowKey(record.key);
  };

  const onMouseLeaveRow = () => {
    setHoveredRowKey(null);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.clear();
    };
    let savedSearchedResponse = JSON.parse(sessionStorage.getItem("search-results"));
    if (savedSearchedResponse) {
      setSearchedProfileResponse(savedSearchedResponse?.searchedProfileResponse);
      const usersData = constructTableDataFromSearchedProfileResponse(savedSearchedResponse?.searchedProfileResponse);
      setSearchedProfileData(usersData);
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [searchedProfileData]);

  useEffect(() => {
    if (searchedProfileData.length > 0) {
      const scrollTarget = sessionStorage.getItem("scrollTarget");
      if (scrollTarget) {
        const targetElement = document.querySelector(`tr[data-row-key="${scrollTarget}"]`);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }
  }, [searchedProfileData]);

  useEffect(() => {
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    const queryParams = Object.fromEntries(searchParams);
    (async () => {
      if (currentTab !== "Search by profile") return;
      setTableLoading(false);
      setShowTable(false);
      setSearchInput("");
      const selectedWorkPlatformId = options.filter((option) => option.value === queryParams.platform)[0].id;
      setSelectedPlatformID(selectedWorkPlatformId);
      setCardsLoading(true);
      const recentSearchProfileResponse = await getRecentSearches({
        workplatformId: selectedWorkPlatformId,
        creatorSearchType: "PROFILE",
        warehouse,
      });
      setRecentSearchProfileResponse(recentSearchProfileResponse.data);
      setCardsLoading(false);
    })();
  }, [selectedPlatform]);

  useEffect(() => {
    if (searchInput.length === 0) {
      setShowTable(false);
    }
  }, [searchInput]);
  const handleUsersSearch = async (recentCardSearchInput = "") => {
    setShowTable(true);
    setSearchedProfileData([]);
    setShowTableErrorScreen({ flag: false, status: 200 });
    sessionStorage.removeItem("search-results");
    let url = "";
    let username = "";
    if (searchInput === "") {
      if (recentCardSearchInput !== "") searchInput = recentCardSearchInput;
      else {
        setShowTable(false);
        return;
      }
    }
    if (platformHandleRegex.test(searchInput)) {
      username = searchInput;
    } else if (URL_REGEX.test(searchInput)) {
      url = searchInput;
    }
    setShowTable(true);
    setTableLoading(true);
    trackEvent(SEARCH_PROFILE_SEARCHED, { url: url, platform: selectedPlatform, username: username });
    try {
      let response = await getUsersHandles({
        searchQuery: searchInput,
        workplatformId: selectedPlatformID,
        type: "search",
        is_search: true,
        warehouse,
      });
      // if response is null/undefined or length is 0 will show error screen
      if (response.data.length === 0) {
        const payload = {
          identifier: searchInput,
          work_platform_id: selectedPlatformID,
        };
    
        try {
          const analyticsResponse = await getProfileAnalytics({ warehouse, payload });
    
          const analyticsResponseData = {
            data: [
              {
                user_id: analyticsResponse.profile.external_id,
                username: analyticsResponse.profile.platform_username,
                fullname: analyticsResponse.profile.full_name,
                picture: analyticsResponse.profile.image_url,
                followers: analyticsResponse.profile.follower_count,
                is_verified: analyticsResponse.profile.is_verified,
              },
            ],
          };
    
          response = analyticsResponseData;
        } catch (analyticsError) {
          setShowTableErrorScreen({ flag: true, status: "no_data" });
          setTableLoading(false);
          return;
        }
      }
      setSearchedProfileResponse(response.data);
      let responseData = response.data.map((profileSearchedData) => {
        return {
          ...profileSearchedData,
          url: WORKPLATFORM_USER_HANDLE_MAPPING[selectedPlatform] + profileSearchedData.username,
        };
      });
      const usersData = constructTableDataFromSearchedProfileResponse(responseData);
      setSearchedProfileData(usersData);
      setTableLoading(false);
    } catch (error) {
      if (error.response.status) {
        setShowTableErrorScreen({ flag: true, status: error.response.status });
        setTableLoading(false);
      }
    }
  };

  const constructTableDataFromSearchedProfileResponse = (searchedProfileResponse, iterationOffset = 0) => {
    const tableData = searchedProfileResponse.map((invitation, idx) => {
      const rowData = {
        key: idx + iterationOffset + 1,
        account: (
          <Box className="table-profile-col">
            <Box>
              <Avatar alt="Profile Pic" src={invitation?.picture} className="table-profile-pic" sx={{ width: 72, height: 72 }} />
            </Box>
            <Box className="table-username-container">
              <Box
                onClick={(e) => {
                  trackEvent(SEARCH_PLATFORM_PROFILE_VIEWED, {
                    username: invitation?.platform_username,
                    col: invitation,
                    platform: invitation?.work_platform?.name,
                    source: "search_page",
                  });
                }}
                className="table-profile-full-name"
              >
                <a target="_self">{invitation?.fullname?.replace(/&#\d+;/g, "").replace(/:[^:\s]+:/g, "")}</a>
                {invitation?.is_verified && (
                  <div className="is-verified-logo-container">
                    <Icons.verified_logo className="is-verified-logo" />{" "}
                  </div>
                )}
              </Box>
              {invitation?.username && (
                <Box
                  className="table-profile-user-name"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <a href={invitation?.url} target="_blank">
                    <Box display={"flex"} gap={"4px"} alignItems={"center"} justifyContent={"center"}>
                      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"20px"} width={"20px"}>
                        {CREATOR_SEARCH_TABLE_ICONS[selectedPlatform].icon}
                      </Box>
                      <Box>@{invitation?.username}</Box>
                      <Box>
                        <i className="ri-arrow-right-up-line" style={{ fontSize: "16px", color: "var(--neutrals-secondary-grey)" }}></i>
                      </Box>
                    </Box>
                  </a>
                </Box>
              )}
            </Box>
          </Box>
        ),
        followers: convertToShortNumber(invitation?.followers.toString()),
        platformUsername: invitation?.username,
        full_name: invitation?.fullname,
        image_url: invitation?.picture,
        is_verified: invitation?.is_verified,
        profile_url: invitation?.url,
      };
      return rowData;
    });
    return tableData;
  };

  const onKeyDownHandler = (e) => {
    (async function () {
      if (e.key === "Enter") {
        setShowTable(true);
        await handleUsersSearch();
      }
    })().then(() => {});
  };

  const clearRecentSearchCardHandler = async (recentSearchCardId) => {
    setCardsLoading(true);
    const response = await removeRecentSearchCard({
      warehouse,
      id: recentSearchCardId,
    });
    if (response.status === "accepted") {
      const updatedRecentSearchCard = recentSearchProfileResponse.filter((card) => card.id !== recentSearchCardId);
      setRecentSearchProfileResponse(updatedRecentSearchCard);
      setCardsLoading(false);
    }
  };

  return (
    <div>
      <Box className="profile-search-content-container">
        <Box>
          <PlatformSelectionDropdown
            platformList={options}
            selectedPlatform={selectedPlatform}
            setSelectedPlatform={setSelectedPlatform}
            onChangeHandle={(platform) => {
              sessionStorage.removeItem("search-results");
              setShowTable(false);
              setSearchedProfileData([]);
              upsertPlatformToQueryParams(platform);
            }}
          />
          <Box sx={{ width: "100%", minHeight: "56px" }}>
            <TextField
              placeholder="Search using username, @handle, or profile URL"
              className="profile-search-input"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              onKeyDown={onKeyDownHandler}
            />
          </Box>
          <Box>
            <PrimaryButton
              style={{ height: "56px", width: "133px" }}
              label="Get results"
              disabled={searchInput.length === 0}
              onClick={async () => {
                await handleUsersSearch();
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box></Box>
      {/* {searchedProfileData && searchedProfileData.length > 0 && (
        <Box className="export-result-container">
          <Box>
            {" "}
            <p>
              {selectedRows.length} selected of{" "}
              {JSON.parse(sessionStorage.getItem("search-results"))
                ? JSON.parse(sessionStorage.getItem("search-results")).totalSearchedResults
                : searchedProfileData.length}{" "}
              results
            </p>
          </Box>
          {/* <ExportResultOverlay
            totalExportResults={selectedRows.length}
            totalCredits={10}
            selectedPlatform={{ id: selectedPlatformID, name: selectedPlatform }}
            source="filter_search"
            selectedRows={selectedRows}
          /> */}
      {/* </Box> */}
      {/* )} */}
      {(showTable || sessionStorage.getItem("search-results")) && (
        <>
          {tableLoading && searchedProfileData ? (
            <div className="table-skeleton-container">
              <Box className="table-row-skeleton">
                <Skeleton animation="wave" variant="rectangular" width={12} height={12} />
                <Skeleton animation="wave" variant="text" width={"100%"} height={40} />
              </Box>
              <TableSearchSkeleton />
            </div>
          ) : (
            searchedProfileData &&
            searchedProfileData.length > 0 && (
              <div className="creator-search-table-container">
                <TableListingRecords
                  blurRecords={isTrialMode}
                  columns={columns}
                  dataSource={searchedProfileData}
                  headerClickHandler={{}}
                  locale={null}
                  scroll={true}
                  size="small"
                  border={false}
                  rowClickHandler={(record) => {
                    return {
                      onMouseEnter: () => {
                        onMouseEnterRow(record);
                      },
                      onMouseLeave: onMouseLeaveRow,
                      onClick: (event) => {
                        const trElement = event.target.closest("tr[data-row-key]");
                        if (trElement) {
                          const rowKey = trElement.getAttribute("data-row-key");
                          sessionStorage.setItem("scrollTarget", rowKey);
                        }
                        let identifier = record.platformUsername;
                        let workplatformId = selectedPlatformID;
                        let selectedCreatorDetails = {
                          follower_count: record.followers,
                          full_name: record.full_name,
                          image_url: record.image_url,
                          is_verified: record.is_verified,
                          platform_username: record.platformUsername,
                          profile_url: record.profileImg,
                          workPlatformId: selectedPlatformID,
                        };
                        dispatchCreatorDiscoveryWarehouse({ type: BASIC_DETAILS_OF_SELECTED_PROFILE, payload: selectedCreatorDetails });
                        let searchedResults = {
                          searchedProfileResponse: searchedProfileResponse,
                          totalSearchedResults: searchedProfileResponse.length,
                          currentTab: currentTab,
                          selectedCreator: selectedCreatorDetails,
                        };
                        sessionStorage.setItem("search-results", JSON.stringify(searchedResults));
                        setSearchedProfileResponse([]);
                        navigate(CREATOR_PROFILE_LINK(identifier, workplatformId));
                        trackEvent(SEARCH_PROFILE_VIEWED, { username: identifier, platform: selectedPlatform });
                      },
                    };
                  }}
                />
                <UnlockFullAccessPopUp
                  open={searchedProfileData.length > 5 && isTrialMode}
                  onClose={() => setIsModalVisible(false)}
                  header="Unlock the full list of creators"
                  description="Go beyond the top 10 to find that perfect fit."
                  actionButtonLabel="Upgrade now"
                  height="235px"
                  padding="48px"
                />
              </div>
            )
          )}
        </>
      )}
      {!tableLoading && showTableErrorScreen.flag && showTable && (
        <>
          {showTableErrorScreen.status === 404 || showTableErrorScreen.status === "no_data" ? (
            <Box>
              <EmptyTableScreen
                icon={<Icons.no_content_icon />}
                header={tableErrorMessages.no_content}
                content={tableErrorMessages.no_profile_found}
              />
            </Box>
          ) : (
            <Box>
              <EmptyTableScreen icon={<Icons.failed_connections />} header={tableErrorMessages.try_again_msg} />
            </Box>
          )}
        </>
      )}
      {cardsLoading && !sessionStorage.getItem("search-results") && (
        <Box className="cards-container">
          <SearchCardSkeleton />
        </Box>
      )}
      {recentSearchProfileResponse &&
        recentSearchProfileResponse.length > 0 &&
        !cardsLoading &&
        !showTable &&
        !sessionStorage.getItem("search-results") && (
          <Box className="cards-container">
            <p>Recent searches</p>
            <Box>
              {/* {recentSearchProfileResponse.map((tag) => {
                return (
                  tag?.search_query?.query_text && (
                    <FeaturedSearchCard
                      filters={tag.search_query.query_text}
                      results={tag.result_count}
                      logo={WORKPLATFORM_ID_MAPPING[selectedPlatformID].platformLogo}
                      date={convertDate(tag.updated_at)}
                      onClickHandler={async () => {
                        setSearchInput(tag.search_query.query_text);
                        await handleUsersSearch(tag.search_query.query_text);
                      }}
                    />
                  )
                );
              })} */}
              {recentSearchProfileResponse.map((tag) => {
                return (
                  tag?.search_query?.query_text && (
                    <RecentSearchCard
                      tags={tag.search_query.query_text}
                      searchType={tag.search_type}
                      recentSearchCardId={tag.id}
                      deleteRecentSearchCardHandler={async (recentSearchCardId) => {
                        await clearRecentSearchCardHandler(recentSearchCardId);
                      }}
                      logo={WORKPLATFORM_ID_MAPPING[selectedPlatformID].platformLogo}
                      onClickHandler={async () => {
                        setSearchInput(tag.search_query.query_text);
                        await handleUsersSearch(tag.search_query.query_text);
                      }}
                    />
                  )
                );
              })}
            </Box>
          </Box>
        )}
    </div>
  );
};

export default ProfileSearchPage;
