import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Icons from "../Icons/Icons";
import TwitterContentData from "../TwitterContentData/TwitterContentData";
import "./AccountContentData.scss";
import Dropdown_but from "../Dropdown/Dropdown";
import { GAP_BETWEEN_CONTENT_CARDS, WIDTH_CONTENT_CARD } from "../../constants/constants";
import ContentData from "./ContentData";
import ContentDatePicker from "../ContentDatePicker/ContentDatePicker";

const CONTENT_FEED_ATTRIBUTES = {
  thumbnail_url: "thumbnail_url",
  title: "title",
  likes: "likes",
  comments: "comments",
  views: "views",
  watch_time: "watch_time",
  impressions: "impressions",
  reach: "reach",
  shares: "shares",
};

const CONTENT_FEED_MAPPING = {
  YouTube: [
    CONTENT_FEED_ATTRIBUTES.thumbnail_url,
    CONTENT_FEED_ATTRIBUTES.title,
    CONTENT_FEED_ATTRIBUTES.likes,
    CONTENT_FEED_ATTRIBUTES.comments,
    CONTENT_FEED_ATTRIBUTES.views,
    CONTENT_FEED_ATTRIBUTES.watch_time,
  ],
  Instagram: [
    CONTENT_FEED_ATTRIBUTES.thumbnail_url,
    CONTENT_FEED_ATTRIBUTES.title,
    CONTENT_FEED_ATTRIBUTES.likes,
    CONTENT_FEED_ATTRIBUTES.comments,
    CONTENT_FEED_ATTRIBUTES.views,
    CONTENT_FEED_ATTRIBUTES.impressions,
    CONTENT_FEED_ATTRIBUTES.reach,
  ],
  TikTok: [
    CONTENT_FEED_ATTRIBUTES.thumbnail_url,
    CONTENT_FEED_ATTRIBUTES.title,
    CONTENT_FEED_ATTRIBUTES.likes,
    CONTENT_FEED_ATTRIBUTES.comments,
    CONTENT_FEED_ATTRIBUTES.views,
    CONTENT_FEED_ATTRIBUTES.shares,
  ],
  Twitter: [CONTENT_FEED_ATTRIBUTES.thumbnail_url, CONTENT_FEED_ATTRIBUTES.title, CONTENT_FEED_ATTRIBUTES.likes, CONTENT_FEED_ATTRIBUTES.shares],
  Facebook: [
    CONTENT_FEED_ATTRIBUTES.thumbnail_url,
    CONTENT_FEED_ATTRIBUTES.title,
    CONTENT_FEED_ATTRIBUTES.likes,
    CONTENT_FEED_ATTRIBUTES.comments,
    CONTENT_FEED_ATTRIBUTES.views,
    CONTENT_FEED_ATTRIBUTES.impressions,
    CONTENT_FEED_ATTRIBUTES.reach,
  ],
  others: [
    CONTENT_FEED_ATTRIBUTES.thumbnail_url,
    CONTENT_FEED_ATTRIBUTES.title,
    CONTENT_FEED_ATTRIBUTES.likes,
    CONTENT_FEED_ATTRIBUTES.comments,
    CONTENT_FEED_ATTRIBUTES.views,
    CONTENT_FEED_ATTRIBUTES.impressions,
    CONTENT_FEED_ATTRIBUTES.reach,
  ],
};

const CONTENT_WORKPLATFORMS_MAPPING = {
  "9bb8913b-ddd9-430b-a66a-d74d846e6c66": [
    { key: "All" },
    { key: "Video" },
    { key: "Image" },
    { key: "Feed" },
    { key: "IGTV" },
    { key: "Reels" },
    { key: "Album" },
    { key: "Story" },
  ],
  "de55aeec-0dc8-4119-bf90-16b3d1f0c987": [{ key: "All" }, { key: "Video" }],
  "14d9ddf5-51c6-415e-bde6-f8ed36ad7054": [{ key: "All" }, { key: "Video" }, { key: "Playlist" }],
  "7645460a-96e0-4192-a3ce-a1fc30641f72": [{ key: "All" }, { key: "Tweet" }],
  "e4de6c01-5b78-4fc0-a651-24f44134457b": [{ key: "All" }, { key: "Video" }, { key: "Stream" }],
  "ad2fec62-2987-40a0-89fb-23485972598c": [{ key: "All" }, { key: "Image" }, { key: "Video" }, { key: "Post" }],
};

function AccountContentData({
  selectedPlatform,
  title,
  titleIcon,
  content,
  setLimit,
  limit,
  selectedType,
  setIsSelectedType,
  fromDateHandler,
  toDateHandler,
}) {
  // const [content, setContent] = useState(null);
  const [currWorkPlatformMetrics, setCurrWorkPlatformMetrics] = useState(null);
  const [workPlatformName, setWorkPlatformName] = useState(null);
  const isCompMounted = useRef();
  const pollId = useRef();
  const pollStartTime = useRef();
  // const [limit, setLimit] = useState();
  const [products, setProducts] = useState([]);
  const [trimContent, SetTrimContent] = useState(null);
  const noHoverState = {
    "&:hover": {
      borderColor: "rgba(255,240,10,0.8)",
    },
  };
  useEffect(() => {
    if (limit && content) {
      let trim = content.data.slice(0, limit);
      //sort trim data by published_at which has data in 2023-01-24T15:29:24.000000 format
      trim = trim.sort((a, b) => {
        return new Date(b.published_at) - new Date(a.published_at);
      });
      SetTrimContent({ data: trim });
    }
  }, [content, limit]);

  useLayoutEffect(() => {
    let contentGridContainer = document.querySelector(".content-grid-container");

    const twitterContenGridContainer = document.querySelector(".twitter-content-grid-container");
    if (selectedPlatform?.work_platform?.id !== "7645460a-96e0-4192-a3ce-a1fc30641f72" && contentGridContainer) {
      contentGridContainer = document.querySelector(".content-grid-container");
    } else {
      contentGridContainer = document.querySelector(".twitter-content-grid-container");
    }

    const totalWidth = contentGridContainer.clientWidth;
    const totalCards = Math.floor(totalWidth / (WIDTH_CONTENT_CARD + GAP_BETWEEN_CONTENT_CARDS));
    setLimit(totalCards * 2);
  });

  useEffect(() => {
    if (selectedPlatform) {
      const work_platform_name = selectedPlatform.work_platform.name;
      if (CONTENT_FEED_MAPPING.hasOwnProperty(work_platform_name)) {
        setCurrWorkPlatformMetrics(CONTENT_FEED_MAPPING[work_platform_name]);
      } else {
        setCurrWorkPlatformMetrics(CONTENT_FEED_MAPPING["others"]);
      }
      setWorkPlatformName(work_platform_name);
      const work_platform_id = selectedPlatform.work_platform.id;
      if (CONTENT_WORKPLATFORMS_MAPPING.hasOwnProperty(work_platform_id)) {
        setProducts(CONTENT_WORKPLATFORMS_MAPPING[work_platform_id]);
      }
    }
  }, [selectedPlatform]);

  function ContentTopBar() {
    if (products.length === 0) {
      return null;
    }
    return (
      <Grid container direction="row" alignItems="flex-start" spacing={1.75}>
        <Grid item>
          <Dropdown_but products={products} icons={false} selected={selectedType} setIsSelected={setIsSelectedType} />
        </Grid>
        <Grid item>
          <ContentDatePicker fromDateHandler={fromDateHandler} toDateHandler={toDateHandler} />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {selectedPlatform?.work_platform?.id === "7645460a-96e0-4192-a3ce-a1fc30641f72" && (
        <>
          <Box className="content-container contentMainContainer">
            <Box className="mainHeading">
              {" "}
              <div
                style={{
                  marginRight: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {titleIcon}
              </div>
              {title}
            </Box>
            <ContentTopBar />
            <TwitterContentData content={trimContent} title={title} titleIcon={titleIcon} cols={limit / 2} />
          </Box>
        </>
      )}
      {selectedPlatform?.work_platform?.id !== "7645460a-96e0-4192-a3ce-a1fc30641f72" && (
        <Box className="content-container contentMainContainer">
          <Box className="mainHeading">
            {" "}
            <div
              style={{
                marginRight: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {titleIcon}
            </div>
            {title}
          </Box>
          <ContentTopBar />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
            className="content-grid-container"
          >
            {content ? (
              trimContent?.data.map((content, index) => (
                <ContentData content={content} currWorkPlatformMetrics={currWorkPlatformMetrics} CONTENT_FEED_ATTRIBUTES={CONTENT_FEED_ATTRIBUTES} />
              ))
            ) : (
              <Box className="no-content-message">
                <Icons.no_content_icon />
                <p>Oops, no content to show here.</p>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default AccountContentData;
