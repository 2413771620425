import React, { useContext, useEffect, useState } from 'react';
import { Loader, ProductsCard } from '../../components';
import TextBox from '../../components/InsightIQ/TextBox/TextBox';
import { getPageTitle, productsPageSubTitle, productsPageTitle, searchProducts } from '../../constants/constants';
import './ProductsPage.scss';
import { getProductList } from '../../api/products/products';
import { SEARCH_CLICKED, trackEvent } from '../../analytics';
import { isEmpty } from '../../utils/util';
import { Helmet } from "react-helmet";
import { WarehouseContext } from "../../storage/context";
export default function ProductsPage() {
    const [searchText, setSearchText] = useState('');
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { warehouse } = useContext(WarehouseContext);

    useEffect(() => {
        setIsLoading(true);
        getProductList().then((productData) => {
            setProducts(productData);
            setFilteredProducts(productData);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    const onSearch = (searchText) => {
        if (isEmpty(searchText)) {
            setFilteredProducts(products);
            return;
        }
        const filteredList = products?.filter(({ title, description }) =>
            title.toLowerCase().includes(searchText.toLowerCase()) ||
            description.toLowerCase().includes(searchText.toLowerCase()));

        setFilteredProducts(filteredList);
        trackEvent(SEARCH_CLICKED, {
            search_string: searchText,
        });
    };

    function FilteredProductsList() {
        if (isEmpty(filteredProducts)) {
            return null;
        }
        return (
            <div className='products-data'>
                {filteredProducts?.map((product, index) => (
                    <ProductsCard
                        key={index}
                        product={product}
                    />
                ))}
            </div>
        );
    }

    window.addEventListener('keydown', function (event) {
        // Check if Ctrl (Cmd on Mac) and F keys are pressed simultaneously
        if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
            event.preventDefault(); // Prevent the default browser Find functionality

            // Focus on the custom search input field
            const productsSearchInput = document.getElementsByClassName('MuiInputBase-input')[1];
            productsSearchInput.focus();

        }
    });

    return (
        <div className='product-page-main-container'>
            <Helmet>
                <title>{getPageTitle('PRODUCTS_PAGE', warehouse.environment.current)}</title>
            </Helmet>
            <div className='product-page-wrapper-container'>
                <header className='products-page-header'>
                    <div>
                        <div className='title'>{productsPageTitle}</div>
                        <div className='body-r products-page-subtitle'>{productsPageSubTitle}</div>
                    </div>
                    <div className='products-page-search'>
                        <TextBox
                            variant={'short'}
                            placeholder={searchProducts}
                            value={searchText}
                            onEnter={onSearch}
                            onClear={() => {
                                setSearchText('');
                                onSearch('');
                            }}
                            containerClass={'hundred-percent-width products-search'}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                isEmpty(e.target.value) && onSearch('');
                            }}

                        />
                    </div>
                </header>
                {isLoading ?
                    <Loader loading={isLoading}/> : <FilteredProductsList/>}
            </div>
        </div>
    );
};
