import React, { useEffect, useRef, useState } from "react";
import "./TagsCard.scss";
import Icons from "../Icons/Icons";
import PropTypes from "prop-types";
import { Box, Skeleton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { trackEvent } from "../../analytics";

const TagsCard = ({ loading, header, tags, isIconWithTag = false, tooltip = false, event = false, suffix, description, icon, tooltipText }) => {
  const containerRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    // Check if content overflows on mount and when the content changes
    checkOverflow();
  }, [containerRef.current]);

  const checkOverflow = () => {
    const container = containerRef.current;
    if (container) {
      const hasOverflow = container.scrollHeight > container.clientHeight;
      setHasOverflow(hasOverflow);
    }
  };

  return loading ? (
    <TagsCardSkeletonComponent tags={tags} />
  ) : (
    <div className="tags-card-container">
      <div className="tags-card-header-container">
        <div className="tags-card-header-icon">{icon}</div>
        <div className="tags-card-header-tooltip-container">
          <Box display={"flex"} gap={"4px"} alignItems={"center"}>
            <div className="tags-card-header">{header}</div>
            {tooltip && (
              <Tooltip title={tooltipText} placement="top-start">
                <i className="ri-information-line tags-icon"></i>
              </Tooltip>
            )}
          </Box>
          <div className="tags-card-description">{description}</div>
        </div>
      </div>
      <Box className={`tags-scroll-container ${hasOverflow ? "has-overflow" : ""}`}>
        <div className="tags-container" ref={containerRef}>
          {tags.map((tag, index) => {
            return (
              <div
                onClick={() => {
                  trackEvent(event, { source_profile_username: tag.name, source: header });
                }}
                className="label-container"
                key={index}
              >
                <div style={{ display: !isIconWithTag ? "none" : "flex" }}>{isIconWithTag && tag.icon}</div>
                {tag.name.length > 30 ? (
                  <Tooltip title={tag.name} placement="right">
                    <div className="tag-name">
                      {suffix && suffix}
                      {tag.name.substring(0, 27) + "..."}
                    </div>
                  </Tooltip>
                ) : (
                  <div className="tag-name">
                    {suffix && suffix}
                    {tag.name}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Box>
    </div>
  );
};

const TagsCardSkeletonComponent = ({ tags }) => {
  return (
    <div className="tags-card-container">
      <Skeleton animation="wave" height={16} width="100%" />
      <div className="tags-container">
        {tags.map((tag, index) => {
          return <Skeleton animation="wave" width={106} sx={{ borderRadius: "1000px" }} />;
        })}
      </div>
    </div>
  );
};

TagsCard.propTypes = {
  loading: PropTypes.bool,
  header: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.element,
    })
  ),
  isIconWithTag: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default TagsCard;
