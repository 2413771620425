import userEvent from "@testing-library/user-event";
import { Form, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { SECRET_ROTATION_PASSWORD_CANCEL, trackEvent } from "../../analytics";
import { Icons, PasswordField, PrimaryButton, Typography } from "../../components";
import { EMPTY_REQUIRED_FIELD_MESSAGE } from "../../constants/constants";
import { loginErrorMessage } from "../../constants/errorMessages";
import { CLIENT_SECRET_ROTATED } from "../../constants/toastMessages";
import useFormSubmitDisabled from "../../hooks/useFormSubmitDisable";
import { useToast } from "../../hooks/useToast";
import "./APICredentialsPage.scss";

const RotateSecretModal = ({ handler, isModalVisible, setIsModalVisible }) => {
  const [formInstance] = Form.useForm();
  const [errors, setErrors] = useState({ password: false, errorMessage: "" });
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = useToast();
  const [isDisabled, setIsDisabled] = useState(true);
  const requiredFormFields = ["password"];
  const disableSubmit = useFormSubmitDisabled(formInstance, requiredFormFields);
  const buttonRef = useRef(null);
  // const disabledState = useFormSubmitDisabled(
  //   formInstance,
  //   formInstance.fields
  // );
  // useEffect(() => {
  //   setIsDisabled(
  //     !formInstance.isFieldsTouched(true) ||
  //       formInstance.getFieldsError().filter(({ errors }) => errors.length)
  //         .length > 0
  //   );
  // }, [formInstance]);

  const resetError = (name) => {
    setErrors({ ...errors, [name]: false });
  };

  const handleSubmitForm = () => {
    setButtonLoading(true);
    const { password } = formInstance.getFieldsValue();
    const response = handler(password);
    response
      .then(() => {
        setButtonLoading(false);
        setIsModalVisible(false);
        toast(CLIENT_SECRET_ROTATED);
      })
      .catch((err) => {
        console.log(err, "err");
        setButtonLoading(false);
        setErrors({ errorMessage: err, password: true });
      });
    return response;
  };

  return (
    <Modal
      title={
        <Typography h3 style={{ fontWeight: "600" }}>
          Rotate Secret
        </Typography>
      }
      destroyOnClose={true}
      visible={isModalVisible}
      closeIcon={<Icons.close_circle_fill />}
      width={"384px"}
      bodyStyle={{ height: "375px" }}
      closable={true}
      icon={false}
      cancelText={null}
      cancelButtonProps={{ style: { display: "none" } }}
      footer={null}
      // okText={"Create new secret"}
      // footer={null}
      // okButtonProps={{
      //   style: {
      //     color: "#ffffff",
      //     background: "border: 1.5px solid #204187 !important",
      //     backgroundColor: "var(--main-primary) !important",
      //   },
      //   disabled: buttonDisabled,
      // }}
      // okType={null}
      // okText={<PrimaryButton label="Create new secret" />}
      onOk={() => {
        const { password } = formInstance.getFieldsValue();
        handler(password)
          .then(() => toast(CLIENT_SECRET_ROTATED))
          .catch((err) => {
            console.log(err, "is err");
            setErrors({
              password: true,
              errorMessage: loginErrorMessage[err.error_code],
            });
          });
      }}
      onCancel={() => {
        formInstance.resetFields();
        setErrors({ password: false, errorMessage: "" });
        setIsModalVisible(!isModalVisible);
        trackEvent(SECRET_ROTATION_PASSWORD_CANCEL);
      }}
    >
      <div>
        <div className="rotate-secret-caution-container">
          <Icons.caution />
          <p>This generates a new secret, and the old secret automatically expires after 7 days. You cannot view or use the old secret after that.</p>
        </div>
        <div className="rotate-secret-password-container">
          <p style={{ color: "#4C505B" }}>Enter your password to continue with this action.</p>
          <Form form={formInstance} preserve={false}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Form.Item
                name="password"
                rules={[{ required: true, message: EMPTY_REQUIRED_FIELD_MESSAGE }]}
                // validateStatus={errors ? "error" : ""}
              >
                <PasswordField
                  showTags={false}
                  inputLabel="Password"
                  placeholder="Enter your password"
                  onChange={() => {
                    resetError("password");
                  }}
                  hasError={errors.password}
                />
              </Form.Item>

              <p className="password-error-text">{errors.errorMessage}</p>
            </div>
            <Form.Item shouldUpdate>
              {() => (
                <PrimaryButton
                  label="Create new secret"
                  loading={buttonLoading}
                  style={{
                    float: "right",
                  }}
                  disabled={disableSubmit()}
                  htmlType="submit"
                  onSubmit={handleSubmitForm}
                  // disabled={
                  //   !formInstance.isFieldsTouched(true) ||
                  //   formInstance
                  //     .getFieldsError()
                  //     .filter(({ errors }) => errors.length).length > 0
                  // }
                  // disabled={disabledState}
                  // disabled={isDisabled}
                  onClick={() => {
                    handleSubmitForm();
                  }}
                />
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default RotateSecretModal;
