import React from "react";
import "./Card.scss";

export default function Card({ children, className = "", ...otherProps }) {
  return (
    <div className={`card-container ${className}`} {...otherProps}>
      {children}
    </div>
  );
}
