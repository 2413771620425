import { Box } from '@material-ui/core';
import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react'
import Icons from '../Icons/Icons';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import "./AddBrandProfilesPopup.scss";
import {
    instagram_profile_URL_REGEX,
    tiktok_profile_URL_REGEX,
    WORKPLATFORM_ID_MAPPING,
    WORKPLATFORM_IDS,
    youtube_profile_URL_REGEX,
} from "../../constants/constants";
import { getBrandByID, updateBrandByID } from '../../api/CampaignTrackingAPI/CampaignTrackingAPI';
import { WarehouseContext } from '../../storage/context';
import Loader from '../Loader/Loader';
import {
    ADD_BRAND_PROFILES_DESCRIPTION,
    ADD_BRAND_PROFILES_ERROR,
    ADD_BRAND_PROFILES_HEADER,
    DONE
} from "../../constants/campaignTrackingConstants";
import { convertURLToPlatformURL } from '../../utils/util';

const AddBrandProfilesPopup = ({ brand, onClose, onDone }) => {
  const { warehouse } = useContext(WarehouseContext);
  const { YouTubePlatformId, InstagramPlatformId, TikTokPlatformId } = WORKPLATFORM_IDS
  const [brandProfiles, setBrandProfiles] = useState({
    [YouTubePlatformId]: { profileURL: "", convertedProfileURL: "", isValid: true },
    [InstagramPlatformId]: { profileURL: "", convertedProfileURL: "", isValid: true },
    [TikTokPlatformId]: { profileURL: "", convertedProfileURL: "", isValid: true },
  })
  const [brandLoading, setBrandLoading] = useState(true)

  const { value: brand_id, name } = brand;
  const header = ADD_BRAND_PROFILES_HEADER(name);
  const description = ADD_BRAND_PROFILES_DESCRIPTION;

  const getBrandProfile = async () => {
    try {
      setBrandLoading(true)
      const brand = await getBrandByID({ warehouse, brand_id })
      if (brand.id) {
        const updatedProfiles = brandProfiles;
        brand?.profiles.forEach(profile => {
          if (profile.work_platform_id in updatedProfiles) {
            updatedProfiles[profile.work_platform_id].profileURL = profile.profile_url;
            updatedProfiles[profile.work_platform_id].convertedProfileURL = profile.profile_url;
            updatedProfiles[profile.work_platform_id].isValid = true;
          }
        });
        setBrandProfiles(updatedProfiles);
      }
    } catch (error) {
      onClose()
    } finally {
      setBrandLoading(false)
    }
  }

  useEffect(() => {
    getBrandProfile().then(r => {
    });
  }, []);

  const handleInputChange = (e, platformId) => {
    const inputValue = e.target.value
    setBrandProfiles((prev) => {
      return {
        ...prev,
        [platformId]: { profileURL: inputValue, isValid: true }
      }
    })
  }
  const handleInputBlur = (platformId) => {
    const enteredURL = brandProfiles[platformId].profileURL

    const urlRegexMapping = {
      [YouTubePlatformId]: youtube_profile_URL_REGEX,
      [InstagramPlatformId]: instagram_profile_URL_REGEX,
      [TikTokPlatformId]: tiktok_profile_URL_REGEX
    }
    let isUrlValid;
    let url;
    if (enteredURL === "") {
      url = ''
      isUrlValid = true
    } else {
      isUrlValid = urlRegexMapping[platformId].test(enteredURL);
      if (isUrlValid) {
        url = convertURLToPlatformURL(enteredURL, platformId);
      } else {
        url = enteredURL
      }
    }
    setBrandProfiles((prev) => {
      return {
        ...prev,
        [platformId]: { profileURL: enteredURL, convertedProfileURL: url, isValid: isUrlValid }
      }
    })
  }

  const getInputPlaceholder = (platformId) => {
    let name = WORKPLATFORM_ID_MAPPING[platformId].platformName;

    return `Paste brand’s ${name} account link / URL`
  }

  const getFormatError = (platformId) => {

    return (
      <div className='url-format-error subtitle-m'>
        <Icons.caution_red />&nbsp;
        {ADD_BRAND_PROFILES_ERROR(WORKPLATFORM_ID_MAPPING[platformId].platformName)}
      </div>
      );
  }

  function onDoneClick() {
    const payload = Object.keys(brandProfiles).map((platformId) => ({
      work_platform_id: platformId,
      profile_url: brandProfiles[platformId].convertedProfileURL,
    }));
    updateBrandByID({ warehouse, brand_id, profiles: payload }).then(() =>
        onDone()
    ).finally(() =>
        onClose()
    );
  }

  function handleClearClick(e, platformId) {
    setBrandProfiles(prev => ({
      ...prev,
      [platformId]: { profileURL: '', isValid: true, convertedProfileURL : '' }
    }));
  }

  return (
    <div className="modal">
      <Box className="brand-profile-pop-up-container">
        {!brandLoading ?
          <Box>
            <Box className='add-profile-header' >
              <Box className="section-heading" >{header}</Box>
              <Box className="add-profile-header-desc body-r" >{description}</Box>
            </Box>

            <Box className='add-profile-input-container' >
              {/* instagram */}
              <Box>
                <Box> <img src={WORKPLATFORM_ID_MAPPING[InstagramPlatformId].platformLogo} /> </Box>
                <Box>
                  <TextField className={'brand-socials-text-field'}
                    placeholder={getInputPlaceholder(InstagramPlatformId)}
                    onChange={(e) => {
                      handleInputChange(e, InstagramPlatformId)
                    }}
                    value={brandProfiles[InstagramPlatformId]?.profileURL}
                    onBlur={() => handleInputBlur(InstagramPlatformId)}
                    onEnter={() => handleInputBlur(InstagramPlatformId)}
                    error={!brandProfiles[InstagramPlatformId]?.isValid}
                    helperText={!brandProfiles[InstagramPlatformId]?.isValid && getFormatError(InstagramPlatformId)}
                    onClear={(e) => {
                      handleClearClick(e, InstagramPlatformId);
                    }}
                    inputProps={{
                      maxLength: 100,
                    }}
                    InputProps={{
                      endAdornment: (
                          <>
                            {brandProfiles[InstagramPlatformId]?.profileURL && (
                                <i className={'ri-close-line clear-icon'}
                                   onClick={(e) => handleClearClick(e, InstagramPlatformId)} />
                            )}
                          </>
                      )
                    }}
                  />
                </Box>
              </Box>
              {/* youtube */}
              <Box>
                <Box> <img src={WORKPLATFORM_ID_MAPPING[YouTubePlatformId].platformLogo} /> </Box>
                <Box>
                  <TextField className={'brand-socials-text-field'}
                    placeholder={getInputPlaceholder(YouTubePlatformId)}
                    value={brandProfiles[YouTubePlatformId]?.profileURL}
                    onChange={(e) => handleInputChange(e, YouTubePlatformId)}
                    onBlur={() => handleInputBlur(YouTubePlatformId)}
                    onEnter={() => handleInputBlur(YouTubePlatformId)}
                    error={!brandProfiles[YouTubePlatformId]?.isValid}
                    helperText={!brandProfiles[YouTubePlatformId]?.isValid && getFormatError(YouTubePlatformId)}
                    onClear={(e) => {
                      handleClearClick(e, YouTubePlatformId);
                    }}
                     inputProps={{
                      maxLength: 100,
                    }}
                     InputProps={{
                       endAdornment: (
                           <>
                             {brandProfiles[YouTubePlatformId]?.profileURL && (
                                 <i className={'ri-close-line clear-icon'}
                                    onClick={(e) => handleClearClick(e, YouTubePlatformId)} />
                             )}
                           </>
                       )
                     }}
                  />
                </Box>
              </Box>
              {/* Tiktok */}
              <Box>
                <Box> <img src={WORKPLATFORM_ID_MAPPING[TikTokPlatformId].platformLogo} /> </Box>
                <Box>
                  <TextField className={'brand-socials-text-field'}
                    placeholder={getInputPlaceholder(TikTokPlatformId)}
                    value={brandProfiles[TikTokPlatformId]?.profileURL}
                    onChange={(e) => handleInputChange(e, TikTokPlatformId)}
                    onBlur={() => handleInputBlur(TikTokPlatformId)}
                    onEnter={() => handleInputBlur(TikTokPlatformId)}
                    error={!brandProfiles[TikTokPlatformId]?.isValid}
                    helperText={!brandProfiles[TikTokPlatformId]?.isValid && getFormatError(TikTokPlatformId)}
                    onClear={(e) => {
                      handleClearClick(e, TikTokPlatformId);
                    }}
                     inputProps={{
                      maxLength: 100,
                    }}
                     InputProps={{
                       endAdornment: (
                           <>
                             {brandProfiles[TikTokPlatformId]?.profileURL && (
                                 <i className={'ri-close-line clear-icon'}
                                    onClick={(e) => handleClearClick(e, TikTokPlatformId)} />
                             )}
                           </>
                       )
                     }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="add-profile-pop-up-action">
              <PrimaryButton
                label={DONE}
                className="button add-profile-button"
                onClick={onDoneClick}
                disabled={Object.values(brandProfiles).some(profile => !profile.isValid)}
              />
            </Box>

          </Box> : <Loader className='add-profile-loader' loading={brandLoading} />}
        <Box className="close-icon-container">
          <Icons.close_icon onClick={onClose} cursor="pointer" />
        </Box>
      </Box>
    </div>
  )
}

export default AddBrandProfilesPopup