import React from "react";
import Icons from "../Icons/Icons";

const GettingStartedStepSuccess = ({ content, withIcon = false, success }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {withIcon && (success ? <Icons.tick /> : <Icons.getting_started_failure />)}
      <p
        style={{
          marginLeft: withIcon ? "8px" : "0px",
          marginTop: "10px",
          marginBottom: "10px",
          color: success ? "#188c5b" : "#AD3737",
        }}
      >
        {content}
      </p>
    </div>
  );
};

export default GettingStartedStepSuccess;
