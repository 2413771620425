import { DonutChart, DonutChartLegend, EmptyTableScreen, Icons, Loader } from "../index";
import React from "react";
import './CommentsAnalysis.scss';
import '../../styles/insightIq.scss';
import {
    ANALYZING_COMMENTS,
    AUDIENCE_SENTIMENT,
    AUDIENCE_SENTIMENT_MESSAGE,
    COMMENT_FAILURE_TITLE,
    COMMENTS_ANALYSIS_MESSAGE_SUCCESS,
    COMMENTS_ANALYSIS_TITLE_SUCCESS,
    INTENT_TO_BUY_PRODUCT,
    INTENT_TO_BUY_PRODUCT_MESSAGE,
    LEARN_HOW_AI,
    LEARN_HOW_AI_TEXT,
    NEGATIVE,
    NEUTRAL,
    NOT_RELEVANT,
    POSITIVE,
    RELEVANT,
    RELEVANT_COMMENTS,
    RELEVANT_COMMENTS_MESSAGE,
    THIS_MIGHT_TAKE_SOME_TIME
} from "../../constants/constants";
import { Colors } from "../../styles/colors";
import { Chip, Tooltip } from "@mui/material";
import {
    getFractionsText,
    INTERESTED,
    NOT_INTERESTED,
    OOPS_POST_HAS_NO_COMMENTS,
    OOPS_POST_HAS_NO_COMMENTS_MESSAGE,
    somethingWentWrongMsgDesc
} from "../../constants/campaignTrackingConstants";
import { Box } from "@material-ui/core";
import { formatNumber, percentFormatter } from "../../utils/profileHelpers";
import { isEmpty } from "../../utils/util";

function isNullOrZero(val) {
    return val === null || val === 0;
}

export function Outline({ title, subTitle, showTooltip, children }) {
    return <div className={"div-comments-analysis"}>
        <div className={"div-comments-analysis-header"}>
            <div className={"div-left-header"}>
                {title}
                {subTitle}
            </div>
            {showTooltip ?
                <Tooltip placement={"bottom-start"} title={LEARN_HOW_AI_TEXT}>
                    <Chip
                        icon={<i className="ri-information-line tooltip-icon"></i>}
                        label={<div className={"body-r tooltip-label"}>{LEARN_HOW_AI}</div>}
                        tabIndex={-1}
                        classes={{
                            root: "platform-btn",
                        }}
                        className={"comments-chip"}
                    />
                </Tooltip> : null}
        </div>
        {children}
    </div>;
}

export default function CommentsAnalysis({ campaignContentData }) {
    if (!campaignContentData) {
        return null;
    }

    const {
        total_positive_comments,
        total_negative_comments,
        total_neutral_comments,
        total_analysed_comments,
        relevancy_score,
        purchase_intent_comment_count
    } = campaignContentData;

    let profile_comment_count = null;
    if (!isEmpty(campaignContentData.comment_count)) {
        profile_comment_count = campaignContentData.comment_count;
    }

    const { sync_error_code, sync_failure_reason } = campaignContentData;

    let title = null, subTitle = null;
    let relevantCommentsChart = null, audienceSentimentChart = null, purchaseIntentChart = null;
    const noCommentAnalysis_profileCommentCount = isNullOrZero(profile_comment_count);
    const noCommentAnalysis_analysisCommentCount = !isEmpty(sync_error_code) || !isEmpty(sync_failure_reason);
    const commentAnalysis_inProgress = total_analysed_comments === null || relevancy_score === null;

    if (!isEmpty(sync_error_code) || !isEmpty(sync_failure_reason)) {
        console.error(`Comment analysis is failing. Here is the sync_error_code: ${sync_error_code} and the sync_failure_reason: ${sync_failure_reason}`);
    }

    if (noCommentAnalysis_profileCommentCount || noCommentAnalysis_analysisCommentCount) {
        title = <div className={'section-heading'}>{COMMENTS_ANALYSIS_TITLE_SUCCESS}</div>;
    } else if (commentAnalysis_inProgress) {
        title = (
            <div className={'section-heading div-row'} style={{ gap: 16 }}>
                <Loader className={"campaign-loader-container"}/>
                {ANALYZING_COMMENTS}
            </div>
        );
        subTitle = <div className={'body-r subtitle'}>{THIS_MIGHT_TAKE_SOME_TIME}</div>;
    } else {
        title = <div className={'section-heading'}>{COMMENTS_ANALYSIS_TITLE_SUCCESS}</div>;
        subTitle = <div className={'body-r subtitle'}>
            {COMMENTS_ANALYSIS_MESSAGE_SUCCESS(formatNumber(total_analysed_comments))}</div>;

        if (relevancy_score === null) {
            relevantCommentsChart = null;
        } else {
            const relevantCommentsData = getRelevantCommentsData();
            relevantCommentsChart = (
                <div className={'div-cell'}>
                    <DonutChart data={relevantCommentsData.data} labels={relevantCommentsData.labels}
                                variant={'dual-connected'} height={160} width={160}/>
                </div>
            );
        }

        if (isNullOrZero(total_analysed_comments)) {
            audienceSentimentChart = null;
        } else {
            const audienceSentimentData = getAudienceSentimentData();
            audienceSentimentChart = (
                <div className={'div-cell div-chart-audience-sentiment'}>
                    <DonutChart data={audienceSentimentData.data} labels={audienceSentimentData.labels}
                                variant={'triple-disconnected'} height={160} width={160}/>
                    <div className={'donut-legend'}>
                        {audienceSentimentData.legendData.map(DonutChartLegend)}
                    </div>
                </div>
            );
        }

        if (isNullOrZero(total_analysed_comments) || purchase_intent_comment_count === null) {
            purchaseIntentChart = null;
        } else {
            const purchaseIntentData = getPurchaseIntentData();
            purchaseIntentChart = (
                <div className={'div-cell'} style={{ marginBottom: -64 }}>
                    <DonutChart data={purchaseIntentData.data} labels={purchaseIntentData.labels}
                                variant={'half-connected'} height={160} width={160}/>
                </div>
            );
        }
    }

    function getRelevantCommentsData() {
        const label = percentFormatter.format(relevancy_score);
        return {
            data: [
                { name: RELEVANT, value: relevancy_score },
                { name: NOT_RELEVANT, value: 1 - relevancy_score },
            ],
            labels: [label, RELEVANT]
        };
    }

    function getAudienceSentimentData() {
        const rawData = [{
            name: POSITIVE,
            color: Colors.accentCaribbeanGreen,
            value: total_positive_comments,
        }, {
            name: NEGATIVE,
            color: Colors.accentAmaranth,
            value: total_negative_comments,
        }, {
            name: NEUTRAL,
            color: Colors.neutralsBorderGrey,
            value: total_neutral_comments,
        }];

        const data = rawData.map((d) => {
            const { name, color, value } = d;
            return { name, color, value: value / total_analysed_comments };
        });

        function getLabelFields() {
            if (total_negative_comments > total_positive_comments) {
                return {
                    value: total_negative_comments,
                    name: NEGATIVE
                };
            }
            return {
                value: total_positive_comments,
                name: POSITIVE
            };
        }

        const {
            value: labelValue,
            name: labelName
        } = getLabelFields();

        const labels = [percentFormatter.format(labelValue / total_analysed_comments), labelName];

        const legendData = [{
            color: Colors.accentCaribbeanGreen,
            title: percentFormatter.format(total_positive_comments / total_analysed_comments),
            totalCount: formatNumber(total_positive_comments),
            subTitle: POSITIVE,
        }, {
            color: Colors.neutralsBorderGrey,
            title: percentFormatter.format(total_neutral_comments / total_analysed_comments),
            totalCount: formatNumber(total_neutral_comments),
            subTitle: NEUTRAL,
        }, {
            color: Colors.accentAmaranth,
            title: percentFormatter.format(total_negative_comments / total_analysed_comments),
            totalCount: formatNumber(total_negative_comments),
            subTitle: NEGATIVE,
        }];

        return {
            data,
            labels,
            legendData,
        };
    }

    function getPurchaseIntentData() {
        const label = percentFormatter.format(purchase_intent_comment_count / total_analysed_comments);
        return {
            data: [
                { name: INTERESTED, value: (purchase_intent_comment_count / total_analysed_comments) },
                { name: NOT_INTERESTED, value: 1 - purchase_intent_comment_count / total_analysed_comments },
            ],
            labels: [label, INTERESTED]
        };
    }

    function FailureMessage() {
        return (
            <Box className='campaign-error-content'>
                <EmptyTableScreen
                    icon={<Icons.details_not_found/>}
                    header={COMMENT_FAILURE_TITLE}
                    content={somethingWentWrongMsgDesc}
                    isActionButton={false}
                />
            </Box>
        );
    }

    function SuccessContent() {
        return (
            <div className={'div-comments-analysis-content'}>
                <div className={'div-row div-first-row'}>
                    {relevantCommentsChart}
                    {audienceSentimentChart}
                </div>
                <div className={'div-row div-second-row'}>
                    {relevantCommentsChart ? <div className={'div-cell div-chart-audience-sentiment-cell'}>
                        <div className={'sub-section-heading'}>
                            {RELEVANT_COMMENTS}
                        </div>
                        <div className={'body-r subtitle comments-message'}>
                            {getFractionsText(formatNumber(Math.round(relevancy_score * total_analysed_comments)), formatNumber(total_analysed_comments))}
                            <br/>
                            {RELEVANT_COMMENTS_MESSAGE}
                        </div>
                    </div> : null}
                    {audienceSentimentChart ? <div className={'div-cell div-chart-audience-sentiment-cell'}>
                        <div className={'sub-section-heading'}>
                            {AUDIENCE_SENTIMENT}
                        </div>
                        <div className={'body-r subtitle comments-message'}>
                            {AUDIENCE_SENTIMENT_MESSAGE}
                        </div>
                    </div> : null}
                </div>
                {purchaseIntentChart && <div className={'div-row div-first-row'}>
                    {purchaseIntentChart}
                </div>}
                {purchaseIntentChart && <div className={'div-row div-second-row'}>
                    <div className={'div-cell div-chart-audience-sentiment-cell'}>
                        <div className={'sub-section-heading'}>
                            {INTENT_TO_BUY_PRODUCT}
                        </div>
                        <div className={'body-r subtitle comments-message'}>
                            {getFractionsText(formatNumber(purchase_intent_comment_count), formatNumber(total_analysed_comments))}
                            <br/>
                            {INTENT_TO_BUY_PRODUCT_MESSAGE}
                        </div>
                    </div>
                    <div className={'div-cell div-chart-audience-sentiment-cell'}>
                        {/*This blank div is needed*/}
                    </div>
                </div>}
            </div>
        );
    }

    function CommentAnalysisLoading() {
        return (
            <div className={'div-row'} style={{ gap: 60 }}>
                <div className={'div-cell'}>
                    <Icons.rectangle_grey_100pcx262/>
                </div>
                <div className={'div-cell'}>
                    <Icons.rectangle_grey_100pcx262/>
                </div>
            </div>
        );
    }

    function NoCommentAnalysis() {
        return (
            <Box className='campaign-error-content no-comment-analysis'>
                <EmptyTableScreen
                    icon={<Icons.post_has_no_comments/>}
                    header={OOPS_POST_HAS_NO_COMMENTS}
                    content={OOPS_POST_HAS_NO_COMMENTS_MESSAGE}
                    isActionButton={false}
                />
            </Box>
        );
    }

    function Content() {
        if (noCommentAnalysis_profileCommentCount) {
            return <NoCommentAnalysis/>;
        }
        if (commentAnalysis_inProgress) {
            return <CommentAnalysisLoading/>;
        }
        if (noCommentAnalysis_analysisCommentCount) {
            return <FailureMessage/>;
        }
        return <SuccessContent/>;
    }

    return (
        <Outline title={title} subTitle={subTitle}
                 showTooltip={(!noCommentAnalysis_analysisCommentCount || !noCommentAnalysis_profileCommentCount)}
        >
            <Content/>
        </Outline>
    )
}