import { useContext, useEffect, useReducer, useState } from "react";
import { createShopifyAuthorisationURL, getShopifyStores } from "../../api/CampaignTrackingAPI/CampaignTrackingAPI";
import { UserContext, WarehouseContext } from "../../storage/context";
import { useNavigate } from "react-router-dom";
import { shopifyStoreWorkplatformId } from "../../constants/campaignTrackingConstants";
import useProfile from "../../hooks/useSetUserProfile";
import { IntermediateLoader } from "../../components";

function ShopifyOAuth() {
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);
  const queryParams = Object.fromEntries(searchParams);
  const { shop, hmac } = queryParams;
  const { userProfile, dispatchUserProfile } = useContext(UserContext);
  const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
  const navigate = useNavigate();
  const { logoutUserProfile } = useProfile();

  async function fetchStores() {
    return await getShopifyStores({ warehouse, status: "CONNECTED" });
  }

  function checkShopNameInResponse(shopifyStores) {
    if (!shopifyStores || shopifyStores.length === 0) return false;
    return shopifyStores.filter((shopItem) => shopItem.metadata.myshopify_domain === shop).length > 0;
  }

  async function initiateShopifyOAuth() {
    let shopSlug = shop.split(".")[0];
    if (shopSlug.includes("https://")) {
      shopSlug = shopSlug.split("https://")[1];
    }
    try {
      const postOAuthredirectURL = `${process.env.REACT_APP_POST_OAUTH_REDIRECT_URL}?shop_slug=${shopSlug}`;
      const response = await createShopifyAuthorisationURL({
        shopifyStoreWorkplatformId,
        shopSlug,
        postOAuthredirectURL,
        warehouse,
      });
      let shopifyOAuthURL = new URL(response.authorization_url);
      shopifyOAuthURL.searchParams.append("storeURL", encodeURIComponent(shop.includes("https://") ? shop : "https://" + shop));
      window.location.href = shopifyOAuthURL.href;
    } catch (error) {
      console.error("initiateShopifyOAuth", error);
    }
  }

  useEffect(() => {
    const hasLoggedIn = !!userProfile.user.emailVerified && !!userProfile.user.token;
    if (hasLoggedIn && !!shop && !!hmac) {
      fetchStores().then((shopifyStores) => {
        if (checkShopNameInResponse(shopifyStores)) navigate("/creators/campaigns");
        else initiateShopifyOAuth();
      });
    } else logoutUserProfile(dispatchWarehouse, dispatchUserProfile);
  }, [userProfile]);

  return (
    <div className="loader-container" style={{ height: "100vh", width: "100vw" }}>
      <IntermediateLoader />
    </div>
  );
}
export default ShopifyOAuth;
