import React from "react";
import "./SelectedCreatorList.scss";
import UserRow from "../UserRow/UserRow";
import { getRowId } from "../SelectableCreatorGrid/SelectableCreatorGrid";
import { isEmpty } from "../../utils/util";
import CheckBox from "../../components/CheckBox/CheckBox";
import { creators, WORKPLATFORM_ID_MAPPING } from "../../constants/constants";
import { Box, Tooltip } from "@mui/material";
import Icons from "../Icons/Icons";

const CREATOR_SEARCH_TABLE_ICONS = {
  Instagram: {
    icon: <Icons.insta_table_icon />,
  },
  YouTube: {
    icon: <Icons.youtube_table_icon />,
  },
  TikTok: {
    icon: <Icons.tiktok_table_icon />,
  },
};

const SelectedCreatorList = ({ rows, onDeleteRow, variant, onCheckBoxClick, onListClick, onCreatorExport }) => {
  const defaultSelectedCreatorList = (row) => {
    const { platform_username, thumbnail_url, is_verified, work_platform_id } = row;
    let { fullname } = row;
    if (isEmpty(fullname)) {
      fullname = platform_username;
    }
    const handleDelete = () => {
      if (row?.identifier_type) {
        onDeleteRow(getRowId(row)); // Notify parent component that a row is deleted
      } else {
        onDeleteRow(row);
      }
    };
    return (
      <div className="list-item">
        <UserRow
          name={fullname}
          username={platform_username}
          is_verified={is_verified}
          work_platform_id={work_platform_id}
          thumbnail_url={thumbnail_url}
        />
        <i className="ri-delete-bin-line" onClick={handleDelete} style={{ fontSize: 24, cursor: "pointer" }}></i>
      </div>
    );
  };

  const listWithCheckBox = (row) => {
    const {
      id,
      icon,
      count_of_creators,
      isDeleteAllowed,
      checked,
      full_name,
      platform_username,
      thumbnail_url,
      work_platform_id,
      name,
      profile_url,
      isCheckboxEnabled = true,
      is_verified,
    } = row;
    let title, subtitle;
    let tooltipTitle = {};
    if (row.work_platform_id) {
      title = full_name;
      subtitle = (
        <a href={profile_url} target="_blank">
          <Box display={"flex"} gap={"4px"} alignItems={"center"} justifyContent={"flex-start"}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"20px"} width={"20px"}>
              {CREATOR_SEARCH_TABLE_ICONS[WORKPLATFORM_ID_MAPPING[work_platform_id]?.platformName].icon}
            </Box>
            <Box color="var(--neutrals-secondary-grey)">@{platform_username}</Box>
            <Box>
              <i className="ri-arrow-right-up-line" style={{ fontSize: "16px", color: "var(--neutrals-secondary-grey)" }}></i>
            </Box>
          </Box>
        </a>
      );
      tooltipTitle.delete = "Remove creator";
      tooltipTitle.export = "Export creator as CSV";
    } else {
      title = name;
      subtitle = count_of_creators + ` ` + (count_of_creators === 1 ? "creator" : creators);
      tooltipTitle.delete = "Delete list";
      tooltipTitle.export = "Export list as CSV";
    }

    return (
      <div onClick={() => onListClick(row)} className={`list-with-checkbox-container list-item ${checked ? "selected-div" : ""}`}>
        <div className="user-row">
          {/* checkbox */}
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CheckBox
              defaultChecked={checked}
              onClick={(value) => {
                onCheckBoxClick(id, value);
              }}
              disabled={!isCheckboxEnabled}
            />
          </div>
          {/* Thumbnail */}
          <div className="checkbox-thumbnail">
            {icon ? <div className={` checkbox-icon ${checked ? "checked-icon" : ""}`}>{icon}</div> : null}
            {thumbnail_url ? (
              <div className="thumbnail">
                {thumbnail_url ? <img src={thumbnail_url} className="thumbnail_image" alt="Thumbnail" /> : <Icons.creator_placeholder />}
                {work_platform_id ? (
                  <Box className="user_platform">
                    <img width={24} height={24} src={WORKPLATFORM_ID_MAPPING[work_platform_id]?.platformLogo} alt={""} />
                  </Box>
                ) : null}
              </div>
            ) : null}
          </div>
          {/* User Info */}
          <div className="user-info">
            <span className="user-title-container">
              <span className="body-m">{title}</span>
              {is_verified ? (
                <span className="verified-logo">
                  <Icons.verified_logo />
                </span>
              ) : null}
            </span>
            <div className="subtitle-r" style={{ color: "var(--neutrals-secondary-grey)" }}>
              {subtitle}
            </div>
          </div>
        </div>
        <div className="list-operations">
          {isDeleteAllowed && (
            <Tooltip
              title={tooltipTitle.delete}
              placement="top-start"
              classes={platform_username ? { tooltip: "MuiTooltip-tooltip custom-tooltip" } : {}}
            >
              <div>
                <i
                  className="ri-delete-bin-line"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteRow(id);
                  }}
                  style={{ fontSize: 24, cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          )}
          <Tooltip title={tooltipTitle.export} placement="top-start" classes={{ tooltip: "MuiTooltip-tooltip custom-tooltip" }}>
            <div>
              <i
                className="ri-share-forward-line"
                onClick={(e) => {
                  e.stopPropagation();
                  onCreatorExport(id);
                }}
                style={{ fontSize: 24, cursor: "pointer" }}
              ></i>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  };

  // Row renderer function
  function renderRow(row) {
    switch (variant) {
      case "listWithCheckBox":
        return listWithCheckBox(row);
      default:
        return defaultSelectedCreatorList(row);
    }
  }

  if (!rows || rows.length === 0) {
    return null;
  }

  return (
    <div className="selected-creator-list">
      {rows.map((row, index) => (
        <div key={index}>{renderRow(row)}</div>
      ))}
    </div>
  );
};

export default SelectedCreatorList;
