import React, { useState } from 'react';
import './ShortLinkCell.scss';
import { SHORT_LINK_COPIED } from "../../constants/constants";
import { isEmpty } from "../../utils/util";
import { Icons } from "../index";
import { useToast } from '../../hooks/useToast';

export default function ShortLinkCell({ value }) {
    const toast = useToast();
    const [isHovered, setIsHovered] = useState(false);

    function handleCopyAction(copyText) {
        navigator.clipboard.writeText(copyText);
        toast(SHORT_LINK_COPIED);
    }

    return (
        <div className='body-r color-neutrals-secondary-grey'>
            {isEmpty(value) ?
                '-' :
                <div className={'short-link-url-container'}
                     onMouseEnter={() => setIsHovered(true)}
                     onMouseLeave={() => setIsHovered(false)}>
                    <a className={'body-r color-neutrals-secondary-grey short-link-url-text'}
                       href={value}
                       target={'_blank'}
                       rel={'noopener noreferrer'}
                       onClick={(e) => e.stopPropagation()}
                    >{value?.replace(/^https?:\/\//, '')}</a>
                    {isHovered ? <Icons.copy_icon_transparent
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCopyAction(value);
                        }}
                        cursor='pointer'
                    /> : <span>&nbsp;&nbsp;&nbsp;</span>}
                </div>
            }
        </div>
    );
}
