import { Box } from "@mui/system";
import React, { useContext, useEffect } from "react";
import { deleteQueryParamsAndFilterPayload } from "../../pages/CreatorDiscoveryPage/CreatorDiscoveryPageUtils";
import { CreatorDiscoveryWarehouseContext } from "../../storage/context";
import Icons from "../Icons/Icons";
import "./FilterTags.scss";
import { SET_LAST_INTERACTED_FILTER_NULL, UPDATE_LAST_INTERACTED_FILTER } from "../../storage/reducers/types";
const FilterTags = ({ filterTags, openFilterOnTagClick, setShowTable, setIsModalVisible, handleClickShowResults, setSearchTableSortState }) => {
  const { creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse } = useContext(CreatorDiscoveryWarehouseContext);
  const handleFilterDelete = (filter) => {
    deleteQueryParamsAndFilterPayload(filter, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse);

    // This is only to trigger the event after clearing the tileCount & sourceOfFilter being passed in the segment
    dispatchCreatorDiscoveryWarehouse({
      type: UPDATE_LAST_INTERACTED_FILTER,
      payload: {
        filterKey: "random string",
        state: true,
      },
    });
    setTimeout(() => {
      dispatchCreatorDiscoveryWarehouse({ type: SET_LAST_INTERACTED_FILTER_NULL });
    }, 500);
  };

  return (
    <Box className="filter-tags-container">
      {Object.keys(filterTags).map((key) => (
        <Box
          key={filterTags[key].filter}
          onClick={(e) => {
            e.stopPropagation();
            openFilterOnTagClick(key);
          }}
        >
          <div>{filterTags[key].tagValue}</div>
          <i
            className="ri-close-line"
            onClick={(e) => {
              e.stopPropagation();
              handleFilterDelete(filterTags[key].filter);
              if (Object.keys(filterTags).length === 1) {
                setIsModalVisible(false);
                handleClickShowResults({
                  field: "AVERAGE_LIKES",
                  order: "DESCENDING",
                });
              }
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default FilterTags;
