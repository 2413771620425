// import dependencies
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "./TableListingRecords.scss";
import IntermediateLoader from "../IntermediateLoader/IntermediateLoader";
import { SCREEN_HEIGHT_FOR_LARGE_SCREEN, YOFFSET_FOR_LARGE_SCREEN, YOFFSET_FOR_SMALL_SCREEN } from "../../constants/constants";

const TableListingRecords = ({
  onChange = () => {},
  columns,
  dataSource,
  size,
  border,
  paginationPosition,
  rowSelection,
  rowClickHandler,
  headerClickHandler,
  locale,
  scroll = false,
  loading = false,
  blurRecords = false,
}) => {
  // define the initial height of the screen
  const screenHeight = window.screen.height;
  // initialize the state to store the offset of the table
  const [yOffset, setYOffset] = useState(screenHeight > SCREEN_HEIGHT_FOR_LARGE_SCREEN ? YOFFSET_FOR_LARGE_SCREEN : YOFFSET_FOR_SMALL_SCREEN);

  useEffect(() => {
    function handleResize() {
      // get the height of the window
      const windowHeight = window.innerHeight;
      // calculate the new offset
      const newOffset = windowHeight - 400; // adjust the value to fit your needs
      // update the state
      setYOffset(newOffset);
    }

    // add an event listener to listen for the resize event
    window.addEventListener("resize", handleResize);

    return () => {
      // remove the event listener
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Table
      rowClassName={(record, index) => (blurRecords && index > 9 ? "table-record-blur" : loading ? "my-table-loading-row" : "")}
      onChange={onChange}
      className="invitation-table"
      size={size}
      columns={columns}
      dataSource={dataSource}
      // pagination={{ position: [paginationPosition] }}
      pagination={false}
      bordered={border}
      rowSelection={rowSelection}
      onRow={rowClickHandler}
      locale={locale}
      scroll={scroll && { x: 1100, y: yOffset }}
      loading={loading ? { indicator: <IntermediateLoader /> } : false}
    />
  );
};

export default TableListingRecords;
