const rotateSecretWrongPasswordErrorMessage = {
  invalid_credentials: "The password is incorrect, please check and try again.",
  missing_parameters: "Please add password and try again",
};
const registerErrorMessage = {
  email_id_exists: "This email is already registered with us, wanna sign in instead?",
  missing_parameters: "Please add first name and try again",
};
const loginErrorMessage = {
  invalid_credentials: "The email or password is incorrect, please check and try again.",
};
const webhooksErrorMessage = {
  duplicate_webhook_url: "This URL is already in use for another webhook, please edit that instead of creating a new one.",
  missing_parameters: "The webhook should listen for at least one event.",
  max_webhooks_configured: "You have reached the maximum limit of creating webhooks.",
};

const tableErrorMessages = {
  no_content: "Oops! No results found",
  no_creators_found: "It seems like there are not many creators that fit your requirement. Try changing some filters to find the closest matches.",
  no_profile_found: "We could not find the profile you are looking for. Please check the <spellings|url>.",
  try_again_msg: "Something is wrong on our end. Please try in some time",
};

export { rotateSecretWrongPasswordErrorMessage, loginErrorMessage, webhooksErrorMessage, registerErrorMessage, tableErrorMessages };
