import React from "react";
import "./AlertInfo.scss";
import PropTypes from "prop-types";

const AlertInfo = ({ width, icon, content, color, iconPosition }) => {
  return (
    <div className="alert-info-container" style={{ background: color, width: width }}>
      {iconPosition === "left" && icon}
      <div>{content}</div>
      {iconPosition === "right" && icon}
    </div>
  );
};



AlertInfo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number]),
  icon: PropTypes.node,
  content: PropTypes.node.isRequired,
  color: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
};

AlertInfo.defaultProps = {
  width: "10%",
  color: "#f0f0f0",
  iconPosition: "left",
};

export default AlertInfo;
