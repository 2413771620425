import { Pagination } from "@mui/material";
import "./PaginateRecords.scss";
import PropTypes from "prop-types";

export default function PaginateRecords({
  color,
  totalPageCount,
  size,
  page,
  onPageClick,
  showFirstButton = true,
  showLastButton = true,
  disabled = false,
  ...otherProps
}) {
  return (
    <div className="pagination-container">
      <Pagination
        disabled={disabled}
        count={totalPageCount}
        size={size}
        page={page}
        onChange={onPageClick}
        variant="outlined"
        sx={{
          "& .MuiPaginationItem-root": {
            gap: "8px",
            color: { color },
          },
          "& .MuiPaginationItem-root.Mui-selected": {
            color: "#fff",
            backgroundColor: "#51515F",
          },
          "& .MuiPaginationItem-root.Mui-selected:hover": {
            backgroundColor: "#51515F",
          },
          "& .MuiPagination-ul": {
            gap: "8px",
          },
        }}
        showFirstButton={showFirstButton}
        showLastButton={showLastButton}
      />
    </div>
  );
}


PaginateRecords.propTypes = {
  color: PropTypes.string,
  totalPageCount: PropTypes.number.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  page: PropTypes.number.isRequired,
  onPageClick: PropTypes.func.isRequired,
  showFirstButton: PropTypes.bool,
  showLastButton: PropTypes.bool,
  disabled: PropTypes.bool,
};

PaginateRecords.defaultProps = {
  color: "inherit",
  size: "medium",
  showFirstButton: true,
  showLastButton: true,
  disabled: false,
};