import React, { useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import './AddPostDialog.scss';
import {
    ADD_NEW_POST_TO_CAMPAIGN,
    campaignPageData,
    getFormatedCampaignURL,
    trackingPage,
    URL_ERROR_MESSAGES
} from "../../constants/campaignTrackingConstants";
import { validatePostUrl } from "../../utils/profileHelpers";
import TextBox from "../InsightIQ/TextBox/TextBox";
import { PrimaryButton } from "../index";
import { Colors } from "../../styles/colors";

export default function AddPostDialog({ open, handleClose, handleConfirm, campaignId, campaignName }) {
    const [inputValue, setInputValue] = useState('');
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [error, setError] = useState('');

    function handleInputChange(event) {
        setInputValue(event.target.value);
        setError(''); // Clear any existing error message when the input changes
        setButtonDisabled(event.target.value === ''); // Disable the button if the input is empty
    }

    function onAddClick(v) {
        // Validate the URL
        if (validatePostUrl(inputValue)) {
            handleConfirm(campaignId, getFormatedCampaignURL(inputValue), campaignName);
            onClose();
        } else {
            setError(URL_ERROR_MESSAGES.URL_Validation);
        }
    }

    function onClose() {
        setError('');
        handleClose();
    }

    function ModalTitle() {
        return <DialogTitle className={"add-modal-title"}>
            <div className="section-heading add-modal-title-text">{ADD_NEW_POST_TO_CAMPAIGN}</div>
            <i className="ri-close-line" onClick={onClose}/>
        </DialogTitle>;
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'xl'}>
            <ModalTitle/>
            <DialogContent>
                <DialogContentText>
                    <div className="add-modal-content">
                        <TextBox
                            variant={'default'}
                            placeholder={trackingPage.inputPlaceholder}
                            value={inputValue}
                            onChange={handleInputChange}
                            error={Boolean(error)}
                            helperText={error}
                            containerClass={'add-modal-input-container'}
                        />
                        <PrimaryButton
                            disabled={isButtonDisabled}
                            className={'add-modal-button'}
                            onClick={onAddClick}
                            label={<span
                                className={'body-m'}
                                style={{ color: Colors.white }}>
                                {campaignPageData.addPostButtonCTA}
                            </span>}/>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

