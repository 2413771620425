import React from 'react'
import { oneBillion } from '../../constants/campaignTrackingConstants';
import { isEmpty } from "../../utils/util";
import TextBox from '../InsightIQ/TextBox/TextBox';
import Loader from '../Loader/Loader';
import UserRow from '../UserRow/UserRow';
import "./CreatorCostList.scss"
const CreatorCostList = ({ rows, handleCostChange, loading }) => {

  if (loading) {
    return <Loader className='creator-cost-list-loader' />
  }

  function renderRow(row) {
    const { id, cost, platform_username, thumbnail_url, is_verified, work_platform_id } = row;
    let { fullname } = row;
    if (isEmpty(fullname)) {
      fullname = platform_username;
    }

    return (
      <div className="list-item">
        <UserRow name={fullname} username={platform_username} is_verified={is_verified}
          work_platform_id={work_platform_id}
          thumbnail_url={thumbnail_url} />

        <TextBox
          value={cost}
          containerClass={"creator_cost_input"}
          onKeyPress={(e) => {
            const key = e.key;

            // Allow numeric digits, period (for decimal point), backspace, delete, and arrow keys
            if (!/^\d$|^\.$|^Backspace$|^Delete$|^ArrowLeft$|^ArrowRight$/.test(key)) {
              e.preventDefault();
            }

            // Check if the input already contains a decimal point and prevent another one
            if (key === '.' && e.target.value.includes('.')) {
              e.preventDefault();
            }
          }}
          type="number"
          onChange={(e) => {
            const { target: { value } } = e;
            if (value === '') {
              handleCostChange(id, '')
              return;
            }
            let cost = parseFloat(value)
            if (cost < 0 || cost === -0) cost = 0;
            if (cost > oneBillion) return
            handleCostChange(id, value)
          }}
          onClear={() => handleCostChange(id, 0)}
        />
      </div>
    );
  }

  if (!rows || rows.length === 0) {
    return null;
  }
  return (
    <div className="creator-cost-list">
      {rows.map((row, index) => (
        <div key={index}>{renderRow(row)}</div>
      ))}
    </div>
  )
}

export default CreatorCostList