import {
  ADD_WORK_PLATFORM,
  UPDATE_FILTER_PAYLOAD,
  ADD_FILTER_PAYLOAD,
  UPDATE_LAST_INTERACTED_FILTER,
  SET_LAST_INTERACTED_FILTER_NULL,
  ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS,
  UPDATE_FILTER_TAG,
  SET_SHOULD_RENDER_TRUE,
  SET_SHOULD_RENDER_FALSE,
  SET_SHOULD_TAGS_RENDER_TRUE,
  SET_SHOULD_TAGS_RENDER_FALSE,
  UPDATE_CREATOR_LISTS_SELECTED_CREATORS,
  BASIC_DETAILS_OF_SELECTED_PROFILE,
} from "./types";

const DEFAULT_CREATOR_DISCOVERY_WAREHOUSE_STATE = {
  selectedWorkplatform: {
    id: "",
    name: "",
  },
  primaryFilters: [],
  moreFilters: { creatorsFilters: [], audienceFilters: [], performenceFilters: [] },
  filtersPayload: {},
  filters: {},
  lastInteractedFilter: null,
  shouldRender: false,
  shouldTagsRender: false,
  selectedListCreatorData: {
    selectedListData: {},
    selectedListCreators: [],
  },
  selectedCreatorDetails: {},
};

const creatorDiscoveryWarehouseReducer = (state, action) => {
  switch (action.type) {
    case ADD_WORK_PLATFORM:
      return {
        ...state,
        selectedWorkplatform: action.payload,
      };
    case ADD_FILTER_PAYLOAD:
      return {
        ...action.payload,
      };
    case UPDATE_FILTER_PAYLOAD:
      return {
        ...state,
        filters: {
          ...state.filters,
          [Object.keys(action.payload)[0]]: {
            ...state.filters[Object.keys(action.payload)[0]],
            payload: action.payload[Object.keys(action.payload)[0]].payload,
          },
        },
        shouldRender: action?.shouldRender,
      };
    case UPDATE_LAST_INTERACTED_FILTER:
      let filterState = action.payload.state;
      let lastInteractedFilter = null;
      let prevLastInteractedFilter = state.lastInteractedFilter;
      if (filterState === true) {
        lastInteractedFilter = action.payload.filterKey;
      } else if (filterState === false) {
        if (prevLastInteractedFilter === action.payload.filterKey) {
          lastInteractedFilter = null;
        } else {
          lastInteractedFilter = prevLastInteractedFilter;
        }
      }
      return {
        ...state,
        lastInteractedFilter: lastInteractedFilter,
      };
    case SET_LAST_INTERACTED_FILTER_NULL:
      return {
        ...state,
        lastInteractedFilter: null,
      };
    case ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS:
      return {
        ...state,
        shouldTagsRender: true,
        shouldRender: true,
        filters: {
          ...state.filters,
          [action.payload.key]: {
            ...state.filters[action.payload.key],
            meta_data: {
              ...state.filters[action.payload.key].meta_data,
              options: action.payload.options,
            },
          },
        },
      };
    case UPDATE_FILTER_TAG:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: {
            ...state.filters[action.payload.key],
            payload: {
              ...state.filters[action.payload.key].payload,
              tags: action.payload.tags,
            },
          },
        },
      };
    case SET_SHOULD_RENDER_TRUE:
      return {
        ...state,
        shouldRender: true,
      };
    case SET_SHOULD_RENDER_FALSE:
      return {
        ...state,
        shouldRender: false,
      };
    case SET_SHOULD_TAGS_RENDER_TRUE:
      return {
        ...state,
        shouldTagsRender: true,
      };
    case SET_SHOULD_TAGS_RENDER_FALSE:
      return {
        ...state,
        shouldTagsRender: false,
      };
    case UPDATE_CREATOR_LISTS_SELECTED_CREATORS:
      return {
        ...state,
        selectedListCreatorData: {
          selectedListCreators: action.payload.selectedListCreators,
          selectedListData: action.payload.selectedListData,
        },
      };
    case BASIC_DETAILS_OF_SELECTED_PROFILE:
      return {
        ...state,
        selectedCreatorDetails: action.payload,
      };
    default:
      return state;
  }
};

export { DEFAULT_CREATOR_DISCOVERY_WAREHOUSE_STATE, creatorDiscoveryWarehouseReducer };
