import './InfoActionBanner.scss';
import { Colors } from "../../styles/colors";

export default function InfoActionBanner({ variant, icon, title, actionIcon, actionText, actionClick }) {

    let backgroundColor = '', titleColor = '', titleClass = '', buttonColor = '';
    switch (variant) {
        case 'brand-profiles':
            backgroundColor = Colors.mainSecondary;
            titleColor = Colors.neutralsPrimaryGrey;
            titleClass = 'body-r';
            buttonColor = Colors.mainPrimary
            break;
        case 'brand-profiles-grey':
            backgroundColor = Colors.neutralsBackgroundGrey;
            titleColor = Colors.neutralsPrimaryGrey;
            titleClass = 'body-m';
            buttonColor = Colors.mainPrimary
            break;
        case 'brand-profiles-alert':
            backgroundColor = Colors.semanticsSecondaryAlert;
            titleColor = Colors.semanticsPrimaryAlert;
            titleClass = 'body-m';
            buttonColor = Colors.semanticsPrimaryAlert
            break;
        case 'creator-addition':
        default:
            backgroundColor = Colors.white;
            titleColor = Colors.semanticsPrimarySuccess;
            titleClass = 'body-m';
            buttonColor = Colors.mainPrimary
            break;
    }
    return (
        <div className={`action-container-edit`} style={{ backgroundColor }}>
            <div className="first-child">
                {icon}
                <div className={titleClass} style={{ color: titleColor, display: 'flex' }}>
                    {title}
                </div>
            </div>
            <div className={'second-child'} style={{color:buttonColor}} onClick={actionClick}>
                {actionIcon}
                <div className="body-m" style={{color:buttonColor}}>{actionText}</div>
            </div>
        </div>
    );
}