import React, { useContext, useEffect, useState } from "react";
import { MenuItem, Popover } from "@material-ui/core";
import { Box, Button } from "@mui/material";
import { workPlatformsDropdownOptions } from "../../utils/util";
import { PlatformSelectionDropdown } from "../index";
import './SearchAndAddCreators.scss';
import { creatorDiscoveryAPI } from "../../api";
import { WarehouseContext } from "../../storage/context";
import { makeStyles } from "@material-ui/core/styles";
import {
    GET_RESULTS,
    NO_CREATORS_FOUND,
    SEARCH_SELECT_CREATOR_PLACEHOLDER,
    SELECT
} from "../../constants/campaignTrackingConstants";
import UserRow, { UserRowSkeleton } from "../UserRow/UserRow";
import { trackEvent, TRACKING_CAMPAIGN_ACCOUNTS_SEARCHED } from "../../analytics";
import { Colors } from "../../styles/colors";
import TextBox from "../InsightIQ/TextBox/TextBox";

const useStyles = makeStyles((theme) => ({
    popoverPaper: {
        padding: 8,
        marginTop: 10,
        // maxWidth: 1100, // Set the width to fill the parent div
        // width: 1064,
        // maxHeight: 300, // Optionally set a maximum height for the Popover
        overflowY: 'auto', // Enable scrolling if the content overflows
        // Add any other custom styles as needed
    },
}));

export default function SearchAndAddCreators({ onSelectorClick }) {
    const { warehouse } = useContext(WarehouseContext);
    const [inputValue, setInputValue] = useState("");
    const [selectedPlatform, setSelectedPlatform] = useState(workPlatformsDropdownOptions[0]?.value);
    const [selectableCreators, setSelectableCreators] = useState([]);
    const [popoverWidth, setPopoverWidth] = useState('100%');
    const [loading, setLoading] = useState(false);
    const classes = useStyles();


    useEffect(() => {
        // Function to update the popover width when the window is resized
        const handleWindowResize = () => {
            const searchAndAddCreatorsContainer = document.querySelector('.Search-And-Add-Creators');
            if (searchAndAddCreatorsContainer) {
                const width = searchAndAddCreatorsContainer.clientWidth - 16;
                setPopoverWidth(width + 'px');
            }
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleWindowResize);

        // Call the handleWindowResize function initially to set the correct width
        handleWindowResize();

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWorkPlatformId(selectedPlatform) {
        return workPlatformsDropdownOptions
            .find(wpOption => wpOption.value.toLowerCase() === selectedPlatform.toLowerCase())?.id;
    }

    async function fetchUserHandles() {
        setLoading(true);
        trackEvent(TRACKING_CAMPAIGN_ACCOUNTS_SEARCHED, { platform_name: selectedPlatform, keyword: inputValue.trim() })
        const workplatformId = getWorkPlatformId(selectedPlatform);
        const res = await creatorDiscoveryAPI.getUsersHandles({
            searchQuery: inputValue,
            workplatformId,
            type: 'search',
            is_search: false,
            warehouse,
            limit: 3
        });
        setLoading(false);
        return res.data;
    }

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
    };

    const handleMenuItemClick = (creator) => {
        onSelectorClick({ creator, workPlatformId: getWorkPlatformId(selectedPlatform) });
    };

    const [anchorEl, setAnchorEl] = useState(null);

    // Function to handle the opening of the Popover
    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
        setSelectableCreators([]);
        fetchUserHandles().then(setSelectableCreators);
    };

    // Function to handle the closing of the Popover
    const handleClose = () => {
        setAnchorEl(null);
    };

    function onClearClick() {
        setInputValue('');
        setSelectableCreators([]);
    }

    function renderCreatorRow(creator) {
        const { fullname, is_verified, picture, user_id, username } = creator;
        return (
            <MenuItem
                key={user_id}
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                onClick={() => {
                    handleMenuItemClick(creator);
                    handleClose();
                }}
            >
                <UserRow name={fullname}
                         username={username}
                         is_verified={is_verified}
                         work_platform_id={getWorkPlatformId(selectedPlatform)}
                         thumbnail_url={picture}
                />
                <div
                    className={'body-m'}
                    style={{
                        textDecoration: "underline",
                        color: "var(--main-primary)",
                        cursor: "pointer"
                    }}
                >
                    {SELECT}
                </div>
            </MenuItem>
        );
    }

    function renderLoaderRow(key) {
        return (
            <MenuItem key={key}>
                <UserRowSkeleton/>
            </MenuItem>
        );
    }

    // function handleKeyDown(event) {
    //     if (event.key !== "Enter") {
    //         return;
    //     }
    //
    //     handleButtonClick(event);
    // };

    function getNoCreatorMessage() {
        return <div className={'body-m search-no-creators-found'}>
            {NO_CREATORS_FOUND}
        </div>;
    }

    return (
        <div className="search-and-add-creators-container">
            <div className={"Search-And-Add-Creators"}>
                <PlatformSelectionDropdown
                    platformList={workPlatformsDropdownOptions}
                    selectedPlatform={selectedPlatform}
                    setSelectedPlatform={setSelectedPlatform}
                    selectContainerClass={'search-and-add-select-container'}
                />
                <TextBox
                    variant={'default'}
                    placeholder={SEARCH_SELECT_CREATOR_PLACEHOLDER}
                    value={inputValue}
                    onChange={handleInputChange}
                    onClear={onClearClick}
                    containerClass={'hundred-percent-width'}
                />
                {inputValue.trim().length > 0 && (
                    <Button
                        className={'button-get-creator-results'}
                        onClick={handleButtonClick}
                    >
                        <span className={'body-m'} style={{ color: Colors.mainPrimary }}>{GET_RESULTS}</span>
                    </Button>
                )}
            </div>
            <Popover
                classes={{ paper: classes.popoverPaper }} // Apply custom class to the Popover component

                // className={'popover-select-creators'}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ width: popoverWidth }}>
                    {loading ?
                        [0, 1, 2].map(renderLoaderRow) :
                        (selectableCreators?.length === 0 ?
                            getNoCreatorMessage() :
                            selectableCreators?.map(renderCreatorRow))
                    }
                </Box>
            </Popover>
        </div>
    );
}