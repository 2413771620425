import { useContext } from "react";
import { parseTenantUsageQuotaInfoPerModule } from "../utils/tenantProductHelpers";
import { WarehouseContext } from "../storage/context";
import { tenantUsageQuotaInfoAPI } from "../api";

export const useFetchTenantUsageInfo = () => {
  const { warehouse } = useContext(WarehouseContext);

  return async () => {
    try {
      const tenantUsageQuotaInfoResponse = await tenantUsageQuotaInfoAPI.getTenantUsageQuotaInfo(warehouse.environment.current);
      // const tenantUsageQuotaInfoResponse = [
      //   {
      //     tenant_app_id: "9db1f440-2239-4196-b52a-7307fc7d63ac",
      //     max_limit: 25,
      //     usage: 25,
      //     product_category: "DASHBOARD_PRODUCT",
      //     product_attribute: "ACCOUNTS_CONNECTED",
      //   },
      //   {
      //     tenant_app_id: "9db1f440-2239-4196-b52a-7307fc7d63ac",
      //     max_limit: 3,
      //     usage: 3,
      //     product_category: "DASHBOARD_PRODUCT",
      //     product_attribute: "PROFILE_ANALYTICS",
      //   },
      // ];
      return parseTenantUsageQuotaInfoPerModule(tenantUsageQuotaInfoResponse);
    } catch (e) {
      console.log(e);
    }
  };
};
