import React, { useState } from "react";
import { Box } from "@mui/material";
import "./FeaturedSearchCard.scss";
import { formatNumber } from "../../utils/profileHelpers";
import PropTypes from "prop-types";

const FeaturedSearchCard = ({ filters, results, logo, onClickHandler, date, recentSearchCardColorPallete }) => {
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const { height, width } = recentSearchCardColorPallete.category;
  return (
    <Box
      className="featured-search-container"
      onMouseOver={(e) => {
        setShowSearchIcon(true);
      }}
      onMouseOut={(e) => {
        setShowSearchIcon(false);
      }}
      onClick={onClickHandler}
      sx={{ background: recentSearchCardColorPallete.backgroundColor }}
    >
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <img src={recentSearchCardColorPallete.backgroundImage} width={width} height={height} />
      </Box>
      <Box className="text-logo-box">
        <Box className="filter-title">{filters}</Box>
        <Box className="filter-result">
          Discover {formatNumber(results, 1)} creators <i className="ri-arrow-right-line"></i>
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturedSearchCard;

FeaturedSearchCard.propTypes = {
  filters: PropTypes.string.isRequired,
  results: PropTypes.number.isRequired,
};

FeaturedSearchCard.defaultProps = {
  filters: "filter text",
  results: 110,
};
