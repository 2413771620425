import { Box } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {
    trackEvent,
    TRACKING_CAMPAIGNLIST_CLICKED,
    TRACKING_PAGE_OPENED,
    TRACKING_URL_ANALYSIS_CLICKED,
    TRACKING_URL_ANALYSIS_ERROR,
    TRACKING_URL_CHECK_EXAMPLE
} from "../../analytics";
import {
    campaignPageTabValues,
    checkExample,
    CONTENT_AUDIENCE_DEMOGRAPHICS,
    sampleAnalysePost,
    TOP_HEADER_INFO_CAMPAIGN_TRIAL_MODE,
    trackingPage,
    URL_ERROR_MESSAGES
} from '../../constants/campaignTrackingConstants';
import { campaignTrackingProductID, getPageTitle, TRIAL, WORKPLATFORM_ID_MAPPING } from '../../constants/constants';
import { CampaignTrackingContext, WarehouseContext } from '../../storage/context';
import {
    ADD_USAGE_QUOTA,
    HIDE_TOP_HEADER,
    SET_CAMPAIGN_ACTIVE_TAB,
    SET_CAMPAIGN_URL,
    SET_POST_IDENTIFIERS,
    SET_TRACKED_CREATORS,
    UPDATE_FEATURE
} from '../../storage/reducers/types';
import { getWorkPlatformIDFromURL, validatePostUrl } from '../../utils/profileHelpers';
import './TrackingPage.scss';
import { CampaignDataGrid, HeaderTopNav, Icons, InputField, PrimaryButton } from "../../components";
import { Colors } from "../../styles/colors";
import { validateFeatures } from "../../api/CampaignTrackingAPI/CampaignTrackingAPI";
import { useFetchTenantUsageInfo } from "../../hooks/useFetchTenantUsageInfo";
import { isEmpty } from "../../utils/util";

export default function TrackingPage() {
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const { campaignTrackingWarehouse, dispatchCampaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const { campaignURL } = campaignTrackingWarehouse;

    const [inputErrorState, setInputErrorState] = useState({
        hasError: false,
        errorMessage: "",
    });
    const onlyOnce = useRef(true);
    const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
    const isTrialMode = warehouse.tenantAppProducts[campaignTrackingProductID] === TRIAL;
    const fetchTenantUsageInformation = useFetchTenantUsageInfo();
    const [isCreateEnabled, setCreateEnabled] = useState(false);

    function setCampaignURL(payload) {
        dispatchCampaignTrackingWarehouse({
            type: SET_CAMPAIGN_URL,
            payload,
        })
    }

    useEffect(() => {
        fetchTenantUsageInformation().then((usageInfo) => {
            dispatchWarehouse({ type: ADD_USAGE_QUOTA, payload: usageInfo });
        });
        dispatchWarehouse({ type: HIDE_TOP_HEADER });
        dispatchCampaignTrackingWarehouse({
            type: SET_CAMPAIGN_ACTIVE_TAB,
            payload: campaignPageTabValues.trackPosts,
        });

        if (onlyOnce.current) {
            validateCampaignFeatures().then(() => {
            });
            trackEvent(TRACKING_PAGE_OPENED);
            onlyOnce.current = false;
        }

        return () => {
            dispatchCampaignTrackingWarehouse({
                type: SET_TRACKED_CREATORS,
                payload: [],
            });
            dispatchCampaignTrackingWarehouse({
                type: SET_POST_IDENTIFIERS,
                payload: null,
            });
        }
    }, []);

    useEffect(() => {
        setCreateEnabled(!isEmpty(warehouse.tenantUsageQuota?.quotaInfoCreatorModule?.CAMPAIGNS_CREATED));
    }, [warehouse.tenantUsageQuota]);

    const validateCampaignFeatures = async () => {
        try {
            let feature = CONTENT_AUDIENCE_DEMOGRAPHICS
            const response = await validateFeatures({ warehouse, feature })
            if (response[CONTENT_AUDIENCE_DEMOGRAPHICS]) {
                const payload = { ...campaignTrackingWarehouse.features, [CONTENT_AUDIENCE_DEMOGRAPHICS]: true }
                dispatchCampaignTrackingWarehouse({
                    type: UPDATE_FEATURE,
                    payload
                })
            }
        } catch (error) {

        }
    }

    const handleAnalyze = (campaignURL) => {
        setInputErrorState({ hasError: false, errorMessage: "" });
        if (validatePostUrl(campaignURL)) {
            navigate("/creators/campaigns/content-view");
            const platformID = getWorkPlatformIDFromURL(campaignURL)
            trackEvent(TRACKING_URL_ANALYSIS_CLICKED, {
                url_name: campaignURL,
                platform_name: WORKPLATFORM_ID_MAPPING[platformID].platformName
            });
        } else {
            setInputErrorState({ hasError: true, errorMessage: URL_ERROR_MESSAGES.URL_Validation });
            trackEvent(TRACKING_URL_ANALYSIS_ERROR, {
                url_name: campaignURL,
                error_message: URL_ERROR_MESSAGES.URL_Validation
            })
        }
    };
    const handleEnterKeyPress = (event) => {
        if (event.key === "Enter") {
            const analyzeButton = document.querySelector(".analyse-button");
            analyzeButton.click();
        }
    };

    function onCampaignClick(campaignId, name, total_content_count, created_at, brand_name) {
        if (!campaignId) {
            console.error("Campaign ID not found when clicking on campaign row");
            return;
        }
        trackEvent(TRACKING_CAMPAIGNLIST_CLICKED, {
            name_of_campaign: name,
            brand_name: brand_name,
            posts: total_content_count,
            created_on: `${created_at}`,
        });
        navigate(`/creators/campaigns/campaign-view/${campaignId}`);
    }

    function addSampleExample() {
        setCampaignURL(sampleAnalysePost);
        handleAnalyze(sampleAnalysePost);
        trackEvent(TRACKING_URL_CHECK_EXAMPLE);
    }

    return (

        <Box>
            <Helmet>
                <title>{getPageTitle("TRACKING_CAMPAIGN_PAGE", "")}</title>
            </Helmet>
            <Box className="tracking-page-container">
                <Box>
                    <Icons.campaign_tracking_icon/>
                </Box>
                <Box className="campaign-search-container">
                    <Box className="title">{trackingPage.titleDesc}</Box>
                    <Box className="input-button-container">
                        <InputField
                            placeholder={trackingPage.inputPlaceholder}
                            // maxLength={200}
                            onChange={(e) => {
                                setCampaignURL(e.target.value.trim());
                                setInputErrorState({ hasError: false, errorMessage: "" });
                            }}
                            onKeyPress={handleEnterKeyPress}
                            autoFocus
                            ref={inputRef}
                            hasError={inputErrorState.hasError}
                            errorMessage={
                                <>
                                    {inputErrorState.hasError ?
                                        <Icons.caution_red/> : null} {inputErrorState.errorMessage}
                                </>
                            }
                        />
                        <PrimaryButton onClick={() => handleAnalyze(campaignURL)} label={trackingPage.buttonCTA}
                                       className="analyse-button body-m"/>
                    </Box>
                    <Box onClick={addSampleExample} className="body-m checkExampleText">{checkExample}</Box>
                </Box>
            </Box>
            <div className="tracking-page-header-nav" >
            {isTrialMode && (
                <HeaderTopNav
                    title={TOP_HEADER_INFO_CAMPAIGN_TRIAL_MODE}
                    backgroundColor={Colors.semanticsSecondaryAlert}
                />
            )}
            </div>
            <CampaignDataGrid onRowClick={onCampaignClick} isCreateEnabled={isCreateEnabled}/>
        </Box>
    );
}
