import React from 'react';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import "./ProductPageHeader.scss";
import { PRODUCT_DOCS_CLICKED, trackEvent } from "../../analytics";
import { Skeleton } from "@mui/material";
import { Icons } from "../index";

export default function ProductPageHeader({
                                              isLoading = false,
                                              title,
                                              mainSubtitle,
                                              additionalSubtitle,
                                              docsLink,
                                              buttonLabel
                                          }) {
    if (isLoading) {
        return (
            <header className={'div-header-container'}>
                <Skeleton variant="text" className={"loading-skeleton"} animation={"pulse"}/>
            </header>
        );
    }

    const buttonLabelComponent =
        <div className={'div-docs-button-text'}>
            <Icons.docs_icon/>
            {buttonLabel}
            <i className="ri-arrow-right-up-line redirect-arrow docs-arrow"></i>
        </div>;

    return (
        <header className={'div-header-container product-page-header'}>
            <div className={'product-page-header-left-container'}>
                <div className={'div-main-title'}>
                    <div className='title'>{title}</div>
                    {mainSubtitle ?
                        <div
                            className={`body-r color-neutrals-primary-grey ${!additionalSubtitle ? 'color-neutrals-secondary-grey' : null}`}>
                            {mainSubtitle}
                        </div> :
                        null}
                </div>
                {additionalSubtitle ?
                    <div className='body-r color-neutrals-secondary-grey'>
                        {additionalSubtitle}
                    </div> : null}
            </div>
            {docsLink ?
                <div className='products-page-docs-button'>
                    <PrimaryButton
                        label={buttonLabelComponent}
                        onClick={() => {
                            window.open(docsLink, '_blank');
                            trackEvent(PRODUCT_DOCS_CLICKED, {
                                product_name: title,
                            });
                        }}
                        className="docs-button"
                    />
                </div> : null}
        </header>
    );
};
