import React, { useEffect, useReducer, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AuthenticatedRoutes, UnAuthenticatedRoutes } from "./routes/index";
import { UserContext, WarehouseContext, AnalyticsContext } from "./storage/context/index";
import { ADD_USER_PROFILE } from "./storage/reducers/types";
import axiosInstance, { attachAxiosInterceptors } from "./api/axiosInstance";
import { changePage, getUTMParams, logUser, trackEvent, updateAnalyics, VERIFY_EMAIL_FAILURE, VERIFY_EMAIL_SUCCESS } from "./analytics";
import "./App.scss";
import {
  analyticsReducer,
  campaignTrackingReducer,
  creatorDiscoveryWarehouseReducer,
  DEFAULT_CAMPAIGN_TRACKING_STATE,
  DEFAULT_CREATOR_DISCOVERY_WAREHOUSE_STATE,
  DEFAULT_WAREHOUSE_STATE,
  USER_PROFILE_DEFAULT_STATE,
  userProfileReducer,
  warehouseReducer,
} from "./storage/reducers/index";
import { initializeApp } from "firebase/app";

import { useToast } from "./hooks/useToast";
import { EMAIL_VERIFIED_LOGIN_SUCCESSFUL, EMAIL_VERIFIED_LOGIN_SUCCESSFUL_AUTO_LOGIN, TOKEN_EXPIRED } from "./constants/toastMessages";
import useProfile from "./hooks/useSetUserProfile";
import CreatorDiscoveryWarehouseContext from "./storage/context/creatorDiscoveryWarehouseContext";
import { CampaignTrackingContext } from "./storage/context";
import factorsai from "factorsai";

function App() {
  const [warehouse, dispatchWarehouse] = useReducer(warehouseReducer, DEFAULT_WAREHOUSE_STATE);
  const [creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse] = useReducer(
    creatorDiscoveryWarehouseReducer,
    DEFAULT_CREATOR_DISCOVERY_WAREHOUSE_STATE
  );
  const [campaignTrackingWarehouse, dispatchCampaignTrackingWarehouse] = useReducer(campaignTrackingReducer, DEFAULT_CAMPAIGN_TRACKING_STATE);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { logoutUserProfile } = useProfile();
  const toast = useToast();

  let userRequestedLocation = useRef(location.pathname + location.search + location.hash);

  const [userProfile, dispatchUserProfile] = useReducer(userProfileReducer, USER_PROFILE_DEFAULT_STATE);
  const [userAuthenticated, setUserAuthenticated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [analytics, dispatchAnalytics] = useReducer(analyticsReducer, JSON.parse(localStorage.getItem("insightiq_analytics")));

  // initializes page change tracker for the analytics
  useEffect(() => {
    changePage(location.pathname);
    // updateAnalyics(userProfile, dispatchAnalytics, warehouse.environment.current);
  }, [location]);

  // useEffect(() => {
  //   updateAnalyics(userProfile, dispatchAnalytics, warehouse.environment.current);
  // }, [warehouse.environment.current]);

  useEffect(() => {
    if (process.env.REACT_APP_FACTORS_TOKEN) {
      JSON.parse(process.env.REACT_APP_HUBSPOT_SCRIPT_ENABLED) && factorsai.init(process.env.REACT_APP_FACTORS_TOKEN);
    }
    const nonSavedRoutes = ['/sign-in', '/set-password'];
    if (!localStorage.getItem("userRequestedLocation") && !nonSavedRoutes.includes(userRequestedLocation.current)) {
      localStorage.setItem("userRequestedLocation", JSON.stringify(userRequestedLocation.current));
    }
    getUTMParams();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    updateAnalyics(user, dispatchAnalytics, warehouse.environment.current);
    if (params.get("message") && params.get("success") === "true" && params.get("code") === "success") {
      if (user) {
        // If the session is opening in the same browser --> Auto login to dashboard will happen
        toast(EMAIL_VERIFIED_LOGIN_SUCCESSFUL_AUTO_LOGIN);
        user.emailVerified = true;
        localStorage.setItem("user", JSON.stringify(user));
        trackEvent(VERIFY_EMAIL_SUCCESS, { email: user.email });
      } else {
        // If the session is opening on a different browser/device --> Redirected to Sign-in page
        toast(EMAIL_VERIFIED_LOGIN_SUCCESSFUL);
        trackEvent(VERIFY_EMAIL_SUCCESS);
      }
    } else if (params.get("message") && params.get("success") === "false")
      trackEvent(VERIFY_EMAIL_FAILURE, { failure_reason: params.get("message") });

    // check if token got expired or not
    // if it has expired, simply remove from local storage
    const time = new Date();
    if (user && new Date(user.tokenExpiry) < new Date(time.toISOString())) {
      // Passing the params here because context is set in this profile and not passed in profile context
      localStorage.removeItem("user");
      logoutUserProfile(dispatchWarehouse, dispatchUserProfile);
      window.location.reload();
    }

    if (user) {
      dispatchUserProfile({ type: ADD_USER_PROFILE, payload: user });
      logUser(user.email, {
        email: user.email.toLowerCase(),
        registered_from: window.location.host,
      });
      // adding response interceptor to axios instance
      attachAxiosInterceptors(toast, TOKEN_EXPIRED);
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const hasLoggedIn = !!userProfile.user.emailVerified && !!userProfile.user.token;

    setUserAuthenticated(hasLoggedIn);
  }, [userProfile.user]);

  // Initialize Firebase
  useEffect(() => {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_CT_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_CT_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_CT_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_CT_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_CT_FIREBASE_PROJECT_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_CT_FIREBASE_PROJECT_APP_ID,
      measurementId: process.env.REACT_APP_CT_FIREBASE_PROJECT_MEASUREMENT_ID,
    };
    try {
      initializeApp(firebaseConfig);
    } catch (e) {
      console.error("Firebase app not initialized. Campaign creation will not work", e);
    }
  }, []);

  if (loading) {
    return null;
  }
  return (
    <UserContext.Provider value={{ userProfile, dispatchUserProfile }}>
      <WarehouseContext.Provider value={{ warehouse, dispatchWarehouse }}>
        <AnalyticsContext.Provider value={{ analytics, dispatchAnalytics }}>
          <CreatorDiscoveryWarehouseContext.Provider value={{ creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse }}>
            <CampaignTrackingContext.Provider value={{ campaignTrackingWarehouse, dispatchCampaignTrackingWarehouse }}>
              <Routes>
                {userAuthenticated ? (
                  <Route path="*" element={<AuthenticatedRoutes userRequestedLocation={userRequestedLocation.current} />} />
                ) : (
                  <Route path="*" element={<UnAuthenticatedRoutes />} />
                )}
              </Routes>
            </CampaignTrackingContext.Provider>
          </CreatorDiscoveryWarehouseContext.Provider>
        </AnalyticsContext.Provider>
      </WarehouseContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
