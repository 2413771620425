import './CreatorUtmLinksGrid.scss';
import { WarehouseContext } from "../../storage/context";
import { downloadUtmLinks, getAllCampaignProfiles } from "../../api/CampaignTrackingAPI/CampaignTrackingAPI";
import { downloadFile, isEmpty, setAddedProfiles } from "../../utils/util";
import UserRow from "../UserRow/UserRow";
import { Colors } from "../../styles/colors";
import { CustomFooter, getSortedHeaderClass } from "../../utils/DataGridUtils";
import {
    CAMPAIGN_PROFILES_CREATED_VIA,
    COPY_TO_CLIPBOARD,
    CreatorUtmGridHeaders,
    DOWNLOAD_UTM_LINKS,
    EDIT_UTM,
    LINK_COPIED,
    NO_CREATORS_FOUND,
    SELECT_CREATOR_PLACEHOLDER
} from "../../constants/campaignTrackingConstants";
import TextBox from "../InsightIQ/TextBox/TextBox";
import { useContext, useEffect, useState } from "react";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ExportLoadingModal, PrimaryButton } from "../index";
import { useNavigate } from 'react-router-dom';
import { exportingCsv, exportingPdf } from "../../constants/constants";

export default function CreatorUtmLinksGrid({ campaignId }) {
    const allowedSorts = ["desc", "asc"];
    const defaultSortModel = [{ field: "platform_username", sort: "desc" }];
    const PAGE_SIZE = 10;
    const ROW_HEIGHT = 132;
    const navigate = useNavigate()
    const { warehouse } = useContext(WarehouseContext);
    const [rows, setRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(0); // Adjusted to 0-based indexing
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [sortModel, setSortModel] = useState(defaultSortModel);
    const [searchText, setSearchText] = useState(null);
    const [tooltipText, setTooltipText] = useState(COPY_TO_CLIPBOARD);
    const [isExportLoadingModalVisible, setExportLoadingModalVisible] = useState(false);

    useEffect(() => {
        fetchData().then(() => {
        });
    }, [pageNumber, sortModel, searchText]);

    useEffect(() => {
        if (isExportLoadingModalVisible) {
            downloadUtmLinks({ warehouse, campaignId })
                .then((response) => {
                    if (!response) {
                        throw new Error('Could not download UTM CSV');
                    }
                    downloadFile(response, 'utm-links.csv');
                })
                .catch(console.error)
                .finally(() => setExportLoadingModalVisible(false));
        }
    }, [isExportLoadingModalVisible]);

    async function fetchData() {
        try {
            let sort_by = null,
                sort_desc = null;
            if (sortModel.length === 1) {
                sort_by = sortModel[0].field;
                sort_desc = sortModel[0].sort === "desc";
            }
            setLoading(true);

            getAllCampaignProfiles({
                warehouse,
                offset: pageNumber * PAGE_SIZE,
                limit: PAGE_SIZE,
                campaignId,
                search_string: searchText,
                sort_by,
                sort_desc,
                created_via: CAMPAIGN_PROFILES_CREATED_VIA.DIRECT
            }).then(res => {
                const { data, total_count } = res;
                data.forEach(setAddedProfiles);
                setRows(data);
                setTotalRows(total_count);
            });
        } catch (e) {
            // TODO show error screen
            console.error("Error fetching data:", e);
        } finally {
            setLoading(false);
        }
    }

    function handlePageChange(newPageNumber) {
        setPageNumber(newPageNumber); // Adjusted to 0-based indexing
    }

    function renderCreatorAccountHeader(params) {
        return <div >
            {CreatorUtmGridHeaders.CREATOR_ACCOUNT} ({totalRows})
            {/* <Tooltip placement={'top-end'} title={'tooltip'}>
                <i className="ri-information-line"></i>
            </Tooltip> */}
        </div>
    }

    function renderFullLinkHeader() {
        return <div style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {CreatorUtmGridHeaders.FULL_LINK} 
            <Tooltip placement={'top-end'} title={'UTM link for that particular creator account'}>
                <i className="ri-information-line"></i>
            </Tooltip>
        </div>
    }

    function renderCreatorAccountCell(params) {
        const { thumbnail_url, platform_username, is_verified, work_platform_id } = params.row;
        let { fullname } = params.row;
        if (isEmpty(fullname)) {
            fullname = platform_username;
        }
        return (
            <UserRow
                name={fullname}
                username={platform_username}
                is_verified={is_verified}
                work_platform_id={work_platform_id}
                thumbnail_url={thumbnail_url}
            />
        )
    }

    function renderLinkCell(link) {
        return (
            <Chip
                sx={{ userSelect: 'unset' }}
                deleteIcon={<Tooltip placement={'top'} title={tooltipText}>
                    <i
                        className="ri-file-copy-line"
                        style={{ color: Colors.neutralsSecondaryGrey }}
                        onMouseLeave={() => setTooltipText(COPY_TO_CLIPBOARD)}
                    ></i>
                </Tooltip>}
                onDelete={() => {
                    navigator.clipboard.writeText(link).then(() => setTooltipText(LINK_COPIED));
                }}
                label={
                    <Typography noWrap className={"subtitle-r"} style={{ color: Colors.neutralsPrimaryGrey }}>
                        {link}
                    </Typography>}
                classes={{
                    root: "link-chip-root",
                }}
                className={"link-chip"}
            />
        );
    }

    const commonHeaderProps = {
        flex: 1,
        headerClassName: "subtitle-m mui-data-grid-header hideRightSeparator",
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            hide: true,
        },
        {
            ...commonHeaderProps,
            field: "platform_username",
            headerAlign: "left",
            align: "left",
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, "platform_username")}`,
            renderHeader: renderCreatorAccountHeader,
            maxWidth: 350,
            minWidth: 180,
            renderCell: renderCreatorAccountCell,
            sortable: true,
        },
        {
            ...commonHeaderProps,
            field: "full_link",
            headerAlign: "right",
            align: "right",
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, "full_link")}`,
            headerName: renderFullLinkHeader(),
            minWidth: 330,
            renderCell: (params) => renderLinkCell(params.row.utm_link),
            sortable: false,
        },
        {
            ...commonHeaderProps,
            field: "short_link",
            headerAlign: "right",
            align: "right",
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, "short_link")}`,
            headerName: CreatorUtmGridHeaders.SHORT_LINK,
            maxWidth: 310,
            minWidth: 300,
            renderCell: (params) => renderLinkCell(params.row.short_utm_link),
            sortable: false,
        }
    ];

    return (
        <div className={"creator-utm-data-grid-container"}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <TextBox
                    variant={'short'}
                    placeholder={SELECT_CREATOR_PLACEHOLDER}
                    onEnter={setSearchText}
                    value={searchText}
                    onClear={() => setSearchText("")}
                />
                <div style={{ display: 'flex', gap: 32 }}>
                    <div className={'body-m'}
                         style={{
                             cursor: 'pointer',
                             display: 'flex',
                             gap: 8,
                             color: Colors.mainPrimary,
                             alignItems: 'center'
                         }}
                         onClick={() => {
                             navigate(`/creators/campaigns/edit-utm-links/${campaignId}`);
                         }}>
                        <i className="ri-edit-line"></i>
                        <Typography>{EDIT_UTM}</Typography>
                    </div>
                    <PrimaryButton
                        icon={<i className="body-m ri-download-2-line" style={{ color: Colors.white }}></i>}
                        label={<span className={'body-m'} style={{ color: Colors.white }}> {DOWNLOAD_UTM_LINKS}</span>}
                        onClick={() => setExportLoadingModalVisible(true)}
                        className={'download-utm-button'}/>
                    <ExportLoadingModal open={isExportLoadingModalVisible} title={exportingCsv}/>
                </div>
            </Box>
            <DataGrid
                className={"mui-data-grid"}
                columns={columns}
                components={{
                    Footer: (props) => <CustomFooter totalRows={totalRows} pageSize={PAGE_SIZE}
                                                     handlePageChange={handlePageChange} pageNumber={pageNumber}/>,
                }}
                disableColumnMenu
                disableSelectionOnClick
                getRowHeight={() => ROW_HEIGHT}
                getRowId={(row) => row.id}
                initialState={{
                    sorting: {
                        sortModel: defaultSortModel,
                    },
                }}
                loading={isLoading}
                onPageChange={handlePageChange}
                onSortModelChange={setSortModel}
                page={pageNumber}
                pageSize={PAGE_SIZE}
                pagination
                paginationMode={"server"}
                rowCount={totalRows}
                sortModel={sortModel}
                sortingMode={"server"}
                sortingOrder={allowedSorts}
                sx={{
                    '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    }
                }}
                rows={rows}
                localeText={{
                    noRowsLabel: (
                        <span className={"body-m"} style={{ color: Colors.neutralsSecondaryGrey }}>
                          {NO_CREATORS_FOUND}
                        </span>
                    ),
                }}
            />
        </div>
    );
}
