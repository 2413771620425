import React, { useContext, useEffect, useState } from 'react';
import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    InputAdornment,
    Skeleton,
    TextField
} from '@mui/material';
import { getAllCampaigns } from "../../api/CampaignTrackingAPI/CampaignTrackingAPI";
import { Icons, PrimaryButton, UnlockFullAccessPopUp } from "../index";
import SearchIcon from "@mui/icons-material/Search";
import './AddToCampaignDialog.scss';
import { CampaignTrackingContext, WarehouseContext } from '../../storage/context';
import { clearCampaignTrackingWarehouse, isCampaignEnded, isEmpty } from "../../utils/util";
import {
    ADD_POST_TO_CAMPAIGN,
    CREATE_NEW_CAMPAIGN,
    SAVE_CHANGES,
    SEARCH_CAMPAIGN_NAME
} from "../../constants/campaignTrackingConstants";
import { Colors } from "../../styles/colors";
import { trackEvent, TRACKING_UPGRADE_POPUP_CLOSED, TRACKING_URL_CREATE_A_CAMPAIGN_CLICKED } from '../../analytics';
import { campaignTrackingProductID, TRIAL } from "../../constants/constants";
import { useNavigate } from "react-router-dom";

export default function AddToCampaignDialog({ open, handleClose, handleSave }) {
    const [campaigns, setCampaigns] = useState([]);
    const navigate = useNavigate();
    const { dispatchCampaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [filteredCampaigns, setFilteredCampaigns] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const { warehouse } = useContext(WarehouseContext);
    const {
        usage: campaignsCreated,
        max_limit: campaignCreationLimit
    } = warehouse.tenantUsageQuota?.quotaInfoCreatorModule?.CAMPAIGNS_CREATED || {};
    const isTrialMode = warehouse.tenantAppProducts[campaignTrackingProductID] === TRIAL;

    const [modalDetails, setModalDetails] = useState({
        isModalVisible: false,
        modalInfo: "",
    });

    useEffect(() => {
        // Fetch the campaigns from the API when the dialog opens
        async function fetchCampaigns() {
            try {
                setLoading(true);
                const res = await getAllCampaigns({
                    limit: 100,
                    offset: 0,
                    warehouse
                });
                const { data } = res;
                if (isEmpty(data)) {
                    return;
                }
                const filteredCampaignsRes = data.filter(d => !isCampaignEnded(d) && !!!d.is_trial);
                setFilteredCampaigns(filteredCampaignsRes);
                setCampaigns(filteredCampaignsRes);
            } catch (error) {
                console.error('Error fetching campaigns:', error);
            } finally {
                setLoading(false);
            }
        }

        if (open) {
            fetchCampaigns().then(() => {
            });
        }
    }, [open]);

    useEffect(() => {
        if (isEmpty(searchString)) {
            setFilteredCampaigns(campaigns);
            return;
        }
        const filteredCampaignsRes = filteredCampaigns.filter(campaign =>
            campaign.name.toLowerCase().includes(searchString.toLowerCase())
        );
        setFilteredCampaigns(filteredCampaignsRes);
    }, [searchString]);

    function handleCheckboxChange(campaignId) {
        setSelectedCampaigns((prevSelected) =>
            prevSelected.includes(campaignId)
                ? prevSelected.filter((id) => id !== campaignId)
                : [...prevSelected, campaignId]
        );
    }

    const handleSaveChanges = () => {
        const selectedCampaignNames = [];
        selectedCampaigns.forEach(campaignId => {
            const name = campaigns.find(campaign => campaign.id === campaignId)?.name;
            name && selectedCampaignNames.push(name);
        });
        handleSave(selectedCampaigns, selectedCampaignNames);
        handleClose();
    };

    const handleCreateCampaign = () => {
        clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
        handleClose(); // Close the dialog before navigating
        trackEvent(TRACKING_URL_CREATE_A_CAMPAIGN_CLICKED);
        navigate("/creators/campaigns/create-campaign");
    };

    const handleSearchChange = (event) => {
        setSearchString(event.target.value);
    };

    const onClear = () => {
        setSearchString('');
    };

    function shouldHideSaveButton() {
        return filteredCampaigns.length === 0 || selectedCampaigns.length === 0;
    }

    function CreateCampaignListButton() {
        if (isTrialMode) {
            if (isEmpty(campaignsCreated) || isEmpty(campaignCreationLimit)) {
                console.error('Campaign tenant app quota probably errored out');
                return null;
            }
            if (campaignsCreated >= campaignCreationLimit) {
                return null;
            }
        }
        return (
            <div
                className={'create-new-button'}
                onClick={handleCreateCampaign} style={{ cursor: 'pointer' }}>
                <i className="ri-add-line body-m list-text"></i>
                <span className={'body-m list-text'}>{CREATE_NEW_CAMPAIGN}</span>
            </div>
        );
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'xl'} className={'add-to-campaign-dialog'} PaperProps={{
            style: {
                width: '380px', // Set the width of the inner Paper component
                height: '647px'
            },
        }}>
            <DialogTitle className={'add-to-campaign-title'}>
                <span className={'section-heading'}>{ADD_POST_TO_CAMPAIGN}</span>
                <i className="ri-close-line" onClick={handleClose}></i>
            </DialogTitle>
            {isLoading ?
                <DialogContent>
                    <Skeleton variant="text" width={"100%"} className={'loading-skeleton'}
                        animation={'pulse'} />
                </DialogContent> :
                <DialogContent className={'add-to-campaign-dialog-content'}>
                    <TextField
                        className={'campaign-search-input-existing'}
                        InputProps={{
                            classes: { root: 'custom-search-input-root' },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon className="search-icon" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <>
                                    {searchString && (
                                        <i className="ri-close-line clear-icon" onClick={onClear} />
                                    )}
                                </>
                            ),
                        }}
                        placeholder={SEARCH_CAMPAIGN_NAME}
                        value={searchString}
                        onChange={handleSearchChange}
                        variant="outlined"
                        fullWidth
                        margin={"normal"}
                    />
                    <CreateCampaignListButton />
                    {filteredCampaigns && filteredCampaigns.length > 0 && filteredCampaigns.map((campaign) => {
                        const { id, name } = campaign;
                        return (
                            <FormControlLabel
                                key={id}
                                control={
                                    <Checkbox
                                        checked={selectedCampaigns.includes(id)}
                                        onChange={() => handleCheckboxChange(id)}
                                        data-campaign-id={id} // Set custom data attribute for campaignId
                                        sx={{
                                            padding: 0, // Remove padding to align the checkbox to the edge
                                            paddingRight: '12px',
                                        }}
                                        icon={<Icons.unchecked_checkbox />} // Style the unchecked checkbox
                                        checkedIcon={<Icons.checked_checkbox />} // Style the checked checkbox
                                        disableRipple
                                    />
                                }
                                label={<span className={'body-m list-text'}>{name}</span>}
                                sx={{
                                    borderBottom: '1px solid var(--neutrals-border-grey)',
                                    padding: '0 20px', // Adjust the padding to include both left and right paddings
                                    minHeight: '56px', // Set the minimum height of the row
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            />
                        );
                    })}
                </DialogContent>}
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '16px 8px 0' }}>
                <PrimaryButton disabled={shouldHideSaveButton()} className={'save-btn'}
                    label={<span className={'body-m'}
                        style={{ color: Colors.white }}>{SAVE_CHANGES}</span>}
                    onClick={handleSaveChanges} />
            </div>
            <UnlockFullAccessPopUp
                open={modalDetails.isModalVisible}
                onClose={() => {
                    setModalDetails({ ...modalDetails, isModalVisible: false });
                    trackEvent(TRACKING_UPGRADE_POPUP_CLOSED, { trigger_event: modalDetails.modalInfo?.header });
                }}
                header={modalDetails.modalInfo.header}
                description={modalDetails.modalInfo.description}
                actionButtonLabel="Contact us"
                height="264px"
                padding="52px 52px 48px 48px"
                isModal
            />
        </Dialog>
    );
}
