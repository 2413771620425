const webhookUrl = "/developers/webhooks";
const updateWebhookPageUrl = "/developers/webhooks/edit";
const createWebhookPageUrl = "/developers/webhooks/create";
const create_identifier = "https://docs.insightiq.ai/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1users/post";
const generateToken = "https://docs.insightiq.ai/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1sdk-tokens/post";
const product = "https://docs.insightiq.ai/docs/api-reference/introduction/products";
const web_integration = "https://docs.insightiq.ai/docs/api-reference/connect-SDK/web/browser-integration";
const ios_integration = "https://docs.insightiq.ai/docs/api-reference/connect-SDK/mobile/ios-SDK-integration";
const product_APIs = "https://docs.insightiq.ai/docs/api-reference/API/getting-started-with-APIs";
const contactIconsUrl = "https://cdn.insightiq.ai/profile/contact_icons/";

export {
  webhookUrl,
  updateWebhookPageUrl,
  createWebhookPageUrl,
  create_identifier,
  generateToken,
  product,
  web_integration,
  ios_integration,
  product_APIs,
  contactIconsUrl,
};
