import { Button, Checkbox } from "antd";
import {
  ACCOUNT_STATUS,
  AccountStatus,
  APPLY,
  CREATOR_COLUMNS,
  RESET,
  SUPPORTED_PLATFORMS,
  WORKPLATFORM_ID_MAPPING,
} from "../../constants/constants";
import { useState } from "react";
import "./PlatformFilter.scss";
import { ApplyButton, ResetButton } from "../StyledComponents/Buttons";

const platformFilterDropdownOptions = Object.keys(WORKPLATFORM_ID_MAPPING)
  .filter((workPlatformId) => SUPPORTED_PLATFORMS.includes(workPlatformId))
  .map((workPlatformId) => {
    const label = WORKPLATFORM_ID_MAPPING[workPlatformId].platformName;
    const value = workPlatformId;
    return { label, value };
  })
  .sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

const accountStatusFilterDropdownOptions = [
  {
    label: AccountStatus.CONNECTED.label,
    value: AccountStatus.CONNECTED.key,
  },
  {
    label: AccountStatus.NOT_CONNECTED.label,
    value: AccountStatus.NOT_CONNECTED.key,
  },
  {
    label: AccountStatus.SESSION_EXPIRED.label,
    value: AccountStatus.SESSION_EXPIRED.key,
  },
];

export default function PlatformFilter(props) {
  const {
    platformFilterConfig: { appliedPlatforms, setAppliedPlatforms, appliedPlatformStatuses, setAppliedPlatformStatuses, setPlatformsFilterVisible },
  } = props;
  const [appliedPlatformsLocal, setAppliedPlatformsLocal] = useState(appliedPlatforms);
  const [appliedPlatformStatusesLocal, setAppliedPlatformStatusesLocal] = useState(appliedPlatformStatuses);

  function handlePlatformFilterConfirm() {
    setAppliedPlatforms(appliedPlatformsLocal);
    setAppliedPlatformStatuses(appliedPlatformStatusesLocal);
    setPlatformsFilterVisible(false);
  }

  function handlePlatformFilterReset() {
    setAppliedPlatformsLocal(null);
    setAppliedPlatformStatusesLocal(null);
    setAppliedPlatforms(null);
    setAppliedPlatformStatuses(null);
    setPlatformsFilterVisible(false);
  }

  return (
    <div className={"platform-filter"}>
      <div>
        <div className={"platform-filter-section-title platform-filter-title"}>{CREATOR_COLUMNS.PLATFORM}</div>
        <Checkbox.Group options={platformFilterDropdownOptions} value={appliedPlatformsLocal} onChange={setAppliedPlatformsLocal} />
      </div>
      <hr className={"platform-filter-separator"} />
      <div>
        <div className={"platform-filter-section-title"}>{ACCOUNT_STATUS}</div>
        <Checkbox.Group
          options={accountStatusFilterDropdownOptions}
          value={appliedPlatformStatusesLocal}
          onChange={setAppliedPlatformStatusesLocal}
        />
      </div>
      <br />
      <div className={"platform-filter-items"}>
        <ApplyButton type="primary" onClick={handlePlatformFilterConfirm}>
          {APPLY}
        </ApplyButton>
        <ResetButton onClick={handlePlatformFilterReset}>{RESET}</ResetButton>
      </div>
    </div>
  );
}
