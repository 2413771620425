import axios from "axios";
import axiosInstance from "../axiosInstance";
import {
  currentDashboardURL,
  devBaseURL,
  getDisconnectUrl,
  phylloAPIPrefix,
  profileSDKBASEURL,
  URL_GET_ALL_ACCOUNTS_PROFILE,
  URL_GET_AUDIENCE,
  URL_GET_CONTENT,
  URL_GET_CONTENT_GROUPS,
  URL_GET_USER_PROFILE,
} from "../endpoints";
import { AccountStatus, DisconnectionSource } from "../../constants/constants";
import { checkRemoved } from "../../utils/profileHelpers";

export const getAudienceDemographics = async (id, token, environmentBaseURL) => {
  try {
    const response = await axiosInstance.get(`${currentDashboardURL}${phylloAPIPrefix}/${URL_GET_AUDIENCE}?account_id=${id}`);

    return response;
  } catch (error) {
    console.error(error);
    return { status: error.response.status, message: error.message };
  }
};

export const getContent = async (id, limit, offset, type, token, environmentBaseURL, fromDate, toDate) => {
  const url = new URL(`${currentDashboardURL}${phylloAPIPrefix}/${URL_GET_CONTENT}`);
  url.searchParams.append("account_id", id);
  url.searchParams.append("offset", offset);
  url.searchParams.append("limit", limit);
  fromDate && url.searchParams.append("from_date", fromDate);
  toDate && url.searchParams.append("to_date", toDate);

  try {
    let response;
    type && url.searchParams.append("filters", `(type.eq=[${type}])`);
    response = await axiosInstance.get(url.href);
    return response;
  } catch (error) {
    console.error(error);
    return { status: error.response.status, message: error.message };
  }
};

export const getContentGroup = async (id, limit, offset, type, token, environmentBaseURL, fromDate, toDate) => {
  const url = new URL(`${currentDashboardURL}${phylloAPIPrefix}/${URL_GET_CONTENT_GROUPS}`);
  url.searchParams.append("account_id", id);
  url.searchParams.append("offset", offset);
  url.searchParams.append("limit", limit);
  fromDate && url.searchParams.append("from_date", fromDate);
  toDate && url.searchParams.append("to_date", toDate);

  try {
    let response;
    type && url.searchParams.append("filters", `(type.eq=[${type}])`);
    response = await axiosInstance.get(url.href);
    return response;
  } catch (error) {
    console.error(error);
    return { status: error.response.status, message: error.message };
  }
};

export const getProfileInfo = async (id, token, environmentBaseURL) => {
  try {
    const response = await axiosInstance.get(`${currentDashboardURL}${phylloAPIPrefix}/${URL_GET_USER_PROFILE}?account_id=${id}`);

    return response;
  } catch (error) {
    console.error(error);
    return { status: error.response.status, message: error.message };
  }
};

export const getAllAccounts = async (id, token, environmentBaseURL) => {
  try {
    const response = await axiosInstance.get(`${currentDashboardURL}${phylloAPIPrefix}/${URL_GET_ALL_ACCOUNTS_PROFILE}?user_id=${id}&limit=100`);
    if (response.status === 200) {
      const {
        data: { data: accounts },
      } = response;
      if (accounts.length > 0) {
        const removedAccounts = [];
        const validAccounts = [];
        accounts.forEach((acc) => {
          if (checkRemoved(acc)) {
            removedAccounts.push(acc);
          } else {
            validAccounts.push(acc);
          }
        });
        return [...validAccounts, ...removedAccounts];
      }
    } else {
      console.error(response.message);
    }
    // return response;
  } catch (error) {
    console.error(error);
    return { status: error.response.status, message: error.message };
  }
};

export function disconnectAccountApi(accountId, token, environmentBaseURL) {
  try {
    const url = new URL(`${currentDashboardURL}${phylloAPIPrefix}/${getDisconnectUrl(accountId)}`);
    return axiosInstance.post(url.href);
  } catch (error) {
    console.error(error);
    return { status: error.response.status, message: error.message };
  }
}
