import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import { Autocomplete, Box } from "@mui/material";
import PropTypes from "prop-types";
import "./SearchableDropDown.scss";
import Icons from "../Icons/Icons";
import { isEmpty } from "../../utils/util";
import { IconButton } from "@mui/material";
import { FILTER_SEARCHED, trackEvent } from "../../analytics";
import { FILTER_KEY_MAPPING, AUDIENCE_BRAND_AFFINITIES, AUDIENCE_INTERESTS, CREATOR_BRAND_AFFINITIES, CREATOR_INTERESTS } from "../../constants/creatorDiscovery";

const SearchableDropDown = ({
  multi = true,
  options,
  defaultValue,
  searchPlaceholder,
  getFilterValue,
  showTags = true,
  showClose = false,
  filterKey,
  onTextFieldChange,
  additionalStyles = {},
  shouldSetInputValue = false

}) => {
  let initialState;
  let timeout;
  const DEBOUNCE_TIME = 200;
  if (multi) {
    if (!isEmpty(defaultValue)) initialState = defaultValue;
    else initialState = [];
  } else {
    if (!isEmpty(defaultValue)) initialState = defaultValue;
    else initialState = null;
  }
  const [selectedValues, setSelectedValues] = useState(initialState);

  const { root } = additionalStyles;
  let rootStyle = { width: "auto", height: "46px" };
  if (!isEmpty(root)) {
    rootStyle = { ...rootStyle, ...root };
  }

  useEffect(() => {
    if (!shouldSetInputValue) return;
    setSelectedValues(initialState);
  }, [initialState]);

  const handleChange = (event, values) => {
    event.stopPropagation();
    setSelectedValues(values);
    if (getFilterValue) getFilterValue(values);
  };

  const onDelete = (name) => () => {
    const updatedValues = selectedValues.filter((v) => v.name !== name);
    setSelectedValues(updatedValues);
    if (getFilterValue) getFilterValue(updatedValues);
  };

  function onTextChange(event) {
    const queryText = event.target.value;
    if (!queryText) return;
    onTextFieldChange && onTextFieldChange(queryText);
    const matchingKeywordCount = getMatchingKeywordCount(queryText);
    if (filterKey === CREATOR_BRAND_AFFINITIES || filterKey === CREATOR_INTERESTS || filterKey === AUDIENCE_BRAND_AFFINITIES || filterKey === AUDIENCE_INTERESTS) {
      const debouncedTrackEvent = debounce(() => {
        trackEvent(FILTER_SEARCHED, {
          query: queryText,
          source: FILTER_KEY_MAPPING[filterKey],
          result_count: matchingKeywordCount,
        });
      }, DEBOUNCE_TIME);
      debouncedTrackEvent();
    }
  }

  function getMatchingKeywordCount(enteredText) {
    return options.filter((option) =>
      option.name.toLowerCase().includes(enteredText.toLowerCase())
    ).length;
  }

  function debounce(func, delay) {
    return function () {
      const context = this;
      const args = arguments;

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  return (
    <>
      <Autocomplete
        disableClearable={!showClose}
        defaultValue={defaultValue}
        classes={{
          root: "searchable-drop-down-container",
          option: "searchable-drop-down-option",
          paper: "searchable-drop-down-option-container",
        }}
        componentsProps={{
          popper: {
            classes: {
              root: "options-container",
            },
          },
        }}
        size="small"
        multiple={multi}
        id="custom-autocomplete"
        options={options}
        value={selectedValues}
        onChange={handleChange}
        getOptionLabel={(option) => option.name}
        renderTags={() => null}
        style={rootStyle}
        popoverProps={{
          onClick: (ev) => {
            ev.stopPropagation();
          },
        }}
        popupIcon={<Icons.down_arrow />}
        renderInput={(params) => (
          <TextField
            sx={{
              padding: "0px",
            }}
            {...params}
            className="my-searchable-textfield"
            size="small"
            variant="outlined"
            placeholder={searchPlaceholder}
            onChange={onTextChange}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value} style={{ borderBottom: "1px solid var(--neutrals-border-grey)", height: "56px" }}>
              <div className="searchable-options">{option.name}</div>
            </li>
          );
        }}
      />
      {showTags && (
        <Box sx={{ marginLeft: "4px" }}>
          {selectedValues?.length > 0 &&
            selectedValues.map((v) => (
              <Chip
                classes={{
                  root: "searchable-drop-down-chip-contianer",
                  label: "searchable-drop-down-chip-label",
                  deleteIcon: "searchable-drop-down-chip-delete-icon",
                }}
                key={v.name}
                label={v.name}
                onDelete={onDelete(v.name)}
                deleteIcon={<Icons.icon_close />}
              />
            ))}
        </Box>
      )}
    </>
  );
};

export default SearchableDropDown;

SearchableDropDown.propTypes = {
  options: PropTypes.array,
  multi: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  getFilterValue: PropTypes.func,
};
