import { Box, Skeleton } from "@mui/material";
import "./LookAlikeCard.scss";
import Icons from "../Icons/Icons";
import { formatNumber } from "../../utils/profileHelpers";
import { Tooltip } from "antd";
import { CREATOR_PROFILE_LINK } from "../../constants/constants";

const LookAlikeCard = ({ title, tooltip, loading, data, isAudienceLookAlike, workplatformId }) => {
  if (loading) {
    return <SkeletonComp size="small" />;
  }
  return (
    <div className="creator-look-alike">
      <div className="header">
        <div className="creator-lookalikes-header" style={{ fontSize: isAudienceLookAlike && "18px" }}>
          {title}
        </div>
        {/* <Tooltip title={tooltip} placement="top">
          <Icons.info_icon style={{ cursor: "pointer" }} />
        </Tooltip> */}
      </div>
      <div className="container">
        {!loading &&
          data &&
          data.map((item) => (
            <a href={CREATOR_PROFILE_LINK(item?.platform_username, workplatformId)} target="_blank">
              <div className="profile">
                <div className="ellipse-parent">
                  <img className="frame-child" alt="" src={item.image_url} />
                  <div className="frame-parent">
                    <div className="peteran-parent">
                      <div className="creator-lookalikes">{item?.platform_username}</div>
                      {item?.is_verified && <Icons.verified_logo className="twitter-verified-badge-1-icon" />}
                    </div>
                    {/* <div className="bessie-cooper">Bessie Cooper</div> */}
                    {item?.follower_count && <div className="k-subscribers">{formatNumber(item?.follower_count)} followers</div>}
                    {item?.subscriber_count && <div className="k-subscribers">{formatNumber(item?.subscriber_count)} subscribers</div>}
                  </div>
                </div>
              </div>
            </a>
          ))}
      </div>
    </div>
  );
};

function SkeletonComp({ size }) {
  return (
    <Box className={size === "large" ? "country-distribution-container large" : "country-distribution-container small"}>
      <Box className="header">
        <Skeleton animation="wave" width={"40%"} height={14} sx={{ marginBottom: "16px" }} />
        <Skeleton animation="wave" variant="circular" width={16} height={16} />
      </Box>

      {[1, 2, 3, 4, 5].map(() => {
        return (
          <Box className="row">
            <Box>
              <Skeleton animation="wave" variant="circular" width={25} height={25} />
            </Box>
            <Skeleton animation="wave" variant="text" width={"90%"} height={20} sx={{ marginLeft: "12px" }} />
          </Box>
        );
      })}
    </Box>
  );
}

export default LookAlikeCard;
