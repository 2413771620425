import { DataGrid } from '@mui/x-data-grid';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { deleteCompareCreatorsLists, exportCreatorComparisonData, getCompareCreatorData } from '../../api/compareCreatorAPI/compareCreatorAPI';
import { COMPARE_CREATOR_LINK, creatorSearchTabs, DATE_TIME_FORMAT_WITHOUT_TIME, DONT_DELETE, TRIAL, SHARE_BUTTON_TEXT } from '../../constants/constants';
import { CompareCreatorGridColumns, COMPARE_CREATOR_REPORT_STATUS, COMPARE_CREATOR_SEARCH_PLACEHOLDER, CONVERT_TO_PERCENTAGE, COPY_LINK, CREATORS, DELETED_COMPARISON, DELETE_COMPARISON, DELETE_TOOLTIP_TEXT, GENERATING_LINK, GET_COMPARE_CREATORS_DELETE_DESC, NO_COMPARISON_FOUND, RECENT_COMPARISON, SAMPLE_REPORT, SHARE_CREATOR_COMPARISON, YES_DELETE } from '../../constants/creatorDiscovery';
import { useToast } from '../../hooks/useToast';
import { Colors } from '../../styles/colors';
import { CustomFooter, getSortedHeaderClass } from '../../utils/DataGridUtils';
import ExportPDFPopUp from '../ExportPDFPopUp/ExportPDFPopUp';
import IntermediateLoader from '../IntermediateLoader/IntermediateLoader';
import Icons from '../Icons/Icons';
import TextBox from '../InsightIQ/TextBox/TextBox';
import ModalDialog from '../ModalDialog/ModalDialog';
import "./CompareCreatorTable.scss";
import moment from 'moment-timezone';
import ToastMessage from '../ToastMessage/ToastMessage';
import { useNavigate } from 'react-router-dom';
import { WarehouseContext } from '../../storage/context';
import Loader from '../Loader/Loader';
import { Tooltip } from "@material-ui/core";

const COMPARE_CREATOR_TABLE_ICONS = {
  Instagram: {
    icon: <Icons.insta_table_icon />,
  },
  YouTube: {
    icon: <Icons.youtube_table_icon />,
  },
  TikTok: {
    icon: <Icons.tiktok_table_icon />,
  },
};

function CompareCreatorTable({ isTrialMode }) {
  const allowedSorts = ['desc', 'asc'];
  const PAGE_SIZE = 10;
  const ROW_HEIGHT = 96;
  const [rows, setRows] = useState([]);
  const { warehouse } = useContext(WarehouseContext);
  const [pageNumber, setPageNumber] = useState(0); // Adjusted to 0-based indexing
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState({
    showModal: false,
    creators: []
  });
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const toast = useToast();
  const defaultRecentTableSortModel = [{ field: CompareCreatorGridColumns.CREATED_ON.field, sort: "desc" }];
  const [sortModel, setSortModel] = useState(defaultRecentTableSortModel);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const DELETE_TOAST_DURATION = 2500;
  const navigate = useNavigate();
  const { SUCCESS } = COMPARE_CREATOR_REPORT_STATUS;
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const onlyOnce = useRef(false);
  useEffect(() => {
    fetchData().then(() => {
    });
  }, [pageNumber, sortModel]);

  async function fetchData(searchString = searchText) {
    try {
      let sort_by = null,
        sort_desc = null;
      if (sortModel?.length === 1) {
        sort_by = sortModel[0].field;
        sort_desc = sortModel[0].sort === 'desc';
      }
      setLoading(true);
      const res = await getCompareCreatorData({
        offset: pageNumber * PAGE_SIZE,
        limit: PAGE_SIZE,
        sort_by,
        sort_desc,
        searchString
      });
      const { data, metadata } = res;
      setRows(data);
      setTotalRows(metadata.count);
    } catch (e) {
      console.error('Error fetching data:', e);
    } finally {
      setLoading(false);
    }
  }

  const commonHeaderProps = {
    flex: 1,
    headerClassName: 'subtitle-m mui-data-grid-header hideRightSeparator',
  };


  function renderCreatorsCell(params) {
    const { creators, is_sample, work_platform } = params.row;
    return <div className='creators-cell-container' style={{ gap: creators.length === 3 ? '48px' : '32px' }}>
      <div className='creator-images'>
        {creators.map((creator, index) => (
          <div key={index}>
            {creator.thumbnail_url ?
              <img
                style={{ marginRight: creators.length === 3 ? '-32px' : '-16px' }}
                src={creator.thumbnail_url}
              /> :
              <Icons.male_profile_icon style={{ marginRight: creators.length === 3 ? '-32px' : '-16px' }} />}
          </div>
        ))}
      </div>
      <div className='creators-cell-details text-truncate' >
        <div className={`body-b text-truncate`} >
          {is_sample ? <span className='body-b trial-sample-report' >{SAMPLE_REPORT} </span> : null}{creators
            .map(creator => creator.full_name ?? creator.platform_username)
            .join(', ')}
        </div>
        <div className='body-r total-creators'  >
          <div className='compare-creator-table-total-creator'>
            {COMPARE_CREATOR_TABLE_ICONS[work_platform.name].icon}
          </div>
          {creators?.length} {CREATORS} </div>
      </div>
    </div>;
  }

  function renderUniqueAudienceCell(params) {
    const { unique_followers } = params.row;
    return <div className=' body-r'>
      {CONVERT_TO_PERCENTAGE(unique_followers)}
    </div>;
  }

  function renderSharedAudienceCell(params) {
    const { shared_followers } = params.row;
    return <div className=' body-r'>
      {CONVERT_TO_PERCENTAGE(shared_followers)}
    </div>;
  }

  function renderCreatedOnCell(params) {
    const { created_at } = params.row;
    return <div className=' body-r'>
      {moment.utc(created_at).format(DATE_TIME_FORMAT_WITHOUT_TIME)}
    </div>;
  }

  async function handleProfileExport({ comparisonId, isPDFDownloadRequired = false }) {
    setIsPDFLoading(true);
    const payload = {
      id: comparisonId
    };
    try {
      const response = await exportCreatorComparisonData({ payload });
      if (!response.url) return;
      const url = response.url;
      setShareLink(url);
      // Provide a filename for the downloaded PDF
      if (isPDFDownloadRequired) {
        const fileName = response.filename;
        // take url from response and click to download it
        const pdfDownloadUrl = document.createElement("a");
        pdfDownloadUrl.href = url;
        pdfDownloadUrl.download = fileName;
        document.body.appendChild(pdfDownloadUrl);
        pdfDownloadUrl.click();
        document.body.removeChild(pdfDownloadUrl);
      }
      setIsPDFLoading(false);
      setIsExportModalOpen(true);
    } catch (error) {
      throw error;
    } finally {
      setIsPDFLoading(false);
    }
  };


  function renderActionsCell(params) {
    const { id, is_sample, status } = params.row;
    return <div className='action-cell-buttons'>
      <div className={status !== SUCCESS ? 'compare-creator-limit-reached' : ''}
        onClick={(e) => {
          e.stopPropagation();
          handleProfileExport({ comparisonId: id, isPDFDownloadRequired: false });
        }} > 
        <Tooltip placement={'top-left'} title={SHARE_BUTTON_TEXT}>
        <div>
          <i class="ri-share-forward-line ri-xl"></i>
        </div>
        </Tooltip>
      </div>
      <div className={is_sample ? 'compare-creator-limit-reached' : ''}
        onClick={(e) => {
          e.stopPropagation();
          setShowDeleteModal({ showModal: true, creatorsList: params.row });
        }} >
        <Tooltip placement={'top-left'} title={DELETE_TOOLTIP_TEXT}>
        <div>
          <i class="ri-delete-bin-line ri-xl"></i>
        </div>
        </Tooltip>
      </div>
    </div>;
  }

  const columns = [
    {
      field: 'id',
      headerName: CompareCreatorGridColumns.ID.header,
      hide: true,
    },
    {
      ...commonHeaderProps,
      align: 'left',
      field: CompareCreatorGridColumns.CREATORS.field,
      headerAlign: 'left',
      headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, CompareCreatorGridColumns.CREATORS.field)}`,
      headerName: CompareCreatorGridColumns.CREATORS.header,
      minWidth: 420,
      maxHight: 96,
      renderCell: renderCreatorsCell,
      sortable: false,
    },
    {
      ...commonHeaderProps,
      align: 'right',
      field: CompareCreatorGridColumns.SHARED_AUDIENCE.field,
      headerAlign: 'left',
      headerName: CompareCreatorGridColumns.SHARED_AUDIENCE.header,
      minWidth: 10,
      maxWidth: 158,
      renderCell: renderSharedAudienceCell,
      sortable: false,
    },
    {
      ...commonHeaderProps,
      align: 'right',
      field: CompareCreatorGridColumns.UNIQUE_AUDIENCE.field,
      headerAlign: 'left',
      headerName: CompareCreatorGridColumns.UNIQUE_AUDIENCE.header,
      minWidth: 20,
      maxWidth: 158,
      renderCell: renderUniqueAudienceCell,
      sortable: false,
    },
    {
      ...commonHeaderProps,
      align: 'right',
      field: CompareCreatorGridColumns.CREATED_ON.field,
      headerAlign: 'right',
      headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, CompareCreatorGridColumns.CREATED_ON.field)}`,
      headerName: CompareCreatorGridColumns.CREATED_ON.header,
      minWidth: 20,
      maxWidth: 157,
      renderCell: renderCreatedOnCell,
      sortable: true,
    },
    {
      ...commonHeaderProps,
      align: 'left',
      field: CompareCreatorGridColumns.ACTIONS.field,
      headerAlign: 'left',
      headerClassName: ` action-header ${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, CompareCreatorGridColumns.ACTIONS.field)}`,
      headerName: CompareCreatorGridColumns.ACTIONS.header,
      minWidth: 170,
      renderCell: renderActionsCell,
      sortable: false,
    }
  ];

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  function handleRowClick(params) {
    const comparisonId = params.id;
    sessionStorage.setItem("search-results", JSON.stringify({ currentTab: creatorSearchTabs.compareCreators }));
    navigate(COMPARE_CREATOR_LINK(comparisonId));
  }

  function onSearch(searchString) {
    fetchData(searchString).then(() => {
    });
  }

  const onDeleteCompareCreatorLists = async () => {
    try {
      const deleteId = showDeleteModal.creatorsList.id;
      const response = await deleteCompareCreatorsLists({ deleteCreatorId: deleteId });
      setShowDeleteModal({ isModalOpen: false, creatorsList: {} });
      setIsToastOpen(true);
    } catch (error) {
      console.log(error);
    } finally {
      fetchData().then(() => { });
    }
  };

  if (isTrialMode && isLoading && !onlyOnce.current) {
    onlyOnce.current = true;
    return <IntermediateLoader />;
  }

  // In case of trial mode, don't render search box and table if no comparison
  if (isTrialMode && totalRows === 0 && !isLoading && searchText.length === 0) {
    return;
  }

  return (
    <div>
      <div className='recent-comparison-header sub-section-heading' >{RECENT_COMPARISON}</div>
      <div>
        <TextBox
          variant={"short"}
          placeholder={COMPARE_CREATOR_SEARCH_PLACEHOLDER}
          value={searchText}
          onEnter={onSearch}
          onClear={() => {
            setSearchText("");
            onSearch("");
          }}
          containerClass={""}
          onChange={(e) => {
            e.preventDefault();
            if (e.target.value.length === 0) {
              onSearch("");
            }
            setSearchText(e.target.value);
          }}
        />
      </div>
      <div className={`compare-creator-data-grid-container`} style={!isLoading && rows.length !== 0 ? { height: `${(rows.length + 1) * (ROW_HEIGHT + 5)}px` } : null} >
        <DataGrid
          className={"mui-data-grid"}
          columns={columns}
          components={{
            Footer: (props) => {
              return <CustomFooter totalRows={totalRows} pageSize={PAGE_SIZE}
                handlePageChange={handlePageChange} pageNumber={pageNumber} />;
            },
          }}
          getRowHeight={() => ROW_HEIGHT}
          getRowId={(row) => row.id}
          initialState={{
            sorting: {
              sortModel: defaultRecentTableSortModel,
            },
          }}
          loading={isLoading}
          onPageChange={handlePageChange}
          // onPageSizeChange={handlePageSizeChange}
          onRowClick={handleRowClick}
          onSortModelChange={setSortModel}
          page={pageNumber}
          pageSize={PAGE_SIZE}
          pagination
          paginationMode={"server"}
          rowCount={totalRows}
          rows={rows}
          sortModel={sortModel}
          sortingMode={"server"}
          sortingOrder={allowedSorts}
          disableColumnMenu
          sx={{
            '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
          }}
          localeText={{
            noRowsLabel: (
              <span className={"body-m"} style={{ color: Colors.neutralsSecondaryGrey }}>
                {NO_COMPARISON_FOUND}
              </span>
            ),
          }}
        />
      </div>
      <div className='compare-creator-delete-modal' >
        {showDeleteModal.showModal ? <ModalDialog
          variant={"withOutContent"}
          cancelText={DONT_DELETE}
          buttonText={YES_DELETE}
          title={DELETE_COMPARISON}
          additionalText={GET_COMPARE_CREATORS_DELETE_DESC(showDeleteModal?.creatorsList?.creators)}
          onAction={onDeleteCompareCreatorLists}
          onClose={() => setShowDeleteModal({ showModal: false, creatorsList: {} })}
          onDont={() => setShowDeleteModal({ showModal: false, creatorsList: {} })}
        /> : null}

        <ExportPDFPopUp
          open={isExportModalOpen}
          onClose={() => setIsExportModalOpen(false)}
          header={SHARE_CREATOR_COMPARISON}
          actionButtonLabel={COPY_LINK}
          actionButtonHandler={() => {
            navigator.clipboard.writeText(shareLink);
            setIsExportModalOpen(false);
            toast("Link copied to clipboard");
          }}
          exportPDFLink={shareLink}
        />
        <ToastMessage
          openToast={isToastOpen}
          message={DELETED_COMPARISON}
          duration={DELETE_TOAST_DURATION}
          onCloseToast={() => {
            setIsToastOpen(false);
          }}
          type={'delete'}
        />
        <ExportLoadingModal open={isPDFLoading} />
      </div>
    </div>
  );
}

const ExportLoadingModal = ({ open, onClose }) => {
  if (!open) {
    return null;
  }
  return (
    <div className="modal">
      <div className="export-results-overlay-loading">
        <div className="export-result-loader-container">
          <Loader className={"campaign-loader-container"} />
          <div className="export-result-loader-header">{GENERATING_LINK}</div>
        </div>
      </div>
    </div>
  );
};


export default CompareCreatorTable;