import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { STEP } from '../../constants/campaignTrackingConstants';
import Icons from '../Icons/Icons';
import "./Step.scss"
import { Colors } from "../../styles/colors";

const Step = ({ icon, stepCount, title, message, content, variant, stepOpenState, updateStep }) => {



  const stepWithIcon = () => {
    return (
      <Box mb={"32px"}>
        <Box className={'measure-step-container'} >
          <Box>{icon}</Box>
          <Box>
            <div className={'sub-section-heading'} style={{ marginBottom: 8 }}>
              <span style={{ color: 'var(--neutrals-secondary-grey)' }}>{`${STEP} ${stepCount}: `}</span>
              {title}
            </div>
            <div className={'body-r step-desc'}>{message} </div>
          </Box>
        </Box>
        <div>
          {content}
        </div>
      </Box>)
  }
  const stepWithoutIcon = () => {
    return (
      <div className={'step-container'}>
        <span className={'sub-section-heading'} style={{ marginBottom: 8 }}>
          <span style={{ color: 'var(--neutrals-secondary-grey)' }}>{`${STEP} ${stepCount}: `}</span>
          {title}
        </span>
        <span className={'body-r step-message'} >{message}</span>
        <div>
          {content}
        </div>
      </div>)
  }

  const dropdownStep = () => {
    return (
      <div className={'step-container'}>
        <span className={'sub-section-heading dropdown-step-arrow-container'}>
          <div>
            <span style={{ color: 'var(--neutrals-secondary-grey)' }}>{`${STEP} ${stepCount}: `}</span>
            {title}
          </div>
          <div style={{ cursor: "pointer" }} onClick={() => updateStep(stepCount)} >
            {stepOpenState[stepCount] ? (
              <Icons.down_arrow alt="down_arrow_icon" className="down-arrow-transform" />
            ) : (
              <Icons.down_arrow alt="down_arrow_icon" />
            )}
          </div>
        </span>
        <span className={'body-r step-message'}>{message}</span>
        {stepOpenState[stepCount] && <div>
          {content}
        </div>}
      </div>
    )
  }

  const dropdownStepWithIcon = () => {
    return (
      <div style={{margin:"0px"}} className={'step-container'}>
        <span onClick={() => updateStep(stepCount)} className={'sub-section-heading dropdown-step-arrow-container'}>
          <div className='icon-text-div' >
            <Box>{icon}</Box>
            <Box>
              <div>{title}</div>
              <div className={'body-r dropdown-with-icon-message'}
               >{message}
              </div>
            </Box>
          </div>
          <div style={{ cursor: "pointer" }}  >
            {stepOpenState[stepCount] ? (
              <Icons.down_arrow alt="down_arrow_icon" className="down-arrow-transform" />
            ) : (
              <Icons.down_arrow alt="down_arrow_icon" />
            )}
          </div>
        </span>

        {stepOpenState[stepCount] && <div>
          {content}
        </div>}
      </div>
    )
  }
  switch (variant) {
    case "StepWithIcon":
      return stepWithIcon();
    case "DropdownStep":
      return dropdownStep();
    case "DropdownStepWithIcon":
      return dropdownStepWithIcon();
    default:
      return stepWithoutIcon();
  }

}

export default Step