import {
  Typography,
  Icons,
  Tag,
  InputFieldWithCopyIcon,
  InputFieldPassWithCopyIcon,
  CardLink,
  InvertedPrimaryButton,
  Loader,
  PrimaryButton,
} from "../../components/index";
import "./APICredentialsPage.scss";
import { Helmet } from "react-helmet";

import React, { useContext, useState } from "react";
import { AnalyticsContext, UserContext, WarehouseContext } from "../../storage/context";
import { Modal, Tooltip } from "antd";
import { tenantInfoApi } from "../../api";
import { API_KEYS_PAGE_HEADER, CREDENTIAL_SECTRET_ACTIVE_STATUS, EMPTY_REQUIRED_FIELD_MESSAGE, getPageTitle, pageTitle, Sandbox } from "../../constants/constants";
import moment from 'moment-timezone';
import _ from "lodash";
import { useFetchTenantInformation } from "../../hooks/useFetchTenantInformation";
import { rotateSecretWrongPasswordErrorMessage } from "../../constants/errorMessages";
import { useToast } from "../../hooks/useToast";

import {
  CREDENTIALS_PAGE_VIEW,
  CREDENTIAL_COPY,
  SECRET_ROTATION_NEW_SECRET_GENERATE_FAILURE,
  SECRET_ROTATION_PASSWORD_FAILURE,
  SECRET_ROTATION_PASSWORD_SUBMIT,
  SECRET_ROTATION_PASSWORD_SUCCESS,
  SECRET_ROTATION_START,
  trackEvent,
} from "../../analytics";
import { CLIENT_SECRET_DELETED } from "../../constants/toastMessages";
import { getExpiryTime } from "../../utils/util";
import { useEffect } from "react";
import { UPDATE_ANALYTICS } from "../../storage/reducers/types";
import { useReducer } from "react";
import { analyticsReducer } from "../../storage/reducers";
import axios from "axios";
import RotateSecretModal from "./RotateSecretModal";

const ListViewComponent = ({ environment, tenantInfo, dispatchWarehouse }) => {
  const tenantId = tenantInfo[0].id;
  const clientId = tenantInfo[0]?.apps[0]?.id;
  const clientSecrets = tenantInfo[0].apps[0]?.credentials;
  const fetchTenantInformation = useFetchTenantInformation();
  const toast = useToast();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const rotateClientSecretHandler = async (password) => {
    try {
      trackEvent(SECRET_ROTATION_PASSWORD_SUBMIT);
      const response = await tenantInfoApi.rotateClientSecret({
        tenantId,
        clientId,
        tenantCredentialId: clientSecrets[0]?.id,
        password,
        environment,
      });
      if (response) {
        trackEvent(SECRET_ROTATION_PASSWORD_SUCCESS);
        fetchTenantInformation();
      }
    } catch (e) {
      trackEvent(SECRET_ROTATION_PASSWORD_FAILURE, {
        failure_reason: rotateSecretWrongPasswordErrorMessage[e.error_code],
      });
      throw rotateSecretWrongPasswordErrorMessage[e.error_code];
    }
  };

  const deleteClientSecret = async (clientSecretIdToBeDeleted, timeLeftToExpiry) => {
    const response = await tenantInfoApi.deleteClientSecret({
      tenantId,
      clientId,
      tenantCredentialId: clientSecretIdToBeDeleted,
      environment,
      timeLeftToExpiry,
    });
    if (response) {
      toast(CLIENT_SECRET_DELETED);
      setIsModalVisible(false);
      fetchTenantInformation();
    }
  };

  if (clientSecrets.length === 1) {
    return (
      <>
        <div className="list-view-container">
          <InputFieldPassWithCopyIcon
            inputLabel="Secret"
            placeholder="Secret"
            type="password"
            value={clientSecrets[0]?.client_secret}
            onClick={() => trackEvent(CREDENTIAL_COPY, { credential_type: "Secret" })}
          />
          <div style={{ marginTop: "30px" }}>
            <label className="input-label">Created on</label>
            <Typography h3>{moment(clientSecrets[0]?.created_at).format("MMM DD, YYYY")}</Typography>
          </div>
          <div style={{ marginRight: "16px", marginTop: "30px" }}>
            <InvertedPrimaryButton
              label="Rotate"
              icon={<Icons.rotate />}
              onClick={() => {
                setIsModalVisible(true);
                trackEvent(SECRET_ROTATION_START);
              }}
            />
          </div>
        </div>
        <RotateSecretModal handler={rotateClientSecretHandler} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
      </>
    );
  }

  return clientSecrets
    .sort((d1, d2) => {
      const date1 = new Date(d1.expiring_at);
      const date2 = new Date(d2.expiring_at);
      return date1 - date2;
    })
    .reverse()
    .map((item, index) => {
      const { expiring_at } = item;
      return (
        <div className="list-view-container">
          <InputFieldPassWithCopyIcon
            inputLabel="Secret"
            placeholder={expiring_at ? "Old secret" : "New secret"}
            type="password"
            value={item.client_secret}
          />
          <div>
            <label className="input-label">Created on</label>
            <Typography h3>{moment(item?.created_at).format("MMM DD, YYYY")}</Typography>
          </div>
          <div>
            {expiring_at ? (
              <Tag
                color="error"
                // backgroundColor="#FFD8CA"
                // textColor="#884E39"
                height="36px"
                width="64px"
                fontSize="14px"
              >
                Old
              </Tag>
            ) : (
              <Tag color="success" backgroundColor="#D2F3E5" textColor="#417854" height="36px" width="64px" fontSize="14px">
                New
              </Tag>
            )}
          </div>
          {expiring_at ? (
            <span className="expires-date-container">
              {" "}
              Expires in {getExpiryTime(item?.expiring_at)}
              {/* {moment(item?.expiring_at).diff(moment(new Date()), "days") +
                1}{" "} */}{" "}
            </span>
          ) : (
            <span className="expires-date-container" style={{ visibility: "hidden" }}></span>
          )}

          {expiring_at ? (
            <Tooltip title="Delete">
              <Icons.delete_icon
                className="delete-icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  Modal.warn({
                    title: "Delete secret?",
                    className: "delete-modal-container",
                    content: `Are you sure you want to delete the old secret?`,
                    onOk: () => deleteClientSecret(item.id, getExpiryTime(item?.expiring_at)),
                    closable: true,
                    okText: "Delete",
                    closeIcon: <Icons.close_circle_fill />,
                    icon: false,
                    footer: null,
                    // okButtonProps: {
                    //   style: {
                    //     "background-color": "#AD3737",
                    //     border: "1.5px solid #852121",
                    //   },
                    // },
                  });
                }}
              />
            </Tooltip>
          ) : (
            <Icons.delete_icon style={{ visibility: "hidden" }} />
          )}
        </div>
      );
    });
};

function APICredentialsPage() {
  const {
    warehouse,
    warehouse: { tenantInfo },
    dispatchWarehouse,
  } = useContext(WarehouseContext);
  const { userProfile } = useContext(UserContext);
  const [analytics, dispatchAnalytics] = useReducer(analyticsReducer, JSON.parse(localStorage.getItem("insightiq_analytics")));

  useEffect(() => {
    if (Object.keys(tenantInfo).length > 0) {
      dispatchAnalytics({
        type: UPDATE_ANALYTICS,
        payload: {
          tenant_id: tenantInfo[0]?.id,
          user_email: userProfile.user.email,
          user_id: userProfile.user.email,
          environment: warehouse.environment.current,
        },
      });
    }
  }, [dispatchAnalytics, tenantInfo]);

  useEffect(() => {
    trackEvent(CREDENTIALS_PAGE_VIEW);
  }, [dispatchAnalytics]);

  if (!tenantInfo.length > 0) {
    return <Loader />;
  }
  return (
    <div className="api-credentials-page-container">
      <div className="title api-credentials-page-header">
        {API_KEYS_PAGE_HEADER}
      </div>
      <div className="credentials-content-container">
        <InputFieldWithCopyIcon inputLabel="Client ID" placeholder="Client ID" value={tenantInfo[0]?.apps[0]?.id} />
        {tenantInfo.length > 0 && (
          <ListViewComponent environment={warehouse.environment.current} tenantInfo={tenantInfo} dispatchWarehouse={dispatchWarehouse} />
        )}
        {/* // <ListViewComponent
        //   clientSecrets={appParams.clientCredentials[0].apps[0].credentials}
        // /> */}
      </div>
      <div className="credentials-cards-container">
        <CardLink
          title="Getting started with the APIs"
          description="Get all the information you need to get started with insightIQ's APIs."
          event="contextual"
          link="https://docs.insightiq.ai/docs/developer-docs/API/getting-started-with-APIs"
          backgroundColor="#F5F5F7"
        ></CardLink>
        <CardLink
          title="API references"
          description="Get all the information you need related to insightIQ’s APIs and their URLs."
          event="contextual"
          link="https://docs.insightiq.ai/docs/developer-docs/api/ref"
          backgroundColor="#F5F5F7"
        ></CardLink>
      </div>
      <Helmet>
        <title>{getPageTitle("API_CREDENTIAL_PAGE", warehouse.environment.current)}</title>
      </Helmet>
    </div>
  );
}

export default APICredentialsPage;
