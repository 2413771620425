import React, { useContext, useEffect, useReducer, useState } from "react";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Icons from "../Icons/Icons";
import { ADD_ENVIRONMENT, UPDATE_ANALYTICS, UPDATE_ENVIRONMENT_BASE_URL } from "../../storage/reducers/types";
import { UserContext, WarehouseContext } from "../../storage/context";
import "./Sidebar.scss";
import {
    campaignTrackingProductID,
    creatorLinkageProductID,
    creatorSearchProductID,
    CURR_SELECTED_ENV_KEY,
    Developers,
    ENVIRONMENT,
    HELP_LABEL,
    INSIGHTIQ_API_STATUS,
    INSIGHTIQ_DOCS_LINK,
    MEETING_SCHEDULE_LINK,
    Production,
    Sandbox,
    SIDEBAR_MENUS,
    Staging,
    TRIAL,
} from "../../constants/constants";
import getModal from "../../constants/modal";
import { analyticsReducer } from "../../storage/reducers";
import {
    ACCOUNT_CLICKED,
    DEVELOPER_CLICKED,
    ENVIRONMENT_CLICKED,
    MAINMENU_CLICKED,
    MARKETER_CLICKED,
    trackEvent
} from "../../analytics";
import { getEnvBaseURL, isEmpty } from "../../utils/util";
import LinkText from "../LinkText/LinkText";
import { Box } from "@mui/material";
import EnvironmentSelectionDropdown from "../EnvironmentSelectionDropdown/EnvironmentSelectionDropdown";
import { getAmberfloUsageUrl } from "../../api/usage/usage";
import { tenantInfoApi } from "../../api";

const { Sider } = Layout;

export default function Sidebar({ onContactSalesClick, currentTab, headerInfo }) {
    const {
        warehouse,
        warehouse: { productionKeys, tenantAppProducts },
        dispatchWarehouse,
    } = useContext(WarehouseContext);
    const { userProfile } = useContext(UserContext);
    const [activeTab, setActiveTab] = useState("getting-started");
    const location = useLocation();
    const [showSalesCard, setShowSalesCard] = useState(false);
    const [isComplianceFormFilled, setIsComplianceFormFilled] = useState(warehouse.isComplianceFormFilled);
    const [isSalesModalOpen, setIsSalesModalOpen] = useState(false);
    const [analytics, dispatchAnalytics] = useReducer(analyticsReducer, JSON.parse(localStorage.getItem("inisightiq_analytics")));
    const pathName = location.pathname;
    const [isCreatorDiscoveryActive, setIsCreatorDiscoveryActive] = useState(
        pathName.includes("creator-search") || pathName.includes("campaigns") ? true : false
    );
    const [isLogOutPopoverOpen, setIsLogOutPopoverOpen] = useState(false);
    let selectedEnv = warehouse.environment.current;
    const [usageInfo, setUsageInfo] = useState({});

    async function getUsageData() {
        const tenantResponse = await tenantInfoApi.getTenantInfo(Production);
        setUsageInfo((prevUsageInfo) => {
            return {
                ...prevUsageInfo,
                prodTenantAppId: tenantResponse[0]?.apps[0]?.id,
            }
        });
        const amberfloUsageUrl = await getAmberfloUsageUrl({
            prodTenantAppId: tenantResponse[0]?.apps[0]?.id
        });
        setUsageInfo((prevUsageInfo) => {
            return {
                ...prevUsageInfo,
                amberfloUsageUrl,
            }
        });
    }

    useEffect(() => {
        if (currentTab === Developers) {
            getUsageData().then(() => {
            });
        }
    }, [currentTab]);

    useEffect(() => {
        if (!isEmpty(userProfile?.user?.email)) {
            setUsageInfo((prevUsageInfo) => {
                return {
                    ...prevUsageInfo,
                    email: userProfile?.user?.email,
                }
            });
        }
    }, [userProfile]);

    const dropDownKeysBasedOnCurrentTab = {
        Creators: [
            {
                icon: <Icons.staging_circle/>,
                key: ENVIRONMENT.STAGING,
                tooltip: "Connect real accounts and fetch real data for internal development.",
            },
            {
                icon: <Icons.prod_circle/>,
                key: ENVIRONMENT.PRODUCTION,
                tooltip: "Manage live user accounts and your insightIQ usage.",
            },
        ],
        Developers: [
            {
                icon: <Icons.sandbox_circle/>,
                key: ENVIRONMENT.SANDBOX,
                tooltip: "Explore insightIQ APIs with dummy data.",
            },
            {
                icon: <Icons.staging_circle/>,
                key: ENVIRONMENT.STAGING,
                tooltip: "Connect real accounts and fetch real data for internal development.",
            },
            {
                icon: <Icons.prod_circle/>,
                key: ENVIRONMENT.PRODUCTION,
                tooltip: "Manage live user accounts and your insightIQ usage.",
            },
        ],
    };

    // Sidebar bottom menus based on current module
    const sidebarMenusBasedOnCurrentModule = {
        Creators: [
            {
                title: SIDEBAR_MENUS.TALK_TO_US,
                event: "",
                linkToRedirect: MEETING_SCHEDULE_LINK,
                icon: <Icons.schedule_call_icon/>,
                isLinkIconPresent: false,
                isPopUpRequired: true,
            },
            {
                title: SIDEBAR_MENUS.FOR_DEVELOPERS,
                event: DEVELOPER_CLICKED,
                linkToRedirect: "/developers",
                icon: <Icons.code_icon/>,
                isLinkIconPresent: true,
                isPopUpRequired: false,
            },
            {
                title: SIDEBAR_MENUS.YOUR_ACCOUNT,
                event: "",
                linkToRedirect: "",
                icon: <Icons.account_icon/>,
                isLinkIconPresent: false,
                isPopUpRequired: true,
                isActive: true,
            },
        ],
        Developers: [
            {
                title: SIDEBAR_MENUS.DOCS,
                event: "",
                linkToRedirect: INSIGHTIQ_DOCS_LINK,
                icon: <Icons.docs_icon/>,
                isLinkIconPresent: true,
                isPopUpRequired: false,
            },
            {
                title: SIDEBAR_MENUS.USAGE,
                event: "",
                icon: <Icons.line_chart/>,
                isLinkIconPresent: true,
                isPopUpRequired: false,
            },
            {
                title: SIDEBAR_MENUS.API_STATUS,
                event: "",
                linkToRedirect: INSIGHTIQ_API_STATUS,
                icon: <Icons.api_status/>,
                isLinkIconPresent: true,
                isPopUpRequired: false,
            },
            {
                title: SIDEBAR_MENUS.FOR_MARKETERS,
                event: MARKETER_CLICKED,
                linkToRedirect: "/creators",
                icon: <Icons.marketers_icon/>,
                isLinkIconPresent: true,
                isPopUpRequired: false,
            },
            {
                title: SIDEBAR_MENUS.YOUR_ACCOUNT,
                event: "",
                linkToRedirect: "",
                icon: <Icons.account_icon/>,
                isLinkIconPresent: false,
                isPopUpRequired: true,
                isActive: true,
            },
        ],
    };

  const getOrderedCreatorProducts = (creatorTabs) => {
    const primaryProducts = [];
    const secondaryProducts = [];
    creatorTabs.map((item) => {
      if (tenantAppProducts[item.productId] !== TRIAL) primaryProducts.push(item);
      else secondaryProducts.push(item);
    });
    // If no products is paid then set discovery as default
    if (primaryProducts.length === 0)
      return [
        ...secondaryProducts.filter((item) => item.productId === creatorSearchProductID),
        ...secondaryProducts.filter((item) => item.productId !== creatorSearchProductID),
      ];
    return [...primaryProducts, ...secondaryProducts];
  };
    // key in this object should be as per the url routes defined.
    const tabs = {
        // The products in the order of the precedence of Creator Products
        Creators: [
            {
                label: "Creator search",
                key: "creator-search",
                icon: <Icons.creator_icon/>,
                productId: creatorSearchProductID,
            },
            {
                label: "Creator onboarding",
                key: "connected-accounts",
                icon: <Icons.creator_discovery_icon/>,
                productId: creatorLinkageProductID,
            },
            {
                label: "Campaigns",
                key: "campaigns",
                icon: <Icons.campaign_icon/>,
                productId: campaignTrackingProductID,
            },
        ],
        Developers: [
            {
                label: "Products",
                key: "products",
                icon: <Icons.products_icon/>,
            },
            {
                label: "API keys",
                key: "api-credentials",
                icon: <Icons.api_credentials_icon/>,
            },
            {
                label: "Webhooks",
                key: "webhooks",
                icon: <Icons.webhook_icon/>,
            },
            {
                label: HELP_LABEL,
                key: "support-and-sales",
                icon: <Icons.help_question_mark_icon/>,
            },
        ],
    };

    useEffect(() => {
        const pathName = location.pathname.slice(1);
        setActiveTab(pathName);
    }, [location]);

    const stagingInfoModal = {
        title: "You are in staging environment",
        content: ` <p>✦  Access <b>25 live account</b> connections.</p>
                  <p>✦  Connect <b>real accounts</b> and fetch real data.</p>
                  <p>✦  Use staging for <b>internal development</b> and testing.</p>
                  <p>✦  <b>Don't use</b> staging credentials in actual production environment.</p>`,
        buttonLabel: "Got it!",
        closable: false,
    };

    const afterSubmitFormModal = {
        title: "Thank you for applying for production access to insightIQ APIs!",
        content: ` <p >✦  Our <b>team will be in touch</b> with you shortly.</p>
                  <p >✦  <b>Got questions?</b> Email <a href="mailto:support@insightiq.ai">
                  <u>support@insightiq.ai.</u>
                </a></p>
                  <p >✦  <b>Need product guidance?</b> <a style={{color: "#2262ED"}}
                  href=${MEETING_SCHEDULE_LINK}
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>Book a meeting.</u>
                </a>`,
        buttonLabel: "Got it!",
        closable: true,
    };

    const dispatchSelected = (env) => {
        env = env.toLowerCase();
        let modalObj = getModal(stagingInfoModal);
        let prevEnv = warehouse.environment.current;
        let currEnv = env;
        // TODO: to remove once dropdown has new design
        if (isCreatorDiscoveryActive && env === Staging) {
            return;
        }

        if (env === Staging) {
            if (currentTab === Developers) {
                // !isSalesModalOpen && Modal.info(modalObj);
                setIsSalesModalOpen(true);
            }
        }

        if (env === Production) {
            if (currentTab === Developers && !productionKeys.isAvailable) {
                env = prevEnv;
                setShowSalesCard(true);
            }
        }
        if (env === Staging) {
            setShowSalesCard(true);
        }
        if (env === Sandbox) setShowSalesCard(false);
        dispatchWarehouse({ type: ADD_ENVIRONMENT, payload: env });
        dispatchWarehouse({
            type: UPDATE_ENVIRONMENT_BASE_URL,
            payload: getEnvBaseURL(env),
        });
        dispatchAnalytics({
            type: UPDATE_ANALYTICS,
            payload: {
                environment: env,
            },
        });
        sessionStorage.setItem(CURR_SELECTED_ENV_KEY, env)
        trackEvent(ENVIRONMENT_CLICKED, {
            from_environment: prevEnv,
            to_environment: currEnv,
        });
    };
    const navigate = useNavigate();

  // Default dropdown selected environment for creator module is Staging
  const setDefaultSelectedEnvironmentForCreatorModule = (env) => {
    dispatchWarehouse({ type: ADD_ENVIRONMENT, payload: env });
    dispatchWarehouse({
      type: UPDATE_ENVIRONMENT_BASE_URL,
      payload: getEnvBaseURL(env),
    });
    dispatchAnalytics({
      type: UPDATE_ANALYTICS,
      payload: {
        environment: env,
      },
    });
    return env;
  };

    const setActiveClassName = () => {
        var elements = document.getElementsByClassName("sidebar-bottom-menu");
        if (elements[elements.length - 1].classList.contains("active")) {
            elements[elements.length - 1].className = elements[elements.length - 1].className.replace(" active", "");
            setIsLogOutPopoverOpen(false);
        } else {
            elements[elements.length - 1].className += " active";
            setIsLogOutPopoverOpen(true);
        }
    };

    return (
        <>
            <Sider width={225} className="site-layout-sider">
                <div className="side-bar-container">
                    <div
                        className={(!headerInfo.showHeaderInfo || headerInfo.isHeaderScrollable) && "side-bar-top-container"}>
                        {currentTab === Developers && (
                            <div className="dropDownContainer">
                                <EnvironmentSelectionDropdown
                                    environmentList={dropDownKeysBasedOnCurrentTab[currentTab]}
                                    selectedEnvironment={selectedEnv.charAt(0).toUpperCase() + selectedEnv.slice(1).toLowerCase()}
                                    setSelectedEnvironment={dispatchSelected}
                                    currentTab={currentTab}
                                    isComplianceFormFilled={isComplianceFormFilled}
                                    isProductionKeysAvailable={productionKeys.isAvailable}
                                    showCreatorLinkage={warehouse.showCreatorLinkageModule}
                                    showCreatorSearch={warehouse.showCreatorSearchModule}
                                />
                            </div>
                        )}
                        <Menu
                            className="side-bar-menu"
                            mode="inline"
                            style={{
                                marginTop: currentTab === Developers ? "32px" : "60px",
                            }}
                        >
                            {tabs[currentTab].map((menuTab) => {
                                return (
                                    <Menu.Item
                                        onClick={() => {
                                            trackEvent(MAINMENU_CLICKED, {
                                                menu_item: menuTab.label,
                                            });
                                            navigate(menuTab.key);
                                        }}
                                        key={menuTab.title}
                                        className={`menu-item-on-sider ${activeTab.includes(menuTab.key) ? "ant-menu-item-selected" : ""}`}
                                    >
                                        <div className="side-bar-menu-item">
                                            <Box display={"flex"} alignItems={"center"}>
                                                {menuTab.icon}
                                            </Box>
                                            <span className="sider-menu-item-title">{menuTab.label}</span>
                                        </div>
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    </div>
                    <div style={{ marginBottom: "40px" }}>
                        <div className="sidebar-bottom-menu-wrapper">
                            {sidebarMenusBasedOnCurrentModule[currentTab].map((menu) => {
                                if (menu.title === SIDEBAR_MENUS.USAGE && isEmpty(usageInfo?.amberfloUsageUrl)) {
                                    return null;
                                }
                                return (
                                    <div
                                        className="sidebar-bottom-menu"
                                        onClick={() => {
                                            if (menu.isActive) {
                                                setActiveClassName();
                                            }
                                            trackEvent(MAINMENU_CLICKED, {
                                                menu_item: menu.title,
                                            });
                                            (menu.title === SIDEBAR_MENUS.YOUR_ACCOUNT) && trackEvent(
                                                ACCOUNT_CLICKED, { email: userProfile?.user?.email });
                                        }}
                                    >
                                        <div className="sidebar-bottom-menu-item">
                                            <LinkText
                                                title={menu.title}
                                                event={menu.event}
                                                to={menu.linkToRedirect}
                                                isLinkIconPresent={menu.isLinkIconPresent}
                                                isPopUpRequired={menu.isPopUpRequired}
                                                isActive={isLogOutPopoverOpen}
                                                setIsActive={() => {
                                                    setIsLogOutPopoverOpen(false);
                                                }}
                                                usageInfo={usageInfo}
                                            >
                                                {menu.icon}
                                            </LinkText>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Sider>
        </>
    );
}
