import { isEmpty } from "../../utils/util";
import { cgfAPIPrefix, currentDashboardURL, URL_GET_RECENT_COMPARE_CREATORS } from "../endpoints";
import axiosInstance from "../axiosInstance";

async function getCompareCreatorData({ limit, offset, sort_desc, searchString, sort_by }) {
  const url = new URL(`${currentDashboardURL}${cgfAPIPrefix}/${URL_GET_RECENT_COMPARE_CREATORS}`);
  !isEmpty(searchString) && url.searchParams.append("search_string", searchString);
  !isEmpty(sort_by) && url.searchParams.append("sort_by", String(sort_by).toUpperCase());
  !isEmpty(sort_desc) && url.searchParams.append("sort_desc", Boolean(sort_desc).toString());
  !isEmpty(limit) && url.searchParams.append("limit", limit);
  !isEmpty(offset) && url.searchParams.append("offset", offset);
  try {
    const response = await axiosInstance.get(url.href);
    return response.data;
  } catch (error) {
    throw error.message;
  }
};

async function deleteCompareCreatorsLists({ deleteCreatorId }) {
  const url = new URL(`${currentDashboardURL}${cgfAPIPrefix}/${URL_GET_RECENT_COMPARE_CREATORS}/${deleteCreatorId}`);
  const response = await axiosInstance.delete(url.href);
  if (response.status === 201) {
    return response.data;
  }
}

async function postCompareCreators({ workPlatformID, platform_usernames }) {
  try {
    const url = new URL(`${currentDashboardURL}${cgfAPIPrefix}/${URL_GET_RECENT_COMPARE_CREATORS}`);
    const response = await axiosInstance.post(url.href, {
      work_platform_id: workPlatformID,
      platform_usernames: platform_usernames,
    });
    if (response.status === 202) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (error) {
    return error?.response?.data?.error;
  }
};

async function postCompareCreatorSampleReport() {
  try {
    const url = new URL(`${currentDashboardURL}${cgfAPIPrefix}/${URL_GET_RECENT_COMPARE_CREATORS}/sample-report`);
    const response = await axiosInstance.post(url.href);
    if (response.status === 202) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (error) {
    return error?.response?.data?.error;
  }
};


async function getCreatorComparisonById({ comparisonId }) {
  const url = new URL(`${currentDashboardURL}${cgfAPIPrefix}/${URL_GET_RECENT_COMPARE_CREATORS}/${comparisonId}`);
  try {
    const response = await axiosInstance.get(url.href);
    return response;
  } catch (error) {
    throw error.message;
  }
};

async function exportCreatorComparisonData({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const options = {
      headers: headers,
    };
    const url = new URL(`${currentDashboardURL}${cgfAPIPrefix}/${URL_GET_RECENT_COMPARE_CREATORS}/export`);
    const response = await axiosInstance.post(url.href, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return response.error;
    }
  } catch (e) {
    throw e;
  }
};

export {
  getCompareCreatorData,
  deleteCompareCreatorsLists,
  postCompareCreators,
  getCreatorComparisonById,
  postCompareCreatorSampleReport,
  exportCreatorComparisonData
};