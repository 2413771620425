import { Avatar, Link, Skeleton, Tooltip } from "@mui/material";
import { Checkbox } from "antd";
import React, { useEffect, useRef, useState, useContext, useLayoutEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  CreateListPopUp,
  CreatorListSidebar,
  CustomDropDown,
  EmptyTableScreen,
  ExportCSVPopUp,
  FeaturedSearchCard,
  FiltersOverlay,
  FilterTags,
  FilterWrapper,
  Icons,
  InvertedPrimaryButton,
  Loader,
  MainBanner,
  ModalDialog,
  PlatformSelectionDropdown,
  PrimaryButton,
  RecentSearchCard,
  SearchableCheckBoxModal,
  TabSwitch,
  ToastMessage,
  UnlockFullAccessPopUp,
} from "../../components";
import ExportResultOverlay from "../../components/ExportResultOverlay/ExportResultOverlay";
import { CreatorDiscoveryWarehouseContext, WarehouseContext } from "../../storage/context";
import renderFilter, {
  deleteAllQueryParamsAndFilterPayload,
  updateFilterTags,
  upsertPayloadFromQueryParams,
  upsertPlatformToQueryParams,
  createSearchPayloadFromFiltersPayload,
  createQueryParamsFromSearchPayload,
} from "./CreatorDiscoveryPageUtils";
import {
  convertToShortNumber,
  getSearchableDropdownOptionsForBrands,
  getSearchableDropdownOptionsForInterest,
  getSearchableDropdownOptionsForLanguages,
  isEmpty,
  handleHeightandWidthOfCardImage,
  workPlatformsDropdownOptions,
  commaReplacer,
  compareCreatorWorkPlatformsDropdownOptions,
} from "../../utils/util";
import {
  getPageTitle,
  SEARCHED_RESULTS_RECORDS_LIMIT,
  PERMITTED_FILTER_SORTING_MAPPING,
  WORKPLATFORM_ID_MAPPING,
  creatorSearchProductID,
  TRIAL,
  DEFAULT_CREATOR_IDENTIFIER,
  DONT_DELETE,
  CREATOR_PROFILE_LINK,
  WORKPLATFORMS,
  creatorSearchTabs,
  creatorSearchTabLabels,
  compareCreatorProductID,
} from "../../constants/constants";
import { Box, Typography } from "@mui/material";
import "./CreatorDiscoveryPage.scss";
import { convertDate, formatNumber, percentFormatter } from "../../utils/profileHelpers";
import { useFetchCreatorDiscovery } from "../../hooks/useFetchCreatorDiscovery";
import { TableListingRecords, SearchCardSkeleton, TableSearchSkeleton } from "../../components";
import intendToBuyGif from "../../components/Icons/intent_to_buy_dial.gif";
import intendToBuyPic from "../../components/Icons/intent_to_buy_dial.jpg";
import "./CreatorDiscoveryPage.scss";
import {
  ADD_USAGE_QUOTA,
  BASIC_DETAILS_OF_SELECTED_PROFILE,
  HIDE_TOP_HEADER,
  SET_LAST_INTERACTED_FILTER_NULL,
  SET_SHOULD_RENDER_FALSE,
  SET_SHOULD_RENDER_TRUE,
  SET_SHOULD_TAGS_RENDER_FALSE,
  SHOW_TOP_HEADER,
  UPDATE_CREATOR_LISTS_SELECTED_CREATORS,
  UPDATE_LAST_INTERACTED_FILTER,
} from "../../storage/reducers/types";
import ProfileSearchPage from "../ProfileSearchPage/ProfileSearchPage";
import {
  createList,
  deleteCreatorsFromFavourites,
  deleteCreatorsFromLists,
  deleteCreatorsLists,
  getAllCreatorList,
  getAllCreatorsInList,
  getFeaturedSearches,
  getRecentSearches,
  removeRecentSearchCard,
} from "../../api/creatorDiscoveryAPI/creatorDiscoveryAPI";
import { creatorDiscoveryAPI } from "../../api";
import {
  SEARCH_MORE_FILTERS_CLICKED,
  SEARCH_PAGE_OPENED,
  SEARCH_PLATFORM_CHANGED,
  SEARCH_RESULTS_VIEWED,
  SEARCH_FILTERS_SEARCH_SELECTED,
  SEARCH_MORE_RESULTS_LOADED,
  SEARCH_PLATFORM_PROFILE_VIEWED,
  SEARCH_PROFILE_SEARCH_SELECTED,
  trackEvent,
  SEARCH_PROFILE_VIEWED,
  CHECK_PURCHASE_INTENT_CLICKED,
  PURCHASE_INTENT_BANNER_CLICKED,
  YOUR_LIST_CLICKED,
  LIST_DELETED,
  CREATOR_DELETED,
  LIST_SEARCHED,
} from "../../analytics";
import { tableErrorMessages } from "../../constants/errorMessages";
import { Helmet } from "react-helmet";
import { isArray } from "lodash";
import { Colors } from "../../styles/colors";
import { getTopHeaderNavTextAsPerUsage } from "../../utils/tenantProductHelpers";
import { useFetchTenantUsageInfo } from "../../hooks/useFetchTenantUsageInfo";
import {
  TOPIC_RELEVANCE_FILTER_NAME,
  DESCRIPTION_KEYWORDS,
  DESCRIPTION_KEYWORDS_LABEL,
  SEARCH_RESULTS_TABLE_OFFSET,
  SEARCH_RESULTS_LIMIT,
  SEARCH_RESULTS_LOAD_MORE_LIMIT,
  SEARCH_RESULTS_LOAD_MORE_TABLE_OFFSET,
  DEFAULT_SELECTED_FILTERS,
  PURCHASE_INTENT_TOOLTIP_TEXT,
  DEFAULT_SEARCH,
  FILTER_BASED_SEARCH,
  getUniqueElements,
  TOPIC_RELEVANCE_FILTER_DISPLAY_NAME,
  TOPIC_RELEVANCE_FILTER_SORTING_OPTION,
  ADD_TO_LIST_TOOLTIP_TEXT,
  LISTS_TOOLTIP_TEXT,
  CREATE_LIST_POPUP_ACTIONS_LABEL,
  favourite,
  confirmDelete,
  YOUR_CREATOR_LISTS_TITLE,
  ADD_CREATOR_TO_LIST_TITLE,
  ADD_LIST_TOAST_MESSAGE_DURATION,
  CREATE_LIST_POPUP_HEADER,
  yesRemove,
  dontRemove,
  REEL_VIEWS,
  REELS_VIEWS,
  TOAST_MESSAGE_DELAY_DURATION,
  unlockPopupDetails,
  CONTACT_US
} from "../../constants/creatorDiscovery";
import { yourCreatorListsTitle } from "../../constants/campaignTrackingConstants";
import CompareCreatorsPage from "../CompareCreatorsPage/CompareCreatorsPage";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CreatorDiscoveryPage = () => {
  const columns = [
    {
      dataIndex: "key",
      key: "key",
      title: (record) => (
        <Checkbox
          checked={titleCheckboxChecked}
          onClick={(e) => {
            e.stopPropagation();
            const trElements = document.querySelectorAll(".ant-table-row");
            trElements.forEach((trElement) => {
              if (titleCheckboxChecked) {
                if (trElement.classList.contains("active-row")) {
                  trElement.className = trElement.className.replace(" active-row", "");
                } else {
                  trElement.className += " active-row";
                }
                trElement.className = trElement.className.replace(" active-row", "");
              } else {
                trElement.className += " active-row";
              }
            });
            const newSelectedRowKeys = titleCheckboxChecked
              ? []
              : searchedProfileData.slice(0, searchTableOffset + SEARCHED_RESULTS_RECORDS_LIMIT).map((record) => record.key);
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(
              newSelectedRowKeys.map((key) =>
                searchedProfileData.slice(0, searchTableOffset + SEARCHED_RESULTS_RECORDS_LIMIT).find((record) => record.key === key)
              )
            );
            setTitleCheckboxChecked(!titleCheckboxChecked);
          }}
        />
      ),
      width: 35,
      // fixed: "left",
      render: (text, record) => {
        let isCheckboxEnabledForRow = selectedRowKeys.findIndex((key) => key === record.key) !== -1;
        // if (titleCheckboxChecked || isCheckboxEnabledForRow) {
        return (
          <Checkbox
            checked={isCheckboxEnabledForRow}
            onClick={(e) => {
              e.stopPropagation();
              const trElement = document.querySelector(`tr[data-row-key="${record.key.toString()}"]`);
              if (isCheckboxEnabledForRow) {
                trElement.className = trElement.className.replace(" active-row", "");
              } else {
                trElement.className += " active-row";
              }
              const selectedRowKeyIndex = selectedRowKeys.indexOf(record.key);
              let newSelectedRowKeys = [];

              if (selectedRowKeyIndex === -1) {
                newSelectedRowKeys = [...selectedRowKeys, record.key];
              } else {
                newSelectedRowKeys = selectedRowKeys.filter((key) => key !== record.key);
              }

              setSelectedRowKeys(newSelectedRowKeys);
              setSelectedRows(
                newSelectedRowKeys.map((key) =>
                  searchedProfileData.slice(0, searchTableOffset + SEARCHED_RESULTS_RECORDS_LIMIT).find((record) => record.key === key)
                )
              );
            }}
          />
        );
        // }
        // return record.key;
      },
      align: "center",
    },
    {
      dataIndex: "account",
      key: "account",
      title: "Creator",
      // fixed: "left",
      width: 200,
    },
  ];
  const [filtersOverlayOpen, setFiltersOverlayOpen] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(workPlatformsDropdownOptions[0]?.value);
  const [creatorsFilters, setCreatorsFilters] = useState([]);
  const [audienceFilters, setAudienceFilters] = useState([]);
  const [performanceFilters, setPerformanceFilters] = useState([]);
  const [superPrimaryFilters, setSuperPrimaryFilters] = useState([]);
  const [primaryFilters, setPrimaryFilters] = useState([]);
  const [overlayLoading, setOverlayLoading] = useState(false);
  const [sourceOfFilters, setSourceOfFilters] = useState();
  const [tileCount, setTileCount] = useState();
  const onlyOnce = useRef(true);
  let [filterTags, setFilterTags] = useState({});

  const CREATOR_SEARCH_TABLE_ICONS = {
    Instagram: {
      icon: <Icons.insta_table_icon />,
    },
    YouTube: {
      icon: <Icons.youtube_table_icon />,
    },
    TikTok: {
      icon: <Icons.tiktok_table_icon />,
    },
  };

  let currentTab = JSON.parse(sessionStorage.getItem("search-results"))?.currentTab || creatorSearchTabs.filters;
  const [searchType, setSearchType] = useState(currentTab);
  const navigate = useNavigate();
  // const [mockWarehouse, setMockWarehouse] = useState({
  //   "Account Type": "CREATOR",
  // });
  const { creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse } = useContext(CreatorDiscoveryWarehouseContext);
  const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
  const filters = creatorDiscoveryWarehouse.filters;
  const [selectedPlatformID, setSelectedPlatformID] = useState(workPlatformsDropdownOptions[0].id);
  const [hoveredRowKey, setHoveredRowKey] = useState(null);
  const [searchedProfileData, setSearchedProfileData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [titleCheckboxChecked, setTitleCheckboxChecked] = useState(false);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [isSecondaryfiltersLoaded, setIsSecondaryfiltersLoaded] = useState(false);
  const moreFilterButtonRef = useRef(null);
  const fetchCreatorDiscovery = useFetchCreatorDiscovery();
  const [recentSearchTagsResponse, setRecentSearchTagsResponse] = useState([]);
  const [featuredSearchTagsResponse, setFeaturedSearchTagsResponse] = useState([]);
  const [cardsLoading, setCardsLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [moreResultsLoading, setMoreResultsLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [interests, setInterests] = useState([]);
  const [brands, setBrands] = useState([]);
  const [totalSearchedResults, setTotalSearchedResults] = useState(
    JSON.parse(sessionStorage.getItem("search-results")) ? JSON.parse(sessionStorage.getItem("search-results")).totalSearchedResults : 0
  );
  const [searchedProfileResponse, setSearchedProfileResponse] = useState([]);
  const [searchedPurchaseIntentList, setSearchedPurchaseIntentList] = useState([]);
  const [showTableErrorScreen, setShowTableErrorScreen] = useState({ flag: false, status: 200 });
  const [searchTableOffset, setSearchTableOffset] = useState(0);
  const [activeRowKey, setActiveRowKey] = useState(null);
  const [searchTableSortState, setSearchTableSortState] = useState({
    field: "AVERAGE_LIKES",
    order: "DESCENDING",
  });
  const [hideLoadMoreResultsButton, setHideLoadMoreResultsButton] = useState(true);
  const onlyOnceForCreatingPayload = useRef(true);
  const [showTable, setShowTable] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState({
    flag: false,
    sort_by: searchTableSortState,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const fetchTenantUsageInformation = useFetchTenantUsageInfo();
  const [isTrialMode, setIsTrialMode] = useState(false);
  const [sortingOptions, setSortingOptions] = useState([]);
  const [isExportOverlaySticky, setIsExportOverlaySticky] = useState(false);
  const [exportOverlayHeight, setExportOverlayHeight] = useState(38);
  const [createListPopUpState, setCreateListPopUpState] = useState({
    visible: false,
    isVerified: false,
    full_name: "",
    image_url: "",
    isCustomListCreated: false,
    favoriteListCount: 0,
    customList: [],
    platform_username: "",
    profile_url: "",
    workplatform_id: "",
    defaultSelectedList: [],
    isMultipleCreatorSelected: false,
    multipleSelectedCreators: [],
    isCreatorAlreadyPresentInList: false,
  });
  const [isCreatorDrawerOpen, setIsCreatorDrawerOpen] = useState(false);
  const [creatorList, setCreatorList] = useState([]);
  const [filteredCreatorList, setFilteredCreatorList] = useState([]);
  const [creatorListLoading, setCreatorListLoading] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState({
    isModalOpen: false,
    deleteIDs: {},
  });
  const [modalDetails, setModalDetails] = useState({
    isModalVisible: false,
    modalInfo: "",
  });

  const [openToast, setOpenToast] = useState({
    open: false,
    message: "",
    duration: ADD_LIST_TOAST_MESSAGE_DURATION,
    type: "",
  });
  const [totalFetchedRecords, setTotalFetchedRecords] = useState(0);

  const handleLanguageAPI = async () => {
    let apiResponse = await getSearchableDropdownOptionsForLanguages({ warehouse });
    return apiResponse;
  };
  const handleInterestAPI = async () => {
    let apiResponse = await getSearchableDropdownOptionsForInterest({ warehouse });
    return apiResponse;
  };

  const handleBrandAPI = async () => {
    let apiResponse = await getSearchableDropdownOptionsForBrands({ warehouse });
    return apiResponse;
  };

  const formatValue = (value) => {
    if (!value) return "";
    return value.toLocaleString("en-US");
  };

  const getFiltersBasedOnPlatform = (platform) => {
    let filters = [];
    filters = creatorDiscoveryWarehouse.work_platforms[platform]["filters"];
    return filters;
  };

  const renderFilterTags = () => {
    let filtersIdentifier = getFiltersBasedOnPlatform(workPlatformsDropdownOptions.filter((option) => option.value === selectedPlatform)[0].id);

    setFilterTags({});
    for (let filter of filtersIdentifier) {
      const filterObj = creatorDiscoveryWarehouse.filters[filter.identifier];

      if (filterObj && filterObj.payload && "tags" in filterObj.payload) {
        let tag = {
          type: filterObj.type,
          filter: filter.identifier,
          tagValue: filterObj.payload?.tags,
          filterValue: filterObj.payload?.searchPayload,
          category: filterObj.category,
          filterName: filterObj.display_name,
          filterType: filter.is_primary || filter.is_super_primary ? "primary" : "secondary",
        };
        setFilterTags((prev) => {
          return {
            ...prev,
            [filter.identifier]: tag,
          };
        });
      }
    }
  };

  const loadSecondaryFilters = async (filtersIdentifier) => {
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    const queryParams = Object.fromEntries(searchParams);

    setOverlayLoading(true);

    // const languages = await handleLanguageAPI();
    // const interests = await handleInterestAPI();
    // const brands = await handleBrandAPI();

    const filters = creatorDiscoveryWarehouse.filters;
    // updateFilterTags(queryParams, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse);
    setIsSecondaryfiltersLoaded(true);
    setOverlayLoading(false);
    for (let filter_id of filtersIdentifier) {
      const filter_name = filter_id.identifier;
      renderFilter({
        type: filters[filter_name].type,
        metadata: filters[filter_name].meta_data,
        category: filters[filter_name].category,
        name: filters[filter_name].display_name,
        description: filters[filter_name].description,
        setCreatorsFilters: setCreatorsFilters,
        setAudienceFilters: setAudienceFilters,
        setPerformanceFilters: setPerformanceFilters,
        setPrimaryFilters: null,
        languages: languages,
        interests: interests,
        brands: brands,
        payload: filters[filter_name].payload,
        key: filter_name,
        primaryFiltersIdentifier: null,
        creatorDiscoveryWarehouse: creatorDiscoveryWarehouse,
        dispatchCreatorDiscoveryWarehouse: dispatchCreatorDiscoveryWarehouse,
        selectedPlatformID: selectedPlatformID,
        warehouse,
      });
    }
  };

  const handleRenderFilters = async (selectedPlatform) => {
    if (isEmpty(selectedPlatform)) {
      return;
    }
    //iterate over filters object
    setCreatorsFilters([]);
    setAudienceFilters([]);
    setPerformanceFilters([]);
    setPrimaryFilters([]);
    setSuperPrimaryFilters([]);
    let filtersIdentifier = getFiltersBasedOnPlatform(workPlatformsDropdownOptions.filter((option) => option.value === selectedPlatform)[0].id);
    // {identifier: 'follower_count', is_primary: true}
    // Super primary filters - sync
    let superPrimaryFiltersIdentifier = filtersIdentifier.filter((filter) => filter.is_super_primary).map((filter) => filter.identifier);
    // Special handling for description_keywords in SuperPrimaryFilters
    // superPrimaryFiltersIdentifier.push(DESCRIPTION_KEYWORDS);

    for (let filter_name of superPrimaryFiltersIdentifier) {
      renderFilter({
        type: filters[filter_name].type,
        metadata: filters[filter_name].meta_data,
        category: filters[filter_name].category,
        name: filters[filter_name].display_name,
        description: filters[filter_name].description,
        setCreatorsFilters: null,
        setAudienceFilters: null,
        setPerformanceFilters: null,
        setPrimaryFilters: setSuperPrimaryFilters,
        languages: languages,
        interests: interests,
        brands: brands,
        payload: filters[filter_name].payload,
        key: filter_name,
        primaryFiltersIdentifier: superPrimaryFiltersIdentifier,
        creatorDiscoveryWarehouse: creatorDiscoveryWarehouse,
        dispatchCreatorDiscoveryWarehouse: dispatchCreatorDiscoveryWarehouse,
        selectedPlatformID: workPlatformsDropdownOptions.filter((option) => option.value === selectedPlatform)[0].id,
        warehouse,
      });
    }
    // Special handling for description_keywords in PrimaryFilters - removing from here
    let primaryFiltersIdentifier = filtersIdentifier.filter((filter) => filter.is_primary).map((filter) => filter.identifier);

    // primary filters - sync
    for (let filter_name of primaryFiltersIdentifier) {
      renderFilter({
        type: filters[filter_name].type,
        metadata: filters[filter_name].meta_data,
        category: filters[filter_name].category,
        name: filters[filter_name].display_name,
        description: filters[filter_name].description,
        setCreatorsFilters: null,
        setAudienceFilters: null,
        setPerformanceFilters: null,
        setPrimaryFilters: setPrimaryFilters,
        languages: languages,
        interests: interests,
        brands: brands,
        payload: filters[filter_name].payload,
        key: filter_name,
        primaryFiltersIdentifier: primaryFiltersIdentifier,
        creatorDiscoveryWarehouse: creatorDiscoveryWarehouse,
        dispatchCreatorDiscoveryWarehouse: dispatchCreatorDiscoveryWarehouse,
        selectedPlatformID: workPlatformsDropdownOptions.filter((option) => option.value === selectedPlatform)[0].id,
        warehouse,
      });
    }

    // secondary filters - async
    loadSecondaryFilters(filtersIdentifier);
  };

  const handleTrialMode = (isModalVisible,modalInfo) => {
    setModalDetails({
      ...modalDetails,
      isModalVisible: isModalVisible,
      modalInfo: modalInfo,
    });
  };

  // Function that generates the dynamic columns for multi-select filter
  const getTableColumnsWithMultipleOccurences = (data, dynamicColumnToBeAdded) => {
    // Set to store the unique selected-filter labels
    const filterLabels = new Set();
    let isArrayTypeFilter = false;
    for (let record of data) {
      // Condition to check if the selected-filter is inside the filter_match object
      if (!record[dynamicColumnToBeAdded]) {
        // Condition to check if the selected-filter is an array of objects
        if (record?.filter_match && record?.filter_match[dynamicColumnToBeAdded]) {
          if (Array.isArray(record?.filter_match[dynamicColumnToBeAdded])) {
            for (let key in record?.filter_match[dynamicColumnToBeAdded]) {
              filterLabels.add(record?.filter_match[dynamicColumnToBeAdded][key]?.label);
              isArrayTypeFilter = true;
            }
          } else {
            // Adding the object type filter
            filterLabels.add(record?.filter_match[dynamicColumnToBeAdded]?.label);
            isArrayTypeFilter = true;
          }
        }
      } else {
        // Adding the string type filter
        filterLabels.add(filters[dynamicColumnToBeAdded].display_name);
      }
    }
    // Return the unique selected-filter labels
    return isArrayTypeFilter ? Array.from(filterLabels) : Array.from(filterLabels).join("");
  };

  const constructDynamicTableColumns = (dynamicColumnToBeAdded, data, clearExistingSearchObj = false, sortBy = {}, purchaseIntentList) => {
    let dynamicColumns = [];
    let searchedData = [];
    let searchedPurchaseIntentData = [];
    const sortByState = isEmpty(sortBy) ? Object.create(searchTableSortState) : Object.create(sortBy);
    let matchFilterDynamicColumns;
    // This condition is added for youtube for making it Subscriber_Count sorting work
    if (sortByState.field === PERMITTED_FILTER_SORTING_MAPPING.FOLLOWER_COUNT && selectedPlatformID === "14d9ddf5-51c6-415e-bde6-f8ed36ad7054")
      sortByState.field = PERMITTED_FILTER_SORTING_MAPPING.SUBSCRIBER_COUNT;
    for (let filter_name of dynamicColumnToBeAdded) {
      matchFilterDynamicColumns = getTableColumnsWithMultipleOccurences(data, filter_name);
      if (isArray(matchFilterDynamicColumns)) {
        // Loop through the multiple selected array-type and object-type filters
        for (let column of matchFilterDynamicColumns) {
          dynamicColumns.push({
            title: () => {
              return sortByState.field !== filter_name.toUpperCase() ? (
                PERMITTED_FILTER_SORTING_MAPPING.hasOwnProperty(filter_name.toUpperCase()) ? (
                  <Tooltip title={column} placement="top">
                    <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "end" }}>
                      <div id="match-filter">{column}</div>
                      <Icons.down_arrow_icon alt="down_arrow_icon" />
                    </div>
                  </Tooltip>
                ) : filter_name === "purchase_intent_comment_count" ? (
                  <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "end" }}>
                    {filters[filter_name].display_name}{" "}
                    <Tooltip title={PURCHASE_INTENT_TOOLTIP_TEXT} placement="top-end">
                      <Box>
                        <i class="ri-information-line"></i>
                      </Box>
                    </Tooltip>
                  </div>
                ) : (
                  <Tooltip title={column} placement="top">
                    <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "end" }}>
                      <div id="match-filter">{column}</div>
                    </div>
                  </Tooltip>
                )
              ) : (
                <Tooltip title={column} placement="top">
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "5px", color: "var(--main-primary)", justifyContent: "end" }}
                    className="active-header"
                  >
                    <div id="match-filter">{column}</div>
                    <i className="ri-arrow-down-line"></i>
                  </div>
                </Tooltip>
              );
            },
            dataIndex: column,
            key: column,
            width: 200,
            render: (text, record) => {
              if (filters[filter_name].value_type === "number") {
                return text ? convertToShortNumber(commaReplacer(text)) : "-";
              } else if (filters[filter_name].value_type === "percentage") {
                return <div>{text ? parseFloat(commaReplacer(text)).toFixed(2) + "%" : "-"}</div>;
              }
              return text ? text : "-";
            },
            onHeaderCell: (column) => {
              return {
                onClick: () => {
                  if (sortByState.field !== filter_name.toUpperCase() && PERMITTED_FILTER_SORTING_MAPPING.hasOwnProperty(filter_name.toUpperCase())) {
                    setSearchTableSortState({
                      ...searchTableSortState,
                      field:
                        filter_name.toUpperCase() === PERMITTED_FILTER_SORTING_MAPPING.SUBSCRIBER_COUNT
                          ? PERMITTED_FILTER_SORTING_MAPPING.FOLLOWER_COUNT
                          : filter_name.toUpperCase(),
                    });
                    setTriggerSearch({
                      ...triggerSearch,
                      flag: true,
                      sort_by: {
                        ...searchTableSortState,
                        field:
                          filter_name.toUpperCase() === PERMITTED_FILTER_SORTING_MAPPING.SUBSCRIBER_COUNT
                            ? PERMITTED_FILTER_SORTING_MAPPING.FOLLOWER_COUNT
                            : filter_name.toUpperCase(),
                      },
                    });
                  }
                },
              };
            },
            align: "right",
          });
        }
      } else {
        // Adding the string type filter
        dynamicColumns.push({
          title: () => {
            return sortByState.field !== filter_name.toUpperCase() ? (
              PERMITTED_FILTER_SORTING_MAPPING.hasOwnProperty(filter_name.toUpperCase()) ? (
                <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "end" }}>
                  {filters[filter_name].display_name}
                  <Icons.down_arrow_icon alt="down_arrow_icon" />
                </div>
              ) : filter_name === "purchase_intent_comment_count" ? (
                <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "end" }}>
                  {filters[filter_name].display_name}{" "}
                  <Tooltip title={PURCHASE_INTENT_TOOLTIP_TEXT} placement="top-end">
                    <Box>
                      <i class="ri-information-line"></i>
                    </Box>
                  </Tooltip>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "end" }}>{filters[filter_name].display_name}</div>
              )
            ) : (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px", color: "var(--main-primary)", justifyContent: "end" }}
                className="active-header"
              >
                {filters[filter_name].display_name}
                <i className="ri-arrow-down-line"></i>
              </div>
            );
          },
          dataIndex: filter_name,
          key: filter_name,
          width: 200,
          render: (text, record) => {
            if (filters[filter_name].value_type === "number") {
              return text ? convertToShortNumber(commaReplacer(text)) : "-";
            } else if (filters[filter_name].value_type === "percentage") {
              return <div>{text ? parseFloat(commaReplacer(text)).toFixed(2) + "%" : "-"}</div>;
            }
            return text ? text : "-";
          },
          onHeaderCell: (column) => {
            return {
              onClick: () => {
                if (sortByState.field !== filter_name.toUpperCase() && PERMITTED_FILTER_SORTING_MAPPING.hasOwnProperty(filter_name.toUpperCase())) {
                  setSearchTableSortState({
                    ...searchTableSortState,
                    field:
                      filter_name.toUpperCase() === PERMITTED_FILTER_SORTING_MAPPING.SUBSCRIBER_COUNT
                        ? PERMITTED_FILTER_SORTING_MAPPING.FOLLOWER_COUNT
                        : filter_name.toUpperCase(),
                  });
                  setTriggerSearch({
                    ...triggerSearch,
                    flag: true,
                    sort_by: {
                      ...searchTableSortState,
                      field:
                        filter_name.toUpperCase() === PERMITTED_FILTER_SORTING_MAPPING.SUBSCRIBER_COUNT
                          ? PERMITTED_FILTER_SORTING_MAPPING.FOLLOWER_COUNT
                          : filter_name.toUpperCase(),
                    },
                  });
                }
              },
            };
          },
          align: "right",
        });
      }
    }
    // Reverting this to Follower count again
    // if (sortByState.field === PERMITTED_FILTER_SORTING_MAPPING.SUBSCRIBER_COUNT && selectedPlatformID === "14d9ddf5-51c6-415e-bde6-f8ed36ad7054")
    //   sortBy.field = PERMITTED_FILTER_SORTING_MAPPING.FOLLOWER_COUNT;
    // dynamicColumns = [...dynamicColumns, ...dynamicColumns];

    // Adding the action column at the end of the creator search results table
    dynamicColumns.push({
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      align: "right",
      width: 100,
    });

    dynamicColumns[dynamicColumns.length - 1] = {
      ...dynamicColumns[dynamicColumns.length - 1],
      // width: "100%",
      // align: "left",
    };
    setSearchedProfileData((prev) => {
      let iterationOffset = 0;
      if (prev && prev.length > 0) {
        iterationOffset = prev.length;
      }
      const tableDataWithFilters = constructTableDataFromSearchedProfileResponse(data, dynamicColumnToBeAdded, iterationOffset, purchaseIntentList);
      return [...prev, ...tableDataWithFilters];
    });
    if (clearExistingSearchObj) {
      searchedData = data;
      searchedPurchaseIntentData = purchaseIntentList;
    } else {
      searchedData = [...searchedProfileResponse, ...data];
      searchedPurchaseIntentData = [...searchedPurchaseIntentList, ...purchaseIntentList];
    }
    setSearchedProfileResponse(searchedData);
    setSearchedPurchaseIntentList(searchedPurchaseIntentData);
    setDynamicColumns((prevDynamicColumns) => {
      const finalColumns = [];
      const keys = new Set();
      [...dynamicColumns, ...prevDynamicColumns].forEach((obj) => {
        const value = obj["dataIndex"];
        if (!keys.has(value)) {
          keys.add(value);
          finalColumns.push(obj);
        }
      });
      return finalColumns;
    });
    setTableLoading(false);
    setMoreResultsLoading(false);
  };

  const handleCreatorSelectedList = (selectedCreator) => {
    setCreateListPopUpState({
      ...createListPopUpState,
      visible: true,
      isVerified: selectedCreator?.is_verified,
      full_name: selectedCreator?.full_name,
      image_url: selectedCreator?.image_url,
      platform_username: selectedCreator?.platform_username,
      profile_url: selectedCreator?.url,
      workplatform_id: selectedCreator?.work_platform?.id,
      isCreatorAlreadyPresentInList: true,
    });
  };

  const constructTableDataFromSearchedProfileResponse = (searchedProfileResponse, filtersToBeAdded, iterationOffset = 0, purchaseIntentList) => {
    const tableData = searchedProfileResponse.map((invitation, idx) => {
      const rowData = {
        key: idx + iterationOffset + 1,
        account: (
          <Box className="table-profile-col">
            <Box>
              <Avatar alt="Profile Pic" src={invitation?.image_url} className="table-profile-pic" sx={{ width: 72, height: 72 }} />
            </Box>
            <Box className="table-username-container">
              <Box className="table-profile-full-name">
                <a target="_self">{invitation?.full_name?.replace(/&#\d+;/g, "").replace(/:[^:\s]+:/g, "")}</a>
                {invitation?.is_verified && (
                  <div style={{ width: "24px", height: "24px", zIndex: 1 }}>
                    <Icons.verified_logo style={{ width: "100%", height: "100%" }} />{" "}
                  </div>
                )}
              </Box>
              {invitation?.platform_username && (
                <Box
                  className="table-profile-user-name"
                  onClick={(e) => {
                    trackEvent(SEARCH_PLATFORM_PROFILE_VIEWED, {
                      username: invitation?.platform_username,
                      col: invitation,
                      platform: invitation?.work_platform?.name,
                      source: "search_page",
                    });
                    e.stopPropagation();
                  }}
                >
                  <a href={invitation?.url} target="_blank">
                    <Box display={"flex"} gap={"4px"} alignItems={"center"} justifyContent={"center"}>
                      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"20px"} width={"20px"}>
                        {CREATOR_SEARCH_TABLE_ICONS[Object.fromEntries(new URLSearchParams(window.location.search)).platform].icon}
                      </Box>
                      <Box>@{invitation?.platform_username}</Box>
                      <Box>
                        <i className="ri-arrow-right-up-line" style={{ fontSize: "16px", color: "var(--neutrals-secondary-grey)" }}></i>
                      </Box>
                    </Box>
                  </a>
                </Box>
              )}
            </Box>
          </Box>
        ),
        platformUsername: invitation?.platform_username,
        full_name: invitation?.full_name,
        is_verified: invitation?.is_verified,
        image_url: invitation?.image_url,
        profileImg: invitation?.url,
        workPlatformId: invitation?.work_platform?.id,
        actions: invitation?.is_part_of_creator_list ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (isTrialMode) {
              handleTrialMode(true, unlockPopupDetails.addCreatorToList);
            }else{
            handleCreatorSelectedList(invitation);
            }
          }}
        >
          <Icons.created_list />
        </div>
      ) : (
        <Tooltip title={ADD_TO_LIST_TOOLTIP_TEXT} placement="top-end">
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (isTrialMode) {
                handleTrialMode(true, unlockPopupDetails.addCreatorToList);
              } else {
              setCreateListPopUpState({
                ...createListPopUpState,
                visible: true,
                isVerified: invitation?.is_verified,
                full_name: invitation?.full_name,
                image_url: invitation?.image_url,
                platform_username: invitation?.platform_username,
                profile_url: invitation?.url,
                workplatform_id: invitation?.work_platform?.id,
              });
              }
            }}
          >
            <i class="ri-play-list-add-line list-icon"></i>
          </div>
        </Tooltip>
      ),
    };
      if (Array.isArray(filtersToBeAdded)) {
        filtersToBeAdded.forEach((filterToBeAdded) => {
          // Condition to check if the selected-filter is inside the filter_match object
          if (!invitation[filterToBeAdded]) {
            if (invitation?.filter_match && invitation?.filter_match[filterToBeAdded]) {
              // Condition to check if the selected-filter is an array of objects
              if (Array.isArray(invitation?.filter_match[filterToBeAdded])) {
                for (let key in invitation?.filter_match[filterToBeAdded]) {
                  // Mapping the array type filter with column
                  rowData[invitation?.filter_match[filterToBeAdded][key]?.label] = invitation?.filter_match[filterToBeAdded][key].value;
                }
              } else {
                // Mapping the object type filter with column
                rowData[invitation?.filter_match[filterToBeAdded]?.label] = invitation?.filter_match[filterToBeAdded]?.value;
              }
            } else {
              if (filterToBeAdded === "purchase_intent_comment_count") {
                let purchaseIntentCount = purchaseIntentList[idx][filterToBeAdded] / purchaseIntentList[idx].total_comment_count;
                rowData[filterToBeAdded] =
                  purchaseIntentList[idx][filterToBeAdded] === null || Number.isNaN(purchaseIntentCount) ? (
                    <Box className="purchase-intent-list-container">
                      <a
                        className="purchase-intent-list-container"
                        onClick={() => {
                          trackEvent(CHECK_PURCHASE_INTENT_CLICKED, { index: idx + 1 });
                        }}
                      >
                        Check
                      </a>
                    </Box>
                  ) : (
                    percentFormatter.format(purchaseIntentCount)
                  );
              }
            }
          } else {
            // Mapping the string type filter with column
            rowData[filterToBeAdded] = invitation[filterToBeAdded] === null ? "-" : formatValue(invitation[filterToBeAdded]);
          }
        });
      } else {
        // Mapping the string type filter with column
        rowData[filtersToBeAdded] = invitation[filtersToBeAdded] === null ? "-" : formatValue(invitation[filtersToBeAdded]);
      }
      return rowData;
    });
    return tableData;
  };

  // func to open filters on click of tags
  const openFilterOnTagClick = (filterKey) => {
    // search for filterKey in primaryFilters
    let isSuperPrimaryFilter = superPrimaryFilters.find((filter) => filter.filterKey === filterKey);
    let isPrimaryFilter = primaryFilters.find((filter) => filter.filterKey === filterKey);
    if (isPrimaryFilter || isSuperPrimaryFilter) {
      dispatchCreatorDiscoveryWarehouse({
        type: UPDATE_LAST_INTERACTED_FILTER,
        payload: {
          filterKey: filterKey,
          state: true,
        },
      });
      // find element and click on header to open filter
      const interactedFilterId = isSuperPrimaryFilter ? isSuperPrimaryFilter.id : isPrimaryFilter.id;
      const element = document.getElementById(`filter-wrapper-${interactedFilterId}`);
      element.querySelector(".fliter-header")?.click();
      return;
    } else {
      // search for filterKey in secondaryFilters
      let isSecondaryFilter = [...performanceFilters, ...audienceFilters, ...creatorsFilters].find((filter) => filter.filterKey === filterKey);
      if (isSecondaryFilter) {
        moreFilterButtonRef.current.click();
        dispatchCreatorDiscoveryWarehouse({
          type: UPDATE_LAST_INTERACTED_FILTER,
          payload: {
            filterKey: filterKey,
            state: true,
          },
        });
      }
    }
  };

  // fetch results based on selected filters
  const handleClickShowResults = async (sortBy = {}, sourceOfFilter = FILTER_BASED_SEARCH) => {
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    const queryParams = Object.fromEntries(searchParams);
    setShowTable(true);
    setTableLoading(true);
    setSearchedProfileData([]);
    setDynamicColumns([]);
    setShowTableErrorScreen({ flag: false, status: 200 });
    setHideLoadMoreResultsButton(true);
    sessionStorage.removeItem("search-results");
    const selectedWorkPlatformId = workPlatformsDropdownOptions.filter((option) => option.value === queryParams.platform)[0].id;
    // construct payload
    var payload = createSearchPayloadFromFiltersPayload(creatorDiscoveryWarehouse.filters);
    payload.work_platform_id = selectedWorkPlatformId;
    // payload.sort_by = isEmpty(sortBy) ? searchTableSortState : sortBy;
    if (!isEmpty(sortBy)) sortBy.field = sortBy.field === "REEL_VIEWS" ? (sortBy.field = "REELS_VIEWS") : sortBy.field;
    payload.sort_by = sortBy;
    payload.limit = totalFetchedRecords > 0 ? totalFetchedRecords : SEARCH_RESULTS_LIMIT;
    payload.offset = 0;
    setTotalFetchedRecords(0);
    setSearchTableOffset(SEARCH_RESULTS_TABLE_OFFSET);
    // API call
    try {
      let filtersIdentifier = getFiltersBasedOnPlatform(workPlatformsDropdownOptions.filter((option) => option.value === queryParams.platform)[0].id);
      let defaultSortingOptionsIdentifier = filtersIdentifier.filter((filter) => filter.default_sorting).map((filter) => filter.identifier);
      let newAppliedFilterOnWhichSortingApplied = [];

      const filtersFromQueryParams = Object.keys(queryParams);

      filtersFromQueryParams.reverse().forEach((filterKey) => {
        if (creatorDiscoveryWarehouse.filters[filterKey]?.is_sorting_available) {
          newAppliedFilterOnWhichSortingApplied.push(filterKey);
        }
      });
      let isSortingStatePresentInQueryParams = false;
      if (newAppliedFilterOnWhichSortingApplied.length > 0) {
        // Special handling for REEL_VIEWS filter in sort payload. REEL_VIEWS is converted to REELS_VIEWS
        const sortByField = isEmpty(sortBy)
          ? newAppliedFilterOnWhichSortingApplied[0].toUpperCase() === REEL_VIEWS
            ? REELS_VIEWS
            : newAppliedFilterOnWhichSortingApplied[0].toUpperCase()
          : sortBy.field;
        setSearchTableSortState({
          ...searchTableSortState,
          field: sortByField,
        });
        payload.sort_by = {
          field: sortByField,
          order: "DESCENDING",
        };
      } else {
        filtersFromQueryParams.forEach((filterKey) => {
          if (searchTableSortState.field.toLowerCase() === filterKey) {
            isSortingStatePresentInQueryParams = true;
          }
        });
        if (isSortingStatePresentInQueryParams) {
          // Special handling for REEL_VIEWS filter in sort payload. REEL_VIEWS is converted to REELS_VIEWS
          const sortByFieldState = searchTableSortState.field === REEL_VIEWS ? REELS_VIEWS : searchTableSortState.field;
          setSearchTableSortState({
            ...searchTableSortState,
            field: isEmpty(sortBy) ? sortByFieldState.field : sortBy.field,
          });
          payload.sort_by = isEmpty(sortBy) ? sortByFieldState : sortBy;
        } else {
          setSearchTableSortState({
            ...searchTableSortState,
            field: isEmpty(sortBy) ? "AVERAGE_LIKES" : sortBy.field,
          });
          payload.sort_by = isEmpty(sortBy)
            ? {
                field: "AVERAGE_LIKES",
                order: "DESCENDING",
              }
            : sortBy;
        }
      }

      const response = await creatorDiscoveryAPI.postFiltersSearch({
        payload: payload,
        warehouse,
      });

      if (response.data.length === 0) {
        setShowTableErrorScreen({ flag: true, status: "no_data" });
      }
      let defaultColumnsFiltersIdentifier = filtersIdentifier.filter((filter) => filter.mandatory_show_column).map((filter) => filter.identifier);
      let selectedFilters = Object.keys(payload);
      selectedFilters.forEach((filterKey) => {
        if (creatorDiscoveryWarehouse.filters[filterKey]?.show_column || creatorDiscoveryWarehouse.filters[filterKey]?.show_column_on_search) {
          defaultColumnsFiltersIdentifier.push(filterKey);
        }
      });
      var newAppliedFilter = [];

      if (newAppliedFilter.length === 0) {
        newAppliedFilter = selectedFilters;
      }
      newAppliedFilter.forEach((filterKey) => {
        if (creatorDiscoveryWarehouse.filters[filterKey]?.is_sorting_available) {
          defaultSortingOptionsIdentifier.push(filterKey);
        }
      });
      defaultSortingOptionsIdentifier = Array.from(new Set(defaultSortingOptionsIdentifier));

      let defaultSortingOptions = [];
      defaultSortingOptionsIdentifier.forEach((filterKey) => {
        // Special handling for REEL_VIEWS filter in sort payload. REEL_VIEWS is converted to REELS_VIEWS
        // Special handling for TOPIC_RELEVANCE // To change the display name from Topics to Topic Relevance
        defaultSortingOptions.push({
          name:
            creatorDiscoveryWarehouse.filters[filterKey]?.display_name === TOPIC_RELEVANCE_FILTER_DISPLAY_NAME
              ? TOPIC_RELEVANCE_FILTER_SORTING_OPTION
              : creatorDiscoveryWarehouse.filters[filterKey]?.display_name,
          value: filterKey.toUpperCase() === REEL_VIEWS ? REELS_VIEWS : filterKey?.toUpperCase(),
        });
      });
      setSortingOptions(defaultSortingOptions);

      if (sourceOfFilter === FILTER_BASED_SEARCH) {
        trackEvent(SEARCH_RESULTS_VIEWED, {
          filters_added: Object.entries(filterTags).map(([key, value]) => ({
            [key]: {
              filter_name: value.filterName,
              category: value.category,
              type: value.filterType,
              values: value.filterValue,
            },
          })),
          platform: selectedPlatform,
          source: sourceOfFilters ? sourceOfFilters : FILTER_BASED_SEARCH,
          ...(tileCount && { tile_number: tileCount }),
        });
      }

      setTotalSearchedResults(convertToShortNumber(response?.metadata?.total_results.toString()));
      // when response is less than limit hide load more results button
      if (response?.metadata?.total_results <= payload.limit) {
        setHideLoadMoreResultsButton(false);
      }
      // Passing true to clear the already existing search response
      constructDynamicTableColumns(
        defaultColumnsFiltersIdentifier,
        response.data,
        true,
        newAppliedFilterOnWhichSortingApplied.length > 0
          ? {
              field: isEmpty(sortBy) ? newAppliedFilterOnWhichSortingApplied[0].toUpperCase() : sortBy.field,
              order: "DESCENDING",
            }
          : isEmpty(sortBy)
          ? isSortingStatePresentInQueryParams
            ? searchTableSortState
            : {
                field: "AVERAGE_LIKES",
                order: "DESCENDING",
              }
          : sortBy,
        response.profile_comment_analytics_list
      );
      setTableLoading(false);
    } catch (error) {
      if (error.response.status) {
        setShowTableErrorScreen({ flag: true, status: error.response.status });
      }
    }
    setTableLoading(false);
  };

  const handleClickLoadMoreResults = async () => {
    setMoreResultsLoading(true);
    const payload = createSearchPayloadFromFiltersPayload(creatorDiscoveryWarehouse.filters);
    payload.limit = SEARCH_RESULTS_LOAD_MORE_LIMIT;
    payload.offset = searchTableOffset + SEARCH_RESULTS_LOAD_MORE_TABLE_OFFSET; // To ensure that More results always takes latest +5 state
    payload.sort_by = searchTableSortState;
    payload.work_platform_id = selectedPlatformID;
    const response = await creatorDiscoveryAPI.postFiltersSearch({
      payload: payload,
      warehouse,
    });
    setTotalFetchedRecords(payload.limit + payload.offset);
    // when response is 0 hide load more results
    if (response.data.length === 0) {
      setHideLoadMoreResultsButton(false);
      setTableLoading(false);
    }
    // Updating the state for the next API call
    setSearchTableOffset((prevOffset) => prevOffset + SEARCH_RESULTS_LOAD_MORE_TABLE_OFFSET);
    // Setting this for load more results
    let filtersIdentifier = getFiltersBasedOnPlatform(workPlatformsDropdownOptions.filter((option) => option.value === selectedPlatform)[0].id);

    let defaultColumnsFiltersIdentifier = filtersIdentifier.filter((filter) => filter.mandatory_show_column).map((filter) => filter.identifier);
    let selectedFilters = Object.keys(payload);
    selectedFilters.forEach((filterKey) => {
      if (creatorDiscoveryWarehouse.filters[filterKey]?.show_column || creatorDiscoveryWarehouse.filters[filterKey]?.show_column_on_search) {
        defaultColumnsFiltersIdentifier.push(filterKey);
      }
    });

    setTotalSearchedResults(formatNumber(response?.metadata?.total_results));
    constructDynamicTableColumns(defaultColumnsFiltersIdentifier, response.data, false, {}, response.profile_comment_analytics_list);
    // Navigate to bottom of the page once we have the search results
    sessionStorage.removeItem("scrollTarget");
    const targetElement = document.getElementsByClassName("table-skeleton-container")[0];
    // const targetElement = document.querySelector(`tr[data-row-key="${searchedProfileData.length}"]`);
    targetElement.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const handleDropdownAPIs = async () => {
    let languages = await handleLanguageAPI();
    let interests = await handleInterestAPI();
    let brands = await handleBrandAPI();

    setLanguages(languages);
    setInterests(interests);
    setBrands(brands);

    dispatchCreatorDiscoveryWarehouse({
      type: SET_SHOULD_RENDER_TRUE,
    });
  };


  useEffect(() => {
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    const queryParams = Object.fromEntries(searchParams);

    // Condition to check the Trial status of Creator Search and Add the TopHeader | Also fetch the usge info
    (async () => {

    if(searchType ===  creatorSearchTabs.filters || searchType ===  creatorSearchTabs.profileSearch){
      if (warehouse.tenantAppProducts[creatorSearchProductID] === TRIAL ) {
        // This is to add a Loader state of Header
        dispatchWarehouse({
          type: SHOW_TOP_HEADER,
          payload: {
            title: <Loader />,
            backgroundColor: Colors.semanticsSecondaryAlert,
          },
        });
        setIsTrialMode(true);
        const usageInfo = await fetchTenantUsageInformation();
        dispatchWarehouse({ type: ADD_USAGE_QUOTA, payload: usageInfo });
        dispatchWarehouse({
          type: SHOW_TOP_HEADER,
          payload: {
            title: getTopHeaderNavTextAsPerUsage(usageInfo, creatorSearchProductID),
            backgroundColor: Colors.semanticsSecondaryAlert,
          },
        });
      } else  {
        setIsTrialMode(false);
        dispatchWarehouse({ type: HIDE_TOP_HEADER });
      }
    } else if(searchType === creatorSearchTabs.compareCreators){
      if (warehouse.tenantAppProducts[compareCreatorProductID] === TRIAL ) {
        // This is to add a Loader state of Header
        dispatchWarehouse({
          type: SHOW_TOP_HEADER,
          payload: {
            title: <Loader />,
            backgroundColor: Colors.semanticsSecondaryAlert,
          },
        });
        setIsTrialMode(true);
        const usageInfo = await fetchTenantUsageInformation();
        dispatchWarehouse({ type: ADD_USAGE_QUOTA, payload: usageInfo });
        dispatchWarehouse({
          type: SHOW_TOP_HEADER,
          payload: {
            title: getTopHeaderNavTextAsPerUsage(usageInfo, compareCreatorProductID),
            backgroundColor: Colors.semanticsSecondaryAlert,
          },
        });
      } else  {
        setIsTrialMode(false);
        dispatchWarehouse({ type: HIDE_TOP_HEADER });
      }
    }

    })();
    // handleSavedSearchResponse(queryParams);
  }, [searchType]);

  const handleSavedSearchResponse = (queryParams) => {
    const handleBeforeUnload = () => {
      sessionStorage.clear();
    };
    let savedSearchedResponse = JSON.parse(sessionStorage.getItem("search-results"));
    if (savedSearchedResponse) {
      if (savedSearchedResponse.totalSearchedResults <= SEARCH_RESULTS_LIMIT) {
        setHideLoadMoreResultsButton(false);
      } else {
        setHideLoadMoreResultsButton(true);
      }
    }
    if (savedSearchedResponse && !isEmpty(filters)) {
      const payload = createSearchPayloadFromFiltersPayload(creatorDiscoveryWarehouse.filters);
      let filtersIdentifier = getFiltersBasedOnPlatform(
        workPlatformsDropdownOptions.filter((option) => option.value === (!isEmpty(queryParams) ? queryParams.platform : selectedPlatform))[0]?.id
      );
      let defaultColumnsFiltersIdentifier = [];
      if (searchType === creatorSearchTabs.filters) {
        defaultColumnsFiltersIdentifier = filtersIdentifier.filter((filter) => filter.mandatory_show_column).map((filter) => filter.identifier);
        let selectedFilters = Object.keys(payload);
        selectedFilters.forEach((filterKey) => {
          if (creatorDiscoveryWarehouse.filters[filterKey]?.show_column || creatorDiscoveryWarehouse.filters[filterKey]?.show_column_on_search) {
            defaultColumnsFiltersIdentifier.push(filterKey);
          }
        });
        setSearchTableOffset(savedSearchedResponse.searchedProfileResponse.length);
      }
      setSortingOptions(savedSearchedResponse.sortingList.sortingOptions);
      setSearchTableSortState({
        field: savedSearchedResponse.sortingList.selectedSortingState,
        order: "DESCENDING",
      });
      constructDynamicTableColumns(
        defaultColumnsFiltersIdentifier,
        savedSearchedResponse.searchedProfileResponse,
        false,
        {
          field: savedSearchedResponse.sortingList.selectedSortingState,
          order: "DESCENDING",
        },
        savedSearchedResponse.purchaseIntentList
      );
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  };

  useEffect(() => {
    setTitleCheckboxChecked(
      selectedRowKeys.length === searchedProfileData.slice(0, searchTableOffset + SEARCHED_RESULTS_RECORDS_LIMIT).length &&
      searchedProfileData.slice(0, searchTableOffset + SEARCHED_RESULTS_RECORDS_LIMIT).length !== 0
    );
  }, [searchedProfileData, selectedRowKeys, searchTableOffset]);


  useEffect(() => {
    if (searchedProfileData.length > 0) {
      const scrollTarget = sessionStorage.getItem("scrollTarget");
      if (scrollTarget) {
        const targetElement = document.querySelector(`tr[data-row-key="${scrollTarget}"]`);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [searchedProfileData]);

  // to set search payload from query params
  useEffect(() => {
    let url = new URL(window.location);
    let searchParams = new URLSearchParams(url.search);
    let queryParams = Object.fromEntries(searchParams);
    if (Object.keys(queryParams).length === 1 && queryParams.platform === WORKPLATFORMS.INSTAGRAM) {
      const retrievedUrl = JSON.parse(sessionStorage.getItem("urlWithParams"));
      if (retrievedUrl && retrievedUrl !== url.href) {
        onlyOnceForCreatingPayload.current = true;
        window.history.pushState({}, "", retrievedUrl);
        // window.location.href = retrievedUrl;
        url = new URL(retrievedUrl);
        searchParams = new URLSearchParams(url.search);
        queryParams = Object.fromEntries(searchParams);
      }
    }
    (async () => {
      if (Object.keys(creatorDiscoveryWarehouse.filters).length > 0 && onlyOnceForCreatingPayload.current) {
        onlyOnceForCreatingPayload.current = false;
        // checks if platform is already present in query params with valid value
        if (queryParams.platform && workPlatformsDropdownOptions.find((option) => option.value === queryParams.platform)) {
          setSelectedPlatform(queryParams.platform);
        } else upsertPlatformToQueryParams(selectedPlatform);
        if (Object.keys(queryParams).length > 1) {
          upsertPayloadFromQueryParams({
            queryParams,
            creatorDiscoveryWarehouse,
            dispatchCreatorDiscoveryWarehouse,
            warehouse,
          });
          let savedSearchedResponse = JSON.parse(sessionStorage.getItem("search-results"));
          if (savedSearchedResponse) {
            handleSavedSearchResponse(queryParams);
          } else {
            handleClickShowResults();
          }
        } else {
          handleClickShowResults({}, DEFAULT_SEARCH);
        }
        await handleRenderFilters(selectedPlatform);
        renderFilterTags();
        let selectedListCreatorInfo = creatorDiscoveryWarehouse?.selectedListCreatorData;
        if (selectedListCreatorInfo?.selectedListCreators.length > 0) {
          handleCreatorListClick();
        }
      }
    })();
  }, [creatorDiscoveryWarehouse]);

  // get filters payload from api
  useEffect(() => {
    if (warehouse.tenantInfo.length === 0) {
      return;
    }
    (async () => {
      if (onlyOnce.current) {
        onlyOnce.current = false;
        trackEvent(SEARCH_PAGE_OPENED);

        fetchCreatorDiscovery();
        handleDropdownAPIs();
      }
    })();
  }, [warehouse]);

  useEffect(() => {
    (async () => {
      if (searchType !== creatorSearchTabs.filters) return;
      // setSearchedProfileData([]);

      const selectedWorkPlatformId = workPlatformsDropdownOptions.filter((option) => option.value === selectedPlatform)[0].id;
      setSelectedPlatformID(selectedWorkPlatformId);
      // get recent and featured tags
      // setCardsLoading(true);
      // const recentSearchResponse = await getRecentSearches({
      //   workplatformId: selectedWorkPlatformId,
      //   creatorSearchType: "FILTERS",
      //   warehouse,
      // });
      // setRecentSearchTagsResponse(recentSearchResponse.data);
      // const featuredTagsResponse = await getFeaturedSearches({ workplatformId: selectedWorkPlatformId, warehouse });
      // setFeaturedSearchTagsResponse(featuredTagsResponse.data);
      // setCardsLoading(false);
    })();
  }, [selectedPlatform, searchType]);

  useEffect(() => {
    // render filters tags on warehouse update
    if (selectedPlatform && !isEmpty(creatorDiscoveryWarehouse.filters)) {
      (async () => {
        // await handleRenderFilters(selectedPlatform);
        renderFilterTags();
      })();
    }

    // render filters on warehouse update with check on shouldRender
    if (selectedPlatform && creatorDiscoveryWarehouse?.shouldRender) {
      (async () => {
        await handleRenderFilters(selectedPlatform);
        dispatchCreatorDiscoveryWarehouse({
          type: SET_SHOULD_RENDER_FALSE,
        });
        // renderFilterTags();
      })();
    }

    // Reseting the source & tile count values on manual interation of filters
    if (creatorDiscoveryWarehouse.lastInteractedFilter !== null) {
      setSourceOfFilters();
      setTileCount();
    }
  }, [creatorDiscoveryWarehouse]);

  useEffect(() => {
    // This useEffect is handling the tags updating when the API gets resolved
    if (isSecondaryfiltersLoaded && creatorDiscoveryWarehouse?.shouldTagsRender) {
      const url = new URL(window.location);
      const searchParams = new URLSearchParams(url.search);
      const queryParams = Object.fromEntries(searchParams);

      if (Object.keys(queryParams).length > 0) {
        updateFilterTags({ queryParams, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse });
        renderFilterTags();
      }

      dispatchCreatorDiscoveryWarehouse({
        type: SET_SHOULD_TAGS_RENDER_FALSE,
      });
    }
  }, [isSecondaryfiltersLoaded, creatorDiscoveryWarehouse]);

  useEffect(() => {
    if (triggerSearch.flag) {
      handleClickShowResults(triggerSearch.sort_by);
      setTriggerSearch({
        ...triggerSearch,
        flag: false,
      });
      setSearchTableSortState({
        ...searchTableSortState,
        field: triggerSearch.sort_by.field,
      });
    }
  }, [triggerSearch]);

  const handleRemoveAllFilters = () => {
    deleteAllQueryParamsAndFilterPayload(creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse);
    // This is only to trigger the event after clearing the tileCount & sourceOfFilter being passed in the segment
    dispatchCreatorDiscoveryWarehouse({
      type: UPDATE_LAST_INTERACTED_FILTER,
      payload: {
        filterKey: "random string",
        state: true,
      },
    });
    setTimeout(() => {
      dispatchCreatorDiscoveryWarehouse({ type: SET_LAST_INTERACTED_FILTER_NULL });
    }, 500);
    handleClickShowResults();
    // setShowTable(false);
  };

  const clearRecentSearchCardHandler = async (recentSearchCardId) => {
    setCardsLoading(true);
    const response = await removeRecentSearchCard({
      warehouse,
      id: recentSearchCardId,
    });
    if (response.status === "accepted") {
      const updatedRecentSearchCard = recentSearchTagsResponse.filter((card) => card.id !== recentSearchCardId);
      setRecentSearchTagsResponse(updatedRecentSearchCard);
      setCardsLoading(false);
    }
  };

  // This useEffect used to set export overlay height when it is on sticky position
  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(".scrolling-page-content");
      const shouldBeSticky = element.scrollTop > 650;

      setIsExportOverlaySticky(shouldBeSticky);
      // adjusting the height as needed to set-up the export overlay
      setExportOverlayHeight(shouldBeSticky ? 56 : 38);
    };

    const element = document.querySelector(".scrolling-page-content");
    element?.addEventListener("scroll", handleScroll);

    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const updateCreatorList = (list, favoriteCreatorCount) => {
    const updatedList = list?.map((item) => {
      item.isDeleteAllowed = true;
      item.icon = <Icons.menu_items_icon />;
      item.checked = false;
      item.isCheckboxEnabled = true;

      return item;
    });

    let favouriteObj = {
      isDeleteAllowed: false,
      icon: <Icons.heart_icon />,
      checked: false,
      count_of_creators: favoriteCreatorCount,
      name: favourite,
      id: favourite.toLocaleLowerCase(),
      isCheckboxEnabled: false,
    };

    return [favouriteObj, ...updatedList];
  };

  // make api call and update creator List
  const handleCreatorListClick = async (openToast = false, message = "") => {
    if (isTrialMode) {
      handleTrialMode(true, unlockPopupDetails.addCreatorToList);
    }
    else {
      setCreatorListLoading(true);
      setIsCreatorDrawerOpen(true);
      const list = await getAllCreatorList({ warehouse });
      if (openToast === true) {
        setTriggerSearch({
          ...triggerSearch,
          flag: true,
          sort_by: searchTableSortState,
        });
        setTimeout(() => {
          onOpenToast(message);
        }, TOAST_MESSAGE_DELAY_DURATION);
        trackEvent(YOUR_LIST_CLICKED);
      }

      const sortedList = list?.data?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      const updatedList = updateCreatorList(sortedList, list?.favourite_creator_count);
      setCreatorList(updatedList);
      setFilteredCreatorList(updatedList);
      setCreatorListLoading(false);
      return updatedList;
    }
  };

  const onCreatorListSearch = (searchText) => {
    let filteredList = creatorList?.filter((list) => list["name"].toLowerCase().includes(searchText.toLowerCase()));
    setFilteredCreatorList(filteredList);
    trackEvent(LIST_SEARCHED, { query: searchText, result_count: filteredList?.length });
  };

  const onOpenToast = async (message) => {
    setOpenToast({
      open: true,
      message: (
        <>
          <b>{message}</b>
        </>
      ),
      duration: ADD_LIST_TOAST_MESSAGE_DURATION,
    });
  };

  const onDeleteCreatorLists = async (ids) => {
    try {
      const response = await deleteCreatorsLists({ warehouse, ids });
      handleCreatorListClick(true, `Deleted ${ids.length} ${ids.length > 1 ? "lists" : "list"}`);
    } catch (error) {
      console.log(error);
    } finally {
      let deletedLists = filteredCreatorList.filter((list) => ids.includes(list.id)).map((item) => item.name);
      setDeleteModalState({ isModalOpen: false, deleteIDs: {} });
      trackEvent(LIST_DELETED, { name: deletedLists });
    }
  };

  const onCreatorDelete = async (deleteIds) => {
    const { listId, creatorIds, creatorNames } = deleteIds;
    try {
      if (listId == favourite.toLocaleLowerCase()) {
        await deleteCreatorsFromFavourites({ warehouse, creatorIds });
      } else {
        await deleteCreatorsFromLists({ warehouse, listId, creatorIds });
      }
      handleCreatorListClick(true, `Removed ${creatorIds.length} ${creatorIds.length > 1 ? "creators" : "creator"}`);
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteModalState({ isModalOpen: false, deleteIDs: {} });
      trackEvent(CREATOR_DELETED, { name: creatorNames });
    }
  };

  const getListDeleteTitle = () => {
    let count;
    if (deleteModalState?.deleteIDs?.creatorIds) {
      count = deleteModalState?.deleteIDs?.creatorIds.length;
      return `Remove ${count} ${count > 1 ? "creators" : "creator"} from list?`;
    } else {
      count = deleteModalState?.deleteIDs?.length;
      return `Delete ${count} ${count > 1 ? "lists" : "list"} ?`;
    }
  };
  const getListDeleteDesc = () => {
    let count;
    if (deleteModalState?.deleteIDs?.creatorIds) {
      count = deleteModalState?.deleteIDs?.creatorIds.length;
      return `You’re about to remove ${count} ${count > 1 ? "creators" : "creator"} from the list.`;
    } else {
      count = deleteModalState?.deleteIDs?.length;
      return `You’re about to permanently delete ${count} ${count > 1 ? "lists" : "list"}. This action can’t be reversed. `;
    }
  };
  const handleMultipleAddToCreatorList = (selectedRows) => {
    setCreateListPopUpState({
      ...createListPopUpState,
      visible: true,
      isMultipleCreatorSelected: true,
      multipleSelectedCreators: selectedRows,
      isCreatorAlreadyPresentInList: false,
    });
  };

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast({
      open: false,
      message: "",
      duration: ADD_LIST_TOAST_MESSAGE_DURATION,
      type: "success",
    });
  };

  const fetchResultsFromRetrievedUrl = (retrievedUrl) => {
    let url = new URL(retrievedUrl);
    let searchParams = new URLSearchParams(url.search);
    let queryParams = Object.fromEntries(searchParams);
    (async () => {
      if (Object.keys(creatorDiscoveryWarehouse.filters).length > 0) {
        // checks if platform is already present in query params with valid value
        if (queryParams.platform && workPlatformsDropdownOptions.find((option) => option.value === queryParams.platform)) {
          setSelectedPlatform(queryParams.platform);
        } else upsertPlatformToQueryParams(selectedPlatform);
        if (Object.keys(queryParams).length > 1) {
          upsertPayloadFromQueryParams({
            queryParams,
            creatorDiscoveryWarehouse,
            dispatchCreatorDiscoveryWarehouse,
            warehouse,
          });
          let savedSearchedResponse = JSON.parse(sessionStorage.getItem("search-results"));
          if (savedSearchedResponse) {
            handleSavedSearchResponse(queryParams);
          } else {
            handleClickShowResults();
          }
        } else {
          handleClickShowResults({}, DEFAULT_SEARCH);
        }
        await handleRenderFilters(selectedPlatform);

        // if (Object.keys(queryParams).length > 0) {
        //   updateFilterTags({ queryParams, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse });
        //   renderFilterTags();
        // }
        // renderFilterTags();
      }
    })();
  };

  return (
    <div className="creator-search-page-container">
      <FiltersOverlay
        loading={overlayLoading}
        open={filtersOverlayOpen}
        onClose={() => {
          dispatchCreatorDiscoveryWarehouse({ type: SET_LAST_INTERACTED_FILTER_NULL });
          setFiltersOverlayOpen(false);
        }}
        creatorsFilters={creatorsFilters}
        audienceFilters={audienceFilters}
        performanceFilters={performanceFilters}
        handleClickShowResults={handleClickShowResults}
        setShowTable={setShowTable}
      />
      <Box className="creator-search-page-header">
        <Box>
          <Box className="page-header-container">
            <Box className="creator-search-page-heading">Discover creators</Box>
            <Box>
              <PrimaryButton label={YOUR_CREATOR_LISTS_TITLE} className="lists-button" onClick={handleCreatorListClick} />
            </Box>
          </Box>
          <CreatorListSidebar
            onClose={() => setIsCreatorDrawerOpen(false)}
            isOpen={isCreatorDrawerOpen}
            heading={yourCreatorListsTitle}
            // onAddMoreAccounts={onAddMoreAccounts}
            clearAllSelection={() => {
              const updatedCreatorList = filteredCreatorList?.map((item) => {
                return { ...item, checked: false };
              });
              setFilteredCreatorList(updatedCreatorList);
            }}
            onSearch={onCreatorListSearch}
            rows={filteredCreatorList}
            onCheckBoxClick={(id, value) => {
              const updatedCreatorList = filteredCreatorList?.map((item) => {
                if (item?.id === id) {
                  return { ...item, checked: value };
                }
                return item;
              });

              setFilteredCreatorList(updatedCreatorList);
            }}
            onDeleteCreatorLists={(ids) => {
              setDeleteModalState({ isModalOpen: true, deleteIDs: ids });
            }}
            onCreatorDelete={({ listId, creatorIds, creatorNames }) => {
              setDeleteModalState({ isModalOpen: true, deleteIDs: { listId, creatorIds, creatorNames } });
            }}
            creatorListLoading={creatorListLoading}
            handleCreatorListClick={handleCreatorListClick}
          />

          {deleteModalState.isModalOpen && (
            <ModalDialog
              variant={"remove"}
              cancelText={deleteModalState?.deleteIDs?.creatorIds ? dontRemove : DONT_DELETE}
              buttonText={deleteModalState?.deleteIDs?.creatorIds ? yesRemove : confirmDelete}
              title={getListDeleteTitle()}
              content={getListDeleteDesc()}
              onAction={() =>
                deleteModalState.deleteIDs.creatorIds ? onCreatorDelete(deleteModalState.deleteIDs) : onDeleteCreatorLists(deleteModalState.deleteIDs)
              }
              onClose={() => setDeleteModalState({ isModalOpen: false, deleteIDs: {} })}
              onDont={() => setDeleteModalState({ isModalOpen: false, deleteIDs: {} })}
            />
          )}
          <Box>
            <TabSwitch
              currentTab={searchType}
              handleTabChange={(event, newValue) => {
                if (newValue === creatorSearchTabs.filters && tableLoading === false) {
                  handleClickShowResults();
                }
                sessionStorage.clear();
                trackEvent(newValue === creatorSearchTabs.filters ? SEARCH_FILTERS_SEARCH_SELECTED : SEARCH_PROFILE_SEARCH_SELECTED, {});
                setSearchType(newValue);
              }}
              tabs={creatorSearchTabLabels}
              height="60px"
              width="616px"
              isPaddingRequired
            />
          </Box>
        </Box>
        {/* <Tooltip placement="bottom" title={parse(creatorCreditsContactInfo)} color="#222838">
          <div className="credits-container">
            <AlertInfo content="1,374,965 credits" width="fit-content" iconPosition="right" icon={<Icons.info_icon />} color="#F7F7FB" />
          </div>
        </Tooltip> */}
      </Box>
      <TabPanel index={creatorSearchTabs.filters} value={searchType} sx={{ margin: "0px", padding: "0px" }} className="search-type-tab">
        <Box className="creator-search-content-container">
          <Box marginBottom={"16px"} display={"flex"} gap={"12px"}>
            <Box className="platform-filter-container">
              <PlatformSelectionDropdown
                platformList={workPlatformsDropdownOptions}
                selectedPlatform={selectedPlatform}
                setSelectedPlatform={setSelectedPlatform}
                onChangeHandle={(platform) => {
                  trackEvent(SEARCH_PLATFORM_CHANGED, { changed_to: platform });
                  sessionStorage.removeItem("search-results");
                  setSearchedProfileData([]);
                  upsertPlatformToQueryParams(platform);
                  setSelectedRows([]);
                  setSelectedRowKeys([]);
                  setTitleCheckboxChecked(false);
                  setDynamicColumns([]);
                  // Reseting the source & tile count values
                  setSourceOfFilters();
                  setTileCount();
                  deleteAllQueryParamsAndFilterPayload(creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse);
                  setShowTable(false);
                  handleRenderFilters(platform);
                  const retrievedUrl = JSON.parse(sessionStorage.getItem(`${platform}Url`));
                  if (retrievedUrl && retrievedUrl !== window.location.href) {
                    window.history.pushState({}, "", retrievedUrl);
                    sessionStorage.setItem("urlWithParams", JSON.stringify(retrievedUrl));
                    fetchResultsFromRetrievedUrl(retrievedUrl);
                  } else {
                    handleClickShowResults({}, DEFAULT_SEARCH);
                  }
                }}
              />
            </Box>
            <Box className="super-primary-filter-container">
              {superPrimaryFilters &&
                superPrimaryFilters.length > 0 &&
                superPrimaryFilters.map((filter, idx) => {
                  return (
                    <>
                      <FilterWrapper
                        filterComponent={filter.Filter}
                        filterHeader={filter.label}
                        id={filter.id}
                        isPrimaryFilter={false}
                        type={filter?.type}
                        filterDescription={filter?.description}
                        handleGetFilterValue={filter?.handleGetFilterValue}
                        key={filter.id}
                        filterKey={filter.filterKey}
                        lastInteractedFilter={filter.lastInteractedFilter}
                        isOverlayOpen={filtersOverlayOpen}
                        isSelected={filter.isSelected}
                        isSuperPrimaryFilter={true}
                        // removeParentContainer={filter?.label === DESCRIPTION_KEYWORDS_LABEL ? true : false}
                        removeParentContainer={filter?.label === TOPIC_RELEVANCE_FILTER_NAME ? true : false}
                      />
                    </>
                  );
                })}
              {superPrimaryFilters && superPrimaryFilters.length === 0 && (
                <Box display={"flex"} gap={"12px"}>
                  <Skeleton variant="rectangular" width={"144px"} height="56px" />
                  <Skeleton variant="rectangular" width={"968px"} height="56px" />
                </Box>
              )}
            </Box>
            <Box>
              <PrimaryButton
                label="Get results"
                style={{ height: "56px", width: "133px" }}
                disabled={primaryFilters.length === 0}
                onClick={() => {
                  setSelectedRows([]);
                  setSelectedRowKeys([]);
                  setTitleCheckboxChecked(false);
                  handleClickShowResults();
                }}
              />
            </Box>
          </Box>
          <Box>
            {primaryFilters && primaryFilters.length > 0 && (
              <div className="primary-filters-container">
                {primaryFilters.map((filter, idx) => {
                  return (
                    <>
                      <FilterWrapper
                        filterComponent={filter.Filter}
                        filterHeader={filter.label}
                        id={filter.id}
                        isPrimaryFilter={filter?.isPrimaryFilter}
                        type={filter?.type}
                        filterDescription={filter?.description}
                        handleGetFilterValue={filter?.handleGetFilterValue}
                        key={filter.id}
                        filterKey={filter.filterKey}
                        lastInteractedFilter={filter.lastInteractedFilter}
                        isOverlayOpen={filtersOverlayOpen}
                        isSelected={filter.isSelected}
                      />
                      {idx < primaryFilters.length - 1 && (
                        <div style={{ width: "1px", height: "40px", background: "var(--neutrals-border-grey)", margin: "8px 8px" }} />
                      )}
                    </>
                  );
                })}
                <button
                  className="more-filter-button"
                  ref={moreFilterButtonRef}
                  onClick={() => {
                    trackEvent(SEARCH_MORE_FILTERS_CLICKED);
                    dispatchCreatorDiscoveryWarehouse({ type: SET_LAST_INTERACTED_FILTER_NULL });
                    dispatchCreatorDiscoveryWarehouse({ type: SET_SHOULD_RENDER_TRUE });
                    setFiltersOverlayOpen(true);
                  }}
                >
                  {" "}
                  {Object.values(filterTags).length <= 0 && <Icons.more_icon />} More filters
                  {Object.values(filterTags).length > 0 && <Box className="total-filters-count">{Object.values(filterTags).length}</Box>}
                </button>
              </div>
            )}
            {primaryFilters && primaryFilters.length === 0 && <RenderSkeletonForPrimaryFilters />}
          </Box>
          {Object.keys(filterTags).length > 0 && (
            <Box className="filter-tags-wrapper">
              {/* render filter tags */}
              <FilterTags
                filterTags={filterTags}
                openFilterOnTagClick={openFilterOnTagClick}
                setShowTable={setShowTable}
                setIsModalVisible={setIsModalVisible}
                handleClickShowResults={handleClickShowResults}
                setSearchTableSortState={setSearchTableSortState}
              />
              {Object.keys(filterTags).length > 0 && (
                <Link underline="always" onClick={handleRemoveAllFilters}>
                  Remove all filters
                </Link>
              )}
            </Box>
          )}
        </Box>
        {!showTableErrorScreen.flag &&
          searchedProfileData &&
          searchedProfileData.length > 0 &&
          (showTable || sessionStorage.getItem("search-results")) && (
            <Box
              className="export-result-container"
              sx={{
                position: isExportOverlaySticky ? "sticky" : "static",
                top: isExportOverlaySticky ? 0 : "auto",
                zIndex: 2,
                padding: isExportOverlaySticky ? "16px 32px" : 0,
                borderBottom: isExportOverlaySticky ? "1px solid var(--neutrals-border-grey)" : "none",
                marginLeft: isExportOverlaySticky ? "-32px" : 0,
                marginRight: isExportOverlaySticky ? "-32px" : 0,
                justifyContent: !selectedRows || selectedRows.length === 0 ? "space-between" : "flex-end",
              }}
            >
              {(!selectedRows || selectedRows.length === 0) && (
                <Box className="total-results">
                  {JSON.parse(sessionStorage.getItem("search-results"))
                    ? JSON.parse(sessionStorage.getItem("search-results")).totalSearchedResults
                    : totalSearchedResults}{" "}
                  creators found
                </Box>
              )}
              <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"} gap={"24px"} height={exportOverlayHeight}>
                {(!selectedRows || selectedRows.length === 0) && (
                  <Box className="sorting-dropdown-container">
                    <Box>
                      <CustomDropDown
                        options={sortingOptions}
                        defaultValue={searchTableSortState.field}
                        getFilterValue={(value) => {
                          setSearchTableSortState({
                            ...searchTableSortState,
                            field: value,
                          });
                          setTriggerSearch({
                            ...triggerSearch,
                            flag: true,
                            sort_by: {
                              ...searchTableSortState,
                              field: value,
                            },
                          });
                        }}
                        prefixLabel={`<div class="sorting-dropdown-label"><i class="ri-arrow-up-down-line"></i>Sort by</div>`}
                      />
                    </Box>
                  </Box>
                )}
                {selectedRows.length > 0 && (
                  <Box className="export-creators-count">
                    {selectedRows.length === 1 ? `${selectedRows.length} creator selected` : `${selectedRows.length} creators selected`}
                  </Box>
                )}
                {selectedRows.length > 0 && (
                  <PrimaryButton
                    label={ADD_CREATOR_TO_LIST_TITLE}
                    className="lists-button add-to-list"
                    onClick={() => {
                      handleMultipleAddToCreatorList(selectedRows);
                    }}
                  />
                )}
                <ExportResultOverlay
                  totalExportResults={selectedRows.length}
                  totalCredits={10}
                  filterTags={filterTags}
                  selectedPlatform={{ id: selectedPlatformID, name: selectedPlatform }}
                  source={sourceOfFilters}
                  selectedRows={selectedRows}
                  searchPayload={createSearchPayloadFromFiltersPayload(creatorDiscoveryWarehouse.filters)}
                  sortState={searchTableSortState}
                  exportLimit={searchedProfileData.length}
                  isTrialMode={isTrialMode}
                  totalSearchedResults={
                    JSON.parse(sessionStorage.getItem("search-results"))
                      ? JSON.parse(sessionStorage.getItem("search-results")).totalSearchedResults
                      : totalSearchedResults
                  }
                />
              </Box>
            </Box>
          )}
        {(showTable || sessionStorage.getItem("search-results")) && (
          <>
            {tableLoading && searchedProfileData ? (
              <div className="table-skeleton-container">
                <Box className="table-row-skeleton">
                  <Skeleton animation="wave" variant="text" width={30} height={40} />
                  <Skeleton animation="wave" variant="text" width={"100%"} height={80} />
                </Box>
                <TableSearchSkeleton />
              </div>
            ) : (
              searchedProfileData &&
              searchedProfileData.length > 0 && (
                <>
                  {" "}
                  <div className="creator-search-table-container">
                    <TableListingRecords
                      blurRecords={isTrialMode}
                      columns={[...columns, ...dynamicColumns]}
                      dataSource={searchedProfileData}
                      headerClickHandler={{}}
                      locale={null}
                      scroll={true}
                      size="small"
                      border={false}
                      rowClickHandler={(record) => {
                        return {
                          onClick: (event) => {
                            if (event.target.childNodes[0].tagName === "LABEL" || event.target.tagName === "LABEL") {
                              return;
                            }
                            const trElement = event.target.closest("tr[data-row-key]");
                            if (trElement) {
                              const rowKey = trElement.getAttribute("data-row-key");
                              sessionStorage.setItem("scrollTarget", rowKey);
                            }
                            let identifier = record.platformUsername;
                            let workplatformId = selectedPlatformID;
                            let selectedCreatorDetails = {
                              follower_count: record.follower_count,
                              full_name: record.full_name,
                              image_url: record.image_url,
                              is_verified: record.is_verified,
                              platform_username: record.platformUsername,
                              profile_url: record.profileImg,
                              workPlatformId: selectedPlatformID,
                            };
                            dispatchCreatorDiscoveryWarehouse({ type: BASIC_DETAILS_OF_SELECTED_PROFILE, payload: selectedCreatorDetails });
                            let searchedResults = {
                              searchedProfileResponse: searchedProfileResponse,
                              totalSearchedResults: totalSearchedResults,
                              currentTab: searchType,
                              purchaseIntentList: searchedPurchaseIntentList,
                              sortingList: {
                                selectedSortingState: searchTableSortState.field,
                                sortingOptions: sortingOptions,
                              },
                              selectedCreator: selectedCreatorDetails,
                            };
                            sessionStorage.setItem("search-results", JSON.stringify(searchedResults));
                            setSearchedProfileResponse([]);
                            setSearchedPurchaseIntentList([]);
                            dispatchCreatorDiscoveryWarehouse({
                              type: UPDATE_CREATOR_LISTS_SELECTED_CREATORS,
                              payload: {
                                selectedListData: {},
                                selectedListCreators: [],
                              },
                            });
                            if (event.target.matches(".purchase-intent-list-container")) {
                              navigate(CREATOR_PROFILE_LINK(identifier, workplatformId, "#comment-analysis-section"));
                            } else {
                              navigate(CREATOR_PROFILE_LINK(identifier, workplatformId));
                            }
                            trackEvent(SEARCH_PROFILE_VIEWED, { username: identifier, platform: selectedPlatform });
                          },
                        };
                      }}
                    />
                    {/* <DataGrid columns={[...columns, ...dynamicColumns]} rows={searchedProfileData} /> */}
                    <UnlockFullAccessPopUp
                      open={modalDetails.isModalVisible}
                      onClose={() => {
                        setModalDetails({ ...modalDetails, isModalVisible: false });
                      }}
                      header={modalDetails.modalInfo.header}
                      description={modalDetails.modalInfo.description}
                      actionButtonLabel={CONTACT_US}
                      height="295px"
                      padding="52px 52px 48px 48px"
                      isModal
                    />
                    <UnlockFullAccessPopUp
                      open={searchedProfileData.length > 5 && isTrialMode}
                      onClose={() => setIsModalVisible(false)}
                      header="Unlock the full list of creators"
                      description="Go beyond the top 10 to find that perfect fit."
                      actionButtonLabel="Contact us"
                      height="235px"
                      padding="48px"
                    // isModal
                    />
                  </div>
                  <>
                    {moreResultsLoading ? (
                      <div className="table-skeleton-container">
                        <TableSearchSkeleton />
                      </div>
                    ) : (
                      hideLoadMoreResultsButton && (
                        <div className="load-more-button-container">
                          {!isTrialMode && (
                            <InvertedPrimaryButton
                              label={"Load more results"}
                              onClick={() => {
                                // let savedSearchedResponse = JSON.parse(sessionStorage.getItem("search-results"));
                                // let offset;
                                // if (savedSxearchedResponse) {
                                //   offset = savedSearchedResponse.length + 5;
                                // } else {
                                //   offset = pageSize + 5;
                                // }
                                // setPageSize(searchTableOffset + savedSearchedResponse.length);
                                handleClickLoadMoreResults();
                                trackEvent(SEARCH_MORE_RESULTS_LOADED, {});
                              }}
                              className="table-load-more-button"
                            />
                          )}
                        </div>
                      )
                    )}
                  </>
                </>
              )
            )}
          </>
        )}
        {!tableLoading && showTableErrorScreen.flag && showTable && (
          <Box>
            {showTableErrorScreen.status === 404 || showTableErrorScreen.status === "no_data" ? (
              <Box>
                <EmptyTableScreen
                  icon={<Icons.no_content_icon />}
                  header={tableErrorMessages.no_content}
                  content={tableErrorMessages.no_creators_found}
                />
              </Box>
            ) : (
              <Box>
                <EmptyTableScreen icon={<Icons.failed_connections />} header={tableErrorMessages.try_again_msg} />
              </Box>
            )}
          </Box>
        )}
        {!showTable && !sessionStorage.getItem("search-results") && (
          <>
            {recentSearchTagsResponse && recentSearchTagsResponse.length > 0 && !cardsLoading && primaryFilters.length !== 0 && (
              <Box className="cards-container">
                <p>Recent searches</p>
                <Box>
                  {recentSearchTagsResponse.map((tag, index) => {
                    return (
                      tag.search_query?.filter_tags &&
                      Object.keys(tag.search_query?.filter_tags).length > 0 && (
                        <RecentSearchCard
                          tags={tag.search_query?.filter_tags}
                          results={tag.result_count}
                          date={convertDate(tag.updated_at)}
                          logo={WORKPLATFORM_ID_MAPPING[selectedPlatformID].platformLogo}
                          onClickHandler={() => {
                            createQueryParamsFromSearchPayload(
                              tag.search_query,
                              creatorDiscoveryWarehouse,
                              dispatchCreatorDiscoveryWarehouse,
                              warehouse
                            );
                            dispatchCreatorDiscoveryWarehouse({ type: SET_LAST_INTERACTED_FILTER_NULL });
                            setSourceOfFilters("recent_searches");
                            setTileCount(index + 1);
                            setShowTable(true);
                            setTableLoading(true);
                            // This is to ensure that the payload and tags get resolved before making the search
                            setTimeout(() => {
                              setTriggerSearch({
                                ...triggerSearch,
                                flag: true,
                                sort_by: tag.search_query.sort_by,
                              });
                            }, 1500);
                          }}
                          recentSearchCardId={tag.id}
                          deleteRecentSearchCardHandler={async (recentSearchCardId) => {
                            await clearRecentSearchCardHandler(recentSearchCardId);
                          }}
                        />
                      )
                    );
                  })}
                </Box>
              </Box>
            )}
            {featuredSearchTagsResponse && featuredSearchTagsResponse.length > 0 && !cardsLoading && primaryFilters.length !== 0 && (
              <Box className="cards-container">
                <p>Featured collections</p>
                <Box gap={"24px !important"}>
                  {featuredSearchTagsResponse.map((tag, index) => {
                    return (
                      tag.search_query &&
                      tag.title && (
                        <FeaturedSearchCard
                          filters={tag.title}
                          results={tag.result_count}
                          logo={WORKPLATFORM_ID_MAPPING[selectedPlatformID].platformLogo}
                          onClickHandler={() => {
                            createQueryParamsFromSearchPayload(
                              tag.search_query,
                              creatorDiscoveryWarehouse,
                              dispatchCreatorDiscoveryWarehouse,
                              warehouse
                            );
                            dispatchCreatorDiscoveryWarehouse({ type: SET_LAST_INTERACTED_FILTER_NULL });
                            setSourceOfFilters("featured_searches");
                            setTileCount(index + 1);
                            setShowTable(true);
                            setTableLoading(true);
                            // This is to ensure that the payload and tags get resolved before making the search
                            setTimeout(() => {
                              setTriggerSearch({
                                ...triggerSearch,
                                flag: true,
                                sort_by: tag.search_query.sort_by ? tag.search_query.sort_by : searchTableSortState,
                              });
                            }, 1500);
                          }}
                          recentSearchCardColorPallete={{
                            backgroundImage: tag.background_image_url,
                            backgroundColor: tag.background_color,
                            category: handleHeightandWidthOfCardImage(tag.category),
                          }}
                        />
                      )
                    );
                  })}
                </Box>
              </Box>
            )}
            {(cardsLoading || primaryFilters.length === 0) && (
              <Box className="cards-container">
                <TableSearchSkeleton />
              </Box>
            )}
          </>
        )}
      </TabPanel>
      <TabPanel index={creatorSearchTabs.profileSearch} value={searchType} className="tab-body-container">
        <ProfileSearchPage
          options={workPlatformsDropdownOptions}
          currentTab={searchType}
          selectedPlatform={selectedPlatform}
          setSelectedPlatform={setSelectedPlatform}
          selectedPlatformID={selectedPlatformID}
          setSelectedPlatformID={setSelectedPlatformID}
          isTrialMode={isTrialMode}
        />
        <UnlockFullAccessPopUp
          open={modalDetails.isModalVisible}
          onClose={() => {
            setModalDetails({ ...modalDetails, isModalVisible: false });
          }}
          header={modalDetails.modalInfo.header}
          description={modalDetails.modalInfo.description}
          actionButtonLabel={CONTACT_US}
          height="295px"
          padding="52px 52px 48px 48px"
          isModal
        />
      </TabPanel>
      <TabPanel index={creatorSearchTabs.compareCreators} value={searchType} className="tab-body-container">
        <CompareCreatorsPage
          options={compareCreatorWorkPlatformsDropdownOptions}
          currentTab={searchType}
          selectedPlatform={selectedPlatform}
          setSelectedPlatform={setSelectedPlatform}
          selectedPlatformID={selectedPlatformID}
          setSelectedPlatformID={setSelectedPlatformID}
        />
        <UnlockFullAccessPopUp
          open={modalDetails.isModalVisible}
          onClose={() => {
            setModalDetails({ ...modalDetails, isModalVisible: false });
          }}
          header={modalDetails.modalInfo.header}
          description={modalDetails.modalInfo.description}
          actionButtonLabel={CONTACT_US}
          height="295px"
          padding="52px 52px 48px 48px"
          isModal
        />
      </TabPanel>
      <CreateListPopUp
        open={createListPopUpState?.visible}
        onClose={() => {
          setCreateListPopUpState({ ...createListPopUpState, visible: false });
        }}
        header={CREATE_LIST_POPUP_HEADER(createListPopUpState?.isMultipleCreatorSelected, selectedRows)}
        buttonsLabelsAndActions={CREATE_LIST_POPUP_ACTIONS_LABEL(createListPopUpState?.favoriteListCount)}
        createListPopUpState={createListPopUpState}
        setCreateListPopUpState={setCreateListPopUpState}
        onAddToCreatorListHandler={() => {
          setTriggerSearch({
            ...triggerSearch,
            flag: true,
            sort_by: searchTableSortState,
          });
        }}
        source={"Search results page"}
      />
      <ToastMessage
        openToast={openToast.open}
        message={openToast.message}
        onCloseToast={handleToastClose}
        duration={openToast.duration}
        position={{ vertical: "top", horizontal: "center" }}
        type={openToast.type}
      />
      <Helmet>
        <title>{getPageTitle("CREATOR_SEARCH_PAGE", warehouse.environment.current)}</title>
      </Helmet>
    </div>
  );
};

function RenderSkeletonForPrimaryFilters() {
  return <Skeleton variant="rectangular" width={"100%"} height="72px" />;
}

export default CreatorDiscoveryPage;