import { Box, Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import {
    createCampaign,
    getAllCampaignProfiles,
    getCampaignById,
    updateCampaign,
    verifyStoreWebpage
} from '../../api/CampaignTrackingAPI/CampaignTrackingAPI';
import { EditCreatorDrawer, FilledTextField, Icons, PrimaryButton } from '../../components';
import {
    AUTO_ADD_CREATORS_TITLE,
    AUTO_ADD_STEP1_TEXT,
    campaignPageTabValues,
    CAMPAIGN_PROFILES_CREATED_VIA,
    createUTMLink,
    create_utm_link,
    CREATE_UTM_LINKS,
    CREATE_UTM_LINKS_DESC,
    CREATE_UTM_LINKS_EDIT_FLOW,
    CREATE_UTM_LINKS_HEADER,
    duplicate_campaign,
    EDIT_UTM_LINKS_HEADER,
    IDENTIFIER_TYPES,
    MANAGE,
    SIDE_DRAWER_TITLE,
    shopifyLinkErrorMessage,
    updateUTMLink
} from '../../constants/campaignTrackingConstants';
import { WarehouseContext } from '../../storage/context';
import campaignTrackingContext from '../../storage/context/campaignTrackingContext';
import {
    ADD_USAGE_QUOTA,
    SET_CAMPAIGN_ACTIVE_TAB,
    SET_CAMPAIGN_FORM,
    SET_TRACKED_CREATORS,
    SHOW_CAMPAIGN_ERROR,
    UPDATE_STORE_DETAILS
} from '../../storage/reducers/types';
import "./CreateUtmLinks.scss"
import { getDeletableProfilePromises, getNonDeletedCreators, isCampaignEnded, isEmpty, setAddedProfiles, clearCampaignTrackingWarehouse, convertDateToUtc, createAddableProfiles } from "../../utils/util";
import { trackEvent, TRACKING_CAMPAIGN_MANAGE_CREATORS_CLICKED } from "../../analytics";
import InfoActionBanner from "../../components/InfoActionBanner/InfoActionBanner";
import { Colors } from '../../styles/colors';
import { WORKPLATFORM_ID_MAPPING, WORKPLATFORM_USER_HANDLE_MAPPING } from '../../constants/constants';
import { useFetchTenantUsageInfo } from '../../hooks/useFetchTenantUsageInfo';


const CreateUtmLinks = () => {
    const navigate = useNavigate()
    const { storeId: storeIdFromParams, campaignId } = useParams();
    const { dispatchCampaignTrackingWarehouse, campaignTrackingWarehouse } = useContext(campaignTrackingContext);
    let {
        trackedCreators,
        campaignForm: {
            name: campaignName,
            brand: {
                value: brand_id,
                name: brand_name
            },
            cost,
            date: {
                fromDate: start_date,
                toDate: end_date,
            }
        },
        storeDetails
    } = campaignTrackingWarehouse;
    const [utmLink, setUtmLink] = useState(storeDetails.storeLink ? storeDetails.storeLink : "")
    const [isUtmLinkInvalid, setIsUtmLinkInvalid] = useState(false)
    const [isStoreVerified, setIsStoreVerified] = useState(storeDetails.isVerified || false)
    const [storeId, setStoreId] = useState(storeIdFromParams);
    const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
    const [isEditCampaignPage, setIsEditCampaignPage] = useState(false);
    const [isEditMenuOpen, setEditMenuOpen] = useState(false);
    const [filteredCreators, setFilteredCreators] = useState([]);
    const [isStoreLinkAlreadySetup, setIsStoreLinkAlreadySetup] = useState(false)
    const [createUtmButtonLoading, setCreateUtmButtonLoading] = useState(false)
    const [selectedCreators, setSelectedCreators] = useState(trackedCreators);
    const fetchTenantUsageInformation = useFetchTenantUsageInfo();
    const [isCreateCampaignWithStore,setIsCreateCampaignWithStore] = useState(false)
    function onSearch(searchText) {
        if (isEmpty(searchText)) {
            setFilteredCreators(campaignTrackingWarehouse?.trackedCreators?.filter(getNonDeletedCreators));
            return;
        }
        setFilteredCreators(campaignTrackingWarehouse?.trackedCreators?.filter((tc) => tc["platform_username"].includes(searchText)));
    }

    useEffect(onSearch, [campaignTrackingWarehouse]);
    useEffect(() => {

        let pathArray = window.location.pathname.split("/")
        if ((pathArray[3] === "create-utm-links")) {
            setIsEditCampaignPage(false)
            setStoreId(storeIdFromParams)
            if (campaignId) {
                setIsCreateCampaignWithStore(true)
            }
        } else if ((pathArray[3] === "edit-utm-links")) {
            setIsEditCampaignPage(true);
            setStoreId(storeIdFromParams)
        } else {
            navigate("/creators/campaigns");
        }

    }, [window.location]);

    useEffect(() => {
        if (isEditCampaignPage || isCreateCampaignWithStore) {
            getCampaignById({ warehouse, campaignId }).then((res) => {
                const { store_id, store_link, name } = res;
                if (store_link) {
                    setUtmLink(store_link);
                    setIsStoreVerified(true)
                    setIsStoreLinkAlreadySetup(true)
                    setStoreId(store_id);
                }
                dispatchCampaignTrackingWarehouse({
                    type: SET_CAMPAIGN_FORM,
                    payload: {
                        ...campaignTrackingWarehouse.campaignForm,
                        name,
                    },
                });
            });

            getAllCampaignProfiles({ warehouse, limit: 100, campaignId, created_via: CAMPAIGN_PROFILES_CREATED_VIA.DIRECT })
                .then((res) => {
                    const { data } = res;
                    data.forEach(setAddedProfiles);
                    dispatchCampaignTrackingWarehouse({
                        type: SET_TRACKED_CREATORS,
                        payload: data,
                    });
                });
        }
    }, [isEditCampaignPage, isCreateCampaignWithStore]);

    useEffect(() => {
        if (utmLink && storeId && storeDetails.storeLink && isEditCampaignPage) {
            verifyStore().then(() => {
            });
        }
    }, [utmLink, storeId]);

    const PageHeader = () => {
        return (
            <Box className={'create-utm-link-page-header'}>
                <i className="ri-arrow-left-line back-icon" onClick={() => {
                    dispatchCampaignTrackingWarehouse({
                        type: SET_TRACKED_CREATORS,
                        payload: [],
                    });
                    if (!isEditCampaignPage) {
                        dispatchCampaignTrackingWarehouse({
                            type: SET_CAMPAIGN_ACTIVE_TAB,
                            payload: campaignPageTabValues.measureROI,
                        })
                    }
                    navigate(-1);
                }}></i>
                <Box className='header-text-div'>
                    <Box
                        className={'title '}>{isEditCampaignPage ? EDIT_UTM_LINKS_HEADER : CREATE_UTM_LINKS_HEADER}</Box>
                    <Box
                        className={'body-r neutral-sec-grey-color '}>{CREATE_UTM_LINKS_DESC} <span style={{ color: "var(--neutrals-primary-grey, #121B2E)" }} >{campaignTrackingWarehouse?.campaignForm?.name}</span> </Box>
                </Box>
            </Box>)
    }

    function onSelectCreatorClick() {
        if(campaignId && !isStoreLinkAlreadySetup){
                 updateCampaign({
                    warehouse,
                    campaignId,
                    store_id: storeId,
                    store_link: utmLink
                }).then(()=>{});
        }
        let selectCreatorsState = {
            navigateDoneRoute: `/creators/campaigns/utm-links/${campaignId}`,
            doneButton: isEditCampaignPage || isCreateCampaignWithStore ? CREATE_UTM_LINKS_EDIT_FLOW : CREATE_UTM_LINKS,
            storeId,
            store_link: utmLink
        };
        navigate(`/creators/campaigns/select-creators`, { state: selectCreatorsState });
        dispatchCampaignTrackingWarehouse({
            type: SET_CAMPAIGN_ACTIVE_TAB,
            payload: campaignPageTabValues.measureROI,
        });
    }

    async function verifyStore() {
        if (utmLink.length === 0) {
            setIsStoreVerified(false)
            return
        }
        try {
            const response = await verifyStoreWebpage({ warehouse, store_id: storeId, store_link: utmLink })
            if (response.status === 400) {
                setIsUtmLinkInvalid(true)
            }
            setIsStoreVerified(response.is_valid)
            if (response.is_valid) {
                const payload = {
                    ...campaignTrackingWarehouse.storeDetails,
                    storeLink: utmLink,
                    isVerified: response.is_valid
                }
                dispatchCampaignTrackingWarehouse({
                    type: UPDATE_STORE_DETAILS,
                    payload
                })
                setIsUtmLinkInvalid(false)

            }
        } catch (error) {
            console.error(error)
            if (error.response.data.error.status_code === 400) {
                setIsUtmLinkInvalid(true)
                setIsStoreVerified(false)
            }
        }
    }

    const updateStoreDetails = async () => {
        try {
            setCreateUtmButtonLoading(true)
            // if store is already added don't update
            if (isStoreLinkAlreadySetup) {
                navigate(`/creators/campaigns/utm-links/${campaignId}`);
            } else {
                const response = await updateCampaign({
                    warehouse,
                    campaignId,
                    store_id: storeId,
                    store_link: utmLink
                });
                if (response.id) {
                    navigate(`/creators/campaigns/utm-links/${response.id}`);
                    clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setCreateUtmButtonLoading(false)
        }
    }

    const MeasureRoiCreateCampaign = async () => {
        setCreateUtmButtonLoading(true)
        const newProfiles = selectedCreators
            .filter(getNonDeletedCreators)
            .map(createAddableProfiles)
            .map(obj => obj['identifier']);

        try {
            const response = await createCampaign({
                warehouse,
                name: campaignName,
                cost,
                brand_id,
                profile_urls: newProfiles,
                start_date: convertDateToUtc({ date: start_date }),
                end_date: convertDateToUtc({ date: end_date, endDate: true }),
                storeId,
                store_link: utmLink
            });

            if (response.id) {
                navigate(`/creators/campaigns/utm-links/${response.id}`);
                fetchTenantUsageInformation().then((usageInfo) => {
                    dispatchWarehouse({ type: ADD_USAGE_QUOTA, payload: usageInfo });
                });
                clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
            } else {
                if (response.error?.code === duplicate_campaign) {
                    dispatchCampaignTrackingWarehouse({
                        type: SHOW_CAMPAIGN_ERROR,
                        payload: duplicate_campaign,
                    })
                    navigate(`/creators/campaigns/create-campaign`)
                }
            }
        } catch (e) {
            console.error('Could not create a campaign', e);
        }
        setCreateUtmButtonLoading(false)

    }

    async function deleteCreatorsAndUpdate() {
        try {
            await getDeletableProfilePromises(warehouse, campaignId, trackedCreators);
            const nonDeletedCreators = trackedCreators?.filter(getNonDeletedCreators);
            dispatchCampaignTrackingWarehouse({
                type: SET_TRACKED_CREATORS,
                payload: nonDeletedCreators,
            });
        } catch (e) {
            console.error('Could not delete creators in the edit-utm-links page');
        }
    }

    function onDrawerClose() {
        deleteCreatorsAndUpdate().then(() => {
        });
        setEditMenuOpen(false);
    }

    function onAddMoreAccounts() {
        if (!campaignId) {
            return;
        }
        getCampaignById({ warehouse, campaignId }).then((res) => {
            const { name, store_id, store_link } = res;
            dispatchCampaignTrackingWarehouse({
                type: SET_CAMPAIGN_FORM,
                payload: {
                    ...campaignTrackingWarehouse.campaignForm,
                    name,
                },
            });
            navigate(`/creators/campaigns/select-creators`, {
                state: {
                    navigateDoneRoute: `/creators/campaigns/utm-links/${campaignId}`,
                    doneButton: CREATE_UTM_LINKS_EDIT_FLOW,
                    storeId: store_id,
                    store_link: store_link,
                }
            });
        });
    }



    function UtmLinksSection() {
        return trackedCreators?.length === 0 ? (
            <>
                <Box className='sub-section-heading'>{create_utm_link.selectCreatorHeader}</Box>
                <Box mt={"8px"} className='body-r neutral-sec-grey-color'>{create_utm_link.selectCreatorsDesc}</Box>
                <br />
                <PrimaryButton className='ant-btn-primary create-utm-link-button'
                    label={<><i
                        className="ri-user-search-line button-icon"></i> {create_utm_link.buttonCTA}</>}
                    disabled={!isStoreVerified || isEmpty(utmLink) || utmLink.length === 0}
                    onClick={onSelectCreatorClick}
                />
            </>
        ) : (
            <>
                <Box className='sub-section-heading'>{create_utm_link.selectCreatorHeader}</Box>
                <Box mt={"8px"} className='body-r neutral-sec-grey-color'>{create_utm_link.selectCreatorsDesc}</Box>
                <br />
                <InfoActionBanner
                    variant={"creator-addition"}
                    title={AUTO_ADD_STEP1_TEXT(
                        trackedCreators?.length
                    )}
                    icon={<Icons.Added_creator_accounts />}
                    actionIcon={<i className="ri-edit-line"></i>}
                    actionText={MANAGE}
                    actionClick={() => {
                        trackEvent(TRACKING_CAMPAIGN_MANAGE_CREATORS_CLICKED);
                        setEditMenuOpen(!isEditMenuOpen);
                    }}
                />
            </>
        );
    }

    return (
        <Box className='create-utm-link-page'>
            <PageHeader />
            <Box className='create-utm-link-body'>
                <Box>
                    <Box className='sub-section-heading'>{create_utm_link.subsectionHeading}</Box>
                    <Box mt={"8px"} className='body-r neutral-sec-grey-color'>{create_utm_link.subsectionDesc}</Box>
                    <Box className='utm-link-input'>
                        <FilledTextField
                            label={create_utm_link.inputLabel}
                            value={utmLink}
                            onChange={(e) => {
                                setUtmLink(e.target.value.trim())
                                setIsUtmLinkInvalid(false)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    verifyStore().then(() => {
                                    });
                                }
                            }}
                            onBlur={() => verifyStore().then(() => {
                            })}
                            hasError={isUtmLinkInvalid}
                            errorMessage={
                                isUtmLinkInvalid && <>
                                    {isUtmLinkInvalid ? <Icons.caution_red /> : null} {shopifyLinkErrorMessage}
                                </>
                            }
                            disabled={isStoreLinkAlreadySetup}
                        />
                        {isStoreVerified && utmLink.length > 0 && <Box className="store-verified"> <Icons.tick /> Verified </Box>}
                    </Box>
                </Box>
                <UtmLinksSection />
                <EditCreatorDrawer
                    onClose={onDrawerClose}
                    isOpen={isEditMenuOpen}
                    heading={SIDE_DRAWER_TITLE(filteredCreators.length)}
                    onAddMoreAccounts={onSelectCreatorClick}
                    onSearch={onSearch}
                    rows={filteredCreators}
                />
                {filteredCreators.length > 0 && <Box className={'add-creators-to-campaign-container'}>
                    <PrimaryButton
                        className={'add-creators-to-campaign-button'}
                        label={<span className={'body-m'}
                            style={{ color: Colors.white }}>{isEditCampaignPage ? updateUTMLink : createUTMLink}</span>}
                        loading={createUtmButtonLoading}
                        onClick={() => isEditCampaignPage || isCreateCampaignWithStore ? updateStoreDetails() : MeasureRoiCreateCampaign()}
                        disabled={!isStoreVerified || isUtmLinkInvalid}
                    />
                </Box>}
            </Box>
        </Box>
    )

}

export default CreateUtmLinks;