import React from "react";
import "./LineChartDistribution.scss";
import { Chart, ArgumentAxis, ValueAxis, LineSeries } from "@devexpress/dx-react-chart-material-ui";
import Icons from "../Icons/Icons";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import { convertToShortNumber } from "../../utils/util";
import { CartesianGrid, Line, LineChart, XAxis, YAxis, Tooltip } from "recharts";
import { Colors } from "../../styles/colors";
import { formatNumber } from "../../utils/profileHelpers";

const LineChartDistribution = ({ width, height, data, xAxisKey, yAxisKey, yTickFormatter }) => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="MuiTooltip-tooltip">
          <div className="label">{label}</div>
          <div className="desc">{formatNumber(payload[0].value)}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <LineChart width={width} height={height} data={data}>
      <CartesianGrid stroke={Colors.neutralsBorderGrey} strokeDasharray="5" vertical={false} />
      <XAxis
        dataKey={xAxisKey}
        tickLine={false}
        tick={{ fontSize: 12, fill: Colors.neutralsPrimaryGrey }}
        axisLine={{ stroke: Colors.neutralsBorderGrey, strokeWidth: 1 }}
        padding={{ left: 40 }}
      />
      <YAxis
        axisLine={false}
        tickLine={false}
        tick={{ fontSize: 12, fill: Colors.neutralsPrimaryGrey }}
        tickFormatter={yTickFormatter}
        domain={["auto", "auto"]}
        width={60}
      />
      <Tooltip content={<CustomTooltip />} />
      <Line type="linear" dataKey={yAxisKey} stroke={Colors.mainPrimary} strokeWidth={4} dot={false} />
    </LineChart>
  );
};

// const Label = (props) => {
//   const { text } = props;
//   const YAxixValue = convertToShortNumber(text.replace(",", ""));
//   return (
//     <ValueAxis.Label
//       {...props}
//       text={YAxixValue}
//       style={{
//         fontFamily: "Inter",
//         fontStyle: "normal",
//         fontWeight: 500,
//         fontSize: "12px",
//         lineHeight: "100%",
//         textAlign: "center",
//         color: "#313544",
//       }}
//     />
//   );
// };

// const XLabel = (props) => {
//   const { text } = props;
//   return (
//     <ArgumentAxis.Label
//       {...props}
//       text={text}
//       style={{
//         fontFamily: "Inter",
//         fontStyle: "normal",
//         fontWeight: 500,
//         fontSize: "12px",
//         lineHeight: "100%",
//         textAlign: "center",
//         color: "#313544",
//       }}
//     />
//   );
// };

// const LineChart = ({ loading, header, yAxisMinValue, yAxisMaxValue, color = "var(--main-primary)", yAxisField, xAxisField, endpoints, tooltip }) => {
//   return loading ? (
//     <LineChartSkeletonComponent />
//   ) : (
//     <div className="line-chart-container">
//       <div className="line-chart-header-container">
//         <div className="line-chart-header">{header}</div>
//         <Tooltip title={tooltip} placement="top">
//           <Icons.info_icon style={{ cursor: "pointer" }} />
//         </Tooltip>
//       </div>
//       <div className="chart-container">
//         <Chart data={endpoints}>
//           <ArgumentAxis tickFormat={(tick) => tick} labelComponent={XLabel} />
//           <ValueAxis max={yAxisMaxValue} min={yAxisMinValue} labelComponent={Label} showGrid={false} />
//           <LineSeries valueField={yAxisField} argumentField={xAxisField} color={color} />
//         </Chart>
//       </div>
//     </div>
//   );
// };

const LineChartSkeletonComponent = () => {
  return (
    <div className="line-chart-container">
      <Skeleton animation="wave" height={16} width="100%" />
      <Skeleton animation="wave" height={260} width="100%" />
    </div>
  );
};

LineChartDistribution.propTypes = {
  loading: PropTypes.bool,
  header: PropTypes.string,
  yAxisMinValue: PropTypes.number,
  yAxisMaxValue: PropTypes.number,
  color: PropTypes.string,
  yAxisField: PropTypes.string,
  xAxisField: PropTypes.string,
  endpoints: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

export default LineChartDistribution;
