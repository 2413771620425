import { Box } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
    CampaignPageHeader,
    ConnectShopifyMainContent,
    ContentGrid,
    ContextualMenu,
    EmptyTableScreen,
    ExportLoadingModal,
    FilterMenu,
    Icons,
    InfoActionBanner,
    Loader,
    ModalDialog,
    PerformanceMetric,
    Separator,
    TrackedPostsMainContent,
    Typography,
    UnlockFullAccessPopUp,
} from "../../components";
import {
    ADD_BRAND_ACCOUNTS,
    ADD_CREATORS_ENTRY_SOURCE,
    allCreatorsAccounts,
    allPlatforms,
    average,
    CAMPAIGN_BRAND_PROFILES_DONE_TITLE,
    CAMPAIGN_BRAND_PROFILES_PARTIAL,
    CAMPAIGN_BRAND_PROFILES_TITLE,
    CAMPAIGN_PROFILES_CREATED_VIA,
    campaign_view_loading_msg,
    campaignComingSoon,
    campaignPageTabValues,
    comments,
    commentsAnalyzedText,
    ContentGridColumns,
    contentGridHeader,
    cpe,
    cpm,
    cpv,
    creatorAccounts,
    EDIT_BRAND_ACCOUNTS,
    EDIT_CAMPAIGN_DETAILS,
    editCreatorsAndKeywordsText,
    editPostLinksText,
    engagement_rate,
    engagements,
    impressions,
    likes,
    MANAGE_POSTS,
    MEASURE_ROI_CAMPAIGN_ENDED,
    performance,
    performanceTabValues,
    perPost,
    PostTypes,
    purchaseIntentCount,
    shares,
    tmv,
    tooltip,
    TOP_HEADER_INFO_TRIAL_MODE_CAMPAIGN,
    total,
    total_engagement,
    totalPositiveComments,
    totalRelevantComments,
    TRACK_POST_CAMPAIGN_ENDED,
    TrackPostCampaignComingSoon,
    unlockAccessPopupDetails,
    userAccounts,
    views,
} from "../../constants/campaignTrackingConstants";
import {
    campaignTrackingProductID,
    DATE_FORMAT,
    DATE_TIME_FORMAT_STATUS,
    DELETE,
    DELETE_CAMPAIGN_TITLE,
    EXPORT_REPORT,
    exportCSVReport,
    exportingCsv,
    exportingPdf,
    exportPDFReport,
    JOB_STATUS,
    POSITIVE_AUDIENCE_SENTIMENT,
    POSITIVE_AUDIENCE_SENTIMENT_TOOLTIP,
    PURCHASE_INTENT,
    PURCHASE_INTENT_TOOLTIP,
    RELEVANT_COMMENTS,
    RELEVANT_COMMENTS_TOOLTIP,
    TRIAL,
    WORKPLATFORM_ID_MAPPING,
} from "../../constants/constants";
import { formatNumber, getWorkPlatformIdByPlatformName } from "../../utils/profileHelpers";
import "./CampaignPage.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
    deleteCampaign,
    deleteContentFromCampaignByContentIds,
    downloadCampaignPdf,
    downloadPerformanceMetricsCsv,
    getAllBrands,
    getAllCampaignProfiles,
    getAllCampaignsContents,
    getCampaignAggregatedReport,
    getCampaignById,
    getFirebaseAuthToken,
    getShopifyStores
} from "../../api/CampaignTrackingAPI/CampaignTrackingAPI";
import { CampaignTrackingContext, WarehouseContext } from "../../storage/context";
import { Skeleton } from "@mui/material";
import {
    convertTimeToLocale,
    downloadFile,
    getIdentifiers,
    getNonDeletedCreators,
    isCampaignEnded,
    isEmpty,
    workPlatformsDropdownOptions
} from "../../utils/util";
import {
    trackEvent,
    TRACKING_CAMPAIGN_BACK_CLICKED,
    TRACKING_CAMPAIGN_CONTENT_CLICKED,
    TRACKING_CAMPAIGN_CSV_EXPORTED,
    TRACKING_CAMPAIGN_DELETE_CAMPAIGNS_CLICKED,
    TRACKING_CAMPAIGN_EDIT_CAMPAIGNS_CLICKED,
    TRACKING_CAMPAIGN_EDIT_CREATORS_CLICKED,
    TRACKING_CAMPAIGN_EDIT_LINKS_CLICKED,
    TRACKING_CAMPAIGN_MANAGE_POST_CLICKED,
    TRACKING_CAMPAIGN_PDF_EXPORTED,
    TRACKING_CAMPAIGN_PERFORMANCE_CLICKED,
    TRACKING_CAMPAIGN_PLATFORM_FILTERED,
    TRACKING_CAMPAIGN_POST_FILTERED,
    TRACKING_UPGRADE_POPUP_OPENED,
} from "../../analytics";
import {
    ADD_USAGE_QUOTA,
    HIDE_TOP_HEADER,
    SET_CAMPAIGN_ACTIVE_TAB,
    SET_TRACKED_CREATORS,
    SHOW_TOP_HEADER
} from "../../storage/reducers/types";
import { Colors } from "../../styles/colors";
import TabSwitch from "../../components/TabSwitch/TabSwitch";
import moment from 'moment-timezone';
import AddBrandProfilesPopup from "../../components/AddBrandProfilesPopup/AddBrandProfilesPopup";
import MeasureRoiPage from "./MeasureRoiPage";
import CampaignSyncStatus from "../../components/CampaignSyncStatus/CampaignSyncStatus";
import { useFetchTenantUsageInfo } from "../../hooks/useFetchTenantUsageInfo";
import { Outline } from "../CreatorDiscoveryPage/CreatorDiscoveryCommentAnalysis";
import { getCampaignDocumentReference } from "../../api/firebase/api";
import { onSnapshot } from "firebase/firestore";
import { getAuth, onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

const { allTypes, feed, image, post, reels, video, story } = PostTypes;

const engagementMetrics = [
    {
        name: `${total} ${likes}`,
        icon: <i className="ri-heart-3-line metric-icon"></i>,
        path: "likes",
        variant: "metricIcon",
        hasAvgValue: true,
        avgValuePath: "avg_likes",
        avgMetricName: `${average} ${likes.toLowerCase()}`,
    },
    {
        name: `${total} ${comments}`,
        icon: <i className="ri-chat-3-line metric-icon"></i>,
        path: "comments",
        variant: "metricIcon",
        hasAvgValue: true,
        avgValuePath: "avg_comments",
        avgMetricName: `${average} ${comments}`,
    },
    {
        name: `${total} ${views}`,
        icon: <i className="ri-eye-line metric-icon"></i>,
        path: "views",
        variant: "infoMetricIcon",
        hasAvgValue: true,
        avgValuePath: "avg_views",
        avgMetricName: `${average} ${views}`,
        tooltip: tooltip.total_views,
    },
    {
        name: `${total} ${shares} on TikTok`,
        icon: <i className="ri-share-forward-line metric-icon"></i>,
        path: "shares",
        variant: "infoIcon",
        hasAvgValue: true,
        avgValuePath: "avg_shares",
        avgMetricName: `${average} ${shares}`,
        tooltip: tooltip.shareOnTikTok,
    },
    {
        name: total_engagement,
        path: "engagements",
        variant: "infoIcon",
        hasAvgValue: true,
        tooltip: tooltip.total_engagement,
        avgValuePath: "avg_engagement",
        avgMetricName: `${average} ${engagements}`,
    },
    {
        name: "Total " + impressions.toLowerCase(),
        path: "impressions",
        variant: "infoIcon",
        hasAvgValue: true,
        avgValuePath: "avg_impressions",
        tooltip: tooltip.estimated_impressions,
        avgMetricName: `${average} ${impressions}`,
    },
];

const monetaryMetrics = [
    {
        name: cpe.name,
        path: "cost_per_engagement",
        variant: "infoIcon",
        prefix: "$",
        tooltip: cpe.tooltip,
        shouldFormat: false,
    },
    {
        name: cpm.name,
        path: "cost_per_mille",
        variant: "infoIcon",
        prefix: "$",
        tooltip: cpm.tooltip,
        shouldFormat: false,
    },
    {
        name: cpv.name,
        path: "cost_per_view",
        variant: "infoIcon",
        prefix: "$",
        tooltip: cpv.tooltip,
        shouldFormat: false,
    },
    {
        name: tmv.name,
        path: "total_media_value",
        variant: "infoMetricIcon",
        prefix: "$",
        tooltip: tmv.tooltip,
        shouldFormat: true,
        metricIcon: <i className="ri-hand-coin-line metric-icon"></i>,
    },
];

const commentsMetrics = [
    {
        name: RELEVANT_COMMENTS,
        path: "avg_relevance_score",
        variant: "infoIcon",
        suffix: "%",
        tooltip: RELEVANT_COMMENTS_TOOLTIP,
        shouldFormat: false,
        totalMetricName: totalRelevantComments,
        totalValuePath: "total_relevant_comments"
    },
    {
        name: POSITIVE_AUDIENCE_SENTIMENT,
        path: "positive_sentiment_score",
        variant: "infoIcon",
        suffix: "%",
        tooltip: POSITIVE_AUDIENCE_SENTIMENT_TOOLTIP,
        shouldFormat: false,
        totalMetricName: totalPositiveComments,
        totalValuePath: "total_positive_comments"
    },
    {
        name: PURCHASE_INTENT,
        path: "purchase_intent_score",
        variant: "infoIcon",
        suffix: "%",
        tooltip: PURCHASE_INTENT_TOOLTIP,
        shouldFormat: false,
        totalMetricName: purchaseIntentCount,
        totalValuePath: "total_purchase_intent_comment_count"
    },
];

const platformWisePostTypes = {
    [workPlatformsDropdownOptions[0].value]: [video, reels, post, image, story],
    [workPlatformsDropdownOptions[1].value]: [video],
    [workPlatformsDropdownOptions[2].value]: [video],
};

const performanceTabs = [
    {
        label: performanceTabValues.social,
    },
    {
        label: performanceTabValues.monetary,
    },
    {
        label: performanceTabValues.comments,
    },
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const CampaignPage = () => {
    const { campaignId } = useParams();
    const navigate = useNavigate();
    const [campaignIdJobStatus, setCampaignIdJobStatus] = useState(JOB_STATUS.NOT_STARTED);
    const [campaignByIdResponse, setCampaignByIdResponse] = useState(null);
    const [campaignBrands, setCampaignBrands] = useState([]);
    const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
    const [campaignBrand, setCampaignBrand] = useState("");
    const [performanceMetrics, setPerformanceMetrics] = useState({});
    const [showDeleteFromCampaign, setShowDeleteFromCampaign] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState({
        [allPlatforms]: true,
        [workPlatformsDropdownOptions[0].value]: true,
        [workPlatformsDropdownOptions[1].value]: true,
        [workPlatformsDropdownOptions[2].value]: true,
    });
    const [selectedPostTypes, setSelectedPostTypes] = useState({
        [allTypes]: true,
        [video]: true,
        [reels]: true,
        [image]: true,
        [post]: true,
        [story]: true
    });
    const [filterBody, setFilterBody] = useState({});
    const [totalContentCount, setTotalContentCount] = useState(0);
    const { dispatchCampaignTrackingWarehouse, campaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const [currTab, SetCurrTab] = useState(performanceTabValues.social);
    const [modalDetails, setModalDetails] = useState({
        isModalVisible: false,
        modalInfo: "",
    });
    const editCampaignMenu = [{
        icon: <i className="ri-group-line"></i>,
        text: editCreatorsAndKeywordsText,
        onClick: () => {
            navigate(`/creators/campaigns/automatically-add-creators/${campaignId}`);
            trackEvent(TRACKING_CAMPAIGN_EDIT_CREATORS_CLICKED);
        },
    }, {
        icon: <i className="ri-links-line"></i>,
        text: editPostLinksText,
        onClick: () => {
            navigate(`/creators/campaigns/edit-post-links/${campaignId}`);
            trackEvent(TRACKING_CAMPAIGN_EDIT_LINKS_CLICKED);
        },
    }];

    const exportReportMenu = [{
        text: exportPDFReport,
        onClick: () => onExportClick(exportPdfState),
    }, {
        text: exportCSVReport,
        onClick: () => onExportClick(exportCsvState),
    }];

    const [isCampaignComingSoon, setIsCampaignComingSoon] = useState(false);
    const [brandSocialsCount, setBrandSocialsCount] = useState(0);
    const [showAddBrandProfilesPopup, setShowAddBrandProfilesPopup] = useState(false);
    const [socialsJustAdded, setSocialsJustAdded] = useState(false);
    const [hasTenantConnectedStores, setHasTenantConnectedStores] = useState(false);
    const [hasCampaignConnectedStores, setHasCampaignConnectedStores] = useState(false);
    const [hasContents, setHasContents] = useState(false);
    const [hasIdentifiers, setHasIdentifiers] = useState(false);
    const [profileCount, setProfileCount] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [hasUnsavedContent, setHasUnsavedContent] = useState(false);
    const fetchTenantUsageInformation = useFetchTenantUsageInfo();
    const isStoreFetched = useRef(true)
    const [shopifyStores, setShopifyStores] = useState([]);
    const isTrialMode = warehouse.tenantAppProducts[campaignTrackingProductID] === TRIAL;
    const [isExportLoadingModalVisible, setExportLoadingModalVisible] = useState(false);
    const exportCsvState = {
        key: 'CSV',
        value: exportingCsv,
    };
    const exportPdfState = {
        key: 'PDF',
        value: exportingPdf,
    };
    const [exportModalState, setExportModalState] = useState({ key: '', value: '' });
    const campaignPageTabs = [{
        label: campaignPageTabValues.trackPosts,
    }, {
        label: campaignPageTabValues.measureROI,
    }];
    const defaultContentSortModel = [{ field: ContentGridColumns.POSTED_ON.field, sort: "desc" }];
    const [contentSortModel, setContentSortModel] = useState(defaultContentSortModel);
    const {
        trackedCreators,
        postIdentifiers,
    } = campaignTrackingWarehouse;
    const [creatorAccountsMenu, setCreatorAccountsMenu] = useState([])
    const [selectedAccounts, setSelectedAccounts] = useState({})
    const [allCreatorsClicked, setAllCreatorsClicked] = useState(false);

    useEffect(() => {
        const { hashtags, keywords, mentions } = getIdentifiers(postIdentifiers);
        setHasUnsavedContent(
            trackedCreators?.filter(getNonDeletedCreators).length !== 0
            && (!isEmpty(hashtags) || !isEmpty(keywords) || !isEmpty(mentions)));
    }, [postIdentifiers, trackedCreators]);

    async function initCampaign() {
        try {
            setLoading(true);
            await getCampaignByIdApi();
            const shopifyStores = await getShopifyStores({ warehouse, status: 'CONNECTED' });
            if (shopifyStores && shopifyStores.length > 0) {
                setHasTenantConnectedStores(true);
            } else {
                setHasTenantConnectedStores(false);
            }
            const contentUrls = await getAllCampaignsContents({
                offset: 0,
                limit: 100,
                campaignId,
                warehouse,
            });
            if (contentUrls?.data && contentUrls.data.length > 0) {
                setHasContents(true);
            }
            const profiles = await getAllCampaignProfiles({
                warehouse,
                campaignId,
                created_via: CAMPAIGN_PROFILES_CREATED_VIA.DIRECT,
            });
            !isEmpty(profiles) && setProfileCount(Number(profiles['total_count']));
            updateCreatorAccountFilter(profiles?.data)
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    function updateFilterBody() {
        const searchParams = new URLSearchParams(window.location.search);

        const workPlatformIds = searchParams.get('work_platform_ids');
        const contentTypes = searchParams.get('content_types');
        const campaign_profile_ids = searchParams.get('campaign_profile_ids');

        let filterObj = {}
        if (contentTypes) filterObj.content_types = contentTypes.split(',')
        if (workPlatformIds) filterObj.work_platform_ids = workPlatformIds.split(',')
        if (campaign_profile_ids) filterObj.campaign_profile_ids = campaign_profile_ids.split(',')
        setFilterBody(filterObj)

        if (!workPlatformIds) return
        const updatedSelectedPlatform = { ...selectedPlatform };

        for (const key in updatedSelectedPlatform) {
            if (updatedSelectedPlatform.hasOwnProperty(key)) {
                const platformId = getWorkPlatformIdByPlatformName(key)
                updatedSelectedPlatform[key] = workPlatformIds.split(',').includes(platformId);
            }
        }
        setSelectedPlatform(updatedSelectedPlatform);

    }

    function updatePostTypeFromURL(updatedSelectedPostTypes) {
        const searchParams = new URLSearchParams(window.location.search);

        const contentTypes = searchParams.get('content_types');

        if (!contentTypes) return;

        const contentTypesArray = contentTypes ? contentTypes.split(',') : [];
        for (let key in updatedSelectedPostTypes) {
            if (key === post) {
                key = feed;
            }
            if (updatedSelectedPostTypes.hasOwnProperty(key)) {
                updatedSelectedPostTypes[key] = contentTypesArray.includes(key.toLocaleUpperCase())
            }
        }
        setSelectedPostTypes(updatedSelectedPostTypes)

    }

    function updateProfileIdsFromURL(updatedSelectedProfileIds) {
        const searchParams = new URLSearchParams(window.location.search);

        const profilesIds = searchParams.get('campaign_profile_ids');

        if (!profilesIds) return;

        const profilesIdsArray = profilesIds ? profilesIds.split(',') : [];
        if (Object.keys(updatedSelectedProfileIds).length - 1 === profilesIdsArray.length) return
        for (let key in updatedSelectedProfileIds) {
            if (updatedSelectedProfileIds.hasOwnProperty(key)) {
                updatedSelectedProfileIds[key] = profilesIdsArray.includes(key)
            }
        }
        setSelectedAccounts(updatedSelectedProfileIds)
    }

    async function updateCreatorAccountFilterFromSearch(searchString) {
        const profiles = await getAllCampaignProfiles({
            warehouse,
            campaignId,
            search_string: searchString,
            created_via: CAMPAIGN_PROFILES_CREATED_VIA.DIRECT
        });
        updateCreatorAccountFilter(profiles?.data)
    }

    useEffect(() => {
        if (!allCreatorsClicked) return
        const allChecked = !selectedAccounts[allCreatorsAccounts];
        const updatedSelection = { ...selectedAccounts };
        for (const account in selectedAccounts) {
            updatedSelection[account] = allChecked;
        }
        setSelectedAccounts(updatedSelection);

        const updatedCreatorAccountsMenu = [...creatorAccountsMenu]; // Create a copy of the array

        if (updatedCreatorAccountsMenu.length > 0) {
            updatedCreatorAccountsMenu[0].checked = allChecked; // Update the checked value of the first object
        }

        setCreatorAccountsMenu(updatedCreatorAccountsMenu);
        setAllCreatorsClicked(false)
    }, [allCreatorsClicked])

    useEffect(() => {
        initCampaign().then(() => {
        });

        fetchTenantUsageInformation().then((usageInfo) => {
            dispatchWarehouse({ type: ADD_USAGE_QUOTA, payload: usageInfo });
        });

        updateFilterBody()

    }, [campaignId]);

    useEffect(() => {
        if (campaignIdJobStatus === JOB_STATUS.FAILURE) {
            console.error(`Campaign details not found for campaignId ${campaignId}`);
            navigate("/creators/campaigns");
        }
    }, [campaignIdJobStatus]);

    function onGetCampaignSuccess(res) {
        setCampaignByIdResponse(res);
        setTotalContentCount(res?.total_content_count);
        setCampaignIdJobStatus(JOB_STATUS.SUCCESS);
    }

    function updateCampaignOnSync(unsubscribeAuth) {
        const campaignDocRef = getCampaignDocumentReference(warehouse.tenantInfo[0]?.apps[0]?.id, campaignId);
        const unsubscribe = onSnapshot(campaignDocRef, handleSnapshot, handleError);

        function handleSnapshot(docSnapshot) {
            if (docSnapshot.exists()) {
                // Document exists, you can access its data
                if (isEmpty(docSnapshot.data().synced_at)) {
                    logEvent(getAnalytics(), 'error-campaign_synced_at_empty', {
                        campaignId,
                        tenantAppId: warehouse.tenantInfo[0]?.apps[0]?.id
                    });
                    throw new Error('Campaign synced_at is empty, this is an error');
                } else {
                    getCampaignById({ warehouse, campaignId }).then(onGetCampaignSuccess);
                }
                unsubscribe();
                unsubscribeAuth && unsubscribeAuth();
            }
        }

        function handleError(error) {
            logEvent(getAnalytics(), 'error-campaign_sync_snapshot', {
                campaignId,
                tenantAppId: warehouse.tenantInfo[0]?.apps[0]?.id
            });
            unsubscribe();
            unsubscribeAuth && unsubscribeAuth();
            throw error;
        }
    }

    function checkSyncStatusAndUpdate() {
        const unsubscribeAuth = onAuthStateChanged(getAuth(), (user) => {
            if (user && user.uid === warehouse.tenantInfo[0]?.apps[0]?.id) {
                updateCampaignOnSync(unsubscribeAuth);
            } else {
                getFirebaseAuthToken({ warehouse }).then(signIn);
            }
        });

        function signIn(token) {
            signInWithCustomToken(getAuth(), token)         // no need for then() since auth listener will handle
                .catch((e) => {
                    logEvent(getAnalytics(), 'error-user_not_authorized', { token, name: e.name, message: e.message });
                    console.error('Firebase app not authorized. Campaign creation will not work');
                    unsubscribeAuth && unsubscribeAuth();
                    throw e;
                });
        }
    }

    async function getCampaignByIdApi() {
        try {
            if (!!!campaignId) {
                return;
            }

            const res = await getCampaignById({ warehouse, campaignId });
            if (isEmpty(res)) {
                // show error
                setCampaignIdJobStatus(JOB_STATUS.FAILURE);
                return;
            }
            onGetCampaignSuccess(res);

            // If campaign yet to start, then no need to listen for synced_at
            if (moment(res.start_date).toDate() > new Date() || !isEmpty(res.synced_at)) {
                return;
            }

            checkSyncStatusAndUpdate();

        } catch (e) {
            setCampaignIdJobStatus(JOB_STATUS.FAILURE);
            console.error(e);
        }
    }

    async function removeContentApi({ campaignId, contentId }) {
        if (!!!campaignId || !!!contentId) {
            return;
        }
        try {
            await deleteContentFromCampaignByContentIds({ warehouse, campaignId, content_ids: [contentId] });
            await initCampaign();
        } catch (e) {
            console.error(e);
        }
    }

    const handleAccountClick = (accountValue) => {
        setSelectedAccounts((prevState) => ({
            ...prevState,
            [accountValue]: !prevState[accountValue],
            [allCreatorsAccounts]: false,
        }));
    };


    useEffect(() => {
        if (isEmpty(creatorAccountsMenu)) {
            return;
        }
        const updatedCreatorAccountsMenu = [...creatorAccountsMenu];
        updatedCreatorAccountsMenu.forEach((account) => {
            if (account.work_platform_id) account.checked = selectedAccounts[account.id];
        });
        setCreatorAccountsMenu(updatedCreatorAccountsMenu);
    }, [selectedAccounts])

    function updateCreatorAccountFilter(data) {
        const creatorAccountOptions = [];
        const selectedAccountsState = {
            [allCreatorsAccounts]: true
        };
        const allCreatorAccount = {
            icon: <></>,
            text: allCreatorsAccounts,
            checked: selectedAccounts[allCreatorsAccounts] === undefined ? true : selectedAccounts[allCreatorsAccounts],
            isVisible: true,
            onClick: () => setAllCreatorsClicked(true),
        }

        data.forEach((obj) => {
            const {
                thumbnail_url,
                platform_username,
                fullname: name,
                is_verified,
                work_platform_id,
                id,
            } = obj;

            const newObj = {
                thumbnail_url,
                username: platform_username,
                name,
                is_verified,
                work_platform_id,
                id,
                text: id,
                checked: !!!selectedAccounts[id],
                onClick: () => {
                    handleAccountClick(id)
                }
            };

            creatorAccountOptions.push(newObj);
            selectedAccountsState[id] = true;
        });
        setCreatorAccountsMenu([allCreatorAccount, ...creatorAccountOptions])
        setSelectedAccounts(selectedAccountsState);
        updateProfileIdsFromURL(selectedAccountsState)

    }

    useEffect(() => {
        if (isEmpty(campaignByIdResponse)) {
            return;
        }
        // TODO Do error handling
        getAllBrands({ warehouse, limit: 100 }).then((v) => {
            setCampaignBrands(v.data);
        });

        const { hashtags, keywords, mentions } = campaignByIdResponse;
        setHasIdentifiers(!isEmpty(hashtags) || !isEmpty(keywords) || !isEmpty(mentions));
        setIsCampaignComingSoon(moment(campaignByIdResponse.start_date).toDate() > new Date());

        if (!isEmpty(campaignByIdResponse?.synced_at)) {
            getCampaignAggregatedReport({
                warehouse,
                campaignId,
            }).then(setPerformanceMetrics);
        }
    }, [campaignByIdResponse]);

    useEffect(() => {
        if (campaignByIdResponse?.store_id) {
            setHasCampaignConnectedStores(true);
        }
    }, [campaignByIdResponse?.store_id]);

    useEffect(() => {
        if (!campaignBrands || !campaignByIdResponse || !campaignByIdResponse?.brand_id) {
            return;
        }
        // TODO Set campaign name here
        const brandObj = campaignBrands?.find((obj) => obj.id === campaignByIdResponse?.brand_id);
        if (!!brandObj) {
            setCampaignBrand({ name: brandObj.name, value: brandObj.id });
            setBrandSocialsCount(brandObj.profiles?.length || 0);
        }
    }, [campaignBrands, campaignByIdResponse]);

    useEffect(() => {
        if (campaignByIdResponse?.is_trial) {
            dispatchWarehouse({
                type: SHOW_TOP_HEADER,
                payload: {
                    scrollable: true,
                    title: TOP_HEADER_INFO_TRIAL_MODE_CAMPAIGN,
                    backgroundColor: Colors.accentMidnightGreen,
                    textClass: "top-header-status-text top-header-status-text-success",
                },
            });
        } else {
            dispatchWarehouse({ type: HIDE_TOP_HEADER });
        }
    }, [campaignByIdResponse?.is_trial]);

    function handleContentClick(contentId, url, work_platform_id, synced_at) {
        if (!contentId) {
            console.error("Content ID not found when clicking on content row");
            return;
        }
        trackEvent(TRACKING_CAMPAIGN_CONTENT_CLICKED, {
            name_of_campaign: campaignByIdResponse?.name,
            url_name: url,
            platform_name: WORKPLATFORM_ID_MAPPING[work_platform_id].platformName,
            last_refreshed_time: `${synced_at}`,
        });

        navigate(`/creators/campaigns/campaign-content-view/${campaignByIdResponse.id}/${contentId}`);
    }

    const CampaignPerformanceMetrics = () => {

        function SocialMetricsTab() {
            return (
                <div className="campaignPerformanceMetrics" id={'div-social-metrics'}>
                    {engagementMetrics?.map((metric, index) => {
                        const { variant, path, name, icon, tooltip, avgMetricName } = metric;
                        const total = formatNumber(performanceMetrics[path].total);
                        const subtitleVal = performanceMetrics[path].average !== null ? formatNumber(performanceMetrics[path].average) : "-";
                        return (
                            <div id={`social-performance-${path}`} key={index}>
                                <PerformanceMetric key={index} variant={variant} content={total} metricName={name}
                                                   metricIcon={icon} tooltip={tooltip}/>
                                <hr/>
                                <div className={"metrics-subtitle subtitle-r"}>
                                    {subtitleVal}{" "}
                                    <span>
                                        {avgMetricName} {perPost}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                    {
                        <div id={'social-performance-engagement_rate'}>
                            <PerformanceMetric
                                variant={"infoIcon"}
                                content={performanceMetrics.engagement_rate.average !== null ? `${performanceMetrics.engagement_rate.average.toFixed(2)} %` : "-"}
                                metricName={engagement_rate}
                                tooltip={tooltip.engagement_rate}
                            />
                        </div>
                    }
                </div>
            );
        }

        function MonetaryMetricsTab() {
            return (
                <div className="campaignPerformanceMetrics" id={'div-monetary-metrics'}>
                    {monetaryMetrics?.map((metric, index) => {
                        const { variant, path, name, tooltip, prefix, shouldFormat, metricIcon } = metric;
                        const value =
                            performanceMetrics?.cost_metrics[path] !== null
                                ? shouldFormat
                                    ? `${prefix}${formatNumber(performanceMetrics.cost_metrics[path])}`
                                    : `${prefix}${performanceMetrics.cost_metrics[path].toFixed(2)}`
                                : "-";
                        return (
                            <div key={index} id={`monetary-performance-${path}`}>
                                <PerformanceMetric key={index} variant={variant} content={value} metricName={name}
                                                   tooltip={tooltip} metricIcon={metricIcon}/>
                            </div>
                        );
                    })}
                </div>
            );
        }

        function CommentMetricsTab() {
            if (!campaignByIdResponse?.is_trial) {
                if (socialsJustAdded) {
                    return (
                        <InfoActionBanner
                            title={<span className={'body-m'}
                                         style={{ width: 580 }}>{CAMPAIGN_BRAND_PROFILES_DONE_TITLE}</span>}
                            variant={'brand-profiles-grey'}
                            icon={<Icons.clock_icon/>}
                            actionText={EDIT_BRAND_ACCOUNTS}
                            actionIcon={<i className="ri-edit-line"></i>}
                            actionClick={() => setShowAddBrandProfilesPopup(true)}
                        />
                    );
                }
                if (brandSocialsCount === 0) {
                    return (
                        <InfoActionBanner
                            title={<span className={'body-m'}
                                         style={{ width: 580 }}>{CAMPAIGN_BRAND_PROFILES_TITLE}</span>}
                            variant={'brand-profiles'}
                            icon={<Icons.comment_icon/>}
                            actionText={ADD_BRAND_ACCOUNTS}
                            actionIcon={<i className="ri-add-line"></i>}
                            actionClick={() => setShowAddBrandProfilesPopup(true)}
                        />
                    );
                }
            }

            return (
                <>
                    {performanceMetrics.comment_analyses.total_analysed_comments ?
                        <div style={{ marginBottom: "32px" }}>
                            <Outline title={null}
                                     subTitle={commentsAnalyzedText(formatNumber(performanceMetrics.comment_analyses.total_analysed_comments))}
                                     showTooltip></Outline>
                        </div> : null}
                    <div className="campaignPerformanceMetrics" id={'div-comment-metrics'}>
                        {commentsMetrics?.map((metric, index) => {
                            const {
                                variant,
                                path,
                                name,
                                tooltip,
                                shouldFormat,
                                suffix,
                                totalMetricName,
                                totalValuePath
                            } = metric;
                            const value =
                                performanceMetrics?.comment_analyses[path] !== null
                                    ? shouldFormat
                                        ? `${formatNumber(performanceMetrics.comment_analyses[path] * 100)}${suffix}`
                                        : `${(performanceMetrics.comment_analyses[path] * 100).toFixed(2)}${suffix}`
                                    : "-";
                            const subtitleVal = performanceMetrics.comment_analyses[totalValuePath] === null ? "-" : formatNumber(performanceMetrics.comment_analyses[totalValuePath]);
                            return (
                                <div key={index} id={`comments-performance-${path}`}>
                                    <PerformanceMetric key={index} variant={variant} content={value} metricName={name}
                                                       tooltip={tooltip}/>
                                    <hr/>
                                    <div className={"metrics-subtitle subtitle-r"}>
                                        {subtitleVal}{" "}
                                        <span>
                                            {totalMetricName}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {brandSocialsCount !== 3 ?
                        <Box style={{ marginTop: "40px" }}>
                            <InfoActionBanner
                                title={<span
                                    style={{ width: 580 }}>{CAMPAIGN_BRAND_PROFILES_PARTIAL(brandSocialsCount)}</span>}
                                variant={'brand-profiles-alert'}
                                icon={<Icons.comment_icon_yellow/>}
                                actionText={ADD_BRAND_ACCOUNTS}
                                actionIcon={<i color={Colors.semanticsPrimaryAlert} className="ri-add-line"></i>}
                                actionClick={() => setShowAddBrandProfilesPopup(true)}
                            />
                        </Box> : null
                    }

                </>
            );
        }

        return (
            <Box className="campaign-performance-metrics-container">
                <Box className="section-heading">{performance}</Box>
                <Box className="tab-switch-main-div">
                    <TabSwitch
                        handleTabChange={(ev, value) => {
                            const selectedPlatforms = Object.keys(selectedPlatform).filter((platform) => platform !== allPlatforms && selectedPlatform[platform]);
                            const selectedPostTypesList = Object.keys(selectedPostTypes).filter((type) => type !== allTypes && selectedPostTypes[type]);
                            trackEvent(TRACKING_CAMPAIGN_PERFORMANCE_CLICKED, {
                                name_of_tab: value,
                                platform_filter: selectedPlatforms,
                                post_type: selectedPostTypesList,
                                last_updated_at: convertTimeToLocale(campaignByIdResponse?.synced_at, DATE_TIME_FORMAT_STATUS)
                            });
                            SetCurrTab(value);
                        }}
                        aria-label="icon position tabs example"
                        currentTab={currTab}
                        tabs={performanceTabs}
                        height="55px"
                        width="fit-content"
                    />
                </Box>
                <TabPanel index={performanceTabValues.social} value={currTab} sx={{ margin: "0px", padding: "0px" }}
                          className="search-type-tab tabpanel-social-metrics">
                    <SocialMetricsTab/>
                </TabPanel>
                <TabPanel index={performanceTabValues.monetary} value={currTab} sx={{ margin: "0px", padding: "0px" }}
                          className="search-type-tab">
                    <MonetaryMetricsTab/>
                </TabPanel>
                <TabPanel index={performanceTabValues.comments} value={currTab} sx={{ margin: "0px", padding: "0px" }}
                          className="search-type-tab">
                    <CommentMetricsTab/>
                </TabPanel>
            </Box>
        );
    };


    const onDeleteCampaign = async () => {
        if (!!!campaignId) {
            console.error(`Trying to delete with no campaignId ${campaignId}`);
            setShowDeleteFromCampaign(false);
            return;
        }
        try {
            await deleteCampaign({ warehouse, campaignId });
        } catch (error) {
        }
        setShowDeleteFromCampaign(false);
        navigate("/creators/campaigns");
    };

    function CampaignContent() {

        function onClickManagePostsContextualMenu() {
            if (campaignByIdResponse?.is_trial) {
                setModalDetails({
                    ...modalDetails,
                    isModalVisible: true,
                    modalInfo: unlockAccessPopupDetails.managePosts,
                });
                trackEvent(TRACKING_UPGRADE_POPUP_OPENED, { trigger_event: "managePosts" });
            } else {
                trackEvent(TRACKING_CAMPAIGN_MANAGE_POST_CLICKED, {
                    name_of_campaign: campaignByIdResponse?.name,
                    last_updated_at: convertTimeToLocale(campaignByIdResponse?.synced_at, DATE_TIME_FORMAT_STATUS)
                });
            }
            return campaignByIdResponse?.is_trial;
        }

        if (!!!campaignByIdResponse) {
            return null;
        }

        // TODO Show error screen instead of null
        if (campaignIdJobStatus === JOB_STATUS.FAILURE) {
            return null;
        }

        if (!!campaignByIdResponse.synced_at) {
            return (
                <>
                    <Box className="your-campaign-title add-post-but-details" sx={{ marginBottom: 24 }}>
                        <Box id={"post-count"} className="section-heading">
                            {contentGridHeader(totalContentCount)}
                        </Box>
                        {isCampaignEnded(campaignByIdResponse) ? null : (
                            <ContextualMenu
                                onClickOverride={onClickManagePostsContextualMenu}
                                variant={"button"}
                                menuItems={editCampaignMenu}
                                buttonLabel={
                                    <span className={"body-m"} style={{ color: Colors.mainPrimary }}>
                                        <i className="ri-edit-line"/>{` ${MANAGE_POSTS}`}
                                    </span>
                                }
                            />
                        )}
                    </Box>
                    <ContentGrid
                        campaignId={campaignId}
                        onRowClick={handleContentClick}
                        onRemoveContent={(campaignId, contentId) => {
                            removeContentApi({ campaignId, contentId }).then(() => {
                            });
                        }}
                        filterBody={filterBody}
                        setTotalContentCount={setTotalContentCount}
                        isTrialCampaign={campaignByIdResponse?.is_trial}
                        isCampaignEnded={isCampaignEnded(campaignByIdResponse)}
                        sortModel={contentSortModel}
                        setSortModel={setContentSortModel}
                        defaultSortModel={defaultContentSortModel}
                    />
                </>
            );
        }
        return null;
    }

    const allPlatformsSelected = () => {
        for (const platform in selectedPlatform) {
            if (platform !== allPlatforms && !selectedPlatform[platform]) {
                return false;
            }
        }
        return true;
    };

    const prevAllPlatformsSelected = useRef(allPlatformsSelected());

    const updateSelectedPostTypes = () => {
        const allSelectedPlatforms = Object.entries(selectedPlatform)
            .filter(([_, isSelected]) => isSelected) // Get only selected platforms
            .map(([platform]) => platform) // Extract platform names
            .filter((platform) => platform !== "All Platforms");

        if (allSelectedPlatforms.length > 0) {
            const commonPostTypes = [];
            allSelectedPlatforms.map((platform) => {
                commonPostTypes.push(...platformWisePostTypes[platform]);
            });
            // Set the common post types to true in the selectedPostTypes state
            setSelectedPostTypes((prevSelectedPostTypes) => ({
                ...prevSelectedPostTypes,
                ...commonPostTypes.reduce((acc, postType) => ({ ...acc, [postType]: true }), {}),
            }));
        }
    };

    useEffect(() => {
        // Update "All Platforms" selection based on the individual platforms
        const isAllPlatformSelected = allPlatformsSelected();
        if (isAllPlatformSelected !== prevAllPlatformsSelected.current) {
            setSelectedPlatform((prevState) => ({
                ...prevState,
                [allPlatforms]: isAllPlatformSelected,
            }));
        }

        prevAllPlatformsSelected.current = isAllPlatformSelected;

        const updatedSelectedPostTypes = { ...selectedPostTypes };

        for (const postType in updatedSelectedPostTypes) {
            // Check if the post type is part of the selected platform's post types
            const isPostTypeInSelectedPlatform =
                selectedPlatform[allPlatforms] ||
                Object.entries(selectedPlatform).some(([platform, isSelected]) => isSelected && platformWisePostTypes[platform]?.includes(postType));

            // If the post type is not in the selected platform's post types, set it to false
            if (!isPostTypeInSelectedPlatform) {
                delete updatedSelectedPostTypes[postType];
            } else {
                updatedSelectedPostTypes[postType] = true;
            }
        }
        updatedSelectedPostTypes[allTypes] = true;
        setSelectedPostTypes(updatedSelectedPostTypes);
        updateSelectedPostTypes();
        updatePostTypeFromURL(updatedSelectedPostTypes)
    }, [selectedPlatform]);

    const allPostTypesSelected = () => {
        for (const postType in selectedPostTypes) {
            if (postType !== allTypes && !selectedPostTypes[postType]) {
                return false;
            }
        }
        return true;
    };

    const prevAllPostTypesSelected = useRef(allPostTypesSelected());

    function getSelectedPostType(selectedPostTypes) {
        const postTypeValues = Object.values(selectedPostTypes);
        const selectedCount = postTypeValues.reduce((acc, val) => acc + (val ? 1 : 0), 0);

        if (selectedCount === postTypeValues.length) {
            return "All";
        } else if (selectedCount === 0) {
            return null;
        } else if (selectedCount === 1) {
            const selectedType = Object.keys(selectedPostTypes).find((key) => selectedPostTypes[key]);
            if (selectedPostTypes === selectedAccounts) {
                const foundAccount = creatorAccountsMenu.find(account => account.id === selectedType);

                if (foundAccount) {
                    return foundAccount.username;
                } else {
                    return "-"
                }
            }
            return selectedType;
        } else {
            const allTypesSelected = selectedPostTypes[allTypes];
            if (allTypesSelected) {
                const remainingTypes = Object.keys(selectedPostTypes).filter((key) => key !== allTypes);
                return `${remainingTypes[0]} + ${selectedCount - 1}`;
            } else {
                const firstSelectedType = Object.keys(selectedPostTypes).find((key) => selectedPostTypes[key]);
                if (selectedPostTypes === selectedAccounts) {
                    const foundAccount = creatorAccountsMenu.find(account => account.id === firstSelectedType);
                    if (foundAccount) {
                        return `${foundAccount.username} + ${selectedCount - 1}`;
                    } else {
                        return "-"
                    }
                }
                return `${firstSelectedType} + ${selectedCount - 1}`;
            }
        }
    }

    useEffect(() => {
        const isAllPostTypesSelected = allPostTypesSelected();
        if (isAllPostTypesSelected !== prevAllPostTypesSelected.current) {
            setSelectedPostTypes((prevState) => ({
                ...prevState,
                [allTypes]: isAllPostTypesSelected,
            }));
        }

        prevAllPostTypesSelected.current = isAllPostTypesSelected;
    }, [selectedPostTypes]);

    useEffect(() => {
        if (!isExportLoadingModalVisible) {
            return;
        }
        const { content_types, work_platform_ids, campaign_profile_ids } = filterBody;
        const { field: sort_by, sort } = contentSortModel[0];
        if (exportModalState.key === 'CSV') {
            downloadPerformanceMetricsCsv({
                warehouse,
                campaignId,
                content_types,
                work_platform_ids,
                campaign_profile_ids,
                sort_desc: String(sort).toUpperCase() === 'DESC',
                sort_by,
            })
                .then((response) => {
                    if (!response) {
                        throw new Error('Could not download UTM CSV');
                    }
                    downloadFile(response, 'performance-report.csv');
                    trackEvent(TRACKING_CAMPAIGN_CSV_EXPORTED, {
                        name_of_campaign: campaignByIdResponse?.name,
                        last_updated_at: convertTimeToLocale(campaignByIdResponse?.synced_at, DATE_TIME_FORMAT_STATUS),
                        last_refreshed_time: convertTimeToLocale(campaignByIdResponse?.synced_at, DATE_TIME_FORMAT_STATUS)
                    });
                })
                .catch(console.error)
                .finally(() => setExportLoadingModalVisible(false));
        } else if (exportModalState.key === 'PDF') {
            downloadCampaignPdf({
                warehouse,
                campaignId,
                content_types,
                work_platform_ids,
                campaign_profile_ids,
                sort_desc: String(sort).toUpperCase() === 'DESC',
                sort_by,
                end_user_timezone: moment.tz.guess(true),
            })
                .then((response) => {
                    if (!response) {
                        throw new Error('Could not download campaign PDF');
                    }
                    downloadFile(response, `campaign-pdf-report-${campaignId}.pdf`, 'application/pdf');
                    trackEvent(TRACKING_CAMPAIGN_PDF_EXPORTED, {
                        name_of_campaign: campaignByIdResponse?.name,
                        last_updated_at: convertTimeToLocale(campaignByIdResponse?.synced_at, DATE_TIME_FORMAT_STATUS),
                        last_refreshed_time: convertTimeToLocale(campaignByIdResponse?.synced_at, DATE_TIME_FORMAT_STATUS)
                    });
                })
                .catch(console.error)
                .finally(() => setExportLoadingModalVisible(false));
        }
    }, [isExportLoadingModalVisible]);

    if (!campaignByIdResponse) {
        return <ContentLoading/>;
    }
    const platformTypeMenu = {
        firstName: "Platforms",
        selectedValueText: getSelectedPostType(selectedPlatform),
        options: [
            {
                icon: <></>,
                text: allPlatforms,
                checked: selectedPlatform[allPlatforms],
                isVisible: true,
                onClick: () => {
                    const allChecked = !selectedPlatform[allPlatforms];
                    const updatedSelection = { ...selectedPlatform };
                    for (const platform in selectedPlatform) {
                        updatedSelection[platform] = allChecked;
                    }
                    setSelectedPlatform(updatedSelection);
                },
            },
            {
                icon: workPlatformsDropdownOptions[0].icon,
                text: workPlatformsDropdownOptions[0].value,
                isVisible: true,
                checked: selectedPlatform[workPlatformsDropdownOptions[0].value],
                onClick: () => handlePlatformClick(workPlatformsDropdownOptions[0].value),
            },
            {
                icon: workPlatformsDropdownOptions[1].icon,
                text: workPlatformsDropdownOptions[1].value,
                isVisible: true,
                checked: selectedPlatform[workPlatformsDropdownOptions[1].value],
                onClick: () => handlePlatformClick(workPlatformsDropdownOptions[1].value),
            },
            {
                icon: workPlatformsDropdownOptions[2].icon,
                text: workPlatformsDropdownOptions[2].value,
                isVisible: true,
                checked: selectedPlatform[workPlatformsDropdownOptions[2].value],
                onClick: () => handlePlatformClick(workPlatformsDropdownOptions[2].value),
            },
        ],
    };

    const handlePlatformClick = (platformValue) => {
        setSelectedPlatform((prevState) => ({
            ...prevState,
            [platformValue]: !prevState[platformValue],
            [allPlatforms]: false,
        }));
    };

    const handlePostTypeClick = (postValue) => {
        setSelectedPostTypes((prevState) => ({
            ...prevState,
            [postValue]: !prevState[postValue],
            [allTypes]: false,
        }));
    };

    const isPostTypeVisible = (postType) => {
        const selectedPlatformsArray = Object.entries(selectedPlatform);
        const isPostTypeInSelectedPlatform =
            selectedPlatform[allPlatforms] ||
            selectedPlatformsArray.some(([platform, isSelected]) => isSelected && platformWisePostTypes[platform]?.includes(postType));

        // Only set the postType to true if it is part of the selected platform's post types
        // Avoid unnecessary re-renders by checking if it is already true

        return isPostTypeInSelectedPlatform;
    };

    const postTypeMenu = {
        firstName: "Post types",
        selectedValueText: getSelectedPostType(selectedPostTypes),
        options: [
            {
                icon: <></>,
                text: allTypes,
                checked: selectedPostTypes[allTypes],
                isVisible: true,
                onClick: () => {
                    const allChecked = !selectedPostTypes[allTypes];
                    const updatedSelection = { ...selectedPostTypes };
                    for (const postType in selectedPostTypes) {
                        updatedSelection[postType] = allChecked;
                    }
                    setSelectedPostTypes(updatedSelection);
                },
            },
            {
                icon: <></>,
                text: video,
                checked: selectedPostTypes[video],
                isVisible: isPostTypeVisible(video),
                onClick: () => handlePostTypeClick(video),
            },
            {
                icon: <></>,
                text: reels,
                checked: selectedPostTypes[reels],
                isVisible: isPostTypeVisible(reels),
                onClick: () => handlePostTypeClick(reels),
            },
            {
                icon: <></>,
                text: image,
                checked: selectedPostTypes[image],
                isVisible: isPostTypeVisible(image),
                onClick: () => handlePostTypeClick(image),
            },
            {
                icon: <></>,
                text: post,
                checked: selectedPostTypes[post],
                isVisible: isPostTypeVisible(post),
                onClick: () => handlePostTypeClick(post),
            },
            {
                icon: <></>,
                text: story,
                checked: selectedPostTypes[story],
                isVisible: isPostTypeVisible(story),
                onClick: () => handlePostTypeClick(story),
            },
        ],
    };

    const handleFilterChange = () => {
        const payload = {};
        const selectedPlatforms = Object.keys(selectedPlatform).filter((platform) => platform !== allPlatforms && selectedPlatform[platform]);
        trackEvent(TRACKING_CAMPAIGN_PLATFORM_FILTERED, { changed_to: selectedPlatforms });
        if (selectedPlatforms.length > 0 && !selectedPlatform[allPlatforms]) {
            payload.work_platform_ids = selectedPlatforms.map((selectedPlatform) => getWorkPlatformIdByPlatformName(selectedPlatform));
        }

        // Check if 'allTypes' is selected, and add 'content_types' accordingly
        const selectedPostTypesList = Object.keys(selectedPostTypes).filter((type) => type !== allTypes && selectedPostTypes[type]);
        const indexToReplace = selectedPostTypesList.indexOf(post);
        if (indexToReplace !== -1) {
            selectedPostTypesList[indexToReplace] = feed;
        }
        trackEvent(TRACKING_CAMPAIGN_POST_FILTERED, { changed_to: selectedPostTypesList });
        if (selectedPostTypesList.length > 0 && !selectedPostTypes[allTypes]) {
            payload.content_types = selectedPostTypesList.map((type) => type.toUpperCase());
        }

        const selectedCreatorAccounts = Object.keys(selectedAccounts).filter((account) => account !== allCreatorsAccounts && selectedAccounts[account]);
        if (selectedCreatorAccounts.length > 0 && selectedCreatorAccounts.length !== Object.keys(selectedAccounts).length - 1) {

            payload.campaign_profile_ids = selectedCreatorAccounts;
        }

        setFilterBody(payload);
        const url = new URL(window.location);
        const queryParams = new URLSearchParams(payload);
        url.search = queryParams.toString();

        if (selectedPostTypes[allTypes]) {
            url.searchParams.delete('content_types');
        }
        if (selectedPlatform[allPlatforms]) {
            url.searchParams.delete('work_platform_ids');
        }
        if (selectedCreatorAccounts.length === Object.keys(selectedAccounts).length - 1) {
            url.searchParams.delete('campaign_profile_ids');
        }
        window.history.pushState({}, "", url);
        if (!isEmpty(campaignByIdResponse?.synced_at)) {
            getCampaignAggregatedReport({
                warehouse,
                campaignId,
                filterBody: payload,
            }).then(setPerformanceMetrics);
        }
    };

    function CampaignComingSoon() {
        return (
            campaignTrackingWarehouse.campaignPageActiveTab === campaignPageTabValues.measureROI ? <Box>
                <Box className="campaign-error-content campaign-comming-soon" style={{ marginTop: 80 }}>
                    <EmptyTableScreen
                        icon={<Icons.campaign_coming_soon/>}
                        header={campaignComingSoon.title}
                        content={`${campaignComingSoon.desc} ${convertTimeToLocale(campaignByIdResponse.start_date, DATE_FORMAT)}`}
                        actionButtonContent={
                            <>
                                <i className="ri-edit-line"></i> {"Manage UTM"}
                            </>
                        }
                        actionButtonHandler={() => navigate(`/creators/campaigns/manage-utm-links/${campaignId}`)}
                        isActionButton={true}
                    />
                </Box>
            </Box> : <Box>
                <Box className="campaign-error-content campaign-comming-soon" style={{ marginTop: 80 }}>
                    <EmptyTableScreen
                        icon={<Icons.campaign_coming_soon/>}
                        header={TrackPostCampaignComingSoon.title}
                        content={`${TrackPostCampaignComingSoon.desc} ${convertTimeToLocale(campaignByIdResponse.start_date, DATE_FORMAT)}`}
                        actionButtonContent={
                            <>
                                {MANAGE_POSTS}
                            </>
                        }
                        actionButtonHandler={() => navigate(`/creators/campaigns/automatically-add-creators/${campaignId}`)}
                        isActionButton={true}
                    />
                </Box>
            </Box>
        );
    }

    function onExportClick(modalState) {
        if (campaignByIdResponse?.is_trial) {
            setModalDetails({
                ...modalDetails,
                isModalVisible: true,
                modalInfo: unlockAccessPopupDetails.exportCampaign,
            });
        } else {
            setExportModalState(modalState);
            setExportLoadingModalVisible(true);
        }
    }

    function trackPostsTabPanel() {
        if (isLoading) {
            return (
                <Skeleton variant="rounded" height={800} width={"100%"}
                          className={"loading-skeleton"}
                          animation={"pulse"}/>
            );
        }

        // if campaign is ended
        if (isCampaignEnded(campaignByIdResponse) && !(hasContents || hasIdentifiers)) {
            return (
                <>
                    <Box className="sync-status-text"><CampaignSyncStatus campaignByIdResponse={campaignByIdResponse}/></Box>
                    <Box className="campaign-error-content campaign-ended-screen"
                         style={{ marginTop: 80 }}><EmptyTableScreen
                        icon={<Icons.campaign_coming_soon/>}
                        header={TRACK_POST_CAMPAIGN_ENDED.title}
                        content={TRACK_POST_CAMPAIGN_ENDED.desc}
                        isActionButton={false}
                    />
                    </Box>
                </>
            )
        }


        function onClickEditContextualMenu() {
            campaignByIdResponse?.is_trial &&
            setModalDetails({
                ...modalDetails,
                isModalVisible: true,
                modalInfo: unlockAccessPopupDetails.managePosts,
            });
            trackEvent(TRACKING_UPGRADE_POPUP_OPENED, { trigger_event: "managePosts" })
            return campaignByIdResponse?.is_trial;
        }

        if (isCampaignComingSoon) {
            if (hasContents || hasIdentifiers) {
                return <CampaignComingSoon/>;
            }
            if (profileCount > 0 && hasCampaignConnectedStores) {
                return <TrackedPostsMainContent
                    campaignId={campaignId}
                    entrySource={ADD_CREATORS_ENTRY_SOURCE.EDIT_CAMPAIGN_CAMPAIGNPAGE}
                    hasUnsavedContent={hasUnsavedContent}/>
            }
            return <CampaignComingSoon/>;
        }

        if (hasContents || hasIdentifiers) {
            return (
                <div>
                    {campaignByIdResponse?.is_trial ? null : <Box className="sync-status-text">
                        <CampaignSyncStatus campaignByIdResponse={campaignByIdResponse}/>
                    </Box>}
                    <Box className="filter-menu-container">
                        <Box>
                            <FilterMenu
                                firstItem={platformTypeMenu.firstName}
                                menuItems={platformTypeMenu.options}
                                selectedValueText={platformTypeMenu.selectedValueText}
                                handleFilterChange={handleFilterChange}
                                isMultiSelect
                            />
                            <FilterMenu
                                firstItem={postTypeMenu.firstName}
                                menuItems={postTypeMenu.options}
                                selectedValueText={postTypeMenu.selectedValueText}
                                handleFilterChange={handleFilterChange}
                                isMultiSelect
                            />
                            <FilterMenu
                                variant={userAccounts}
                                firstItem={creatorAccounts}
                                menuItems={creatorAccountsMenu}
                                selectedValueText={getSelectedPostType(selectedAccounts)}
                                handleFilterChange={handleFilterChange}
                                enableSearch={true}
                                onSearch={(searchString) => updateCreatorAccountFilterFromSearch(searchString)}
                                isMultiSelect
                            />
                        </Box>
                        {(!campaignByIdResponse || isEmpty(performanceMetrics)) ? null :
                            <Box className="export-csv-container">
                                <ContextualMenu
                                    variant={"text-button"}
                                    menuItems={exportReportMenu}
                                    buttonLabel={EXPORT_REPORT}
                                />
                                <ExportLoadingModal
                                    open={isExportLoadingModalVisible}
                                    title={exportModalState.value}
                                    onClose={() => setExportLoadingModalVisible(false)}/>
                                {isCampaignEnded(campaignByIdResponse) ? null : (
                                    <Box>
                                        {editCampaignMenu?.length > 0 && (
                                            <ContextualMenu
                                                onClickOverride={onClickEditContextualMenu}
                                                menuItems={editCampaignMenu}
                                            />
                                        )}
                                    </Box>
                                )}
                            </Box>}
                    </Box>
                    {isEmpty(performanceMetrics) ? (
                        <ContentLoading/>
                    ) : (
                        <>
                            <CampaignPerformanceMetrics/>
                            <Separator/>
                        </>
                    )}
                    <CampaignContent/>
                </div>
            );
        }

        return <TrackedPostsMainContent
            campaignId={campaignId}
            entrySource={ADD_CREATORS_ENTRY_SOURCE.EDIT_CAMPAIGN_CAMPAIGNPAGE}
            hasUnsavedContent={hasUnsavedContent}
        />
    }

    function MeasureRoiTabPanel() {
        if (isLoading) {
            return (
                <Skeleton variant="rounded" height={800} width={"100%"}
                          className={"loading-skeleton"}
                          animation={"pulse"}/>
            );
        }

        // If campaign is ended
        if (isCampaignEnded(campaignByIdResponse) && !hasCampaignConnectedStores) {
            return (
                <>
                    <Box className="sync-status-text"><CampaignSyncStatus campaignByIdResponse={campaignByIdResponse}/></Box>
                    <Box className="campaign-error-content campaign-ended-screen" style={{ marginTop: 80 }}>
                        <EmptyTableScreen
                            icon={<Icons.measure_roi_setup_logo/>}
                            header={MEASURE_ROI_CAMPAIGN_ENDED.title}
                            content={MEASURE_ROI_CAMPAIGN_ENDED.desc}
                            isActionButton={false}
                        />
                    </Box>
                </>
            )
        }

        // Trial campaigns don't have connected stores but still have ROI data
        if (campaignByIdResponse?.is_trial && !isEmpty(campaignByIdResponse?.store_link)) {
            return <MeasureRoiPage campaignId={campaignId} campaignByIdResponse={campaignByIdResponse}
                                   totalContentCount={totalContentCount} setProfileCount={setProfileCount}/>;
        }

        if (hasCampaignConnectedStores) {
            if (isCampaignComingSoon) {
                return <CampaignComingSoon/>;
            } else {
                return <MeasureRoiPage campaignId={campaignId} campaignByIdResponse={campaignByIdResponse}
                                       totalContentCount={totalContentCount} setProfileCount={setProfileCount}
                />;
            }
        }

        return <ConnectShopifyMainContent campaignId={campaignId}
                                          entrySource={ADD_CREATORS_ENTRY_SOURCE.EDIT_CAMPAIGN_CAMPAIGNPAGE}
                                          isStoreFetched={isStoreFetched}
                                          shopifyStores={shopifyStores}
                                          setShopifyStores={setShopifyStores}
                                          hasCampaignEnded={isCampaignEnded(campaignByIdResponse)}
        />;
    }

    function getCampaignViewHeaderMenu() {

        const campaignViewTopMenu = [];

        function onEditCampaignClick() {
            navigate(`/creators/campaigns/edit-campaign/${campaignId}`);
            trackEvent(TRACKING_CAMPAIGN_EDIT_CAMPAIGNS_CLICKED, {
                name_of_campaign: campaignByIdResponse?.name,
                last_updated_at: convertTimeToLocale(campaignByIdResponse?.synced_at, DATE_TIME_FORMAT_STATUS)
            })
        }

        function onDeleteCampaignClick() {
            setShowDeleteFromCampaign(true);
            trackEvent(TRACKING_CAMPAIGN_DELETE_CAMPAIGNS_CLICKED, {
                name_of_campaign: campaignByIdResponse?.name,
                last_updated_at: convertTimeToLocale(campaignByIdResponse?.synced_at, DATE_TIME_FORMAT_STATUS)
            })
        }

        if (!isCampaignEnded(campaignByIdResponse)) {
            campaignViewTopMenu.push({
                icon: <i className="ri-edit-line"></i>,
                text: EDIT_CAMPAIGN_DETAILS,
                onClick: onEditCampaignClick,
            });
        }
        !isTrialMode && campaignViewTopMenu.push({
            icon: (
                <span className={"body-m"} style={{ color: Colors.semanticsPrimaryError }}>
                    <i className="ri-delete-bin-fill"></i>
                </span>
            ),
            text: (
                <span className={"body-m"} style={{ color: Colors.semanticsPrimaryError }}>
                    {DELETE}
                </span>
            ),
            onClick: onDeleteCampaignClick,
        });

        return campaignViewTopMenu;
    }


    return (
        <Box>
            {campaignByIdResponse ? (
                <CampaignPageHeader
                    campaignData={campaignByIdResponse}
                    campaignBrandName={campaignBrand.name}
                    campaignViewTopMenu={getCampaignViewHeaderMenu()}
                    campaignProfileCount={profileCount}
                    onBack={() => {
                        trackEvent(TRACKING_CAMPAIGN_BACK_CLICKED, {
                            name_of_campaign: campaignByIdResponse?.name,
                            last_updated_at: convertTimeToLocale(campaignByIdResponse?.synced_at)
                        })
                        navigate("/creators/campaigns")
                    }}
                />
            ) : null}

            <Box className="page-tab-switch-main-div">
                <TabSwitch
                    handleTabChange={(ev, value) => {
                        getAllCampaignProfiles({
                            warehouse,
                            campaignId,
                            created_via: CAMPAIGN_PROFILES_CREATED_VIA.DIRECT,
                        }).then((profiles) => {
                            !isEmpty(profiles) && setProfileCount(Number(profiles['total_count']));
                        });

                        dispatchCampaignTrackingWarehouse({
                            type: SET_CAMPAIGN_ACTIVE_TAB,
                            payload: value,
                        });
                        dispatchCampaignTrackingWarehouse({
                            type: SET_TRACKED_CREATORS,
                            payload: [],
                        });
                    }}
                    aria-label="icon position tabs example"
                    currentTab={campaignTrackingWarehouse.campaignPageActiveTab}
                    tabs={campaignPageTabs}
                    height="55px"
                    width="fit-content"
                    variant={"underline"}
                />
            </Box>
            <>
                <TabPanel index={campaignPageTabValues.trackPosts}
                          value={campaignTrackingWarehouse.campaignPageActiveTab}
                          sx={{ margin: "0px", padding: "0px" }}>
                    <div className={'campaign-page-body'}>
                        {trackPostsTabPanel()}
                    </div>
                </TabPanel>
                <TabPanel index={campaignPageTabValues.measureROI}
                          value={campaignTrackingWarehouse.campaignPageActiveTab}
                          sx={{ margin: "0px", padding: "0px" }}>
                    <div className={'campaign-page-body'}>
                        <MeasureRoiTabPanel/>
                    </div>
                </TabPanel>

            </>
            {showDeleteFromCampaign ? (
                <ModalDialog
                    variant={"remove"}
                    cancelText={"Don’t delete"}
                    buttonText={"Delete"}
                    title={DELETE_CAMPAIGN_TITLE}
                    content={"Once you delete this campaign, all associated data will be lost forever. Take a moment to decide."}
                    onAction={onDeleteCampaign}
                    onClose={() => setShowDeleteFromCampaign(false)}
                    onDont={() => setShowDeleteFromCampaign(false)}
                />
            ) : null}
            {showAddBrandProfilesPopup && <AddBrandProfilesPopup
                brand={campaignBrand}
                onClose={() => {
                    setShowAddBrandProfilesPopup(false);
                }}
                onDone={() => setSocialsJustAdded(true)}
            />}

            <UnlockFullAccessPopUp
                open={modalDetails.isModalVisible}
                onClose={() => {
                    setModalDetails({ ...modalDetails, isModalVisible: false });
                }}
                header={modalDetails.modalInfo.header}
                description={modalDetails.modalInfo.description}
                actionButtonLabel="Contact us"
                height="264px"
                padding="52px 52px 48px 48px"
                isModal
            />
        </Box>
    );
};

function ContentLoading() {
    return (
        <>
            <Box className="campaign-content-loading content-loading">
                <Box className="section-heading">
                    <Loader className={"campaign-loader-container"}/>
                    {campaign_view_loading_msg.title}
                </Box>
                <Box className="body-m">{campaign_view_loading_msg.desc}</Box>
            </Box>
            <Box className="skeleton-loading">
                <Skeleton variant="text" height={"246px"} width={"100%"} className={"loading-skeleton"}
                          animation={"pulse"}/>
                <hr/>
                <Skeleton variant="text" height={"100%"} width={"100%"} className={"loading-skeleton"}
                          animation={"pulse"}/>
            </Box>
        </>
    );
}

export default CampaignPage;
