import axiosInstance from "../axiosInstance";
import {
    currentDashboardURL,
    insightiqAPIPrefix,
    URL_CREATE_CAMPAIGN,
    URL_GET_CAMPAIGN_BRANDS,
    URL_GET_CAMPAIGN_DATA,
    URL_GET_ALL_CAMPAIGNS,
    URL_GET_RECENTLY_WORKED_WITH,
    URL_VALIDATE_FEATURE,
    URL_GET_STORES,
    URL_VERIFY_STORE_WEBPAGE,
    URL_PROFILES_ANALYTICS,
    URL_CREATE_SHOPIFY_AUTHORISATION_URL,
    URL_CAMPAIGN_FIREBASE_AUTH,
} from "../endpoints";
import { isEmpty } from "../../utils/util";

export async function getAllCampaigns({ search_string, sort_by, sort_desc, limit, offset }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}`);

    !isEmpty(search_string) && url.searchParams.append("search_string", search_string);
    !isEmpty(sort_by) && url.searchParams.append("sort_by", String(sort_by).toUpperCase());
    !isEmpty(sort_desc) && url.searchParams.append("sort_desc", Boolean(sort_desc).toString());
    !isEmpty(limit) && url.searchParams.append("limit", limit);
    !isEmpty(offset) && url.searchParams.append("offset", offset);
    try {
        const response = await axiosInstance.get(url.href);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.message;
    }
}

export async function getCampaignById({ campaignId }) {
    if (!!!campaignId) {
        return;
    }
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}`);
    try {
        const response = await axiosInstance.get(url.href);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.response.data;
    }
}

export const getCampaignContentData = async (workPlatformID, content_url ) => {
    try {
        const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_CAMPAIGN_DATA}`);
        const response = await axiosInstance.post(url.href, {
            work_platform_id: workPlatformID,
            content_url: content_url,
        });
        if (response.status === 200) {
            return response.data;
        } else {
            return response.error;
        }
    } catch (error) {
        return error?.response?.data?.error;
    }
};

export const createCampaign = async ({
    name,
    cost,
    brand_id,
    content_urls,
    profile_urls,
    file,
    hashtags,
    keywords,
    mentions,
    start_date,
    end_date,
    storeId,
    store_link,
    profileFile
}) => {
    try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("cost", cost);
        formData.append("brand_id", brand_id);
        file && formData.append("content_urls_file", file);
        profileFile && formData.append("profile_urls_file", profileFile);
        !isEmpty(content_urls) &&
            content_urls.map((url) => {
                formData.append("content_urls", url);
            });
        !isEmpty(profile_urls) &&
            profile_urls.map((url) => {
                formData.append("profile_urls", url);
            });
        !isEmpty(hashtags) &&
            hashtags.map((url) => {
                formData.append("hashtags", url);
            });
        !isEmpty(keywords) &&
            keywords.map((url) => {
                formData.append("keywords", url);
            });
        !isEmpty(mentions) &&
            mentions.map((url) => {
                formData.append("mentions", url);
            });
        !isEmpty(start_date) && formData.append("start_date", start_date);
        !isEmpty(end_date) && formData.append("end_date", end_date);
        !isEmpty(storeId) && formData.append("store_id", storeId);
        !isEmpty(store_link) && formData.append("store_link", store_link);

        const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_CREATE_CAMPAIGN}`);

        const response = await axiosInstance.post(url.href, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if (response.status === 201) {
            return response.data;
        } else {
            return response.error;
        }
    } catch (error) {
        return error?.response?.data;
    }
};

export const getAllBrands = async ({ limit }) => {
    try {
        const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_CAMPAIGN_BRANDS}`);
        !isEmpty(limit) && url.searchParams.append("limit", limit);

        const response = await axiosInstance.get(url.href);

        return response.data;
    } catch (e) {
        // Todo handle error
        return e;
    }
};

export async function getAllCampaignsContents({
    campaignId,
    offset,
    limit,
    filterBody,
    sort_by,
    sort_desc
}) {

    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/contents`);
    !isEmpty(limit) && url.searchParams.append("limit", limit);
    !isEmpty(offset) && url.searchParams.append("offset", offset);
    if (!isEmpty(filterBody)) {
        if (filterBody.work_platform_ids) {
            filterBody.work_platform_ids.map((work_platform_id) => {
                url.searchParams.append("work_platform_ids", work_platform_id);
            });
        }
        if (filterBody.content_types) {
            filterBody.content_types.map((content_type) => {
                url.searchParams.append("content_types", content_type);
            });
        }
        if (filterBody.campaign_profile_ids) {
            filterBody.campaign_profile_ids.map((profile_id) => {
                url.searchParams.append("campaign_profile_ids", profile_id);
            });
        }
    }
    !isEmpty(sort_by) && url.searchParams.append("sort_by", String(sort_by).toUpperCase());
    !isEmpty(sort_desc) && url.searchParams.append("sort_desc", Boolean(sort_desc).toString());

    try {
        const response = await axiosInstance.get(url.href);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.message;
    }
}

export async function getCampaignContentById({ campaignId, contentId }) {
    try {
        const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/contents/${contentId}`);
        const response = await axiosInstance.get(url.href);
        if (response.status === 200) {
            return response;
        } else {
            return response.error;
        }
    } catch (error) {
        return error?.response?.data?.error;
    }
}

export async function updateCampaign({
    campaignId,
    hashtags = null,
    keywords = null,
    mentions = null,
    name,
    start_date,
    end_date,
    cost,
    brand_id,
    store_id,
    store_link }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}`);
    let req = {};
    name && (req = { ...req, name });
    start_date && start_date !== null && (req = { ...req, start_date });
    end_date && (req = { ...req, end_date });
    !isEmpty(cost) && (req = { ...req, cost });
    brand_id && (req = { ...req, brand_id });
    hashtags !== null && (req = { ...req, hashtags });
    keywords !== null && (req = { ...req, keywords });
    mentions !== null && (req = { ...req, mentions });
    !isEmpty(store_id) && (req = { ...req, store_id });
    !isEmpty(store_link) && (req = { ...req, store_link });

    try {
        const response = await axiosInstance.patch(url.href, req);
        if (response.status === 200) {
            return response.data;
        } else {
            return response.error;
        }
    } catch (error) {
        return error?.response?.data;
    }
}

export async function addContentToCampaign({ content_urls, campaignId, file }) {
    const formData = new FormData();
    content_urls &&
        content_urls.map((url) => {
            formData.append("content_urls", url);
        });
    file && formData.append("file", file);

    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/contents`);

    const response = await axiosInstance.post(url.href, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    if (response.status === 201) {
        return response.data;
    }
}

export async function deleteContentFromCampaign({ ids, campaignId }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/contents`);
    const response = await axiosInstance.delete(url.href, {
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            ids: ids,
        },
    });
    if (response.status === 201) {
        return response.data;
    }
}

export async function deleteCampaign({ campaignId }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}`);
    const response = await axiosInstance.delete(url.href);
    if (response.status === 201) {
        return response.data;
    }
}

export async function getCampaignAggregatedReport({ campaignId, filterBody }) {
    if (!!!campaignId) {
        return;
    }
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/performance-report`);
    if (!isEmpty(filterBody)) {
        if (filterBody.work_platform_ids) {
            filterBody.work_platform_ids.forEach((work_platform_id) => {
                url.searchParams.append("work_platform_ids", work_platform_id);
            });
        }

        if (filterBody.content_types) {
            filterBody.content_types.forEach((content_type) => {
                url.searchParams.append("content_types", content_type);
            });
        }

        if (filterBody.campaign_profile_ids) {
            filterBody.campaign_profile_ids.forEach((profile_id) => {
                url.searchParams.append("campaign_profile_ids", profile_id);
            });
        }
    }
    try {
        const response = await axiosInstance.get(url.href);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.message;
    }
}

export async function deleteContentFromCampaignByContentIds({ content_ids, campaignId }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/contents`);
    const response = await axiosInstance.delete(url.href, {
        data: { ids: content_ids },
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (response.status === 200) {
        return response.data;
    } else {
        throw response.error;
    }
}

export async function getRecentProfiles({ offset, limit, search_string }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_RECENTLY_WORKED_WITH}`);
    !isEmpty(search_string) && url.searchParams.append("search_string", search_string);
    !isEmpty(limit) && url.searchParams.append("limit", limit);
    !isEmpty(offset) && url.searchParams.append("offset", offset);

    try {
        const response = await axiosInstance.get(url.href);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.message;
    }
}

export async function deleteProfileFromCampaign({ campaignId, ids }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/profiles`);
    const response = await axiosInstance.delete(url.href, {
        headers: {
            "Content-Type": "application/json",
        },
        data: {
            ids: ids,
        },
    });
    if (response.status === 204) {
        return response.status;
    }
}

export async function getAllCampaignProfiles({
    search_string,
    limit,
    offset,
    campaignId,
    sort_by,
    sort_desc
    , created_via }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/profiles`);
    !isEmpty(search_string) && url.searchParams.append("search_string", search_string);
    !isEmpty(limit) && url.searchParams.append("limit", limit);
    !isEmpty(offset) && url.searchParams.append("offset", offset);
    !isEmpty(sort_by) && url.searchParams.append("sort_by", String(sort_by).toUpperCase());
    !isEmpty(sort_desc) && url.searchParams.append("sort_desc", Boolean(sort_desc).toString());
    !isEmpty(created_via) && url.searchParams.append("created_via", created_via);
    try {
        const response = await axiosInstance.get(url.href);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.message;
    }
}

export async function addProfilesToCampaign({ campaignId, profiles, file }) {
    const formData = new FormData();
    !isEmpty(profiles) && profiles.forEach((profile) => formData.append("profile_urls", profile["identifier"]));
    file && formData.append("file", file);
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/profiles`);
    const response = await axiosInstance.post(url.href, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    if (response.status === 201) {
        return response.data;
    } else {
        return response.error;
    }
}

export const createAudienceReport = async ({ campaignId, contentId }) => {
    try {
        const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_CREATE_CAMPAIGN}/${campaignId}/contents/
${contentId}/audience-report`);
        const response = await axiosInstance.post(url.href);
        if (response.status === 201) {
            return response.data;
        } else {
            return response.error;
        }
    } catch (error) {
        return error?.response?.data?.error;
    }
};

export async function validateFeatures({ feature }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_VALIDATE_FEATURE}`);
    url.searchParams.append("features", feature);
    try {
        const response = await axiosInstance.get(url.href);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.message;
    }
}

export const getBrandByID = async ({ brand_id }) => {
    try {
        const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_CAMPAIGN_BRANDS}${brand_id}`);
        const response = await axiosInstance.get(url.href);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateBrandByID = async ({ brand_id, profiles }) => {
    try {
        const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_CAMPAIGN_BRANDS}${brand_id}`);
        let req = {};
        profiles && (req = { ...req, profiles });
        const response = await axiosInstance.patch(url.href, req);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export async function createBrand({ name, website_url, profiles }) {
    try {
        const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_CAMPAIGN_BRANDS}`);
        const formData = new FormData();
        formData.append("name", name);
        !isEmpty(website_url) && formData.append("website_url", website_url);
        !isEmpty(profiles) && formData.append("profiles", profiles);
        const response = await axiosInstance.post(
            url.href,
            { name }
            //     ,
            //     {
            //   headers: {
            //     "Content-Type": "multipart/form-data",
            //   },
            // }
        );
        if (response.status === 201) {
            return response.data;
        } else {
            return response.error;
        }
    } catch (e) {
        throw e;
    }
}

export async function getCreatorAccounts({
    campaignId,
    offset,
    limit,
    filterBody,
    sort_by,
    sort_desc,
    search_string
}) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/${URL_PROFILES_ANALYTICS}`);

    !isEmpty(limit) && url.searchParams.append("limit", limit);
    !isEmpty(offset) && url.searchParams.append("offset", offset);
    !isEmpty(search_string) && url.searchParams.append("search_string", search_string);
    if (!isEmpty(filterBody)) {
        if (filterBody.work_platform_ids) {
            filterBody.work_platform_ids.map((work_platform_id) => {
                url.searchParams.append("work_platform_ids", work_platform_id);
            });
        }
        if (filterBody.profile_ids) {
            filterBody.profile_ids.map((profile_id) => {
                url.searchParams.append("campaign_profile_ids", profile_id);
            });
        }
    }
    !isEmpty(sort_by) && url.searchParams.append("sort_by", String(sort_by).toUpperCase());
    !isEmpty(sort_desc) && url.searchParams.append("sort_desc", Boolean(sort_desc).toString());

    try {
        const response = await axiosInstance.get(url.href);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.message;
    }
}

export const getShopifyStores = async ({ status }) => {
    try {
        const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_STORES}`);
        !isEmpty(status) && url.searchParams.append('status', status);
        const response = await axiosInstance.get(url);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};

export const verifyStoreWebpage = async ({ store_id, store_link }) => {
    try {
        const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_VERIFY_STORE_WEBPAGE}`);

        const response = await axiosInstance.post(url.href, {
            store_id,
            store_link,
        });
        if (response.status === 200) {
            return response.data;
        } else {
            return response.error;
        }

        //   return {
        //   "store_id": "25345345",
        //   "store_link": "www.store_link.com",
        //   "store_web_page_title": "store_web_page_title",
        //   "is_valid": true
        // }

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateProfileCost = async ({ campaignId, payload }) => {
    try {
        const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/profiles`);
        const response = await axiosInstance.patch(url.href, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createShopifyAuthorisationURL = async ({
    shopifyStoreWorkplatformId,
    shopSlug,
    postOAuthredirectURL
}) => {
    try {
        const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_CREATE_SHOPIFY_AUTHORISATION_URL}`);
        const response = await axiosInstance.post(url.href, {
            store_work_platform_id: shopifyStoreWorkplatformId,
            shop_slug: shopSlug,
            post_oauth_redirect_uri: postOAuthredirectURL,
        });
        // return {
        //   store_authorization_id: "11cb5681-5364-456d-83b4-bf6a6efe4651",
        //   authorization_url:
        //     "https://quickstart-06d882b5.myshopify.com/admin/oauth/authorize?client_id=da8e32378af99b1f58dadb03c0a87bac&scope=read_customers&redirect_uri=http%3A%2F%2Flocalhost%3A8041%2Fv1%2Fstore-management%2Foauth%2Fshopify%2Fcallback&state=5ad9a170ac9f4fdb9b97dc712bf8171d_11cb56815364456d83b4bf6a6efe4651",
        // };
        return response.data;
    } catch (error) {
        throw error;
    }
};

export async function getCampaignRoi({ campaignId, filterBody }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/roi-metrics`);

    if (!isEmpty(filterBody)) {
        if (filterBody.work_platform_ids) {
            filterBody.work_platform_ids.map((work_platform_id) => {
                url.searchParams.append("work_platform_ids", work_platform_id);
            });
        }
        if (filterBody.profile_ids) {
            filterBody.profile_ids.map((profile_id) => {
                url.searchParams.append("campaign_profile_ids", profile_id);
            });
        }
    }

    try {
        const response = await axiosInstance.get(url.href);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.message;
    }
}

export async function downloadUtmLinks({ campaignId }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/profiles/export-utm-links`);
    const response = await axiosInstance.post(url.href);
    if (response.status === 200) {
        return response;
    } else {
        throw response;
    }
}

export async function downloadRoiMetrics({
    campaignId,
    campaign_profile_ids,
    work_platform_ids,
    sort_desc,
    sort_by
}) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/export-roi-metrics`);

    let postData = {};
    !isEmpty(campaign_profile_ids) && (postData = { ...postData, campaign_profile_ids });
    !isEmpty(work_platform_ids) && (postData = { ...postData, work_platform_ids });
    !isEmpty(sort_desc) && (postData = { ...postData, sort_desc });
    !isEmpty(sort_by) && (postData = { ...postData, sort_by: String(sort_by).toUpperCase() });

    const response = await axiosInstance.post(url.href, postData);

    if (response.status === 200) {
        return response;
    } else {
        throw response;
    }
}

export async function downloadCampaignPdf({
                                              campaignId,
                                              content_types,
                                              work_platform_ids,
                                              campaign_profile_ids,
                                              sort_desc,
                                              sort_by,
                                              end_user_timezone,
                                          }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/export`);

    let postData = {};
    !isEmpty(content_types) && (postData = { ...postData, content_types });
    !isEmpty(work_platform_ids) && (postData = { ...postData, work_platform_ids });
    !isEmpty(campaign_profile_ids) && (postData = { ...postData, campaign_profile_ids });
    !isEmpty(sort_desc) && (postData = { ...postData, sort_desc });
    !isEmpty(sort_by) && (postData = { ...postData, sort_by: String(sort_by).toUpperCase() });
    !isEmpty(end_user_timezone) && (postData = { ...postData, end_user_timezone });

    const response = await axiosInstance.post(url.href, postData);

    if (response.status === 200) {
        return response;
    } else {
        throw response;
    }
}

export async function downloadCampaignContentPdf({ campaignId, contentId, end_user_timezone }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/contents/${contentId}/export`);
    let postData = {};
    !isEmpty(end_user_timezone) && (postData = { ...postData, end_user_timezone });

    const response = await axiosInstance.post(url.href, postData);

    if (response.status === 200) {
        return response;
    } else {
        throw response;
    }
}

export async function downloadPerformanceMetricsCsv({
    campaignId,
    content_types,
    work_platform_ids,
    campaign_profile_ids,
    sort_desc,
    sort_by
}) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/export-performance-report`);

    let postData = {};
    !isEmpty(content_types) && (postData = { ...postData, content_types });
    !isEmpty(work_platform_ids) && (postData = { ...postData, work_platform_ids });
    !isEmpty(campaign_profile_ids) && (postData = { ...postData, campaign_profile_ids });
    !isEmpty(sort_desc) && (postData = { ...postData, sort_desc });
    !isEmpty(sort_by) && (postData = { ...postData, sort_by: String(sort_by).toUpperCase() });

    const response = await axiosInstance.post(url.href, postData);

    if (response.status === 200) {
        return response;
    } else {
        throw response;
    }
}

export async function downloadRoiPdf({
                                         campaignId,
                                         work_platform_ids,
                                         campaign_profile_ids,
                                         sort_desc,
                                         sort_by,
                                         search_string,
                                         end_user_timezone
                                     }) {
    const url = new URL(`${currentDashboardURL}${insightiqAPIPrefix}/${URL_GET_ALL_CAMPAIGNS}/${campaignId}/roi-metrics/export`);
    let postData = {};
    !isEmpty(work_platform_ids) && (postData = { ...postData, work_platform_ids });
    !isEmpty(campaign_profile_ids) && (postData = { ...postData, campaign_profile_ids });
    !isEmpty(sort_desc) && (postData = { ...postData, sort_desc });
    !isEmpty(sort_by) && (postData = { ...postData, sort_by: String(sort_by).toUpperCase() });
    !isEmpty(search_string)  && (postData = { ...postData, search_string });
    !isEmpty(end_user_timezone) && (postData = { ...postData, end_user_timezone });

    const response = await axiosInstance.post(url.href, postData);

    if (response.status === 200) {
        return response;
    } else {
        throw response;
    }
}

export async function getFirebaseAuthToken({warehouse}) {
    const response = await axiosInstance.post(`${currentDashboardURL}/${URL_CAMPAIGN_FIREBASE_AUTH}`, {
        environment: "PRODUCTION"
    });
    if (response.status === 201) {
        if (!isEmpty(response.data)) {
            return response.data.token;
        }
    } else {
        throw response;
    }
}