import { Skeleton } from '@mui/material';
import React from 'react';
import { AUDIENCE_OVERLAP, CONVERT_TO_PERCENTAGE, SHARED_FOLLOWERS, SHARED_FOLLOWERS_DESC, UNIQUE_FOLLOWERS, UNIQUE_FOLLOWERS_DESC } from '../../constants/creatorDiscovery';
import Icons from '../Icons/Icons';
import "./AudienceOverlapData.scss";

function AudienceOverlapData({ shared_followers, unique_followers, creator_count, isLoading }) {
  if (isLoading) {
    return (
      <div className='audience-overlap-container' >
        <div className='audience-overlap-data' >
          <Skeleton width="130px" className="fullName" variant="text"></Skeleton>
          <div className='audience-follower-data' >
            <div className='shared-follower-data' >
              <Skeleton width="90px" height={"50px"} variant="text"></Skeleton>
              <Skeleton width="150px" height={"30px"} variant="text"></Skeleton>
              <Skeleton width="214px" height={"40px"} variant="text"></Skeleton>
            </div>
            <div className='shared-follower-data' >
              <Skeleton width="90px" height={"50px"} variant="text"></Skeleton>
              <Skeleton width="150px" height={"30px"} variant="text"></Skeleton>
              <Skeleton width="214px" height={"40px"} variant="text"></Skeleton>
            </div>
          </div>
        </div>
        <div className='audience-overlap-profile-loading-container' >
          <Skeleton className="profilePic audience-overlap-profile-loading" variant="circular" ></Skeleton>
          <Skeleton className="profilePic audience-overlap-profile-loading" variant="circular" ></Skeleton>
        </div>
      </div>
    );
  }
  return (
    <div className='audience-overlap-container' >
      <div className='audience-overlap-data' >
        <div className='sub-section-heading' >{AUDIENCE_OVERLAP}</div>
        <div className='audience-follower-data' >
          <div className='shared-follower-data' >
            <div className='title' >{CONVERT_TO_PERCENTAGE(shared_followers)}</div>
            <div className='body-m' >{SHARED_FOLLOWERS}</div>
            <div className='body-r shared-follower-desc' >{SHARED_FOLLOWERS_DESC(creator_count)}</div>
          </div>
          <div className='shared-follower-data' >
            <div className='title' >{CONVERT_TO_PERCENTAGE(unique_followers)}</div>
            <div className='body-m' >{UNIQUE_FOLLOWERS}</div>
            <div className='body-r shared-follower-desc' >{UNIQUE_FOLLOWERS_DESC}</div>
          </div>
        </div>
      </div>
      <div>
        {creator_count === 2 ? <Icons.audience_overlap_2_creator /> : <Icons.audience_overlap_3_creator />}
      </div>
    </div>
  );
}

export default AudienceOverlapData;