import React, { useContext, useEffect, useState } from 'react';
import './CompareCreatorsPage.scss';
import { ApiSearchableDropdown, GenericPopup, Icons, PlatformSelectionDropdown, PrimaryButton, SupportEmail, MainBanner, UnlockFullAccessPopUp } from '../../components';
import { upsertPlatformToQueryParams } from '../CreatorDiscoveryPage/CreatorDiscoveryPageUtils';
import { compareCreatorProductID, COMPARE_CREATORS_BUTTON_TEXT, COMPARE_CREATORS_SUBHEADING, COMPARE_CREATOR_INPUT_DEFAULT_STATE, COMPARE_CREATOR_LINK, creatorSearchTabs, MEETING_SCHEDULE_LINK, PAID, TRIAL } from '../../constants/constants';
import { getProfileAnalytics, getUsersHandles } from '../../api/creatorDiscoveryAPI/creatorDiscoveryAPI';
import { WarehouseContext } from '../../storage/context';
import CompareCreatorTable from '../../components/CompareCreatorTable/CompareCreatorTable';
import { postCompareCreators, postCompareCreatorSampleReport } from '../../api/compareCreatorAPI/compareCreatorAPI';
import { workPlatformsDropdownOptions, compareCreatorWorkPlatformsDropdownOptions } from '../../utils/util';
import { useNavigate } from 'react-router-dom';
import { COMPARE_CREATOR_LIMIT_REACHED_MODAL, COMPARE_CREATOR_POST_ERROR_MODAL, COMPARE_CREATOR_TRIAL_MODE_MODAL, COMPARE_CREATOR_UPGRADE_MODAL, CREATORS_COMPARED, extractIdFromErrorMessage, LIMIT_REACHED, TRIAL_MODE } from '../../constants/creatorDiscovery';
import { useFetchTenantUsageInfo } from '../../hooks/useFetchTenantUsageInfo';
import { ADD_USAGE_QUOTA } from '../../storage/reducers/types';

function CompareCreatorsPage({ options, currentTab, selectedPlatform, setSelectedPlatform, selectedPlatformID, setSelectedPlatformID }) {
  const [compareCreators, setCompareCreators] = useState(COMPARE_CREATOR_INPUT_DEFAULT_STATE);
  const [compareButtonDisabled, setCompareButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [showErrorModal, setErrorModal] = useState(false);
  const fetchTenantUsageInformation = useFetchTenantUsageInfo();
  const [maxLimitReached, setMaxLimitReached] = useState(false);
  const [isUpgradeModalVisible, setIsUpgradeModalVisible] = useState(false);
  const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
  const isTrialMode = !warehouse?.tenantAppProducts[compareCreatorProductID] || warehouse?.tenantAppProducts[compareCreatorProductID] === TRIAL;
  const [loadingSampleReport, setLoadingSampleReport] = useState(false);
  // Function to handle the selection of a creator
  function handleCreatorSelection(index, data) {
    setCompareCreators(prevState => {
      const newState = [...prevState];
      newState[index].defaultSelectedCreator = data;
      return newState;
    });
  };

  async function handleProfileSearch({ searchQuery, newCancelToken }) {
    let response = await getUsersHandles({
      searchQuery: searchQuery,
      workplatformId: selectedPlatformID,
      type: 'search',
      newCancelToken,
      is_search: true,
      warehouse,
    });

    response = response.data;

    // Added Profile Analytics API as a backup in case of no search results
    if (response.length === 0) {
      const payload = {
        identifier: searchQuery,
        work_platform_id: selectedPlatformID,
      };

      const analyticsResponse = await getProfileAnalytics({ warehouse, payload });

      const analyticsResponseData = {
        data: [
          {
            user_id: analyticsResponse?.profile?.external_id,
            username: analyticsResponse?.profile?.platform_username,
            fullname: analyticsResponse?.profile?.full_name,
            picture: analyticsResponse?.profile?.image_url,
            followers: analyticsResponse?.profile?.follower_count,
            is_verified: analyticsResponse?.profile?.is_verified,
          },
        ],
      };

      response = analyticsResponseData;
    }
    response = response.map((item) => {
      return {
        ...item,
        id: item.user_id,
        display_name: item.fullname,
      };
    });
    return response;
  };

  async function handlePostCompareCreators() {
    try {
      if (isTrialMode) {
        setIsUpgradeModalVisible(true);
        return;
      }
      setIsButtonLoading(true);
      const platformUsername = compareCreators
        .map(creator => creator.defaultSelectedCreator?.username)
        .filter(username => username != null);
      const response = await postCompareCreators({ workPlatformID: selectedPlatformID, platform_usernames: platformUsername });
      if (response?.id) {
        resetSelectedCompareCreators();
        sessionStorage.setItem("search-results", JSON.stringify({ currentTab: creatorSearchTabs.compareCreators }));
        const comparisonId = response?.id;
        navigate(COMPARE_CREATOR_LINK(comparisonId));
      } else {
        setErrorModal(true);
      }
    } catch (error) {
      console.error(error);
      setErrorModal(true);
    } finally {
      setIsButtonLoading(false);
    }
  }

  function resetSelectedCompareCreators() {
    setCompareCreators(prevState =>
      prevState.map(creator => ({
        ...creator,
        defaultSelectedCreator: null
      }))
    );
  }

  function handlePlatformChange(selectedPlatform) {
    const selectedWorkPlatformId = workPlatformsDropdownOptions.filter((option) => option.value === selectedPlatform)[0].id;
    setSelectedPlatformID(selectedWorkPlatformId);
    setSelectedPlatform(selectedPlatform);
    resetSelectedCompareCreators();
  }

  function updateCompareCreatorDisabledVal() {
    const nonNullCount = compareCreators.filter(creator => creator.defaultSelectedCreator !== null).length;
    setCompareButtonDisabled(!(nonNullCount >= 2));
  };

  async function checkCompareCreatorLimit() {
    try {
      if (warehouse.tenantAppProducts[compareCreatorProductID] === PAID) {
        const usageInfo = await fetchTenantUsageInformation();
        dispatchWarehouse({ type: ADD_USAGE_QUOTA, payload: usageInfo });
        if (usageInfo[CREATORS_COMPARED].usage >= usageInfo[CREATORS_COMPARED].max_limit) {
          setMaxLimitReached(true);
        }
      }
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    checkCompareCreatorLimit();
    updateCompareCreatorDisabledVal();
  }, [compareCreators]);

  useEffect(() => {
    resetSelectedCompareCreators();
  }, []);

  useEffect(() => {
    let url = new URL(window.location);
    let searchParams = new URLSearchParams(url.search);
    let queryParams = Object.fromEntries(searchParams);
    if (queryParams.platform && compareCreatorWorkPlatformsDropdownOptions.find((option) => option.value === queryParams.platform)) {
      const selectedWorkPlatformId = workPlatformsDropdownOptions.filter((option) => option.value === queryParams.platform)[0].id;
      setSelectedPlatform(queryParams.platform);
      setSelectedPlatformID(selectedWorkPlatformId);
    } else {
      setSelectedPlatform(compareCreatorWorkPlatformsDropdownOptions[0].value);
      setSelectedPlatformID(compareCreatorWorkPlatformsDropdownOptions[0].id);
      upsertPlatformToQueryParams(compareCreatorWorkPlatformsDropdownOptions[0].value);
    }
  }, []);

  async function handlePostCompareCreatorSampleReport() {
    try {
      setLoadingSampleReport(true);
      const response = await postCompareCreatorSampleReport();
      if (response?.id) {
        sessionStorage.setItem("search-results", JSON.stringify({ currentTab: creatorSearchTabs.compareCreators }));
        const comparisonId = response?.id;
        navigate(COMPARE_CREATOR_LINK(comparisonId));
      } else if (response?.code === "compare_creators_sample_report_already_generated") {
        sessionStorage.setItem("search-results", JSON.stringify({ currentTab: creatorSearchTabs.compareCreators }));
        const comparisonId = extractIdFromErrorMessage(response.message);
        if (comparisonId) {
          navigate(COMPARE_CREATOR_LINK(comparisonId));
        }
      }
    } catch (error) {
      if (error.code === "compare_creators_sample_report_already_generated") {
        const comparisonId = extractIdFromErrorMessage(error.message);
        if (comparisonId) {
          navigate(COMPARE_CREATOR_LINK(comparisonId));
        }
      }
    } finally {
      setLoadingSampleReport(false);
    }
  }

  return (
    <div className='compare-creators-page-container'>
      {maxLimitReached ?
        <div className='compare-creator-limit-reached-modal' >
          <MainBanner
            icon={<Icons.compare_creator_icon />}
            headerText={COMPARE_CREATOR_LIMIT_REACHED_MODAL.title}
            descText={COMPARE_CREATOR_LIMIT_REACHED_MODAL.desc}
            buttonText={COMPARE_CREATOR_LIMIT_REACHED_MODAL.buttonCTA}
            buttonAction={() => window.open(MEETING_SCHEDULE_LINK, "_blank")}
            AdditionalText=""
            variant="default"
          />
        </div> : null}
      {isTrialMode ?
        <div  >
          <MainBanner
            icon={<Icons.compare_creator_icon />}
            headerText={COMPARE_CREATOR_TRIAL_MODE_MODAL.title}
            descText={COMPARE_CREATOR_TRIAL_MODE_MODAL.desc}
            buttonText={COMPARE_CREATOR_TRIAL_MODE_MODAL.buttonCTA}
            buttonAction={handlePostCompareCreatorSampleReport}
            AdditionalText={COMPARE_CREATOR_TRIAL_MODE_MODAL.additionalText}
            variant="default"
            buttonLoading={loadingSampleReport}
          />
        </div> : null}
      {isTrialMode ? null :
        <>
          <div className={`body-r ${maxLimitReached ? 'compare-creator-limit-reached' : ''} `}>{COMPARE_CREATORS_SUBHEADING}</div>
          <div className={`compare-creators-actions-container ${maxLimitReached ? 'compare-creator-limit-reached' : ''} `}>
            <PlatformSelectionDropdown
              platformList={options}
              selectedPlatform={selectedPlatform}
              setSelectedPlatform={handlePlatformChange}
              onChangeHandle={upsertPlatformToQueryParams}
            />
            {compareCreators.map((creator, index) => {
              const { placeholder, label, defaultSelectedCreator } = creator;
              return (
                <div className='compare-creator-search-input' key={index}>
                  <ApiSearchableDropdown
                    placeholder={""}
                    disableClearable={false}
                    variant={'textfield-with-label'}
                    searchLabel={label}
                    handlePostSearch={handleProfileSearch}
                    defaultValue={defaultSelectedCreator}
                    getFilterValue={(data) => {
                      handleCreatorSelection(index, data);
                    }}
                    showTags={false}
                    multi={false}
                    key={index}
                    workPlatformId={selectedPlatformID}
                    dropDownVariant={'dropdown-option-with-workPlatform'}
                    searchType={creatorSearchTabs.compareCreators}
                    showTagsInTextfield
                  />
                </div>
              );
            })}
            <PrimaryButton label={isButtonLoading ? '' : COMPARE_CREATORS_BUTTON_TEXT} disabled={compareButtonDisabled} onClick={handlePostCompareCreators} className='compare-creators-button' loading={isButtonLoading} />
          </div>
        </>}
      <CompareCreatorTable isTrialMode={isTrialMode} />

      {showErrorModal ? <GenericPopup
        open={showErrorModal}
        icon={<span className="export-modal-icon" >{<Icons.caution_icon />}</span>}
        padding={"48px 48px 48px 48px"}
        height={'300px'}
        onClose={(e) => {
          e.stopPropagation();
          setErrorModal(false);
        }}
        header={COMPARE_CREATOR_POST_ERROR_MODAL.title}
        description={COMPARE_CREATOR_POST_ERROR_MODAL.desc}
        isModal
        actionButtonLabel={''}
        handleButtonClick={() => { }}
        actionButtonSubtext={<div className='compare-creator-post-error-subtext' ><SupportEmail variant={'IF_QUESTIONS_DROP_US_EMAIL_TEXT'} /></div>}
      /> : null}

      <UnlockFullAccessPopUp
        open={isUpgradeModalVisible}
        onClose={() => setIsUpgradeModalVisible(false)}
        header={COMPARE_CREATOR_UPGRADE_MODAL[maxLimitReached ? LIMIT_REACHED : isTrialMode ? TRIAL_MODE : '']?.title}
        description={COMPARE_CREATOR_UPGRADE_MODAL[maxLimitReached ? LIMIT_REACHED : isTrialMode ? TRIAL_MODE : '']?.desc}
        actionButtonLabel={COMPARE_CREATOR_UPGRADE_MODAL[maxLimitReached ? LIMIT_REACHED : isTrialMode ? TRIAL_MODE : '']?.buttonCTA}
        height={COMPARE_CREATOR_UPGRADE_MODAL[maxLimitReached ? LIMIT_REACHED : isTrialMode ? TRIAL_MODE : '']?.height}
        padding="52px 52px 48px 48px"
        isModal
      />

    </div>
  );
}

export default CompareCreatorsPage;
