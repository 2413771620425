import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import './ModalDialog.scss';

function ModalDialog({ title, content, onClose, onAction, buttonText, cancelText, variant = 'remove', onDont, additionalText }) {

    let buttonClass = {};
    let titleClass, additionalContentClass, additionalButtonClass = '';
    switch (variant) {
        case 'remove':
            buttonClass = 'remove-button';
            additionalContentClass = 'modal-additional-content';
            break;
        case 'primary':
            buttonClass = 'primary-button';
            additionalContentClass = 'modal-additional-content';
            break;
        case 'withOutContent':
            titleClass = "modal-without-content";
            buttonClass = 'remove-button';
            additionalContentClass = 'modal-additional-content-without-content'
            additionalButtonClass = "modal-additional-button"
            break;
    }

    function ModalTitle() {
        return <DialogTitle className={`modal-title ${titleClass}`}>
            <div className="section-heading modal-title-text">{title}</div>
            <i className="ri-close-line" onClick={onClose}/>
        </DialogTitle>;
    }

    function ModalAdditionalContent() {
        return (
            <DialogContent>
                <div className={`${additionalContentClass}`}>
                    <div className={'body-r modal-content-text'}>{additionalText}</div>
                </div>
            </DialogContent>
        );
    }
    function ModalContent() {
        return (
            <DialogContent>
                <div className="modal-content">
                    <i className="ri-alert-line"></i>
                    <span className={'body-r modal-content-text'}>{content}</span>
                </div>
            </DialogContent>
        );
    }

    function ModalButtons() {
        return (
            <DialogActions className={'modal-button-container'}>
                <Button onClick={onDont} className={`${additionalButtonClass}`} >
                    <div className={'body-m link-button'}>{cancelText}</div>
                </Button>
                <Button onClick={onAction} className={`action-button ${buttonClass}`}>
                    <div className="action-button-content">
                        {variant === 'remove' ? <i className="ri-delete-bin-line action-button-icon"></i> : null}
                        <div className={'body-m action-button-text'}>{buttonText}</div>
                    </div>
                </Button>
            </DialogActions>
        );
    }

    return (
        <Dialog open={true} onClose={onClose}>
            <ModalTitle/>
            {additionalText ? <ModalAdditionalContent/> : null}
            {content ? <ModalContent/> : null}
            <ModalButtons/>
        </Dialog>
    );
}


export default ModalDialog;