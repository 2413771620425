import React from "react";
import { Tag } from "antd";
import "./Tag.scss";

export default function TagComponent({
  backgroundColor = "",
  children,
  height,
  width,
  fontSize = "8px",
  color = "#8A8894",
}) {
  return (
    <Tag
      className="tag-container"
      style={{
        // color: textColor,
        fontWeight: 500,
        marginRight: "4px",
        backgroundColor: backgroundColor,
        height: height,
        width: width,
        fontSize: fontSize,
        display: "flex",
        justifyContent: "center",
      }}
      color={color}
    >
      {children}
    </Tag>
  );
}
