import React, { useContext, useEffect, useState } from 'react';
import {
    exportCampaignLinksCsv,
    getAllCampaignLinks,
    getAllShortUrlDomains,
    getLinksMetrics,
    getMeasurementProduct
} from '../../api/products/products';
import { Chip, Icons, MeasurementsPageTemplate, ShortLinkCell } from '../../components';
import {
    ALL,
    calendarTabSwitchLabels,
    CAMPAIGN_LINKS_HEADERS,
    campaignLinksGenerated,
    CUSTOM_DATE_LABEL,
    DATE_FORMAT,
    DEFAULT,
    getTagsAdditionalText, MEASUREMENT_PRODUCTS_API_DATE_FORMAT,
    NO_CAMPAIGN_LINKS_AVAILABLE,
    SEARCH_LINKS_PLACEHOLDER,
    SHORT_LINK_DOMAINS,
    totalLinkClicks,
    UPDATED_PERIODICALLY,
    WE_COULDNT_FIND_ANY_CAMPAIGN_LINKS,
    WE_WILL_SHOW_THEM
} from '../../constants/constants';
import { WarehouseContext } from '../../storage/context';
import { getSortedHeaderClass } from '../../utils/DataGridUtils';
import { convertTimeToLocale, isEmpty } from '../../utils/util';
import './CampaignLinks.scss';
import { formatNumber } from '../../utils/profileHelpers';
import { getDateRange } from "../../components/CustomDatePicker/CustomDatePicker";
import { format, subMonths } from 'date-fns';
import { useNavigate } from "react-router-dom";
import { RouteKeys } from "../../routes/ProductsRoutes";

const commonHeaderProps = {
    flex: 1,
    headerClassName: 'subtitle-m mui-data-grid-header hideRightSeparator',
};

export default function CampaignLinks() {
    const CampaignLinksColumns = {
        id: {
            field: 'id',
        },
        title: {
            field: 'title',
            headerName: CAMPAIGN_LINKS_HEADERS.link,
        },
        short_link: {
            field: 'short_link',
            headerName: CAMPAIGN_LINKS_HEADERS.short_link,
        },
        total_clicks: {
            field: 'total_clicks',
            headerName: CAMPAIGN_LINKS_HEADERS.clicks,
        },
        group_name: {
            field: 'group_name',
            headerName: CAMPAIGN_LINKS_HEADERS.group,
        },
        tags: {
            field: 'tags',
            headerName: CAMPAIGN_LINKS_HEADERS.tags,
        },
        created_at: {
            field: 'created_at',
            headerName: CAMPAIGN_LINKS_HEADERS.created_on,
        }
    };

    const ROW_HEIGHT = 100;
    const PAGE_SIZE = 10;

    const [product, setProduct] = useState({});
    const defaultSortModel = [{ field: CampaignLinksColumns.created_at.field, sort: 'desc' }];
    const [sortModel, setSortModel] = useState(defaultSortModel);
    const [totalRows, setTotalRows] = useState(0);
    const [rows, setRows] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [isHeaderLoading, setHeaderLoading] = useState(false);
    const [isGridLoading, setGridLoading] = useState(false);
    const [isMetricsLoading, setMetricsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [clickedRow, setClickedRow] = useState({});
    const [shortUrlDomains, setShortUrlDomains] = useState([]);
    const [selectedShortUrlDomain, setSelectedShortUrlDomain] = useState(ALL);
    const [customDate, setCustomDate] = useState(CUSTOM_DATE_LABEL);
    const defaultDate = calendarTabSwitchLabels(customDate)[2].label;
    const [selectedDayTab, setSelectedDayTab] = useState(defaultDate);
    const months = parseInt(defaultDate.slice(0, -1), 10);
    const [dateRange, setDateRange] = useState([subMonths(new Date(), months), format(new Date(), MEASUREMENT_PRODUCTS_API_DATE_FORMAT)]);
    const navigate = useNavigate();

    const { warehouse } = useContext(WarehouseContext);

    useEffect(() => {
        // initial load - no need to do anything
        if (selectedDayTab === CUSTOM_DATE_LABEL) {
            return;
        }
        setDateRange(getDateRange(selectedDayTab));
    }, [selectedDayTab]);

    function handleCustomDateChange(dateLabel) {
        setCustomDate(dateLabel);
        if (dateLabel === CUSTOM_DATE_LABEL) {
            setSelectedDayTab(defaultDate);
        } else {
            setSelectedDayTab(dateLabel);
        }
    }

    function renderLinkCell(params) {
        const { title, link } = params.row;
        return (
            <div className='campaign-link-cell'>
                <div className='body-m text-truncate'>{isEmpty(title) ? '-' : title}</div>
                <div className='body-r text-truncate color-neutrals-secondary-grey'>{isEmpty(link) ? '-' : link}</div>
            </div>
        );
    }

    function renderShortLinkCell(params) {
        return <ShortLinkCell value={params.row[CampaignLinksColumns.short_link.field]}/>
    }

    function renderTotalClicksCountCell(params) {
        const total_clicks = params.row[CampaignLinksColumns.total_clicks.field];
        return (
            <div className='body-r color-neutrals-secondary-grey'>
                {total_clicks > 0 ? total_clicks : '-'}
            </div>
        );
    }

    function renderGroupCell(params) {
        const group_name = params.row[CampaignLinksColumns.group_name.field];
        return (
            <div className='body-r color-neutrals-secondary-grey'>
                {isEmpty(group_name) ? '-' : group_name}
            </div>
        );
    }

    function renderCreatedOnCell(params) {
        const created_at = params.row[CampaignLinksColumns.created_at.field];
        let date = '-';
        if (created_at) {
            date = convertTimeToLocale(created_at, DATE_FORMAT);
        }
        return (
            <div className={'body-r campaign-data-grid-cell-content'}>
                {date}
            </div>
        );
    }

    function renderTagsCell(params) {
        const tags = params.row[CampaignLinksColumns.tags.field];
        if (isEmpty(tags)) {
            return '-';
        }
        const label = <span>
            {tags[0]}&nbsp;
            {tags.length > 1 ?
                <span className='color-neutrals-secondary-grey'>{getTagsAdditionalText(tags.length - 1)}</span> : null}
        </span>;
        return (
            <div className={'body-r'}>
                <Chip label={label} index={0}/>
            </div>
        );
    }

    const columns = [
        {
            field: CampaignLinksColumns.id.field,
            headerName: CampaignLinksColumns.id.headerName,
            hide: true,
        },
        {
            ...commonHeaderProps,
            align: 'left',
            field: CampaignLinksColumns.title.field,
            headerAlign: 'left',
            headerName: CampaignLinksColumns.title.headerName,
            // minWidth: '253px',
            // maxWidth: '40%',
            minWidth: 300,
            renderCell: renderLinkCell,
            sortable: false,
        },
        {
            ...commonHeaderProps,
            align: 'left',
            field: CampaignLinksColumns.short_link.field,
            headerAlign: 'left',
            headerName: CampaignLinksColumns.short_link.headerName,
            // minWidth: '25%',
            minWidth: 260,
            renderCell: renderShortLinkCell,
            sortable: false,
        },
        {
            ...commonHeaderProps,
            align: 'right',
            field: CampaignLinksColumns.total_clicks.field,
            headerAlign: 'right',
            headerName: CampaignLinksColumns.total_clicks.headerName,
            flex: 0.5,
            // maxWidth: '5%',
            // minWidth: '5%',
            renderCell: renderTotalClicksCountCell,
            sortable: true,
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, CampaignLinksColumns.total_clicks.field)}`,
        },
        {
            ...commonHeaderProps,
            align: 'left',
            field: CampaignLinksColumns.group_name.field,
            headerAlign: 'left',
            headerName: CampaignLinksColumns.group_name.headerName,
            flex: 0.6,
            // minWidth: '10%',
            // maxWidth: '10%',
            renderCell: renderGroupCell,
            sortable: false,
        },
        {
            ...commonHeaderProps,
            align: 'left',
            field: CampaignLinksColumns.tags.field,
            headerAlign: 'left',
            headerName: CampaignLinksColumns.tags.headerName,
            flex: 1,
            // minWidth: '15%',
            renderCell: renderTagsCell,
            sortable: false,
        },
        {
            ...commonHeaderProps,
            align: 'left',
            field: CampaignLinksColumns.created_at.field,
            headerAlign: 'left',
            headerName: CampaignLinksColumns.created_at.headerName,
            flex: 0.7,
            // maxWidth: '100px',
            // minWidth: '15%',
            renderCell: renderCreatedOnCell,
            sortable: true,
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, CampaignLinksColumns.created_at.field)}`,
        },
    ];

    async function fetchCampaignLinksCsv() {
        let sort_by = null,
            sort_desc = null;
        if (sortModel?.length === 1) {
            sort_by = sortModel[0].field.toLocaleUpperCase();
            sort_desc = sortModel[0].sort === 'desc';
        }
        const domain_names = (selectedShortUrlDomain === ALL) ? null : selectedShortUrlDomain;
        return await exportCampaignLinksCsv({
            created_from: dateRange[0],
            created_to: dateRange[1],
            domain_names,
            search_string: searchText,
            sort_by,
            sort_desc,
            warehouse,
        });
    }

    async function fetchShortUrlDomains() {
        try {
            const { data, default_domain } = await getAllShortUrlDomains({ warehouse });
            const domains = [ALL];
            !isEmpty(default_domain) && domains.push(default_domain);
            !isEmpty(data) && domains.push(...data);
            setShortUrlDomains(domains);
        } catch (e) {
            console.error('Error fetching data:', e);
        }
    }

    async function fetchLinksMetrics() {
        try {
            setMetricsLoading(true);
            const res = await getLinksMetrics({
                warehouse,
                created_from: dateRange[0],
                created_to: dateRange[1],
            });
            const { total_links, total_clicks } = res;
            setMetrics({ total_links, total_clicks });
        } catch (e) {
            console.error('Error fetching data:', e);
        } finally {
            setMetricsLoading(false);
        }
    }

    async function fetchCampaignLinks() {
        try {
            let sort_by = null,
                sort_desc = null;
            if (sortModel?.length === 1) {
                sort_by = sortModel[0].field;
                sort_desc = sortModel[0].sort === 'desc';
            }

            const domain_names = (selectedShortUrlDomain === ALL) ? [] : [selectedShortUrlDomain];
            setGridLoading(true);
            const res = await getAllCampaignLinks({
                created_from: dateRange[0],
                created_to: dateRange[1],
                domain_names,
                limit: PAGE_SIZE,
                offset: pageNumber * PAGE_SIZE,
                search_string: searchText,
                sort_by,
                sort_desc,
                warehouse,
            });
            const { data, metadata: { count: total_count } } = res;
            setRows(data);
            setTotalRows(total_count);
        } catch (e) {
            console.error('Error fetching data:', e);
        } finally {
            setGridLoading(false);
        }
    }

    useEffect(() => {
        const urlParts = window.location.pathname.replace(/\/+$/, '').split('/');
        const productId = urlParts[urlParts.length - 1];
        if (!isEmpty(productId)) {
            setHeaderLoading(true);
            getMeasurementProduct(productId).then(setProduct).finally(() => setHeaderLoading(false));
        }
        fetchLinksMetrics().then(() => {
        });
        fetchShortUrlDomains().then(() => {
        });
    }, [warehouse.environmentChanged]);


    useEffect(() => {
        fetchCampaignLinks().then(() => {
        });
    }, [sortModel, pageNumber, searchText, selectedShortUrlDomain, dateRange, warehouse.environmentChanged]);

    useEffect(() => {
        fetchLinksMetrics().then(() => {
        });
    }, [dateRange]);

    const headerProps = {
        isLoading: isHeaderLoading,
        title: product?.title ?? '',
        subtitle: product?.description ?? '',
        docsLink: product?.pathToPage ?? '',
        onBackClick: () => navigate(`/developers/products/${RouteKeys.ROI_MEASUREMENT}`)
};

    const infoBannerProps = {
        message: UPDATED_PERIODICALLY,
    };

    const datePickerProps = {
        selectedDayTab,
        customDate,
        setSelectedDayTab,
        daysArray: calendarTabSwitchLabels(customDate),
        onDateChanged: handleCustomDateChange
    }

    const exportCsvProps = {
        onExportCsvClick: fetchCampaignLinksCsv,
        defaultFileName: 'campaign_links.csv',
    };

    const topMetricsArray = [{
        metricsRemixIcon: <Icons.links_icon/>,
        title: metrics?.total_links ? formatNumber(metrics?.total_links) : '-',
        subtitle: campaignLinksGenerated,
    }, {
        metricsRemixIcon: <Icons.mouse_click_icon/>,
        title: metrics?.total_clicks ? formatNumber(metrics?.total_clicks) : '-',
        subtitle: totalLinkClicks,
    }];

    const topMetricsProps = {
        topMetricsArray,
        isLoading: isMetricsLoading,
    };

    const gridDropdownProps = {
        label: SHORT_LINK_DOMAINS,
        menuItems: shortUrlDomains,
        onItemSelect: setSelectedShortUrlDomain,
        selectedValueText: selectedShortUrlDomain,
        rowRenderer: (menuItemIndex) => {
            let menuItemText = shortUrlDomains[menuItemIndex];
            if (menuItemIndex === 1) {
                menuItemText = `${menuItemText} (${DEFAULT})`;
            }
            return <span
                className={'body-m color-neutrals-primary-grey'}>{menuItemText}</span>
        }
    };

    const gridProps = {
        columns,
        customProps: {
            emptyTitle: NO_CAMPAIGN_LINKS_AVAILABLE,
            emptySubtitle: WE_WILL_SHOW_THEM,
            noDataMessage: WE_COULDNT_FIND_ANY_CAMPAIGN_LINKS,
        },
        getRowHeight: () => ROW_HEIGHT,
        loading: isGridLoading,
        onPageChange: setPageNumber,
        onRowClick: (params) => {
            setClickedRow(rows.find((row) => row.id === params.row.id));
        },
        onSortModelChange: setSortModel,
        page: pageNumber,
        rowCount: totalRows,
        rows,
        sortModel,
    };

    const sideDrawerProps = {
        isOpen: !isEmpty(clickedRow),
        onClose: () => setClickedRow(null),
        drawerData: {
            title: clickedRow?.title,
            subtitleLink: clickedRow?.link,
            metrics: [
                {
                    title: CAMPAIGN_LINKS_HEADERS.clicks,
                    value: clickedRow?.total_clicks ? formatNumber(clickedRow?.total_clicks) : '-',
                },
                {
                    title: CAMPAIGN_LINKS_HEADERS.group,
                    value: clickedRow?.group_name,
                },
                {
                    title: CAMPAIGN_LINKS_HEADERS.created_on,
                    value: clickedRow?.created_at ? convertTimeToLocale(clickedRow?.created_at, DATE_FORMAT) : null,
                },
            ],
            shortLink: clickedRow?.short_link,
            tags: clickedRow?.tags,
        },
    };

    const gridSearchProps = {
        value: searchText,
        onEnter: setSearchText,
        onClear: () => {
            setSearchText('');
        },
        placeholder: SEARCH_LINKS_PLACEHOLDER,
    };

    return (
        <div>
            <MeasurementsPageTemplate
                headerProps={headerProps}
                infoBannerProps={infoBannerProps}
                exportCsvProps={exportCsvProps}
                topMetricsProps={topMetricsProps}
                gridDropdownProps={gridDropdownProps}
                gridProps={gridProps}
                sideDrawerProps={sideDrawerProps}
                gridSearchProps={gridSearchProps}
                datePickerProps={datePickerProps}
                showEmptyScreen={shortUrlDomains.length === 1}
            />
        </div>
    );
};

