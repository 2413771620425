import { Input } from "antd";
import React from "react";
import "./InputField.scss";
import PropTypes from "prop-types";
import Icons from "../Icons/Icons";
import { Colors } from "../../styles/colors";

export default function InputField({ inputLabel, placeholder, errorMessage = "", hasError = false, ...otherParams }) {
  // whatever label is passed in, name will be the same, but formatted to lower case, with spaces replaced with underscores
  const name = inputLabel.toLowerCase().replaceAll(" ", "_");
  const fieldLabel = inputLabel[0]?.toUpperCase() + inputLabel?.slice(1);
  return (
    <div className="input-field-container">
      {inputLabel ? (
        <label htmlFor="input-field" className="input-label">
          {`${fieldLabel} ${otherParams.showRequiredIcon ? "*" : ""}`}
        </label>
      ) : (
        <></>
      )}
      <div className="input-icon-container">
        <Input
          className={`input-field`}
          placeholder={placeholder}
          name={name}
          style={{
            fontSize: "14px",
            borderWidth: "2px",
            borderRadius: "6px",
            borderColor: hasError ? Colors.semanticsPrimaryError : "",
            ...otherParams.inputStyle,
          }}
          {...otherParams}
        />
        {/* <div className="input-right-icon">{otherParams.suffix}</div> */}
      </div>
      {hasError ? <span className="input-error-message">{errorMessage}</span> : null}
    </div>
  );
}

InputField.propTypes = {
  inputLabel: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  errorMessage: PropTypes.element,
  hasError: PropTypes.bool,
  showRequiredIcon: PropTypes.bool,
};

InputField.defaultProps = {
  inputLabel: "",
  placeholder: "insightIQ",
  errorMessage: "",
  hasError: false,
  showRequiredIcon: false,
};
