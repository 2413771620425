import { Box } from "@mui/system";
import React, { useState } from "react";
import { Icons, PrimaryButton, Step } from "../../components";
import { setupGuideDetails, setupGuideSteps } from "../../constants/campaignTrackingConstants";
import "./SetupGuidePage.scss";
import { useLocation, useParams } from "react-router-dom";
import gif from "../../components/Icons/setupGuideStep1.gif";

function SetupGuidePage() {
  const [step, setStep] = useState(1);
  const { step1, step2, step3 } = setupGuideSteps;
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);
  const queryParams = Object.fromEntries(searchParams);
  const { shop_slug, theme_id, shop_name } = queryParams;
  const [stepOpen, setStepOpen] = useState({
    1: true,
    2: false,
    3: false,
  })

  function updateStepDropdown(step) {
    setStepOpen((prevStepOpen) => {
      const newStepOpen = { ...prevStepOpen };
      for (const key in newStepOpen) {
        if (+key === step) {
          newStepOpen[key] = true
        }
        else {
          newStepOpen[key] = false
        }
      }
      return newStepOpen;
    });
  }

  const openThemeExtensionURL = () => {
    const baseUrl = "https://admin.shopify.com/store/";
    const url = new URL(baseUrl);
    url.pathname += `${shop_slug}/themes/${theme_id}/editor`;
    url.searchParams.set("context", "apps");
    window.open(url.toString(), "_blank");
  };

  const verifyThemeExtension = () => {
    const baseUrl = `https://${shop_slug}.myshopify.com/`;
    const popupURL = new URL(baseUrl);
    popupURL.searchParams.append("pixel_verify", true);
    window.open(popupURL.toString(), "_blank");
  };

  const goToInsightIQDashboard = () => {
    if (window.opener === null || window.opener === "undefined") {
      window.location.href = "https://dashboard.insightiq.ai/registration";
    } else {
      window.opener.postMessage({ insightiq_status: "user_closed" }, "*");
      window.opener.focus();
      window.close();
    }
  };

  function Step1Content() {
    return (
      <>
        <img className="step1-toggle-gif" src={gif} />
        <PrimaryButton
          className="button ant-btn-primary step-button"
          onClick={() => {
            openThemeExtensionURL();
            setStep(2);
            updateStepDropdown(2)
          }}
          label={
            <div>
              {step1.buttonCTA} <i class="step-icon ri-arrow-right-up-line"></i>
            </div>
          }
        />
      </>
    );
  }
  function Step2Content() {
    return (
      <>
        <PrimaryButton
          className="button ant-btn-primary step-button"
          onClick={() => {
            verifyThemeExtension();
            setStep(3);
            updateStepDropdown(3)
          }}
          disabled={step < 2}
          label={
            <div>
              {step2.buttonCTA} <i class="step-icon ri-arrow-right-up-line"></i>
            </div>
          }
        />
      </>
    );
  }
  function Step3Content() {
    return (
      <>
        <PrimaryButton
          className="button ant-btn-primary step-button"
          onClick={() => {
            goToInsightIQDashboard();
          }}
          disabled={step < 3}
          label={
            <div>
              {step3.buttonCTA} <i class="step-icon ri-arrow-right-up-line"></i>
            </div>
          }
        />
      </>
    );
  }

  return (
    <Box className="setup-guide-page">
      <Box>
        <Box className="insight-ig-logo">
          {" "}
          <Icons.insight_iq_icon_with_name />{" "}
        </Box>

        <Box mb={"32px"}>
          <Icons.setup_guide_logo />
        </Box>

        <Box width={"456px"}>
          <Box className="title" color={"var(--neutrals-secondary-grey)"} mb="4px">
            {setupGuideDetails.title} {shop_name}
          </Box>
          <Box className="title" mb="16px">
            {setupGuideDetails.subTitle}
          </Box>
          <Box className="body-r" color={"var(--neutrals-secondary-grey, #5A5A67)"} lineHeight="150%">
            {setupGuideDetails.desc}
          </Box>
        </Box>
      </Box>

      <Box>
        <Box>
          <Box className={"setup-guide-step"}>
            <Step variant={"DropdownStep"} title={step1.title} message={step1.desc} stepCount={1} stepOpenState={stepOpen} updateStep={(step) => setStepOpen({ ...stepOpen, [step]: !stepOpen[step] })} content={<Step1Content />} />
            <Step variant={"DropdownStep"} title={step2.title} message={step2.desc} stepCount={2} stepOpenState={stepOpen} updateStep={(step) => setStepOpen({ ...stepOpen, [step]: !stepOpen[step] })} content={<Step2Content />} />
            <Step variant={"DropdownStep"} title={step3.title} message={step3.desc} stepCount={3} stepOpenState={stepOpen} updateStep={(step) => setStepOpen({ ...stepOpen, [step]: !stepOpen[step] })} content={<Step3Content />} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SetupGuidePage;
