import React, { useState } from "react";
import InputField from "../InputField/InputField";
import "./InputField.scss";
import Icon from "../Icons/Icons";
import { Tag, Tooltip } from "antd";
import PropTypes from 'prop-types';

export default function PasswordField({ showTags = true, ...props }) {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    longEnough: false,
  });
  const [hasTouched, setHasTouched] = useState(false);

  const validatePassword = (password) => {
    const passwordRequirements = {
      lowercase: password.match(/[a-z]/),
      uppercase: password.match(/[A-Z]/),
      number: password.match(/[0-9]/),
      longEnough: password.length >= 8,
    };
    setPasswordRequirements(passwordRequirements);
  };

  const onChangePassword = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
    if (!hasTouched) {
      setHasTouched(true);
    }
    if (showTags) {
      validatePassword(e.target.value);
    }
  };

  const { longEnough, lowercase, number, uppercase } = passwordRequirements;

  const setToastColor = (value) => {
    if (!hasTouched) {
      return "";
    }
    return value ? "success" : "error";
  };
  return (
    <>
      <InputField
        {...props}
        type={showPassword ? "text" : "password"}
        suffix={
          <button
            type="button"
            className="password-toggle-button"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? (
              <Tooltip title="Hide">
                <Icon.open_eye_active name="open_eye_active" />
              </Tooltip>
            ) : (
              <Tooltip title="Show">
                <Icon.open_eye name="open_eye" />
              </Tooltip>
            )}
          </button>
        }
        onChange={onChangePassword}
      />
      {showTags && (
        <div className="password-suggestion-tags-container " style={props.tagContainerStyle}>
          <Tag color={setToastColor(lowercase)}> lowercase</Tag>
          <Tag color={setToastColor(uppercase)}>uppercase</Tag>
          <Tag color={setToastColor(number)}>number</Tag>
          <Tag color={setToastColor(longEnough)}>8+ characters</Tag>
        </div>
      )}
    </>
  );
}


// <PasswordField showTags={false} inputLabel={inputLabel} placeholder={placeholder} name={name} {...otherParams} />


PasswordField.propTypes = {
  showTags: PropTypes.bool,
  inputLabel: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

PasswordField.defaultProps = {
  showTags: false,
  inputLabel: "Password",
  placeholder: "Please Enter password",
  name: "password",
};

PasswordField.propTypes = {
  showTags: PropTypes.bool,
  tagContainerStyle: PropTypes.object,
  onChange: PropTypes.func,
};

PasswordField.defaultProps = {
  showTags: true,
  tagContainerStyle: {},
};