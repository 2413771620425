import React from "react";
import "./UnlockFullAccessPopUp.scss";
import Icons from "../Icons/Icons";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import PropTypes from "prop-types";
import { MEETING_SCHEDULE_LINK } from "../../constants/constants";
import { trackEvent, TRACKING_UPGRADE_POPUP_CLICKED } from "../../analytics";

const UnlockFullAccessPopUp = ({ open, onClose, header, description, actionButtonLabel, height, isModal, padding }) => {
  if (!open) {
    return null;
  }
  return (
    <div className={isModal ? "modal" : "unlock-access-modal"}>
      <div className="unlock-access-pop-up-container" style={{ height: height, top: `calc(50% - (${height}/2))`, padding: padding }}>
        <div className="unlock-access-pop-up-content-container">
          <div className="unlock-access-pop-up-icon">
            <Icons.lock_icon />
          </div>
          <div className="unlock-access-pop-up-description-container">
            <div className="unlock-access-pop-up-heading">
              <h3 className="unlock-access-pop-up-info-header">{header}</h3>
            </div>
            <div className="unlock-access-pop-up-description">
              <p>{description}</p>
            </div>
            <div className="unlock-access-pop-up-action">
              <PrimaryButton
                className="unlock-access-pop-up-action-button"
                label={
                  <div className={'unlock-access-pop-up-button body-m'}>
                    {actionButtonLabel} <i className="ri-arrow-right-line"></i>
                  </div>
                }
                onClick={() => {
                  trackEvent(TRACKING_UPGRADE_POPUP_CLICKED)
                  window.open(MEETING_SCHEDULE_LINK, "_blank");
                }}
              />
            </div>
          </div>
        </div>
        {isModal && (
          <div className="close-icon-container">
            <Icons.close_icon onClick={onClose} cursor="pointer" />
          </div>
        )}
      </div>
    </div>
  );
};

UnlockFullAccessPopUp.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  header: PropTypes.string,
  description: PropTypes.string,
  actionButtonLabel: PropTypes.string,
  height: PropTypes.string,
  isModal: PropTypes.bool,
};

export default UnlockFullAccessPopUp;
