import React, { useEffect, useState } from 'react';
import './PostsImage.scss';
import {Icons} from '../index';
import { isImageExist } from "../../utils/profileHelpers";
import { story } from '../../constants/campaignTrackingConstants';

export default function PostsImage({ thumbnail_url, title, subTitle , titleIcon, type}) {
    const [thumbnail, setThumbnail] = useState(null);
    useEffect(() => {
        if (!thumbnail_url) {
            setThumbnail(null);
            return;
        }

        isImageExist(thumbnail_url)
            .then(() => {
                setThumbnail(thumbnail_url);
            })
            .catch(() => {
                setThumbnail(null);
            });
    }, [thumbnail_url]);
    return (
        <div className="posts-image-container">
            <div className="posts-image-thumbnail">
                {thumbnail ? <img src={thumbnail} alt="Image" className="posts-image-img"/> : <Icons.couldnt_load_the_thumbnail className="posts-image-img" />}
                {type === story ? <div className='story-text-div small-labels' >{story}</div> : null}
            </div>
            <div className="posts-image-content">
                <div className="posts-image-title">
                    <div className="posts-image-titleIcon">
                        <img src={titleIcon} alt="Platform" className="posts-image-img" />
                    </div>
                    <div className={'posts-image-title-text'}>
                        {title}
                    </div>
                </div>
                <div>{subTitle}</div>
            </div>
        </div>
    );
}