import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MainLayout } from "../components";
import { APICredentialsPage, HelpPage } from "../pages";
import { WebhooksRoutes } from "../routes";
import ProductsRoutes from "../routes/ProductsRoutes";

export default function DeveloperModule() {
    return (
        <MainLayout>
            <Routes path="">
                <Route path="help" element={<HelpPage/>}/>
                <Route path="support-and-sales" element={<HelpPage/>}/>
                <Route path="products/*" element={<ProductsRoutes/>}/>
                <Route path="webhooks/*" element={<WebhooksRoutes/>}/>
                <Route path="api-credentials" element={<APICredentialsPage/>} title="Credentials Page"/>
                <Route path="*" element={<Navigate to="products/"/>}/>
            </Routes>
        </MainLayout>
    );
}
