import React, { useEffect, useRef } from "react";
import "./GenericPopup.scss";
import Icons from "../Icons/Icons";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { styled } from "styled-components";

export default function GenericPopup({
                                         icon,
                                         open,
                                         onClose,
                                         header,
                                         description,
                                         actionButtonLabel,
                                         height,
                                         padding,
                                         handleButtonClick,
                                         actionButtonSubtext,
                                         closeOnOutsideClick = true,
                                     }) {

    const modalRef = useRef(null);
    useEffect(function () {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target) && closeOnOutsideClick) {
                onClose(event);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const GenericPopUpContainer = styled.div.attrs((props) => ({
        className: `${props.className || ''}`,
    }))`
        height: ${(props) => props.height};
        top: calc(50% - (${(props) => props.height} / 2));
        padding: ${(props) => props.padding};
    `;

    if (!open) {
        return null;
    }

    return (
        <div className={"modal"}>
            <GenericPopUpContainer height={height} padding={padding} className="generic-pop-up-container"
                                   ref={modalRef}>
                <div className="generic-pop-up-content-container">
                    <div className="generic-pop-up-icon">
                        {icon}
                    </div>
                    <div className="generic-pop-up-description-container">
                        <div className="generic-pop-up-heading">
                            <h3 className="generic-pop-up-info-header">{header}</h3>
                        </div>
                        <div className="generic-pop-up-description">
                            <p>{description}</p>
                        </div>
                        {actionButtonLabel ? <div className="generic-pop-up-action">
                            <PrimaryButton
                                className="generic-pop-up-action-button"
                                label={actionButtonLabel}
                                onClick={handleButtonClick}
                            />
                        </div> : null}
                        {actionButtonSubtext}
                    </div>
                </div>
                <div className="close-icon-container">
                    <Icons.close_icon onClick={onClose} cursor="pointer"/>
                </div>
            </GenericPopUpContainer>
        </div>
    );
}
