import React, { useContext, useEffect, useState } from 'react';
import './ResetPasswordDialog.scss';
import { RESET_PASSWORD_MODAL } from '../../../constants/constants';
import { GenericPopup, ToastMessage } from '../../index';
import { resetPasswordApi } from "../../../api";
import { ADD_LIST_TOAST_MESSAGE_DURATION } from "../../../constants/creatorDiscovery";
import { Icons } from '../../../components';
import { RESEND_PWD_CLICKED, RESET_PWD_CLICKED, trackEvent } from "../../../analytics";
import { UserContext } from "../../../storage/context";

export default function ResetPasswordDialog({ open, handleClose, email }) {
    const [isDisabled, setIsDisabled] = useState(false);
    const [isToastOpen, setToastOpen] = useState(false);
    const { userProfile } = useContext(UserContext);

    function resendEmail() {
        setIsDisabled(true);
        resetPasswordApi.resetPassword(email).then((res) => {
            if (res.status === 200) {
                setToastOpen(true);
            }
        }).finally(() => {
            setIsDisabled(false);
            handleClose();
        });
        trackEvent(RESEND_PWD_CLICKED, { email: userProfile?.user?.email });
    }

    useEffect(() => {
        if (open) {
            resetPasswordApi.resetPassword(email).then(() => {
            });
            trackEvent(RESET_PWD_CLICKED, { email: userProfile?.user?.email });
        }
    }, [open]);

    return (
        <>
            <GenericPopup
                open={open}
                icon={<span className={'span-reset-password-icon'}><Icons.reset_password_icon/></span>}
                onClose={handleClose}
                header={RESET_PASSWORD_MODAL.title}
                description={<div>
                    {RESET_PASSWORD_MODAL.description(email)}
                    <ul>
                        <li>{RESET_PASSWORD_MODAL.bullet1}</li>
                        <li>{RESET_PASSWORD_MODAL.bullet2}</li>
                    </ul>
                    {RESET_PASSWORD_MODAL.didntReceive}
                    &nbsp;
                    <a className={'body-m color-main-primary a-resend-it'} onClick={resendEmail}
                       style={{
                           pointerEvents: isDisabled ? 'none' : '',
                           cursor: isDisabled ? 'default' : 'pointer',
                       }}>
                        {RESET_PASSWORD_MODAL.resend}
                    </a>
                </div>}
                height="285px"
                padding="48px 48px 48px 48px"
                isModal
            />
            <ToastMessage
                openToast={isToastOpen}
                message={RESET_PASSWORD_MODAL.emailSent(email)}
                duration={ADD_LIST_TOAST_MESSAGE_DURATION}
                onCloseToast={() => {
                    setToastOpen(false);
                }}
                type={'success'}
            />
        </>
    )
};

