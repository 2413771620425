import axiosInstance from "../axiosInstance";
import { currentDashboardURL, rotateCredentialsEndpoint, signInEndpoint, signOutEndpoint } from "../endpoints";
import { LOGOUT_FAILURE, LOGOUT_SUCCESS, logUser, SIGN_IN_FAILURE, SIGN_IN_SUCCESS, trackEvent } from "../../analytics";
const signInUser = async ({ email, password, analytics }) => {
  try {
    const response = await axiosInstance.post(`${currentDashboardURL}/${signInEndpoint}`, {
      email,
      password,
    });
    if (response.status === 200) {
      logUser(email.toLowerCase(), response.email);
      trackEvent(SIGN_IN_SUCCESS, { email: response.data.email });
      return response;
    }
    trackEvent(SIGN_IN_FAILURE, { email, failure_reason: response?.response?.data?.error });
  } catch (e) {
    trackEvent(SIGN_IN_FAILURE, { email, failure_reason: e.response?.data?.error?.message });
    throw e.response.data.error;
  }
};

const signOutUser = async () => {
  try {
    const response = await axiosInstance.get(`/${signOutEndpoint}`);
    if (response.status === 200) {
      trackEvent(LOGOUT_SUCCESS, response.data);
      return response;
    }
    throw new Error("Sign out failed");
  } catch (e) {
    trackEvent(LOGOUT_FAILURE, { failure_reason: e?.response?.data?.error });
    throw e;
  }
};

export { signInUser, signOutUser };
