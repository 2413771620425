import React, { useContext } from "react";
import HelpGuideText from "../../constants/help-guides.json";
import { CardLink, Icons, PrimaryButton, SupportEmail, Typography } from "../../components";
import "./HelpPage.scss";
import {
    getPageTitle,
    HELP_LABEL,
    IMPORTANT_LINKS,
    MEETING_SCHEDULE_LINK,
    SCHEDULE_A_CALL,
    TALK_TO_US,
    TALK_TO_US_DESC
} from "../../constants/constants";
import { Helmet } from "react-helmet";
import { WarehouseContext } from "../../storage/context";
import { HELP_PAGE_VIEW, SCHEDULE_A_CALL_CLICK, trackEvent } from "../../analytics";


export default function HelpPage() {
    const { warehouse } = useContext(WarehouseContext);

    const buttonsLabelsAndActions =
        {
            label: SCHEDULE_A_CALL,
            icon: <Icons.calendar_icon/>,
            buttonAction: () => {
                window.open(MEETING_SCHEDULE_LINK, "_blank");
                trackEvent(SCHEDULE_A_CALL_CLICK, { link: MEETING_SCHEDULE_LINK, source: "SIDEBAR" });
            },
        };

    trackEvent(HELP_PAGE_VIEW);
    return (
        <div>
            <Helmet>
                <title>{getPageTitle("HELP_PAGE", warehouse.environment.current)}</title>
            </Helmet>
            <Typography h1 className="title title-container">
                {HELP_LABEL}
            </Typography>
            <div className="help-page-main-container">
                <div className="help-page-container">
                    <div className="section-heading important-link-heading">{IMPORTANT_LINKS}</div>
                    {HelpGuideText.map((helpGuide, i) => (
                        <div className={`help-section section-${i}`}>
                            <Typography h3 style={{ marginBottom: "11px" }}>
                                {helpGuide.heading}
                            </Typography>
                            <div className="helping-card-container">
                                {helpGuide.items.map((content, j) => (
                                    <CardLink
                                        title={content.title}
                                        description={content.description}
                                        link={content.link}
                                        backgroundColor={helpGuide.background_color}
                                        className="individual-card"
                                        event={content.event}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div
                    className={`talk-to-us-container ${!!warehouse?.headerTopNavInfo?.showHeader ? 'talk-to-us-with-header' : null} `}>
                    <div className="section-heading">{TALK_TO_US}</div>
                    <div className="talk-to-us-desc body-r">{TALK_TO_US_DESC}</div>

                    <div className="help-page-schedule-call-action">
                        <PrimaryButton
                            icon={buttonsLabelsAndActions.icon}
                            label={<span
                                className={'button color-neutrals-white'}>{buttonsLabelsAndActions.label}</span>}
                            className="schedule-call-icon-button"
                            onClick={buttonsLabelsAndActions.buttonAction}
                        />
                        <SupportEmail/>
                    </div>
                </div>
            </div>
        </div>
    );
}
