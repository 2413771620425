import React, { useContext, useEffect, useReducer, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { WarehouseContext } from "../storage/context/index";
import { useFetchTenantInformation } from "../hooks/useFetchTenantInformation";
import { CreatorModule, DeveloperModule } from "../modules";
import { tenantInfoApi } from "../api";
import { Production } from "../constants/constants";
import { ADD_ENABLED_PRODUCTS, UPDATE_ANALYTICS, UPDATE_BULK_EXPORT_STATUS, UPDATE_PRODUCTION_KEY_STATUS, } from "../storage/reducers/types";
import { IntermediateLoader } from "../components";
import { parseTenantAppProducts, parseTenantAppProductsForAnalytics } from "../utils/tenantProductHelpers";
import { analyticsReducer } from "../storage/reducers";
import { logUser } from "../analytics";
import { RedirectAmberfloUsagePage, SetNewPasswordPage, SetupGuidePage } from "../pages";
import ShopifyOAuth from "../pages/CampaignTrackingPage/ShopifyOAuth";

export default function AuthenticatedRoutes(props) {
    const navigate = useNavigate();
    const fetchTenantInformation = useFetchTenantInformation();
    const {
        warehouse,
        warehouse: { tenantInfo, environmentChanged },
        dispatchWarehouse,
    } = useContext(WarehouseContext);
    const [analytics, dispatchAnalytics] = useReducer(analyticsReducer, JSON.parse(localStorage.getItem("insightiq_analytics")));
    const [isLoading, setIsLoading] = useState(true);
    const [prodTenantAppId, setProdTenantAppId] = useState(null);
    useEffect(() => {
        if (tenantInfo.length === 0) fetchTenantInformation().then(() => {
        });
        let linkToRedirect = JSON.parse(localStorage.getItem("userRequestedLocation"));
        if (linkToRedirect) {
            navigate(linkToRedirect);
            localStorage.removeItem("userRequestedLocation");
        } else {
            linkToRedirect = props.userRequestedLocation;
            navigate(linkToRedirect);
        }
    }, []);

    useEffect(() => {
        if (environmentChanged === true) fetchTenantInformation().then(() => {
        });
    }, [environmentChanged]);

    useEffect(() => {
        (async () => {
            // Check which products are enabled for this tenant and which of them are paid
            const user = JSON.parse(localStorage.getItem("user"));
            let tenantResponse = await tenantInfoApi.getTenantInfo(Production);
            setProdTenantAppId(tenantResponse[0]?.apps[0]?.id);
            let enabledTenantProducts, userProductsSubscription;
            try {
                enabledTenantProducts = await tenantInfoApi.checkProductsEnabledForTenant(warehouse.environment.current);
                userProductsSubscription = parseTenantAppProductsForAnalytics(enabledTenantProducts.tenant_app_products);
                dispatchWarehouse({
                    type: ADD_ENABLED_PRODUCTS,
                    payload: parseTenantAppProducts(enabledTenantProducts.tenant_app_products)
                });
                window.clarity("identify", user.email.toLowerCase(), "", "", {
                    registered_from: window.location.host,
                    user_type: userProductsSubscription,
                });
                logUser(user.email.toLowerCase(), {
                    registered_from: window.location.host,
                    user_type: userProductsSubscription,
                });
                dispatchAnalytics({
                    type: UPDATE_ANALYTICS,
                    payload: {
                        tenant_name: user?.companyName,
                        user_email: user?.email.toLowerCase(),
                        user_id: user?.email.toLowerCase(),
                        user_type: userProductsSubscription,
                    },
                });
            } catch (err) {
                console.warn(err);
            }
            if (tenantResponse.length !== 0) {
                const isProductionEnvEnable = tenantResponse[0]?.apps[0]?.enabled_on_dashboard;
                dispatchWarehouse({
                    type: UPDATE_PRODUCTION_KEY_STATUS,
                    payload: {
                        isAvailable: isProductionEnvEnable,
                    },
                });
                const isBulkExportEnabled = tenantResponse[0]?.apps[0]?.is_dashboard_async_profile_export_enabled;
                dispatchWarehouse({
                    type: UPDATE_BULK_EXPORT_STATUS,
                    payload: isBulkExportEnabled,
                });
            }
            setIsLoading(false);
        })();
    }, []);

    if (isLoading) {
        return (
            <div className="loader-container" style={{ height: "100vh", width: "100vw" }}>
                <IntermediateLoader />
            </div>
        );
    }

    return (
        <>
            <Routes path="">
                <Route path="creators/*" element={<CreatorModule/>}/>
                <Route path="developers/usage" element={<RedirectAmberfloUsagePage prodTenantAppId={prodTenantAppId} />}/>
                <Route path="developers/*" element={<DeveloperModule/>}/>
                <Route path="setup-guide" element={<SetupGuidePage/>}/>
                <Route path="shopify-oauth" element={<ShopifyOAuth/>}/>
                <Route path="set-password" element={<SetNewPasswordPage />} />
                <Route path="*" element={<Navigate to={'creators'}/>}/>
            </Routes>
        </>
    );
}
