import styled from "styled-components";
import {Button} from 'antd';

export const ApplyButton = styled(Button)`
  background: #2262ED;
  border-radius: 4px;
`;

export const ResetButton = styled(Button)`
  background: #FFFFFF;
  border: 1px solid #2262ED;
  border-radius: 4px;
  color: #2262ED;
`;
