import { ReactComponent as logo_facebook_connected } from "../components/Icons/logo_facebook_connected.svg";
import { ReactComponent as logo_facebook_not_connected } from "../components/Icons/logo_facebook_not_connected.svg";

import { ReactComponent as logo_instagram_connected } from "../components/Icons/logo_instagram_connected.svg";
import { ReactComponent as logo_instagram_not_connected } from "../components/Icons/logo_instagram_not_connected.svg";

import { ReactComponent as logo_tiktok_connected } from "../components/Icons/logo_tiktok_connected.svg";
import { ReactComponent as logo_tiktok_not_connected } from "../components/Icons/logo_tiktok_not_connected.svg";

import { ReactComponent as logo_twitch_connected } from "../components/Icons/logo_twitch_connected.svg";
import { ReactComponent as logo_twitch_not_connected } from "../components/Icons/logo_twitch_not_connected.svg";

import { ReactComponent as logo_twitter_connected } from "../components/Icons/logo_twitter_connected.svg";
import { ReactComponent as logo_twitter_not_connected } from "../components/Icons/logo_twitter_not_connected.svg";

import { ReactComponent as logo_youtube_connected } from "../components/Icons/logo_youtube_connected.svg";
import { ReactComponent as logo_youtube_not_connected } from "../components/Icons/logo_youtube_not_connected.svg";
export const LogosWithStatus = {
    facebook: {
        connected: logo_facebook_connected,
        not_connected: logo_facebook_not_connected,
        session_expired: logo_facebook_not_connected,
    },
    instagram: {
        connected: logo_instagram_connected,
        not_connected: logo_instagram_not_connected,
        session_expired: logo_instagram_not_connected,
    },
    tiktok: {
        connected: logo_tiktok_connected,
        not_connected: logo_tiktok_not_connected,
        session_expired: logo_tiktok_not_connected,
    },
    twitch: {
        connected: logo_twitch_connected,
        not_connected: logo_twitch_not_connected,
        session_expired: logo_twitch_not_connected,
    },
    twitter: {
        connected: logo_twitter_connected,
        not_connected: logo_twitter_not_connected,
        session_expired: logo_twitter_not_connected,
    },
    youtube: {
        connected: logo_youtube_connected,
        not_connected: logo_youtube_not_connected,
        session_expired: logo_youtube_not_connected,
    },
};
