import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import "./CampaignDataGrid.scss";
import { addContentToCampaign, getAllBrands, getAllCampaigns } from "../../api/CampaignTrackingAPI/CampaignTrackingAPI";
import AddPostDialog from "../AddPostDialog/AddPostDialog";
import { CampaignTrackingContext, WarehouseContext } from "../../storage/context";
import { campaignTrackingProductID, DATE_FORMAT, TRIAL, WORKPLATFORM_ID_MAPPING } from "../../constants/constants";
import { EmptyTableScreen, Icons, PrimaryButton, UnlockFullAccessPopUp } from "../index";
import { formatNumber, getWorkPlatformIDFromURL } from "../../utils/profileHelpers";
import { CustomFooter, getSortedHeaderClass } from "../../utils/DataGridUtils";
import { clearCampaignTrackingWarehouse, convertTimeToLocale, isCampaignEnded, isEmpty } from "../../utils/util";
import { Box } from "@material-ui/core";
import {
    newCampaign,
    NO_CAMPAIGNS_FOUND,
    Sample,
    SEARCH_CAMPAIGNS_BRANDS,
    START_YOUR_FIRST_CAMPAIGN,
    START_YOUR_FIRST_CAMPAIGN_MESSAGE,
    unlockAccessPopupDetails,
    yourCampaigns,
} from "../../constants/campaignTrackingConstants";
import { Chip, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../styles/colors";
import {
    trackEvent,
    TRACKING_CAMPAIGNLIST_ADD_POST_CLICKED,
    TRACKING_CAMPAIGNLIST_NEW_CAMPAIGN_CLICKED,
    TRACKING_CAMPAIGNLIST_SEARCHED,
    TRACKING_UPGRADE_POPUP_CLOSED,
    TRACKING_UPGRADE_POPUP_OPENED
} from "../../analytics";
import TextBox from "../InsightIQ/TextBox/TextBox";

export default function CampaignDataGrid({ onRowClick, isCreateEnabled }) {
    const allowedSorts = ["desc", "asc"];
    const defaultSortModel = [{ field: "created_at", sort: "desc" }];
    const PAGE_SIZE = 10;
    const ROW_HEIGHT = 68;
    const { dispatchCampaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const { warehouse } = useContext(WarehouseContext);
    const navigate = useNavigate();
    // const [pageSize, setPageSize] = useState(10); // Adjust the page size according to your needs
    const [rows, setRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(0); // Adjusted to 0-based indexing
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [sortModel, setSortModel] = useState(defaultSortModel);
    const [openDialog, setOpenDialog] = useState(false);
    const [clickedCampaignId, setClickedCampaignId] = useState(null);
    const [clickedCampaignName, setClickedCampaignName] = useState(null);
    const [campaignBrands, setCampaignBrands] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [initialLoad, setInitialLoad] = useState(true);
    const [modalDetails, setModalDetails] = useState({
        isModalVisible: false,
        modalInfo: "",
    });
    const isTrialMode = warehouse.tenantAppProducts[campaignTrackingProductID] === TRIAL;

    useEffect(() => {
        fetchData().then(() => {
        });
    }, [pageNumber, sortModel, searchText]);

    useEffect(() => {
        getAllBrands({ warehouse, limit: 100 }).then((v) => setCampaignBrands(v.data));
    }, []);

    async function fetchData() {
        try {
            let sort_by = null,
                sort_desc = null;
            if (sortModel.length === 1) {
                sort_by = sortModel[0].field;
                sort_desc = sortModel[0].sort === "desc";
            }
            setLoading(true);

            const res = await getAllCampaigns({
                offset: pageNumber * PAGE_SIZE,
                limit: PAGE_SIZE,
                sort_by,
                sort_desc,
                search_string: searchText,
                warehouse,
            });
            const { data, total_count } = res;
            // TODO if data or totalCount is empty, show create-first-campaign messaging
            setRows(data);
            setTotalRows(total_count);
            if (!isEmpty(searchText)) trackEvent(TRACKING_CAMPAIGNLIST_SEARCHED, {
                input: searchText,
                number_of_results: total_count
            })
        } catch (e) {
            // TODO show error screen
            console.error("Error fetching data:", e);
        } finally {
            setLoading(false);
            setInitialLoad(false);
        }
    }

    const handlePageChange = (newPageNumber) => {
        setPageNumber(newPageNumber); // Adjusted to 0-based indexing
    };

    // const handlePageSizeChange = (event) => {
    //     setPageSize(+event.target.value);
    //     setPageNumber(0); // Reset page number to 0 when page size changes
    // };

    function handleRowClick(params) {
        const { id, row } = params;
        const { name, total_content_count, created_at } = row;
        const brand_name = renderBrandsCell(params).props.children;
        // Get the ID value of the clicked row
        // Call your callback function and pass the "id" value
        // For example, if you have a callback prop named "onRowClick":
        if (onRowClick) {
            onRowClick(id, name, total_content_count, created_at, brand_name);
        }
    }

    function handleAddPostClick(params) {
        if (!!!params.id) {
            return;
        }
        setClickedCampaignId(params.id);
        setClickedCampaignName(params.row.name)
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
        setClickedCampaignId(null);
        setClickedCampaignName(null)

    }

    function handleConfirmDialog(campaignId, contentUrl, campaignName) {
        let workPlatformID = getWorkPlatformIDFromURL(contentUrl)
        // TODO do some error checking
        addContentToCampaign({
            warehouse,
            content_urls: [contentUrl],
            campaignId,
        })
            .then(fetchData)
            .catch(console.error);
        trackEvent(TRACKING_CAMPAIGNLIST_ADD_POST_CLICKED, {
            url_name: contentUrl,
            campaign_name: campaignName,
            platform_name: WORKPLATFORM_ID_MAPPING[workPlatformID].platformName
        });
        handleCloseDialog();
    }

    function renderCampaignsCell(params) {
        return <div className={'campaign-data-grid-first-column'}>
            {params.row.is_trial ? <Chip classes={{ label: 'subtitle-r' }} size={'small'} label={Sample}/> : null}
            <span className={'body-m campaign-title'}>{params.row.name}</span>
        </div>;
    }

    function renderBrandsCell(params) {
        if (!!!campaignBrands) {
            console.error("Campaigns grid - brands not available");
            return "";
        }
        let brandName = campaignBrands?.find((campaignBrand) => campaignBrand.id === params.row.brand_id);
        brandName = brandName ? brandName.name : "-";
        return <div className={"body-r campaign-data-grid-cell-content"}>{brandName}</div>;
    }

    function renderTotalContentCountCell(params) {
        return <div className={"body-r campaign-data-grid-cell-content"} style={{ paddingRight: '2rem' }}>
            {formatNumber(params.row.total_content_count)}
        </div>;
    }

    const renderCreatedOnCell = (params) => {
        let date = "-";
        if (params.row.created_at) {
            date = convertTimeToLocale(params.row.created_at, DATE_FORMAT);
        }
        return <div className={"body-r campaign-data-grid-cell-content"}>{date}</div>;
    };

    function renderAddPostCell(params) {
        if (isCampaignEnded(params.row)) {
            return null;
        }
        return (
            <div
                className={"body-m campaign-data-grid-cell-content"}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (params.row.is_trial) {
                        setModalDetails({
                            ...modalDetails,
                            isModalVisible: true,
                            modalInfo: unlockAccessPopupDetails.addPost,
                        });
                        trackEvent(TRACKING_UPGRADE_POPUP_OPENED, { trigger_event: "add_post_to_campaign" })
                    } else {
                        handleAddPostClick(params);
                    }
                }}
            >
                + Add post
            </div>
        );
    }

    const commonHeaderProps = {
        flex: 1,
        headerClassName: "subtitle-m mui-data-grid-header hideRightSeparator",
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            hide: true,
        },
        {
            ...commonHeaderProps,
            align: "left",
            field: "name",
            headerAlign: "left",
            headerName: "Campaign",
            maxWidth: 700,
            renderCell: renderCampaignsCell,
            sortable: false,
        },
        {
            ...commonHeaderProps,
            align: "left",
            field: "brand_id",
            headerAlign: "left",
            headerName: "Brand",
            maxWidth: 180,
            minWidth: 20,
            renderCell: renderBrandsCell,
            sortable: false,
        },
        {
            ...commonHeaderProps,
            align: "right",
            field: "total_content_count",
            headerAlign: "right",
            headerClassName: `${commonHeaderProps.headerClassName} posts-header`,
            headerName: "Posts",
            maxWidth: 150,
            minWidth: 10,
            renderCell: renderTotalContentCountCell,
            sortable: false,
        },
        {
            ...commonHeaderProps,
            align: "left",
            field: "created_at",
            headerAlign: "left",
            headerName: "Created on",
            renderCell: renderCreatedOnCell,
            sortable: true,
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, "created_at")}`,
        },
        {
            ...commonHeaderProps,
            align: "right",
            field: "add_post",
            headerName: "",
            renderCell: renderAddPostCell,
            sortable: false,
        },
    ];

    function FailureMessage() {
        return (
            <Box className="campaign-error-content" style={{ marginTop: 80 }}>
                <EmptyTableScreen
                    icon={<Icons.campaign_coming_soon/>}
                    header={START_YOUR_FIRST_CAMPAIGN}
                    content={START_YOUR_FIRST_CAMPAIGN_MESSAGE}
                    actionButtonContent={
                        <>
                            <i className="ri-add-line"></i> {newCampaign}
                        </>
                    }
                    actionButtonHandler={() => navigate("/creators/campaigns/create-campaign")}
                    isActionButton={true}
                />
            </Box>
        );
    }

    function onNewCampaignClick() {
        if (isTrialMode) {
            const {
                usage: campaignsCreated,
                max_limit: campaignCreationLimit
            } = warehouse.tenantUsageQuota?.quotaInfoCreatorModule?.CAMPAIGNS_CREATED || {};
            if (isEmpty(campaignsCreated) || isEmpty(campaignCreationLimit)) {
                console.error('Trial campaign quota not defined');
                return;
            }
            if (campaignsCreated >= campaignCreationLimit) {
                setModalDetails({
                    ...modalDetails,
                    isModalVisible: true,
                    modalInfo: unlockAccessPopupDetails.newCampaign,
                });
                trackEvent(TRACKING_UPGRADE_POPUP_OPENED, { trigger_event: "new_campaign" });
                return;
            }
        }
        clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
        trackEvent(TRACKING_CAMPAIGNLIST_NEW_CAMPAIGN_CLICKED)
        navigate("/creators/campaigns/create-campaign");
    }

    function DataGridContainer() {
        return (
            <>
                <Box className="your-campaign-title">
                    <Box className="title">{yourCampaigns}</Box>
                    <Box>
                        <PrimaryButton
                            disabled={!isCreateEnabled}
                            label={
                                <>
                                    <i className="ri-add-line"></i> {newCampaign}
                                </>
                            }
                            className="new-campaign-button body-m"
                            onClick={onNewCampaignClick}
                        /> </Box>
                </Box>
                <div className={"div-campaign-grid"}>
                    {isTrialMode ? null : <TextBox
                        variant={'short'}
                        placeholder={SEARCH_CAMPAIGNS_BRANDS}
                        onEnter={setSearchText}
                        value={searchText}
                        onClear={() => setSearchText("")}
                    />}
                    <div className={"campaign-data-grid-container"}>
                        <DataGrid
                            className={"mui-data-grid"}
                            columns={columns}
                            components={{
                                Footer: (props) => {
                                    return <CustomFooter totalRows={totalRows} pageSize={PAGE_SIZE}
                                                         handlePageChange={handlePageChange} pageNumber={pageNumber}/>;
                                },
                            }}
                            disableColumnMenu
                            getRowHeight={() => ROW_HEIGHT}
                            getRowId={(row) => row.id}
                            initialState={{
                                sorting: {
                                    sortModel: defaultSortModel,
                                },
                            }}
                            loading={isLoading}
                            onPageChange={handlePageChange}
                            // onPageSizeChange={handlePageSizeChange}
                            onRowClick={handleRowClick}
                            onSortModelChange={setSortModel}
                            page={pageNumber}
                            pageSize={PAGE_SIZE}
                            pagination
                            paginationMode={"server"}
                            rowCount={totalRows}
                            // rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            rows={rows}
                            sortModel={sortModel}
                            sortingMode={"server"}
                            sortingOrder={allowedSorts}
                            sx={{
                                '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                            }}
                            localeText={{
                                noRowsLabel: (
                                    <span className={"body-m"} style={{ color: Colors.neutralsSecondaryGrey }}>
                    {NO_CAMPAIGNS_FOUND}
                  </span>
                                ),
                            }}
                        />
                        <AddPostDialog campaignName={clickedCampaignName} campaignId={clickedCampaignId}
                                       open={openDialog} handleClose={handleCloseDialog}
                                       handleConfirm={handleConfirmDialog}/>
                    </div>
                </div>
                <UnlockFullAccessPopUp
                    open={modalDetails.isModalVisible}
                    onClose={() => {
                        setModalDetails({ ...modalDetails, isModalVisible: false });
                        trackEvent(TRACKING_UPGRADE_POPUP_CLOSED, { trigger_event: modalDetails.modalInfo?.header })
                    }}
                    header={modalDetails.modalInfo.header}
                    description={modalDetails.modalInfo.description}
                    actionButtonLabel="Contact us"
                    height="264px"
                    padding="52px 52px 48px 48px"
                    isModal
                />
            </>
        );
    }

    if (initialLoad) {
        return <Skeleton variant="rounded" height={800} width={"100%"}
                         className={"loading-skeleton loading-skeleton-campaign"} animation={"pulse"}/>;
    }

    return <>{(!totalRows || isEmpty(rows)) && isEmpty(searchText) ? <FailureMessage/> : <DataGridContainer/>}</>;
}
