import React, { useState } from "react";
import SearchableDropDown from "../SearchableDropDown/SearchableDropDown";
import SliderFilter from "../SliderFilter/SliderFilter";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import { Autocomplete, Box } from "@mui/material";
import PropTypes from "prop-types";

const SearchableDropdownSlider = ({ multi, options, defaultValue, defaultSliderValue, searchPlaceholder, steps, getFilterValue, filterKey }) => {
  let initialState;
  if (multi) {
    if (defaultValue)
      initialState = defaultValue.map((searchVal, index) => {
        return {
          selected: searchVal.id,
          step: searchVal.percentage_value,
        };
      });
    else initialState = [];
  } else {
    if (defaultValue) initialState = { selected: defaultValue, step: defaultSliderValue };
    else initialState = null;
  }
  const [selectedValues, setSelectedValues] = useState(initialState);
  const [selectedValuesInDdn, setSelectedValuesInDdn] = useState([]);

  const handleChange = (event, values, slider_step) => {
    event?.stopPropagation();
    if (multi) {
      let updatedValues = [];
      // This is to check whether the value already exists, if yes then add the step from the previous value
      if (values !== undefined && slider_step === undefined) {
        setSelectedValuesInDdn(values);
        setSelectedValues((prevValues) => {
          values.forEach((item) => {
            if (prevValues.filter((prev) => prev.selected === item.value).length === 0) {
              updatedValues.push({
                selected: item.value,
                step: defaultSliderValue,
              });
            } else {
              updatedValues.push({
                selected: item.value,
                step: prevValues.filter((prev) => prev.selected === item.value)[0].step,
              });
            }
          });
          if (getFilterValue) getFilterValue(updatedValues);
          return updatedValues;
        });
      }
      // This is to check whether the value already exists, if yes then add the new step from the current value
      else if (slider_step !== undefined) {
        setSelectedValues((prevValues) => {
          prevValues.forEach((prev) => {
            if (prev.selected === values)
              updatedValues.push({
                selected: values,
                step: slider_step,
              });
            else updatedValues.push(prev);
          });
          if (getFilterValue) getFilterValue(updatedValues);
          return updatedValues;
        });
      }
    } else {
      if (values !== undefined) {
        setSelectedValues((prevVal) => {
          return {
            selected: values,
            step: defaultSliderValue,
          };
        });
        if (getFilterValue) getFilterValue({ selected_value: values?.value, percentage_value: defaultSliderValue });
      } else if (slider_step !== undefined) {
        setSelectedValues((prevVal) => {
          return {
            ...prevVal,
            step: slider_step,
          };
        });
        if (getFilterValue) getFilterValue({ selected_value: selectedValues?.selected?.value, percentage_value: slider_step });
      }
    }
  };
  const handleOnCloseSliderFilter = (name) => {
    const newValues = selectedValues.filter((item) => item.selected !== name);
    setSelectedValues(newValues);
    if (getFilterValue) getFilterValue(newValues);
  };

  return (
    <div>
      <SearchableDropDown
        defaultValue={multi ? selectedValuesInDdn : selectedValues?.selected}
        getFilterValue={(value) => {
          handleChange(undefined, value, undefined);
        }}
        options={options}
        multi={multi}
        showTags={false}
        searchPlaceholder={searchPlaceholder}
        showClose={true}
        filterKey={filterKey}
      />

      <Box
        mt={2}
        sx={{
          "& > :not(:last-child)": { marginRight: 1 },
          "& > *": { marginBottom: 4 },
        }}
      >
        {/* {multi &&
          selectedValues?.selected &&
          selectedValues?.selected.map((v, idx) => (
            <>
              <SliderFilter
                defaultSliderValue={selectedValues?.step}
                sliderFilterHeader={v}
                sx={{ marginTop: "12px" }}
                isMultipleFilter={multi}
                onCloseFilter={() => {
                  onDelete(v.name);
                }}
                key={v.name}
                steps={steps}
                onChange={(event) => {
                  // console.log(event);
                }}
                getFilterValue={(value) => {
                  handleChange(undefined, v, value);
                }}
              />
            </>
          ))}*/}
        {!multi && selectedValues?.selected && (
          <>
            <SliderFilter
              defaultSliderValue={selectedValues?.step}
              sliderFilterHeader={selectedValues?.selected}
              sx={{ marginTop: "12px" }}
              steps={steps}
              filterKey={filterKey}
              onChange={(event) => {
                // console.log(event);
              }}
              getFilterValue={(value) => {
                handleChange(undefined, undefined, value);
              }}
            />
          </>
        )}
        {multi &&
          selectedValues?.length > 0 &&
          selectedValues.map((v, idx) => (
            <>
              <SliderFilter
                defaultSliderValue={v?.step}
                sliderFilterHeader={{ name: v?.selected }}
                sx={{ marginTop: "12px" }}
                isMultipleFilter={multi}
                filterKey={filterKey}
                steps={steps}
                onCloseFilter={handleOnCloseSliderFilter}
                getFilterValue={(value) => {
                  handleChange(undefined, v.selected, value);
                }}
              />
            </>
          ))}
      </Box>

      {/* <SliderFilter /> */}
    </div>
  );
};

export default SearchableDropdownSlider;

SearchableDropdownSlider.propTypes = {
  options: PropTypes.array,
  multi: PropTypes.bool,
};
