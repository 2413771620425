import { Box, Skeleton } from "@mui/material";
import React from "react";

function SearchCardSkeleton({ searchType }) {
  return (
    <>
      <Skeleton variant="rectangular" width={140} height={30}></Skeleton>
      <Box sx={{ marginTop: "24px", marginBottom: "48px" }}>
        <Skeleton variant="rectangular" width={382} height={62} sx={{ borderRadius: "1000px" }}></Skeleton>
        <Skeleton variant="rectangular" width={382} height={62} sx={{ borderRadius: "1000px" }}></Skeleton>
        <Skeleton variant="rectangular" width={382} height={62} sx={{ borderRadius: "1000px" }}></Skeleton>
        <Skeleton variant="rectangular" width={382} height={62} sx={{ borderRadius: "1000px" }}></Skeleton>
      </Box>
      {searchType === "Search by filters" && (
        <>
          <Skeleton variant="rectangular" width={140} height={30}></Skeleton>
          <Box sx={{ marginTop: "24px", marginBottom: "48px", display: "flex", gap: "24px !important" }}>
            <Skeleton variant="rectangular" width={256} height={402} sx={{ borderRadius: "8px" }}></Skeleton>
            <Skeleton variant="rectangular" width={256} height={402} sx={{ borderRadius: "8px" }}></Skeleton>
            <Skeleton variant="rectangular" width={256} height={402} sx={{ borderRadius: "8px" }}></Skeleton>
            <Skeleton variant="rectangular" width={256} height={402} sx={{ borderRadius: "8px" }}></Skeleton>
          </Box>
        </>
      )}
    </>
  );
}

export default SearchCardSkeleton;
