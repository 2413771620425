import { Drawer } from "@mui/material";
import React from "react";
import './SideDrawer.scss';
import { SHORT_LINK, SHORT_LINK_COPIED, TAGS } from "../../../constants/constants";
import { useToast } from "../../../hooks/useToast";
import Icons from "../../Icons/Icons";
import { isEmpty } from "../../../utils/util";
import { Chip } from "../../index";

export default function SideDrawer({ open, onClose, drawerData }) {
    const toast = useToast();
    const DRAWER_WIDTH = 600;
    const {
        title,
        subtitleLink,
        metrics,
        shortLink,
        tags,
        subtitleText,
    } = drawerData;

    function Header() {
        return (
            <div className={'div-side-drawer-header'}>
                <span className={'title'}>{title}</span>
                {isEmpty(subtitleLink) ?
                    <span className="body-r subtitleText" > {subtitleText} </span> :
                    (<a className={'body-r side-drawer-header-link color-neutrals-secondary-grey'} href={subtitleLink}>
                        <span className={'span-link-text text-truncate'}>{subtitleLink}</span>
                        <i className="ri-arrow-right-up-line icon-link-arrow"></i>
                    </a>)
                }
            </div>
        );
    }

    function Body() {

        function Metric(props) {
            const { title, value } = props;
            return (
                <div className={'div-metric'} key={`${title}-${value}`}>
                    <span className={'body-r color-neutrals-secondary-grey'}>{title}</span>
                    <span className={'body-m color-neutrals-primary-grey'}>{isEmpty(value) ? '-' : value}</span>
                </div>
            );
        }

        const CircleIcon = ({ children }) => {
            return (
                <div className="circle">
                    {children}
                </div>
            );
        };

        function Tags() {
            return isEmpty(tags) ? null : (
                <div className={'div-tags-container'}>
                    <div className={'div-title-container'}>
                        <CircleIcon>{<i className="ri-hashtag"></i>}</CircleIcon>
                        <span className={'sub-section-heading'}>{TAGS}</span>
                    </div>
                    <div className={'div-tags'}>
                        {tags?.map((label, index) => {
                            return (
                                <Chip label={label} key={index} index={index} />
                            );
                        })}
                    </div>
                </div>
            );
        }

        const handleCopyAction = (copyText) => {
            navigator.clipboard.writeText(copyText);
            toast(SHORT_LINK_COPIED);
        };

        return (
            <div className={'div-side-drawer-body'}>
                <div className={'div-metrics-container'}>
                    {metrics.map(Metric)}
                </div>
                <div className={'div-short-link-container'}>
                    <div className={'div-title-container'}>
                        <CircleIcon>{<i className="ri-links-line"></i>}</CircleIcon>
                        <span className={'sub-section-heading'}>{SHORT_LINK}</span>
                    </div>

                    {isEmpty(shortLink) ? null : <div className={'short-link-url-container'}>
                        <a className={'body-m color-main-primary'} href={shortLink} target={'_blank'}>{shortLink}</a>
                        <Icons.copy_icon_transparent
                            onClick={(e) => {
                                e.stopPropagation();
                                handleCopyAction(shortLink);
                            }}
                            cursor="pointer"
                        />
                    </div>}
                </div>
                <Tags />
            </div>
        );
    }

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: DRAWER_WIDTH,
                },
            }}
        >
            <Header />
            <Body />
        </Drawer>
    );
}
