import parse from "html-react-parser";
import Icons from "../components/Icons/Icons";
import InvertedPrimaryButton from "../components/InvertedPrimaryButton/InvertedPrimaryButton";
import Typography from "../components/Typography/Typography";

const getModal = (modalObj) => {
  const Modal = {
    title: (
      <Typography h3 style={{ fontWeight: "600" }}>
        {modalObj.title}
      </Typography>
    ),
    content: <div>{parse(modalObj.content)}</div>,
    closeIcon: <Icons.close_circle_fill />,
    closable: modalObj.closable,
    icon: false,
    cancelText: null,
    okType: "text",
    okButtonProps: {
      style: { backgroundColor: "transparent", height: "65px" },
    },
    okText: <InvertedPrimaryButton label={modalObj.buttonLabel} onClick={modalObj.buttonAction ? modalObj.buttonAction : null} />,
  };

  return Modal;
};

export default getModal;
