import React, { useState, useEffect, useContext, useRef } from "react";
import { Card, Typography, PrimaryButton, Tag, Icons, CardLink, MainLayout, Loader, TableListingRecords } from "../../components/index";
import { Descriptions, Space, Table, Modal, Tooltip } from "antd";
import moment from 'moment-timezone';
import { Helmet } from "react-helmet";
import { updateWebhookPageUrl, createWebhookPageUrl } from "../../constants/urlConstants";
import { useNavigate } from "react-router-dom";
import { webhooksApi } from "../../api/index";
import "./WebhookPage.scss";
import { UserContext, WarehouseContext } from "../../storage/context";
import { ADD_WEBHOOKS } from "../../storage/reducers/types";
import useFetchWebhooks from "../../hooks/useFetchWebhooks";
import { environmentBaseInstance } from "../../api/axiosInstance";
import { trackEvent, WEBHOOK_DELETE_CANCEL, WEBHOOK_LIST_PAGE_VIEW } from "../../analytics";
import { useToast } from "../../hooks/useToast";
import { WEBHOOK_DELETED } from "../../constants/toastMessages";
import { WEBHOOK_PAGE_HEADER, getPageTitle, pageTitle } from "../../constants/constants";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    // sorter: true,
    // render: (name) => `${name.first} ${name.last}`,
    width: "30%",
  },
  {
    title: "Events",
    dataIndex: "events",
    // sorter: true,
    width: "20%",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    width: "20%",
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          return (
            <Tag color={tag === "Enabled" ? "success" : "error"} key={tag} height="28px" width="81px" fontSize="12px">
              {tag}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: "Created on",
    dataIndex: "created_on",
    // sorter: true,
    width: "30%",
  },
  {
    title: "Actions",
    dataIndex: "buttons",
    width: "15%",
  },
];

const WebhookPage = () => {
  const navigate = useNavigate();

  const { warehouse } = useContext(WarehouseContext);

  const { fetchWebhooks } = useFetchWebhooks();

  const [webhooksData, setWebhooksData] = useState([]);
  const toast = useToast();

  const tenantId = warehouse.tenantInfo[0]?.id;
  const clientId = warehouse.tenantInfo[0]?.apps[0]?.id;
  const environment = warehouse.environment.current.toUpperCase();
  const isWebhookFetched = useRef(false);

  const setEnvBaseURLAndFetchWebhooks = () => {
    if (warehouse.tenantInfo.length === 0) {
      return;
    }

    // to reduce the number of webhook calls
    if (!isWebhookFetched.current) {
      fetchWebhooks();
      isWebhookFetched.current = true;
    }
  };

  useEffect(() => {
    if (!warehouse.webhookStatus.fetchedInitially) {
      setEnvBaseURLAndFetchWebhooks();
    }
  }, [warehouse.tenantInfo, warehouse.webhookStatus.fetchedInitially]);

  useEffect(() => {
    if (warehouse.environmentChanged) {
      isWebhookFetched.current = false;
      fetchWebhooks();
    }
  }, [warehouse.environmentChanged]);

  useEffect(() => {
    setWebhooksData(warehouse.webhooks);
    if (warehouse.webhookStatus.fetchedInitially) {
      const activeWebhooksCount = warehouse.webhooks?.filter((webhook) => webhook.is_active).length;
      trackEvent(WEBHOOK_LIST_PAGE_VIEW, {
        webhook_count: warehouse.webhooks?.length,
        active_webhook_count: activeWebhooksCount,
      });
    }
  }, [warehouse.webhooks]);

  const loading = warehouse.webhookStatus.loading;

  const onClickWebhookDelete = async ({ webhookId, webhook }) => {
    const response = await webhooksApi.deleteWebhook({
      tenantId,
      clientId,
      environment,
      webhookId,
      webhook,
    });
    toast(WEBHOOK_DELETED);
    fetchWebhooks();
  };
  const onClickWebhookCreate = () => {
    navigate(createWebhookPageUrl);
  };
  const onClickWebhookEdit = ({ webhookId }) => {
    navigate(`${updateWebhookPageUrl}/${webhookId}`);
  };

  const constructTableDataFromWebhooksResponse = (webhooks) => {
    const tableData = [];
    webhooks.forEach((webhook) => {
      tableData.push({
        key: webhook.id,
        name: webhook.name || "no_name",
        url: webhook.url,
        events: webhook.events.length + " event" + (webhook.events.length === 1 ? "" : "s"),
        created_on: moment(webhook.created_at).format("MMM DD, YYYY"),
        updatedAt: webhook.updated_at,
        tags: webhook.is_active ? ["Enabled"] : ["Disabled"],
        buttons: (
          <div className="webhook-table-button">
            <button className="edit-icon icon-button" onClick={() => onClickWebhookEdit({ webhookId: webhook.id })}>
              <Tooltip title="Edit">
                <Icons.edit_icon name="edit_icon " />
              </Tooltip>
            </button>
            <button
              className="delete-icon icon-button"
              // onClick={onClickWebhookDelete}
              onClick={() => {
                Modal.warn({
                  title: "Delete webhook?",
                  className: "delete-modal-container",
                  content: `Are you sure you want to delete ${webhook.name}?`,
                  onOk: () => onClickWebhookDelete({ webhookId: webhook.id, webhook }),
                  onCancel: () => {
                    trackEvent(WEBHOOK_DELETE_CANCEL, webhook);
                  },
                  closable: true,
                  okText: "Delete",
                  closeIcon: <Icons.close_circle_fill />,
                  icon: false,
                  // okButtonProps: {
                  //   style: {
                  //     "background-color": "#AD3737",
                  //     border: "1.5px solid #852121",
                  //   },
                  // },
                });
              }}
            >
              <Tooltip title="Delete">
                <Icons.delete_icon name="delete_icon" />
              </Tooltip>
            </button>
          </div>
        ),
      });
    });
    return tableData;
  };

  return (
    <div>
      <div className="webhook-page-container">
        <Helmet>
          <title>{getPageTitle("WEBHOOK_PAGE", warehouse.environment.current)}</title>
        </Helmet>
        <div className="webhooks-content-container">
          <div className="webhook-title-row">
            <div className="title">
              {WEBHOOK_PAGE_HEADER}
            </div>
            {webhooksData?.length > 0 && <PrimaryButton label="Create webhook" onClick={onClickWebhookCreate} />}
          </div>

          {!loading && webhooksData?.length > 0 ? (
            // <div className="webhook-table-container">
            <TableListingRecords
              columns={columns}
              dataSource={constructTableDataFromWebhooksResponse(webhooksData)}
              bordered={false}
              pagination={false}
              style={{ height: "48px", width: "100%" }}
            />
          ) : // </div>
          loading === true ? (
            <Loader loading={loading} />
          ) : (
            <Card className="empty-webhook-card">
              <Typography h2 className="empty-webhook-title" style={{ marginBottom: 0 }}>
                Start listening to the API events
              </Typography>
              <p className="empty-webhook-description">
                Subscribe to changes to your user’s data through webhook events. Start by creating your first webhook.
              </p>
              <PrimaryButton label="Create webhook" onClick={onClickWebhookCreate} />
            </Card>
          )}
        </div>
        <div className="webhooks-cards-container">
          <CardLink
            title="Webhooks guide"
            description="Know all the important details about the webhooks through this comprehensive guide. "
            link="https://docs.insightiq.ai/docs/api-reference/API/webhooks-guide"
            event="contextual"
            backgroundColor="#F5F5F7"
            style={{ position: "absolute", bottom: "0", width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

// Delete webhook modal config
// const showDeleteModal = () => {};

export default WebhookPage;
