import { GETTING_STARTED_ACCOUNT_CONNECT_SUCCESS, GETTING_STARTED_STEP_3_DATA_LOAD, trackEvent } from "../analytics";
import { tenantInfoApi } from "../api";
import {
  fetchAccountConnectedDetails,
  getAccountIdFromLS,
  getProfile,
  getWorkPlatformDetails,
  renderStep3WithAccountId,
} from "../api/sdkConnect/sdkConnectApi";
import { PHYLLO_SDK_DONE_CLICKED } from "../constants/constants";
import { ADD_TENANT_INFORMATION, ADD_USER_STEPS } from "../storage/reducers/types";
import { createWorkplatformMapping, flattenObj } from "./util";

const openPhylloSDK = (appName, env, userId, token, setAccountId, setLoader, setButtonLoading, dispatchWarehouse, setCurrStep, warehouse) => {
  let accountId;
  const config = {
    clientDisplayName: appName,
    environment: env,
    userId: userId,
    token: token,
    singleAccount: true,
  };

  const phylloConnect = window.PhylloConnect.initialize(config);

  phylloConnect.on("accountConnected", async (accountId, workplatformId, userId) => {
    console.log(`onAccountConnected: ${accountId}, ${workplatformId}, ${userId}`);
    fetchAccountConnectedDetails(accountId, workplatformId, userId, warehouse, dispatchWarehouse).then((accountId) => {
      //
    });
  });

  phylloConnect.on("accountDisconnected", async (accountId, workplatformId, userId) => {
    // gives the successfully disconnected account ID and work platform ID for the given user ID
    console.log(`onAccountDisconnected: ${accountId}, ${workplatformId}, ${userId}`);
    dispatchWarehouse({ type: ADD_USER_STEPS, payload: 2 });
    const userCreds = JSON.parse(localStorage.getItem("SDK_USER_CREDS"));
    // TODO : Create separate localStorage for account-creds
    const obj = {};
    obj.authKey = userCreds.authKey;
    obj.userId = userCreds.userId;
    obj.userToken = userCreds.userToken;
    localStorage.setItem("SDK_USER_CREDS", JSON.stringify(obj));
    localStorage.removeItem("User_Response");
    setButtonLoading(false);
    setCurrStep(2);
    setAccountId();
    const data = await tenantInfoApi.getTenantInfo(env);
    dispatchWarehouse({
      type: ADD_TENANT_INFORMATION,
      payload: data,
    });
  });

  phylloConnect.on("tokenExpired", (userId) => {
    // gives the user ID for which the token has expired
    console.log(`onTokenExpired: ${userId}`); // the SDK closes automatically in case the token has expired, and you need to handle this by showing an appropriate UI and messaging to the users
    dispatchWarehouse({ type: ADD_USER_STEPS, payload: 1 });
    localStorage.removeItem("SDK_USER_CREDS");
    localStorage.removeItem("User_Response");
    setCurrStep(1);
    setButtonLoading(false);
  });

  phylloConnect.on("exit", async (reason, userId) => {
    // indicates that the user with given user ID has closed the SDK and gives an appropriate reason for it
    console.log(`onExit: ${reason}, ${userId}`);
    if (reason === PHYLLO_SDK_DONE_CLICKED) {
      setLoader(true);
      getAccountIdFromLS()
        .then((accountId) => {
          setAccountId(accountId);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setButtonLoading(false);
    }
  });

  phylloConnect.on("connectionFailure", (reason, workplatformId, userId) => {
    // optional, indicates that the user with given user ID has attempted connecting to the work platform but resulted in a failure and gives an appropriate reason for it
    console.log(`onConnectionFailure: ${reason}, ${workplatformId}, ${userId}`);
    setButtonLoading(false);
  });

  phylloConnect.open();
};

export default openPhylloSDK;
