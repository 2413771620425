import { Tooltip } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./ProfileView.scss";
import {
  disconnectAccountApi,
  getAllAccounts,
  getAudienceDemographics,
  getContent,
  getContentGroup,
  getProfileInfo,
} from "../../api/profileView/profileView";
import {
  AccountStatusBtn,
  FullScreenOverlayLoader,
  Icons,
  IntermediateLoader,
  Loader,
  Separator
} from "../../components";
import AccountAudienceData from "../../components/AccountAudienceData/AccountAudienceData";
import AccountContentData from "../../components/AccountContentData/AccountContentData";
import AccountIdentityData from "../../components/AccountIdentityData/AccountIdentityData";
import ProfileHeader from "../../components/ProfileHeader/ProfileHeader";
import { WarehouseContext } from "../../storage/context";
import { checkRemoved, latestDate } from "../../utils/profileHelpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AccountStatus, DisconnectionSource, getPageTitle } from "../../constants/constants";
import { getClientIDAndSecret, getEnvBaseURL } from "../../utils/util";

function ProfileView() {
  const [selectedPlatform, setSelectedPlatform] = useState();
  const {
    warehouse,
    warehouse: { tenantInfo, tenantStatus, environmentBaseURL, environmentChanged, environment },
    dispatchWarehouse,
  } = useContext(WarehouseContext);

  const createEnvToken = async () => {
    var { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
    return window.btoa(`${clientId}:${clientSecret}`);
  };

  const [token, setToken] = useState("");
  let { id: userId } = useParams();
  let profileState = JSON.parse(sessionStorage.getItem("creatorProfile"));
  const [accounts, setAccounts] = useState(null);
  const [identityData, setIdentityData] = useState(null);
  const [demographics, setDemoGraphics] = useState(null);
  const [content, setContent] = useState(null);
  const [limit, setLimit] = useState(10);
  const [selectedType, setIsSelectedType] = useState("All");
  const [currPage, setCurrPage] = useState(1);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [contentLoader, setContentLoader] = useState(false);
  const [lastSyncAt, SetLastSyncAt] = useState(true);
  const [contentOffset, setContentOffset] = useState(0);
  const [contentGroupOffset, setContentGroupOffset] = useState(0);
  const [pageOffSetMap, setPageOffSetMap] = useState({
    content: [0],
    contentGrp: [0],
  });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const onlyOnce = useRef(true);
  const incrementPage = () => {
    if (content) setCurrPage(currPage + 1);
  };

  const decrementPage = () => {
    if (currPage > 1) setCurrPage(currPage - 1);
  };

  async function getAccounts(id = userId) {
    try {
      const accounts = await getAllAccounts(id, token, environmentBaseURL);
      setAccounts(accounts);
    } catch (error) {
      console.error(error);
    }
  }

  async function getIdentityData(id) {
    const currIdentityData = await getProfileInfo(id, token, environmentBaseURL);
    if (currIdentityData.status === 200) {
      setIdentityData(currIdentityData.data.data[0]);
    } else {
      console.error(currIdentityData.message);
    }
  }

  async function getDemographics(id) {
    const currDemographics = await getAudienceDemographics(id, token, environmentBaseURL);
    if (currDemographics.status === 200) {
      setDemoGraphics(currDemographics.data);
    } else {
      console.error(currDemographics.message);
    }
  }

  async function getFeed(id, contentOff = 0, contentGrpOff = 0, type, fromDate, toDate) {
    let contentDataRes;
    let contentGroupDataRes;
    let items = [];
    if (type == "All" || type == undefined) {
      type = undefined;
      const p1 = getContent(id, limit, contentOff, type, token, environmentBaseURL, fromDate, toDate).then((res) => {
        contentDataRes = res;
      });
      let p2 = getContentGroup(id, limit, contentGrpOff, type, token, environmentBaseURL, fromDate, toDate).then((res) => {
        contentGroupDataRes = res;
      });
      await Promise.all([p1, p2]);
      let i = 0,
        j = 0;
      if (contentDataRes.status === 200 && contentGroupDataRes.status === 200) {
        if (contentDataRes.data.data.length + contentGroupDataRes.data.data.length <= limit) {
          items = [...contentDataRes.data.data, ...contentGroupDataRes.data.data];

          const prevCntOff = pageOffSetMap.content[currPage - 1];
          const prevCntGrpOff = pageOffSetMap.contentGrp[currPage - 1];
          setContentOffset((prev) => prevCntOff + contentDataRes.data.data.length);
          setContentGroupOffset((prev) => prevCntOff + contentGroupDataRes.data.data.length);
          setPageOffSetMap((prev) => {
            prev.content[currPage] = prevCntOff + contentDataRes.data.data.length;
            prev.contentGrp[currPage] = prevCntGrpOff + contentGroupDataRes.data.data.length;
            return prev;
          });
        } else {
          for (; i < limit && j < limit && items.length < limit; ) {
            if (contentDataRes.data.data[i]) {
              items.push(contentDataRes.data.data[i]);
              i++;
            }
            if (contentGroupDataRes.data.data[j]) {
              items.push(contentGroupDataRes.data.data[j]);
              j++;
            }
          }
          const prevCntOff = pageOffSetMap.content[currPage - 1];
          const prevCntGrpOff = pageOffSetMap.contentGrp[currPage - 1];
          setContentOffset((prev) => prevCntOff + i);
          setContentGroupOffset((prev) => prevCntGrpOff + j);
          setPageOffSetMap((prev) => {
            prev.content[currPage] = prevCntOff + i;
            prev.contentGrp[currPage] = prevCntGrpOff + j;
            return prev;
          });
        }
      }
    } else if (type == "Album" || type == "Playlist") {
      let p2 = getContentGroup(id, limit, contentGrpOff, type, token, environmentBaseURL, fromDate, toDate).then((res) => {
        contentGroupDataRes = res;
      });
      await Promise.resolve(p2);
      if (contentGroupDataRes.status === 200) {
        items = [...contentGroupDataRes.data.data];
      }
    } else {
      const p1 = getContent(id, limit, contentOff, type, token, environmentBaseURL, fromDate, toDate).then((res) => {
        contentDataRes = res;
      });
      await Promise.resolve(p1);
      if (contentDataRes.status === 200) {
        items = [...contentDataRes.data.data];
      }
    }

    if (items.length > 0) {
      setContent(() => {
        return { data: items };
      });
    } else {
      return;
    }
  }

  async function disconnectAccount(id) {
    return new Promise((resolve, reject) => {
      disconnectAccountApi(id, token, environmentBaseURL).then(resolve).then(getAccounts).catch(reject);
    });
  }

  useEffect(() => {
    (async () => {
      const token = await createEnvToken();
      setToken(token);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (token === "") return;
      if (onlyOnce.current) {
        onlyOnce.current = false;
        await getAccounts();
        setLoading(false);
      }
    })();
  }, [token]);

  useEffect(() => {
    if (!accounts || accounts.length === 0) {
      return;
    }

    // Find first non-removed account and display it
    const account = accounts?.find((acc) => !checkRemoved(acc));
    if (account) {
      const { id, work_platform, status } = account;
      setSelectedPlatform({ id, work_platform, status });
    } else {
      // Did not find any non-removed account
      navigate("creators/invitations");
    }
  }, [accounts]);

  useEffect(() => {
    (async () => {
      if (selectedPlatform && selectedPlatform.status !== AccountStatus.NOT_CONNECTED.key) {
        SetLastSyncAt(() => {
          const acct = accounts.filter((acc) => acc.id === selectedPlatform.id)[0];
          const dates = [];
          if (acct?.data?.identity?.last_sync_at) dates.push(new Date(acct?.data?.identity?.last_sync_at));
          if (acct?.data?.identity?.audience?.last_sync_at) dates.push(new Date(acct?.data?.identity?.audience?.last_sync_at));
          if (acct?.data?.engagement?.last_sync_at) dates.push(new Date(acct?.data?.engagement?.last_sync_at));

          return latestDate(dates);
        });

        setLoading(true);
        setIsSelectedType("All");
        setCurrPage(1);
        setIdentityData(null);
        setDemoGraphics(null);
        setContent(null);
        setFromDate(null);
        setToDate(null);
        const contentOffset = 0;
        const contentGrpOffset = 0;
        setContentGroupOffset(() => contentOffset);
        setContentOffset(() => contentGrpOffset);
        const p1 = getIdentityData(selectedPlatform.id);
        const p2 = getDemographics(selectedPlatform.id);
        const p3 = getFeed(selectedPlatform.id, contentOffset, contentGrpOffset, selectedType, fromDate, toDate);

        await Promise.all([p1, p2, p3]);
        setLoading(false);
      }
    })();
  }, [selectedPlatform]);

  useEffect(() => {
    (async () => {
      if (selectedPlatform) {
        setContentLoader(true);
        setCurrPage(1);
        setContent(null);
        const contentOff = 0;
        const contentGrpOff = 0;
        setContentGroupOffset(() => contentOff);
        setContentOffset(() => contentOff);
        await getFeed(selectedPlatform.id, contentOff, contentOff, selectedType, fromDate, toDate);
        setContentLoader(false);
      }
    })();
  }, [selectedType]);

  useEffect(() => {
    (async () => {
      if (selectedPlatform) {
        setContentLoader(true);
        if (selectedType === "All") {
          setContent(null);

          await getFeed(
            selectedPlatform.id,
            pageOffSetMap.content[currPage - 1],
            pageOffSetMap.contentGrp[currPage - 1],
            selectedType,
            fromDate,
            toDate
          );
        } else {
          const offset = (currPage - 1) * limit;
          setContent(null);
          await getFeed(selectedPlatform.id, offset, offset, selectedType, fromDate, toDate);
        }
        setContentLoader(false);
      }
    })();
  }, [currPage]);

  useEffect(() => {
    if (warehouse.environmentChanged) navigate("../");
  }, [warehouse.environmentChanged]);

  useEffect(() => {
    async function callApi() {
      if (!selectedPlatform) {
        return;
      }
      setCurrPage(1);
      setContentLoader(true);
      setContent(null);
      await getFeed(selectedPlatform.id, 0, 0, selectedType, fromDate, toDate);
      setContentLoader(false);
    }
    callApi().then(() => {});
  }, [fromDate, toDate, selectedPlatform]);

  function renderProfileHeader() {
    return (
      <ProfileHeader
        identityData={identityData}
        selectedPlatform={selectedPlatform}
        updateSelectedPlatform={setSelectedPlatform}
        accounts={accounts}
        email={profileState.email}
        invitedOn={profileState.invitedOn}
        inviteLink={profileState.link}
        resendId={profileState.creatorInviteId}
        name={profileState.name}
        defaultPlatform={profileState.selectedPlatform}
        onRemoveClick={disconnectAccount}
      />
    );
  }

  function ProfileBody() {
    return (
      <div className="profile-body">
        {loading && <IntermediateLoader />}
        {identityData && !loading && (
          <AccountIdentityData identityData={identityData} lastSyncAt={lastSyncAt} creatorInviteId={profileState?.creatorInviteId} />
        )}
        {demographics && Object.keys(demographics).length !== 0 && !loading && <AccountAudienceData demographics={demographics} />}
        {contentLoader && <IntermediateLoader />}
        {!contentLoader && !loading && (
          <AccountContentData
            title={"Content"}
            titleIcon={<Icons.content_icon />}
            content={content}
            selectedPlatform={selectedPlatform}
            limit={limit}
            setLimit={setLimit}
            selectedType={selectedType}
            setIsSelectedType={setIsSelectedType}
            fromDateHandler={{
              fromDate,
              onValidFromDate: (date) => {
                if (!fromDate || (date && date !== fromDate)) {
                  setFromDate(date);
                }
              },
              clearFromDate: () => setFromDate(null),
            }}
            toDateHandler={{
              toDate,
              onValidToDate: (date) => {
                if (!toDate || (date && date !== toDate)) {
                  setToDate(date);
                }
              },
              clearToDate: () => setToDate(null),
            }}
          />
        )}
        {!contentLoader && !loading && (
          <div className="pagination-icons-container">
            <Icons.left_arrow onClick={decrementPage} />
            {/* {currPage} */}
            {currPage === 1 && <Icons.right_arrow onClick={incrementPage} />}
            {currPage > 1 && content && <Icons.right_arrow onClick={incrementPage} />}
          </div>
        )}
      </div>
    );
  }

  function DisconnectedMessage() {
    return (
      <div className="disconnected-body">
        <label className="disconnected-info">This account has been disconnected. Reconnect it to see the details.</label>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{getPageTitle("CREATOR_PROFILE_PAGE")}</title>
      </Helmet>
      {!accounts && !loading && <Icons.back_arrow className="profile-view-back-btn" onClick={() => navigate("creators/invitations")} />}
      {accounts && renderProfileHeader()}
      {accounts && <Separator />}
      {selectedPlatform?.status === AccountStatus.NOT_CONNECTED.key ? <DisconnectedMessage /> : <ProfileBody />}
    </>
  );
}
export default ProfileView;
