import { Box } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    trackEvent,
    TRACKING_CAMPAIGN_ADD_TO_CAMPAIGN_CLICKED,
    TRACKING_CAMPAIGN_CONTENT_ADDED,
    TRACKING_CAMPAIGN_CREATE_CAMPAIGN_CLICKED
} from '../../analytics'
import {
    addContentToCampaign,
    createCampaign,
    deleteContentFromCampaign,
    getAllCampaignsContents
} from '../../api/CampaignTrackingAPI/CampaignTrackingAPI'
import { ChipsContainer, CsvUpload, Icons, InputField, PrimaryButton } from '../../components'
import {
    addPostCSVHeader,
    addPostPageData,
    campaignPostUrlError,
    csvUploadContent,
    defaultCampaignApiLimit,
    duplicate_campaign,
    getFormatedCampaignURL,
    getTotalPostLinks,
    somethingWentWrongMsgDesc,
    somethingWentWrongMsgTitle
} from '../../constants/campaignTrackingConstants'
import { CampaignTrackingContext, WarehouseContext } from '../../storage/context'
import { ADD_USAGE_QUOTA, SET_CAMPAIGN_FORM, SHOW_CAMPAIGN_ERROR } from '../../storage/reducers/types'
import { getValidLinks, validatePostUrl } from '../../utils/profileHelpers'
import { clearCampaignTrackingWarehouse, convertDateToUtc } from '../../utils/util'
import "./AddPostLinks.scss"
import { useFetchTenantUsageInfo } from "../../hooks/useFetchTenantUsageInfo";


const AddPostLinks = () => {
    const navigate = useNavigate()
    const { campaignId } = useParams();
    const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
    const fetchTenantUsageInformation = useFetchTenantUsageInfo();

    const [pageName, setPageName] = useState(addPostPageData.addPostLinks)
    const { dispatchCampaignTrackingWarehouse, campaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const [postUrls, setPostUrls] = useState([])
    const [csvFile, SetCSVFile] = useState(null);
    const [csvPostUrls, setCsvPostUrls] = useState([])
    const [inputPostUrls, setInputPostUrls] = useState("")
    const [prevCampaignData, setPrevCampaignData] = useState({
        contentUrls: []
    })
    const [showCampaignError, setshowCampaignError] = useState({
        hasError: false,
        errorMessage: ""
    });
    const {
        campaignForm: {
            brand: {
                value: brand_id,
                name: brand_name
            },
            name,
            cost,
            date: {
                fromDate,
                toDate,
            }
        },
    } = campaignTrackingWarehouse;
    const handleCreateCampaign = async () => {
        const urlArray = [...postUrls, ...csvPostUrls]
        const validCount = urlArray.filter(item => item.isValid).length;
        const totalCount = urlArray.length;
        trackEvent(TRACKING_CAMPAIGN_ADD_TO_CAMPAIGN_CLICKED, {
            valid_count_url: validCount,
            invalid_count_url: totalCount - validCount,
            total_url_count: totalCount
        })

        const contentUrls = [...postUrls]
            .filter(obj => obj.isValid)
            .map(obj => obj.url);
        try {

            if (campaignId) {

                if (contentUrls.length > 0) {
                    await addContentToCampaign({ warehouse, content_urls: contentUrls, campaignId });
                }

                if (csvFile) {
                    await addContentToCampaign({ warehouse, file: getValidLinks(csvPostUrls, addPostCSVHeader), campaignId });
                }

                clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
                navigate(`/creators/campaigns/campaign-view/${campaignId}`);

            } else {
                dispatchCampaignTrackingWarehouse({
                    type: SET_CAMPAIGN_FORM,
                    payload: {
                        ...campaignTrackingWarehouse.campaignForm,
                        postURLs: postUrls
                    },
                })
                const response = await createCampaign({
                    warehouse,
                    brand_id,
                    name,
                    cost,
                    start_date: convertDateToUtc({ date: fromDate }),
                    end_date: convertDateToUtc({ date: toDate, endDate: true }),
                    content_urls: contentUrls,
                    file: csvFile
                });
                if (response.id) {
                    clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
                    navigate(`/creators/campaigns/campaign-view/${response.id}`)
                    trackEvent(TRACKING_CAMPAIGN_CREATE_CAMPAIGN_CLICKED, {
                        name,
                        brand_name,
                        start_date: convertDateToUtc({ date: fromDate }),
                        end_date: convertDateToUtc({ date: toDate, endDate: true }),
                        campaign_cost: cost,
                        list_of_content: contentUrls
                    });
                    fetchTenantUsageInformation().then((usageInfo) => {
                        dispatchWarehouse({ type: ADD_USAGE_QUOTA, payload: usageInfo });
                    });
                } else if (response.error) {
                    if (response.error?.code === duplicate_campaign) {
                        dispatchCampaignTrackingWarehouse({
                            type: SHOW_CAMPAIGN_ERROR,
                            payload: duplicate_campaign,
                        })
                    }
                    navigate(`/creators/campaigns/create-campaign`)
                }
            }
        } catch (error) {
            if (error.status_code === 500) {
                setshowCampaignError({
                    hasError: true,
                    errorMessage: {
                        icon: <Icons.something_went_wrong/>,
                        title: somethingWentWrongMsgTitle,
                        desc: somethingWentWrongMsgDesc
                    }
                })
            }
        }
    }
    const getCampaignFormData = async () => {
        try {
            // get content urls
            let contentUrls = await getAllCampaignsContents({ warehouse, campaignId })
            if (contentUrls.total_count > defaultCampaignApiLimit) {
                contentUrls = await getAllCampaignsContents({ warehouse, campaignId, limit: contentUrls.total_count })
            }
            contentUrls?.data?.map((content) => {
                const updatedUrls = addPostUrls(content.url)
                // const obj = [{ ...updatedUrls, contentId: content.id }]
                updatedUrls[0].contentId = content.id
                setPrevCampaignData((prevState) => ({
                    ...prevState,
                    contentUrls: [...prevState.contentUrls, ...updatedUrls],
                }));
            })

        } catch (error) {
            throw error;
        }
    }

    const getRemovedAndAddedURLs = (prevData, updatedData) => {
        const prevURLs = prevData.map((obj) => obj.url);
        const updatedURLs = updatedData
            .filter((obj) => obj.isValid)
            .map((obj) => obj.url);

        const removedContentIds = prevData
            .filter((obj) => !updatedURLs.includes(obj.url))
            .map((obj) => obj.contentId);

        const addedURLs = updatedURLs.filter((url) => !prevURLs.includes(url));
        return {
            removedContentIds,
            addedURLs
        };
    };

    const handleUpdateCampaign = async () => {
        try {
            let { removedContentIds, addedURLs } = getRemovedAndAddedURLs(prevCampaignData.contentUrls, postUrls)


            if (removedContentIds.length > 0) {
                await deleteContentFromCampaign({ warehouse, ids: removedContentIds, campaignId });
            }

            if (addedURLs.length > 0) {
                await addContentToCampaign({ warehouse, content_urls: addedURLs, campaignId });
            }

            if (csvFile) {
                await addContentToCampaign({ warehouse, file : getValidLinks(csvPostUrls,addPostCSVHeader ), campaignId });
            }
            navigate(`/creators/campaigns/campaign-view/${campaignId}`)

        } catch (error) {
            if (error.status_code === 500) {
                setshowCampaignError({
                    hasError: true,
                    errorMessage: {
                        icon: <Icons.something_went_wrong/>,
                        title: somethingWentWrongMsgTitle,
                        desc: somethingWentWrongMsgDesc
                    }
                })
            }
        }
    }

    useEffect(() => {
        let pathArray = window.location.pathname.split("/")
        if ((pathArray[3] === "add-post-links")) {
            setPageName(addPostPageData.addPostLinks)
            if (campaignTrackingWarehouse.campaignForm.postURLs.length > 0) {
                setPostUrls(campaignTrackingWarehouse.campaignForm.postURLs)
            }
        } else if ((pathArray[3] === "edit-post-links")) {
            setPageName(addPostPageData.editPostLinks)
            getCampaignFormData()
        } else {
            navigate("/creators/campaigns");
        }
    }, [window.location])

    const isButtonDisable = () => {
        if (postUrls.length === 0 && csvPostUrls.length === 0) return true;
        const postUrlsIsValid = postUrls.some(({ isValid }) => isValid);
        const csvPostUrlsIsValid = csvPostUrls.some(({ isValid }) => isValid);

        return !(postUrlsIsValid || csvPostUrlsIsValid );
    };

    const addPostUrls = (postUrls) => {
        const urls = postUrls.split(',');
        const urlObjects = [];

        urls.forEach(url => {
            if (url.length === 0) return;
            let trimmedURL = url.trim();
            trimmedURL = getFormatedCampaignURL(trimmedURL)

            // Check if the URL already exists in urlObjects
            if (urlObjects.some(obj => obj.url === trimmedURL)) return;
            // Validate the URL
            const isValid = validatePostUrl(trimmedURL);

            // Create the URL object
            const urlObject = {
                url: trimmedURL,
                isValid: isValid
            };

            urlObjects.push(urlObject);
        });
        setPostUrls(prev => {
            // Get the URLs from the previous postUrls
            const prevURLs = prev.map(obj => obj.url);
            // remove dublicates
            const uniqueURLObjects = urlObjects.filter(obj => !prevURLs.includes(obj.url));
            const uniqueUrls = [...prev, ...uniqueURLObjects];

            // take invalid and valid url to move invalid to top
            const invalidURLObjects = uniqueUrls.filter(obj => !obj.isValid);
            const validURLObjects = uniqueUrls.filter(obj => obj.isValid);
            return [...invalidURLObjects, ...validURLObjects];
        });
        setInputPostUrls("")
        return urlObjects;
    };
    const handleEnterKeyPress = (event) => {
        if (event.key === "Enter") {
            inputPostUrls && inputPostUrls.length > 0 && addPostUrls(inputPostUrls);
        }
    };


    const onDelete = (name) => {
        const updatedPostURLS = postUrls.filter((urlObj) => urlObj.url !== name.url);
        setPostUrls(updatedPostURLS);
    };

    function InputPostURlsContainer() {
        return (
            <ChipsContainer
                onClearAllClick={() => setPostUrls([])}
                totalCountMessage={getTotalPostLinks(postUrls).msg}
                totalCountClass={getTotalPostLinks(postUrls).invalidUrls > 0 ? "errorUrls" : ''}
                items={postUrls.map((item) => ({ ...item, label: item.url }))}
                getItemDetails={(item) => ({
                    tooltipTitle: !item.isValid ? campaignPostUrlError : '',
                    rootClass: !item.isValid ? "url-chip-error" : '',
                    label: <>{!item.isValid ? <Icons.caution_red/> : null} {item.label}</>,
                })}
                onItemDelete={onDelete}
            />
        );
    }

    return (
        <Box className='add-post-page-container'>
            <Box>
                <i className="ri-arrow-left-line back-icon" onClick={() => {
                    navigate(-1);
                }}></i>
            </Box>
            <Box className='post-url-form'>
                <Box className='campaign-page-title'>
                    <Icons.links_icon/>
                    <div className='title'>
                        <Box className='section-heading addPost-title'>{pageName.addPost?.title}</Box>
                        <Box className='body-r addPost-desc'>{pageName.addPost?.desc}</Box>
                    </div>
                </Box>

                <Box className="campaign-input-button-container">
                    <InputField
                        placeholder={addPostPageData.postInputPlaceholder}
                        onChange={(e) => {
                            setInputPostUrls(e.target.value.trim())
                        }}
                        autoFocus
                        // allowClear
                        onKeyDown={handleEnterKeyPress}
                        value={inputPostUrls}
                    />
                    <PrimaryButton disabled={inputPostUrls.length === 0} onClick={() => {
                        trackEvent(TRACKING_CAMPAIGN_CONTENT_ADDED, { content_url: inputPostUrls })
                        addPostUrls(inputPostUrls)
                    }} label={addPostPageData.addPostButtonCTA} className="analyse-button body-m"/>
                </Box>
                {postUrls?.length > 0 &&
                    <InputPostURlsContainer/>
                }
                <Box className='postUrl-csv-upload'>
                    <Box className='body-m'> {csvUploadContent.title}</Box>
                    <CsvUpload csvUrls={csvPostUrls} setCsvUrls={setCsvPostUrls} csvFile={csvFile}
                               SetCSVFile={SetCSVFile} fileHeader={addPostCSVHeader} showTooltip={true}/>
                </Box>
            </Box>
            {/* save campaign */}
            <Box className='save-changes-container'>
                <PrimaryButton
                    label={pageName.buttonCTA}
                    className="body-m"
                    disabled={isButtonDisable()}
                    onClick={() => pageName === addPostPageData.editPostLinks ? handleUpdateCampaign() : handleCreateCampaign()}/>
            </Box>
        </Box>
    )
}

export default AddPostLinks