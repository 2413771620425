import { Tooltip } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { resendInvitation } from "../../api/creatorinvitations/creatorinvitations";
import { useNavigate } from "react-router-dom";
import { getAllAccounts } from "../../api/profileView/profileView";
import { useToast } from "../../hooks/useToast";
import AccountStatusBtn from "../AccountStatusBtn/AccountStatusBtn";
import Icons from "../Icons/Icons";
import "./ProfileHeader.scss";
import { Modal } from "antd";
import { COPY_INVITE_LINK, PROFILE_PAGE_VIEW, RESEND_INVITE_LINK, trackEvent } from "../../analytics";
import { WarehouseContext } from "../../storage/context";
import { Box } from "@mui/system";
import InvertedPrimaryButton from "../InvertedPrimaryButton/InvertedPrimaryButton";
import ConnectionAttemptsPopup from "../ConnectionAttemptsPopup/ConnectionAttemptsPopup";
import { DATE_TIME_FORMAT } from "../../constants/constants";
import moment from 'moment-timezone';

/**
 * Renders the profile header.
 * @param selectedPlatform - The selected platform.
 * @param updateSelectedPlatform - A function to update the selected platform.
 * @param accounts - The list of accounts.
 * @param email - The email of the user.
 * @param invitedOn - The date the user was invited.
 * @param inviteLink - The invite link.
 * @param resendId - The resend id.
 * @param name - The name of the user.
 * @param defaultPlatform - The default platform.
 * @param onRemoveClick
 * @returns The profile header.
 */
function ProfileHeader({
  identityData,
  selectedPlatform,
  updateSelectedPlatform,
  accounts,
  email,
  invitedOn,
  inviteLink,
  resendId,
  name,
  defaultPlatform,
  onRemoveClick,
}) {
  const toast = useToast();
  const navigate = useNavigate();
  const onlyOnce = useRef(true);
  const [isModelVisible, setIsModelVisible] = useState(false);
  const { warehouse } = useContext(WarehouseContext);
  useEffect(() => {
    if (!selectedPlatform && onlyOnce.current) {
      onlyOnce.current = false;
      trackEvent(PROFILE_PAGE_VIEW, {
        accounts: accounts.map((acc) => {
          return {
            connected_account_id: acc?.id,
            work_platform_name: acc?.work_platform?.name,
            work_platform_id: acc?.work_platform?.id,
            status: acc?.status,
          };
        }),
      });
      if (defaultPlatform) {
        const selectedAcc = accounts.find((acc) => acc.id == defaultPlatform);
        updateSelectedPlatform({
          id: selectedAcc.id,
          work_platform: selectedAcc.work_platform,
          status: selectedAcc.status,
        });
      } else {
        updateSelectedPlatform({
          id: accounts[0].id,
          work_platform: accounts[0].work_platform,
          status: accounts[0].status,
        });
      }
    }
  }, []);

  useEffect(() => {
    const container = document.getElementsByClassName("profile-header-body")[0];
    let isDragging = false;
    let lastX = 0;
    container.style.overflowX = "hidden";
    // Adds an event listener to the container that listens for a mousedown event.
    container.addEventListener("mousedown", (event) => {
      isDragging = true;
      lastX = event.clientX;
      container.style.cursor = "grabbing";
      // show the scroll when scrolling starts
      container.style.overflowX = "auto";
    });

    container.addEventListener("mousemove", (event) => {
      if (isDragging) {
        const deltaX = event.clientX - lastX;
        container.scrollLeft -= deltaX;
        lastX = event.clientX;
      }
    });

    // Adds a scroll listener to the accounts that scrolls the container sideways.
    container.addEventListener("mouseup", () => {
      isDragging = false;
      container.style.cursor = "grab";
      // hide the scroll when scrolling stops
      container.style.overflowX = "hidden";
    });

    container.addEventListener("mouseleave", () => {
      isDragging = false;
      container.style.cursor = "grab";
      // hide the scroll when scrolling stops
      container.style.overflowX = "hidden";
    });

    const handleScroll = (e) => {
      // Determines if the user is scrolling horizontally or vertically.
      if (Math.abs(e.deltaY) < Math.abs(e.deltaX)) {
        return;
      }
      e.preventDefault();
      container.style.overflowX = "auto";
      // Scrolls the container to the left or right depending on the direction of the mouse wheel.
      if (e.deltaY > 0) {
        container.scrollLeft += 10;
        e.preventDefault();
      } else {
        container.scrollLeft -= 10;
        e.preventDefault();
      }
    };

    container.addEventListener("wheel", handleScroll);
    return () => {
      container.removeEventListener("wheel", handleScroll);
    };
  }, []);

  return (
    <div className="profile-header">
      {isModelVisible && <ConnectionAttemptsPopup open={isModelVisible} name={name} mail={email} onClose={() => setIsModelVisible(false)} />}
      <div className="profile-header-title">
        <Icons.back_arrow className="profile-icon-btns" onClick={() => navigate("creators/invitations")} />
        <label className="profile-user-name">{name}</label>
        <label className="profile-user-email">{email}</label>
        <div className="profile-right-container">
          <label className="profile-user-invited-date">Invited on {moment(invitedOn).format(DATE_TIME_FORMAT)}</label>
          <Tooltip
            title="Copy invite link"
            placement="top"
            arrow
            classes={{
              tooltip: "profiles-icon-tooltip",
              tooltipArrow: "profiles-icon-tooltip-arrow",
            }}
          >
            <Icons.link_icon
              className="profile-icon-btns"
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(inviteLink);
                toast("Invitation link copied");
                trackEvent(COPY_INVITE_LINK, {
                  invite_id: resendId,
                  path: "profile_view",
                });
              }}
            />
          </Tooltip>
          <Tooltip
            title="Resend invitation"
            placement="top"
            arrow
            classes={{
              tooltip: "profiles-icon-tooltip",
              tooltipArrow: "profiles-icon-tooltip-arrow",
            }}
          >
            <Icons.share_icon
              name="delete_icon"
              className="profile-icon-btns"
              onClick={() => {
                Modal.warn({
                  title: "Resend invitation?",
                  className: "resend-modal-container",
                  content: `Are you sure you want to resend the invite to ${name} (${email})?`,
                  onOk: async () => {
                    const response = await resendInvitation(resendId, warehouse.environment.current);
                    if (response.status === 200) {
                      toast("Invitation resent successfully");
                      trackEvent(RESEND_INVITE_LINK, {
                        invite_id: resendId,
                        path: "profile_view",
                      });
                    }
                  },
                  onCancel: () => {},
                  closable: true,
                  okText: "Resend invite",
                  closeIcon: <Icons.close_circle_fill />,
                  icon: false,
                });
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div className="profile-header-container">
        <div className="profile-header-body">
          {accounts &&
            accounts.map((acc, index) => {
              return (
                <AccountStatusBtn
                  identityData={identityData}
                  key={acc.id}
                  label={acc.work_platform.name}
                  icon={acc.work_platform.logo_url}
                  status={acc.status}
                  active={acc.id == selectedPlatform?.id ? true : false}
                  click={(ac = acc) => {
                    updateSelectedPlatform({
                      id: ac.id,
                      work_platform: ac.work_platform,
                      status: ac.status,
                    });
                  }}
                  onRemoveClick={async () => await onRemoveClick(acc.id)}
                  disconnectionSource={acc.disconnection_source}
                  platformUserName={acc.platform_username}
                  userProfilePicture={acc.profile_pic_url}
                />
              );
            })}
        </div>
        <div className="profile-header-conn-attempt-container">
          <button className="profile-header-conn-attempt-btn" onClick={() => setIsModelVisible(true)}>
            <>
              <Icons.connection_attempt_timer />
              Connection attempts
            </>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;
