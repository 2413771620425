import { Box } from '@mui/material';
import React from 'react'
import InputField from '../InputField/InputField';
import "./CostInput.scss";

const CostInput = ({placeholder,currency,...otherParams}) => {
  return (
    <Box className={"cost-input-container"} >
      <Box className={"currency-box body-m"} >
        {currency}
      </Box>
      <InputField
        placeholder={placeholder}
        {...otherParams}

      />
    </Box>
  )
}

export default CostInput