import React, { useEffect, useState } from "react";
import "./UserRow.scss";
import { Icons } from "../index";
import { Skeleton } from "@mui/material";
import { WORKPLATFORM_ID_MAPPING } from "../../constants/constants";
import { isImageExist } from "../../utils/profileHelpers";
import { Box } from "@material-ui/core";

function UserRow({ thumbnail_url, username, name, is_verified, work_platform_id }) {
    const [thumbnail, setThumbnail] = useState(null);

    useEffect(() => {
        if (!thumbnail_url) {
            setThumbnail(null);
            return;
        }

        isImageExist(thumbnail_url)
            .then(() => {
                setThumbnail(thumbnail_url);
            })
            .catch(() => {
                setThumbnail(null);
            });
    }, [thumbnail_url]);

    return (
        <div className="user-row">
            {/* Thumbnail */}
            <div className="thumbnail">
                {thumbnail ? <img src={thumbnail} className="thumbnail_image" alt="Thumbnail"/> :
                    <Icons.creator_placeholder/>}
                {work_platform_id ? <Box className="user_platform">
                    <img width={24} height={24} src={WORKPLATFORM_ID_MAPPING[work_platform_id]?.platformLogo} alt={''}/>
                </Box> : null}
            </div>
            {/* User Info */}
            <div className="user-info">
                <span className={'span-user-row-username'}>
                    <span className="body-b">{username}</span>
                    {is_verified ? <span><Icons.verified_logo/></span> : null}
                </span>
                <div className="subtitle-r span-user-row-name">
                    {name}
                </div>
            </div>
        </div>
    );
}

export function UserRowSkeleton() {
    return (
        <div className="user-row">
            <Skeleton variant="circular" width={72} height={72}/>
            <div className="user-info">
                <Skeleton variant="rounded" width={210} height={16}/>
                <Skeleton variant="rounded" width={210} height={16}/>
            </div>
        </div>
    )
}

export default UserRow;
