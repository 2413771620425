import React from 'react';
import { Tooltip } from '@material-ui/core';
import { SEARCH_ATTRIBUTES_TOOLTIP_TEXT } from '../../constants/constants';
import './CreatorBioDetails.scss';

function CreatorBioDetails({ creatorBioItems, variant }) {
  return (
    // TODO: Need to handle empty bio state need here
    creatorBioItems?.length > 0 && (
      <div className='contact-about-container' id={variant === 'vertical' ? 'contact-vertical-alignment' : ''}>
        {creatorBioItems.map(
          (bio, index) =>
            bio?.value && (
              <div key={index} className='contact-about-item-container'>
                {bio?.icon && <div className='contact-icon-container'>{bio?.icon}</div>}
                <div className='contact-text-container'>
                  <div className='contact-text-container-label-container'>
                    <div className='contact-text-container-label'>{bio?.header}</div>
                    {bio?.showToolTip && (
                      <Tooltip title={SEARCH_ATTRIBUTES_TOOLTIP_TEXT.location} placement='top-start'>
                        <div>
                          <i className='ri-information-line'></i>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <div className='contact-text-container-value'>{bio?.value}</div>
                </div>
              </div>
            )
        )}
      </div>
    )
  );
}

export default CreatorBioDetails;
