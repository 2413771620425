import { Box, Skeleton } from "@mui/material";
import React from "react";
import "./TableSearchSkeleton.scss";

function TableSearchSkeleton() {
  return (
    <div>
      {[1, 2, 3, 4, 5].map(() => {
        return (
          <Box className="table-row-skeleton">
            <Skeleton animation="wave" variant="text" width={30} height={40} />
            <Skeleton animation="wave" variant="circular" width={72} height={72} />
            <Skeleton animation="wave" variant="text" width={"100%"} height={80} />
          </Box>
        );
      })}
    </div>
  );
}

export default TableSearchSkeleton;
