import { Chart, PieSeries, Title } from "@devexpress/dx-react-chart-material-ui";
import { Palette } from "@devexpress/dx-react-chart";
import { Box, Skeleton } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import "./GenderDistribution.scss";
import Icons from "../Icons/Icons";
import { Tooltip } from "antd";

// color scheme
const scheme = ["#f9b90b", "var(--main-primary)", "#ff7d50"];

function GenderDistribution({ loading, title, data, tooltip }) {
  if (loading) {
    return <SkeletonComp />;
  } else
    return (
      <Box className="gender-distribution-container">
        <Box className="header">
          <label className="title">{title}</label>

          <Tooltip title={tooltip} placement="top">
            <Icons.info_icon style={{ cursor: "pointer" }} />
          </Tooltip>
        </Box>
        <Box className="chart-container">
          <Chart data={data}>
            <Palette scheme={scheme} />
            <PieSeries valueField="value" argumentField="argument" innerRadius={0.85} />
          </Chart>
        </Box>
        {data.map((gender, index) => (
          <Box key={index} className="row">
            <Box>
              <span
                className="gender-circle"
                style={{
                  backgroundColor: `${gender.color}`,
                }}
              ></span>
            </Box>
            <Box className="name"> {gender.argument}</Box>
            <Box className="dotted-line"></Box>
            <Box className="value">
              {Number(gender.value).toFixed(1)}
              <p style={{ display: "inline", fontSize: "13px" }}>%</p>
            </Box>
          </Box>
        ))}
      </Box>
    );
}

export default GenderDistribution;

GenderDistribution.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.array,
  tooltip: PropTypes.string,
};

function SkeletonComp() {
  return (
    <Box className="gender-distribution-container">
      <Box className="header">
        <Skeleton animation="wave" width={"40%"} height={14} sx={{ marginBottom: "16px" }} />
        <Skeleton animation="wave" variant="circular" width={16} height={16} />
      </Box>
      <Box className="chart-container">
        <Skeleton animation="wave" variant="circular" width={96} height={96} />
      </Box>
      {[1, 2, 3].map((gender, index) => (
        <Box className="row">
          <Box>
            <Skeleton animation="wave" variant="circular" width={16} height={16} />
          </Box>
          <Skeleton animation="wave" variant="text" width={"90%"} height={16} sx={{ marginLeft: "12px" }} />
        </Box>
      ))}
    </Box>
  );
}
