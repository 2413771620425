import { Alert, AppBar, Box, Button, Grid, IconButton, Toolbar, Skeleton } from "@mui/material";
import { TryCatch } from "@sentry/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  COMMENT_ANALYSIS_CLICKED,
  SEARCH_CONTENT_VIEWED,
  SEARCH_OTHERS_PROFILES_CLICKED,
  SEARCH_PROFILE_LINK_COPIED,
  trackEvent,
} from "../../analytics";
import { creatorDiscoveryAPI } from "../../api";
import { exportProfileAnalytics, postBrandSafety } from "../../api/creatorDiscoveryAPI/creatorDiscoveryAPI";
import {
  AlertInfo,
  ContentItem,
  CountryDistribution,
  DropdownIconMenu,
  DonutChart,
  DonutChartLegend,
  EmptyTableScreen,
  GenderDistribution,
  Icons,
  LinearDistribution,
  LineChartDistribution,
  LookAlikeCard,
  PrimaryButton,
  SearchProfileHeader,
  SearchProfileIdentity,
  TagsCard,
  Typography,
  UnlockFullAccessPopUp,
  BarChartDistribution,
  ExportPDFPopUp,
  TabSwitch,
  Loader,
  CreateListPopUp,
  CreatorProfileSectionBar,
  Banner,
  SupportEmail,
  GenericPopup,
} from "../../components";
import {
  DATE_FORMAT,
  LINE,
  PROFILE_ANALYTICS_ERROR_MAPPING,
  SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING,
  SEARCH_ATTRIBUTES_TOOLTIP_TEXT,
  TRIAL,
  UPDATING_CREATORS_DETAIL_ERROR,
  WORKPLATFORM_ID_MAPPING,
  creatorSearchProductID,
  brandSafetyProductID,
  getPageTitle,
  WORKPLATFORM_IDS,
  SEARCH_IDENTITY_WORKPLATFORMS_MAPPING,
} from "../../constants/constants";
import { CreatorDiscoveryWarehouseContext, WarehouseContext } from "../../storage/context";
import "./CreatorDiscoveryProfilePage.scss";
import { Helmet } from "react-helmet";
import { convertTimeToLocale, convertToShortNumber, convertToShortNumberChart, snakeCaseToSentenceCase } from "../../utils/util";
import { useLocation, useNavigate } from "react-router-dom";
import { HIDE_TOP_HEADER, SHOW_TOP_HEADER } from "../../storage/reducers/types";
import {
  PROFILE_ANALYTICS_TOP_HEADER_INFO,
  creatorBioHeaders,
  creatorBioIconMapping,
  creatorBioSectionItems,
  creatorBioType,
  creatorContactType,
  profileSectionsLabel,
  getGenderDetails,
  getFollowerTypes,
  constructValueForCreatorBio,
  getCredibilityScorePercentage,
  getLargestValueInFollowerTypes,
} from "../../utils/profileHelpers";
import { Colors } from "../../styles/colors";
import { useToast } from "../../hooks/useToast";
import {
  AGE,
  BRAND_SAFETY_BANNER_DESCRIPTION_DOWNLOAD,
  BRAND_SAFETY_BANNER_TITLE_DOWNLOAD,
  BRAND_SAFETY_BANNER_TITLE_TRIAL,
  BRAND_SAFETY_UNLOCK_MODAL_DESCRIPTION,
  BRAND_SAFETY_BANNER_REQUEST_TITLE,
  BRAND_SAFETY_BANNER_REQUEST_DESCRIPTION,
  BRAND_SAFETY_UNLOCK_MODAL_TITLE,
  BRAND_SAFETY_BANNER_REPORT_GENERATING_TITLE,
  BRAND_SAFETY_BANNER_REPORT_GENERATING_DESCRIPTION,
  UPGRADE,
  CITIES,
  CITIES_HEADER,
  CONTACT_TYPE_ICONS_MAPPING,
  COUNTRIES,
  COUNTRIES_HEADER,
  CREATE_LIST_POPUP_ACTIONS_LABEL,
  CREATE_LIST_POPUP_HEADER,
  ETHNICITY,
  EXPORT_FAILURE_DESC,
  EXPORT_FAILURE_HEADER,
  LANGUAGE,
  SPONSORED_CONTENT,
  TOP_CONTENT,
  TRY_AGAIN,
  audienceDemographicsHeaders,
  audienceDemographicsTabs,
  audienceLocationTabs,
  contentTypeTabs,
  GENERATING_LINK,
  NOT_REQUESTED,
  BRAND_SAFETY_REQUEST,
  CHECK_SAMPLE_REPORT,
  BRAND_SAFETY_SUCCESS_BUTTON_CTA,
  FAILURE_MESSAGE,
  FAILURE,
  GENERATING_TITLE,
  unlockPopupDetails,
} from "../../constants/creatorDiscovery";
import { Tooltip } from "@material-ui/core";
import CreatorDiscoveryCommentAnalysis from "./CreatorDiscoveryCommentAnalysis";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { IN_PROGRESS, unlockAccessPopupDetails } from "../../constants/campaignTrackingConstants";
import { checkProductsEnabledForTenant } from "../../api/tenantInformation/tenantInformation";
import { ElectricalServices } from "@mui/icons-material";

function CreatorDiscoveryProfilePage() {
  const topContentScrollContainer = useRef();
  const recentContentScrollContainer = useRef();
  const onlyOnce = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [profileAnalytics, setProfileAnalytics] = useState(null);
  const [errorReason, setErrorReason] = useState(null);
  const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
  const [exportButtonLoading, setExportButtonLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBrandSafetyLoading, setIsBrandSafetyLoading] = useState(false);
  const [render, setRender] = useState(true);
  const [contactMenuItems, setContactMenuItems] = useState([]);
  const [exportPDFMenuItems, setExportPDFMenuItems] = useState([]);
  const [workPlatformsLogos, setWorkPlatformsLogos] = useState([]);
  const [creatorBioItems, setCreatorBioItems] = useState([]);
  const [profileSections, setProfileSections] = useState([]);
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const [workplatformId, setWorkplatformId] = useState();
  const [showBrandSafety, setShowBrandSafety] = useState(true);
  const [brandSafety, setBrandSafety] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const [contactOptionsDropdownAnchorEl, setContactOptionsDropdownAnchorEl] = React.useState(null);
  const [exportPDFDropdownOptionsAnchorEl, setExportPDFDropdownOptionsAnchorEl] = React.useState(null);
  const isContactOptionsDropdownOpen = Boolean(contactOptionsDropdownAnchorEl);
  const isExportPDFDropdownOptionsOpen = Boolean(exportPDFDropdownOptionsAnchorEl);
  const [isSticky, setIsSticky] = useState(false);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [showAppBar, setShowAppBar] = useState(false);
  const [activeSectionTab, setActiveSectionTab] = useState(null);
  const [majorityFollowerType, setMajorityFollowerType] = useState({});
  const [credibilityScore, setCredibilityScore] = useState();
  const [isExportPDFModalOpen, setIsExportPDFModalOpen] = useState(false);
  const performanceSectionRef = useRef(null);
  const growthSectionRef = useRef(null);
  const audienceSectionRef = useRef(null);
  const similarCreatorsSectionRef = useRef(null);
  const followerTypesSectionRef = useRef(null);
  const commentAnalysisRef = useRef(null);
  const toast = useToast();
  const [contentTypeAllTabs, setContentTypeAllTabs] = useState([]);
  const [contentTypeCurrTab, setContentTypeCurrTab] = useState(null);
  const [audienceDemographicsAllTabs, setAudienceDemographicsAllTabs] = useState([]);
  const [audienceLocationAllTabs, setAudienceLocationAllTabs] = useState([]);
  const [audienceDemographicsCurrTab, setAudienceDemographicsCurrTab] = useState(null);
  const [audienceLocationCurrtab, setAudienceLocationCurrtab] = useState(null);
  const [exportPDFLink, setexportPDFLink] = useState("");
  const [isPDFLoaded, setIsPDFLoaded] = useState(false);
  const [sectionRefs, setSectionRefs] = useState([]);
  const location = useLocation();
  const { chartContainerRef: genderAgeChartContainerRef, isVisible: isGenderAgeChartContainerVisible } = useIntersectionObserver(0);
  const { chartContainerRef: followerTypeChartContainerRef, isVisible: isFollowerTypeChartContainerVisible } = useIntersectionObserver(0);
  const isCreatorSearchTrialMode = warehouse.tenantAppProducts[creatorSearchProductID] === TRIAL;
  const isBrandSafetyTrialMode = warehouse.tenantAppProducts[brandSafetyProductID] === TRIAL;
  const [isBrandSafetyModalVisible, setIsBrandSafetyModalVisible] = useState(false);
  const [isBrandSafetyFailure, setIsBrandSafetyFailure] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [createListPopUpState, setCreateListPopUpState] = useState({
    visible: false,
    isVerified: false,
    full_name: "",
    image_url: "",
    isCustomListCreated: false,
    favoriteListCount: 0,
    customList: [],
    platform_username: "",
    profile_url: "",
    workplatform_id: "",
    defaultSelectedList: [],
    isMultipleCreatorSelected: false,
    multipleSelectedCreators: [],
    isCreatorAlreadyPresentInList: false,
  });
  const [modalDetails, setModalDetails] = useState({
    isModalVisible: false,
    modalInfo: "",
  });
  const { creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse } = useContext(CreatorDiscoveryWarehouseContext);
  const [showBasicDetails, setShowBasicDetails] = useState({ show: false, selectedCreatorDetails: {} });

  const handleContactOptionsDropDownClick = (event) => {
    setContactOptionsDropdownAnchorEl(event.currentTarget);
  };
  
  const handleExportPDFDropDownClick = (event) => {
    setExportPDFDropdownOptionsAnchorEl(event.currentTarget);
  };

  const handleContactOptionsDropDownClose = () => {
    setContactOptionsDropdownAnchorEl(null);
  };

  const handleExportPDFDropDownClose = () => {
    setExportPDFDropdownOptionsAnchorEl(null);
  };

  const handleHover = (key) => {
    setIsHovered(key);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(".scrolling-page-content");
      const scrollPositionVertical = element.scrollTop;
      let currentSectionIndex = 0;
      let minDistance = Infinity;

      sectionRefs.forEach((sectionRef, index) => {
        if (sectionRef.current) {
          const distance = Math.abs(sectionRef.current.getBoundingClientRect().top);
          if (distance < minDistance) {
            minDistance = distance;
            currentSectionIndex = index;
          }
        }
      });

      setActiveSectionIndex(currentSectionIndex);

      if (scrollPositionVertical > 200) {
        setShowAppBar(true);
      } else {
        setShowAppBar(false);
      }
      if (scrollPositionVertical > 375) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    const element = document.querySelector(".scrolling-page-content");
    element?.addEventListener("scroll", handleScroll);

    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, [sectionRefs]);

  const handleSectionClick = (sectionLabel, idx) => {
    if (typeof sectionLabel === "object") {
      trackEvent(COMMENT_ANALYSIS_CLICKED);
    }
    let sectionObjKey = Object.keys(profileSectionsLabel).find((key) => profileSectionsLabel[key].label === sectionLabel);
    let elementOfIDToScroll = profileSectionsLabel[sectionObjKey].sectionID;
    const element = document.getElementById(elementOfIDToScroll);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setActiveSectionTab(idx);
  };

  const handleAutomaticScrollOfFollowerType = () => {
    const { current } = followerTypesSectionRef;
    if (current) {
      followerTypesSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  function getAgeDetails({ data }) {
    let ageDetails = [
      { name: "13 - 17", value: 0 },
      { name: "18 - 24", value: 0 },
      { name: "25 - 34", value: 0 },
      { name: "35 - 44", value: 0 },
      { name: "45 - 64", value: 0 },
      { name: "> 65", value: 0 },
    ];
    data?.map((info) => {
      info.age_range === "13-17"
        ? (ageDetails[0].value += info.value)
        : info.age_range === "18-24"
          ? (ageDetails[1].value += info.value)
          : info.age_range === "25-34"
            ? (ageDetails[2].value += info.value)
            : info.age_range === "35-44"
              ? (ageDetails[3].value += info.value)
              : info.age_range === "45-64"
                ? (ageDetails[4].value += info.value)
                : (ageDetails[5].value += info.value);
    });
    return ageDetails;
  }

  function getCities({ data }) {
    let cities = [];
    data?.map((info) => {
      info.age_range === "13-17"
        ? (cities[0].value += info.value)
        : info.age_range === "18-24"
          ? (cities[1].value += info.value)
          : info.age_range === "25-34"
            ? (cities[2].value += info.value)
            : (cities[3].value += info.value);
    });
    return cities;
  }

  //func to convert '2022-09' -> 'Sep 2022'
  function convertDateToString(date) {
    const [year, month] = date.split("-");
    const monthName = new Date(`${month} 01 2000`).toLocaleString("default", { month: "short" });

    const formattedDate = `${monthName} ${year}`;
    return formattedDate;
  }

  useEffect(() => {
    if (location.hash === "#comment-analysis-section") {
      const element = document.getElementById("comment-analysis-section");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    } else {
      document.querySelector(".scrolling-page-content").scrollTo({ top: 0 });
    }
  }, []);



  const createDynamicTabsForContentSwitching = (profileAnalytics) => {
    // For Content Type
    const contentType = [];
    if (profileAnalytics?.profile?.top_contents?.length !== 0) contentType.push(contentTypeTabs.TopContent);
    if (profileAnalytics?.profile?.sponsored_contents?.length !== 0) contentType.push(contentTypeTabs.SponsoredContent);
    setContentTypeAllTabs(contentType);
    // For Audience Location
    const audienceLocations = [];
    if (profileAnalytics?.profile?.audience?.countries?.length !== 0) audienceLocations.push(audienceLocationTabs.Countries);
    if (profileAnalytics?.profile?.audience?.cities?.length !== 0) audienceLocations.push(audienceLocationTabs.Cities);
    setAudienceLocationAllTabs(audienceLocations);

    // For Audience Demographics
    const audienceDemographics = [];
    if (profileAnalytics?.profile?.audience?.age_distribution?.length !== 0) audienceDemographics.push(audienceDemographicsTabs.Age);
    if ((profileAnalytics?.profile?.audience?.languages?.length !== 0) !== 0) audienceDemographics.push(audienceDemographicsTabs.Language);
    if (profileAnalytics?.profile?.audience?.ethnicities?.length !== 0) audienceDemographics.push(audienceDemographicsTabs.Ethnicity);
    setAudienceDemographicsAllTabs(audienceDemographics);

    setContentTypeCurrTab(contentType[0]?.label);
    setAudienceLocationCurrtab(audienceLocations[0]?.label);
    setAudienceDemographicsCurrTab(audienceDemographics[0]?.label);
  };

  const handleCopyAction = (copyText) => {
    navigator.clipboard.writeText(copyText);
    toast("Contact copied to clipboard");
  };

  useEffect(() => {
    if (errorReason === PROFILE_ANALYTICS_ERROR_MAPPING.retry_later) {
      let selectedCreatorDetails = creatorDiscoveryWarehouse.selectedCreatorDetails;
      if (Object.keys(selectedCreatorDetails)?.length === 0) {
        selectedCreatorDetails = JSON.parse(sessionStorage.getItem("search-results"))?.selectedCreator ?? {};
      }

      if (Object.keys(selectedCreatorDetails)?.length > 0) {
        setShowBasicDetails({ show: true, selectedCreatorDetails: selectedCreatorDetails });
      }
    }
  }, [errorReason, creatorDiscoveryWarehouse]);

  useEffect(() => {
    if (errorReason === PROFILE_ANALYTICS_ERROR_MAPPING.retry_later) {
      let selectedCreatorDetails = creatorDiscoveryWarehouse.selectedCreatorDetails;
      if (Object.keys(selectedCreatorDetails)?.length === 0) {
        selectedCreatorDetails = JSON.parse(sessionStorage.getItem("search-results"))?.selectedCreator ?? {};
      }

      if (Object.keys(selectedCreatorDetails)?.length > 0) {
        setShowBasicDetails({ show: true, selectedCreatorDetails: selectedCreatorDetails });
      }
    }
  }, [errorReason, creatorDiscoveryWarehouse]);

  function isContactContainsURL(contact) {
    return contact?.startsWith("https://");
  }

  function getContactDropdownLabel(contact) {
    let contactType = contact?.type;

    if (isContactContainsURL(contact?.value)) {
      return CONTACT_TYPE_ICONS_MAPPING[contactType]?.label;
    } else {
      return contact?.label;
    }
  }

  function getContactDropdownEndIcon(contact) {
    const { email } = creatorContactType;
    let contactType = contact?.type;

    if (isContactContainsURL(contact?.value)) {
      return <i className="ri-arrow-right-up-line menu-icon" />;
    } else if (contactType === email) {
      return (
        <Box display={"flex"} gap={"24px"}>
          <i className="ri-arrow-right-up-line menu-icon"></i>
          <i
            className="ri-file-copy-line menu-icon"
            onClick={(e) => {
              e.stopPropagation();
              handleCopyAction(contact?.label);
            }}
          />
        </Box>
      );
    } else {
      return (
        <i
          className="ri-file-copy-line menu-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleCopyAction(contact?.label);
          }}
        />
      );
    }
  }

  function contactDropdownActionHandler(contact) {
    const { email } = creatorContactType;
    let contactType = contact?.type;

    if (isContactContainsURL(contact?.value)) {
      return () => {
        window.open(contact?.value, "_blank");
      };
    } else if (contactType === email) {
      return emailMenuHandler;
    }
  }

  const handleProfileAnalytics = async () => {
    if (onlyOnce.current) {
      onlyOnce.current = false;
      const url = new URL(window.location);
      const searchParams = new URLSearchParams(url.search);
      const identifier = searchParams.get("identifier");
      const work_platformId = searchParams.get("workplatformId");
      setWorkplatformId(work_platformId);

      const payload = {
        identifier: identifier,
        work_platform_id: work_platformId,
      };
      try {
        const response = await creatorDiscoveryAPI.getProfileAnalytics({ warehouse, payload });
        if (response?.profile?.updated_at) {
          dispatchWarehouse({
            type: SHOW_TOP_HEADER,
            payload: {
              scrollable: true,
              title: PROFILE_ANALYTICS_TOP_HEADER_INFO(convertTimeToLocale(response.profile.updated_at, DATE_FORMAT)),
              backgroundColor: Colors.white,
              textClass: "profile-header-status-text",
            },
          });
        } else {
          dispatchWarehouse({
            type: HIDE_TOP_HEADER,
          });
        }
        const majorityFollowerType = getLargestValueInFollowerTypes(response?.profile?.audience?.follower_types);
        const credibilityScore = getCredibilityScorePercentage(response?.profile?.audience?.credibility_score);

        // convert age distribution
        response.profile.audience.age_distribution = getAgeDetails({ data: response?.profile?.audience?.gender_age_distribution });

        // convert gender distribution
        response.profile.audience.gender_age_distribution = getGenderDetails({ data: response?.profile?.audience?.gender_age_distribution });

        // convert follower types
        response.profile.audience.follower_types = getFollowerTypes({ data: response?.profile?.audience?.follower_types });

        // convert languages
        response.profile.audience.languages = response.profile.audience.languages
          // .sort(function (a, b) {
          //   return b.value - a.value;
          // })
          .slice(0, 5)
          .map((lang) => {
            return {
              name: new Intl.DisplayNames(["en"], { type: "language" }).of(lang.code),
              value: lang.value,
            };
          });

        // convert countries
        response.profile.audience.countries = response?.profile?.audience?.countries
          ?.sort(function (a, b) {
            return b.value - a.value;
          })
          .map((country) => {
            return {
              name: new Intl.DisplayNames(["en"], { type: "region" }).of(country.code),
              value: country.value,
            };
          });
        //convert dates in graph '2022-09' -> 'Sep 2022'
        response.profile.reputation_history = response.profile.reputation_history
          .sort((a, b) => b.month.localeCompare(a.month))
          .slice(0, 5)
          .reverse()
          .map((item) => {
            return {
              ...item,
              month: new Date(item.month).toLocaleDateString("default", { month: "short" }) + " " + new Date(item.month).getFullYear(),
            };
          });

        createDynamicTabsForContentSwitching(response);

        const contactDetailsOfCreators = response?.profile?.contact_details;
        const contactMenuItems = contactDetailsOfCreators;
        const creatorOthersSocialHandles = contactDetailsOfCreators?.filter((contactDetailObj) => {
          return isContactContainsURL(contactDetailObj?.value);
        });
        const workPlatformLogos = [
          {
            handle: response?.profile?.url,
            logo: WORKPLATFORM_ID_MAPPING[response?.work_platform?.id]?.platformLogo,
          },
        ];
        creatorOthersSocialHandles.map((otherhandlesObj) => {
          workPlatformLogos.push({
            handle: otherhandlesObj.value,
            logo: CONTACT_TYPE_ICONS_MAPPING[otherhandlesObj.type]?.logo,
          });
        });
        const contactDropDownMenuItems = contactMenuItems?.map((contactDropdownMenuItem) => {
          return {
            startIcon: CONTACT_TYPE_ICONS_MAPPING[contactDropdownMenuItem?.type]?.logo,
            label: getContactDropdownLabel(contactDropdownMenuItem),
            endIcon: getContactDropdownEndIcon(contactDropdownMenuItem),
            actionHandler: contactDropdownActionHandler(contactDropdownMenuItem),
          };
        });

        const creatorBioSectionContent = [];
        creatorBioSectionItems.forEach((item) => {
          if (response?.profile.hasOwnProperty(item)) {
            creatorBioSectionContent.push({
              icon: creatorBioIconMapping[item],
              header: creatorBioHeaders[item],
              value: constructValueForCreatorBio(item, response?.profile[item]),
              showToolTip: item === creatorBioType.location ? true : false,
            });
          }
        });

        const { overview, commentAnalysis, growth, audience, similarCreators } = profileSectionsLabel;
        const profileSectionsBasedOnPlatform = [overview, commentAnalysis, audience];

        if (response?.profile?.reputation_history.length > 1) {
          profileSectionsBasedOnPlatform.splice(2, 0, growth);
        }

        if (response?.profile?.lookalikes?.length > 0) {
          profileSectionsBasedOnPlatform.push(similarCreators);
        }

        let sectionTabsRefs;
        // Create an array of section refs
        if (response?.profile?.reputation_history.length > 1 && response?.profile?.lookalikes?.length > 0) {
          sectionTabsRefs = [performanceSectionRef, commentAnalysisRef, growthSectionRef, audienceSectionRef, similarCreatorsSectionRef];
        } else if (response?.profile?.reputation_history.length > 1) {
          sectionTabsRefs = [performanceSectionRef, commentAnalysisRef, growthSectionRef, audienceSectionRef];
        } else if (response?.profile?.lookalikes?.length > 0) {
          sectionTabsRefs = [performanceSectionRef, commentAnalysisRef, audienceSectionRef, similarCreatorsSectionRef];
        } else {
          sectionTabsRefs = [performanceSectionRef, commentAnalysisRef, audienceSectionRef];
        }
        const exportPDFDropdownOptions = [
          {
            startIcon: <i class="ri-links-line menu-icon" />,
            label: "Share link",
            actionHandler: async () => {
              await handleProfileExport();
              setIsExportPDFModalOpen(true);
            },
          },
          {
            startIcon: <i class="ri-file-list-3-line menu-icon" />,
            label: "Download PDF",
            actionHandler: async () => {
              let isPDFDowloadRequired = true;
              await handleProfileExport(isPDFDowloadRequired);
            },
          },
        ];

        if (work_platformId === WORKPLATFORM_IDS.YouTubePlatformId) {
          setShowBrandSafety(false);
        }
        else {
          if (isBrandSafetyTrialMode) {
            setBrandSafety({ status: TRIAL });
          } else if(response.brand_safety) {
            let brandSafetyDetails = response?.brand_safety;
            if (!response.brand_safety || brandSafetyDetails?.status === null) {
              brandSafetyDetails.status = NOT_REQUESTED;
            }
            setBrandSafety(brandSafetyDetails);
          }
        }

        setSectionRefs(sectionTabsRefs);
        setWorkPlatformsLogos(workPlatformLogos);
        setMajorityFollowerType(majorityFollowerType);
        setCredibilityScore(credibilityScore);
        setProfileSections(profileSectionsBasedOnPlatform);
        setCreatorBioItems(creatorBioSectionContent);
        setContactMenuItems(contactDropDownMenuItems);
        setExportPDFMenuItems(exportPDFDropdownOptions);
        setProfileAnalytics(response);
      } catch (error) {
        if (error) {
          setIsLoading(false);
          if (error.response.data) {
            if (error.response.data.error.message === PROFILE_ANALYTICS_ERROR_MAPPING["max_limit_reached"]) {
              setRender(false);
              setIsModalVisible(true);
              setModalDetails({
                ...modalDetails,
                isModalVisible: true,
                modalInfo: unlockPopupDetails.fetchCreator,
              });
              
            } else {
              if (PROFILE_ANALYTICS_ERROR_MAPPING.hasOwnProperty(error.response.data.error.error_code)) {
                setErrorReason(PROFILE_ANALYTICS_ERROR_MAPPING[error.response.data.error.error_code]);
                if (error.response.data.error.error_code === "retry_later") {
                  dispatchWarehouse({
                    type: SHOW_TOP_HEADER,
                    payload: {
                      scrollable: true,
                      title: UPDATING_CREATORS_DETAIL_ERROR.top_header_details.title,
                      backgroundColor: UPDATING_CREATORS_DETAIL_ERROR.top_header_details.backgroundColor,
                      textClass: UPDATING_CREATORS_DETAIL_ERROR.top_header_details.textClass,
                    },
                  });
                }
              } else {
                setErrorReason(PROFILE_ANALYTICS_ERROR_MAPPING["something_wrong_on_our_end"]);
              }
            }
          }
        }
      }

      // todo remvoe this delay once api is integrated
      setIsLoading(false);
    }
  };

  // to call profile analytics api
  useEffect(() => {
    handleProfileAnalytics();
  }, []);


  const emailMenuHandler = () => {
    window.location = "mailto:rev@insightiq.ai";
  };


  // to call export profile analytics api
  const handleProfileExport = async (isPDFDowloadRequired = false) => {
    setIsPDFLoaded(true);
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    const identifier = searchParams.get("identifier");
    const work_platformId = searchParams.get("workplatformId");
    const payload = {
      identifier: identifier,
      work_platform_id: work_platformId,
      format: "pdf",
    };
    try {
      const response = await exportProfileAnalytics({ warehouse: warehouse, environment: warehouse.environment.current, payload });

      const url = response.data?.url;
      setexportPDFLink(url);
      // return url;
      // Provide a filename for the downloaded PDF
      if (isPDFDowloadRequired) {
        const fileName = response.data?.filename;
        // take url from response and click to download it
        const pdfDownloadUrl = document.createElement("a");
        pdfDownloadUrl.href = url;
        pdfDownloadUrl.download = fileName;
        document.body.appendChild(pdfDownloadUrl);
        pdfDownloadUrl.click();
        document.body.removeChild(pdfDownloadUrl);
      }
    } catch (error) {
      setIsPDFLoaded(false);
    }
    setIsPDFLoaded(false);
  };

  const handleCreatorSelectedList = (selectedCreator, work_platformId) => {
    setCreateListPopUpState({
      ...createListPopUpState,
      visible: true,
      isVerified: selectedCreator?.is_verified,
      full_name: selectedCreator?.full_name,
      image_url: selectedCreator?.image_url,
      platform_username: selectedCreator?.platform_username,
      profile_url: selectedCreator?.url,
      workplatform_id: work_platformId,
      isCreatorAlreadyPresentInList: true,
    });
  };

  const creatorsListsHandler = () => {
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);
    const work_platformId = searchParams.get("workplatformId");
    if (isCreatorSearchTrialMode) {
      setModalDetails({
        ...modalDetails,
        isModalVisible: true,
        modalInfo: unlockAccessPopupDetails.addCreatorList,
      });
    } else {
      if (profileAnalytics?.is_part_of_creator_list) {
        handleCreatorSelectedList(profileAnalytics?.profile, work_platformId);
      } else {
        setCreateListPopUpState({
          ...createListPopUpState,
          visible: true,
          isVerified: profileAnalytics?.profile?.is_verified,
          full_name: profileAnalytics?.profile?.full_name,
          image_url: profileAnalytics?.profile?.image_url,
          platform_username: profileAnalytics?.profile?.platform_username,
          profile_url: profileAnalytics?.profile?.url,
          workplatform_id: profileAnalytics?.work_platform?.id,
        });
      }
    }
  };

  // Sticky Nav-Bar when we scroll bottom of the page
  const StickyTopBar = () => {
    return (
      showAppBar && (
        <AppBar position="fixed" sx={{ transform: showAppBar ? "translateY(0)" : "translateY(-100%)" }} className="creator-discovery-profile-app-bar">
          <Toolbar disableGutters className="app-bar-container">
            <Box display={"flex"} width={"449.5px"} gap={"24px"}>
              <Box>{<i className="ri-arrow-left-line back-icon" onClick={() => window.history.back()} />}</Box>
              <Box className="app-bar-profile-name">
                <Box>
                  {profileAnalytics?.profile?.full_name !== null && profileAnalytics?.profile?.full_name !== "None"
                    ? profileAnalytics?.profile?.full_name
                    : profileAnalytics?.profile?.platform_username}
                </Box>
                {profileAnalytics?.profile?.is_verified && (
                  <div>
                    <Icons.verified_logo />
                  </div>
                )}
              </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={"32px"}>
              {/* <Box className="identity-right-header-favorite-container">
                <i className="ri-heart-line heart-icon"></i>
                Add to favorites
              </Box> */}
              <Box className="identity-right-header-favorite-container" onClick={creatorsListsHandler}>
                {profileAnalytics?.is_part_of_creator_list ? (
                  <div className="profile-header-icon-container selected">
                    <Icons.created_list />
                  </div>
                ) : (
                  <div>
                    <i className="ri-play-list-add-line heart-icon"></i>
                  </div>
                )}
                {profileAnalytics?.is_part_of_creator_list ? (
                  <div style={{ color: "var(--main-primary)" }}>Added to list</div>
                ) : (
                  <div>Add to list</div>
                )}
              </Box>
              <Box className="identity-right-header-button-container">
                <Button
                  startIcon={<i className="ri-share-forward-line share-icon" />}
                  endIcon={<i className="ri-arrow-down-s-line"></i>}
                  id="demo-customized-button"
                  aria-controls={isExportPDFDropdownOptionsOpen ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={isExportPDFDropdownOptionsOpen ? "true" : undefined}
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={handleExportPDFDropDownClick}
                >
                  Share
                </Button>
                <DropdownIconMenu
                  open={isExportPDFDropdownOptionsOpen}
                  anchorEl={exportPDFDropdownOptionsAnchorEl}
                  handleClose={handleExportPDFDropDownClose}
                  menuOptions={exportPDFMenuItems}
                  width={"180px"}
                  showAppBar={showAppBar}
                  dropDownRightPosition={"170px"}
                />
              </Box>
              <Box display={"flex"} gap={"40px"}>
                {contactMenuItems.length > 0 && (
                  <Box className="identity-right-header-contact-button">
                    <Button
                      id="demo-customized-button"
                      aria-controls={isContactOptionsDropdownOpen ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={isContactOptionsDropdownOpen ? "true" : undefined}
                      disableElevation
                      onClick={handleContactOptionsDropDownClick}
                      endIcon={<i className="ri-arrow-down-s-line"></i>}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    >
                      Contact
                    </Button>
                    <DropdownIconMenu
                      open={isContactOptionsDropdownOpen}
                      anchorEl={contactOptionsDropdownAnchorEl}
                      handleClose={handleContactOptionsDropDownClose}
                      menuOptions={contactMenuItems}
                      isLogoContainsImage
                      width={"305px"}
                      showAppBar={showAppBar}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      )
    );
  };

  const checkActiveSection = (className, profileAnalytics) => {
    switch (className) {
      case "stats-container":
        return (
          profileAnalytics?.profile?.top_hashtags.length > 0 ||
          profileAnalytics?.profile?.top_mentions.length > 0 ||
          profileAnalytics?.profile?.top_interests.length > 0 ||
          profileAnalytics?.profile?.brand_affinity.length > 0
        );
      case "graphs-container":
        return (
          profileAnalytics?.profile?.reputation_history[0]?.follower_count ||
          profileAnalytics?.profile?.reputation_history[0]?.subscriber_count ||
          profileAnalytics?.profile?.reputation_history[0]?.average_likes
        );
      case "audience-demographics-container":
        return (
          profileAnalytics?.profile?.audience?.age_distribution?.length > 0 ||
          profileAnalytics?.profile?.audience?.languages?.length > 0 ||
          profileAnalytics?.profile?.audience?.ethnicities?.length > 0
        );
      case "audience-brand-affinity-container":
        return profileAnalytics?.profile?.audience?.brand_affinity?.length > 0 || profileAnalytics?.profile?.audience?.interests > 0;
    }
  };


  const fullName = profileAnalytics?.profile?.full_name;

  function downloadBrandSafetyPdf() {
    const url = brandSafety?.report_pdf_url;
    const fileName = "brand_safety_report";
    // take url from response and click to download it
    const pdfDownloadUrl = document.createElement("a");
    pdfDownloadUrl.href = url;
    pdfDownloadUrl.target = "_blank";
    pdfDownloadUrl.download = fileName;
    document.body.appendChild(pdfDownloadUrl);
    pdfDownloadUrl.click();
    document.body.removeChild(pdfDownloadUrl);
  }

  const BRAND_SAFETY_STATES = {
    SUCCESS: {
      title: BRAND_SAFETY_BANNER_TITLE_DOWNLOAD(fullName),
      description: BRAND_SAFETY_BANNER_DESCRIPTION_DOWNLOAD,
      buttonCTA: BRAND_SAFETY_SUCCESS_BUTTON_CTA,
      buttonIcon: <Icons.download_icon />,
      bannerIcon: <Icons.brand_safety_icon />,
      buttonAction: () => {
        downloadBrandSafetyPdf();
      }
    },
    IN_PROGRESS: {
      title: (
        <div className="brand-safety-in-progress-title" >
          <Loader className="brand-safety-loader"  />
          {BRAND_SAFETY_BANNER_REPORT_GENERATING_TITLE(fullName)}
        </div>
      ),
      description: BRAND_SAFETY_BANNER_REPORT_GENERATING_DESCRIPTION(userDetails.email),
      buttonCTA: null,
      bannerIcon: <Icons.brand_safety_icon />,
      buttonAction: () => { }
    },
    TRIAL: {
      title: BRAND_SAFETY_BANNER_TITLE_TRIAL(fullName),
      description: <>{BRAND_SAFETY_BANNER_REQUEST_DESCRIPTION}</>,
      buttonCTA: UPGRADE,
      bannerIcon: <Icons.brand_safety_icon />,
      buttonAction: () => {
        setIsBrandSafetyModalVisible(true);
      }
    },
    NOT_REQUESTED: {
      title: BRAND_SAFETY_BANNER_REQUEST_TITLE(fullName),
      description: <>{BRAND_SAFETY_BANNER_REQUEST_DESCRIPTION}</>,
      buttonCTA: BRAND_SAFETY_REQUEST,
      bannerIcon: <Icons.brand_safety_icon />,
      buttonAction: handlePostBrandSafety,
    },
    FAILURE: {
      title: BRAND_SAFETY_BANNER_REQUEST_TITLE(fullName),
      description: <>{BRAND_SAFETY_BANNER_REQUEST_DESCRIPTION}</>,
      buttonCTA: BRAND_SAFETY_REQUEST,
      bannerIcon: <Icons.brand_safety_icon />,
      buttonAction: handlePostBrandSafety,
    },
  };

  async function handlePostBrandSafety() {
    try {
      setIsBrandSafetyLoading(true);
      const payload = {
        work_platform_id: workplatformId,
        profile_url: profileAnalytics?.profile?.url,
      };
      
      const response = await postBrandSafety({ warehouse, payload });
      if (response?.status === FAILURE) {
        setIsBrandSafetyFailure(true);
        setBrandSafety({ ...brandSafety, status: NOT_REQUESTED });
      }
      setBrandSafety({ ...brandSafety, status: response?.status });
    } catch (error) {
      setIsBrandSafetyFailure(true);
      setBrandSafety({ ...brandSafety, status: NOT_REQUESTED });
      console.log(error);
    } finally {
      setIsBrandSafetyLoading(false);
    }
  }


  return (
    <>
      {render && (
        <section className="creator-discovery-profile-contianer" ref={scrollRef}>
          {/* profile header and identity */}
          <Box sx={{ padding: "20px 0px 20px 8px" }} id="overview-section">
            {<i className="ri-arrow-left-line back-icon" onClick={() => window.history.back()} />}
          </Box>
          <StickyTopBar />
          <SearchProfileHeader
            previousePageUrl="creators/creator-search"
            errorReason={errorReason}
            identityData={profileAnalytics}
            loading={isLoading}
            handleProfileExport={handleProfileExport}
            exportButtonLoading={exportButtonLoading}
            contactMenuItems={contactMenuItems}
            creatorBioItems={creatorBioItems}
            workPlatformLogos={workPlatformsLogos}
            isExportPDFModalOpen={isExportPDFModalOpen}
            setIsExportPDFModalOpen={setIsExportPDFModalOpen}
            exportPDFMenuItems={exportPDFMenuItems}
            isTrialMode={isCreatorSearchTrialMode}
            createListPopUpState={createListPopUpState}
            setCreateListPopUpState={setCreateListPopUpState}
            handleMultipleAddToCreatorList={handleCreatorSelectedList}
            modalDetails={modalDetails}
            setModalDetails={setModalDetails}
            showBasicDetails={showBasicDetails}
          />
          {!errorReason && (
            <>
              <CreatorProfileSectionBar 
                isLoading={isLoading} 
                sectionBarLabels={profileSections} 
                handleTabHover={handleHover}
                handleMouseTabLeave={handleMouseLeave}
                handleTabClick={handleSectionClick}
                activeTabIndex={activeSectionIndex}
                activeTabHoveredState={isHovered}
                isSticky={isSticky}
              />

              <div ref={performanceSectionRef} className="overview-section">
                <SearchProfileIdentity
                  workPlatformMapping={SEARCH_IDENTITY_WORKPLATFORMS_MAPPING}
                  identityData={profileAnalytics}
                  loading={isLoading}
                  majorityFollowerType={majorityFollowerType}
                  followerTypeActionHandler={handleAutomaticScrollOfFollowerType}
                  credibilityScore={credibilityScore}
                />
              </div>
              {isLoading ? (
                <Skeleton animation="wave" variant="text" width={307} height={43} />
              ) : (
                contentTypeAllTabs?.length > 0 && (
                  <Box padding={"24px 8px 48px 8px"} display={"flex"} flexDirection={"column"} gap={"24px"}>
                    <Box height={contentTypeAllTabs?.length > 1 ? "48px" : "auto"}>
                      {contentTypeAllTabs?.length > 1 ? (
                        <TabSwitch
                          currentTab={contentTypeCurrTab}
                          handleTabChange={(event, newValue) => {
                            setContentTypeCurrTab(newValue);
                          }}
                          tabs={contentTypeAllTabs}
                          width={"314px"}
                        />
                      ) : (
                        <Box className="content-section-header">{contentTypeCurrTab === TOP_CONTENT ? TOP_CONTENT : SPONSORED_CONTENT}</Box>
                      )}
                    </Box>
                  </Box>
                )
              )}
              {/* top content */}
              {contentTypeCurrTab === TOP_CONTENT && (
                <div className="top-content-container">
                  <div className="top-content-wrapper">
                    <div className="content-container" ref={topContentScrollContainer}>
                      {!isLoading &&
                        profileAnalytics &&
                        profileAnalytics?.profile &&
                        profileAnalytics.profile?.top_contents &&
                        profileAnalytics.profile?.top_contents?.length > 0 && (
                          <>
                            {profileAnalytics.profile?.top_contents?.map((content, index) => (
                              <ContentItem
                                key={index}
                                profileImageURL={profileAnalytics.profile?.image_url}
                                workplatformId={workplatformId}
                                comments={content?.engagement?.comment_count}
                                desc={content?.description}
                                img={content?.thumbnail_url}
                                likes={content?.engagement?.like_count}
                                url={content?.url}
                                event={SEARCH_CONTENT_VIEWED}
                                platform={profileAnalytics?.work_platform?.name}
                                username={profileAnalytics?.profile?.platform_username}
                                type="top_content"
                                published_date={content?.published_at}
                              />
                            ))}
                          </>
                        )}
                      {profileAnalytics.profile?.top_contents?.length > 4 && (
                        <>
                          <IconButton
                            aria-label="left-scroll"
                            className="left-scroll-btn"
                            onClick={() => {
                              topContentScrollContainer.current.scrollBy(-400, 0);
                            }}
                          >
                            <Icons.left_arrow />
                          </IconButton>
                          <IconButton
                            aria-label="right-scroll"
                            className="right-scroll-btn"
                            onClick={() => {
                              topContentScrollContainer.current.scrollBy(400, 0);
                            }}
                          >
                            <Icons.right_arrow />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* sponsored content */}
              {contentTypeCurrTab === SPONSORED_CONTENT && (
                <div className="top-content-container">
                  <div className="top-content-wrapper">
                    <div className="content-container" ref={topContentScrollContainer}>
                      {!isLoading &&
                        profileAnalytics &&
                        profileAnalytics?.profile &&
                        profileAnalytics.profile?.sponsored_contents &&
                        profileAnalytics.profile?.sponsored_contents?.length > 0 && (
                          <>
                            {profileAnalytics.profile?.sponsored_contents?.map((content, index) => (
                              <ContentItem
                                key={index}
                                profileImageURL={profileAnalytics.profile?.image_url}
                                workplatformId={workplatformId}
                                comments={content?.engagement?.comment_count}
                                desc={content?.description}
                                img={content?.thumbnail_url}
                                likes={content?.engagement?.like_count}
                                url={content?.url}
                                event={SEARCH_CONTENT_VIEWED}
                                platform={profileAnalytics?.work_platform?.name}
                                username={profileAnalytics?.profile?.platform_username}
                                type="sponsored_content"
                                published_date={content?.published_at}
                              />
                            ))}
                          </>
                        )}
                      {profileAnalytics.profile?.sponsored_contents?.length > 4 && (
                        <>
                          <IconButton
                            aria-label="left-scroll"
                            className="left-scroll-btn"
                            onClick={() => {
                              topContentScrollContainer.current.scrollBy(-400, 0);
                            }}
                          >
                            <Icons.left_arrow />
                          </IconButton>
                          <IconButton
                            aria-label="right-scroll"
                            className="right-scroll-btn"
                            onClick={() => {
                              topContentScrollContainer.current.scrollBy(400, 0);
                            }}
                          >
                            <Icons.right_arrow />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* Skelton */}
              {isLoading && (
                <div className="top-content-container">
                  <div className="top-content-wrapper">
                    <div className="content-container">
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => {
                        return <ContentItem key={Math.floor(Math.random() * 10000000)} loading={true} />;
                      })}
                    </div>
                  </div>
                </div>
              )}

              {/* comment analysis */}
              <div className="divider" id="comment-analysis-section" />
              <section className="profile-discovery-comment-analysis-container" ref={commentAnalysisRef}>
                <CreatorDiscoveryCommentAnalysis profileAnalytics={profileAnalytics} />
              </section>

              {/* brand safety  */}
              <div className="divider" id="brand-safety-section" />

              { showBrandSafety && brandSafety ? <section className="profile-discovery-brand-safety-container">
                <Banner
                  title={BRAND_SAFETY_STATES[brandSafety?.status]?.title}
                  description={BRAND_SAFETY_STATES[brandSafety?.status]?.description}
                  icon={BRAND_SAFETY_STATES[brandSafety?.status]?.bannerIcon}
                  button={
                    (BRAND_SAFETY_STATES[brandSafety?.status]?.buttonCTA !== null ?
                      <div className="button-and-link">
                        <PrimaryButton 
                          label={BRAND_SAFETY_STATES[brandSafety?.status]?.buttonCTA}
                          className="button secondary-button body-m"
                          icon={BRAND_SAFETY_STATES[brandSafety?.status]?.buttonIcon}
                          onClick={BRAND_SAFETY_STATES[brandSafety?.status]?.buttonAction}
                        />
                        {isBrandSafetyTrialMode ? (
                          <div className="sample-report-container">
                            <span className='trial-or'>or</span>
                            <a
                              href="https://cdn.insightiq.ai/sample_reports/brand_safety_sample_report.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="sample-report-link"
                            >
                              {CHECK_SAMPLE_REPORT}
                            </a>
                          </div>
                        ) : null}
                      </div>
                      : null
                    )
                  }
                />
                <UnlockFullAccessPopUp
                  open={isBrandSafetyModalVisible}
                  onClose={() => setIsBrandSafetyModalVisible(false)}
                  header={BRAND_SAFETY_UNLOCK_MODAL_TITLE}
                  description={BRAND_SAFETY_UNLOCK_MODAL_DESCRIPTION}
                  actionButtonLabel="Contact us"
                  height="310px"
                  padding="52px 52px 48px 48px"
                  isModal
                />
                <ExportLoadingModal
                  open={isBrandSafetyLoading}
                  title="Requesting report..."
                  description="This may take a few minutes"
                  onClose={() => {
                    setIsBrandSafetyLoading(false);
                  }}
                />
                <GenericPopup
                  open={isBrandSafetyFailure}
                  icon={<span className="export-modal-icon">{<Icons.caution_icon />}</span>}
                  padding={"48px 48px 48px 48px"}
                  height={"338px"}
                  onClose={(e) => {
                    setIsBrandSafetyFailure(false);
                    setIsBrandSafetyLoading(false);
                  }}
                  header={EXPORT_FAILURE_HEADER}
                  description={EXPORT_FAILURE_DESC}
                  isModal
                  actionButtonLabel={
                    <span className={"span-action-button-label"}>
                      <div className="export-try-again-button">
                        <Icons.retry_circle />
                        {TRY_AGAIN}
                      </div>
                    </span>
                  }
                  handleButtonClick={(e) => {
                    handlePostBrandSafety().then(()=>{});
                    setIsBrandSafetyFailure(false);
                  }}
                  actionButtonSubtext={
                    <div style={{ marginTop: 24 }}>
                      <SupportEmail />
                    </div>
                  }
                />
              </section> : null}

              {/* graphs */}
              <>
                {profileAnalytics?.profile?.reputation_history.length > 1 && <div className="divider" />}
                <section
                  className={`graphs-container ${checkActiveSection("graphs-container", profileAnalytics) ? "active" : ""}`}
                  ref={growthSectionRef}
                  id="growth-section"
                >
                  {profileAnalytics?.profile?.reputation_history.length > 1 && <Box className="mainHeading">Growth & interests</Box>}

                  {/* skeletons */}
                  {isLoading && (
                    <Box sx={{ width: "570px" }}>
                      <LineChartDistribution loading={true} />
                    </Box>
                  )}
                  {isLoading && (
                    <Box sx={{ width: "570px" }}>
                      <LineChartDistribution loading={true} />
                    </Box>
                  )}

                  <Box display={"flex"} gap={"64px"}>
                    {/* Follower growth for tiktok and insta */}
                    {!isLoading &&
                      profileAnalytics?.profile?.reputation_history.length > 1 &&
                      profileAnalytics?.profile?.reputation_history[0]?.follower_count && (
                        <Box sx={{ width: "518px" }} display={"flex"} flexDirection={"column"} gap={"24px"}>
                          <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                            <Box className="content-section-header">{"Followers growth"}</Box>
                            <Box className="description">{"Monthly trend of total followers"}</Box>
                          </Box>
                          <LineChartDistribution
                            width={518}
                            height={264}
                            data={profileAnalytics?.profile?.reputation_history}
                            xAxisKey={"month"}
                            yAxisKey={"follower_count"}
                            yTickFormatter={convertToShortNumberChart}
                          />
                        </Box>
                      )}

                    {/*Subscriber growth for youtube */}
                    {!isLoading &&
                      profileAnalytics?.profile?.reputation_history.length > 1 &&
                      profileAnalytics?.profile?.reputation_history[0]?.subscriber_count && (
                        <Box sx={{ width: "518px" }} display={"flex"} flexDirection={"column"} gap={"24px"}>
                          <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                            <Box className="content-section-header">{"Subscribers growth"}</Box>
                            <Box className="description">{"Monthly trend of total subscribers"}</Box>
                          </Box>
                          <LineChartDistribution
                            width={518}
                            height={264}
                            data={profileAnalytics?.profile?.reputation_history}
                            xAxisKey={"month"}
                            yAxisKey={"subscriber_count"}
                            yTickFormatter={convertToShortNumberChart}
                          />
                        </Box>
                      )}

                    {/* Likes growth for all */}
                    {!isLoading &&
                      profileAnalytics?.profile?.reputation_history.length > 1 &&
                      profileAnalytics?.profile?.reputation_history[0]?.average_likes && (
                        <Box sx={{ width: "518px" }} display={"flex"} flexDirection={"column"} gap={"24px"}>
                          <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                            <Box className="content-section-header">{"Likes growth"}</Box>
                            <Box className="description">{"Monthly trend of average likes per post"}</Box>
                          </Box>
                          <LineChartDistribution
                            width={518}
                            height={264}
                            data={profileAnalytics?.profile?.reputation_history}
                            xAxisKey={"month"}
                            yAxisKey={"average_likes"}
                            yTickFormatter={convertToShortNumberChart}
                          />
                        </Box>
                      )}
                  </Box>
                </section>
              </>

              {/* stats */}
              {(profileAnalytics?.profile || isLoading) && (
                <section className={`stats-container ${checkActiveSection("stats-container", profileAnalytics) ? "active" : ""}`}>
                  {(profileAnalytics?.profile?.brand_affinity.length !== 0 || isLoading) && (
                    <Box width={"525.5px"}>
                      <TagsCard
                        header="Creator’s brand affinity"
                        tags={profileAnalytics?.profile?.brand_affinity || []}
                        loading={isLoading}
                        tooltip={true}
                        description={SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.creator_brand_affinity}
                        icon={<i className="ri-price-tag-3-line tags-header-icon"></i>}
                        tooltipText={SEARCH_ATTRIBUTES_TOOLTIP_TEXT.creator_brand_affinity}
                      />
                    </Box>
                  )}

                  {(profileAnalytics?.profile?.top_interests.length !== 0 || isLoading) && (
                    <Box width={"525.5px"}>
                      <TagsCard
                        header="Creator's interests"
                        tags={profileAnalytics?.profile?.top_interests || []}
                        loading={isLoading}
                        tooltip={true}
                        description={SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.interests}
                        icon={<i className="ri-user-heart-line tags-header-icon"></i>}
                        tooltipText={SEARCH_ATTRIBUTES_TOOLTIP_TEXT.interests}
                      />
                    </Box>
                  )}

                  {(profileAnalytics?.profile?.top_hashtags.length !== 0 || isLoading) && (
                    <Box width={"525.5px"}>
                      <TagsCard
                        header="Frequently used hashtags"
                        tags={profileAnalytics?.profile?.top_hashtags || []}
                        loading={isLoading}
                        tooltip={false}
                        suffix={"#"}
                        description={SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.popular_hashtags}
                        icon={<i className="ri-hashtag tags-header-icon"></i>}
                      />
                    </Box>
                  )}
                  {(profileAnalytics?.profile?.top_mentions.length !== 0 || isLoading) && (
                    <Box width={"525.5px"}>
                      <TagsCard
                        header="Frequently tagged accounts"
                        tags={profileAnalytics?.profile?.top_mentions || []}
                        loading={isLoading}
                        tooltip={false}
                        event={SEARCH_OTHERS_PROFILES_CLICKED}
                        description={SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.popular_tags}
                        suffix={"@"}
                        icon={<i className="ri-at-line tags-header-icon"></i>}
                      />
                    </Box>
                  )}
                </section>
              )}

              <div className="divider" />
              {/* Audience */}
              {(profileAnalytics?.profile?.audience?.countries != null || isLoading) && (
                <section className="profile-discovery-audience-container" ref={audienceSectionRef}>
                  <Box className="mainHeading">Audience</Box>

                  <Box display={"flex"} flexWrap={"wrap"}>
                    <Box display={"flex"} gap={"48px"} width={"100%"} paddingBottom={"48px"}>
                      {(profileAnalytics?.profile?.audience?.gender_age_distribution.length !== 0 || isLoading) && (
                        <Box display={"flex"} flexDirection={"column"} gap={"40px"} width={"526px"} id="audience-section">
                          <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                            <Box className="content-section-header">{"Gender distribution"}</Box>
                          </Box>
                          <div className="piechart-main-container" ref={genderAgeChartContainerRef}>
                            {isGenderAgeChartContainerVisible && (
                              <DonutChart
                                data={profileAnalytics?.profile?.audience?.gender_age_distribution}
                                variant={"triple-disconnected"}
                                height={271}
                                width={271}
                                isActive={isGenderAgeChartContainerVisible}
                              />
                            )}
                            <div className={"div-donut-chart-legend-profile"}>
                              {profileAnalytics?.profile?.audience?.gender_age_distribution.map((legend) => {
                                const { color, value, name } = legend;
                                return (
                                  //{Chart Legends}
                                  <div className={"row-1"}>
                                    <svg width={16} height={16}>
                                      <circle cx={8} cy={8} r={8} fill={color} />
                                    </svg>
                                    <div className={"sub-section-heading legend-title"}>{Number(value).toFixed(1) + "%"}</div>
                                    <div className={"body-r legend-subtitle"}>{name}</div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Box>
                      )}
                      {audienceLocationAllTabs?.length > 0 && (
                        <Box display={"flex"} flexDirection={"column"} gap={"24px"} width={"526px"}>
                          <Box display={"flex"} flexDirection={"column"} gap={"24px"}>
                            <Box className="content-section-header">
                              <div>
                                {"Audience location"}{" "}
                                <Tooltip title={SEARCH_ATTRIBUTES_TOOLTIP_TEXT.audience_location} placement="top-start">
                                  <i className="ri-information-line"></i>
                                </Tooltip>
                              </div>
                            </Box>
                            <Box height={audienceLocationAllTabs?.length > 1 ? "48px" : "auto"}>
                              {audienceLocationAllTabs?.length > 1 ? (
                                <TabSwitch
                                  currentTab={audienceLocationCurrtab}
                                  handleTabChange={(event, newValue) => {
                                    setAudienceLocationCurrtab(newValue);
                                  }}
                                  tabs={audienceLocationAllTabs}
                                  width="195px"
                                />
                              ) : (
                                <Box className="content-section-header">
                                  {audienceLocationCurrtab === COUNTRIES ? COUNTRIES_HEADER : CITIES_HEADER}
                                </Box>
                              )}
                            </Box>
                          </Box>
                          {/* Audience location - Country*/}
                          {audienceLocationCurrtab === COUNTRIES && profileAnalytics?.profile?.audience?.countries?.length !== 0 && (
                            <Box>
                              <LinearDistribution
                                data={profileAnalytics?.profile?.audience?.countries}
                                loading={isLoading}
                                title="Audience location - Country"
                              />
                            </Box>
                          )}

                          {/* Audience location - City*/}
                          {audienceLocationCurrtab === CITIES && profileAnalytics?.profile?.audience?.cities?.length !== 0 && (
                            <Box>
                              <LinearDistribution
                                data={profileAnalytics?.profile?.audience?.cities.sort(function (a, b) {
                                  return b.value - a.value;
                                })}
                                loading={isLoading}
                                title="Audience location - City"
                              />
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>

                    <Box
                      className={`audience-demographics-container ${checkActiveSection("audience-demographics-container", profileAnalytics) ? "active" : ""
                        }`}
                    >
                      <Box display={"flex"} flexDirection={"column"} gap={"24px"}>
                        <Box className="content-section-header">{"Audience demographics"}</Box>
                        <Box height={audienceDemographicsAllTabs?.length > 1 ? "48px" : "auto"}>
                          {audienceDemographicsAllTabs?.length > 1 ? (
                            <TabSwitch
                              currentTab={audienceDemographicsCurrTab}
                              handleTabChange={(event, newValue) => {
                                setAudienceDemographicsCurrTab(newValue);
                              }}
                              tabs={audienceDemographicsAllTabs}
                              width={audienceDemographicsAllTabs?.length > 2 ? "285px" : "183px"}
                            />
                          ) : (
                            <Box className="content-section-header">{audienceDemographicsHeaders[audienceDemographicsCurrTab]}</Box>
                          )}
                        </Box>
                      </Box>
                      <Box className="profile-discovery-audience-box-container">
                        {/* age distribution */}
                        {audienceDemographicsCurrTab === AGE && (
                          <Box>
                            <BarChartDistribution
                              width={1100}
                              height={328}
                              data={profileAnalytics?.profile?.audience?.age_distribution}
                              currentTab={AGE}
                            />
                          </Box>
                        )}

                        {/* Lanuages */}
                        {audienceDemographicsCurrTab === LANGUAGE && profileAnalytics?.profile?.audience?.languages?.length !== 0 && (
                          <Box>
                            <BarChartDistribution
                              width={1100}
                              height={328}
                              data={profileAnalytics?.profile?.audience?.languages}
                              currentTab={LANGUAGE}
                            />
                          </Box>
                        )}

                        {/* Ethncities */}
                        {audienceDemographicsCurrTab === ETHNICITY && profileAnalytics?.profile?.audience?.ethnicities?.length !== 0 && (
                          <Box>
                            <BarChartDistribution
                              width={1100}
                              height={328}
                              data={profileAnalytics?.profile?.audience?.ethnicities}
                              currentTab={ETHNICITY}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>

                    <Box
                      className={`audience-brand-affinity-container ${checkActiveSection("audience-brand-affinity-container", profileAnalytics) ? "active" : ""
                        }`}
                    >
                      {/* Audience brand affinity */}
                      {(profileAnalytics?.profile?.audience?.brand_affinity?.length !== 0 || isLoading) && (
                        <Box width={"518px"} display={"flex"} flexDirection={"column"} gap={"24px"}>
                          <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                            <Box className="content-section-header">{"Audience brand affinity"}</Box>
                            <Box className="description">{"Brands followed by the audience"}</Box>
                          </Box>
                          <LinearDistribution
                            data={profileAnalytics?.profile?.audience?.brand_affinity?.sort(function (a, b) {
                              return b.value - a.value;
                            })}
                            loading={isLoading}
                            title="Audience brand affinity"
                          />
                        </Box>
                      )}

                      {/* Audience Interests */}
                      {(profileAnalytics?.profile?.audience?.interests?.length !== 0 || isLoading) && (
                        <Box width={"518px"} display={"flex"} flexDirection={"column"} gap={"24px"} ref={followerTypesSectionRef}>
                          <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                            <Box className="content-section-header">
                              <div>
                                {"Audience interests"}{" "}
                                <Tooltip title={SEARCH_ATTRIBUTES_TOOLTIP_TEXT.audience_interest} placement="top-start">
                                  <i className="ri-information-line"></i>
                                </Tooltip>
                              </div>
                            </Box>
                            <Box className="description">{"Interests and topics that the audience posts about"}</Box>
                          </Box>
                          <LinearDistribution
                            data={profileAnalytics?.profile?.audience?.interests?.sort(function (a, b) {
                              return b.value - a.value;
                            })}
                            loading={isLoading}
                            title="Audience Interest"
                          />
                        </Box>
                      )}
                    </Box>

                    {/* Follower types */}
                    {(majorityFollowerType || isLoading) && (
                      <Box className="followers-type-container">
                        <Box className="content-section-header">{"Follower types"}</Box>
                        <div className="piechart-main-container" ref={followerTypeChartContainerRef}>
                          {isFollowerTypeChartContainerVisible && (
                            <DonutChart
                              data={profileAnalytics?.profile?.audience?.follower_types}
                              variant={"triple-disconnected"}
                              height={271}
                              width={271}
                              isActive={isFollowerTypeChartContainerVisible}
                            />
                          )}
                          <div className={"div-donut-chart-legend-profile"} style={{ flexDirection: "row" }}>
                            {profileAnalytics?.profile?.audience?.follower_types?.map((legend) => {
                              const { color, value, name } = legend;
                              return (
                                //{Chart Legends}
                                <div>
                                  <div className={"row-1"}>
                                    <svg width={16} height={16}>
                                      <circle cx={8} cy={8} r={8} fill={color} />
                                    </svg>
                                    <div className={"sub-section-heading legend-title"}>{Number(value).toFixed(1) + "%"}</div>
                                  </div>
                                  <div className={"body-r legend-subtitle"} style={{ marginTop: "8px" }}>
                                    {name}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Box>
                    )}

                    <Box>
                      {/* Audience Lookalikes */}
                      {(profileAnalytics?.profile?.audience?.lookalikes?.length !== 0 || isLoading) && (
                        <Box borderTop={"1px solid #e5e5e8"}>
                          <LookAlikeCard
                            title={"Creators with similar audience"}
                            tooltip={"Username of the profiles who have audience similar to the creator"}
                            loading={isLoading}
                            data={profileAnalytics?.profile?.audience?.lookalikes?.slice(0, 5)}
                            isAudienceLookAlike={true}
                            workplatformId={workplatformId}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </section>
              )}

              <div className="divider" />
              {/* Creator lookalikes */}
              {(profileAnalytics?.profile?.lookalikes?.length !== 0 || isLoading) && (
                <Box ref={similarCreatorsSectionRef} id="similar-creators-section">
                  <LookAlikeCard
                    title={"Similar creators"}
                    tooltip={"Username of the profiles that are similar to the creator"}
                    loading={isLoading}
                    data={profileAnalytics?.profile?.lookalikes?.slice(0, 5)}
                    workplatformId={workplatformId}
                  />
                </Box>
              )}
            </>
          )}
          <>
            {errorReason && (
              <section
                style={{
                  backgroundColor: showBasicDetails?.show ? "#fff" : "#f5f5f6",
                  borderTop: "1px solid var(--neutrals-border-grey)",
                  padding: showBasicDetails?.show ? "0px" : "32px 32px 32px 32px",
                  margin: showBasicDetails?.show ? "0px" : "0px -32px 0px -32px",
                  minHeight: showBasicDetails?.show ? "auto" : "100%",
                }}
                className="profile-error-screen-container"
              >
                {showBasicDetails?.show ? (
                  <EmptyTableScreen
                    icon={<Icons.loading_creators_detail_error />}
                    header={UPDATING_CREATORS_DETAIL_ERROR.title}
                    content={UPDATING_CREATORS_DETAIL_ERROR.desc}
                    showBasicDetails={true}
                  />
                ) : (
                  <EmptyTableScreen icon={<Icons.failed_connections />} header={errorReason} />
                )}
              </section>
            )}
          </>
          <Helmet>
            <title>{getPageTitle("CREATOR_PROFILE_PAGE", warehouse.environment.current)}</title>
          </Helmet>
        </section>
      )}
    
      <ExportPDFPopUp
        open={isExportPDFModalOpen}
        onClose={() => setIsExportPDFModalOpen(false)}
        header={`Share @${profileAnalytics?.profile.platform_username}'s profile`}
        actionButtonLabel={"Copy link"}
        actionButtonHandler={() => {
          navigator.clipboard.writeText(exportPDFLink);
          setIsExportPDFModalOpen(false);
          trackEvent(SEARCH_PROFILE_LINK_COPIED, {
            username: profileAnalytics?.profile.platform_username,
            platform: profileAnalytics?.work_platform?.name,
          });
          toast("Link copied to clipboard");
        }}
        exportPDFLink={exportPDFLink}
      />
      <ExportLoadingModal open={isPDFLoaded} title= {GENERATING_TITLE} />
      <CreateListPopUp
        open={createListPopUpState?.visible}
        onClose={() => {
          setCreateListPopUpState({ ...createListPopUpState, visible: false });
        }}
        header={CREATE_LIST_POPUP_HEADER(createListPopUpState?.isMultipleCreatorSelected, [1])}
        buttonsLabelsAndActions={CREATE_LIST_POPUP_ACTIONS_LABEL(createListPopUpState?.favoriteListCount)}
        createListPopUpState={createListPopUpState}
        setCreateListPopUpState={setCreateListPopUpState}
        onAddToCreatorListHandler={() => {
          onlyOnce.current = true;
          setIsLoading(true);
          handleProfileAnalytics();
        }}
        source={"Profile page"}
      />
      <UnlockFullAccessPopUp
        open={modalDetails.isModalVisible}
        onClose={() => {
          navigate(-1);
          setModalDetails({ ...modalDetails, isModalVisible: false });
        }}
        header={modalDetails.modalInfo.header}
        description={modalDetails.modalInfo.description}
        actionButtonLabel={"Contact us"}
        height="264px"
        padding="52px 52px 48px 48px"
        isModal
      />
    </>
  );
}

const ExportLoadingModal = ({ open, onClose, title, description }) => {
  if (!open) {
    return null;
  }
  return (
    <div className="modal">
      <Box className="export-results-overlay-loading">
        <div className="export-result-loader-container">
          <Loader className={"campaign-loader-container"} />
          <div className="export-result-loader-header">{title}</div>
        </div>
        {description ? <div className="export-result-loader-description">{description}</div> : ""}
      </Box>
    </div>
  );
};

export default CreatorDiscoveryProfilePage;