import { Table, Tooltip } from "antd";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import moment from 'moment-timezone';
import { getConnectionAttempts } from "../../api/creatorinvitations/creatorinvitations";
import {
  clickSortMsg,
  connectionsStatus,
  Connection_attempts,
  FAILEDREASONMAPPING,
  SOMETHING_WRONG_ERROR,
  sort_by_keys,
  WORKPLATFORM_ID_MAPPING,
} from "../../constants/constants";
import Icons from "../Icons/Icons";
import TableListingRecords from "../TableListingRecords/TableListingRecords";
import "./ConnectionAttemptsPopup.scss";
import IntermediateLoader from "../IntermediateLoader/IntermediateLoader";
import EmptyTableScreen from "../EmptyTableScreen/EmptyTableScreen";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { WarehouseContext } from "../../storage/context";

const page_limit = 10;
const overScan = 0;
function ConnectionAttemptsPopup({ open, onClose, name, mail }) {
  const onlyOnce = useRef(true);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotal] = useState();
  const [data, SetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [sortByState, setSortByState] = useState({
    field: sort_by_keys.created_at.key,
    sortDesc: true,
  });
  const [columns, setColumns] = useState([]);
  const observer = useRef();
  const tableRef = useRef();
  const [showTableError, setShowTableError] = useState(false);
  let { id: core_schema_user_id } = useParams();
  const { warehouse } = useContext(WarehouseContext);

  const lastEltRef = useCallback(
    (node) => {
      if (loading || !hasMore) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (hasMore && entries[0].isIntersecting) {
          const page = offset + page_limit;
          if (page < totalItems) {
            setOffset(page);
            getData(page_limit, page, false);
          }
        }
      });
      if (node) observer.current.observe(node, { once: true });
    },
    [loading, hasMore]
  );

  function generateColMapping() {
    const cols = [
      {
        title: () => {
          return (
            <Tooltip title={clickSortMsg}>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {sort_by_keys.work_platform_id.fieldName}
                {sortByState.field === sort_by_keys.work_platform_id.key && (
                  <>
                    {sortByState.sortDesc ? (
                      <Icons.down_arrow_icon alt="down_arrow_icon" />
                    ) : (
                      <Icons.down_arrow_icon alt="down_arrow_icon" style={{ transform: "rotateZ(180deg)" }} />
                    )}
                  </>
                )}
              </div>
            </Tooltip>
          );
        },
        dataIndex: "platform",
        width: "15%",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              SetData([]);
              setSortByState((prevSortState) => {
                return {
                  ...prevSortState,
                  field: sort_by_keys.work_platform_id.key,
                  sortDesc: !prevSortState.sortDesc,
                };
              });
              getData(page_limit, 0, true, sort_by_keys.work_platform_id.key, !sortByState.sortDesc);
            },
          };
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        width: "35%",
      },
      {
        title: () => {
          return (
            <Tooltip title={clickSortMsg}>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {sort_by_keys.created_at.fieldName}
                {sortByState.field === sort_by_keys.created_at.key && (
                  <>
                    {sortByState.sortDesc ? (
                      <Icons.down_arrow_icon alt="down_arrow_icon" />
                    ) : (
                      <Icons.down_arrow_icon alt="down_arrow_icon" style={{ transform: "rotateZ(180deg)" }} />
                    )}
                  </>
                )}
              </div>
            </Tooltip>
          );
        },
        dataIndex: "attempted_at",
        width: "25%",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              SetData([]);
              setSortByState((prevSortState) => {
                return {
                  ...prevSortState,
                  field: sort_by_keys.created_at.key,
                  sortDesc: !prevSortState.sortDesc,
                };
              });
              getData(page_limit, 0, true, sort_by_keys.created_at.key, !sortByState.sortDesc);
            },
          };
        },
      },
    ];

    return cols;
  }

  const Row = useCallback(
    ({ index, style }) => {
      const { email } = data[index] || {};
      if (index + 1 === data.length && index + 1 !== totalItems) {
        return (
          <div ref={lastEltRef} style={style}>
            loading... {index}
          </div>
        );
      }

      return <div style={style}>{email}</div>;
    },
    [data]
  );

  useEffect(() => {
    setColumns(generateColMapping());
  }, []);

  async function getData(limit = page_limit, offset = 0, sortUpdated, sortByField = sortByState.field, sortByDesc = sortByState.sortDesc) {
    setLoading(true);
    try {
      setColumns(generateColMapping());
      // to save prev sort by for scroll api calling
      const response = await getConnectionAttempts(warehouse, limit, offset, "", sortByDesc, core_schema_user_id, sortByField);
      const formatData = response.results.map((row, index) => {
        return {
          platform: (
            <div className="platform-image-container">
              <img className="table-platform-image" src={WORKPLATFORM_ID_MAPPING[row?.work_platform_id]?.platformLogo} />
              <div>{WORKPLATFORM_ID_MAPPING[row?.work_platform_id]?.platformName}</div>
            </div>
          ),
          status:
            row?.status !== connectionsStatus.SUCCESS ? (
              <div className="table-status-container">
                <Tooltip title={parse(FAILEDREASONMAPPING[row?.status]?.toolTipText)}>
                  <label>{FAILEDREASONMAPPING[row?.status]?.title}</label> - {FAILEDREASONMAPPING[row?.status]?.reason}
                </Tooltip>
              </div>
            ) : (
              <div className="table-status-container text-truncate">
                <Tooltip>
                  <label>{FAILEDREASONMAPPING[row?.status]?.title}</label> ({row?.username})
                </Tooltip>
              </div>
            ),
          attempted_at: moment(row.created_at).format("MMM DD, YYYY") + " at " + moment(row.created_at).format("hh:mm A"),
        };
      });
      const arr = sortUpdated ? [...formatData] : [...data, ...formatData];
      if (sortUpdated) {
        observer.current = null;
      }
      SetData(arr);
      if (totalItems !== response.total) {
        setTotal(response.total);
      }
      setLoading(false);
      setHasMore(response.total > arr.length);
    } catch (error) {
      setLoading(false);
      setShowTableError(true);
    }
  }

  useEffect(() => {
    (async () => {
      if (onlyOnce.current) {
        onlyOnce.current = false;
        await getData();
      }
    })();
  }, []);

  useEffect(() => {
    setOffset(0);
    setColumns(generateColMapping());
  }, [sortByState.sortDesc]);

  if (!open) {
    return null;
  }

  return (
    <div className="modal">
      <div className="conn-attempts-popup-form-container">
        <div className="conn-attempts-popup-header">
          <h2 className="title">{Connection_attempts}</h2>
          <label className="desc">
            {name} | {mail}
          </label>
        </div>
        <div className="conn-attempts-popup-table">
          {!showTableError ? (
            <Table
              ref={tableRef}
              className="conn-attempts-table"
              rowClassName={loading ? "my-table-loading-row" : ""}
              loading={loading ? { indicator: <IntermediateLoader /> } : loading}
              columns={columns}
              pagination={false}
              dataSource={data}
              scroll={{ y: 300 }}
              size="small"
              locale={
                loading && {
                  emptyText: <div></div>,
                }
              }
              onRow={(record, index) => {
                return {
                  className:
                    record.status.props.children.props.children[0].props.children.toLowerCase() === connectionsStatus.SUCCESS.toLowerCase() &&
                    `success-row`,
                  ref: index === data.length - 1 ? lastEltRef : null,
                };
              }}
            ></Table>
          ) : (
            <>
              {" "}
              <Box className="conn-attempt-table-error-screen">
                <Icons.caution_grey />
                <Box>
                  <Box>{SOMETHING_WRONG_ERROR.title}</Box>
                  <Box>{SOMETHING_WRONG_ERROR.message}</Box>
                </Box>
              </Box>
            </>
          )}
        </div>
        <div className="close-icon-container">
          <Icons.close_circle_fill onClick={onClose} cursor="pointer" />
        </div>
      </div>
    </div>
  );
}

export default ConnectionAttemptsPopup;
