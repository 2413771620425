import axiosInstance from "../axiosInstance";
import { currentDashboardURL, sendEmailVerificationLinkEndpoint } from "../endpoints";

const sendEmailVerificationLink = async (email) => {
  try {
    const response = await axiosInstance.post(`${currentDashboardURL}/${sendEmailVerificationLinkEndpoint}`, {
      email,
    });
    return response;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export { sendEmailVerificationLink };
