import React from "react";
import "./NavigationHeader.scss";
import Icon from "../Icons/Icons";
import { Link } from "react-router-dom";

const NavigationHeader = ({ isAuthenticated = true, isClickable = true, ...props }) => {
  return (
    <div className="navigation-header not-authenticated-header">
      {isClickable ? (
        <a href="/">
          <Icon.insight_iq_icon_with_name name="insightiq_icon_with_name" className="insightiq-header-icon" />
        </a>
      ) : (
        <Icon.insight_iq_icon_with_name name="insightiq_icon_with_name" className="insightiq-header-icon" />
      )}
    </div>
  );
};

export default NavigationHeader;
