import { useRef, useState } from "react";
import "./Dropdown.scss";
import Icons from "../Icons/Icons";
import { useEffect } from "react";
import { Creators, Developers, MEETING_SCHEDULE_LINK, Production } from "../../constants/constants";
import { Tooltip } from "antd";
import { SCHEDULE_A_CALL_CLICK, trackEvent } from "../../analytics";
import { forEach } from "lodash";
import PropTypes from "prop-types";
import { Warehouse } from "@mui/icons-material";

const Dropdown_but = ({
  products,
  icons,
  selected,
  setIsSelected,
  isComplianceFormFilled,
  isProductionKeysAvailable,
  currentTab,
  showCreatorLinkage,
  showCreatorSearch,
  warehouse,
  fromSidebar = false,
}) => {
  const [isActive, setIsActive] = useState(false);
  const isActiveRef = useRef(isActive);

  isActiveRef.current = isActive;

  function hideDropDownOnOuterClick(ev) {
    if (isActiveRef.current === false) {
      return;
    }
    // checks if click evt is within dropdown container or not
    // const dropdownELT = document.querySelector(".dropdown");
    var elements = document.getElementsByClassName("dropdown");
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].contains(ev.target)) {
        return;
      }
    }
    setIsActive(false);
    // if (!dropdownELT.contains(ev.target)) {
    //   setIsActive(false);
    // }
  }

  useEffect(() => {
    document.addEventListener("click", hideDropDownOnOuterClick);

    return () => {
      document.removeEventListener("click", hideDropDownOnOuterClick);
    };
  }, []);
  return (
    <div className="dropdown">
      <Tooltip
        title={products[0].tooltip ? products.map((product) => (product.key === selected ? product.tooltip : null)) : selected}
        placement="right"
      >
        <div
          onClick={(e) => {
            setIsActive(!isActive);
          }}
          className="dropdown-btn"
          style={{ borderBottom: isActive && "0px" }}
        >
          <div className="dropDown_key">
            {icons && products.map((product) => (product.key === selected ? product.icon : null))}
            {selected.charAt(0).toUpperCase() + selected.slice(1)}
          </div>
          {isActive ? (
            <i className="ri-arrow-up-s-line" style={{ fontSize: "medium" }}></i>
          ) : (
            <i className="ri-arrow-down-s-line" style={{ fontSize: "medium" }}></i>
          )}
        </div>
      </Tooltip>
      <div className="dropdown-content" style={{ display: isActive ? "block" : "none" }}>
        {products.map(
          (product, index) =>
            (product.key ? product.key : product) !== selected && (
              <div
                onClick={(e) => {
                  if (!fromSidebar) {
                    setIsSelected(e.target.textContent);
                    setIsActive(!isActive);
                  } else {
                    setIsSelected(
                      product.key === Production
                        ? Production
                        : warehouse.environment.available.includes(e.target.textContent.toLowerCase())
                        ? e.target.textContent
                        : selected
                    );
                    setIsActive(!isActive);
                    if (product.key === Production) {
                      if (currentTab === Developers && !isProductionKeysAvailable) {
                        trackEvent(SCHEDULE_A_CALL_CLICK, { link: MEETING_SCHEDULE_LINK, source: "PRODUCTION_DROPDOWN" });
                        window.open(MEETING_SCHEDULE_LINK, "_blank");
                      } else if (currentTab === Creators && !showCreatorLinkage && !showCreatorSearch) {
                        trackEvent(SCHEDULE_A_CALL_CLICK, { link: MEETING_SCHEDULE_LINK, source: "PRODUCTION_DROPDOWN" });
                        window.open(MEETING_SCHEDULE_LINK, "_blank");
                      }
                    }
                  }
                }}
                className="item"
                key={index}
              >
                <Tooltip title={product.tooltip ? product.tooltip : product.key} placement="right">
                  <div className="dropDown_key">
                    {icons && product.icon}
                    {product.key ? product.key.charAt(0).toUpperCase() + product.key.slice(1) : product}
                    {currentTab === Developers
                      ? product.key &&
                        product.key === Production &&
                        !isProductionKeysAvailable && <div className="prodformState">{!isComplianceFormFilled ? `Request access` : `Requested`}</div>
                      : product.key &&
                        product.key === Production &&
                        !showCreatorLinkage &&
                        !showCreatorSearch && <div className="prodformState">{!isComplianceFormFilled ? `Request access` : `Requested`}</div>}
                  </div>
                </Tooltip>
              </div>
            )
        )}
      </div>
    </div>
  );
};

Dropdown_but.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      tooltip: PropTypes.string,
    })
  ),
  icons: PropTypes.bool,
  selected: PropTypes.string,
  setIsSelected: PropTypes.func,
};
export default Dropdown_but;
