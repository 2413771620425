import { Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import MinMaxDropdownFilter from "../MinMaxDropdownFilter/MinMaxDropdownFilter";
import SliderFilter from "../SliderFilter/SliderFilter";
import "./MinMaxDropdownSlider.scss";
import PropTypes from "prop-types";

const MinMaxDropdownSlider = ({
  minValuesOptions,
  maxValuesOptions,
  defaultMinValue,
  defaultMaxValue,
  getFilterValue,
  defaultSliderValue,
  steps,
  filterKey,
}) => {
  const [min, setMin] = useState(defaultMinValue);
  const [max, setMax] = useState(defaultMaxValue);
  const [selectedSliderValue, setSelectedSliderValue] = useState(defaultSliderValue);

  const minRef = useRef(min);
  const maxRef = useRef(max);
  const selectedSliderValueRef = useRef(selectedSliderValue);
  minRef.current = min;
  maxRef.current = max;
  selectedSliderValueRef.current = selectedSliderValue;

  // useEffect(() => {
  //   if (getFilterValue) getFilterValue({ max: max, min: min, step: selectedSliderValue });
  // }, [min, max, selectedSliderValue]);

  return (
    <Box className="min-max-dropdown-filter-container">
      <MinMaxDropdownFilter
        minValuesOptions={minValuesOptions}
        maxValuesOptions={maxValuesOptions}
        defaultMaxValue={defaultMaxValue}
        defaultMinValue={defaultMinValue}
        getFilterValue={(values) => {
          if (values.min !== minRef.current) {
            setMin(values.min);
            if (getFilterValue) getFilterValue({ min: values.min, max: maxRef.current, percentage_value: selectedSliderValueRef.current });
          }

          if (values.max !== maxRef.current) {
            setMax(values.max);
            if (getFilterValue) getFilterValue({ max: values.max, min: minRef.current, percentage_value: selectedSliderValueRef.current });
          }
        }}
      />
      {(min !== "" || max !== "") && (
        <Box
          onClick={(e) => e.stopPropagation()}
          mt={3}
          sx={{
            "& > :not(:last-child)": { marginRight: 1 },
            "& > *": { marginBottom: 1 },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SliderFilter
            defaultSliderValue={selectedSliderValue}
            steps={steps}
            sliderFilterHeader={{ name: min && max ? min + " - " + max : min ? " > " + min : max ? " < " + max : "" }}
            filterKey={filterKey}
            getFilterValue={(value) => {
              setSelectedSliderValue(value);
              if (getFilterValue) getFilterValue({ min: minRef.current, max: maxRef.current, percentage_value: value });
            }}
          />
        </Box>
      )}
    </Box>
  );
};

MinMaxDropdownSlider.propTypes = {
  minValuesOptions: PropTypes.array,
  maxValuesOptions: PropTypes.array,
  defaultMinValue: PropTypes.string,
  defaultMaxValue: PropTypes.string,
  getFilterValue: PropTypes.func,
  defaultSliderValue: PropTypes.number,
  steps: PropTypes.array,
};

export default MinMaxDropdownSlider;
