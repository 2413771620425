import { Avatar, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVertOutlined } from "@mui/icons-material";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal } from "antd";
import "./MenuButton.scss";

import Icons from "../Icons/Icons";
import {
  REMOVE_MODAL_BUTTON_TITLE,
  REMOVE_MODAL_MESSAGE,
  REMOVE_MODAL_TITLE,
  REMOVE_MODAL_WARNING_MESSAGE,
  REMOVE_TEXT,
} from "../../constants/constants";

export default function MenuButton({ onRemoveClick, imageUrl, userName }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function handleClose(e) {
    e.stopPropagation();
    setAnchorEl(null);
  }

  function onClick(e) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  function ModalContent() {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="stretch" className={"remove-modal-body"}>
        <Grid item>
          <span className={"text-remove-modal-message"}>{REMOVE_MODAL_MESSAGE}</span>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={"remove-modal-identity-info"}>
            <Grid item>
              <Avatar alt="Profile Pic" src={imageUrl} />
            </Grid>
            <Grid item>
              <span className={"text-user-name"}>{userName}</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={"remove-modal-warning-grid"}>
          <div className={"remove-modal-warning"}>
            <Icons.caution />
            <p className={"text-remove-modal-warning"}>{REMOVE_MODAL_WARNING_MESSAGE}</p>
          </div>
        </Grid>
      </Grid>
    );
  }

  function onRemoveButtonClick(e) {
    handleClose(e);
    Modal.warn({
      closable: true,
      closeIcon: <Icons.close_circle_fill />,
      content: <ModalContent />,
      icon: false,
      okButtonProps: { danger: true, className: "btn-remove-account" },
      okText: REMOVE_TEXT,
      onOk: async () => {
        try {
          await onRemoveClick();
        } catch (e) {
          console.error(`MenuButton: Account remove error = ${e}`);
          throw e;
        }
      },
      title: <span className={"remove-modal-title"}>{REMOVE_MODAL_TITLE}</span>,
    });
  }

  return (
    <>
      <IconButton onClick={onClick} size={"small"} className={"icon-button"}>
        <MoreVertOutlined fontSize={"small"} />
      </IconButton>
      <Menu id={"menu-remove-account"} className={"menu-remove-account"} anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={onRemoveButtonClick}>
          <div className={"remove-account-button"}>
            <i className="ri-delete-bin-2-fill"></i>
            {REMOVE_MODAL_BUTTON_TITLE}
          </div>
        </MenuItem>
      </Menu>
    </>
  );
}

MenuButton.propTypes = { onRemoveClick: PropTypes.func };
