const CLIENT_ID_COPIED = "Client ID copied";
const CLIENT_SECRET_COPIED = "Secret copied";
const CLIENT_SECRET_ROTATED = "New secret generated";
const CLIENT_SECRET_DELETED = "Old secret deleted";
const WEBHOOK_DELETED = "Webhook deleted";
const WEBHOOK_CREATED = "Webhook created";
const WEBHOOK_EDIT_SUCCESS = "Webhook changes saved";
const SET_NEW_PASSWORD_SUCCESS = "Password changed successfully, please sign in to continue!";
const EMAIL_VERIFIED_LOGIN_SUCCESSFUL_AUTO_LOGIN = "Email verified successfully. Welcome to insightIQ!";
const EMAIL_VERIFIED_LOGIN_SUCCESSFUL = "Email verified successfully. Please sign in to continue!";
const EMAIL_RESEND_MESSAGE = "Email resent successfully!";
const TOKEN_EXPIRED = "Session expired, please sign in to continue!";
export {
  CLIENT_ID_COPIED,
  CLIENT_SECRET_COPIED,
  CLIENT_SECRET_ROTATED,
  CLIENT_SECRET_DELETED,
  WEBHOOK_DELETED,
  WEBHOOK_CREATED,
  WEBHOOK_EDIT_SUCCESS,
  SET_NEW_PASSWORD_SUCCESS,
  EMAIL_VERIFIED_LOGIN_SUCCESSFUL,
  EMAIL_RESEND_MESSAGE,
  TOKEN_EXPIRED,
  EMAIL_VERIFIED_LOGIN_SUCCESSFUL_AUTO_LOGIN,
};
