import { CreatorUtmLinksGrid, PrimaryButton } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import {
    campaignPageTabValues,
    UTM_LINK_SUBTITLE_SUCCESS,
    UTM_LINK_TITLE_MANAGE,
    UTM_LINK_TITLE_SUCCESS
} from "../../constants/campaignTrackingConstants";
import { Colors } from "../../styles/colors";
import { SET_CAMPAIGN_ACTIVE_TAB } from "../../storage/reducers/types";
import { useContext, useEffect, useState } from "react";
import { CampaignTrackingContext } from "../../storage/context";

export default function CreatorUtmLinksPage() {
    const navigate = useNavigate();
    const { campaignId } = useParams()
    const { dispatchCampaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const [isManageUtmEntry, setManageUtmEntry] = useState(false);

    useEffect(() => {

        let pathArray = window.location.pathname.split("/")
        if ((pathArray[3] === "utm-links")) {
            setManageUtmEntry(false);
        } else if ((pathArray[3] === "manage-utm-links")) {
            setManageUtmEntry(true);
        } else {
            navigate("/creators/campaigns");
        }
    }, [window.location]);

    return (
        <div>
            {isManageUtmEntry && <i className="ri-arrow-left-line back-icon" onClick={() => {
                navigate(-1);
            }}></i>}
            <div className='title' style={{ marginTop: 41 }}>
                {isManageUtmEntry ? UTM_LINK_TITLE_MANAGE : UTM_LINK_TITLE_SUCCESS}
            </div>
            <div className='body-r'
                 style={{ color: Colors.neutralsSecondaryGrey, marginTop: 16 }}>{UTM_LINK_SUBTITLE_SUCCESS}</div>
            <div style={{ marginTop: 36 }}>
                <CreatorUtmLinksGrid campaignId={campaignId} />
            </div>
            <div className={'add-creators-to-campaign-container'}>
                <PrimaryButton className={'add-creators-to-campaign-button'}
                               label={<span className={'body-m'}
                                            style={{ color: Colors.white }}>Next <i
                                   className="ri-arrow-right-line next-arrow-icon"></i> </span>}
                               onClick={() => {
                                   dispatchCampaignTrackingWarehouse({
                                       type: SET_CAMPAIGN_ACTIVE_TAB,
                                       payload: campaignPageTabValues.measureROI,
                                   });
                                   navigate(`/creators/campaigns/creator-cost-page/${campaignId}`)
                               }}
                />
            </div>
        </div>
    )
}
