import { convertTimeToLocale, isEmpty } from "../../utils/util";
import { Tooltip } from "@material-ui/core";
import { ContextualMenu, Icons, UnlockFullAccessPopUp } from "../index";
import {
    getCampaignDateTooltip,
    NUMBER_CREATOR_ACCOUNTS,
    unlockAccessPopupDetails
} from "../../constants/campaignTrackingConstants";
import React, { useState } from "react";
import "./CampaignPageHeader.scss";
import { DATE_FORMAT } from "../../constants/constants";
import { formatNumber } from "../../utils/profileHelpers";
import { trackEvent, TRACKING_UPGRADE_POPUP_OPENED } from "../../analytics";

export default function CampaignPageHeader({
                                               campaignData,
                                               campaignBrandName,
                                               campaignViewTopMenu,
                                               campaignProfileCount,
                                               onBack,
                                           }) {
    const [modalDetails, setModalDetails] = useState({
        isModalVisible: false,
        modalInfo: "",
    });

    function onClickHeaderContextualMenu() {
        if (campaignData?.is_trial) {
            setModalDetails({
                ...modalDetails,
                isModalVisible: true,
                modalInfo: unlockAccessPopupDetails.editCampaign,
            })
            trackEvent(TRACKING_UPGRADE_POPUP_OPENED, { trigger_event: "editCampaign" })
        }
        return campaignData?.is_trial;
    }

    function PipeSeparator() {
        return <p style={{ margin: "0px 8px 0px 8px" }}>|</p>;
    }

    return (
        <div className="campaign-page-main-header">
            <div className="campaign-page-header">
                <div>
                    <i className="ri-arrow-left-line back-icon" onClick={onBack}></i>
                </div>
                <div>
                    <div className="campaign-info-container">
                        <div className="campaign-tracking-icon">
                            <Icons.campaign_icon_white_bg/>
                        </div>
                        <div>
                            <div className="title">{campaignData?.name}</div>
                            <div className="sub-section-heading" style={{ textTransform: "capitalize" }}>
                                {campaignBrandName}
                            </div>
                            <div style={{ marginTop: "16px" }} className="cost-date-info body-m">
                                {!isEmpty(campaignData?.cost) ?
                                    <i className="ri-money-dollar-circle-line large-header-icon"></i> : null}
                                {!isEmpty(campaignData?.cost) ? <>USD {formatNumber(campaignData?.cost)}&nbsp;
                                    <PipeSeparator/> </> : null}
                                <i className="ri-calendar-line large-header-icon"></i>
                                {convertTimeToLocale(campaignData?.start_date, DATE_FORMAT)} - {convertTimeToLocale(campaignData?.end_date, DATE_FORMAT)}
                                <Tooltip placement="right-end"
                                         title={getCampaignDateTooltip(campaignData?.start_date, campaignData?.created_at)}>
                                    <div>
                                        {" "}
                                        <i className="ri-information-line"></i>{" "}
                                    </div>
                                </Tooltip>
                                {campaignProfileCount === null || campaignProfileCount === undefined || campaignProfileCount === 0 ? null : <>
                                    <PipeSeparator/> <i
                                    className="ri-group-line large-header-icon"></i>{NUMBER_CREATOR_ACCOUNTS(campaignProfileCount)}
                                </>}
                            </div>
                        </div>
                    </div>
                    <div className="body-m">
                        {/* <i className="ri-share-forward-line"></i> {exportCSVReport} */}
                        {
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {campaignViewTopMenu && (
                                    <ContextualMenu
                                        onClickOverride={onClickHeaderContextualMenu}
                                        menuItems={campaignViewTopMenu}
                                    />
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <UnlockFullAccessPopUp
                open={modalDetails.isModalVisible}
                onClose={() => {
                    setModalDetails({ ...modalDetails, isModalVisible: false });
                }}
                header={modalDetails.modalInfo.header}
                description={modalDetails.modalInfo.description}
                actionButtonLabel="Contact us"
                height="264px"
                padding="52px 52px 48px 48px"
                isModal
            />
        </div>
    );
}
