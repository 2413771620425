import React, { useContext, useEffect, useState } from "react";
import { tenantInfoApi, webhooksApi } from "../api";
import { UserContext, WarehouseContext } from "../storage/context";
import { ADD_WEBHOOKS, FETCH_WEBHOOKS } from "../storage/reducers/types";

const useFetchWebhooks = () => {
  const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
  const tenantId = warehouse.tenantInfo[0]?.id;
  const clientId = warehouse.tenantInfo[0]?.apps[0]?.id;
  const environment = warehouse.environment.current.toUpperCase();

  useEffect(() => {
    if (warehouse.tenantInfo.length === 0) {
      return;
    }
  }, [warehouse]);

  const fetchWebhooks = async () => {
    try {
      dispatchWarehouse({ type: FETCH_WEBHOOKS });
      if (!tenantId || !clientId)
        // const tenant = await tenantInfoApi.getTenantInfo(environment);
        // let tenantId = tenant[0].id;
        // let clientId = tenant[0]?.apps[0].id;
        return;
      const data = await webhooksApi.getAllWebhooks(tenantId, clientId, environment);
      dispatchWarehouse({ type: ADD_WEBHOOKS, payload: data });
      // return data;
      // setWebhooksData(data);
    } catch (e) {
      console.log(e);
    }
  };
  return { fetchWebhooks };
};

export default useFetchWebhooks;
