import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./CustomDropDown.scss";
import { isEmpty } from "lodash";
import { FILTER_SORTING_MAP } from "../../constants/constants";
export default function CustomDropDown({ options, defaultValue, getFilterValue, label, filterKey, prefixLabel = "", type }) {
  const [value, setValue] = useState(defaultValue);
  const elementRef = useRef(null);
  const onlyOnce = useRef(true);
  const handleChange = (newVal) => {
    setValue(newVal);
    if (getFilterValue) getFilterValue(newVal);
  };
  useEffect(() => {
    if (elementRef.current && onlyOnce.current) {
      // Your script here
      selectScript(handleChange, elementRef.current, prefixLabel, value, label);
      onlyOnce.current = false;
    }
  }, [elementRef.current]);
  return (
    <FormControl sx={{ minWidth: 84, display: "flex", margin: 0 }}>
      <div className="custom-select" ref={elementRef}>
        <select value={value} onChange={() => {}}>
          {/* <option key="" value="">
            {label || "Select"}
          </option> */}
          {options &&
            options.map((opt) => {
              return opt.value === null || opt.value === "null" ? (
                <option key={opt.name} value={""}>
                  {opt.name}
                </option>
              ) : (
                <option key={opt.name} value={opt.value}>
                  {opt.name}
                </option>
              );

              // return (
              //   <option key={opt.name} value={opt.value}>
              //     {opt.name}
              //   </option>
              // );
            })}
        </select>
      </div>
    </FormControl>
  );
}

CustomDropDown.propTypes = {
  options: PropTypes.array,

  getFilterValue: PropTypes.func,
  label: PropTypes.string,
};

function selectScript(handleChange, ref, prefixLabel, value, label) {
  // console.log("render", ref);
  var x, i, j, l, ll, selElmnt, a, b, c;
  /* Look for any elements with the class "custom-select": */
  // x = document.getElementsByClassName("custom-select");
  x = [ref];
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    ll = selElmnt.length;
    /* For each element, create a new DIV that will act as the selected item: */
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    if(label && value){
      let containerDiv = document.createElement("DIV");
      containerDiv.setAttribute("class", "dropdown-container-div");
      let placeholderDiv = document.createElement("DIV");
      placeholderDiv.setAttribute("class", "dropdown-placeholder-div");
      let contentDiv = document.createElement("DIV");
      contentDiv.setAttribute("class", "dropdown-content-div");
      placeholderDiv.innerHTML = label;
      contentDiv.innerHTML = prefixLabel + selElmnt.options[selElmnt.selectedIndex].innerHTML;
      containerDiv.appendChild(placeholderDiv);
      containerDiv.appendChild(contentDiv);
      a.appendChild(containerDiv)
    }else{
      let defaultSelectedValue = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      if(defaultSelectedValue === FILTER_SORTING_MAP.FROM || defaultSelectedValue === FILTER_SORTING_MAP.TO){
        a.classList.add("custom-select-selected");
      }
      a.innerHTML = prefixLabel + defaultSelectedValue;
    }
    if (!selElmnt.options[selElmnt.selectedIndex].value) {
      a.classList.add("placeholder");
    }
    x[i].appendChild(a);
    /* For each element, create a new DIV that will contain the option list: */
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 0; j < ll; j++) {
      /* For each option in the original select element,
    create a new DIV that will act as an option item: */

      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      //c.setAttribute("dataname", selElmnt.options[j].text);
      c.setAttribute("datavalue", selElmnt.options[j].value);
      c.addEventListener("click", function (e) {
        /* When an item is clicked, update the original select box,
        and the selected item: */
        e.stopPropagation();

        var y, i, k, s, h, sl, yl;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = prefixLabel + this.innerHTML;
            const selectedValue = this.getAttribute("datavalue");
            if (!selectedValue) {
              h.classList.add("placeholder");
              if(this.innerHTML === FILTER_SORTING_MAP.FROM || this.innerHTML === FILTER_SORTING_MAP.TO){
                h.classList.add("custom-select-selected");
              }
            } else {
              if(label){
                h.innerHTML = '';
                let containerDiv = document.createElement("DIV");
                containerDiv.setAttribute("class", "dropdown-container-div");
                let placeholderDiv = document.createElement("DIV");
                placeholderDiv.setAttribute("class", "dropdown-placeholder-div");
                placeholderDiv.innerHTML = label;
                let contentDiv = document.createElement("DIV");
                contentDiv.setAttribute("class", "dropdown-content-div");
                contentDiv.innerHTML = prefixLabel + this.innerHTML;
                containerDiv.appendChild(placeholderDiv);
                containerDiv.appendChild(contentDiv);
                h.appendChild(containerDiv);
              }
              h.classList.remove("placeholder");
            }
            // handleChange(isNaN(selectedValue) ? selectedValue : Number(selectedValue));
            handleChange(selectedValue);
            y = this.parentNode.getElementsByClassName("same-as-selected");
            yl = y.length;
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function (e) {
      /* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
      this.nextSibling.childNodes.forEach((node) => {
        if (value !== "") {
          if (node.getAttribute("datavalue") === value) {
            node.setAttribute("class", "same-as-selected");
          }
        }
      });
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }

  function closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document,
  except the current select box: */
    var x,
      y,
      i,
      xl,
      yl,
      arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt === y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }

  /* If the user clicks anywhere outside the select box,
then close all select boxes: */
  document.addEventListener("click", closeAllSelect);
}
