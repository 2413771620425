import React, { useState } from "react";
import CustomDropDown from "../CustomDropDown/CustomDropDown";
import { Box } from "@mui/material";
import SliderFilter from "../SliderFilter/SliderFilter";
import PropTypes from "prop-types";

const DropdownSlider = ({
  options,
  defaultDropDownValue,
  defaultSliderValue,
  steps,
  showSliderOnDropdownValues = undefined,
  getFilterValue,
  filterKey,
  label
}) => {
  const [selectedValue, setSelectedValue] = useState({
    selected: defaultDropDownValue,
    step: defaultSliderValue,
  });

  const handleChange = (dropdownvalue, slidervalue) => {
    if (dropdownvalue !== undefined) {
      const updatedValue = { ...selectedValue, selected: dropdownvalue, step: defaultSliderValue };
      setSelectedValue(updatedValue);
      if (getFilterValue) getFilterValue({ selected_value: updatedValue.selected, percentage_value: updatedValue.step });
    }
    if (slidervalue !== undefined) {
      const updatedValue = { ...selectedValue, step: slidervalue };
      setSelectedValue(updatedValue);
      if (getFilterValue) getFilterValue({ selected_value: updatedValue.selected, percentage_value: updatedValue.step });
    }
  };

  return (
    <div className="dropdown-slider">
      <CustomDropDown defaultValue={defaultDropDownValue} getFilterValue={(value) => handleChange(value, undefined)} options={options} label={label} />
      {selectedValue?.selected && (showSliderOnDropdownValues ? showSliderOnDropdownValues.includes(selectedValue.selected) : true) && (
        <Box
          onClick={(e) => e.stopPropagation()}
          mt={3}
          sx={{
            "& > :not(:last-child)": { marginRight: 1 },
            "& > *": { marginBottom: 1 },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SliderFilter
            getFilterValue={(value) => handleChange(undefined, value)}
            defaultSliderValue={selectedValue.step}
            sliderFilterHeader={{ name: selectedValue?.selected }}
            sx={{ marginTop: "12px" }}
            steps={steps}
            filterKey={filterKey}
          />
        </Box>
      )}
    </div>
  );
};

export default DropdownSlider;

DropdownSlider.propTypes = {
  options: PropTypes.array,
};
