import React, { useState, useEffect, useContext, useRef } from "react";
import "./CreateListPopUp.scss";
import Icons from "../Icons/Icons";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { Avatar, Box, TextField } from "@material-ui/core";
import CheckBox from "../CheckBox/CheckBox";
import ExportCSVPopUp from "../ExportCSVPopUp/ExportCSVPopUp";
import {
  addCreatorToFavoriteList,
  addCreatorToList,
  createList,
  deleteCreatorsFromFavourites,
  deleteCreatorsFromLists,
  getAllCreatorList,
  getAllCreatorsInList,
  getAllFavouriteCreators,
} from "../../api/creatorDiscoveryAPI/creatorDiscoveryAPI";
import { WarehouseContext } from "../../storage/context";
import { isEmpty } from "../../utils/util";
import ToastMessage from "../ToastMessage/ToastMessage";
import { Skeleton } from "@mui/material";
import {
  ADD_LIST_TOAST_MESSAGE_DURATION,
  dublicateListError,
  maximumCharError,
  handleAddListLabel,
  listNameText,
  favourite,
} from "../../constants/creatorDiscovery";
import { CREATOR_ADDED_TO_LIST, NEW_LIST_CREATED, trackEvent } from "../../analytics";

const CreateListPopUp = ({
  open,
  onClose,
  header,
  buttonsLabelsAndActions,
  createListPopUpState,
  setCreateListPopUpState,
  onAddToCreatorListHandler,
  source,
}) => {
  const {
    full_name,
    isVerified,
    image_url,
    isCustomListCreated,
    customList,
    profile_url,
    workplatform_id,
    platform_username,
    defaultSelectedList,
    isMultipleCreatorSelected,
    multipleSelectedCreators,
    isCreatorAlreadyPresentInList,
  } = createListPopUpState;
  let initialSelectedList = [];
  if (!isEmpty(defaultSelectedList)) {
    defaultSelectedList.forEach((item) => {
      const foundObject = customList.find((obj) => obj.name === item.name);
      if (foundObject) {
        initialSelectedList.push(foundObject?.name);
      }
    });
  }
  const [selectedItems, setSelectedItems] = useState(initialSelectedList);
  const [filteredList, setFilteredList] = useState(customList);
  const [isAddListNamePopUpVisible, setIsAddListNamePopUpVisible] = useState(false);
  const [listName, setListName] = useState("");
  const [listNameError, setListNameError] = useState({
    hasError: false,
    errorMessage: "",
  });
  const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
  const [loader, setLoader] = useState(true);
  const [isCreatorListLoading, setIsCreatorListLoading] = useState(false);
  const [openToast, setOpenToast] = useState({
    open: false,
    message: "",
    duration: ADD_LIST_TOAST_MESSAGE_DURATION,
    type: "",
  });
  const [searchText, setSearchText] = useState("");
  const onlyOnce = useRef(true);

  useEffect(() => {
    setSelectedItems(initialSelectedList);
    setFilteredList(customList);
  }, [customList]);

  useEffect(() => {
    if (open && onlyOnce.current) {
      setLoader(true);
      getAllCustomLists();
      onlyOnce.current = false;
    }
  }, [open]);

  // To get custom lists
  const getAllCustomLists = async () => {
    setLoader(true);
    let creatorList = await getAllCreatorList();
    if (creatorList.data.length > 0) {
      creatorList.data.unshift({
        name: "Favorites",
        count_of_creators: creatorList.favourite_creator_count,
      });
    }

    // To check if creator is already present in list
    if (isCreatorAlreadyPresentInList) {
      let listInWhichCreatorPresent = [];
      let creatorListWithSelectedCreator = await getAllCreatorList(platform_username, workplatform_id);
      let creatorsInFavoriteList = await getAllFavouriteCreators();
      let isCreatorPresentInFavourites = creatorsInFavoriteList?.data?.filter((creator) => {
        return creator?.platform_username === platform_username;
      });
      if (isCreatorPresentInFavourites.length > 0) {
        creatorListWithSelectedCreator.data.unshift({
          name: "Favorites",
          count_of_creators: creatorListWithSelectedCreator.favourite_creator_count,
        });
      }
      listInWhichCreatorPresent = listInWhichCreatorPresent.concat(creatorListWithSelectedCreator.data);

      setCreateListPopUpState({
        ...createListPopUpState,
        isCustomListCreated: creatorList.data.length > 0,
        favoriteListCount: creatorList.favourite_creator_count,
        customList: creatorList.data,
        defaultSelectedList: listInWhichCreatorPresent,
        isCreatorAlreadyPresentInList: listInWhichCreatorPresent.length > 0,
      });
    } else {
      setCreateListPopUpState({
        ...createListPopUpState,
        isCustomListCreated: creatorList.data.length > 0,
        favoriteListCount: creatorList.favourite_creator_count,
        customList: creatorList.data,
      });
    }
    setLoader(false);
  };
  
  const handleListsFilterSearch = (e) => {
    if (e.target.value === "") {
      setFilteredList(customList);
      return;
    }
    const filteredValues = customList.filter((item) => item?.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    setFilteredList(filteredValues);
    setSearchText(e.target.value);
  };

  const handleRowClick = (idx, listItem) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(listItem?.name)) {
        return prevSelectedItems.filter((item) => item !== listItem?.name);
      } else {
        return [...prevSelectedItems, listItem?.name];
      }
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast({
      open: false,
      message: "",
      duration: ADD_LIST_TOAST_MESSAGE_DURATION,
      type: "success",
    });
  };

  const handleAddSingleCreatorToMultipleLists = async () => {
    const selectedValues = [];
    selectedItems.forEach((item) => {
      const foundObject = customList.find((obj) => obj.name === item);
      if (foundObject) {
        selectedValues.push(foundObject);
      }
    });
    const payload = {
      data: [],
    };
    if (isMultipleCreatorSelected) {
      multipleSelectedCreators.forEach((item) => {
        payload.data = [
          ...payload.data,
          {
            platform_username: item.platformUsername,
            full_name: item.full_name,
            is_verified: item.is_verified,
            work_platform_id: item.workPlatformId,
            profile_url: item.profileImg,
            image_url: item.image_url,
          },
        ];
      });
    } else {
      payload.data = [
        {
          platform_username: platform_username,
          full_name: full_name,
          is_verified: isVerified,
          work_platform_id: workplatform_id,
          profile_url: profile_url,
          image_url: image_url,
        },
      ];
    }
    let nameOfListsInwhichCreatorsAdded = [];
    const promises = selectedValues.map(async (item) => {
      try {
        setIsCreatorListLoading(true);
        if (item?.name === "Favorites") {
          const response = await addCreatorToFavoriteList({ warehouse, payload });
          if (response.status === 200) {
            nameOfListsInwhichCreatorsAdded.push(favourite);
            return true;
          }
        } else {
          const response = await addCreatorToList({ warehouse, listId: item?.id, payload });
          if (response.status === 200) {
            nameOfListsInwhichCreatorsAdded.push(item?.name);
            return true;
          }
        }
      } catch (err) {
        setIsCreatorListLoading(false);
        console.log(err);
        return false;
      }
    });

    if (isCreatorAlreadyPresentInList) {
      const perviousSelectedList = defaultSelectedList.filter((item) => {
        return !selectedValues.find((obj) => obj.name === item.name);
      });
      perviousSelectedList.map(async (list) => {
        if (list?.name === "Favorites") {
          let creatorsInFavoriteList = await getAllFavouriteCreators({
            warehouse,
          });
          let isCreatorPresentInList = creatorsInFavoriteList?.data?.filter((creator) => {
            return creator?.platform_username === platform_username;
          });
          if (isCreatorPresentInList.length > 0) {
            await deleteCreatorsFromFavourites({ warehouse, creatorIds: [isCreatorPresentInList[0].id] });
          }
        } else {
          let creatorsInList = await getAllCreatorsInList({ warehouse, listId: list.id });
          let isCreatorPresentInList = creatorsInList?.data?.filter((creator) => {
            return creator?.platform_username === platform_username;
          });
          if (isCreatorPresentInList.length > 0) {
            await deleteCreatorsFromLists({ warehouse, listId: list.id, creatorIds: [isCreatorPresentInList[0].id] });
          }
        }
      });
    }

    try {
      const results = await Promise.all(promises);
      // Check if all API calls were successful (assuming true indicates success)
      if (results.every((result) => result === true)) {
        trackEvent(CREATOR_ADDED_TO_LIST, {
          lists_in_which_creator_added: nameOfListsInwhichCreatorsAdded,
          count: nameOfListsInwhichCreatorsAdded.length,
          source: source,
        });
        setIsCreatorListLoading(false);
        onClose();
        onlyOnce.current = true;
        onAddToCreatorListHandler();
        setCreateListPopUpState({});
        setListName("");
        setSearchText("");
        setTimeout(() => {
          onOpenToast(`Added to ${selectedValues.length} ${selectedValues.length > 1 ? "lists" : "list"}`);
        }, ADD_LIST_TOAST_MESSAGE_DURATION);
      } else {
        // Handle the case where at least one API call failed
        setIsCreatorListLoading(false);
        console.log("One or more API calls failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddCreatorToFavoriteList = async () => {
    const payload = {
      data: [
        {
          platform_username: platform_username,
          full_name: full_name,
          is_verified: isVerified,
          work_platform_id: workplatform_id,
          profile_url: profile_url,
          image_url: image_url,
        },
      ],
    };
    try {
      const response = await addCreatorToFavoriteList({ warehouse, payload });
      if (response.status === 200 && !isEmpty(response.data.data)) {
        trackEvent(CREATOR_ADDED_TO_LIST, {
          lists_in_which_creator_added: [favourite],
          count: 1,
          source: source,
        });
        onClose();
        onlyOnce.current = true;
        onAddToCreatorListHandler();
        setCreateListPopUpState({});
        setListName("");
        setSearchText("");
        setTimeout(() => {
          onOpenToast("Added to Favorites");
        }, ADD_LIST_TOAST_MESSAGE_DURATION);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onOpenToast = async (message) => {
    setOpenToast({
      open: true,
      message: message,
      duration: ADD_LIST_TOAST_MESSAGE_DURATION,
      type: "success",
    });
  };

  const handleCreateNewListAndAddCreatorToList = async () => {
    try {
      setIsCreatorListLoading(true);
      const createNewListResponse = await createList({
        warehouse,
        payload: {
          title: listName,
        },
      });
      const payload = {
        data: [],
      };
      if (createNewListResponse.status === 200 && !isEmpty(createNewListResponse.data)) {
        trackEvent(NEW_LIST_CREATED, { source: source === "Search results page" ? "Creator list popup" : source, name: listName });
        if (isMultipleCreatorSelected) {
          multipleSelectedCreators.forEach((item) => {
            payload.data = [
              ...payload.data,
              {
                platform_username: item.platformUsername,
                full_name: item.full_name,
                is_verified: item.is_verified,
                work_platform_id: item.workPlatformId,
                profile_url: item.profileImg,
                image_url: item.image_url,
              },
            ];
          });
        } else {
          payload.data = [
            {
              platform_username: platform_username,
              full_name: full_name,
              is_verified: isVerified,
              work_platform_id: workplatform_id,
              profile_url: profile_url,
              image_url: image_url,
            },
          ];
        }
        const response = await addCreatorToList({ warehouse, listId: createNewListResponse?.data?.id, payload });
        if (response.status === 200 && !isEmpty(response.data.data)) {
          trackEvent(CREATOR_ADDED_TO_LIST, {
            lists_in_which_creator_added: [listName],
            count: 1,
            source: source,
          });
          setIsCreatorListLoading(false);
          setListName("");
          setIsAddListNamePopUpVisible(false);
          onAddToCreatorListHandler();
          setLoader(true);
          onlyOnce.current = true;
          setCreateListPopUpState({});
          setListName("");
          setSearchText("");
          setTimeout(() => {
            onOpenToast(
              <>
                <b>
                  {isMultipleCreatorSelected ? multipleSelectedCreators.length : full_name}{" "}
                  {isMultipleCreatorSelected && multipleSelectedCreators.length > 1 ? "creators" : "creator"}
                </b>{" "}
                was added to the list <b>{listName}</b>
              </>
            );
          }, ADD_LIST_TOAST_MESSAGE_DURATION);
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        setIsCreatorListLoading(false);
        setListName("");
        setListNameError({ hasError: true, errorMessage: dublicateListError });
      }
    }
  };

  const handleListNameError = (listName) => {
    if (listName.length > 50) {
      setListNameError({ hasError: true, errorMessage: maximumCharError });
    } else {
      setListNameError({ hasError: false, errorMessage: "" });
    }
  };

  const CreatorList = ({ idx, listItem }) => {
    return (
      <>
        <div
          className={`searchable-checkbox-modal-contact-list-item ${selectedItems.includes(listItem?.name) ? "highlighted" : ""}`}
          style={{ padding: "16px 16px 16px 16px" }}
          onClick={() => {
            handleRowClick(idx, listItem);
          }}
        >
          <CheckBox blackCheck={false} defaultChecked={selectedItems.includes(listItem?.name)} />
          <div className="searchable-checkbox-modal-contact-list-item-text">
            {listItem?.name === "Favorites" ? (
              <div className="creator-list-modal-icon" style={{ background: "rgba(217, 29, 74, 0.08)" }}>
                <i class="ri-heart-line list-icon" style={{ color: "var(--accent-amaranth)" }}></i>
              </div>
            ) : (
              <div className="creator-list-modal-icon">
                <i class="ri-list-check list-icon" style={{ color: "var(--main-primary)" }}></i>
              </div>
            )}
            <div className="creator-list-modal-item-container">
              <div className="searchable-checkbox-modal-contact-list-item-label">{listItem?.name}</div>
              <div className="searchable-checkbox-modal-contact-list-item-label-description">
                {listItem?.count_of_creators} {listItem?.count_of_creators === 1 ? "creator" : "creators"}
              </div>
            </div>
          </div>
        </div>
        <div className="item-divider" />
      </>
    );
  };

  if (!open) {
    return (
      <>
        <ToastMessage
          openToast={openToast.open}
          message={openToast.message}
          onCloseToast={handleClose}
          duration={openToast.duration}
          position={{ vertical: "top", horizontal: "center" }}
          type={openToast.type}
        />
        <ExportCSVPopUp
          open={isAddListNamePopUpVisible}
          onClose={() => {
            setIsAddListNamePopUpVisible(false);
            setLoader(true);
            onlyOnce.current = true;
            setListName("");
          }}
          icon={<Icons.add_list_icon />}
          header={"Create new list"}
          description={`${isMultipleCreatorSelected ? multipleSelectedCreators.length : full_name} ${
            isMultipleCreatorSelected && multipleSelectedCreators.length > 1 ? "creators" : "creator"
          } will be added to this list.`}
          actionButtonLabel={"Done"}
          actionButtonHandler={handleCreateNewListAndAddCreatorToList}
          exportCount={listName}
          setExportCount={setListName}
          exportCountError={listNameError}
          setExportCountError={setListNameError}
          textboxType={"string"}
          textboxPlaceholder={"Enter list name e.g. Beauty creators"}
          onTextBoxChangeHandler={handleListNameError}
          buttonLoading={isCreatorListLoading}
          focusedInputText={listNameText}
        />
      </>
    );
  }

  const handleNewCreatorSelectedList = (defaultSelectedList, selectedItems) => {
    let defaultSelectedItems = defaultSelectedList.map((item) => item.name);
    let newSelectedItems = selectedItems.filter((item) => !defaultSelectedItems.includes(item));
    return newSelectedItems.length > 0;
  };

  return (
    <div className="modal" onClick={onClose}>
      <div
        className="create-list-pop-up-container"
        onClick={(e) => e.stopPropagation()}
        style={{
          height: loader || isCustomListCreated ? "704px" : "336px",
          top: loader || isCustomListCreated ? "calc(50% - 352px)" : "calc(50% - 168px)",
        }}
      >
        <div className="create-list-pop-up-content-container">
          <div className="create-list-pop-up-header-container">
            <div className="create-list-pop-up-icon">
              <div style={{ position: "relative" }}>
                {isMultipleCreatorSelected ? (
                  <div className="create-list-multiple-creator-icon">
                    <i class="ri-group-line"></i>
                  </div>
                ) : (
                  <Avatar alt="Profile Pic" src={image_url} className="table-profile-pic" sx={{ width: 72, height: 72 }} />
                )}
                <div className="create-list-pop-up-action-icon">
                  <Icons.create_list />
                </div>
              </div>
            </div>
            <div className="create-list-pop-up-description-container">
              <div className="create-list-pop-up-heading">
                <h3 className="create-list-pop-up-info-header">{header}</h3>
              </div>
              <div className="create-list-pop-up-description">
                <div className="create-list-pop-up-description-label">{full_name}</div>
                <div className="create-list-pop-up-description-verified-icon">{isVerified && <Icons.verified_logo width={16} height={16} />}</div>
              </div>
            </div>
          </div>
          <div className="create-list-pop-up-action-container">
            {loader ? (
              <CreatorListLoading />
            ) : isCustomListCreated ? (
              <div>
                <PrimaryButton
                  label={buttonsLabelsAndActions[0].label}
                  className="create-list-pop-up-action-button"
                  id="add-list"
                  onClick={() => {
                    onClose();
                    setIsCreatorListLoading(false);
                    setListNameError({});
                    setIsAddListNamePopUpVisible(true);
                  }}
                />
                <div className="searchable-checkbox-modal-content">
                  <div className="searchable-checkbox-modal-textbox">
                    <TextField
                      className="my-textfield"
                      onKeyDown={handleListsFilterSearch}
                      variant="outlined"
                      fullWidth
                      hiddenLabel
                      placeholder={"Search your lists"}
                      size="small"
                      InputProps={{
                        className: "tag-filter-input",
                        startAdornment: <i class="ri-search-line"></i>,
                        endAdornment: (
                          <>
                            {searchText ? (
                              <i
                                className={"ri-close-line clear-icon"}
                                onClick={() => {
                                  setSearchText("");
                                  handleListsFilterSearch({ target: { value: "" } });
                                }}
                              />
                            ) : null}
                          </>
                        ),
                      }}
                      onClick={(e) => e.stopPropagation()}
                      onChange={handleListsFilterSearch}
                      sx={{
                        borderColor: "var(--main-primary)",
                      }}
                      value={searchText}
                    />
                  </div>
                  <div
                    className="searchable-checkbox-modal-contact-list-container"
                    style={filteredList?.length > 3 ? { background: "linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 20%)" } : {}}
                  >
                    {filteredList &&
                      filteredList.map((listItem, idx) => {
                        return <CreatorList key={idx} idx={idx} listItem={listItem} />;
                      })}
                  </div>
                </div>
                <div className="searchable-checkbox-modal-footer">
                  <div className="searchable-checkbox-modal-action">
                    <PrimaryButton
                      label={handleAddListLabel(selectedItems, isCreatorAlreadyPresentInList)}
                      disabled={
                        isCreatorAlreadyPresentInList
                          ? selectedItems.length === 0
                            ? false
                            : defaultSelectedList.length === selectedItems.length
                            ? handleNewCreatorSelectedList(defaultSelectedList, selectedItems)
                              ? false
                              : true
                            : false
                          : selectedItems.length === 0
                      }
                      onClick={handleAddSingleCreatorToMultipleLists}
                      loading={isCreatorListLoading}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <PrimaryButton
                  label={buttonsLabelsAndActions[0].label}
                  className="create-list-pop-up-action-button"
                  id="add-list"
                  onClick={() => {
                    onClose();
                    setIsCreatorListLoading(false);
                    setListNameError({});
                    setIsAddListNamePopUpVisible(true);
                  }}
                />
                <PrimaryButton
                  label={buttonsLabelsAndActions[1].label}
                  className="create-list-pop-up-action-button"
                  id="favorite-list"
                  onClick={handleAddCreatorToFavoriteList}
                />
              </div>
            )}
          </div>
        </div>
        <div className="close-icon-container" style={{ top: loader || isCustomListCreated ? "4%" : "10%" }}>
          <Icons.close_icon
            onClick={() => {
              onClose();
              setLoader(true);
              onlyOnce.current = true;
              setCreateListPopUpState({});
              setListName("");
              setSearchText("");
            }}
            cursor="pointer"
          />
        </div>
      </div>
    </div>
  );
};

const CreatorListLoading = () => {
  return (
    <div>
      <Skeleton animation="wave" variant="text" width={"100%"} height={48} />
      {[1, 2, 3, 4, 5].map(() => {
        return (
          <Box className="table-row-skeleton">
            <Skeleton animation="wave" variant="text" width={24} height={40} />
            <Skeleton animation="wave" variant="circular" width={40} height={40} />
            <Skeleton animation="wave" variant="text" width={"100%"} height={80} />
          </Box>
        );
      })}
    </div>
  );
};

export default CreateListPopUp;
