import React from "react";
import CheckBox from "../CheckBox/CheckBox";
import "./CheckboxFilter.scss";
import PropTypes from "prop-types";

function CheckboxFilter({ checked, checkboxLabel, onFilterChange, getFilterValue, filterKey }) {
  const handleCheckboxClick = (value) => {
    if (getFilterValue) getFilterValue(value);
    if (onFilterChange) onFilterChange(true);
  };

  return (
    <div
      className="checkbox-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <CheckBox
        blackCheck={false}
        defaultChecked={checked}
        className={`checkbox-${checkboxLabel.length}`}
        onClick={(value) => {
          handleCheckboxClick(value);
        }}
      />
      <div
        className="checkbox-label"
        onClick={() => {
          const checkbox = document.querySelector(`.checkbox-${checkboxLabel.length}`);
          if (checkbox) {
            checkbox.click();
          }
        }}
      >
        {checkboxLabel}
      </div>
    </div>
  );
}

CheckboxFilter.propTypes = {
  checked: PropTypes.bool,
  checkboxLabel: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  getFilterValue: PropTypes.func,
};

CheckboxFilter.defaultProps = {
  checked: false,
  checkboxLabel: "Check label",
  getFilterValue: () => {},
};

export default CheckboxFilter;
