import React from 'react';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import "./MainBanner.scss";

function MainBanner({ icon, headerText, descText, buttonText, buttonAction, AdditionalText, variant, buttonLoading }) {
  let headerTextColor, bannerBackgroundColor, descTextColor, buttonBGColor;

  switch (variant) {
    // Define cases for different variants if needed
    default:
      headerTextColor = 'var(--accent-midnight-green)';
      bannerBackgroundColor = 'var(--accent-white-ice)';
      descTextColor = 'var(--accent-midnight-green)';
      buttonBGColor = 'var(--accent-midnight-green)';
      break;
  }
  document.documentElement.style.setProperty('--header-text-color', headerTextColor);
  document.documentElement.style.setProperty('--banner-background-color', bannerBackgroundColor);
  document.documentElement.style.setProperty('--desc-text-color', descTextColor);
  document.documentElement.style.setProperty('--button-bg-color', buttonBGColor);


  return (
    <div className='main-banner-container' >
      <div>{icon}</div>
      <div>
        <div className='banner-header section-heading' >{headerText}</div>
        <div className='banner-desc-header body-r' >{descText}</div>
        <div className='banner-button-container' >
          <PrimaryButton
            label={buttonText}
            className="button banner-button body-m"
            onClick={buttonAction}
            loading={buttonLoading}
          />
          <div className='body-r' >{AdditionalText}</div>
        </div>
      </div>
    </div>
  );
}

export default MainBanner;


/**
 * MainBanner Component
 * 
 * This component renders a banner with an icon, header text, description text,
 * a button, and additional text. The styles for various elements are dynamically
 * set based on the provided `variant` prop.
 * 
 * Props:
 * - icon (ReactNode): The icon to be displayed in the banner.
 * - headerText (string): The main header text of the banner.
 * - descText (string): The description text of the banner.
 * - buttonText (string): The text to be displayed on the button.
 * - buttonAction (function): The function to be executed when the button is clicked.
 * - AdditionalText (string): Any additional text to be displayed below the button.
 * - variant (string): A string to determine the style variant of the banner. The default
 *   case sets a predefined style. More variants can be added as needed.
 * 
 * Example Usage:
 * ```
 * <MainBanner
 *   icon={<MyIcon />}
 *   headerText="Welcome to our service"
 *   descText="We provide the best solutions for your needs."
 *   buttonText="Get Started"
 *   buttonAction={() => console.log('Button clicked')}
 *   AdditionalText="Additional information here"
 *   variant="default"
 * />
 * ```
 */