import { InputAdornment, TextField } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';
import Icons from '../Icons/Icons';
import "./FilledTextField.scss";

const FilledTextField = ({ label, hasError, errorMessage, showClearIcon = false , ...otherParams }) => {
  let inputProps = {
    endAdornment: (
      <>
        {showClearIcon && otherParams.value ? (
          <div className='filled-text-field-cross-icon' >
            <i className={'ri-close-line clear-icon'} onClick={() => otherParams.onChange({ target: { value: '' } })} />
          </div>
        ) : null}
      </>
    )
  };
  return (
    <Box className="filled-text-field-container" >
      <TextField
        className={`filled-search  ${hasError && "filled-text-input-error"}  `}
        label={label}
        type="search"
        variant="filled"
        InputProps={inputProps}
        {...otherParams}
      />
      {hasError && <span className="input-error-message">{errorMessage}</span>}
    </Box>
  );
};

export default FilledTextField;
