import { Modal } from "@material-ui/core";
import "./CreatorInviteForm.scss";
import React, { useContext } from "react";
import { Form } from "antd";
import Icons from "../Icons/Icons";
import { useState } from "react";
import EmailPreview from "./EmailPreview";
import { Box, Typography } from "@mui/material";
import IndividualUpload from "./IndividualUpload";
import MultipleUpload from "./MultipleUpload";
import { Avatar, Divider, IconButton, Tab, Tabs } from "@mui/material";
import { WarehouseContext } from "../../storage/context";

const CreatorInviteForm = ({ open, onClose }) => {
  const [stepForm] = Form.useForm();
  const [inviteName, setInviteName] = useState("[name]");
  const { warehouse } = useContext(WarehouseContext);

  const TabValues = {
    individual: "Individual",
    multiple: "Multiple (Bulk upload)",
  };
  const [currTab, SetCurrTab] = useState(TabValues.individual);

  if (!open) {
    return null;
  }

  const handleCloseForm = () => {
    onClose();
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ padding: "0px", height: "100%" }}>
            <Typography sx={{ padding: "0px", height: "100%" }}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <div className="modal">
      <div className="invite-form-container">
        <div className="invite-form-content">
          <h2 className="invite-heading">Invite creators</h2>

          <Tabs className="InviteTabsGroup" value={currTab} onChange={(ev, value) => SetCurrTab(value)} aria-label="icon position tabs example">
            <Tab sx={{ textTransform: "none", fontSize: "14px", fontWeight: "500" }} label={TabValues.individual} value={TabValues.individual} />
            <Tab sx={{ textTransform: "none", fontSize: "14px", fontWeight: "500" }} label={TabValues.multiple} value={TabValues.multiple} />
          </Tabs>
          <TabPanel index={TabValues.individual} value={currTab} className="tab-body-container">
            <IndividualUpload setInviteName={setInviteName} inviteName={inviteName} />
          </TabPanel>
          <TabPanel index={TabValues.multiple} value={currTab} className="tab-body-container">
            <MultipleUpload />
          </TabPanel>
        </div>
        <div className="close-icon-container">
          <Icons.close_circle_fill onClick={handleCloseForm} cursor="pointer" />
        </div>
        <EmailPreview inviteName={inviteName} tenantAppName={warehouse?.tenantInfo[0]?.apps[0]?.name} />
      </div>
    </div>
  );
};

export default CreatorInviteForm;
