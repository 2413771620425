import './EditCreatorDrawer.scss';
import { Button, Drawer } from "@mui/material";

import {
    ADD_MORE_CREATOR_ACCOUNTS,
    PROFILE_ADD_STATUS,
    REMOVE_ACCOUNT_FROM_CAMPAIGN,
    REMOVE_ACCOUNT_FROM_CAMPAIGN_MESSAGE,
    SELECT_CREATOR_PLACEHOLDER
} from "../../constants/campaignTrackingConstants";
import TextBox from "../InsightIQ/TextBox/TextBox";
import { ModalDialog, SelectedCreatorList } from "../index";
import { useContext, useState } from "react";
import { Colors } from "../../styles/colors";
import { getRowId } from "../SelectableCreatorGrid/SelectableCreatorGrid";
import { SET_CSV_CREATORS_LIST, SET_TRACKED_CREATORS } from "../../storage/reducers/types";
import { CampaignTrackingContext } from "../../storage/context";
import { DONT_REMOVE, REMOVE_TEXT } from "../../constants/constants";

export default function EditCreatorDrawer({
    onClose,
    isOpen,
    heading,
    onAddMoreAccounts,
    onSearch,
    rows,
    csvProfileUrls
}) {
    const DRAWER_WIDTH = 590;
    const [searchText, setSearchText] = useState('');
    const [profileToBeDeleted, setProfileToBeDeleted] = useState(null);
    const [profileUrlToBeDeleted, setProfileUrlToBeDeleted] = useState(null);
    const [showRemoveCreatorDialog, setShowRemoveCreatorDialog] = useState(false);
    const { campaignTrackingWarehouse, dispatchCampaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const { csvCreatorsList, trackedCreators } = campaignTrackingWarehouse;

    function onDeleteCreatorInDrawer(id) {
        const creatorsToBeDeleted = [...trackedCreators];
        creatorsToBeDeleted.forEach((trackedCreator) => {
            if (getRowId(trackedCreator) === id) {
                trackedCreator.addStatus = PROFILE_ADD_STATUS.TO_BE_REMOVED;
            }
        });
        dispatchCampaignTrackingWarehouse({
            type: SET_TRACKED_CREATORS,
            payload: trackedCreators,
        });
        setProfileToBeDeleted(null);
    }

    function onDeleteCreatorFromCSV(url) {
        const updatedList = csvCreatorsList.filter((creator) => creator.url !== url);
        dispatchCampaignTrackingWarehouse({
            type: SET_CSV_CREATORS_LIST,
            payload: updatedList,
        });
    }


    function onDeleteAction() {
        setShowRemoveCreatorDialog(false);
        if (profileToBeDeleted) {
            onDeleteCreatorInDrawer(profileToBeDeleted)
        } else if (profileUrlToBeDeleted) {
            onDeleteCreatorFromCSV(profileUrlToBeDeleted)
        }

    }


    return (
        <>
            <Drawer
                PaperProps={{
                    sx: {
                        width: DRAWER_WIDTH,
                    },
                }}
                anchor="right"
                open={isOpen}
                onClose={() => {
                    onClose();
                    setSearchText('');
                    onSearch('');
                }}
            >
                <div className={"side-drawer-custom-class"}>
                    <span className={"section-heading selected-creator-title"}>{heading}</span>
                    <div style={{ marginTop: "41px" }}>
                        <TextBox
                            variant={"short"}
                            placeholder={SELECT_CREATOR_PLACEHOLDER}
                            value={searchText}
                            onEnter={onSearch}
                            onClear={() => {
                                setSearchText('');
                                onSearch('');
                            }}
                            containerClass={"hundred-percent-width"}
                        />
                    </div>
                    <SelectedCreatorList
                        rows={rows}
                        onDeleteRow={(id) => {
                            setProfileToBeDeleted(id);
                            setShowRemoveCreatorDialog(true);
                        }}
                    />
                    {csvProfileUrls?.length !== 0 && <SelectedCreatorList
                        rows={csvProfileUrls}
                        onDeleteRow={(row) => {
                            setProfileUrlToBeDeleted(row.url);
                            setShowRemoveCreatorDialog(true);
                        }}
                    />}
                    <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: 65 }}>
                        <Button className={"button-add-more-creator-accounts"} onClick={onAddMoreAccounts}>
                            <span className={"body-m"} style={{ color: Colors.mainPrimary }}>
                                {ADD_MORE_CREATOR_ACCOUNTS}
                            </span>
                        </Button>
                    </div>
                </div>
            </Drawer>
            {showRemoveCreatorDialog ? (
                <ModalDialog
                    variant={"remove"}
                    cancelText={DONT_REMOVE}
                    buttonText={REMOVE_TEXT}
                    title={REMOVE_ACCOUNT_FROM_CAMPAIGN}
                    content={REMOVE_ACCOUNT_FROM_CAMPAIGN_MESSAGE}
                    onAction={onDeleteAction}
                    onClose={() => setShowRemoveCreatorDialog(false)}
                    onDont={() => setShowRemoveCreatorDialog(false)}
                />
            ) : null}
        </>
    );
}