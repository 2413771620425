import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from 'moment-timezone';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./ContentDatePicker.scss";
import { DATE_FORMAT, FROM_DATE, TO_DATE } from "../../constants/constants";

export default function ContentDatePicker(props) {
  const DATE_PICKER_VIEWS = ["year", "month", "day"];
  const { fromDateHandler, toDateHandler } = props;
  const {
    fromDate,
    onValidFromDate,
    clearFromDate,
    fromMinDate = null,
    disabled = false,
    fromDateLabel = FROM_DATE,
    fromMaxDate = moment(),
  } = fromDateHandler;
  const [fromValue, setFromValue] = useState(moment.isMoment(fromDate) ? fromDate : (fromDate ? moment(fromDate) : null));
  const {
    toDate,
    onValidToDate,
    clearToDate,
    toMaxDate = moment(),
    toDateLabel = TO_DATE,
    toMinDate = fromValue,
  } = toDateHandler;

  const [toValue, setToValue] = useState(moment.isMoment(toDate) ? toDate : (toDate ? moment(toDate) : null));

  useEffect(() => {
    setFromValue(moment.isMoment(fromDate) ? fromDate : (fromDate ? moment(fromDate) : null));
  }, [fromDate]);

  useEffect(() => {
    setToValue(moment.isMoment(toDate) ? toDate : (toDate ? moment(toDate) : null));
  }, [toDate]);

  function onFromDateChange(value) {
    if (value && moment.isMoment(value) && value.isValid()) {
      setFromValue(value);
    } else {
      clearFromDate();
      clearToDate();
      setFromValue(null);
    }
  }

  function onToDateChange(value) {
    if (value && moment.isMoment(value) && value.isValid()) {
      setToValue(value);
    } else {
      clearToDate();
      setToValue(null);
    }
  }

  function getFormattedDate(dateVal) {
    return dateVal && dateVal.isValid() ? dateVal.format('YYYY-MM-DD') : '';
  }

  useEffect(() => {
    if (fromValue && fromValue.isValid()) {
      onValidFromDate(getFormattedDate(fromValue));
    }
    if (toValue && toValue.isValid()) {
      onValidToDate(getFormattedDate(toValue));
    }
  }, [fromValue, toValue, onValidFromDate, onValidToDate]);

  function renderDatePickerInput(params) {
    return <TextField {...params} className="text-field-date" size="small" onKeyDown={e => e.preventDefault()} />;
  }

  // Validate the min and max date to avoid conflict which causes the red border
  const validatedMinDate = fromMinDate && moment.isMoment(fromMinDate) ? fromMinDate : fromMinDate ? moment(fromMinDate) : undefined;
  const validatedMaxDate = fromMaxDate && moment.isMoment(fromMaxDate) ? fromMaxDate : fromMaxDate ? moment(fromMaxDate) : undefined;

  const validatedToMinDate = toMinDate && moment.isMoment(toMinDate) ? toMinDate : toMinDate ? moment(toMinDate) : fromValue ? moment(fromValue) : undefined;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container className="date-picker-container">
        <Grid item>
          <DatePicker
                value={fromValue}
                inputFormat="DD/MM/YYYY"
            label={fromDateLabel}
                maxDate={validatedMaxDate}
                minDate={validatedMinDate}
                className="date-picker"
                onChange={onFromDateChange}
            renderInput={renderDatePickerInput}
            views={DATE_PICKER_VIEWS}
            disabled={disabled}
          />
        </Grid>
          <Grid item className="grid-hyphen">
            <Typography variant="body1">&nbsp;-&nbsp;</Typography>
        </Grid>
        <Grid item>
          <DatePicker
                className="date-picker"
                disabled={!fromValue}
                inputFormat="DD/MM/YYYY"
            label={toDateLabel}
                maxDate={moment(toMaxDate)}
                minDate={validatedToMinDate}
                onChange={onToDateChange}
            renderInput={renderDatePickerInput}
            value={toValue}
            views={DATE_PICKER_VIEWS}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
