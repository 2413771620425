import { useContext, useEffect, useReducer } from "react";
import axiosInstance from "../api/axiosInstance";

import { UserContext, WarehouseContext } from "../storage/context";

import { useNavigate } from "react-router-dom";
import { ADD_USER_PROFILE, REMOVE_USER_PROFILE, REMOVE_WAREHOUSE } from "../storage/reducers/types";
import { USER_PROFILE_DEFAULT_STATE, userProfileReducer } from "../storage/reducers";

const useProfile = () => {
  const { dispatchUserProfile } = useContext(UserContext);
  const { dispatchWarehouse } = useContext(WarehouseContext);
  const navigate = useNavigate();

  const verifyEmailIfNot = (response, email = "", password) => {
    if (response.data.email_verified === true) {
      return true;
    } else {
      return navigate("/verification", {
        state: { email, password },
      });
    }
  };

  const setUserProfile = (response) => {
    let { data } = response;
    const userAttributes = {
      token: data.token,
      tokenExpiry: data.token_expiry_at,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      emailVerified: data.email_verified,
      companyName: data.company_name,
    };

    dispatchUserProfile({ type: ADD_USER_PROFILE, payload: userAttributes });
    localStorage.setItem("user", JSON.stringify(userAttributes));
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${userAttributes.token}`;
    // createAxiosInstance(data.token);
  };

  const logoutUserProfile = (dispatchWarehouseAsParam, dispatchUserProfileAsParam) => {
    dispatchUserProfileAsParam ? dispatchUserProfileAsParam({ type: REMOVE_USER_PROFILE }) : dispatchUserProfile({ type: REMOVE_USER_PROFILE });
    dispatchWarehouseAsParam ? dispatchWarehouseAsParam({ type: REMOVE_WAREHOUSE }) : dispatchWarehouse({ type: REMOVE_WAREHOUSE });

    localStorage.removeItem("user");
    localStorage.removeItem("insightiq_analytics");
    localStorage.removeItem("SDK_USER_CREDS");
    localStorage.removeItem("User_Response");
    sessionStorage.removeItem("creatorProfile");
    localStorage.removeItem("userRequestedLocation");
    localStorage.removeItem("purchaseIntentBannerVisible");
    sessionStorage.removeItem("isGifLoaded");
    sessionStorage.removeItem("urlWithParams");
    sessionStorage.removeItem("InstagramUrl");
    sessionStorage.removeItem("YouTubeUrl");
    sessionStorage.removeItem("TikTokUrl");
    sessionStorage.removeItem("exportMail");
  };

  return { setUserProfile, logoutUserProfile, verifyEmailIfNot };
};

export default useProfile;
