import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { CompareReportPage, CreatorDiscoveryPage, CreatorDiscoveryProfilePage } from "../pages/index";

export default function CreatorDiscoveryRoutes() {
  return (
    <Routes path="">
      <Route path="" element={<CreatorDiscoveryPage />} />
      <Route path="/profile" element={<CreatorDiscoveryProfilePage />} />
      <Route path="/compare-creators/:comparisonId" element={<CompareReportPage />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
