import React, { useContext, useState } from "react";

import { Background, Card, Typography, OtpInputField, PrimaryButton, NavigationHeader, TextLink } from "../../components";
import { Helmet } from "react-helmet";

import { webhooksApi } from "../../api/index";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import { UpsertWebhookForm } from "../../containers/index";
import "./UpdateWebhookPage.scss";
import { UserContext, WarehouseContext } from "../../storage/context";
import useFetchWebhooks from "../../hooks/useFetchWebhooks";
import { trackEvent, WEBHOOK_EDIT_CANCEL, WEBHOOK_EDIT_PAGE_VIEW } from "../../analytics";
import { useToast } from "../../hooks/useToast";
import { WEBHOOK_EDIT_SUCCESS } from "../../constants/toastMessages";
import { getPageTitle, pageTitle } from "../../constants/constants";
import { useEffect } from "react";

export default function UpdateWebhookPage() {
  const navigate = useNavigate();
  let { webhookId } = useParams();
  const { warehouse } = useContext(WarehouseContext);
  const { fetchWebhooks } = useFetchWebhooks();
  const [error, setError] = useState({ errorMessage: "" });
  const [loading, setLoading] = useState(false);
  const [selectedWebhook, setSelectedWebhook] = useState({});
  const toast = useToast();
  const onSubmitForm = async ({
    name = selectedWebhook.name,
    url = selectedWebhook.url,
    events = selectedWebhook.events,
    is_active = selectedWebhook.is_active,
  }) => {
    setLoading(true);
    try {
      const tenantId = warehouse.tenantInfo[0].id;
      const clientId = warehouse.tenantInfo[0].apps[0].id;
      const environment = warehouse.environment.current.toUpperCase();
      const response = await webhooksApi.updateWebhook({
        tenantId,
        clientId,
        environment,
        url,
        events,
        name,
        webhookId,
        is_active,
        webhook: selectedWebhook,
      });
      if (response.status === 200) {
        fetchWebhooks();
        toast(WEBHOOK_EDIT_SUCCESS);
        navigate("../");
      }
    } catch (e) {
      console.log(e);
      setError({ errorMessage: e.message });
      setLoading(false);
    }
  };

  // const selectedWebhook = webhooksData.find(
  //   (webhook) => webhook.id === webhookId
  // );

  const onCancel = () => {
    trackEvent(WEBHOOK_EDIT_CANCEL, selectedWebhook);
    navigate("../");
  };

  useEffect(() => {
    if (warehouse.webhooks.length === 0) {
      fetchWebhooks();
    } else {
      setSelectedWebhook(warehouse.webhooks.find((webhook) => webhook.id === webhookId));
      console.log(
        "selected webhook",
        warehouse.webhooks.find((webhook) => webhook.id === webhookId)
      );
    }
  }, [warehouse.tenantInfo, warehouse.webhooks]);

  useEffect(() => {
    if (Object.keys(selectedWebhook).length > 0) trackEvent(WEBHOOK_EDIT_PAGE_VIEW, selectedWebhook);
  }, [selectedWebhook]);

  if (!selectedWebhook.name) {
    return null;
  }

  return (
    <div>
      <Helmet>
        <title>{getPageTitle("WEBHOOK_UPDATE_PAGE", warehouse.environment.current)}</title>
      </Helmet>
      <UpsertWebhookForm
        onSubmitForm={onSubmitForm}
        webhookName={selectedWebhook.name}
        webhookURL={selectedWebhook.url}
        isUpdate={true}
        isActive={selectedWebhook.is_active}
        onCancel={onCancel}
        selectedWebhookEvents={selectedWebhook.events}
        title="Edit webhook"
        errors={error}
        buttonLoading={loading}
      />
    </div>
  );
}
