import { Skeleton } from '@mui/material';
import React from 'react';
import DonutChart from '../DonutChart/DonutChart';

function CreatorChartDistribution({ distributionData, isLoading }) {
  if (isLoading) {
    return (
      <div className="piechart-main-container">
        <Skeleton variant="circular" height={'200px'} width={'200px'}></Skeleton>
        <div className={"div-donut-chart-legend-profile"}>
          {[0, 1, 2].map((legend) => {
            return (
              <div className={"donut-chart-row-1"}>
                <div className='row-1' >
                  <Skeleton variant="circular" height={'16px'} width={'16px'}></Skeleton>
                  <Skeleton width="60px" height={'30px'} variant="text"></Skeleton>
                </div>
                <div>
                  <Skeleton width="40px" height={'30px'} variant="text"></Skeleton>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="piechart-main-container" >
      <DonutChart
        data={distributionData}
        variant={"triple-disconnected"}
        height={200}
        width={200}
        isActive={true}
      />
      <div className={"div-donut-chart-legend-profile"}>
        {distributionData?.map((legend) => {
          const { color, value, name } = legend;
          return (
            //{Chart Legends}
            <div className={"donut-chart-row-1"}>
              <div className='row-1' >
                <svg width={16} height={16}>
                  <circle cx={8} cy={8} r={8} fill={color} />
                </svg>
                <div className={"sub-section-heading legend-title"}>{value ? Number(value).toFixed(1) + "%" : "-"}</div>
              </div>
              <div>
                <div className={"body-r legend-subtitle"}>{name}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CreatorChartDistribution;