import React, { useRef } from "react";
import { Avatar, Box, CardMedia, Grid, IconButton, LinearProgress, useMediaQuery } from "@mui/material";
import { calculateTotalDays, formatNumber, getNewImageUrl, isImageExist, timeConvert } from "../../utils/profileHelpers";
import Icons from "../Icons/Icons";
import { useState } from "react";
import { useEffect } from "react";
import { CONTENT_ITEM_CLICKED, CONTENT_THUMBNAIL_EXPIRED, trackEvent } from "../../analytics";
const ContentData = ({ content, CONTENT_FEED_ATTRIBUTES, currWorkPlatformMetrics }) => {
  const [isImage, setImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("")
  const isImageUrlExistFetched = useRef(true);

  // Checks if any of the given image URLs exist by making a request to each URL.
  const isImageUrlExist = async (urls) => {
    let imageFound = false
    for (let i = 0; i < urls.length; i++) {
      try {
        if (urls[i] && urls[i] === null) continue;
        let url = getNewImageUrl(urls[i])
        isImageExist(url)
          .then((res) => {
            if (!imageFound) {
              setImage(true);
              setImageUrl(url)
              imageFound = true;
              return;
            }
          },
            () => {
              if (i == 1 && !imageFound) {
                trackEvent(CONTENT_THUMBNAIL_EXPIRED, {
                  work_platform_id: content?.work_platform?.id,
                  work_platform_name: content?.work_platform?.name,
                  connected_account_id: content?.account?.id,
                  content_id: content?.id,
                  user_id: content?.user?.id,
                  thumbnail_url: content?.thumbnail_url,
                  persistent_thumbnail_url: content?.persistent_thumbnail_url
                });
              }
            })
      } catch (error) {

      }
    }
  };
  useEffect(() => {
    if (isImageUrlExistFetched.current) {
      isImageUrlExistFetched.current = false;
      isImageUrlExist([content?.persistent_thumbnail_url, content?.thumbnail_url]);
    }
  }, [content]);


  // Redirects the user to the content item's URL and tracks the event.
  function redirectToContent(url) {
    url !== null && window.open(url);
    trackEvent(CONTENT_ITEM_CLICKED, {
      work_platform_id: content?.work_platform?.id,
      work_platform_name: content?.work_platform?.name,
      connected_account_id: content?.account?.id,
      content_id: content?.id,
      user_id: content?.user?.id,
    });
  }

  // Function to modify the URL by replacing "-" with "--" and "." with "-" in the third section of the URL and 


  return (
    <Box
      className="contentContiner"
      key={content?.id}
      onClick={() => content?.type !== "STORY" && redirectToContent(content?.url)}
      sx={
        content?.type === "STORY" && {
          ":hover": {
            border: "1px solid #ffffff !important",
            cursor: "default !important",
          },
        }
      }
    >
      <Box sx={{ position: "relative" }}>
        {isImage ? (
          <div>
            <CardMedia alt="Profile Pic" image={imageUrl} className="contentPic" />
          </div>
        ) : (
          <div className="contentPic">
            <Icons.content_img_placeholder />
          </div>
        )}
        <div className="updated-time">{calculateTotalDays(content?.published_at)}</div>
        <div className="img-gradient">
          <p className="text-truncate">{content?.title}</p>
        </div>
        {content?.type !== "STORY" && (
          <div className="content_arrow_icon">
            <Icons.content_arrow_icon />
          </div>
        )}
      </Box>
      <Box className="contentInfo">
        {currWorkPlatformMetrics && currWorkPlatformMetrics?.includes(CONTENT_FEED_ATTRIBUTES?.likes) && (
          <Box>
            <Box>LIKES</Box>
            <Box>{content?.engagement?.like_count !== null ? formatNumber(content?.engagement?.like_count) : "-"}</Box>
          </Box>
        )}
        {currWorkPlatformMetrics && currWorkPlatformMetrics?.includes(CONTENT_FEED_ATTRIBUTES?.comments) && (
          <Box>
            <Box>COMMENTS</Box>
            <Box>{content?.engagement?.comment_count !== null ? formatNumber(content?.engagement?.comment_count) : "-"}</Box>
          </Box>
        )}
        {currWorkPlatformMetrics && currWorkPlatformMetrics.includes(CONTENT_FEED_ATTRIBUTES?.views) && (
          <Box>
            <Box>VIEWS</Box>
            <Box>{content?.engagement?.view_count !== null ? formatNumber(content?.engagement?.view_count) : "-"}</Box>
          </Box>
        )}
        {currWorkPlatformMetrics && currWorkPlatformMetrics.includes(CONTENT_FEED_ATTRIBUTES?.impressions) && (
          <Box>
            <Box>IMPRESSIONS</Box>
            <Box>{content?.engagement?.impression_organic_count !== null ? formatNumber(content?.engagement?.impression_organic_count) : "-"}</Box>
          </Box>
        )}
        {currWorkPlatformMetrics && currWorkPlatformMetrics.includes(CONTENT_FEED_ATTRIBUTES?.reach) && (
          <Box>
            <Box>REACH</Box>
            <Box>{content?.engagement?.reach_organic_count !== null ? formatNumber(content?.engagement?.reach_organic_count) : "-"}</Box>
          </Box>
        )}
        {currWorkPlatformMetrics && currWorkPlatformMetrics.includes(CONTENT_FEED_ATTRIBUTES?.watch_time) && (
          <Box>
            <Box>WATCH TIME</Box>
            <Box>{content?.engagement?.watch_time_in_hours !== null ? timeConvert(content?.engagement?.watch_time_in_hours) : "-"}</Box>
          </Box>
        )}
        {currWorkPlatformMetrics && currWorkPlatformMetrics.includes(CONTENT_FEED_ATTRIBUTES?.watch_time) && (
          <Box>
            <Box>WATCH TIME</Box>
            <Box>{content?.engagement?.watch_time_in_hours !== null ? timeConvert(content?.engagement?.watch_time_in_hours) : "-"}</Box>
          </Box>
        )}

        {currWorkPlatformMetrics && currWorkPlatformMetrics.includes(CONTENT_FEED_ATTRIBUTES?.shares) && (
          <Box>
            <Box>SHARES</Box>
            <Box>{content?.engagement?.share_count !== null ? formatNumber(content?.engagement?.share_count) : "-"}</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ContentData;
