import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CREDENTIALS_GENERATE_FAILURE, CREDENTIALS_GENERATE_SUCCESS, trackEvent } from "../analytics";
import { creatorDiscoveryAPI, tenantInfoApi } from "../api";
import { CreatorDiscoveryWarehouseContext, UserContext, WarehouseContext } from "../storage/context";
import { ADD_FILTER_PAYLOAD, ADD_TENANT_INFORMATION } from "../storage/reducers/types";
import { getClientIDAndSecret } from "../utils/util";

export const useFetchCreatorDiscovery = () => {
  const { creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse } = useContext(CreatorDiscoveryWarehouseContext);
  const { warehouse } = useContext(WarehouseContext);

  return async () => {
    try {
      let data;
      if (Object.keys(creatorDiscoveryWarehouse.filters).length === 0) {
        data = await creatorDiscoveryAPI.getFiltersPayload({ warehouse });
        dispatchCreatorDiscoveryWarehouse({
          type: ADD_FILTER_PAYLOAD,
          payload: data,
        });
      } else {
        data = creatorDiscoveryWarehouse.filters;
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  };
};
