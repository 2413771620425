import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { Form, Tree, Switch, Tooltip } from "antd";
import {
  InputField,
  PrimaryButton,
  Icons,
  Typography,
  Tag,
  InvertedPrimaryButton,
  Loader,
  Card,
  Separator,
} from "../../components";
import {
  EMPTY_REQUIRED_FIELD_MESSAGE,
  IP_REGEX,
  LOCALHOST_REGEX,
  WEBHOOK_NAME_EXCEED_LIMIT_MESSAGE,
  URL_REGEX,
  WEBHOOK_CLEAR_SELECTED_EVENTS,
  WEBHOOK_DEFAULT_EXPANDED_KEYS,
  WEBHOOK_EVENT,
  WEBHOOK_SELECTED,
  WEBHOOK_EVENTS_LABEL,
  WEBHOOK_REQUIRED_FIELDS,
  WEBHOOK_SEARCH_PLACEHOLDER,
  WEBHOOK_ROOT_KEY,
  WEBHOOK_INFO_HEADER,
  WEBHOOK_INFO_CONTENT,
  WEBHOOK_DOCS_LINK,
  WEBHOOK_HEADER_STICKY_SCROLL_TOP,
  WEBHOOK_CANCEL_BUTTON_TEXT,
  WEBHOOK_SAVE_BUTTON_TEXT,
  WEBHOOK_CREATE_BUTTON_TEXT,
  LEARN_MORE_TEXT,
  DEBOUNCE_TIME,
  WEBHOOK_HEADER_STICKY_SCROLL_OFFSET,
} from "../../constants/constants";
import { getAllWebhookEventsApi } from "../../api";
import { useNavigate } from "react-router-dom";
import "./UpsertWebhookForm.scss";
import useFormSubmitDisable from "../../hooks/useFormSubmitDisable.js";
import { DOMAIN_NOT_HTTPS, NOT_A_VALID_DOMAIN } from "../../constants/constants";
import { WarehouseContext } from "../../storage/context";
import { isEmpty } from "../../utils/util.js";
import { Colors } from "../../styles/colors.js";
import TextBox from "../../components/InsightIQ/TextBox/TextBox";
import { WEBHOOK_LEARN_CLICKED, WEBHOOK_SEARCH, trackEvent } from "../../analytics/index.js";
import { debounce } from "lodash";

const webhookUrlRules = [
  {
    validator: (_, value) => {
      if (value === "") {
        return Promise.reject(EMPTY_REQUIRED_FIELD_MESSAGE);
      }
      if (!value.startsWith("https://")) {
        return Promise.reject(DOMAIN_NOT_HTTPS);
      }
      if (LOCALHOST_REGEX.test(value) || IP_REGEX.test(value) || !URL_REGEX.test(value) || value.length <= 8) {
        return Promise.reject(NOT_A_VALID_DOMAIN);
      }

      Promise.resolve();
    },
  },
];

const treeNodeStyle = {
  border: "1px solid #F7F7FB",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const formatDataToRequiredFormat = (data) => {
  const result = {};
  const selectedItems = [];
  data.forEach((item) => {
    const category = item.name.split(".")[0];
    // const titleElement =
    if (!result[category]) {
      result[category] = {
        title: category,
        key: category,
        children: [],
        isLeaf: false,
        style: treeNodeStyle,
      };
    }
    const { id, name, display_name, description } = item;
    result[category].children.push({
      title: name,
      key: name,
      description,
      isLeaf: true,
      style: treeNodeStyle,
    });

    selectedItems.push(name);
  });

  // let selectedItems = [];
  // Object.values(result).forEach((item) => {
  //   selectedItems = [...selectedItems, ...item.children];
  // });

  const root = [
    {
      title: "Select all events",
      key: "root",
      children: Object.values(result),
      backgroundColor: "#EBEBEF",
      isLeaf: false,
      style: {
        ...treeNodeStyle,
        backgroundColor: "#EBEBEF",
        borderRadius: "4px 4px 0px 0px",
      },
    },
    {
      title: "Total selected events",
      key: "custom-div",
      // children: Object.values(result),
      backgroundColor: "#EBEBEF",
      checkable: false,
      style: {
        ...treeNodeStyle,
        backgroundColor: "#EBEBEF",
        borderRadius: "0px 0px 4px 4px",
        color: "#8A8894",
        fontWeight: "500",
      },
    },
  ];

  return { root, selectedItems };
};

const webhookHeaderStickyStyling = {
  position: "sticky",
  top: 0,
  zIndex: 2,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  height: "72px",
  background: "var(--white)",
}

const webhookHeaderItemsStickyStyling = {
  display: "flex",
  gap: "24px",
  height: "72px",
  width: "600px",
  marginTop: "24px"
}

const webhookBackIconStickyStyling = {
  display: "flex",
  alignItems: "center",
  height: "24px"
}

export default function UpsertWebhookForm({
  title = "Create webhook",
  isUpdate = false,
  onSubmitForm,
  webhookName = "",
  webhookURL = "",
  isActive = false,
  onCancel,
  errors = { errorMessage: "" },
  selectedWebhookEvents = [],
  buttonLoading = false,
}) {
  const navigate = useNavigate();
  const [formInstance] = Form.useForm();
  const { warehouse } = useContext(WarehouseContext);
  const environment = warehouse.environment.current.toUpperCase();
  const [webhookEvents, setWebhookEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [urlRequirements, setUrlRequirements] = useState({
    isHTTPS: false,
    isNotIP: false,
    isNotLocalDomain: false,
  });
  const [searchText, setSearchText] = useState('');
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [isExportOverlaySticky, setIsExportOverlaySticky] = useState(false);
  const [searchedWebhookEvents, setSearchedWebhookEvents] = useState(WEBHOOK_DEFAULT_EXPANDED_KEYS);
  const DEBOUNCE_DELAY = 500;
  const disableFormSubmit = useFormSubmitDisable(formInstance, WEBHOOK_REQUIRED_FIELDS);
  const scrollPosition = useRef(0);

  const onClickSubmitForm = () => {
    onSubmitForm(formInstance.getFieldValue());
  };

  useEffect(() => {
    async function fetchWebhookEvents(){
      try {
        const { data } = await getAllWebhookEventsApi.getAllWebhookEvents(environment);
        const { root } = formatDataToRequiredFormat(data);
        setWebhookEvents(root);
        formInstance.setFieldsValue({ events: selectedWebhookEvents });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }

      // TODO: insert after the api call
      // setWebhookEvents(formatDataToRequiredFormat(.data));
    };
    setLoading(true);
    fetchWebhookEvents();
  }, []);

  // function to filter tree data on search
  const filterTreeData = (nodes = [], searchText) => {
    return nodes.reduce((acc, node) => {
      if (node.children) {
        const filteredChildren = filterTreeData(node.children, searchText);
        if (filteredChildren.length > 0 || node.title.toLowerCase().includes(searchText.toLowerCase())) {
          acc.push({
            ...node,
            children: filteredChildren.length > 0 ? filteredChildren : node.children,
          });
        }
      } else if (!searchText) {
        acc.push({
          ...node,
          children: node.children,
        });
      } else if (node.title.toLowerCase().includes(searchText.toLowerCase())) {
        acc.push(node);
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    const filteredChildData = filterTreeData(webhookEvents[0]?.children, searchText);
    const filteredTreeData = [...webhookEvents];
    filteredTreeData[0] = {
      ...filteredTreeData[0],
      children: filteredChildData,
    };
    setFilteredTreeData(filteredTreeData);
    if(searchText) {
      let expandWebhookParentEvent = [...(filteredTreeData[0]?.children?.map((item) => item.title) || []), WEBHOOK_ROOT_KEY];
      setSearchedWebhookEvents(expandWebhookParentEvent);
    }
  }, [searchText, webhookEvents]);

  // This useEffect used to set webhook header height when it is on sticky position
  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(".scrolling-page-content");
      const currentScrollTop = element.scrollTop;

      if (Math.abs(currentScrollTop - scrollPosition.current) < WEBHOOK_HEADER_STICKY_SCROLL_OFFSET) {
        // If the scroll change is less than for eg. 40px, ignore it to prevent fluctuation
        return;
      }

      scrollPosition.current = currentScrollTop;
      const shouldBeSticky = currentScrollTop > WEBHOOK_HEADER_STICKY_SCROLL_TOP;

      setIsExportOverlaySticky(shouldBeSticky);
    };

    const element = document.querySelector(".scrolling-page-content");
    element?.addEventListener("scroll", handleScroll);

    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function onSearchChange(event) {
    if(isEmpty(event.target.value)) {
      setSearchedWebhookEvents(WEBHOOK_DEFAULT_EXPANDED_KEYS);
    } else {
      debouncedTrackEvent(event.target.value);
    }
    setSearchText(event.target.value);
  };

  // Memoize the debounced function to avoid recreation on every render
  const debouncedTrackEvent = useCallback(
    debounce((searchString) => {
      trackEvent(WEBHOOK_SEARCH, { search_string: searchString });
    }, DEBOUNCE_DELAY),
    []
  );

  function onSearchClick(event) {
    event.stopPropagation();
  }

  function onSearchClear() {
    setSearchText("");
    setSearchedWebhookEvents(WEBHOOK_DEFAULT_EXPANDED_KEYS);
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  // By default user changing values in form doesn't rerender this component
  // but for update webhook page we needed to show active/disabled state of webhook
  // which is dynamically changed
  // so It was not updating the component, hence we added this state
  // the sole purpose is to update the state and rerender the component
  const [_formFields, updateFormFields] = useState(false); // pls dont remove before reading above
  const [hasTouchedUrl, setHasTouchedUrl] = useState(false);

  const validateURL = (url) => {
    if (url.length > 0 && !hasTouchedUrl) {
      setHasTouchedUrl(true);
    }

    const urlRequirements = {
      isHTTPS: url.startsWith("https://"),
      isNotIP: !IP_REGEX.test(url),
      isNotLocalDomain: !LOCALHOST_REGEX.test(url),
    };
    setUrlRequirements(urlRequirements);
  };

  const onCheck = (_selectedKeys, event) => {
    const events = [];
    event.checkedNodes.forEach((item) => {
      if (item.isLeaf) {
        events.push(item.title);
      }
    });
    formInstance.setFieldsValue({ events });
  };

  const calculateCheckedWebhooks = (title) => {
    // if (!formInstance.getFieldValue("events")) {
    //   formInstance.setFieldsValue("envent", []);
    // }
    const values = formInstance.getFieldValue("events") || [];
    // if (!formInstance.getFieldValue("events")?.length > 0) return 0;

    return values.filter((item) => {
      if (item.startsWith(title + ".")) {
        return true;
      }
    }).length;
  };

  const setToastColor = (value) => {
    if (!hasTouchedUrl && !isUpdate) {
      return "";
    }
    if (!hasTouchedUrl && isUpdate) {
      return "success";
    }
    return value ? "success" : "error";
  };

  useEffect(() => {
    formInstance.setFieldsValue({
      is_active: isActive,
      name: webhookName,
      url: webhookURL,
    });

    updateFormFields();
  }, [isActive, webhookURL, webhookName]);

  if (!webhookEvents.length > 0 || loading) {
    return <Loader />;
  }

  const handleExpand = (expandedKeys) => {
    setSearchedWebhookEvents(expandedKeys);
  };

  function handleWebhookDocsClick(){
    trackEvent(WEBHOOK_LEARN_CLICKED);
  }

  function WebhookInfoCard() {
    return (
      <Card className="info-card-container">
        <div className="info-card-content-container">
          <div className="webhook-info-card-header">
            <div className="info-icon-container">
              <i class="ri-information-line info-icon"></i>
            </div>
            <div className="body-r">
              <div className="body-m">{WEBHOOK_INFO_HEADER}:</div>
              {" "}{WEBHOOK_INFO_CONTENT}{" "}
              <a href={WEBHOOK_DOCS_LINK} target="_blank" onClick={handleWebhookDocsClick}><u className="learn-more">{LEARN_MORE_TEXT}</u></a>
              </div>
           </div>
        </div>
      </Card>
    )
  }

  return (
    <div className="webhook-upsert-form-wrapper">
      <Form
        className="webhook-upsert-form"
        onFinish={onSubmitForm}
        form={formInstance}
      > 
        <div style={isExportOverlaySticky ? webhookHeaderStickyStyling : {}}>
          <div style={isExportOverlaySticky ? webhookHeaderItemsStickyStyling : {}}>
            <div className="navigate-icon-wrapper" style={isExportOverlaySticky ? webhookBackIconStickyStyling : {}}>
              <i className="ri-arrow-left-line back-icon" onClick={() => navigate("/developers/webhooks")} />
            </div>
            <div className="upsert-title-with-icon">
              <div className={isExportOverlaySticky ? "sub-section-heading" : "title"}>{title}</div>
            </div>
          </div>
          {isExportOverlaySticky && <Separator />}
        </div>
        <div>
          <WebhookInfoCard/>
        </div>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: EMPTY_REQUIRED_FIELD_MESSAGE },
            { max: 100, message: WEBHOOK_NAME_EXCEED_LIMIT_MESSAGE },
          ]}
        >
          <InputField inputLabel="name" name="name" placeholder="Name to identify your webhook" defaultValue={webhookName} maxlength={101} onKeyDown={handleKeyDown} />
        </Form.Item>
        <Form.Item name="url" rules={webhookUrlRules}>
          <InputField
            inputLabel="URL"
            placeholder="URL for receiving webhook events (e.g. https://avengers.com/webhook)"
            name="url"
            onChange={async (e) => {
              const url = "https://" + e.target.value;
              validateURL(url);
              formInstance.setFieldsValue({ url });
              await formInstance.validateFields(["url"]);
            }}
            defaultValue={webhookURL.replace("https://", "")}
            addonBefore="https://"
            onKeyDown={handleKeyDown}
          />
          <div className="input-suggesting-tags">
            <Tag color={setToastColor(urlRequirements.isNotIP)}>no IP addresses</Tag>
            <Tag color={setToastColor(urlRequirements.isNotLocalDomain)}>no localhost</Tag>
          </div>
        </Form.Item>
        {isUpdate && (
          <Form.Item name="is_active">
            <div className="webhook-state-update-container">
              <Switch
                defaultChecked={isActive}
                onChange={async (is_active) => {
                  await formInstance.setFieldsValue({ is_active });
                  updateFormFields(is_active);
                }}
              />
              <div className="webhook-state-banner">
                {formInstance.getFieldValue("is_active") === true ? (
                  <span className="active-state">Enabled</span>
                ) : (
                  <span className="inactive-state">Disabled</span>
                )}
              </div>
            </div>
          </Form.Item>
        )}
        <Form.Item shouldUpdate>
          {() => (
            <div>
              <label htmlFor="events-tree" className="input-label" style={{ marginBottom: "8px" }}>
                {WEBHOOK_EVENTS_LABEL}
              </label>
              <Tree
                id="events-tree"
                label={WEBHOOK_EVENTS_LABEL}
                checkable
                treeData={(searchText ? filteredTreeData : webhookEvents) || []}
                style={{ padding: "2px" }}
                selectable={false}
                onCheck={onCheck}
                defaultExpandedKeys={WEBHOOK_DEFAULT_EXPANDED_KEYS}
                expandedKeys={searchedWebhookEvents}
                onExpand={handleExpand}
                checkedKeys={formInstance.getFieldValue("events")}
                titleRender={(item) => {
                  if (item.key === "custom-div") {
                    return (
                      <div className="custom-div-container webhook-section-card">
                        {[undefined].map(() => {
                          const totalSelectedItems = formInstance.getFieldValue("events").length;
                          return totalSelectedItems + (totalSelectedItems === 1 ? ` ${WEBHOOK_EVENT} ` : ` ${WEBHOOK_EVENT}s `) + ` ${WEBHOOK_SELECTED}`;
                        })}
                        <span
                          onClick={() => {
                            formInstance.setFieldsValue({ events: [] });
                          }}
                        >
                          <u>{WEBHOOK_CLEAR_SELECTED_EVENTS}</u>
                        </span>
                      </div>
                    );
                  }
                  return (
                    <div className={item.isLeaf ? "webhook-section-card" : item?.key === WEBHOOK_ROOT_KEY ? "webhook-header-card" : 
                      "webhook-section-card root-card-section-card"}>
                      <div className="webhook-event-title">
                        <Typography h4 style={item.isLeaf ? { color: Colors.neutralGrey7 } : {} }>
                          {item.title}
                        </Typography>
                        {!item?.isLeaf && item.key === WEBHOOK_ROOT_KEY && (
                          <TextBox
                            variant={'default-with-search-icon'}
                            placeholder={WEBHOOK_SEARCH_PLACEHOLDER}
                            onClick={onSearchClick}
                            onEnter={setSearchText}
                            onChange={onSearchChange}
                            value={searchText}
                            onClear={onSearchClear}
                            autoFocus={searchText.length > 0}
                            onKeyDown={handleKeyDown}
                            disableEnterIcon
                          />
                        )}
                        {item?.description && (
                          <Tooltip title={item.description}>
                            <Icons.info_icon />
                          </Tooltip>
                        )}
                      </div>
                      {!item.isLeaf && item.key !== "root" && (
                        <div className="side-options">
                          <p>
                            {[null].map(() => {
                              const selectedItems = calculateCheckedWebhooks(item.title);
                              return selectedItems + (selectedItems === 1 ? ` ${WEBHOOK_EVENT} ` : ` ${WEBHOOK_EVENT}s `);
                            })}
                            {WEBHOOK_SELECTED}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          )}
        </Form.Item>
        <p className="error-message-text">{errors.errorMessage} </p>
        <div className="webhook-form-buttons">
          <Separator />
          <div className="webhook-form-buttons-container">
            <InvertedPrimaryButton label={WEBHOOK_CANCEL_BUTTON_TEXT} onClick={onCancel} className="webhook-form-cancel-button" />
            <Form.Item shouldUpdate noStyle>
              {() => (
                <PrimaryButton
                  loading={buttonLoading}
                  label={isUpdate ? WEBHOOK_SAVE_BUTTON_TEXT : WEBHOOK_CREATE_BUTTON_TEXT}
                  htmlType="submit"
                  disabled={!formInstance.getFieldValue("events").length > 0 || (isUpdate ? disableFormSubmit(true) : disableFormSubmit())}
                  onClick={onClickSubmitForm}
                  type="submit"
                  className="webhook-form-submit-button"
                />
              )}
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
