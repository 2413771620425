import { CAMPAIGN_ENDED_INFO_ERROR, SYNC_STATUS, TOP_HEADER_INFO_ERROR, TOP_HEADER_INFO_UPDATED } from "../../constants/campaignTrackingConstants";
import { HeaderTopNav } from "../index";
import { convertTimeToLocale, isCampaignEnded } from "../../utils/util";
import { DATE_TIME_FORMAT_STATUS } from "../../constants/constants";
import { Colors } from "../../styles/colors";

export default function CampaignSyncStatus({ campaignByIdResponse }) {
    if (!!!campaignByIdResponse) {
        return;
    }
    const { synced_at, sync_status } = campaignByIdResponse;
    if (isCampaignEnded(campaignByIdResponse)) {
        return (
            <HeaderTopNav
                title={CAMPAIGN_ENDED_INFO_ERROR(convertTimeToLocale(synced_at, DATE_TIME_FORMAT_STATUS))}
                backgroundColor={Colors.semanticsSecondaryAlert}
                textClass={"body-r top-header-status-text"}
            />
        );
    } 
     if (synced_at) {
        if (sync_status === SYNC_STATUS.FAILED) {
            return (
                <HeaderTopNav
                    title={TOP_HEADER_INFO_ERROR(convertTimeToLocale(synced_at, DATE_TIME_FORMAT_STATUS))}
                    backgroundColor={Colors.semanticsSecondaryError}
                    textClass={"body-r top-header-status-text top-header-status-text-error"}
                />
            );
        } else {
            return (
                <HeaderTopNav
                    title={TOP_HEADER_INFO_UPDATED(convertTimeToLocale(synced_at, DATE_TIME_FORMAT_STATUS), 12)}
                    backgroundColor={Colors.neutralsBackgroundGrey}
                    textClass={"body-r top-header-status-text"}
                />
            );
        }
    } else {
        return <></>;
    }
}