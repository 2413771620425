import React, { useContext, useEffect, useRef, useState } from 'react';
import './CompareReportPage.scss';
import { CompareCreatorsPageHeader, CompareCreatorsProfile, CreatorBioDetails, CreatorProfileSectionBar, Icons, PerformanceMetric, SearchProfileIdentity, TabSwitch, Typography, LineChartDistribution, EmptyTableScreen, ModalDialog, ToastMessage } from '../../components';
import { constructValueForCreatorBio, creatorBioHeaders, creatorBioIconMapping, creatorBioSectionItems, creatorBioType, formatMonth, formatNumber, getCredibilityScorePercentage, getFollowerTypes, getGenderDetails, getLargestValueInFollowerTypes, profileSectionsLabel } from '../../utils/profileHelpers';
import CreatorChartDistribution from '../../components/CreatorChartDistribution/CreatorChartDistribution';
import { ABOUT, AUDIENCE, averageLikesChartDesc, compareCreatorsTabValues, COMPARE_CREATOR_ERROR_STATE, COMPARE_CREATOR_REPORT_STATUS, DELETE_COMPARISON, FOLLOWERS, GET_COMPARE_CREATORS_DELETE_DESC, GROWTH_AND_INTEREST, PERFORMANCE, totalFollowerChartDesc, YES_DELETE, DELETED_COMPARISON, AUDIENCE_GENDER_DISTRIBUTION, totalSubscriberChartDesc } from '../../constants/creatorDiscovery';
import { commaReplacer, shortNumberFormatter, isEmpty } from '../../utils/util';
import AudienceOverlapData from '../../components/AudienceOverlapData/AudienceOverlapData';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteCompareCreatorsLists, getCreatorComparisonById } from '../../api/compareCreatorAPI/compareCreatorAPI';
import { COMPARE_CREATORS_WORKPLATFORMS_MAPPING, COMPARE_CREATOR_DISCRIPTION_MAPPING, CREATOR_AUDIENCE_ATTRIBUTE_MAPPING, DONT_DELETE, UPDATING_CREATORS_DETAIL_ERROR, WORKPLATFORM_IDS } from '../../constants/constants';
import CompareCreatorReportSkeleton from '../../components/CompareCreatorReportSkeleton/CompareCreatorReportSkeleton';
import { AppBar, Toolbar } from '@mui/material';
import { SHOW_TOP_HEADER } from '../../storage/reducers/types';
import { WarehouseContext } from '../../storage/context';

const creatorColor = [
  {
    "color": '#E1F999'
  },
  {
    "color": '#C39EF4'
  },
  {
    "color": '#F0A5B7'
  },
];

const activeDonutChartTabs = [
  {
    label: compareCreatorsTabValues.genderDistribution,
  },
  {
    label: compareCreatorsTabValues.followerTypes,
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <div sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function CompareReportPage() {
  const [profileSections, setProfileSections] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [activeDonutChart, setActiveDonutChart] = useState(compareCreatorsTabValues.genderDistribution);
  const [activeSectionTab, setActiveSectionTab] = useState(null);
  const { comparisonId } = useParams();
  const [isComparisonLoading, setIsComparisonLoading] = useState(false);
  const [comparisonData, setComparisonData] = useState();
  const [creatorComparisonCount, setCreatorComparisonCount] = useState(null);
  const [creatorBioItems, setCreatorBioItems] = useState([]);
  const [credibilityScore, setCredibilityScore] = useState();
  const [majorityFollowerType, setMajorityFollowerType] = useState({});
  const { SUCCESS, FAILURE, PENDING, IN_PROGRESS } = COMPARE_CREATOR_REPORT_STATUS;
  const [showDeleteModal, setShowDeleteModal] = useState({
    showModal: false,
    creators: []
  });
  const [isToastOpen, setIsToastOpen] = useState(false);
  const navigate = useNavigate();
  const DELETE_TOAST_DURATION = 2500;
  const [activeGrowthChart, setActiveGrowthChart] = useState(compareCreatorsTabValues.followerGrowth);
  const [sectionRefs, setSectionRefs] = useState([]);
  const performanceSectionRef = useRef(null);
  const growthSectionRef = useRef(null);
  const audienceSectionRef = useRef(null);
  const aboutSectionRef = useRef(null);
  const followerTypesSectionRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [collapseProfileData, setCollapseProfileData] = useState(false);
  const [showAppBar, setShowAppBar] = useState(false);
  const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
  const activeGrowthChartTabs = [
    {
      label: comparisonData?.work_platform?.id === WORKPLATFORM_IDS.YouTubePlatformId ? compareCreatorsTabValues.subscribersGrowth : compareCreatorsTabValues.followerGrowth,
    },
    {
      label: compareCreatorsTabValues.likesGrowth,
    },
  ];


  useEffect(() => {
    fetchComparisonDataByID();
    const { overview, growth, audience, about } = profileSectionsLabel;
    const profileSectionsBasedOnPlatform = [audience, about, overview, growth];
    setProfileSections(profileSectionsBasedOnPlatform);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(".scrolling-page-content");
      const scrollPositionVertical = element.scrollTop;

      let currentSectionIndex = 0;
      let minDistance = Infinity;
      sectionRefs?.forEach((sectionRef, index) => {
        if (sectionRef.current) {
          const distance = Math.abs(sectionRef.current.getBoundingClientRect().top);
          if (distance < minDistance) {
            minDistance = distance;
            currentSectionIndex = index;
          }
        }
      });

      setActiveSectionIndex(currentSectionIndex);

      if (scrollPositionVertical > 300) {
        setShowAppBar(true);
      } else {
        setShowAppBar(false);
      }

      if (scrollPositionVertical > 300) {
        setCollapseProfileData(true);
      } else {
        setCollapseProfileData(false);
      }

      if (scrollPositionVertical > 300) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    const element = document.querySelector(".scrolling-page-content");
    element?.addEventListener("scroll", handleScroll);

    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, [sectionRefs]);

  async function fetchComparisonDataByID() {
    try {
      setIsComparisonLoading(true);
      const comparisonData = await getCreatorComparisonById({ comparisonId });
      const { data } = comparisonData;
      if (comparisonData.status !== 200) {
        setComparisonData({ status: FAILURE });
        return;
      }

      if (data.status === PENDING || data.status === IN_PROGRESS) {
        dispatchWarehouse({
          type: SHOW_TOP_HEADER,
          payload: {
            scrollable: true,
            title: UPDATING_CREATORS_DETAIL_ERROR.top_header_details.title,
            backgroundColor: UPDATING_CREATORS_DETAIL_ERROR.top_header_details.backgroundColor,
            textClass: UPDATING_CREATORS_DETAIL_ERROR.top_header_details.textClass,
          },
        });
      }

      const growthChartLabelBasedOnPlatform = data?.work_platform?.id === WORKPLATFORM_IDS.YouTubePlatformId ? compareCreatorsTabValues.subscribersGrowth : compareCreatorsTabValues.followerGrowth;
      setActiveGrowthChart(growthChartLabelBasedOnPlatform);
      if (data?.creators && data?.creators !== null) {
        setCreatorComparisonCount(data?.creators?.length);
        setComparisonData(data);
      }
      if (!data.profile_analytics && data.profile_analytics === null) {
        setComparisonData({ status: FAILURE });
        return;
      };

      Object.values(data.profile_analytics).map((creator, index) => {
        creator.profile.audience.gender_age_distribution = getGenderDetails({ data: creator?.profile?.audience?.gender_age_distribution });
      });

      Object.values(data.profile_analytics).map((creator, index) => {
        creator.profile.audience.follower_types = getFollowerTypes({ data: creator?.profile?.audience?.follower_types });
      });

      Object.values(data.profile_analytics).map((creator, index) => {
        creator.profile.reputation_history = creator?.profile?.reputation_history.map(item => ({
          ...item,
          month: formatMonth(item.month)
        }));
      });

      const creatorBioItemsArray = [];
      Object.values(data.profile_analytics).map((creator, index) => {
        const creatorBioSectionContent = [];
        creatorBioSectionItems.forEach((item) => {
          if (creator?.profile.hasOwnProperty(item)) {
            creatorBioSectionContent.push({
              icon: creatorBioIconMapping[item],
              header: creatorBioHeaders[item],
              value: constructValueForCreatorBio(item, creator?.profile[item]),
              showToolTip: item === creatorBioType.location ? true : false,
            });
          }
        });
        creatorBioItemsArray.push(creatorBioSectionContent);
      });

      let credibilityScore = {};
      Object.values(data.profile_analytics).map((creator, index) => {
        credibilityScore[creator.profile.platform_username] = getCredibilityScorePercentage(creator?.profile?.audience?.credibility_score);
      });

      let majorityFollowerType = {};
      Object.values(data.profile_analytics).map((creator, index) => {
        majorityFollowerType[creator.profile.platform_username] = getLargestValueInFollowerTypes(creator?.profile?.audience?.follower_types);
      });

      let sectionTabsRefs = [audienceSectionRef, aboutSectionRef, performanceSectionRef, growthSectionRef];

      setSectionRefs(sectionTabsRefs);
      setCreatorBioItems(creatorBioItemsArray);
      setMajorityFollowerType(majorityFollowerType);
      setCredibilityScore(credibilityScore);
      setComparisonData(data);
    } catch (error) {
      console.error(error);
      setComparisonData({ status: FAILURE });
    } finally {
      setIsComparisonLoading(false);
    }
  }


  const handleHover = (key) => {
    setIsHovered(key);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSectionClick = (sectionLabel, idx) => {
    let sectionObjKey = Object.keys(profileSectionsLabel).find((key) => profileSectionsLabel[key].label === sectionLabel);
    let elementOfIDToScroll = profileSectionsLabel[sectionObjKey].sectionID;
    const element = document.getElementById(elementOfIDToScroll);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setActiveSectionTab(idx);
  };

  if (isComparisonLoading) {
    return (
      <CompareCreatorReportSkeleton isLoading={isComparisonLoading} />
    );
  }

  function RenderCompareCreatorsPageHeader() {
    if (!comparisonData || creatorComparisonCount == null) return;
    return (
      <CompareCreatorsPageHeader
        isComparisonLoading={isComparisonLoading}
        numberOfCreatorsToCompare={creatorComparisonCount}
        status={comparisonData?.status}
        onDeleteButtonClick={() => setShowDeleteModal({ showModal: true, creatorsList: comparisonData })}
        isSampleReport={comparisonData?.is_sample}
        showAppBar={showAppBar}
        created_at={comparisonData?.created_at}
        comparisonId={comparisonId}
      />);
  }

  function RenderStickyProfile({ profileData }) {
    return (
      <AppBar position="fixed" sx={{ transform: showAppBar ? "translateY(99%)" : "translateY(-100%)" }} className="creator-discovery-profile-app-bar">
        <div className='compare-creator-container' >
          {Object.values(profileData).map((profile, index) => {
            const { full_name, is_verified, platform_username, thumbnail_url } = comparisonData?.creators[index];
            const { logo_url, name, id: workPlatformId } = comparisonData?.work_platform;
            const { profile: profileData } = profile;
            return (
              <div className=' compare-creator-details' key={profileData?.id}>
                <CompareCreatorsProfile
                  profileURL={profileData?.url}
                  profileBio={profileData?.introduction}
                  isProfileVerified={is_verified}
                  profileImage={thumbnail_url}
                  fullName={full_name}
                  userName={platform_username}
                  workPlatformLogo={logo_url}
                  workPlatformName={name}
                  workPlatformHandle={profileData?.url}
                  workPlatformId={workPlatformId}
                  showAppBar={showAppBar}
                />
                {index < creatorComparisonCount - 1 ? (
                  <span className="vertical-divider"></span>
                ) : null}
              </div>
            );
          })}
        </div>
      </AppBar>
    );
  }

  function RenderCompareCreatorProfile() {
    if (!comparisonData) return;
    const profileData = isEmpty(comparisonData?.profile_analytics) ? comparisonData?.creators : comparisonData?.profile_analytics;
    if (profileData === undefined) return;
    if (collapseProfileData) {
      return (<RenderStickyProfile profileData={profileData} />);
    }
    return (
      <div className='compare-creator-container' >
        {Object.values(profileData).map((profile, index) => {
          const { full_name, is_verified, platform_username, thumbnail_url } = comparisonData?.creators[index];
          const { logo_url, name, id: workPlatformId } = comparisonData?.work_platform;
          const { profile: profileData } = profile;
          return (
            <div className='compare-creator-details compare-creators-profile-box' key={profileData?.id}>
              <CompareCreatorsProfile
                profileURL={profileData?.url}
                profileBio={profileData?.introduction}
                isProfileVerified={is_verified}
                profileImage={thumbnail_url}
                fullName={full_name}
                userName={platform_username}
                workPlatformLogo={logo_url}
                workPlatformName={name}
                workPlatformHandle={profileData?.url}
                workPlatformId={workPlatformId}
                showAppBar={collapseProfileData}
              />
              {index < creatorComparisonCount - 1 ? (
                <span className="vertical-divider"></span>
              ) : null}
            </div>
          );
        })}
      </div>);
  }

  function RenderCreatorProfileSectionBar() {
    if (!comparisonData || comparisonData.status !== SUCCESS) return;
    return (
      <div className='compare-creator-section-bar' >
        <CreatorProfileSectionBar
          sectionBarLabels={profileSections}
          handleTabHover={handleHover}
          handleMouseTabLeave={handleMouseLeave}
          handleTabClick={handleSectionClick}
          activeTabIndex={activeSectionIndex}
          activeTabHoveredState={isHovered}
          isLoading={isComparisonLoading}
          isSticky={isSticky}
        />
      </div>
    );
  }

  function RenderCreatorAudienceData() {
    if (!comparisonData || comparisonData.status !== SUCCESS) return;
    return (
      <div ref={audienceSectionRef} id='audience-section' className='creator-audience-data' >
        <div className='section-heading' >{AUDIENCE}</div>
        <div className='compare-creator-container'>
          {Object.values(comparisonData.profile_analytics).map((creator, index) => {
            const { name: workPlatformName } = comparisonData.work_platform;
            const { metricName, attributeName } = CREATOR_AUDIENCE_ATTRIBUTE_MAPPING[workPlatformName];
            const { profile } = creator;
            return (
              <div className='compare-creator-details' >
                <PerformanceMetric
                  variant={"metricIcon"}
                  content={<>{formatNumber(profile[attributeName])} <svg width={16} height={16}><circle cx={8} cy={8} r={8} fill={creatorColor[index].color} /> </svg> </>}
                  metricName={metricName}
                  metricIcon={<Icons.users_icon />}
                />
                {index < creatorComparisonCount - 1 ? (
                  <span className="vertical-divider"></span>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function RenderAudienceOverlapData() {
    if (!comparisonData || comparisonData.status !== SUCCESS) return;
    return (
      <AudienceOverlapData shared_followers={comparisonData?.shared_followers} unique_followers={comparisonData?.unique_followers} creator_count={creatorComparisonCount} />
    );
  }

  function RenderCompareCreatorDonutChart() {
    if (!comparisonData || comparisonData.status !== SUCCESS) return;
    const { id: workPlatformId } = comparisonData?.work_platform;
    return (
      <div className='creator-donut-charts-container' >
        {workPlatformId === WORKPLATFORM_IDS.YouTubePlatformId ?
          <div className='audience-distribution-title sub-section-heading' >{AUDIENCE_GENDER_DISTRIBUTION}</div> :
          <TabSwitch
            currentTab={activeDonutChart}
            handleTabChange={(event, newValue) => {
              setActiveDonutChart(newValue);
            }}
            tabs={activeDonutChartTabs}
            width="335px"
          />}
        <TabPanel index={compareCreatorsTabValues.genderDistribution} value={activeDonutChart}
          sx={{ margin: "0px", padding: "0px" }}>
          <div className='compare-creator-container'>
            {Object.values(comparisonData.profile_analytics).map((creator, index) => (
              <div className='compare-creator-details' key={index}>
                <CreatorChartDistribution distributionData={creator.profile.audience.gender_age_distribution} />
                {index < creatorComparisonCount - 1 && (
                  <span className="vertical-divider"></span>
                )}
              </div>
            ))}
          </div>
        </TabPanel>

        <TabPanel index={compareCreatorsTabValues.followerTypes} value={activeDonutChart}
          sx={{ margin: "0px", padding: "0px" }}>
          <div className='compare-creator-container'>
            {Object.values(comparisonData.profile_analytics).map((creator, index) => (
              <div className='compare-creator-details' key={index}>
                <CreatorChartDistribution distributionData={creator.profile.audience.follower_types} />
                {index < creatorComparisonCount - 1 && (
                  <span className="vertical-divider"></span>
                )}
              </div>
            ))}
          </div>
        </TabPanel>
      </div>
    );
  }

  function RenderCreatorAboutSection() {
    if (!comparisonData || comparisonData.status !== SUCCESS) return;
    return (
      <div ref={aboutSectionRef} id='about-section' className='creator-details-container'>
        <div className='section-heading' >{ABOUT}</div>
        <div className='compare-creator-container' >
          {creatorBioItems?.map((creator, index) => (
            <div className='compare-creator-details' key={index} >
              <CreatorBioDetails variant={'vertical'} creatorBioItems={creator} />
              {index < creatorComparisonCount - 1 ? (
                <span className="vertical-divider"></span>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  }

  function handleAutomaticScrollOfFollowerType() {
    const { current } = followerTypesSectionRef;
    if (current) {
      followerTypesSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  function RenderCreatorPerformanceSection() {
    if (!comparisonData || comparisonData.status !== SUCCESS) return;
    return (
      <div ref={performanceSectionRef} id="overview-section" className='creator-details-container creator-performance-section'>
        <div className='section-heading' >{PERFORMANCE}</div>
        <div className='compare-creator-container' >
          {Object.values(comparisonData.profile_analytics)?.map((creator, index) => (
            <div className='compare-creator-details' key={index} >
              <SearchProfileIdentity
                workPlatformMapping={COMPARE_CREATORS_WORKPLATFORMS_MAPPING}
                identityData={creator}
                loading={isComparisonLoading}
                majorityFollowerType={majorityFollowerType[creator.profile.platform_username]}
                followerTypeActionHandler={handleAutomaticScrollOfFollowerType}
                credibilityScore={credibilityScore[creator.profile.platform_username]}
                descriptionMapping={COMPARE_CREATOR_DISCRIPTION_MAPPING}
              />
              {index < creatorComparisonCount - 1 ? (
                <span className="vertical-divider"></span>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  }

  function RenderCreatorGrowthInterestSection() {
    if (!comparisonData || comparisonData.status !== SUCCESS) return;
    return (
      <div ref={growthSectionRef} id={'growth-section'} className='creator-details-container creator-performance-section'>
        <div className='section-heading' >{GROWTH_AND_INTEREST}</div>
        <TabSwitch
          currentTab={activeGrowthChart}
          handleTabChange={(event, newValue) => {
            setActiveGrowthChart(newValue);
          }}
          tabs={activeGrowthChartTabs}
          width={comparisonData?.work_platform?.id === WORKPLATFORM_IDS.YouTubePlatformId ? "323px" : "305px"}
        />
        <TabPanel index={comparisonData?.work_platform?.id === WORKPLATFORM_IDS.YouTubePlatformId ? compareCreatorsTabValues.subscribersGrowth : compareCreatorsTabValues.followerGrowth} value={activeGrowthChart}
          sx={{ margin: "0px", padding: "0px" }}>
          <div className='body-r chart-desc ' >{comparisonData?.work_platform?.id === WORKPLATFORM_IDS.YouTubePlatformId ? totalSubscriberChartDesc : totalFollowerChartDesc}</div>
          <div className='compare-creator-container' >
            {Object.values(comparisonData.profile_analytics)?.map((creator, index) => (
              <div className='compare-creator-details' key={index} >
                <LineChartDistribution
                  width={330}
                  height={225}
                  data={creator?.profile?.reputation_history}
                  xAxisKey={"month"}
                  yAxisKey={CREATOR_AUDIENCE_ATTRIBUTE_MAPPING[comparisonData?.work_platform?.name].attributeName}
                  yTickFormatter={shortNumberFormatter}
                />
                {index < creatorComparisonCount - 1 ? (
                  <span className="vertical-divider"></span>
                ) : null}
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel index={compareCreatorsTabValues.likesGrowth} value={activeGrowthChart}
          sx={{ margin: "0px", padding: "0px" }}>
          <div className='body-r chart-desc' >{averageLikesChartDesc}</div>
          <div className='compare-creator-container' >
            {Object.values(comparisonData.profile_analytics)?.map((creator, index) => (
              <div className='compare-creator-details' key={index} >
                <LineChartDistribution
                  width={320}
                  height={225}
                  data={creator?.profile?.reputation_history}
                  xAxisKey={"month"}
                  yAxisKey={"average_likes"}
                  yTickFormatter={shortNumberFormatter}
                />
                {index < creatorComparisonCount - 1 ? (
                  <span className="vertical-divider"></span>
                ) : null}
              </div>
            ))}
          </div>
        </TabPanel>
      </div>
    );
  }

  function RenderEmptyDataScreen() {
    if (!comparisonData || comparisonData?.status === SUCCESS) return;
    return (
      <div className='compare-creator-empty-screen' >
        {creatorComparisonCount == null ? null : <div className='divider' ></div>}
        <EmptyTableScreen
          icon={<Icons.loading_creators_detail_error />}
          header={COMPARE_CREATOR_ERROR_STATE[comparisonData.status].title}
          content={COMPARE_CREATOR_ERROR_STATE[comparisonData.status].desc}
          showBasicDetails={true}
        />
      </div>
    );
  }

  const onDeleteCompareCreatorLists = async () => {
    try {
      const deleteId = comparisonData.id;
      const response = await deleteCompareCreatorsLists({ deleteCreatorId: deleteId });
      setShowDeleteModal({ isModalOpen: false, creatorsList: {} });
      setIsToastOpen(true);
    } catch (error) {
      console.log(error);
    } finally {
      navigate(`/creators/creator-search`);
    }
  };

  function RenderCreatorActionModals() {
    return (
      <div className='compare-creator-delete-modal' >
        {showDeleteModal.showModal ? <ModalDialog
          variant={"withOutContent"}
          cancelText={DONT_DELETE}
          buttonText={YES_DELETE}
          title={DELETE_COMPARISON}
          additionalText={GET_COMPARE_CREATORS_DELETE_DESC(comparisonData.creators)}
          onAction={onDeleteCompareCreatorLists}
          onClose={() => setShowDeleteModal({ showModal: false, creatorsList: {} })}
          onDont={() => setShowDeleteModal({ showModal: false, creatorsList: {} })}
        /> : null}

        <ToastMessage
          openToast={isToastOpen}
          message={DELETED_COMPARISON}
          duration={DELETE_TOAST_DURATION}
          onCloseToast={() => {
            setIsToastOpen(false);
          }}
          type={'delete'}
        />
      </div>);
  }

  return (
    <div>
      {RenderCompareCreatorsPageHeader()}
      {RenderCompareCreatorProfile()}
      {RenderCreatorProfileSectionBar()}
      <div style={collapseProfileData ? { marginTop: "550px" } : null}>
        {RenderCreatorAudienceData()}
        {RenderAudienceOverlapData()}
        {RenderCompareCreatorDonutChart()}
        {RenderCreatorAboutSection()}
        {RenderCreatorPerformanceSection()}
        {RenderCreatorGrowthInterestSection()}
        {RenderEmptyDataScreen()}
        {RenderCreatorActionModals()}
      </div>
    </div>
  );
}

export default CompareReportPage;
