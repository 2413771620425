import React, { useState } from "react";
import { Route, useRouteMatch, Routes, Navigate } from "react-router-dom";

import { CreateWebhookPage, UpdateWebhookPage, WebhookPage } from "../pages/index";

export default function WebhooksRoutes() {
  return (
    <Routes path="">
      <Route path="edit/:webhookId" element={<UpdateWebhookPage />} />
      <Route path="create/" element={<CreateWebhookPage />} />
      <Route element={<WebhookPage />} index />
      <Route path="*" element={<Navigate to="/webhooks" />} />
    </Routes>
  );
}
