import { Button } from "antd";
import React from "react";
import PropTypes from "prop-types";
import "./PrimaryButton.scss";

export default function PrimaryButton({ label, className = "", icon, hidden = false, ...otherProps }) {
  return (
    <Button
      className={`button button-container ${className} ${otherProps.disabled ? "disabled-button" : ""}`}
      {...otherProps}
      type="primary"
      hidden={hidden}
    >
      {icon}
      {label}
    </Button>
  );
}

PrimaryButton.propTypes = {
  label: PropTypes.any.isRequired,
  className: PropTypes.string,
  icon: PropTypes.element,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

PrimaryButton.defaultProps = {
  className: "",
  hidden: false,
  disabled: false,
  onClick: () => {},
};
