import React from "react";
import { Route, Routes } from "react-router-dom";
import { GettingStartedPage, ProductsPage } from "../pages";
import MeasurementRoutes from "./MeasurementRoutes";

/**
 * Route keys for products
 * This will be the productId on Contentful
 */
export const RouteKeys  = {
    GETTING_STARTED: 'getting-started',
    CONNECT_AUTHENTICATED_APIS: 'connect-&-authenticated-apis',
    ROI_MEASUREMENT: 'roi-measurement',
}

export default function ProductsRoutes() {
    return (
        <Routes path="">
            <Route path="" element={<ProductsPage/>}/>
            <Route path="*" element={<ProductsPage/>}/>
            <Route path={`/${RouteKeys.GETTING_STARTED}`} element={<GettingStartedPage/>}/>
            <Route path={`/${RouteKeys.CONNECT_AUTHENTICATED_APIS}`} element={<GettingStartedPage/>}/>
            <Route path={`/${RouteKeys.ROI_MEASUREMENT}/*`} element={<MeasurementRoutes />} />
        </Routes>
    );
}
