import React, { useState } from "react";
import { ChipsContainer, InputField, PrimaryButton } from "../index";
import {
    Add,
    IDENTIFIER_EXAMPLES,
    ITEMS_ADDED,
    POST_IDENTIFIERS_PLACEHOLDER
} from "../../constants/campaignTrackingConstants";
import './CampaignPostIdentifier.scss';
import { isEmpty } from "../../utils/util";
import { Colors } from "../../styles/colors";

export default function CampaignPostIdentifiers({ trackedPostIdentifiers, onEnter, onClearAllClick, onItemDelete }) {
    const [text, setText] = useState('');

    function getTotalCountMessage() {
        if (trackedPostIdentifiers?.length === 0) {
            return '';
        }
        return ITEMS_ADDED(trackedPostIdentifiers?.length);
    }

    const handleEnterKeyPress = (event) => {
        if (event.key !== "Enter") {
            return;
        }

        onAdd();
    };

    function onAdd() {
        if (trackedPostIdentifiers?.includes(text) || isEmpty(text)) {
            return;
        }

        onEnter(text);
    }

    return (
        <>
            <div className={'post-identifier-container'}>
                <div className={'post-identifier-left'}>
                    <InputField
                        placeholder={POST_IDENTIFIERS_PLACEHOLDER}
                        onChange={(e) => {
                            setText(e.target.value);
                        }}
                        value={text}
                        onKeyDown={handleEnterKeyPress}
                        inputStyle={{ height: 56 }}
                    />
                    {trackedPostIdentifiers && trackedPostIdentifiers.length > 0 ?
                        <ChipsContainer
                            items={trackedPostIdentifiers}
                            totalCountMessage={getTotalCountMessage()}
                            onClearAllClick={onClearAllClick}
                            onItemDelete={onItemDelete}
                            getItemDetails={(item) => ({
                                label: item,
                            })}
                            containerClass={'campaign-post-identifier-chip'}
                            isSingleColumn={false}
                        /> : null}
                </div>
                <PrimaryButton className={'secondary-button'}
                               label={<span className={'body-m'} style={{ color: Colors.mainPrimary }}>{Add}</span>}
                               onClick={onAdd} disabled={isEmpty(text)}/>
            </div>
            <div className={'body-r'} style={{ color: Colors.neutralsSecondaryGrey }}>{IDENTIFIER_EXAMPLES}</div>
        </>
    );
}