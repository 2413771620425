import { Button, Tooltip } from "antd";

export default function PrimaryButtonWithTooltip({ label, className = "", icon, tooltipText, disabled = false, ...otherProps }) {
  return (
    <Tooltip title={tooltipText}>
      <span>
        <Button className={`button-container ${className} ${disabled ? "disabled-button" : ""}`} {...otherProps} type="primary">
          {icon}
          {label}
        </Button>
      </span>
    </Tooltip>
  );
}
