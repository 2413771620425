import React, { useContext, useEffect, useState, useRef } from "react";
import { CopyBlock, dracula, tomorrowNightBlue } from "react-code-blocks";
import { codeBlock } from "../../constants/codeBlock";
import parse from "html-react-parser";
import "./QuickStart.scss";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { UserContext, WarehouseContext } from "../../storage/context";
import Dropdown_but from "../Dropdown/Dropdown";
import { getGenericInfoAPI, getWorkPlatformDetails } from "../../api/sdkConnect/sdkConnectApi";
import IntermediateLoader from "../IntermediateLoader/IntermediateLoader";
import { ADD_USER_STEPS } from "../../storage/reducers/types";
import { flattenObj, getSdkEnv } from "../../utils/util";
import { GETTING_STARTED_STEP_1_CLICK, GETTING_STARTED_STEP_2_CLICK, GETTING_STARTED_STEP_3_DATA_LOAD, trackEvent } from "../../analytics";
import GettingStartedStepMessage from "../GettingStartedStepMessage/GettingStartedStepMessage";
import {
  GETTING_STARTED_API_RESPONSE_INTERVAL,
  GETTING_STARTED_AUTO_SCROLLING_TIMEOUT,
  GETTING_STARTED_STEP_1_CHANGE_INTERVAL,
  GETTING_STARTED_STEP_2_CHANGE_INTERVAL,
  GETTING_STARTED_STEP_CHANGE_INTERVAL,
  Sandbox,
} from "../../constants/constants";
import { forEach } from "lodash";
import { product } from "../../constants/urlConstants";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

const Steps = ({ step, loader, setLoader, setCurrStep, accountId, setAccountId, userResponse, setUserResponse, dispatchWarehouse }) => {
  const [actionButtonClicked, setActionButtonClicked] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selected, setIsSelected] = useState("Identity");
  const [content, setContent] = useState({});
  const [contentId, setContentId] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const userCreds = JSON.parse(localStorage.getItem("SDK_USER_CREDS"));
  const products = JSON.parse(localStorage.getItem("INSIGHTIQ_DD_PRDS"));
  const ref = useRef(null);
  const [isUserIdAlreadyPresent, setIsUserIdAlreadyPresent] = useState(false);
  const [workplatformName, setWorkplatformName] = useState();
  const [isSupported, setIsSupported] = useState(true);

  const pathArrays = ["profiles", "audience", "social/contents", "social/comments", "social/income/transactions", "media/activity/contents"];

  const { warehouse } = useContext(WarehouseContext);

  useEffect(() => {
    step === 1 ? setContent(codeBlock.step1) : step === 2 ? setContent(codeBlock.step2) : step === 3 ? setContent(codeBlock.step3) : setContent("");
    dispatchWarehouse({ type: ADD_USER_STEPS, payload: step });
  }, [step]);

  useEffect(() => {
    setTimeout(() => {
      if (actionButtonClicked) {
        ref.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, GETTING_STARTED_AUTO_SCROLLING_TIMEOUT);
  }, [actionButtonClicked, accountId]);

  useEffect(() => {
    (async () => {
      step === 3 && setLoader(true);
      const userRes = await JSON.parse(localStorage.getItem("User_Response"));
      const user_sdk_creds = await JSON.parse(localStorage.getItem("SDK_USER_CREDS"));
      step === 3 && setUserResponse(userRes);
      setLoader(false);
      if (user_sdk_creds && "mappingObj" in user_sdk_creds) {
        const productSupportMapping = user_sdk_creds.mappingObj;
        setIsSupported(productSupportMapping[selected]);
        setWorkplatformName(user_sdk_creds.workplatformName);
      }
      if (content.products && userRes && !userRes[selected]) {
        setLoader(true);
        let response = await getGenericInfoAPI(pathArrays[products?.indexOf(selected)], userCreds.accountId, setContentId, warehouse);
        if (response) {
          trackEvent(GETTING_STARTED_STEP_3_DATA_LOAD, {
            product: selected.toUpperCase(),
          });
        }
        userRes[selected] = response;
        setUserResponse(userRes);
        localStorage.setItem("User_Response", JSON.stringify(userRes));
        setLoader(false);
      }
    })();
  }, [selected]);

  useEffect(() => {
    if (accountId === undefined && step >= 2) {
      setAccountId(userCreds?.accountId);
    }
    if (accountId !== undefined) {
      setIsSelected("Identity");
      handler({ type: "noclick" });
    }
  }, [accountId]);

  const handler = async ({ type }) => {
    // Local variable (same as state) to handle steps at the same time as we get the response // Not to wait for re-render from useState
    let isUserIdAlreadyPresent;
    setButtonLoading(true);
    step >= 2 && setLoader(true);
    if (type === "click") {
      isUserIdAlreadyPresent = await content.buttonAction(
        warehouse.environment.current,
        setAccountId,
        setLoader,
        setButtonLoading,
        dispatchWarehouse,
        setCurrStep,
        warehouse
      );
      if (isUserIdAlreadyPresent) {
        setIsUserIdAlreadyPresent(true);
        setCurrStep(1);
      }
      setActionButtonClicked(true);
    }
    if (step === 1) {
      trackEvent(GETTING_STARTED_STEP_1_CLICK);
      setTimeout(() => {
        setButtonLoading(false);
        if (!isUserIdAlreadyPresent) setCurrStep(step + 1);
      }, GETTING_STARTED_STEP_1_CHANGE_INTERVAL);
    }
    step === 2 && type === "click" && trackEvent(GETTING_STARTED_STEP_2_CLICK);
    if (step === 2 && accountId !== undefined) {
      setTimeout(() => {
        setButtonLoading(false);
        setCurrStep(step + 1);
      }, GETTING_STARTED_STEP_2_CHANGE_INTERVAL);
      setLoader(true);
      setTimeout(async () => {
        const userRes = await JSON.parse(localStorage.getItem("User_Response"));

        setUserResponse(userRes);
        setLoader(false);
      }, GETTING_STARTED_API_RESPONSE_INTERVAL);
    }
  };

  const truncateToken = (token) => {
    return token.length > 10 ? token.substring(0, 10) + "..." + token.slice(-10) : token;
  };

  const update_data = (data) => {
    var clientId = warehouse?.tenantInfo[0]?.apps[0].id;
    let clientSecrets = warehouse?.tenantInfo[0]?.apps[0].credentials;
    var clientSecret = clientSecrets[0]?.client_secret;
    let new_data = data?.replaceAll("firstName", user?.firstName);
    new_data = new_data.replaceAll("lastName", user?.lastName ? " " + user?.lastName : "");
    new_data = new_data.replaceAll(
      "companyName",
      warehouse?.tenantInfo[0]?.apps[0]?.name ? warehouse?.tenantInfo[0]?.apps[0]?.name : user?.companyName
    );
    new_data = new_data.replaceAll("UserId", userCreds && userCreds.userId ? userCreds.userId : "");
    new_data = new_data.replaceAll("AUTH_KEY", window.btoa(clientId + ":" + clientSecret));
    new_data = new_data.replaceAll("baseURL", warehouse.environmentBaseURL);
    if (userCreds) {
      new_data = new_data.replaceAll("sdkToken", userCreds.userToken);
      new_data = new_data.replaceAll("truncatedSdkToken", truncateToken(userCreds && userCreds.userToken ? userCreds.userToken : ""));
      new_data = new_data.replaceAll("AccountId", accountId);
      new_data = new_data.replaceAll("Environment", warehouse.environment.current);
      new_data = new_data.replaceAll("contentId", contentId);
      new_data = new_data.replaceAll("truncatedSdkToken", truncateToken(userCreds.userToken ? userCreds.userToken : ""));
      new_data = new_data.replaceAll("Products", products);
    }
    return new_data;
  };

  return (
    <div ref={ref}>
      <h1 className="heading">{content.heading}</h1>
      <div style={{ marginLeft: "22px" }}>
        {content.description?.map((data, key) => (
          <p className="description">{parse(data)}</p>
        ))}
      </div>
      {content.subHeading && <h4 className="sub-heading">{content.subHeading}</h4>}
      {step === 3 && content.preActionText && <p className="pre-action-text">{parse(content.preActionText)}</p>}
      {content.products && (
        <div style={{ marginTop: "12px", marginBottom: "35px" }}>
          {content.products && <Dropdown_but products={products} icons={false} selected={selected} setIsSelected={setIsSelected} />}
        </div>
      )}
      {loader ? (
        <IntermediateLoader
          content={
            <p>
              insightIQ might take some time to fetch this data. Usually, you would subscribe to our webhooks instead of waiting for an API response.
              Refer to{" "}
              <a
                target="_blank"
                href="https://docs.insightiq.ai/docs/api-reference/introduction/getting-started-with-insightiq-APIs#5-fetch-data-of-connected-accounts"
              >
                this doc for more explanation.
              </a>
            </p>
          }
        />
      ) : (
        <div>
          {content.subStep && (
            <>
              {content.subStep.map((subStep) => {
                return (
                  <div className="sub-step">
                    <h4 className="sub-heading" ref={ref}>
                      {subStep.heading}
                    </h4>
                    <CopyBlock text={update_data(subStep.code)} theme={dracula} showLineNumbers="true" wrapLines={true} codeBlock language="bash" />
                  </div>
                );
              })}
            </>
          )}
          {content.requestSnippetHeader && <h4 className="sub-heading">{content.requestSnippetHeader}</h4>}
          {content.code && (
            <CopyBlock
              text={content.products ? update_data(content.code[selected]) : update_data(content.code)}
              theme={dracula}
              showLineNumbers="true"
              wrapLines={true}
              codeBlock
              language={step === 2 ? "html" : "bash"}
            />
          )}
          {content.responseSnippetHeader && <h4 className="sub-heading">{content.responseSnippetHeader}</h4>}
          {content.products && userResponse && userResponse[selected] && (
            <>
              <div className="response">
                <div className="selected_text">
                  <p>{selected.toUpperCase()} DATA</p>
                </div>
                <CopyBlock
                  className="code response"
                  text={
                    userResponse[selected].data
                      ? JSON.stringify(userResponse[selected].data, null, 2)
                      : JSON.stringify(userResponse[selected], null, 2)
                  }
                  theme={dracula}
                  showLineNumbers="true"
                  wrapLines={true}
                  codeBlock
                  language="javascript"
                />
              </div>
              {!isSupported && (
                <div className="getting-started-step-error-text">
                  <GettingStartedStepMessage
                    content={content.errorInfo.replace("{{WorkplatformName}}", workplatformName).replace("{{Product}}", selected)}
                    withIcon
                  />
                </div>
              )}
            </>
          )}
          {content.preActionHeading && (
            <h4 className="sub-heading" style={{ marginTop: "20px", marginBottom: "8px" }}>
              {content.preActionHeading}
            </h4>
          )}
          {step !== 3 && content.preActionText && <p className="pre-action-text">{content.preActionText}</p>}
          {content.note && warehouse.environment.current === Sandbox && <p className="note">{content.note}</p>}
          {content.CTA && (
            <PrimaryButton
              className="next_button"
              label={content.CTA}
              loading={buttonLoading}
              onClick={(event) => {
                handler(event);
                window.scrollTo(0, 500);
              }}
            />
          )}
          {/* {isAllStepActive && (
            <div className="getting-started-step-success-text-active">
              {content.success?.map((successMsg) => {
                return <GettingStartedStepMessage content={update_data(successMsg.content)} withSuccessIcon success />;
              })}
              <GettingStartedStepMessage content={content.proceedText} success />
            </div>
          )} */}
          {step === 2 && accountId ? (
            <div className="getting-started-step-success-text">
              {actionButtonClicked &&
                content.success?.map((successMsg) => {
                  return <GettingStartedStepMessage content={update_data(successMsg.content)} withIcon success />;
                })}
              {actionButtonClicked && <GettingStartedStepMessage content={content.proceedText} success />}
            </div>
          ) : (
            step !== 2 && (
              <div className="getting-started-step-success-text">
                {isUserIdAlreadyPresent ? (
                  <GettingStartedStepMessage content={content.errorText} withIcon />
                ) : (
                  <>
                    {actionButtonClicked &&
                      userCreds &&
                      content.success?.map((successMsg) => {
                        return <GettingStartedStepMessage content={update_data(successMsg.content)} withIcon success />;
                      })}
                    {actionButtonClicked && userCreds && <GettingStartedStepMessage content={content.proceedText} success />}
                  </>
                )}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Steps;
