import { Box, Tooltip } from '@material-ui/core'
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { getCreatorAccounts } from "../../api/CampaignTrackingAPI/CampaignTrackingAPI";
import { WarehouseContext } from "../../storage/context";
import "./CreatorAccountGrid.scss";
import { currencyFormatter, formatNumber, percentFormatter } from "../../utils/profileHelpers";
import {
    creatorAccounts,
    CreatorGridColumns,
    MANAGE_CREATOR_DETAILS,
    NO_CREATORS_FOUND,
    SELECT_CREATOR_PLACEHOLDER
} from "../../constants/campaignTrackingConstants";
import { CustomFooter, getSortedHeaderClass } from "../../utils/DataGridUtils";
import { isEmpty } from "../../utils/util";
import { Colors } from "../../styles/colors";
import UserRow from "../UserRow/UserRow";
import TextBox from "../InsightIQ/TextBox/TextBox";
import { useNavigate } from "react-router-dom";
import { ContextualMenu } from "../index";

export default function CreatorAccountGrid({
                                                searchModel,
                                               sortModel,
                                               campaignId,
                                               filterBody,
                                               hasCampaignEnded,
                                               manageCreatorMenu,
                                               isTrialCampaign
                                           }) {
    const allowedSorts = ["desc", "asc"];
    const PAGE_SIZE = 10;
    const ROW_HEIGHT = 132;

    const { warehouse } = useContext(WarehouseContext);
    const [rows, setRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(0); // Adjusted to 0-based indexing
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const {searchText, setSearchText} = searchModel;
    const navigate = useNavigate();
    const { creatorSortModel, setCreatorSortModel } = sortModel;

    useEffect(() => {
        fetchData().then(() => {
        });
    }, [pageNumber, creatorSortModel, searchText]);

    useEffect(() => {
        if (!!!filterBody) {
            fetchData().then(() => {
            });
        }
    }, [filterBody]);

    async function fetchData() {
        try {
            let sort_by = null,
                sort_desc = null;
            if (creatorSortModel.length === 1) {
                sort_by = creatorSortModel[0].field;
                sort_desc = creatorSortModel[0].sort === "desc";
            }
            setLoading(true);
            const res = await getCreatorAccounts({
                offset: pageNumber * PAGE_SIZE,
                limit: PAGE_SIZE,
                campaignId,
                filterBody,
                sort_by,
                sort_desc,
                search_string: searchText,
                warehouse,
            });
            const { data, total_count } = res;
            setRows(data);
            setTotalRows(total_count);
        } catch (e) {
            // TODO show error screen
            console.error("Error fetching data:", e);
        } finally {
            setLoading(false);
        }
    }

    const handlePageChange = (newPageNumber) => {
        setPageNumber(newPageNumber);
    };

    function renderCreatorAccountCell(params) {
        const { thumbnail_url, platform_username, is_verified, work_platform_id, url } = params.row;
        let { fullname } = params.row;
        if (isEmpty(fullname)) {
            fullname = platform_username;
        }
        return (
            <a href={url} target="_blank">
                <UserRow
                    name={fullname}
                    username={platform_username}
                    is_verified={is_verified}
                    work_platform_id={work_platform_id}
                    thumbnail_url={thumbnail_url}
                />
            </a>
        )
    }

    function renderTotalClicksCell(params) {
        return (
            <div className={"cell-content body-r"}>
                {!isEmpty(params.row.total_link_clicks) ? (
                    <>
                        {formatNumber(params.row.total_link_clicks)}
                    </>
                ) : (
                    "-"
                )}
            </div>
        );
    }

    function renderTotalOrdersCell(params) {
        return (
            <div className={"cell-content body-r"}>
                {!isEmpty(params.row.total_orders_placed) ? (
                    <>
                        {formatNumber(params.row.total_orders_placed)}
                    </>
                ) : (
                    "-"
                )}
            </div>
        );
    }

    function renderTotalSalesCell(params) {
        return (
            <div className={"cell-content body-r"}>
                {!isEmpty(params.row.total_sales) ? (
                    <>
                        {currencyFormatter.format(params.row.total_sales)}
                    </>
                ) : (
                    "-"
                )}
            </div>
        );
    }

    function renderCreatorCostCell(params) {
        if (isTrialCampaign) {
            return null;
        }
        const addCostComponent = (
            <div
                className={"div-add-cost body-m"}
                style={{ cursor: "pointer", color: Colors.mainPrimary }}
                onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/creators/campaigns/creator-cost-page/${campaignId}`)
                }}
            >
                + Add cost
            </div>
        );
        return (
            <div className={"cell-content body-r"}>
                {!isEmpty(params.row.cost) ? (
                    <>
                        {currencyFormatter.format(params.row.cost)}
                    </>
                ) : (
                    hasCampaignEnded ? "-" : addCostComponent
                )}
            </div>
        );
    }

    function renderRoiCell(params) {
        const { return_on_investment, cost } = params.row;
        return (
            <div className={"cell-content body-r"}>
                {!isEmpty(return_on_investment) && !isEmpty(cost) ? (
                    <span
                        style={{ color: `${return_on_investment <= 0 ? Colors.semanticsPrimaryError : Colors.semanticsPrimarySuccess}` }}
                        className={'body-b'}>
                        {percentFormatter.format(return_on_investment / 100)}
                    </span>
                ) : (
                    "-"
                )}
            </div>
        );
    }

    const commonHeaderProps = {
        flex: 1,
        headerClassName: "subtitle-m mui-data-grid-header hideRightSeparator",
        headerAlign: "right",
        align: "right",
    };

    const columns = [
        {
            field: CreatorGridColumns.ID.field,
            headerName: CreatorGridColumns.ID.header,
            hide: true,
        },
        {
            ...commonHeaderProps,
            align: "left",
            field: CreatorGridColumns.CREATOR_ACCOUNT.field,
            headerAlign: "left",
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(creatorSortModel, CreatorGridColumns.CREATOR_ACCOUNT.field)}`,
            headerName: CreatorGridColumns.CREATOR_ACCOUNT.header,
            minWidth: 380,
            renderCell: renderCreatorAccountCell,
            sortable: true,
        },
        {
            ...commonHeaderProps,
            field: CreatorGridColumns.TOTAL_CLICKS.field,
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(creatorSortModel, CreatorGridColumns.TOTAL_CLICKS.field)}`,
            headerName: CreatorGridColumns.TOTAL_CLICKS.header,
            minWidth: 30,
            renderCell: renderTotalClicksCell,
            sortable: true,
        },
        {
            ...commonHeaderProps,
            field: CreatorGridColumns.TOTAL_ORDERS.field,
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(creatorSortModel, CreatorGridColumns.TOTAL_ORDERS.field)}`,
            headerName: CreatorGridColumns.TOTAL_ORDERS.header,
            minWidth: 30,
            renderCell: renderTotalOrdersCell,
            sortable: true,
        },
        {
            ...commonHeaderProps,
            field: CreatorGridColumns.TOTAL_SALES.field,
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(creatorSortModel, CreatorGridColumns.TOTAL_SALES.field)}`,
            headerName: CreatorGridColumns.TOTAL_SALES.header,
            minWidth: 30,
            renderCell: renderTotalSalesCell,
            sortable: true,
        },
        {
            ...commonHeaderProps,
            field: CreatorGridColumns.CREATOR_COST.field,
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(creatorSortModel, CreatorGridColumns.CREATOR_COST.field)}`,
            headerName: CreatorGridColumns.CREATOR_COST.header,
            minWidth: 30,
            renderCell: renderCreatorCostCell,
            sortable: true,
        }, {
            ...commonHeaderProps,
            field: CreatorGridColumns.ROI.field,
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(creatorSortModel, CreatorGridColumns.ROI.field)}`,
            headerName: (<>
                {CreatorGridColumns.ROI.header}&nbsp;
                <Tooltip placement='bottom-start' title={'Return on investment per creator account.'}>
                    <i className="ri-information-line info-icon"></i>
                </Tooltip>
            </>),
            minWidth: 30,
            renderCell: renderRoiCell,
            sortable: true,
        }
    ];

    return (
        <div className={"creator-account-data-grid-container"}>
            <Box className="your-campaign-title add-post-but-details">
                <Box id={"post-count"} className="section-heading">
                    {totalRows} {creatorAccounts}
                </Box>
                {(hasCampaignEnded || isTrialCampaign) ? null : (
                    <ContextualMenu
                        onClickOverride={() => {
                        }}
                        variant={"button"}
                        menuItems={manageCreatorMenu}
                        buttonLabel={
                            <span className={"body-m"} style={{ color: Colors.mainPrimary }}>
                                    <i className="ri-edit-line"/>{` ${MANAGE_CREATOR_DETAILS}`}
                                </span>
                        }
                    />
                )}
            </Box>

            <TextBox
                variant={'short'}
                placeholder={SELECT_CREATOR_PLACEHOLDER}
                onEnter={setSearchText}
                value={searchText}
                onClear={() => setSearchText("")}
            />
            <DataGrid
                className={"mui-data-grid"}
                columns={columns}
                components={{
                    Footer: (props) => <CustomFooter totalRows={totalRows} pageSize={PAGE_SIZE}
                                                     handlePageChange={handlePageChange} pageNumber={pageNumber}/>,
                }}
                disableColumnMenu
                disableSelectionOnClick
                getRowHeight={() => ROW_HEIGHT}
                getRowId={(row) => row.id}
                initialState={{
                    sorting: {
                        sortModel: creatorSortModel,
                    },
                }}
                loading={isLoading}
                onPageChange={handlePageChange}
                onSortModelChange={setCreatorSortModel}
                page={pageNumber}
                pageSize={PAGE_SIZE}
                pagination
                paginationMode={"server"}
                rowCount={totalRows}
                sortModel={creatorSortModel}
                sortingMode={"server"}
                sortingOrder={allowedSorts}
                sx={{
                    '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    }
                }}
                rows={rows}
                localeText={{
                    noRowsLabel: (
                        <span className={"body-m"} style={{ color: Colors.neutralsSecondaryGrey }}>
                          {NO_CREATORS_FOUND}
                        </span>
                    ),
                }}
            />
        </div>
    );
}
