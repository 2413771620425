import { campaignPageTabValues } from "../../constants/campaignTrackingConstants";
import {
    CLEAR_CAMPAIGN_FORM,
    CLEAR_STORE_DETAILS,
    SET_CAMPAIGN_FORM,
    SET_CAMPAIGN_URL,
    SET_POST_IDENTIFIERS,
    SET_TRACKED_CREATORS,
    SHOW_CAMPAIGN_ERROR,
    UPDATE_FEATURE,
    UPDATE_STORE_DETAILS,
    SET_CAMPAIGN_ACTIVE_TAB,
    SET_CSV_CREATORS_LIST,
    SET_PROFILES_CSV
} from "./types";

export const DEFAULT_CAMPAIGN_TRACKING_STATE = {
    campaignForm: {
        name: null,
        brand: {
            name: "",
            value: ""
        },
        date: {
            fromDate: "",
            toDate: ""
        },
        cost: "",
        postURLs : [],
        csvFile: null,
    },
    trackedCreators: [],
    csvCreatorsList:[],
    profilesCsv: null,
    postIdentifiers: null,
    campaignURL: '',
    showCampaignError: "",
    features : {
        CONTENT_AUDIENCE_DEMOGRAPHICS : false
    },
    storeDetails : {
        storeLink : "",
        isVerified : false,
    },
    campaignPageActiveTab : campaignPageTabValues.trackPosts
};

export function campaignTrackingReducer(state, action) {
    switch (action.type) {
        case SET_CAMPAIGN_URL:
            return {
                ...state,
                campaignURL: action.payload,
            };
        case SET_TRACKED_CREATORS:
            return {
                ...state,
                trackedCreators: action.payload,
            };
        case SET_POST_IDENTIFIERS:
            return {
                ...state,
                postIdentifiers: action.payload,
            };
        case SET_CAMPAIGN_FORM:
            return {
                ...state,
                campaignForm: {
                    ...state.campaignForm,
                    ...action.payload,
                },
            };
        case CLEAR_CAMPAIGN_FORM:
            return {
                ...state,
                campaignForm: DEFAULT_CAMPAIGN_TRACKING_STATE.campaignForm,
            };
        case SHOW_CAMPAIGN_ERROR:
            return {
                ...state,
                showCampaignError: action.payload,
            };
        case UPDATE_FEATURE:
            return {
                ...state,
                features: action.payload ,
            };
        case UPDATE_STORE_DETAILS:
            return {
                ...state,
                storeDetails: action.payload ,
            };
        case CLEAR_STORE_DETAILS:
            return {
                ...state,
                storeDetails: DEFAULT_CAMPAIGN_TRACKING_STATE.storeDetails,
            };
        case SET_CAMPAIGN_ACTIVE_TAB :{
            return {
                ...state,
                campaignPageActiveTab : action.payload
            };
        }
        case SET_CSV_CREATORS_LIST:
            return {
                ...state,
                csvCreatorsList: action.payload,
            };
        case SET_PROFILES_CSV:
            return {
                ...state,
                profilesCsv: action.payload,
            };
        default:
            return state;
    }
}