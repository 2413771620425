import {
    currentDashboardURL,
    MEASUREMENT_DASHBOARD_BASE_URL,
    URL_GET_ALL_CAMPAIGN_LINKS,
    URL_GET_ALL_PROMOCODE_STORES,
    URL_GET_ALL_PROMOCODES,
    URL_GET_PROMOCODES_METRICS
} from '../endpoints';
import { createClient as createCMAClient } from 'contentful-management';
import { createClient } from 'contentful';
import { getClientIDAndSecret, isEmpty } from '../../utils/util';
import { getBasicAuthInstance } from "../axiosInstance";

const axios = require('axios'); // Import axios at the top of your file

const {
    REACT_APP_PRODUCT_LIST_CONTENTFUL_SPACE_ID: spaceId,
    REACT_APP_PRODUCT_LIST_CONTENTFUL_ACCESS_TOKEN: accessToken,
    REACT_APP_PRODUCT_LIST_CONTENTFUL_MANAGEMENT_TOKEN: managementToken,
    REACT_APP_ENVIRONMENT: appEnvironment,
    NODE_ENV: nodeEnv,
} = process.env;

const WARN_MESSAGE_1 = 'You are in development mode without defining a Contentful management token. ' +
    'This will query from dev. Please define a management token in the environment but do not commit it, ' +
    'else it will query from PROD.';

const WARN_MESSAGE_2 = 'Accessing product data using Contentful management token. ' +
    'You will get the data from the dev environment on Contentful.';

const PRODUCT_QUERY_PARAMETERS = {
    environment: 'master',
    content_type: 'products',
    select: 'fields',
    order: 'fields.sortOrder',
};


const MEASUREMENT_QUERY_PARAMETERS = {
    environment: 'master',
    content_type: 'roiMeasurement',
    select: 'fields',
    order: 'fields.sortOrder',
};

const CSV_EXPORT_LIMIT = 1000;

function getRichTextContent(fields, item) {
    return fields[item].content[0].content[0].value;
}

function createThumbnailLinks(assets, data) {
    const thumbnailMap = new Map(assets.map(({ sys: { id }, fields: { file: { url } } }) => [id, url]));

    data.forEach((product) => {
        const { thumbnailId } = product;
        if (thumbnailMap.has(thumbnailId)) {
            product.thumbnailUrl = `https:${thumbnailMap.get(thumbnailId)}`;
            delete product.thumbnailId;
        }
    });
}

function localeRemover(entry) {
    const processedFields = {};
    // Iterate through each field of the entry
    Object.keys(entry.fields).forEach(fieldName => {
        // Exclude the locale key ('en-US') from the field
        processedFields[fieldName] = entry.fields[fieldName]['en-US'];
    });
    return { ...entry, fields: processedFields };
}

async function fetchProductList(queryParameters) {
    const { select, content_type, environment, order } = queryParameters;
    const client = createClient({
        space: spaceId,
        environment,
        accessToken,
    });

    return client.getEntries({
        content_type,
        select,
        order,
    });
}

async function fetchProductList_dev(queryParameters) {
    const { select, content_type, order } = queryParameters;
    const client = createCMAClient({
        accessToken: managementToken
    });
    try {
        // Retrieve the space
        const space = await client.getSpace(spaceId);

        // Retrieve the specified environment
        const devEnvironment = await space.getEnvironment('dev');

        // Fetch entries from the 'products' content type
        const entries = await devEnvironment.getEntries({
            content_type,
            select,
            order,
        });

        const processedEntries = entries.items.map(localeRemover);

        const assetIds = processedEntries.map(entry => entry.fields.thumbnail.sys.id);

        // Fetch assets that are linked to the extracted asset IDs
        const assets = await devEnvironment.getAssets({
            'sys.id[in]': assetIds.join(',')
        });
        const processedAssets = assets.items.map(localeRemover);

        // Return the items array from the response
        return {
            items: processedEntries,
            includes: { Asset: processedAssets },
        };

    } catch (error) {
        console.error('Error fetching products from the specified environment:', error);
        throw error;
    }
}

async function fetchProduct(productId, queryParameters) {
    const { select, content_type, environment } = queryParameters;
    const client = createClient({
        space: spaceId,
        environment,
        accessToken,
    });

    return client.getEntries({
        content_type,
        select,
        'fields.productId': productId,
    });
}

async function fetchProduct_dev(productId, queryParameters) {
    const { select, content_type } = queryParameters;

    const client = createCMAClient({
        accessToken: managementToken
    });
    try {
        // Retrieve the space
        const space = await client.getSpace(spaceId);

        // Retrieve the specified environment
        const devEnvironment = await space.getEnvironment('dev');

        // Fetch entries from the 'products' content type
        const entries = await devEnvironment.getEntries({
            content_type,
            select,
            'fields.productId': productId,
        });

        const processedEntries = entries.items.map(localeRemover);

        // Return the items array from the response
        return {
            items: processedEntries,
        };

    } catch (error) {
        console.error('Error fetching product from the specified environment:', error);
        throw error;
    }
}

export async function getProductList() {
    try {
        let response;
        // Management token should be empty only for PROD hosts
        if (appEnvironment === 'prod') {
            if (nodeEnv === 'development') {
                console.warn(WARN_MESSAGE_1);
            }
            response = await fetchProductList(PRODUCT_QUERY_PARAMETERS);
        } else {
            console.warn(WARN_MESSAGE_2);
            response = await fetchProductList_dev(PRODUCT_QUERY_PARAMETERS);
        }

        const {
            items,
            includes: { Asset: assets },
        } = response;

        const data = items.map((entry) => {
            const {
                fields: {
                    title,
                    dataCategory,
                    redirectToDocs,
                    pathToPage,
                    hasPlayground,
                    tags,
                    thumbnail,
                    productId,
                }
            } = entry;
            return {
                title,
                description: getRichTextContent(entry.fields, 'description'),
                dataCategory,
                redirectToDocs,
                pathToPage,
                hasPlayground,
                thumbnailId: thumbnail.sys.id,
                tags,
                productId,
            };
        });

        createThumbnailLinks(assets, data);

        return data;
    } catch (error) {
        console.error('Error fetching product data:', error);
        throw error;
    }
}

export async function getProduct(productId) {
    try {
        let response;
        // Management token should be empty only for PROD hosts
        if (appEnvironment === 'prod') {
            if (nodeEnv === 'development') {
                console.warn(WARN_MESSAGE_1);
            }
            response = await fetchProduct(productId, PRODUCT_QUERY_PARAMETERS);
        } else {
            console.warn(WARN_MESSAGE_2);
            response = await fetchProduct_dev(productId, PRODUCT_QUERY_PARAMETERS);
        }

        const { items } = response;

        if (isEmpty(items)) {
            return null;
        }

        const {
            fields: {
                title,
                dataCategory,
                redirectToDocs,
                pathToPage
            }
        } = items[0];

        return {
            title,
            description: getRichTextContent(items[0].fields, 'description'),
            dataCategory,
            redirectToDocs,
            pathToPage
        };
    } catch (error) {
        console.error('Error fetching product data:', error);
        throw error;
    }
}

export async function getMeasurementProductsList() {
    try {
        let response;
        if (appEnvironment === 'prod') {
            if (nodeEnv === 'development') {
                console.warn(WARN_MESSAGE_1);
            }
            response = await fetchProductList(MEASUREMENT_QUERY_PARAMETERS);
        } else {
            console.warn(WARN_MESSAGE_2);
            response = await fetchProductList_dev(MEASUREMENT_QUERY_PARAMETERS);
        }

        const {
            items,
            includes: { Asset: assets },
        } = response;

        const data = items.map((entry) => {
            const {
                fields: {
                    title,
                    sortOrder,
                    thumbnail,
                    redirectToDocs,
                    pathToPage,
                    tags,
                    productId,
                }
            } = entry;
            return {
                description: getRichTextContent(entry.fields, 'description'),
                redirectToDocs,
                pathToPage,
                sortOrder,
                thumbnailId: thumbnail.sys.id,
                title,
                tags,
                productId,
            };
        });

        createThumbnailLinks(assets, data);

        return data;
    } catch (error) {
        console.error('Error fetching measurement product data:', error);
        throw error;
    }
}

export async function getMeasurementProduct(productId) {
    try {
        let response;
        if (appEnvironment === 'prod') {
            if (nodeEnv === 'development') {
                console.warn(WARN_MESSAGE_1);
            }
            response = await fetchProduct(productId, MEASUREMENT_QUERY_PARAMETERS);
        } else {
            console.warn(WARN_MESSAGE_2);
            response = await fetchProduct_dev(productId, MEASUREMENT_QUERY_PARAMETERS);
        }

        const { items } = response;

        if (isEmpty(items)) {
            return null;
        }

        const {
            fields: {
                title,
                dataCategory,
                redirectToDocs,
                pathToPage
            }
        } = items[0];

        return {
            title,
            description: getRichTextContent(items[0].fields, 'description'),
            dataCategory,
            redirectToDocs,
            pathToPage
        };
    } catch (error) {
        console.error('Error fetching product data:', error);
        throw error;
    }
}

export async function getAllShortUrlDomains({ warehouse }) {
    const { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
    const api = getBasicAuthInstance(clientId, clientSecret, currentDashboardURL);

    const url = new URL(`${warehouse.environmentBaseURL}/${URL_GET_ALL_CAMPAIGN_LINKS}/short-url-domains`);
    url.searchParams.append("sort_by", 'DOMAIN');
    url.searchParams.append("sort_desc", false.toString());

    try {
        const response = await api.get(url.href);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.message;
    }
}

export async function exportCampaignLinksCsv({
                                                 created_from,
                                                 created_to,
                                                 domain_names,
                                                 search_string,
                                                 sort_by,
                                                 sort_desc,
                                                 warehouse,
                                             }) {
    const { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
    const api = getBasicAuthInstance(clientId, clientSecret, currentDashboardURL);

    const url = `${warehouse.environmentBaseURL}/${URL_GET_ALL_CAMPAIGN_LINKS}/export`;
    try {
        const postObj = {};

        // Uncomment if you have time and time zone
        // created_from && (postObj.created_from = created_from.split("+")[0].replace("Z", ""));
        // created_to && (postObj.created_to = created_to.split("+")[0].replace("Z", ""));

        !isEmpty(created_from) && (postObj.created_from = created_from);
        !isEmpty(created_to) && (postObj.created_to = created_to);
        !isEmpty(domain_names) && (postObj.domain_names = domain_names);
        !isEmpty(search_string) && (postObj.search_string = search_string);
        !isEmpty(sort_by) && (postObj.sort_by = sort_by);
        !isEmpty(sort_desc) && (postObj.sort_desc = sort_desc);

        postObj.limit = CSV_EXPORT_LIMIT;
        const response = await api.post(url, postObj);
        if (response.status === 200) {
            return response;
        } else {
            throw new Error("API request failed with status " + response.status);
        }
    } catch (error) {
        throw new Error(error.response ? error.response.data : error.message);
    }

}

export async function getLinksMetrics({
                                          created_from,
                                          created_to,
                                          warehouse,
}) {
    const { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
    const api = getBasicAuthInstance(clientId, clientSecret, currentDashboardURL);

    const url = new URL(`${warehouse.environmentBaseURL}/${URL_GET_ALL_CAMPAIGN_LINKS}/metrics`);
    !isEmpty(created_from) && url.searchParams.append("created_from", created_from);
    !isEmpty(created_to) && url.searchParams.append("created_to", created_to);

    try {
        const response = await api.get(url.href);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.message;
    }
}

export async function getAllCampaignLinks({
                                              created_from,
                                              created_to,
                                              domain_names,
                                              limit,
                                              offset,
                                              search_string,
                                              sort_by,
                                              sort_desc,
                                              warehouse,
                                          }) {
    const { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
    const api = getBasicAuthInstance(clientId, clientSecret, currentDashboardURL);

    const url = new URL(`${warehouse.environmentBaseURL}/${URL_GET_ALL_CAMPAIGN_LINKS}`);

    !isEmpty(created_from) && url.searchParams.append("created_from", created_from);
    !isEmpty(created_to) && url.searchParams.append("created_to", created_to);
    !isEmpty(domain_names) && url.searchParams.append("domain_names", domain_names);
    !isEmpty(limit) && url.searchParams.append("limit", limit);
    !isEmpty(offset) && url.searchParams.append("offset", offset);
    !isEmpty(search_string) && url.searchParams.append("search_string", search_string);
    !isEmpty(sort_by) && url.searchParams.append("sort_by", String(sort_by).toUpperCase());
    !isEmpty(sort_desc) && url.searchParams.append("sort_desc", Boolean(sort_desc).toString());
    try {
        const response = await api.get(url.href);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.message;
    }
}

export async function getPromocodesMetrics({ warehouse, store_id, from_date, to_date }) {
    const { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
    const api = getBasicAuthInstance(clientId, clientSecret, currentDashboardURL);

    const url = new URL(`${warehouse.environmentBaseURL}/${URL_GET_PROMOCODES_METRICS(store_id)}`);
    !isEmpty(from_date) && url.searchParams.append('from_date', from_date);
    !isEmpty(to_date) && url.searchParams.append('to_date', to_date);

    try {
        const response = await api.get(url.href);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("No API credentials found");
        }
    } catch (error) {
        throw error.message;
    }
}

export async function getAllPromocodes({ warehouse, search_string, limit, offset, store_id, from_date, to_date }) {
    const { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
    const api = getBasicAuthInstance(clientId, clientSecret, currentDashboardURL);

    const url = new URL(`${warehouse.environmentBaseURL}/${URL_GET_ALL_PROMOCODES(store_id)}`);

    !isEmpty(search_string) && url.searchParams.append('search_string', search_string);
    !isEmpty(limit) && url.searchParams.append('limit', limit);
    !isEmpty(offset) && url.searchParams.append('offset', offset);
    !isEmpty(from_date) && url.searchParams.append('from_date', from_date);
    !isEmpty(to_date) && url.searchParams.append('to_date', to_date);

    try {
        const response = await api.get(url.href);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('No API credentials found');
        }
    } catch (error) {
        throw error.message;
    }
}

export const getAllStores = async ({ warehouse }) => {
    const { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
    const api = getBasicAuthInstance(clientId, clientSecret, currentDashboardURL);

    const url = new URL(`${warehouse.environmentBaseURL}/${URL_GET_ALL_PROMOCODE_STORES}?limit=100&offset=0`);

    try {
        const response = await api.get(url.href);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('No API credentials found');
        }
    } catch (error) {
        throw error.message;
    }
};

export const exportPromocodeCsv = async ({ warehouse, store_id, exportPayload }) => {
    const { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
    const api = getBasicAuthInstance(clientId, clientSecret, currentDashboardURL);
    const url = new URL(`${warehouse.environmentBaseURL}/${MEASUREMENT_DASHBOARD_BASE_URL}/${store_id}/discounts/export`);
    try {
        const response = await api.post(url.href, exportPayload);
        if (response.status === 200) {
            return response;
        } else {
            throw new Error('No API credentials found');
        }
    } catch (error) {
        throw error.message;
    }
};
