import React, { useRef } from "react";
import { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { emailVerificationApi } from "../../api/index";

import { Background, Card, Typography, OTPInputField, PrimaryButton, NavigationHeader, TextLink } from "../../components";
import "./CheckYourEmailNewPassword.scss";
import { resetPasswordApi } from "../../api/index";
import { useToast } from "../../hooks/useToast";
import { EMAIL_RESEND_MESSAGE } from "../../constants/toastMessages";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../constants/constants";
import {
  RESET_PASSWORD_EMAIL_RESEND_CLICK,
  RESET_PASSWORD_EMAIL_RESEND_FAILURE,
  RESET_PASSWORD_EMAIL_RESEND_SUCCESS,
  trackEvent,
} from "../../analytics";

export default function CheckYourEmailNewPassword(props) {
  const [userEmail, setUserEmail] = useState("");
  let navState = useRef(useLocation().state);
  const toast = useToast();
  const navigate = useNavigate();

  // let {
  //   state: { email: userEmail, password: userPassword = "password" },
  // } = {
  //   state: { email: "test@test.com", userPassword: "password" },
  // };

  const resentResetPasswordMail = async () => {
    trackEvent(RESET_PASSWORD_EMAIL_RESEND_CLICK, { email: userEmail });
    try {
      const response = await resetPasswordApi.resetPassword(userEmail);
      trackEvent(RESET_PASSWORD_EMAIL_RESEND_SUCCESS, { email: userEmail });
      toast(EMAIL_RESEND_MESSAGE);
      return;
    } catch (e) {
      console.log(e);
      trackEvent(RESET_PASSWORD_EMAIL_RESEND_FAILURE, { email: userEmail, failure_reason: e });
    }
  };

  useEffect(() => {
    if (!navState.current) navigate("/sign-in");
    else {
      setUserEmail(navState.current.email);
    }
  }, []);

  return (
    <Background className="email-verification-page">
      <Helmet>
        <title>{getPageTitle("PASSWORD_RESET_VERIFICATION", "")}</title>
      </Helmet>
      <NavigationHeader />
      <div className="email-verification-container">
        <div className="content-card-container">
          <Typography h1>Check your email</Typography>
          <p>
            Please click the link sent on <b>{userEmail || "email"}</b> to set a new password. Don’t forget to check the spam folder 😉
          </p>
          {/* <OTPInputField /> */}
          <span onClick={resentResetPasswordMail} className="text-link">
            Resend link
          </span>
        </div>
      </div>
    </Background>
  );
}
