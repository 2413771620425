import { Chip, Grid, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import "./AccountStatusBtn.scss";
import {
  AccountStatus,
  CONNECTED_TOOLTIP_MESSAGE,
  DISCONNECTED_TOOLTIP_MESSAGE_1,
  DISCONNECTED_TOOLTIP_MESSAGE_2,
  DisconnectionSource,
  EXPIRED_TOOLTIP_MESSAGE_1,
  EXPIRED_TOOLTIP_MESSAGE_2,
  REMOVED_TOOLTIP_MESSAGE_1,
  REMOVED_TOOLTIP_MESSAGE_2,
} from "../../constants/constants";
import { getUserName } from "../../utils/profileHelpers";
import { Info } from "@mui/icons-material";
import MenuButton from "../MenuButton/MenuButton";

const statusMapping = {
  [AccountStatus.CONNECTED.key]: {
    cssClass: "connected",
  },
  [AccountStatus.NOT_CONNECTED.key]: {
    cssClass: "disconnected",
  },
  [AccountStatus.SESSION_EXPIRED.key]: {
    cssClass: "session-expired",
  },
  [AccountStatus.REMOVED.key]: {
    cssClass: "removed",
  },
};

function AccountStatusBtn({ identityData, label, status, icon, active, click, onRemoveClick, disconnectionSource, ...otherProps }) {
  const { platformUserName, userProfilePicture } = otherProps;
  const buttonRef = useRef(null);

  const handleClick = () => {
    buttonRef.current.click();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  let computedStatus = status;
  let removeMenu = (
    <Grid item>
      <MenuButton onRemoveClick={onRemoveClick} imageUrl={userProfilePicture} userName={platformUserName} />
    </Grid>
  );
  let containerClass = ["platform-btn-container"];
  active && containerClass.push("active");
  let imgClass = null;

  if (status === AccountStatus.NOT_CONNECTED.key && disconnectionSource?.toString() === DisconnectionSource.DEVELOPER_API) {
    computedStatus = AccountStatus.REMOVED.key;
    removeMenu = null;
    containerClass.push("platform-removed");
    imgClass = "img-disabled";
  }

  // TODO Remove once PLATFORM-3338 is resolved with a specific implementation for handling removal of disconnected accounts
  if (computedStatus === AccountStatus.NOT_CONNECTED.key) {
    removeMenu = null;
  }

  function getTooltip(computedStatus) {
    let title = AccountStatus[computedStatus].label;
    let className = "";
    switch (computedStatus) {
      case AccountStatus.REMOVED.key:
        title = (
          <>
            <div>{REMOVED_TOOLTIP_MESSAGE_1}</div>
            <br />
            <div>{REMOVED_TOOLTIP_MESSAGE_2}</div>
          </>
        );
        className = "hover-icon-removed";
        break;
      case AccountStatus.CONNECTED.key:
        title = <div>{CONNECTED_TOOLTIP_MESSAGE}</div>;
        className = "hover-icon-connected";
        break;
      case AccountStatus.NOT_CONNECTED.key:
        title = (
          <>
            <div>{DISCONNECTED_TOOLTIP_MESSAGE_1}</div>
            <br />
            <div>{DISCONNECTED_TOOLTIP_MESSAGE_2}</div>
          </>
        );
        className = "hover-icon-disconnected";
        break;
      case AccountStatus.SESSION_EXPIRED.key:
        title = (
          <>
            <div>{EXPIRED_TOOLTIP_MESSAGE_1}</div>
            <br />
            <div>{EXPIRED_TOOLTIP_MESSAGE_2}</div>
          </>
        );
        className = "hover-icon-expired";
        break;
    }
    return (
      <Tooltip placement={"right-start"} title={title}>
        <Info className={`hover-icon ${className}`} />
      </Tooltip>
    );
  }

  return (
    <div className={containerClass.join(" ")} onClick={() => click()} tabIndex={0} onKeyPress={handleKeyPress}>
      <div className="btn-title">
        <img src={icon} width="24px" height="24px" className={imgClass} />
        {label}
      </div>
      <Grid container direction="row" justifyContent="space-between" alignItems={"center"}>
        <Grid item>
          <Chip
            size={"small"}
            // Callback for clicking on the "i" button
            onDelete={computedStatus === AccountStatus.REMOVED.key ? () => {} : () => click()}
            deleteIcon={getTooltip(computedStatus)}
            label={AccountStatus[computedStatus].label}
            ref={buttonRef}
            tabIndex={-1}
            classes={{
              root: "platform-btn",
            }}
            className={statusMapping[computedStatus].cssClass}
            {...otherProps}
          />
        </Grid>
        {removeMenu}
      </Grid>
    </div>
  );
}

export default AccountStatusBtn;
