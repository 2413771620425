import { DataGrid } from "@mui/x-data-grid";
import { CustomFooter } from "../../utils/DataGridUtils";
import React, { useContext, useEffect, useState } from "react";
import { getRecentProfiles } from "../../api/CampaignTrackingAPI/CampaignTrackingAPI";
import UserRow from "../UserRow/UserRow";
import {
    NO_CREATORS_FOUND,
    RECENTLY_WORKED_WITH_MESSAGE,
    RECENTLY_WORKED_WITH_TITLE,
    SELECT,
    SELECT_CREATOR_PLACEHOLDER
} from "../../constants/campaignTrackingConstants";
import './SelectableCreatorGrid.scss';
import { WarehouseContext } from "../../storage/context";
import { isEmpty } from "../../utils/util";
import { Colors } from "../../styles/colors";
import TextBox from "../InsightIQ/TextBox/TextBox";

export default function SelectableCreatorGrid({ onSelectCreator }) {
    const { warehouse } = useContext(WarehouseContext);
    const PAGE_SIZE = 20;
    const ROW_HEIGHT = 104;
    const [isLoading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0); // Adjusted to 0-based indexing
    const [totalRows, setTotalRows] = useState(0);
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        fetchData().then(() => {
        });
    }, [pageNumber, searchText]);

    async function fetchData() {
        try {
            setLoading(true);
            const res = await getRecentProfiles({
                warehouse,
                offset: pageNumber * PAGE_SIZE,
                limit: PAGE_SIZE,
                search_string: searchText,
            });
            const { data, total_count } = res;
            setRows(data);
            setTotalRows(total_count);
        } catch (e) {
            // TODO show error screen
            console.error("Error fetching data:", e);
        } finally {
            setLoading(false);
        }
    }

    function handlePageChange(newPageNumber) {
        setPageNumber(newPageNumber); // Adjusted to 0-based indexing
    }

    function renderProfileCell(params) {
        const { thumbnail_url, platform_username, is_verified, work_platform_id } = params.row;
        let { fullname } = params.row;
        if (isEmpty(fullname)) {
            fullname = platform_username;
        }
        return (
            <UserRow
                name={fullname}
                username={platform_username}
                is_verified={is_verified}
                work_platform_id={work_platform_id}
                thumbnail_url={thumbnail_url}
            />
        )
    }

    function renderSelectCell(params) {
        return (
            <div
                className={'body-m'}
                style={{
                    textDecoration: "underline",
                    color: "var(--main-primary)",
                    cursor: "pointer"
                }}
                onClick={() => onSelectCreator(params.row)}
            >
                {SELECT}
            </div>
        );
    }

    const commonHeaderProps = {
        flex: 1
    };

    const columns = [
        {
            ...commonHeaderProps,
            align: "left",
            field: 'platform_username',
            minWidth: 150,
            renderCell: renderProfileCell
        },
        {
            ...commonHeaderProps,
            align: "right",
            field: 'identifier',
            minWidth: 0,
            maxWidth: 60,
            renderCell: renderSelectCell
        }
    ];

    return (
        <div className={"selectable-creator-grid-container"}>
         
            <TextBox variant={'short'}
                     placeholder={SELECT_CREATOR_PLACEHOLDER}
                     value={searchText}
                     onEnter={setSearchText}
                     onClear={() => setSearchText('')}
                     containerClass={'hundred-percent-width'}/>

            <div className={'creator-grid-container'}>
                <DataGrid
                    className={"mui-data-grid recent-creator-grid"}
                    columns={columns}
                    components={{
                        Footer: (props) => (
                            <CustomFooter
                                containerStyleProps={{ marginTop: '32px' }}
                                totalRows={totalRows}
                                pageSize={PAGE_SIZE}
                                handlePageChange={handlePageChange}
                                pageNumber={pageNumber}
                            />
                        )
                    }}
                    disableColumnMenu
                    getRowHeight={() => ROW_HEIGHT}
                    // Backend confirmed mixture of identifier type and identifier is unique
                    // Row ID is needed, else runtime error in MUI grid
                    getRowId={getRowId}
                    loading={isLoading}
                    onPageChange={handlePageChange}
                    page={pageNumber}
                    pageSize={PAGE_SIZE}
                    pagination
                    paginationMode={"server"}
                    rowCount={totalRows}
                    rows={rows}
                    headerHeight={0}
                    disableSelectionOnClick
                    getRowClassName={(params) => {
                        // return selectedRows.includes(params.id) ? "custom-selected-row" : "";
                        return params.indexRelativeToCurrentPage === 0 ? 'first-row' : '';
                    }}
                    localeText={{
                        noRowsLabel: <span className={'body-m'}
                                           style={{ color: Colors.neutralsSecondaryGrey }}>{NO_CREATORS_FOUND}</span>,
                    }}
                    // getRowStyle={(params) => ({
                    //     borderTop: params.rowIndex === 0 ? "none" : "1px solid var(--neutrals-border-grey)",
                    // })}
                    // checkboxSelection // Enable checkbox selection
                    // selectionModel={selectedRows} // Pass the state to keep track of selected rows
                    // onSelectionModelChange={handleRowSelection} // Handle row selection changes
                />
            </div>
        </div>
    );
}

export function getRowId(row) {
    return `${row.identifier_type}-${row.identifier}`;
}