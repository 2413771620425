import MUIChip from "@material-ui/core/Chip";
import './Chip.scss';
export default function Chip({label, index}) {
    return (
        <MUIChip
            classes={{
                root: 'chip-container',
                label: 'chip-label body-r color-neutrals-primary-grey',
            }}
            key={index}
            label={label}
        />
    )
}
