import React from "react";
import Icons from "../Icons/Icons";
import "./TrialAccessPopUp.scss";
import PropTypes from "prop-types";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { getStarted } from "../../constants/campaignTrackingConstants";

const TrialAccessPopUp = ({ open, onClose, header, subheader, description, icon, buttonAction, height, buttonLabel = getStarted }) => {
  if (!open) {
    return null;
  }
  return (
    <div className="modal">
      <div className="trial-access-pop-up-container" style={{ height: height, top: `calc(50% - (${height}/2))` }}>
        <div className="trial-access-pop-up-icon">{icon}</div>
        <div className="trial-access-pop-up-content-container">
          <div className="trial-access-pop-up-heading">
            <h3 className="trial-access-pop-up-info-header">{header}</h3>
            <h3 className="trial-access-pop-up-info-subheader">{subheader}</h3>
          </div>
          <div className="trial-access-pop-up-description">
            {description.map((descriptionContent) => {
              return (
                <div className="trial-access-pop-up-description-content">
                  <div>
                    <Icons.success_tick_icon />
                  </div>
                  <p>{descriptionContent}</p>
                </div>
              );
            })}
          </div>
          <div className="trial-access-pop-up-action">
            <PrimaryButton className="trial-access-pop-up-action-button" label={buttonLabel} onClick={buttonAction} />
          </div>
        </div>
        <div className="close-icon-container">
          <Icons.close_icon
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            cursor="pointer"
          />
        </div>
      </div>
    </div>
  );
};

TrialAccessPopUp.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  header: PropTypes.string,
  subheader: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.element,
  buttonAction: PropTypes.func,
  height: PropTypes.string,
};

export default TrialAccessPopUp;
