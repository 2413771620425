import { Box, Skeleton } from "@mui/material";
import React from "react";

const SearchProfileIdentitySkeleton = () => {
  return (
    <>
      <Box
        sx={{
          paddingLeft: "8px",
          paddingRight: "8px",
        }}
      >
        <Box className="profile_heading">
          <div
            style={{
              marginRight: "8px",
              display: "flex",
              alignItems: "center",
            }}
          ></div>
          <Skeleton variant="text" width={138} height={27}></Skeleton>
        </Box>
        <Box>
          <Box display={"flex"} sx={{ gap: "20px !important" }} flexWrap={"wrap"}>
            <Skeleton variant="rectangular" width={280} height={100}></Skeleton>
            <Skeleton variant="rectangular" width={280} height={100}></Skeleton>
            <Skeleton variant="rectangular" width={280} height={100}></Skeleton>
            <Skeleton variant="rectangular" width={280} height={100}></Skeleton>
            <Skeleton variant="rectangular" width={280} height={100}></Skeleton>
            <Skeleton variant="rectangular" width={280} height={100}></Skeleton>
            <Skeleton variant="rectangular" width={280} height={100}></Skeleton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SearchProfileIdentitySkeleton;
