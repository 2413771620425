import React, { useContext, useState } from "react";

import {
  Background,
  Card,
  Typography,
  OtpInputField,
  PrimaryButton,
  TextLink,
  InputField,
  PasswordField,
  NavigationHeader,
  Loader,
  ModalLoader,
} from "../../components";
import { Form, Modal } from "antd";
import "./LoginPage.scss";
import useProfile from "../../hooks/useSetUserProfile";

import { EMPTY_REQUIRED_FIELD_MESSAGE, getPageTitle, INVALID_EMAIL_MESSAGE, pageTitle } from "../../constants/constants";

import { loginApi } from "../../api/index";
import { REGISTRATION_LINK_CLICK, RESET_PASSWORD_LINK_CLICK, SIGN_IN_PAGE_VIEW, trackEvent } from "../../analytics";
import { Helmet } from "react-helmet";

import useFormSubmitDisable from "../../hooks/useFormSubmitDisable";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { AnalyticsContext, UserContext } from "../../storage/context";
import { VERIFY_EMAIL_FLAG } from "../../storage/reducers/types";
import { loginErrorMessage } from "../../constants/errorMessages";
const requiredFormFields = ["email", "password"];

export default function LoginPage() {
  const [formInstance] = Form.useForm();
  const { setUserProfile, verifyEmailIfNot } = useProfile();

  const [errors, setErrors] = useState({
    email: false,
    password: false,
    errorMessage: "",
  });

  const resetError = (name) => {
    setErrors({ ...errors, [name]: false });
  };
  const disableSubmit = useFormSubmitDisable(formInstance, requiredFormFields);
  const [loading, setLoading] = useState(false);

  const onSubmitLoginForm = async () => {
    setLoading(true);
    const { email, password } = formInstance.getFieldsValue();
    try {
      const response = await loginApi.signInUser({ email, password });
      setUserProfile(response);
      formInstance.resetFields();
      const isVerified = verifyEmailIfNot(response, email, password);
      if (isVerified) {
        // navigate
      }
    } catch (e) {
      setErrors({
        email: true,
        password: true,
        errorMessage: loginErrorMessage[e.error_code],
      });
      setLoading(false);
    }
  };

  const sendUserAction = () => {
    trackEvent(REGISTRATION_LINK_CLICK);
  };

  const sendUserResetPasswordAction = () => {
    trackEvent(RESET_PASSWORD_LINK_CLICK, {
      email: formInstance.getFieldValue("email"),
    });
  };
  useEffect(() => {
    trackEvent(SIGN_IN_PAGE_VIEW);
  }, []);
  return (
    <Background className="email-verification-page">
      <NavigationHeader isAuthenticated={false} />
      <div className="login-container">
        <Form className="login-form" form={formInstance}>
          <Card className="login-card">
            <Typography h2 style={{ marginBottom: "32px" }}>
              Sign in to your account
            </Typography>
            <p className="error-message-text signin-error-text">{errors.errorMessage}</p>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: EMPTY_REQUIRED_FIELD_MESSAGE },
                {
                  type: "email",
                  message: INVALID_EMAIL_MESSAGE,
                },
              ]}
              // validateStatus={errors.email ? "error" : ""}
              // validateStatus={
              //   errors.email || formInstance.getFieldError("email").length > 0
              //     ? "error"
              //     : formInstance.validateFields()
              // }
            >
              <InputField
                inputLabel="email"
                placeholder="Enter your registered email"
                hasError={errors.email}
                onChange={() => {
                  resetError("email");
                }}
                // hidden={setShowOtpInput}
                // onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: EMPTY_REQUIRED_FIELD_MESSAGE }]}
              // validateStatus={
              //   errors.password ||
              //   formInstance.getFieldError("password").length > 0
              //     ? "error"
              //     : formInstance.validateFields()
              // }
            >
              <PasswordField
                showTags={false}
                inputLabel="password"
                placeholder="Enter your password"
                hasError={errors.password}
                onChange={() => {
                  resetError("password");
                }}
                // hidden={setShowOtpInput}
                // onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <TextLink to="/reset-password" className="info-forgot-password" onClick={sendUserResetPasswordAction}>
              Forgot password?
            </TextLink>
            <div className="card-footer">
              <Form.Item shouldUpdate>
                {/* Disabled button depends on the state of form input */}
                {/* Since we use antd form to handle this, we have to use custom rendering */}
                {() => (
                  <PrimaryButton
                    label="Sign in"
                    className="proceed-button"
                    style={{ marginTop: "32px" }}
                    loading={loading}
                    disabled={disableSubmit()}
                    // hidden={formInstance.validateFields(["email", "password"])}
                    // htmlType="submit"
                    htmlType="submit"
                    onClick={onSubmitLoginForm}
                  />
                )}
              </Form.Item>

              <span className="sign-in-button-text">
                Don’t have an account?{" "}
                <TextLink to="/registration" onClick={sendUserAction}>
                  Register
                </TextLink>
              </span>
            </div>
          </Card>
        </Form>
      </div>
      <Helmet>
        <title>{getPageTitle("LOGIN_PAGE", "")}</title>
      </Helmet>
    </Background>
  );
}
