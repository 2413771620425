import React, { useContext, useRef, useState } from "react";
import Icons from "../Icons/Icons";
import InvertedPrimaryButton from "../InvertedPrimaryButton/InvertedPrimaryButton";
import "./ExportResultOverlay.scss";
import { useEffect } from "react";
import { EMAIL_SUPPORT_LINK_CLICKED, SEARCH_RESULTS_EXPORTED, trackEvent } from "../../analytics";
import { creatorDiscoveryAPI } from "../../api";
import { UserContext, WarehouseContext } from "../../storage/context";
import UnlockFullAccessPopUp from "../UnlockFullAccessPopUp/UnlockFullAccessPopUp";
import ExportCSVPopUp from "../ExportCSVPopUp/ExportCSVPopUp";
import { ExportLoadingModal, GenericPopup, PopUpWithMultipleInput, SupportEmail } from "../index";
import { EMAIL_ERROR_MSG, EMAIL_REGEX, exportingCsv, EXPORT_CSV, formatNumberWithCommas } from "../../constants/constants";
import { BULK_EXPORT_LIMIT_ON_SEARCH, EXPORT_LIMIT_ON_SEARCH, BULK_EXPORT_EMAIL_INPUT_TITLE, BULK_EXPORT_HEADER, CREATOR_NEEDED, EXPORT_CREATOR_FOUND, EXPORT_POPUP_HEADER, SEND_CSV, YOUR_MAIL, EXPORT_LIMIT_ERROR_MSG, SEARCH_EXPORT_LIMIT_ERROR_MSG, EXPORT_SUCCESS_HEADER, EXPORT_SUCCESS_DESC, GOT_IT, EXPORT_FAILURE_HEADER, EXPORT_FAILURE_DESC, TRY_AGAIN, BULK_EXPORT_EXPIRE_INFO, LOADING, EMAIL_CONFIRMATION_TITLE, EMAIL_CONFIRMATION_DESC, EXPORT_CREATOR_SELECTED, parseFormattedNumber } from "../../constants/creatorDiscovery";

const ExportResultOverlay = ({
  totalExportResults,
  totalCredits,
  filterTags,
  selectedPlatform,
  source,
  selectedRows,
  searchPayload,
  exportLimit,
  sortState,
  isTrialMode,
  totalSearchedResults,
}) => {
  const { warehouse } = useContext(WarehouseContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isExportLoadingModalVisible, setIsExportLoadingModalVisible] = useState(false);
  const [exportResponseModal, setExportResponseModal] = useState({ showModal: false });
  const isBulkExportEnabled = warehouse.isBulkExportEnabled;
  const [exportCount, setExportCount] = useState(selectedRows.length > 0 ? selectedRows.length : (isBulkExportEnabled ? 100 : 10));
  const [exportCountError, setExportCountError] = useState({
    hasError: false,
    errorMessage: "",
  });
  const [exportEmailError, setExportEmailError] = useState({
    hasError: false,
    errorMessage: "",
  });
  const { userProfile } = useContext(UserContext);
  const [exportMail, setExportMail] = useState(userProfile.user.email);
  const [emailConfirmationStatus, setEmailConfirmationStatus] = useState(false);
  const exportErrorModalButtonLabelsAndActions = [
    {
      label: "Email us",
      icon: <Icons.email_pop_up_icon />,
      buttonAction: () => {
        trackEvent(EMAIL_SUPPORT_LINK_CLICKED, { source: "CREATOR_SEARCH_EXPORT_CSV" });
        window.location = "mailto:rev@insightiq.ai";
      },
    },
    {
      label: "Try again",
      icon: <i className="ri-restart-line" style={{ fontSize: "larger" }}></i>,
      buttonAction: () => {
        setExportResponseModal({ showModal: false });
        if (selectedRows.length > 0) {
          handleExportCSV();
        } else {
          setIsExportModalVisible(true);
        }
      },
    },
  ];
  const bulkExportLabelAndActions = [
    selectedRows.length === 0 ? {
      title: BULK_EXPORT_HEADER,
      inputLabel: CREATOR_NEEDED,
      icon: null,
      value: formatNumberWithCommas(String(exportCount).replace(/,/g, '')),
      buttonAction: (e) => {
        if (e.target.value === "") {
          setExportCount(e.target.value);
        } else {
          let count = Number(e.target.value.replace(/,/g, ''));
          if (isNaN(count)) return;
          setExportCount(count);
          verifyExportInput({ count });
        }
      },
      error: exportCountError,
      textboxType: "text",
      onBlurAction: (e) => {}
    } : null,
    {
      title: BULK_EXPORT_EMAIL_INPUT_TITLE,
      inputLabel: YOUR_MAIL,
      icon: <i className="ri-time-line ri-lg popup-clock-icon"></i>,
      value: exportMail,
      buttonAction: (e) => {
        setExportMail(e.target.value);
        setExportEmailError({
        hasError: false,
        errorMessage: "",
      });
      },
      error: exportEmailError,
      textboxType: "email",
      onBlurAction: (e) => {
        verifyExportInput({ mail: e.target.value });
      }
    },
  ];
  const EXPORT_MODAL_RESULT = {
    SUCCESS: {
      showModal: true,
      header: EXPORT_SUCCESS_HEADER,
      desc: <ul className="export-success-msg">
        <li>{EXPORT_SUCCESS_DESC(exportMail)[0]} </li>
        <li>{EXPORT_SUCCESS_DESC(exportMail)[1]}</li>
      </ul>,
      buttonLabel: GOT_IT,
      buttonSubText: null,
      icon: <Icons.purple_clock_icon />,
      height: "391px",
      buttonAction: () => {
        handleOnClose();
        setExportResponseModal({ showModal: false });
      },
    },
    ERROR: {
      showModal: true,
      header: EXPORT_FAILURE_HEADER,
      desc: EXPORT_FAILURE_DESC,
      buttonLabel: <div className="export-try-again-button" ><Icons.retry_circle />{TRY_AGAIN}</div>,
      buttonSubText: <div style={{ marginTop: 24 }}><SupportEmail /></div>,
      icon: <Icons.caution_icon />,
      height: "338px",
      buttonAction: (e) => {
        setIsExportModalVisible(true);
        setExportResponseModal({ showModal: false });
      },
    }
  };

  const handleExport = async () => {
    let platformUsername = selectedRows.filter((row) => row.platformUsername !== null).map((row) => row.platformUsername);
    let payload = searchPayload;
    payload.work_platform_id = selectedPlatform.id;
    payload.platform_usernames = platformUsername;
    payload.sort_by = sortState;
    payload.limit = selectedRows.length > 0 ? exportLimit : exportCount;
    payload.offset = 0;
    // API call
    try {
      // TODO - Add csv export logic on entering no. of records in textbox
      let response = await creatorDiscoveryAPI.postCreatorSearchResultsExport({
        payload: payload,
        warehouse,
      });
      // // Converting API response in form of table-formated csv
      const fileName = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
      response = response?.data;

      const file = new Blob([response], { type: "application/json" });
      const fileURL = URL.createObjectURL(file);

      const downloadLink = document.createElement("a");
      downloadLink.href = fileURL;
      downloadLink.download = fileName;
      downloadLink.click();

      setIsExportLoadingModalVisible(false);
      trackEvent(SEARCH_RESULTS_EXPORTED, {
        filters_added: Object.entries(filterTags).map(([key, value]) => ({
          [key]: {
            filter_name: value.filterName,
            category: value.category,
            type: value.filterType,
            values: value.filterValue,
          },
        })),
        platform: selectedPlatform.name,
        source: source,
        count: selectedRows.length > 0 ? selectedRows.length : exportCount,
        user_export_type: isBulkExportEnabled ? 'bulk export' : 'default',
        user_email_entered: null
      });

    } catch (error) {
      setIsExportLoadingModalVisible(false);
      setExportResponseModal(EXPORT_MODAL_RESULT.ERROR);
    }
  };
  const handleBulkExport = async () => {
    let platformUsername = selectedRows.filter((row) => row.platformUsername !== null).map((row) => row.platformUsername);
    let payload = searchPayload;
    payload.work_platform_id = selectedPlatform.id;
    payload.platform_usernames = platformUsername;
    payload.sort_by = sortState;
    payload.limit = selectedRows.length > 0 ? exportLimit : exportCount;
    payload.offset = 0;
    // API call
    try {
      // TODO - Add csv export logic on entering no. of records in textbox
      let response = await creatorDiscoveryAPI.postCreatorSearchResultsAsyncExport({
        payload: { search_request: payload, email: exportMail },
        warehouse,
      });

      setIsExportLoadingModalVisible(false);
      setExportResponseModal(EXPORT_MODAL_RESULT.SUCCESS);
      trackEvent(SEARCH_RESULTS_EXPORTED, {
        filters_added: Object.entries(filterTags).map(([key, value]) => ({
          [key]: {
            filter_name: value.filterName,
            category: value.category,
            type: value.filterType,
            values: value.filterValue,
          },
        })),
        platform: selectedPlatform.name,
        source: source,
        count: selectedRows.length > 0 ? selectedRows.length : exportCount,
        user_export_type: isBulkExportEnabled ? 'bulk export' : 'default',
        user_email_entered: exportMail
      });
    } catch (error) {
      setIsExportLoadingModalVisible(false);
      setExportResponseModal(EXPORT_MODAL_RESULT.ERROR);
    }
  };
  function verifyExportInput({ count = exportCount, mail = exportMail }) {
    if (isBulkExportEnabled) {
      if (Number(count) > BULK_EXPORT_LIMIT_ON_SEARCH || Number(count) < 1) {
        setExportCountError({ hasError: true, errorMessage: EXPORT_LIMIT_ERROR_MSG(formatNumberWithCommas((BULK_EXPORT_LIMIT_ON_SEARCH))) });
        return;
      }
      if (Number(count) > parseFormattedNumber(totalSearchedResults)) {
        setExportCountError({ hasError: true, errorMessage: SEARCH_EXPORT_LIMIT_ERROR_MSG(formatNumberWithCommas(parseFormattedNumber(totalSearchedResults))) });
        return;
      }
      setExportCountError({
        hasError: false,
        errorMessage: "",
      });
      if (mail.length > 0 && !EMAIL_REGEX.test(mail)) {
        setExportEmailError({
          hasError: true,
          errorMessage: EMAIL_ERROR_MSG,
        });
        return;
      }
      setExportEmailError({
        hasError: false,
        errorMessage: "",
      });
    } else {
      if (Number(count) > EXPORT_LIMIT_ON_SEARCH) {
        setExportCountError({ hasError: true, errorMessage: EXPORT_LIMIT_ERROR_MSG(EXPORT_LIMIT_ON_SEARCH) });
        return;
      }
      setExportCountError({
        hasError: false,
        errorMessage: "",
      });
    }
  }
  const handleExportCSV = () => {
    setIsExportModalVisible(false);
    setIsExportLoadingModalVisible(true);
    if (isBulkExportEnabled) {
      handleBulkExport();
    } else {
      handleExport();
    }
  };

  useEffect(() => {
    setExportCount(selectedRows.length > 0 ? selectedRows.length : (isBulkExportEnabled ? 100 : 10));
  }, [selectedRows.length]);

  const exportButtonStyleOnSelect = {
    backgroundColor: selectedRows.length > 0 ? "var(--main-secondary)" : "inherit",
    color: selectedRows.length > 0 ? "var(--main-primary)" : "var(--neutrals-secondary-grey)",
    padding: selectedRows.length > 0 ? "18px 24px 18px 20px" : "0px",
  };

  function handleOnClose() {
    setIsExportModalVisible(false);
    setExportCount(selectedRows.length > 0 ? selectedRows.length : (isBulkExportEnabled ? 100 : 10));
    setExportCountError({
      hasError: false,
      errorMessage: "",
    });
    setExportEmailError({
      hasError: false,
      errorMessage: "",
    });
    setExportMail(userProfile.user.email);
    setEmailConfirmationStatus(false);
  }
  return (
    <>
      <InvertedPrimaryButton
        icon={isTrialMode ? <i className="ri-lock-line"></i> : <i className="ri-share-forward-line"></i>}
        className="export-result-button"
        label={"Export CSV"}
        style={exportButtonStyleOnSelect}
        onClick={() => {
          if (isTrialMode) setIsModalVisible(true);
          else {
            if (selectedRows.length > 0 && !isBulkExportEnabled) {
              handleExportCSV();
            } else {
              setIsExportModalVisible(true);
            }
          }
        }}
      />
      <UnlockFullAccessPopUp
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        header="Upgrade to analyze more creators"
        description="Get detailed insights on each creator, and share them with your team or clients."
        actionButtonLabel="Contact us"
        height="264px"
        padding="52px 52px 48px 48px"
        isModal
      />
      {isExportModalVisible || isExportLoadingModalVisible ?
        isBulkExportEnabled ?
          <div className="bulk-export-popup-container" >
            <PopUpWithMultipleInput
              open={isExportModalVisible}
              onClose={handleOnClose}
              header={EXPORT_POPUP_HEADER}
              description={selectedRows.length > 0 ? EXPORT_CREATOR_SELECTED(selectedRows.length) : EXPORT_CREATOR_FOUND(totalSearchedResults)}
              actionButtonLabel={SEND_CSV}
              popUpLogo={<Icons.export_icon />}
              inputLabelAndActions={bulkExportLabelAndActions}
              actionButtonHandler={handleExportCSV}
              infoBannerText={BULK_EXPORT_EXPIRE_INFO}
              showDescError={selectedRows === 0 && bulkExportLabelAndActions[0].error.hasError && Number(exportCount) > parseFormattedNumber(totalSearchedResults)}
              showBottomConfirmation
              confirmationTitle={EMAIL_CONFIRMATION_TITLE}
              confirmationDesc={EMAIL_CONFIRMATION_DESC}
              handleCheckboxClick={(checkedValue) => { setEmailConfirmationStatus(checkedValue); }}
              confirmationStatus={emailConfirmationStatus}
            />
            <ExportLoadingModal open={isExportLoadingModalVisible} title={LOADING} showDisc={false} />
          </div>
          :
          <>
            <ExportCSVPopUp
              onClose={() => setIsExportModalVisible(false)}
              open={isExportModalVisible}
              header={EXPORT_POPUP_HEADER}
              description={EXPORT_CREATOR_FOUND(totalSearchedResults)}
              actionButtonLabel={EXPORT_CSV}
              actionButtonHandler={handleExportCSV}
              exportCount={exportCount}
              setExportCount={setExportCount}
              setExportCountError={setExportCountError}
              exportCountError={exportCountError}
              icon={<Icons.export_icon />}
              textboxType="number"
              textboxPlaceholder="Number of creators needed for export"
              onTextBoxChangeHandler={(count) => {
                verifyExportInput({ count: +count });
              }}
            />
            <ExportLoadingModal open={isExportLoadingModalVisible} title={exportingCsv} showDisc={true} />
          </>
        : null
      }


      {exportResponseModal.showModal ? <div className="export-generic-popup" >
        <GenericPopup
          open={exportResponseModal.showModal}
          icon={<span className="export-modal-icon" >{exportResponseModal.icon}</span>}
          padding={"48px 48px 48px 48px"}
          height={exportResponseModal.height}
          onClose={(e) => {
            e.stopPropagation();
            handleOnClose();
            setExportResponseModal({ showModal: false });
          }}
          header={exportResponseModal.header}
          description={exportResponseModal.desc}
          isModal
          actionButtonLabel={<span className={'span-action-button-label'}>{exportResponseModal.buttonLabel}</span>}
          handleButtonClick={exportResponseModal.buttonAction}
          actionButtonSubtext={exportResponseModal.buttonSubText}
        />
      </div> : null}
    </>
  );
};

export default ExportResultOverlay;
