const ADD_TENANT_INFORMATION = "ADD_TENANT_INFORMATION";
const ADD_WEBHOOKS = "ADD_WEBHOOKS";
const FETCH_WEBHOOKS = "FETCH_WEBHOOKS";
const ADD_USER_PROFILE = "ADD_USER_PROFILE";
const REMOVE_USER_PROFILE = "REMOVE_USER_PROFILE";
const VERIFY_EMAIL_FLAG = "VERIFY_EMAIL_FLAG ";
const REMOVE_WAREHOUSE = "REMOVE_WAREHOUSE";
const ADD_ANALYTICS = "ADD_ANALYTICS";
const UPDATE_ANALYTICS = "UPDATE_ANALYTICS";
const ADD_USER_STEPS = "ADD_USER_STEPS";
const UPDATE_TENANT_STATUS = "UPDATE_TENANT_STATUS";
const ADD_ENVIRONMENT = "ADD_ENVIRONMENT";
const UPDATE_COMPLIANCE_FORM_STATUS = "UPDATE_COMPLIANCE_FORM_STATUS";
const UPDATE_ENVIRONMENT_BASE_URL = "UPDATE_ENVIRONMENT_BASE_URL";
const UPDATE_PRODUCTION_KEY_STATUS = "UPDATE_PRODUCTION_KEY_STATUS";
const UPDATE_CREATOR_MODULE_STATUS = "UPDATE_CREATOR_MODULE_STATUS";
const UPDATE_CREATOR_LINKAGE_MODULE_STATUS = "UPDATE_CREATOR_LINKAGE_MODULE_STATUS";
const UPDATE_CREATOR_SEARCH_MODULE_STATUS = "UPDATE_CREATOR_SEARCH_MODULE_STATUS";
const SHOW_TOP_HEADER = "SHOW_TOP_HEADER";
const HIDE_TOP_HEADER = "HIDE_TOP_HEADER";
const ADD_USAGE_QUOTA = "ADD_USAGE_QUOTA";
const ADD_ENABLED_PRODUCTS = "ADD_ENABLED_PRODUCTS";

const ADD_WORK_PLATFORM = "ADD_WORK_PLATFORM";
const UPDATE_FILTER_PAYLOAD = "UPDATE_FILTER_PAYLOAD";
const ADD_FILTER_PAYLOAD = "ADD_FILTER_PAYLOAD";
const UPDATE_LAST_INTERACTED_FILTER = "UPDATE_LAST_INTERACTED_FILTER";
const SET_LAST_INTERACTED_FILTER_NULL = "SET_LAST_INTERACTED_FILTER_NULL";
const ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS = "ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS";
const UPDATE_FILTER_TAG = "UPDATE_FILTER_TAG";
const SET_SHOULD_RENDER_TRUE = "SET_SHOULD_RENDER_TRUE";
const SET_SHOULD_RENDER_FALSE = "SET_SHOULD_RENDER_FALSE";
const SET_SHOULD_TAGS_RENDER_TRUE = "SET_SHOULD_TAGS_RENDER_TRUE";
const SET_SHOULD_TAGS_RENDER_FALSE = "SET_SHOULD_TAGS_RENDER_FALSE";
const SET_CAMPAIGN_URL = "SET_CAMPAIGN_URL";
const SET_TRACKED_CREATORS = "SET_TRACKED_CREATORS";
const SET_POST_IDENTIFIERS = "SET_POST_IDENTIFIERS";
const SHOW_CAMPAIGN_ERROR = "SHOW_CAMPAIGN_ERROR";
const SET_CSV_CREATORS_LIST = "SET_CSV_CREATORS_LIST";
const SET_PROFILES_CSV = "SET_PROFILES_CSV";
const BASIC_DETAILS_OF_SELECTED_PROFILE = "BASIC_DETAILS_OF_SELECTED_PROFILE";
const UPDATE_CREATOR_LISTS_SELECTED_CREATORS = "UPDATE_CREATOR_LISTS_SELECTED_CREATORS";

const SET_CAMPAIGN_FORM = "SET_CAMPAIGN_FORM";
const UPDATE_FEATURE = "UPDATE_FEATURE";
const CLEAR_CAMPAIGN_FORM = "CLEAR_CAMPAIGN_FORM";
const UPDATE_STORE_DETAILS = "UPDATE_STORE_DETAILS";
const CLEAR_STORE_DETAILS = "CLEAR_STORE_DETAILS";
const SET_CAMPAIGN_ACTIVE_TAB = "SET_CAMPAIGN_ACTIVE_TAB";
const UPDATE_BULK_EXPORT_STATUS = "UPDATE_BULK_EXPORT_STATUS";

export {
  ADD_USER_PROFILE,
  ADD_TENANT_INFORMATION,
  REMOVE_USER_PROFILE,
  ADD_WEBHOOKS,
  FETCH_WEBHOOKS,
  VERIFY_EMAIL_FLAG,
  REMOVE_WAREHOUSE,
  ADD_ANALYTICS,
  UPDATE_ANALYTICS,
  ADD_USER_STEPS,
  ADD_ENVIRONMENT,
  UPDATE_COMPLIANCE_FORM_STATUS,
  UPDATE_TENANT_STATUS,
  UPDATE_ENVIRONMENT_BASE_URL,
  UPDATE_PRODUCTION_KEY_STATUS,
  UPDATE_CREATOR_MODULE_STATUS,
  UPDATE_CREATOR_LINKAGE_MODULE_STATUS,
  UPDATE_CREATOR_SEARCH_MODULE_STATUS,
  ADD_USAGE_QUOTA,
  ADD_ENABLED_PRODUCTS,
  SHOW_TOP_HEADER,
  HIDE_TOP_HEADER,
  UPDATE_FILTER_PAYLOAD,
  ADD_WORK_PLATFORM,
  ADD_FILTER_PAYLOAD,
  UPDATE_LAST_INTERACTED_FILTER,
  SET_LAST_INTERACTED_FILTER_NULL,
  ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS,
  UPDATE_FILTER_TAG,
  SET_SHOULD_RENDER_TRUE,
  SET_SHOULD_RENDER_FALSE,
  SET_SHOULD_TAGS_RENDER_TRUE,
  SET_SHOULD_TAGS_RENDER_FALSE,
  SET_CAMPAIGN_URL,
  SET_TRACKED_CREATORS,
  SET_POST_IDENTIFIERS,
  SET_CAMPAIGN_FORM,
  CLEAR_CAMPAIGN_FORM,
  SHOW_CAMPAIGN_ERROR,
  UPDATE_FEATURE,
  UPDATE_STORE_DETAILS,
  CLEAR_STORE_DETAILS,
  SET_CAMPAIGN_ACTIVE_TAB,
  SET_CSV_CREATORS_LIST,
  SET_PROFILES_CSV,
  UPDATE_CREATOR_LISTS_SELECTED_CREATORS,
  BASIC_DETAILS_OF_SELECTED_PROFILE,
  UPDATE_BULK_EXPORT_STATUS
};
