import { useEffect, useState, useRef } from 'react';

function useIntersectionObserver(threshold = 0.5) {
  const chartContainerRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    }, { threshold });

    observer.observe(chartContainerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [threshold]);

  return { chartContainerRef, isVisible };
}

export default useIntersectionObserver;
