import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { InvitedUsersPage, ProfileView } from "../pages";

export default function CreatorLinkageRoutes() {
  return (
    <Routes path="">
      <Route path="" element={<InvitedUsersPage />} />
      <Route path="/profile/:id" element={<ProfileView />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
