import { Box, Tooltip } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import {
    ContentDatePicker,
    CostInput,
    EmptyTableScreen,
    FilledTextField,
    Icons,
    InfoActionBanner,
    PrimaryButton,
    SearchableDropDown
} from '../../components';
import {
    ADD_ACCOUNTS,
    campaignCostInputDesc,
    campaignCostInputTitle,
    campaignDurationDesc,
    campaignDurationTitle,
    campaignPageData,
    costInputPlaceholder,
    dublicateCampaginError,
    duplicate_campaign,
    DurationTooltip,
    EDIT,
    END_DATE,
    INFO_ACTION_BANNER_ADD_TITLE,
    INFO_ACTION_BANNER_EMPTY_TITLE,
    oneBillion,
    START_DATE,
    usd
} from '../../constants/campaignTrackingConstants';
import "./CreateCampaignPage.scss";
import {
    createBrand,
    getAllBrands,
    getCampaignById,
    updateCampaign
} from '../../api/CampaignTrackingAPI/CampaignTrackingAPI';
import { CampaignTrackingContext, WarehouseContext } from '../../storage/context';
import { SET_CAMPAIGN_FORM, SHOW_CAMPAIGN_ERROR } from '../../storage/reducers/types';
import { clearCampaignTrackingWarehouse, convertDateToUtc, getBrandMapping, isEmpty } from "../../utils/util";
import moment from 'moment-timezone';
import {
    trackEvent,
    TRACKING_CAMPAIGN_NEW_CAMPAIGN_BACK_CLICKED,
    TRACKING_CAMPAIGN_NEXT_CLICKED
} from '../../analytics';
import { Colors } from "../../styles/colors";
import AddBrandProfilesPopup from "../../components/AddBrandProfilesPopup/AddBrandProfilesPopup";


const CreateCampaignPage = () => {
    const navigate = useNavigate();
    const { dispatchCampaignTrackingWarehouse, campaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const { campaignForm } = campaignTrackingWarehouse;
    const [pageName, setPageName] = useState(campaignPageData.createCampaign)
    const [campaignBrands, setCampaignBrands] = useState([]);
    const { campaignId } = useParams();
    const { warehouse } = useContext(WarehouseContext);
    const [prevCampaignData, setPrevCampaignData] = useState({
        name: "",
        brand: {},
        cost: "",
        fromDate: null,
        toDate: null,
    })
    const [campaignFormData, setCampaignFormData] = useState({
        name: campaignForm.name || "",
        brand: campaignForm.brand || {},
        cost: campaignForm.cost || "",
        fromDate: campaignForm.date.fromDate || null,
        toDate: campaignForm.date.toDate || null,
    })
    const [showCampaignError, setShowCampaignError] = useState({
        hasError: false,
        errorMessage: ""
    });
    const [campaignNameError, setCampaignNameError] = useState({
        hasError: false,
        errorMessage: ""
    });
    const [brandSocialsCount, setBrandSocialsCount] = useState(0);
    const [showAddBrandButton, setShowAddBrandButton] = useState(false);
    const [newBrandName, setNewBrandName] = useState(null);
    const [defaultBrandValue, setDefaultBrandValue] = useState(null);
    const [showAddBrandProfilesPopup, setShowAddBrandProfilesPopup] = useState(false);
    const [defaultBrandFetched, setDefaultBrandFetched] = useState(false)
    const getCampaignFormData = async (campaignBrands) => {
        try {
            if (!campaignId || !campaignBrands) {
                return;
            }
            const campaignDataFromApi = await getCampaignById({ warehouse, campaignId });
            let { name, cost, start_date, end_date } = campaignDataFromApi;

            let foundBrand = campaignBrands?.find(obj => obj.id === campaignDataFromApi?.brand_id);

            const brand = {
                name: foundBrand ? foundBrand.name : "",
                value: campaignDataFromApi?.brand_id
            };


            setPrevCampaignData({
                name: name,
                brand: brand,
                cost: cost,
                fromDate: start_date,
                toDate: end_date
            })

            dispatchCampaignTrackingWarehouse({
                type: SET_CAMPAIGN_FORM,
                payload: {
                    name: campaignDataFromApi.name,
                    brand: {
                        name: brand.name,
                        value: brand.value,
                    },
                    date: {
                        fromDate: campaignDataFromApi.start_date,
                        toDate: campaignDataFromApi.end_date,
                    },
                    cost: campaignDataFromApi.cost,
                },
            })

        } catch (error) {
            throw error;
        }
    }

    async function createBrandApi() {
        const res = await createBrand({ warehouse, name: newBrandName });
        return {
            name: res ? res.name : "",
            value: res?.id,
        };
    }

    // Get all brands when you open the page
    useEffect(() => {
        getAllBrands({ warehouse, limit: 100 })
            .then((v) => setCampaignBrands(v.data));
    }, []);


    useEffect(() => {
        let pathArray = window.location.pathname.split("/")
        if ((pathArray[3] === "create-campaign")) {
            setPageName(campaignPageData.createCampaign)
            if (campaignTrackingWarehouse.showCampaignError === duplicate_campaign) {
                setCampaignNameError({
                    hasError: true,
                    errorMessage: dublicateCampaginError
                })
            }
        } else if ((pathArray[3] === "edit-campaign")) {
            getAllBrands({ warehouse, limit: 100 }).then((v) => {
                getCampaignFormData(v.data).then(() => { });
            });

            setPageName(campaignPageData.editCampaign);
        } else {
            navigate("/creators/campaigns");
        }
    }, [window.location]);

    useEffect(() => {
        if (!campaignFormData || !campaignBrands) {
            return;
        }
        setDefaultBrandFetched(false)
        setDefaultBrandValue(campaignFormData.brand);
        getAllBrands({ warehouse, limit: 100 })
            .then((v) => {
                setCampaignBrands(v.data);
                const brandObj = v.data?.find(campaignBrand => campaignBrand.id === campaignFormData?.brand?.value);
                if (brandObj === undefined) {
                    setBrandSocialsCount(0);
                } else {
                    setBrandSocialsCount(brandObj?.profiles?.length);
                }
            })
            .finally(() => setDefaultBrandFetched(true))

    }, [campaignFormData.brand]);

    useEffect(() => {
        setCampaignFormData({
            name: campaignForm?.name || "",
            brand: campaignForm?.brand || {},
            cost: campaignForm?.cost || "",
            fromDate: campaignForm?.date?.fromDate || null,
            toDate: campaignForm?.date?.toDate || null,
        })
    }, [campaignForm]);

    const handleCreateCampaign = async () => {
        let brand;
        if (defaultBrandValue?.value === null) {
            const responseBrand = await createBrandApi()
            setCampaignFormData({ ...campaignFormData, responseBrand });
            setShowAddBrandButton(false);
            brand = responseBrand
        } else {
            brand = campaignFormData.brand
        }
        const { name: campaignName, fromDate, toDate, cost } = campaignFormData;
        dispatchCampaignTrackingWarehouse({
            type: SET_CAMPAIGN_FORM,
            payload: {
                name: campaignName,
                brand: {
                    name: brand.name,
                    value: brand.value,
                },
                date: {
                    fromDate: fromDate,
                    toDate: toDate,
                },
                cost: cost,
            },
        })
        if (pageName !== campaignPageData.editCampaign) {
            trackEvent(TRACKING_CAMPAIGN_NEXT_CLICKED, {
                name: campaignName,
                brand_name: brand.name,
                start_date: fromDate,
                end_date: convertDateToUtc({ date: toDate, endDate: true }),
                campaign_cost: cost
            });
        }
        navigate(`/creators/campaigns/automatically-add-creators`);
    }

    const isCampaignStarted = () => {
        return moment(prevCampaignData?.fromDate).toDate() < new Date();

    }

    const handleUpdateCampaign = async () => {
        try {
            let brand;
            if (defaultBrandValue?.value === null) {
                const responseBrand = await createBrandApi()
                setCampaignFormData({ ...campaignFormData, responseBrand });
                setShowAddBrandButton(false);
                brand = responseBrand
            } else {
                brand = campaignFormData.brand
            }
            const { name, fromDate, toDate, cost } = campaignFormData;

            const response = await updateCampaign({
                warehouse,
                campaignId,
                name,
                start_date: !isCampaignStarted() ? convertDateToUtc({ date: fromDate }) : null,
                end_date: convertDateToUtc({ date: toDate, endDate: true }),
                cost,
                brand_id: brand.value
            });
            if (response.id) {
                navigate(`/creators/campaigns/campaign-view/${response.id}`)
            } else if (response.error) {
                if (response.error?.code === duplicate_campaign) {
                    setCampaignNameError({
                        hasError: true,
                        errorMessage: dublicateCampaginError
                    })
                }
            }
        } catch (error) {
            console.error(error)
        }

    }

    const isButtonDisable = () => {

        let campaignName = campaignFormData.name.trim()
        if (campaignNameError.hasError) return true;
        if (campaignName === "" || isEmpty(campaignFormData.brand) || campaignFormData.brand.value === "") return true;
        if (campaignFormData.toDate === null || campaignFormData.fromDate === null) return true;

        const fromDateMoment = moment(campaignFormData.fromDate, 'YYYY-MM-DD');
        const toDateMoment = moment(campaignFormData.toDate, 'YYYY-MM-DD');
        if (fromDateMoment.isSame(toDateMoment)) {
            return false;
        } else if (!fromDateMoment.isBefore(toDateMoment)) {
            return true;
        }

    }

    function getSearchableDropdownFilterValue(brand) {
        if (isEmpty(brand)) {
            setCampaignFormData({ ...campaignFormData, brand: { name: '', value: '' } });
        } else {
            setCampaignFormData({ ...campaignFormData, brand });
        }
        setShowAddBrandButton(false);
        setNewBrandName(null);
    }

    function onSearchableDropdownTextFieldChange(brandName) {
        if (isEmpty(brandName)) {
            return;

        }
        const cleanedInputBrandName = brandName.trim();
        const brandFound = campaignBrands?.find(campaignBrand => campaignBrand.name.toLowerCase() === cleanedInputBrandName.toLowerCase());
        if (brandFound === undefined) {
            setCampaignFormData({ ...campaignFormData, brand: { name: brandName, value: null } });
            setShowAddBrandButton(true);
            setNewBrandName(cleanedInputBrandName);
        } else {
            setCampaignFormData({ ...campaignFormData, brand: { name: brandName, value: brandFound.id } });
            setShowAddBrandButton(false);
            setNewBrandName(null);
        }
    }

    if (showCampaignError.hasError) {
        return <Box className='campaign-error-content'>
            <EmptyTableScreen
                icon={showCampaignError.errorMessage.icon}
                header={showCampaignError.errorMessage.title}
                content={showCampaignError.errorMessage.desc}
                isActionButton={false}
            />
        </Box>
    }

    function renderInfoActionBanner() {
        if (isEmpty(defaultBrandValue?.value) || !defaultBrandFetched) {
            return null;
        }
        let actionIcon, icon, actionText, title;
        if (brandSocialsCount === 0) {
            title = INFO_ACTION_BANNER_EMPTY_TITLE;
            icon = <Icons.comment_icon />;
            actionIcon = <i className="ri-add-line"></i>;
            actionText = ADD_ACCOUNTS;
        } else {
            title = INFO_ACTION_BANNER_ADD_TITLE(brandSocialsCount, defaultBrandValue?.name);
            icon = <Icons.checkmark_icon />;
            actionIcon = <i className="ri-edit-line"></i>;
            actionText = EDIT;
        }

        return (
            <div style={{ width: 580 }}>
                <InfoActionBanner
                    title={<span style={{ width: 316 }}>{title}</span>}
                    actionIcon={actionIcon}
                    icon={icon}
                    variant={'brand-profiles'}
                    actionClick={() => setShowAddBrandProfilesPopup(true)} actionText={actionText} />
            </div>
        )
    }

    function onAddClick() {
        createBrandApi().then((brand) => {
            setCampaignFormData({ ...campaignFormData, brand });
            setShowAddBrandButton(false);
            return brand
        });
    }

    function onSocialsDone() {
        getAllBrands({ warehouse, limit: 100 })
            .then((v) => {
                setCampaignBrands(v.data);
                const foundBrand = v.data.find(obj => obj.id === defaultBrandValue?.value);
                if (foundBrand) {
                    setBrandSocialsCount(foundBrand?.profiles?.length);
                }
            });
    }

    return (
        <Box className="campaign-page-container">
            {/* Back icon */}
            <Box>
                <i className="ri-arrow-left-line back-icon" onClick={() => {
                    clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
                    if (pageName === campaignPageData.editCampaign && !!campaignId) {
                        navigate(`/creators/campaigns/campaign-view/${campaignId}`)
                    } else {
                        trackEvent(TRACKING_CAMPAIGN_NEW_CAMPAIGN_BACK_CLICKED)
                        navigate(-1);
                    }
                }}></i>
            </Box>
            {/* title */}
            <Box className='campaign-page-title'>
                <Icons.campaign_icon_white_bg />
                <div className='title'>{pageName.title}</div>
            </Box>

            {/* Campaign Form */}
            <Box className='create-campaign-form'>
                <Box className='campaign-brand-input'>
                    <FilledTextField
                        label={campaignPageData.campaignNameInput}
                        onChange={(e) => {
                            setCampaignFormData({ ...campaignFormData, name: e.target.value })
                            if (campaignNameError.hasError) {
                                setCampaignNameError({
                                    hasError: false,
                                    errorMessage: ""
                                })
                                dispatchCampaignTrackingWarehouse({
                                    type: SHOW_CAMPAIGN_ERROR,
                                    payload: "",
                                })
                            }
                        }}
                        inputProps={{
                            maxLength: 100,
                        }}
                        value={campaignFormData.name}
                        hasError={campaignNameError.hasError}
                        errorMessage={campaignNameError.errorMessage}
                    />
                    <div className={'brand-dropdown-container'}>
                        <SearchableDropDown
                            additionalStyles={{ root: showAddBrandButton ? { width: 490 } : { width: 580 } }}
                            multi={false}
                            options={getBrandMapping(campaignBrands)}
                            searchPlaceholder={campaignPageData.brandNameInput}
                            showClose={true}
                            getFilterValue={getSearchableDropdownFilterValue}
                            defaultValue={defaultBrandValue}
                            onTextFieldChange={onSearchableDropdownTextFieldChange}
                            shouldSetInputValue={true}
                        />
                        {showAddBrandButton && <PrimaryButton
                            className={'add-modal-button add-brand-button'}
                            onClick={onAddClick}
                            label={<span
                                className={'body-m'}
                                style={{ color: Colors.mainPrimary }}>
                                {campaignPageData.addPostButtonCTA}
                            </span>} />}
                    </div>
                    {renderInfoActionBanner()}

                </Box>
            </Box>
            <Box paddingBottom={"24px"}>
                <Box className='campaign-page-title'>
                    <Icons.duration_icon />
                    <div>
                        <Box className='sub-section-heading'>{campaignDurationTitle}</Box>
                        <Box className='body-r'>{campaignDurationDesc}
                            <Tooltip placement='right-end' title={<DurationTooltip />}>
                                <Box><i className="ri-information-line"></i></Box>
                            </Tooltip> </Box>
                    </div>
                </Box>
                <Box className='campaign-date-picker'>
                    <ContentDatePicker
                        fromDateHandler={{
                            fromDate: campaignFormData.fromDate,
                            fromDateLabel: START_DATE,
                            fromMinDate: moment().subtract(29, 'days').toDate(),
                            fromMaxDate: null,
                            disabled: (pageName === campaignPageData.editCampaign && moment(prevCampaignData.fromDate).toDate() < new Date()),
                            onValidFromDate: (date) => {
                                if (!campaignFormData.fromDate || (date && date !== campaignForm.date.fromDate)) {
                                    setCampaignFormData(prev => ({
                                        ...prev,
                                        fromDate: date
                                    }));
                                }
                            },
                            clearFromDate: () => setCampaignFormData(prev => ({
                                ...prev,
                                fromDate: null
                            })),
                        }}
                        toDateHandler={{
                            toDate: campaignFormData.toDate,
                            toDateLabel: END_DATE,
                            onValidToDate: (date) => {
                                if (!campaignFormData.toDate || (date && date !== campaignForm.date.toDate)) {
                                    setCampaignFormData(prev => ({
                                        ...prev,
                                        toDate: date
                                    }));
                                }
                            },
                            toMaxDate: isEmpty(campaignFormData?.fromDate) ? '' : moment(campaignFormData?.fromDate).add(59, 'days').toDate(),
                            toMinDate: moment().isAfter(campaignFormData?.fromDate) ? moment() : moment(campaignFormData?.fromDate),
                            clearToDate: () => setCampaignFormData(prev => ({
                                ...prev,
                                toDate: null
                            })),
                        }} />
                </Box>
            </Box>

            <Box>
                <Box className='campaign-page-title'>
                    <Icons.dollar_icon />
                    <div>
                        <Box className='sub-section-heading'>{campaignCostInputTitle}</Box>
                        <Box className='body-r'>{campaignCostInputDesc}  </Box>
                    </div>
                </Box>
            </Box>

            <div className='cost-input-div'>
                <CostInput placeholder={costInputPlaceholder} currency={usd} value={campaignFormData.cost}
                    onKeyPress={(e) => {
                        const key = e.key;
                        // Allow numeric digits, period (for decimal point), backspace, delete, and arrow keys
                        if (!/^\d$|^\.$|^Backspace$|^Delete$|^ArrowLeft$|^ArrowRight$/.test(key)) {
                            e.preventDefault();
                        }
                        // Check if the input already contains a decimal point and prevent another one
                        if (key === '.' && e.target.value.includes('.')) {
                            e.preventDefault();
                        }
                    }}
                    type={"number"}
                    onChange={(e) => {
                        const { target: { value } } = e;
                        if (value === '') {
                            setCampaignFormData((prev) => ({ ...prev, cost: value }));
                            return;
                        }
                        let cost = parseFloat(value)
                        if (cost < 0 || cost === -0) cost = 0;
                        if (cost > oneBillion) return
                        setCampaignFormData((prev) => ({ ...prev, cost: cost }))
                    }}
                />
            </div>
            {/* save campaign */}
            <Box className='save-changes-container'>
                <PrimaryButton
                    label={pageName.buttonCTA}
                    className="body-m"
                    disabled={isButtonDisable()}
                    onClick={() => pageName === campaignPageData.editCampaign ? handleUpdateCampaign() : handleCreateCampaign()} />
            </Box>
            {showAddBrandProfilesPopup && !isEmpty(defaultBrandValue?.value) && <AddBrandProfilesPopup
                brand={defaultBrandValue}
                onClose={() => {
                    onSocialsDone();
                    setShowAddBrandProfilesPopup(false);
                }}
                onDone={onSocialsDone}
            />}
        </Box>
    )
}

export default CreateCampaignPage
