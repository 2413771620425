import PrimaryButton from "../PrimaryButton/PrimaryButton";
import "./Banner.scss";

function Banner({ title, description, icon, button }) {
  return (
    <div className="banner-container">
      <div className="banner-child-container">
        <div className="banner-icon" width={120} height={120}>
          {icon}
        </div>
        <div className="banner-details-container">
          <div>
            <div className="title banner-title">{title}</div>
            <div lineHeight={"150%"} className="body-r banner-description">
              {description}
            </div>
          </div>
          <div>{button}</div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
