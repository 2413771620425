import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import './FilterMenu.scss';
import { FilterCheckbox, UserRow } from '..';
import TextBox from '../InsightIQ/TextBox/TextBox';
import { SELECT_CREATOR_PLACEHOLDER } from '../../constants/campaignTrackingConstants';


/**
 * FilterMenu component allows users to select filters from a dropdown menu.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.firstItem - The first item to be displayed in the menu.
 * @param {Array} props.menuItems - An array of menu items to be displayed in the dropdown.
 * @param {string} props.selectedValueText - The text to display for the selected value.
 * @param {function} props.handleFilterChange - The function to call when the filter changes.
 * @param {string} props.variant - The variant of the filter menu. Possible variants: "userAccounts", "single-select-custom-render".
 * @param {boolean} [props.enableSearch=false] - Whether to enable the search functionality.
 * @param {function} props.onSearch - The function to call when a search is performed.
 * @param {function} props.rowRenderer - A function to render custom rows in the menu.
 * @param {boolean} [props.isMultiSelect=false] - Whether the menu allows multiple selections.
 *
 * @returns {JSX.Element} The rendered filter menu component.
 */


export default function FilterMenu({
    firstItem,
    menuItems,
    selectedValueText,
    handleFilterChange,
    variant,
    enableSearch = false,
    onSearch,
    rowRenderer,
    isMultiSelect = false
}) {
    const [searchText, setSearchText] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    // const [isChecked, setIsChecked] = useState(true);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleFilterChange && handleFilterChange();
    };

    const handleMenuItemClick = (item) => {
        if (item.onClick) {
            item.onClick();
        }
        !isMultiSelect && handleMenuClose();
    };

    /**
    * Renders the user accounts filter menu.
    * @returns {JSX.Element} The user accounts filter menu.
    */

    const userAccountsFilter = () => {
        return (
            <div>
                <Button
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    className={'filter-dropdown-button'}
                    disableRipple
                >
                    <Box>
                        <Box>
                            <Box className="small-labels">{firstItem}</Box>
                            <Box className="body-m">{selectedValueText}</Box>
                        </Box>
                        <Box className="dropdown-menu-arrows"> {open ? <i className="ri-arrow-up-s-line"></i> :
                            <i className="ri-arrow-down-s-line"></i>}</Box>
                    </Box>
                </Button>

                <Menu
                    className="dropdown-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    keepMounted
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <MenuItem className={'first-item'} disabled><span
                        className="subtitle-r">{firstItem}</span></MenuItem>
                    {enableSearch && <div className='filter-search-container'>
                        <div onKeyDown={(e) => e.stopPropagation()}>
                            <TextBox
                                variant={"short"}
                                placeholder={SELECT_CREATOR_PLACEHOLDER}
                                value={searchText}
                                onEnter={onSearch}
                                onClear={() => {
                                    setSearchText('');
                                    onSearch('');
                                }}
                                containerClass={"hundred-percent-width"}

                            />
                        </div>
                    </div>}
                    {menuItems.map((item, index) => (
                        <MenuItem key={index}
                            onClick={() => handleMenuItemClick(item)}
                            className={item.text}
                            style={index !== menuItems.length - 1 ? { borderBottom: '1px solid var(--neutrals-border-grey)' } : {}}
                        >
                            <div className={`body-m menu-item-content ${item.text}`}>
                                <FilterCheckbox item={item} handleMenuItemClick={handleMenuItemClick} />
                                {!item.work_platform_id ? <>
                                    {item.icon}
                                    {item.text}
                                </> :
                                    <>
                                        <UserRow
                                            thumbnail_url={item.thumbnail_url}
                                            username={item.username}
                                            name={item.name}
                                            is_verified={item.is_verified}
                                            work_platform_id={item.work_platform_id}
                                        />
                                    </>
                                }
                            </div>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    };

    /**
    * Renders the single select filter menu with custom row renderer.
    * @returns {JSX.Element} The single select filter menu.
    */

    function singleSelectFilter() {
        return (
            <div>
                <Button
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    className={'filter-dropdown-button'}
                    disableRipple
                >
                    <Box>
                        <Box>
                            <Box className="small-labels">{firstItem}</Box>
                            <Box className="body-m">
                                {selectedValueText}
                            </Box>
                        </Box>
                        <Box className="dropdown-menu-arrows"> {open ? <i className="ri-arrow-up-s-line"></i> :
                            <i className="ri-arrow-down-s-line"></i>}</Box>
                    </Box>
                </Button>
                <Menu
                    className="dropdown-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    keepMounted
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {menuItems.map((item, index) => (
                        item.isVisible && <MenuItem key={index}
                            onClick={() => handleMenuItemClick(item)}
                            style={index !== menuItems.length - 1 ? { borderBottom: '1px solid var(--neutrals-border-grey)' } : {}}
                        >
                            <div className={`body-m menu-item-content`}>
                                {rowRenderer && rowRenderer(index)}
                            </div>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    }

    /**
    * Renders the default filter menu.
    * @returns {JSX.Element} The default filter menu.
    */

    const defaultFilter = () => {
        return (
            <div>
                <Button
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    className={'filter-dropdown-button'}
                    disableRipple
                >
                    <Box>
                        <Box>
                            <Box className="small-labels">{firstItem}</Box>
                            <Box className="body-m">{selectedValueText}</Box>
                        </Box>
                        <Box className="dropdown-menu-arrows"> {open ? <i className="ri-arrow-up-s-line"></i> :
                            <i className="ri-arrow-down-s-line"></i>}</Box>
                    </Box>
                </Button>
                <Menu
                    className="dropdown-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    keepMounted
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <MenuItem className={'first-item'} disabled>
                        <span className="subtitle-r">{firstItem}</span>
                    </MenuItem>
                    {menuItems.map((item, index) => (
                        item.isVisible && <MenuItem key={index}
                            onClick={() => handleMenuItemClick(item)}
                            className={item.text}
                            style={index !== menuItems.length - 1 ? { borderBottom: '1px solid var(--neutrals-border-grey)' } : {}}
                        >
                            <div className={`body-m menu-item-content ${item.text}`}>
                                <FilterCheckbox item={item} handleMenuItemClick={handleMenuItemClick} />
                                {item.icon}
                                {item.text}
                            </div>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    };

    switch (variant) {
        case "userAccounts":
            return userAccountsFilter();
        case "single-select-custom-render":
            return singleSelectFilter();
        default:
            return defaultFilter();
    }
}
