import React from "react";
import "./PopUpWithMultipleInput.scss";
import Icons from "../Icons/Icons";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import FilledTextField from "../FilledTextField/FilledTextField";
import { CheckBox } from "../../components";

const PopUpWithMultipleInput = ({ open, onClose, header, description, popUpLogo, inputLabelAndActions, actionButtonLabel, actionButtonHandler, infoBannerText, showDescError, showBottomConfirmation, confirmationTitle, confirmationDesc, handleCheckboxClick, confirmationStatus }) => {
  if (!open) {
    return null;
  }
  return (
    <div className="modal" style={{ cursor: "auto" }} onClick={onClose}>
      <div className="multiple-input-pop-up-container" onClick={(e) => e.stopPropagation()}>
        <div className="multiple-input-pop-up-content-container">
          <div className="multiple-input-pop-up-icon">{popUpLogo}</div>
          <div className="multiple-input-pop-up-description-container">
            <div className="multiple-input-pop-up-heading section-heading">{header}</div>
            <div className={`multiple-input-pop-up-description body-r ${showDescError ? 'pop-up-desc-error' : null}`}>{description}</div>
          </div>
        </div>
        <div className="multiple-input-pop-up-action">
          {inputLabelAndActions.map((inputDetails) => {
            if (inputDetails === null) return null;
            const { title, inputLabel, value, error, buttonAction, textboxType, icon, onBlurAction } = inputDetails;
            return (<div className="multiple-input-pop-up-input" >
              <div className="body-b pop-up-input-title" >{icon ?? icon}{title}</div>
              <FilledTextField
                label={inputLabel}
                value={value}
                inputProps={{
                  maxLength: 100,
                }}
                hasError={error.hasError}
                errorMessage={
                  <div className="export-pop-up-error">
                    {error.hasError ? (
                      <div>
                        <Icons.caution_red />
                      </div>
                    ) : null}
                    {error.errorMessage}
                  </div>
                }
                onChange={buttonAction}
                onBlur={onBlurAction}
                type={textboxType}
                showClearIcon
              />

            </div>);
          })}
          {showBottomConfirmation ?
            <div className="pop-up-confirmation-container" onClick={(e) => {
              handleCheckboxClick(!confirmationStatus);
            }} >
              <div><CheckBox
                defaultChecked={confirmationStatus}
                onClick={handleCheckboxClick}
              /></div>
              <div>
                <div className="body-m" >{confirmationTitle}</div>
                <div className="subtitle-r pop-up-confirmation-desc" >{confirmationDesc}</div>
              </div>
            </div>
            : null}
          <div>
            <PrimaryButton
              className="multiple-export-pop-up-action-button"
              label={actionButtonLabel}
              onClick={actionButtonHandler}
              disabled={inputLabelAndActions.some(action => action?.error?.hasError) || (showBottomConfirmation ? !confirmationStatus : false) || inputLabelAndActions.some(action => action?.value?.length===0) }
            />
          </div>
        </div>
        {infoBannerText ? <div className="multiple-export-pop-up-info-banner body-r" >
          <i class="ri-information-line ri-xl info-icon"></i>
          {infoBannerText}
        </div> : null}
        <div className="close-icon-container">
          <Icons.close_icon onClick={onClose} cursor="pointer" />
        </div>
      </div>
    </div>
  );
};

export default PopUpWithMultipleInput;


