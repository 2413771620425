import {
  CheckboxFilter,
  CustomDropDown,
  SearchableDropDown,
  SearchableDropdownSlider,
  TagsFilter,
  SliderFilter,
  MinMaxDropdownFilter,
  DropdownSlider,
  MinMaxDropdownSlider,
  ApiSearchableDropdown,
  SearchableCheckBoxModal,
} from "../../components";
import { removeDuplicates } from "../../utils/profileHelpers";
import {
  ANY_CONTACT_OPTION_VALUE,
  CONTACT_TYPE_FILTER_KEY,
  CREATOR_LOOKALIKES,
  FILTER_TYPES,
  HAS_CONTACT_DETAILS_FILTER_KEY,
} from "../../constants/creatorDiscovery";
import {
  ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS,
  SET_SHOULD_RENDER_TRUE,
  UPDATE_FILTER_PAYLOAD,
  UPDATE_FILTER_TAG,
} from "../../storage/reducers/types";

import { SEARCH_FILTER_ADDED, SEARCH_FILTER_REMOVED, SEARCH_FILTER_UPDATED, trackEvent } from "../../analytics";
import { isEmpty, workPlatformsDropdownOptions } from "../../utils/util";
import ApiSearchableDropdownSlider from "../../components/ApiSearchableDropdownSlider/ApiSearchableDropdownSlider";
import { creatorDiscoveryAPI } from "../../api";
import { LINE, LINEID, SEARCH_BY_CITY_OR_COUNTRY_PLACEHOLDER, SEARCH_BY_COUNTRY_PLACEHOLDER, TWITTER, WORKPLATFORM_IDS, XCOM } from "../../constants/constants";

// This function handles the rendering of the filters based on the type of filter, payloads, metadata and other information
async function renderFilter({
  type,
  metadata,
  category,
  name,
  description,
  setCreatorsFilters,
  setAudienceFilters,
  setPerformanceFilters,
  setPrimaryFilters,
  languages,
  interests,
  brands,
  payload,
  key,
  primaryFiltersIdentifier,
  creatorDiscoveryWarehouse,
  dispatchCreatorDiscoveryWarehouse,
  selectedPlatformID,
  warehouse,
}) {
  let newFilter = {}; 

  const getOptionsBasedOnAPI = (api) => {
    switch (api) {
      case "interests_api":
        return interests;
      case "brands_api":
        return brands;
      case "languages_api":
        return languages;

      default:
        return [];
    }
  };

  const getSearchableDetailsBasedOnAPI = ({ api, warehouse, key }) => {
    switch (api) {
      case "locations_api":
        return {
          /**
           * Handles the search for posts.
           *
           * @async
           * @param {object} options - The options object.
           * @param {string} options.searchQuery - The search query.
           * @param {object} options.newCancelToken - The new cancel token.
           * @returns {Promise<object[]>} - The search results.
           */
          handlePostSearch: async ({ searchQuery, newCancelToken }) => {
            let response = await creatorDiscoveryAPI.getUserLocations({
              searchQuery,
              newCancelToken,
              warehouse,
              workplatformId: selectedPlatformID,
            });
            return response.data;
          },
        };
      case "lookalike_api":
        return {
          /**
           * Handles the search for posts.
           *
           * @async
           * @param {object} options - The options object.
           * @param {string} options.searchQuery - The search query.
           * @param {object} options.newCancelToken - The new cancel token.
           * @returns {Promise<object[]>} - The search results.
           */
          handlePostSearch: async ({ searchQuery, newCancelToken }) => {
            // Special Handling for CREATOR_LOOKALIKES
            const type = key === CREATOR_LOOKALIKES ? "topic-tags" : "lookalike";
            let response = await creatorDiscoveryAPI.getUsersHandles({
              searchQuery,
              workplatformId: selectedPlatformID,
              type,
              newCancelToken,
              is_search: false,
              warehouse,
            });

            response = response.data;
            response = response.map((item) => {
              return {
                ...item,
                id: item.user_id,
                display_name: item.fullname,
              };
            });

            return response;
          },
        };
      case "topic_tags_api":
        return {
          /**
           * Handles the search for posts.
           *
           * @async
           * @param {object} options - The options object.
           * @param {string} options.searchQuery - The search query.
           * @param {object} options.newCancelToken - The new cancel token.
           * @returns {Promise<object[]>} - The search results.
           */
          handlePostSearch: async ({ searchQuery, newCancelToken }) => {
            let response = await creatorDiscoveryAPI.getTopicTags({
              searchQuery,
              workplatformId: selectedPlatformID,
              newCancelToken,
              warehouse,
            });
            response = response.data.map((item) => {
              return {
                ...item,
                id: item.value,
                display_name: item.name,
              };
            });
            return response;
          },
        };
      default:
        return {};
    }
  };

  switch (type) {
    case FILTER_TYPES.DROPDOWN:
      {
        const props = {
          options: metadata.options,
          filterKey: key,
          getFilterValue: (value) => {
            // add converter func value:string
            upsertQueryParams({ filterKey: key, filterType: type, value });
            upsertPayloadInFiltersPayload({ filterKey: key, filterType: type, value, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse });
          },
          // label: name,
        };
        // add code to handle meta_data default values
        if (payload && payload.searchPayload) {
          props.defaultValue = payload.searchPayload;
        }
        newFilter = {
          type: type,
          label: name,
          description: description,
          isSelected: payload ? true : false,
          id: Math.floor(Math.random() * 10000),
          Filter: <CustomDropDown {...props} />,
        };
      }
      break;
    case FILTER_TYPES.BOOLEAN:
      {
        const props = {
          checkboxLabel: metadata.info_text,
          filterKey: key,
          getFilterValue: (value) => {
            // call converter func value:boolean
            upsertQueryParams({ filterKey: key, filterType: type, value });
            upsertPayloadInFiltersPayload({ filterKey: key, filterType: type, value, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse });
          },
        };
        // add code to handle meta_data default values
        if (payload && payload.searchPayload) {
          props.checked = payload.searchPayload;
        }
        newFilter = {
          type: type,
          label: name,
          description: description,
          isSelected: payload ? true : false,
          id: Math.floor(Math.random() * 10000),
          isPrimaryFilter: false,
          Filter: <CheckboxFilter {...props} />,
        };
      }
      break;
    case FILTER_TYPES.MULTI_SELECT_TEXT:
      {
        const props = {
          type: FILTER_TYPES.MULTI_SELECT_TEXT,
          selectedTags: [],
          filterKey: key,
          getFilterValue: (value) => {
            // call converter func value:Array[String]
            upsertQueryParams({ filterKey: key, filterType: type, value });
            upsertPayloadInFiltersPayload({ filterKey: key, filterType: type, value, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse });
          },
          placeholder: metadata?.search_placeholder,
        };
        // add code to handle meta_data default values
        if (payload && payload.searchPayload) {
          // searchPayload Array[String] ["selected value 1", "selected value 2"]
          props.selectedTags = payload.searchPayload;
        }
        newFilter = {
          type: type,
          label: name,
          description: description,
          isSelected: creatorDiscoveryWarehouse.filters[key].payload ? true : false,
          id: Math.floor(Math.random() * 10000),
          isPrimaryFilter: false,
          Filter: <TagsFilter {...props} />,
        };
      }
      break;
    case FILTER_TYPES.API_SEARCHABLE_DROPDOWN:
      {
        if (metadata?.api === "lookalike_api") {
          let props = {
            // todo add api call to get locations
            defaultValue: null,
            placeholder: metadata?.search_placeholder,
            showTags: false,
            multi: false,
            filterKey: key,
            getFilterValue: (values) => {
              let options = [
                {
                  name: values?.display_name,
                  value: values?.username,
                },
              ];

              dispatchCreatorDiscoveryWarehouse({ type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS, payload: { key: key, options } });

              // Special Handling for CREATOR_LOOKALIKES
              if (key === CREATOR_LOOKALIKES) {
                if (selectedPlatformID === WORKPLATFORM_IDS.YouTubePlatformId) values = "@" + values?.user_id;
                else values = "@" + values?.username;
              } else values = values?.username;

              upsertQueryParams({ filterKey: key, filterType: type, value: values });
              upsertPayloadInFiltersPayload({
                filterKey: key,
                filterType: type,
                value: values,
                creatorDiscoveryWarehouse,
                dispatchCreatorDiscoveryWarehouse,
                warehouse
              });
            },
          };
          props = {
            ...props,
            ...getSearchableDetailsBasedOnAPI({ api: metadata?.api, warehouse, key }),
          };
          // add code to handle meta_data default values
          if (payload && payload.searchPayload) {
            props.defaultValue = {
              id: Math.floor(Math.random() * 10000000),
              name: payload.searchPayload,
              display_name: payload.searchPayload,
            };
          }
          newFilter = {
            type: type,
            label: name,
            description: description,
            isSelected: payload ? true : false,
            id: Math.floor(Math.random() * 10000000),
            isPrimaryFilter: false,
            Filter: <ApiSearchableDropdown {...props} />,
          };
        } else {
          const props = {
            options: languages,
            multi: false,
            searchPlaceholder: metadata.search_placeholder,
            filterKey: key,
            getFilterValue: (value) => {
              // value:String
              upsertQueryParams({ filterKey: key, filterType: type, value: value?.value });
              upsertPayloadInFiltersPayload({
                filterKey: key,
                filterType: type,
                value: value?.value,
                creatorDiscoveryWarehouse,
                dispatchCreatorDiscoveryWarehouse,
                warehouse
              });
            },
          };

          if (props?.options.length > 0 && !metadata?.options && !metadata?.options?.length > 0) {
            dispatchCreatorDiscoveryWarehouse({ type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS, payload: { key: key, options: props.options } });
          }
          // add code to handle meta_data default values
          if (payload && payload.searchPayload) {
            // api_searchable_dropdown:String selected value
            const selectedCode = payload.searchPayload;
            const value = languages.find((item) => item.value === selectedCode);
            props.defaultValue = value;
          }
          newFilter = {
            type: type,
            label: name,
            description: description,
            isSelected: payload ? true : false,
            id: Math.floor(Math.random() * 10000),
            isPrimaryFilter: false,
            Filter: <SearchableDropDown {...props} />,
          };
        }
      }
      break;
    case FILTER_TYPES.API_SEARCHABLE_DROPDOWN_STEPS_SLIDER:
      {
        const props = {
          options: languages,
          multi: false,
          defaultSliderValue: metadata.default_step_value,
          searchPlaceholder: metadata.search_placeholder,
          steps: metadata.steps,
          filterKey: key,
          getFilterValue: (value) => {
            //value {selected_value:string,percentage_value:number}
            upsertQueryParams({ filterKey: key, filterType: type, value });
            upsertPayloadInFiltersPayload({ filterKey: key, filterType: type, value, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse });
          },
        };
        if (props?.options.length > 0 && !metadata?.options && !metadata?.options?.length > 0) {
          dispatchCreatorDiscoveryWarehouse({ type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS, payload: { key: key, options: props.options } });
        }
        // add code to handle meta_data default values
        if (payload && payload.searchPayload) {
          //api_searchable_dropdown_steps_slider:Object {selected_value: "selected value", percentage_value: "selected step"}
          const value = languages.find((item) => item.value === payload.searchPayload?.selected_value);
          props.defaultValue = value;
          props.defaultSliderValue = payload.searchPayload?.percentage_value;
        }
        newFilter = {
          type: type,
          label: name,
          description: description,
          isSelected: payload ? true : false,
          id: Math.floor(Math.random() * 10000),
          isPrimaryFilter: false,
          Filter: <SearchableDropdownSlider {...props} />,
        };
      }
      break;
    case FILTER_TYPES.API_SEARCHABLE_MULTI_SELECT_DROPDOWN:
      {
        if (key === "creator_locations") {
          let props = {
            // todo add api call to get locations
            defaultValue: [],
            placeholder:
              selectedPlatformID === WORKPLATFORM_IDS.InstagramPlatformId ? SEARCH_BY_CITY_OR_COUNTRY_PLACEHOLDER : SEARCH_BY_COUNTRY_PLACEHOLDER,
            showTags: true,
            filterKey: key,
            getFilterValue: (values) => {
              values = values.map((item) => {
                return {
                  name: item.display_name,
                  value: item.id,
                };
              });

              dispatchCreatorDiscoveryWarehouse({ type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS, payload: { key: key, options: values } });

              values = values.map((item) => item.value);
              upsertQueryParams({ filterKey: key, filterType: type, value: values });
              upsertPayloadInFiltersPayload({
                filterKey: key,
                filterType: type,
                value: values,
                creatorDiscoveryWarehouse,
                dispatchCreatorDiscoveryWarehouse,
                warehouse
              });
            },
          };
          props = {
            ...props,
            ...getSearchableDetailsBasedOnAPI({ api: metadata?.api, warehouse }),
          };
          if (payload && payload?.searchPayload && metadata?.options) {
            props.defaultValue = payload.searchPayload.map((item) => {
              return {
                id: item,
                name: metadata?.options.find((option) => option.value === item)?.name,
                display_name: metadata?.options.find((option) => option.value === item)?.name,
              };
            });
          }
          // add code to handle meta_data default values
          // if (payload && payload.searchPayload) {
          //   // api_searchable_dropdown:Array[string] selected value1 selected value
          //   const selectedCode = payload.searchPayload;
          //   const value = props.options.filter((item) => selectedCode.includes(item.value));
          //   props.defaultValue = value;
          // }
          newFilter = {
            type: type,
            label: name,
            description: description,
            isSelected: payload ? true : false,
            id: Math.floor(Math.random() * 10000000),
            isPrimaryFilter: false,
            Filter: <ApiSearchableDropdown {...props} />,
          };
        } else if (key === "topic_relevance") {
          let props = {
            defaultValue: [],
            placeholder: metadata?.search_placeholder,
            showTags: true,
            filterKey: key,
            getFilterValue: (values) => {
              values = values.map((item) => {
                return {
                  name: item.display_name,
                  value: item.id,
                };
              });

              dispatchCreatorDiscoveryWarehouse({ type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS, payload: { key: key, options: values } });

              values = values.map((item) => item.value);
              upsertQueryParams({ filterKey: key, filterType: type, value: values });
              upsertPayloadInFiltersPayload({
                filterKey: key,
                filterType: type,
                value: values,
                creatorDiscoveryWarehouse,
                dispatchCreatorDiscoveryWarehouse,
                warehouse
              });
            },
          };
          props = {
            ...props,
            ...getSearchableDetailsBasedOnAPI({ api: metadata?.api, warehouse }),
          };
          if (payload && payload?.searchPayload && metadata?.options) {
            props.defaultValue = payload.searchPayload.map((item) => {
              return {
                id: item,
                name: metadata?.options.find((option) => option.value === item)?.name,
                display_name: metadata?.options.find((option) => option.value === item)?.name,
              };
            });
          }
          // add code to handle meta_data default values
          // if (payload && payload.searchPayload) {
          //   // api_searchable_dropdown:Array[string] selected value1 selected value
          //   const selectedCode = payload.searchPayload;
          //   const value = props.options.filter((item) => selectedCode.includes(item.value));
          //   props.defaultValue = value;
          // }
          newFilter = {
            type: type,
            label: name,
            description: description,
            isSelected: payload ? true : false,
            id: Math.floor(Math.random() * 10000000),
            isPrimaryFilter: false,
            Filter: <ApiSearchableDropdown {...props} showTagsInTextfield={true} filterName={name} showTags={false} />,
          };
        } else {
          const props = {
            options: metadata.options ? metadata.options : getOptionsBasedOnAPI(metadata.api),
            multi: true,
            searchPlaceholder: metadata.search_placeholder,
            filterKey: key,
            getFilterValue: (value) => {
              //value Array[{name:String,value:String}]
              value = value.map((item) => item.value);
              // value Array[String]
              upsertQueryParams({ filterKey: key, filterType: type, value });
              upsertPayloadInFiltersPayload({
                filterKey: key,
                filterType: type,
                value,
                creatorDiscoveryWarehouse,
                dispatchCreatorDiscoveryWarehouse,
                warehouse
              });
            },
          };
          if (props?.options.length > 0 && !metadata?.options && !metadata?.options?.length > 0) {
            dispatchCreatorDiscoveryWarehouse({ type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS, payload: { key: key, options: props.options } });
          }
          // add code to handle meta_data default values
          if (payload && payload.searchPayload) {
            // api_searchable_dropdown:Array[string] selected value1 selected value
            const selectedCode = payload.searchPayload;
            const value = props.options.filter((item) => selectedCode.includes(item.value));
            props.defaultValue = value;
          }
          newFilter = {
            type: type,
            label: name,
            description: description,
            isSelected: payload ? true : false,
            id: Math.floor(Math.random() * 10000),
            isPrimaryFilter: false,
            Filter: <SearchableDropDown {...props} />,
          };
        }
      }
      break;
    case FILTER_TYPES.API_SEARCHABLE_MULTI_SELECT_DROPDOWN_STEPS_SLIDER:
      {
        if (metadata.api === "locations_api" || metadata.api === "lookalike_api") {
          let props = {
            steps: metadata.steps,
            defaultSliderValue: metadata.default_step_value,
            placeholder:
              key === "audience_locations"
                ? selectedPlatformID === WORKPLATFORM_IDS.InstagramPlatformId
                  ? SEARCH_BY_CITY_OR_COUNTRY_PLACEHOLDER
                  : SEARCH_BY_COUNTRY_PLACEHOLDER
                : metadata?.search_placeholder,
            filterKey: key,
            getFilterValue: (value) => {
              value = Object.values(value);

              let options = value.map((item) => {
                return {
                  value: item.id,
                  name: item.display_name,
                };
              });
              dispatchCreatorDiscoveryWarehouse({
                type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS,
                payload: {
                  key: key,
                  options,
                },
              });

              value = value.map((item) => {
                return {
                  selected_value: item.id,
                  percentage_value: item.percentage_value,
                };
              });
              upsertQueryParams({ filterKey: key, filterType: type, value });
              upsertPayloadInFiltersPayload({
                filterKey: key,
                filterType: type,
                value,
                creatorDiscoveryWarehouse,
                dispatchCreatorDiscoveryWarehouse,
                warehouse
              });
            },
          };
          props = {
            ...props,
            ...getSearchableDetailsBasedOnAPI({ api: metadata?.api, warehouse }),
          };

          if (payload && payload?.searchPayload && metadata?.options) {
            props.defaultValue = payload?.searchPayload.map((item) => {
              return {
                id: item?.selected_value,
                name: metadata?.options.find((option) => option.value === item.selected_value)?.name,
                display_name: metadata?.options.find((option) => option.value === item.selected_value)?.name,

                percentage_value: item?.percentage_value,
              };
            });
          }
          newFilter = {
            type: type,
            label: name,
            description: description,
            isSelected: payload ? true : false,
            id: Math.floor(Math.random() * 1000000000),
            isPrimaryFilter: false,
            Filter: <ApiSearchableDropdownSlider {...props} />,
          };
        } else {
          const props = {
            options: getOptionsBasedOnAPI(metadata.api),
            searchPlaceholder: metadata.search_placeholder,
            steps: metadata.steps,
            defaultSliderValue: metadata.default_step_value,
            multi: true,
            filterKey: key,
            getFilterValue: (value) => {
              value = value.map((item) => {
                return {
                  selected_value: item.selected,
                  percentage_value: item.step,
                };
              });
              upsertQueryParams({ filterKey: key, filterType: type, value });
              upsertPayloadInFiltersPayload({
                filterKey: key,
                filterType: type,
                value,
                creatorDiscoveryWarehouse,
                dispatchCreatorDiscoveryWarehouse,
                warehouse
              });
            },
          };

          if (props?.options.length > 0 && !metadata?.options && !metadata?.options?.length > 0) {
            dispatchCreatorDiscoveryWarehouse({ type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS, payload: { key: key, options: props.options } });
          }
          // add code to handle meta_data default values
          if (payload && payload?.searchPayload && metadata?.options) {
            props.defaultValue = payload?.searchPayload.map((item) => {
              return {
                id: item?.selected_value,
                name: metadata?.options.find((option) => option.value === item.selected_value)?.name,
                display_name: metadata?.options.find((option) => option.value === item.selected_value)?.name,

                percentage_value: item?.percentage_value,
              };
            });
          }
          newFilter = {
            type: type,
            label: name,
            description: description,
            isSelected: payload ? true : false,
            id: Math.floor(Math.random() * 1000000000),
            isPrimaryFilter: false,
            Filter: <SearchableDropdownSlider {...props} />,
          };
        }
      }
      break;
    case FILTER_TYPES.TEXT:
      {
        const props = {
          selectedTags: "",
          filterKey: key,
          getFilterValue: (value) => {
            // value:String
            upsertQueryParams({ filterKey: key, filterType: type, value });
            upsertPayloadInFiltersPayload({ filterKey: key, filterType: type, value, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse });
          },
          placeholder: metadata?.search_placeholder,
        };
        if (payload && payload.searchPayload) {
          // searchPayload String selected value
          props.selectedTags = payload.searchPayload;
        }
        newFilter = {
          type: type,
          label: name,
          description: description,
          isSelected: payload ? true : false,
          id: Math.floor(Math.random() * 10000),
          isPrimaryFilter: false,
          Filter: <TagsFilter {...props} />,
        };
      }
      break;
    case FILTER_TYPES.PERCENTAGE_SLIDER:
      {
        const props = {
          sliderFilterHeader: "",
          isMultipleFilter: false,
          defaultSliderValue: metadata.percentage_default,
          sliderMin: 1,
          sliderMax: 100,
          sliderStepInc: 1,
          filterKey: key,
          getFilterValue: (value) => {
            // value:number
            value = {
              percentage_value: value,
            };
            upsertQueryParams({ filterKey: key, filterType: type, value });
            upsertPayloadInFiltersPayload({ filterKey: key, filterType: type, value, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse });
          },
        };
        if (payload && payload.searchPayload) {
          // percentage_value number selected value
          props.defaultSliderValue = payload.searchPayload?.percentage_value;
        }
        newFilter = {
          type: type,
          label: name,
          description: description,
          isSelected: payload ? true : false,
          id: Math.floor(Math.random() * 10000),
          isPrimaryFilter: false,
          Filter: <SliderFilter {...props} />,
        };
      }
      break;
    case FILTER_TYPES.MIN_MAX_DROPDOWN:
      {
        const props = {
          minValuesOptions: metadata.min_options,
          maxValuesOptions: metadata.max_options,
          defaultMinValue: "",
          defaultMaxValue: "",
          filterKey: key,
          getFilterValue: (value) => {
            // value:{min:number || string,max:number || string}
            upsertQueryParams({ filterKey: key, filterType: type, value });
            upsertPayloadInFiltersPayload({ filterKey: key, filterType: type, value, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse });
          },
        };
        if (payload && payload.searchPayload) {
          // searchPayload {min:number,max:number}
          props.defaultMinValue = payload.searchPayload?.min;
          props.defaultMaxValue = payload.searchPayload?.max;
        }
        newFilter = {
          type: type,
          label: name,
          description: description,
          isSelected: payload ? true : false,
          id: Math.floor(Math.random() * 10000),
          isPrimaryFilter: false,
          Filter: <MinMaxDropdownFilter {...props} />,
        };
      }
      break;
    case FILTER_TYPES.DROPDOWN_STEPS_SLIDER:
      {
        const props = {
          options: metadata.options,
          defaultSliderValue: metadata.default_step_value,
          steps: metadata.steps,
          showSliderOnDropdownValues: metadata.show_slider_on_dropdown_values,
          filterKey: key,
          getFilterValue: (value) => {
            // value:{selected_value:String,percentage_value:number}
            upsertQueryParams({ filterKey: key, filterType: type, value });
            upsertPayloadInFiltersPayload({ filterKey: key, filterType: type, value, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse,warehouse });
          },
          // label: name,
        };

        if (payload && payload.searchPayload) {
          // searchPayload {selected_value:string,percentage_value:number}
          props.defaultDropDownValue = payload.searchPayload?.selected_value;
          props.defaultSliderValue = payload.searchPayload?.percentage_value;
        }
        newFilter = {
          type: type,
          label: name,
          description: description,
          isSelected: payload ? true : false,
          id: Math.floor(Math.random() * 10000),
          isPrimaryFilter: false,
          Filter: <DropdownSlider {...props} />,
        };
      }
      break;
    case FILTER_TYPES.MIN_MAX_DROPDOWN_STEPS_SLIDER:
      {
        const props = {
          minValuesOptions: metadata.min_options,
          maxValuesOptions: metadata.max_options,
          defaultMinValue: "",
          defaultMaxValue: "",
          defaultSliderValue: metadata.default_step_value,
          steps: metadata.steps,
          filterKey: key,
          getFilterValue: (value) => {
            // value {min:num,max:num,percentage_value:num}

            upsertQueryParams({ filterKey: key, filterType: type, value });
            upsertPayloadInFiltersPayload({ filterKey: key, filterType: type, value, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse });
          },
        };
        if (payload && payload.searchPayload) {
          // {min:num,max:num,percentage_value:num}
          props.defaultMinValue = payload.searchPayload?.min;
          props.defaultMaxValue = payload.searchPayload?.max;
          if (payload.searchPayload?.percentage_value) props.defaultSliderValue = payload.searchPayload?.percentage_value;
        }
        newFilter = {
          type: type,
          label: name,
          description: description,
          isSelected: payload ? true : false,
          id: Math.floor(Math.random() * 10000),
          isPrimaryFilter: false,
          Filter: <MinMaxDropdownSlider {...props} />,
        };
      }
      break;
    case FILTER_TYPES.SEARCHABLE_MULTI_SELECT_CHECKBOX_MODAL: {
      const props = {
        contactTypeList: metadata.options,
        filterKey: key,
        getFilterValue: (value) => {
          upsertQueryParams({ filterKey: key, filterType: type, value });
          upsertPayloadInFiltersPayload({
            filterKey: key,
            filterType: type,
            value,
            creatorDiscoveryWarehouse,
            dispatchCreatorDiscoveryWarehouse,
            warehouse
          });
        },
        open: true,
      };
      // add code to handle meta_data default values
      if (payload && payload.searchPayload) {
        props.defaultSelectedItems = payload?.searchPayload?.map((defaultItem) => {
          return defaultItem?.type;
        });
      }
      newFilter = {
        type: type,
        label: name,
        description: description,
        isSelected: payload ? true : false,
        id: Math.floor(Math.random() * 10000),
        isPrimaryFilter: false,
        Filter: <SearchableCheckBoxModal {...props} />,
      };
    }
    default:
      break;
  }

  if (!newFilter) {
    return;
  }

  newFilter.filterKey = key;
  newFilter.lastInteractedFilter = creatorDiscoveryWarehouse.lastInteractedFilter;

  if (setPrimaryFilters && primaryFiltersIdentifier.includes(key)) {
    newFilter.isPrimaryFilter = true;
    setPrimaryFilters((prev) => {
      return removeDuplicates([...prev, newFilter], "label");
    });
  }
  if (setAudienceFilters && category === "Audience") {
    setAudienceFilters((prev) => {
      return removeDuplicates([...prev, newFilter], "label");
    });
  }
  if (setCreatorsFilters && category === "Creator") {
    setCreatorsFilters((prev) => {
      return removeDuplicates([...prev, newFilter], "label");
    });
  }
  if (setPerformanceFilters && category === "Performance") {
    setPerformanceFilters((prev) => {
      return removeDuplicates([...prev, newFilter], "label");
    });
  }
}

export default renderFilter;

// This function converts the payload into the required format as per the search API
export const getSearchPayload = (filterType, filterKey, filterPayload) => {
  switch (filterType) {
    case FILTER_TYPES.BOOLEAN:
    case FILTER_TYPES.DROPDOWN:
    case FILTER_TYPES.TEXT:
      return filterPayload;
    case FILTER_TYPES.API_SEARCHABLE_DROPDOWN:
      // if(filterKey === )
      return filterPayload;
    case FILTER_TYPES.DROPDOWN_STEPS_SLIDER:
    case FILTER_TYPES.API_SEARCHABLE_DROPDOWN_STEPS_SLIDER:
      return {
        selected_value: filterPayload.selected_value,
        percentage_value: filterPayload.percentage_value,
      };
    case FILTER_TYPES.MIN_MAX_DROPDOWN:
      return {
        ...(!isEmpty(filterPayload.max) && { max: filterPayload.max }),
        ...(!isEmpty(filterPayload.min) && { min: filterPayload.min }),
      };
    case FILTER_TYPES.API_SEARCHABLE_MULTI_SELECT_DROPDOWN:
    case FILTER_TYPES.MULTI_SELECT_TEXT:
      return [...filterPayload];
    case FILTER_TYPES.API_SEARCHABLE_MULTI_SELECT_DROPDOWN_STEPS_SLIDER:
      return [
        ...filterPayload.map((value) => ({
          selected_value: value.selected_value,
          percentage_value: value.percentage_value,
        })),
      ];
    case FILTER_TYPES.PERCENTAGE_SLIDER:
      return {
        percentage_value: filterPayload.percentage_value,
      };
    case FILTER_TYPES.MIN_MAX_DROPDOWN_STEPS_SLIDER:
      return {
        ...(!isEmpty(filterPayload.min) && { min: filterPayload.min }),
        ...(!isEmpty(filterPayload.max) && { max: filterPayload.max }),
        percentage_value: filterPayload.percentage_value,
      };
    case FILTER_TYPES.SEARCHABLE_MULTI_SELECT_CHECKBOX_MODAL:
      return [
        ...filterPayload.map((value) => ({
          type: value,
        })),
      ];
    default:
      return {};
  }
};

// This function will create the string tags based on each filter type
export const getFilterTag = ({
  filterType,
  filterName,
  filterValue,
  filterPayload,
  dispatchCreatorDiscoveryWarehouse,
  filterkey,
  filterSource,
  warehouse,
}) => {
  let options;
  let name;
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);
  const queryParams = Object.fromEntries(searchParams);
  const selectedWorkPlatformId = workPlatformsDropdownOptions.filter((option) => option.value === queryParams.platform)[0].id;
  try {
    filterValue = JSON.parse(filterValue);
  } catch (e) {}

  switch (filterType) {
    case FILTER_TYPES.BOOLEAN:
      return filterValue && `${filterName.slice(0, -1)}`;
    case FILTER_TYPES.DROPDOWN:
    case FILTER_TYPES.API_SEARCHABLE_DROPDOWN:
      options = filterPayload?.meta_data?.options;
      if (isEmpty(options) && filterPayload?.meta_data?.api === "locations_api")
        getLocationsBasedOnValue({ value: filterValue, filterkey, dispatchCreatorDiscoveryWarehouse, warehouse });
      if (isEmpty(options) && filterPayload?.meta_data?.api === "lookalike_api") {
        getLookalikesBasedOnValue({ value: filterValue, filterkey, dispatchCreatorDiscoveryWarehouse, selectedWorkPlatformId, warehouse });
      }
      if (isEmpty(options)) return "";
      if (filterPayload?.meta_data?.api === "lookalike_api") {
        // Special Handling for CREATOR_LOOKALIKES
        if (filterkey === CREATOR_LOOKALIKES) {
          if (selectedWorkPlatformId === WORKPLATFORM_IDS.YouTubePlatformId) {
            if (options.data) return `${filterName}: ${options?.data?.find((option) => option.user_id === filterValue.substring(1))?.username}`;
            return `${filterName}: ${options[0]?.value}`;
          }
          if (filterValue.charAt(0) === "@") return `${filterName}: ${filterValue.substring(1)}`;
          else return `${filterName}: ${filterValue}`;
        }
        return `${filterName}: ${filterValue}`;
      } else {
        name = options?.find((option) => option.value == filterValue)?.name; // == is intentional for coercion
        if (name.includes("undefined")) {
          if (filterPayload?.meta_data?.options) filterPayload.meta_data.options = [];
          return;
        }
      }
      return `${filterName}: ${name}`;
    case FILTER_TYPES.MULTI_SELECT_TEXT:
      return `${filterName}: ${filterValue.join(", ")}`;
    case FILTER_TYPES.API_SEARCHABLE_DROPDOWN_STEPS_SLIDER:
    case FILTER_TYPES.DROPDOWN_STEPS_SLIDER:
      options = filterPayload?.meta_data?.options;
      if (isEmpty(options) && filterPayload?.meta_data?.api === "locations_api")
        // && filterSource === "url")
        getLocationsBasedOnValue({ value: filterValue, filterkey, dispatchCreatorDiscoveryWarehouse, warehouse });
      if (isEmpty(options)) return "";
      name = options?.find((option) => option.value === filterValue.selected_value)?.name;
      if (name.includes("undefined")) {
        if (filterPayload?.meta_data?.options) filterPayload.meta_data.options = [];
        return;
      }
      return `${filterName}: ${name},>${filterValue.percentage_value}%`;
    case FILTER_TYPES.API_SEARCHABLE_MULTI_SELECT_DROPDOWN_STEPS_SLIDER: {
      options = filterPayload?.meta_data?.options;
      if (isEmpty(options) && filterPayload?.meta_data?.api === "locations_api")
        // && filterSource === "url")
        getLocationsBasedOnValue({ value: filterValue.map((item) => item.selected_value), filterkey, dispatchCreatorDiscoveryWarehouse, warehouse });
      if (isEmpty(options)) return "";
      name = [];
      filterValue.forEach((item) => {
        name.push(options?.find((option) => option.value === item.selected_value)?.name + ", >" + item.percentage_value + "%");
      });
      if (name.filter((item) => isEmpty(item) || item?.includes("undefined")).length > 0) {
        if (filterPayload?.meta_data?.options) {
          dispatchCreatorDiscoveryWarehouse({ type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS, payload: { key: filterkey, options: [] } });
          dispatchCreatorDiscoveryWarehouse({ type: SET_SHOULD_RENDER_TRUE });
        }
      }
      return `${filterName}: ${name.join(" | ")}`;
    }
    case FILTER_TYPES.MIN_MAX_DROPDOWN:
      const minOptions = filterPayload?.meta_data?.min_options;
      const maxOptions = filterPayload?.meta_data?.max_options;
      if (!minOptions && !maxOptions) return "";
      const minName = minOptions?.find((option) => option.value === Number(filterValue.min))?.name || "";
      const maxName = maxOptions?.find((option) => option.value === Number(filterValue.max))?.name || "";
      return `${filterName}: ${minName} - ${maxName}`;
    // return `${filterName}: ${filterValue.min} - ${filterValue.max}`;
    case FILTER_TYPES.MIN_MAX_DROPDOWN_STEPS_SLIDER:
      return `${filterName}: ${filterValue.min} - ${filterValue.max}, >${filterValue.percentage_value}%`;
    case FILTER_TYPES.API_SEARCHABLE_MULTI_SELECT_DROPDOWN:
      options = filterPayload?.meta_data?.options;
      if (isEmpty(options) && filterPayload?.meta_data?.api === "locations_api")
        // && filterSource === "url")
        getLocationsBasedOnValue({ value: filterValue, filterkey, dispatchCreatorDiscoveryWarehouse, warehouse });
      if (isEmpty(options) && filterPayload?.meta_data?.api === "lookalike_api")
        // && filterSource === "url")
        getLookalikesBasedOnValue({ value: filterValue, filterkey, dispatchCreatorDiscoveryWarehouse, selectedWorkPlatformId, warehouse });
      if (isEmpty(options) && filterPayload?.meta_data?.api === "topic_tags_api") {
        //&& filterSource === "url") {
        getTopicTagsBasedOnValue({ value: filterValue, filterkey, dispatchCreatorDiscoveryWarehouse, selectedWorkPlatformId, warehouse });
      }
      if (isEmpty(options)) return "";
      name = [];
      filterValue.forEach((item) => {
        name.push(options?.find((option) => option.value === item)?.name);
      });
      if (name.filter((item) => isEmpty(item) || item?.includes("undefined")).length > 0) {
        if (filterPayload?.meta_data?.options) {
          dispatchCreatorDiscoveryWarehouse({ type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS, payload: { key: filterkey, options: [] } });
          dispatchCreatorDiscoveryWarehouse({ type: SET_SHOULD_RENDER_TRUE });
        }
      }
      return `${filterName}: ${name.join(", ")}`;
    case FILTER_TYPES.TEXT:
      return `${filterName}: ${filterValue}`;
    case FILTER_TYPES.PERCENTAGE_SLIDER:
      return `${filterName}: ≥ ${filterValue.percentage_value}%`;
    case FILTER_TYPES.SEARCHABLE_MULTI_SELECT_CHECKBOX_MODAL:
      options = filterPayload?.meta_data?.options;
      if (isEmpty(options)) return "";
      name = [];
      filterValue.forEach((item) => {
        const contactFilterTag = getSelectedContactOption(item, options);
        if (contactFilterTag) name.push(contactFilterTag);
      });
      return `${filterName}: ${name?.join(", ")}`;
    default:
      return "";
  }
};

function getSelectedContactOption(item, options) {
  if (item === LINEID) {
    return LINE;
  } else if (item === TWITTER) {
    return XCOM;
  } else {
    const option = options?.find(option => option.value === item);
    if (option) {
      return option.value.charAt(0) + option.value.slice(1).toLowerCase();
    }
  }
}

export const getFilterNameFromValues =
  (values) =>
  ({ filterType, filterName, filterValue, filterPayload, dispatchCreatorDiscoveryWarehouse, filterkey, filterSource, warehouse }) => {
    let options;
    let name;
    try {
      filterValue = JSON.parse(filterValue);
    } catch (e) {}
    switch (filterType) {
      case FILTER_TYPES.BOOLEAN:
        return filterValue && `${filterName.slice(0, -1)}`;
      case FILTER_TYPES.DROPDOWN:
      case FILTER_TYPES.API_SEARCHABLE_DROPDOWN:
        options = filterPayload?.meta_data?.options;
        if (isEmpty(options) && filterPayload?.meta_data?.api === "locations_api")
          getLocationsBasedOnValue({ value: filterValue, filterkey, dispatchCreatorDiscoveryWarehouse, warehouse });
        if (filterPayload?.meta_data?.api === "lookalike_api") return `${filterValue}`;
        if (isEmpty(options)) return "";
        name = options?.find((option) => option.value == filterValue)?.name; // == is intentional for coercion
        return `${name}`;
      case FILTER_TYPES.MULTI_SELECT_TEXT:
        return `${filterValue.join(", ")}`;
      case FILTER_TYPES.API_SEARCHABLE_DROPDOWN_STEPS_SLIDER:
      case FILTER_TYPES.DROPDOWN_STEPS_SLIDER:
        options = filterPayload?.meta_data?.options;
        if (isEmpty(options) && filterPayload?.meta_data?.api === "locations_api")
          // && filterSource === "url")
          getLocationsBasedOnValue({ value: filterValue, filterkey, dispatchCreatorDiscoveryWarehouse, warehouse });
        if (isEmpty(options)) return "";
        name = options?.find((option) => option.value === filterValue.selected_value)?.name;
        return `${name},${filterValue.percentage_value}%`;
      case FILTER_TYPES.API_SEARCHABLE_MULTI_SELECT_DROPDOWN_STEPS_SLIDER: {
        options = filterPayload?.meta_data?.options;
        if (isEmpty(options) && filterPayload?.meta_data?.api === "locations_api")
          // && filterSource === "url")
          getLocationsBasedOnValue({
            value: filterValue.map((item) => item.selected_value),
            filterkey,
            dispatchCreatorDiscoveryWarehouse,
            warehouse,
          });
        if (isEmpty(options)) return "";
        name = [];
        filterValue.forEach((item) => {
          name.push(options?.find((option) => option.value === item.selected_value)?.name + ", >" + item.percentage_value + "%");
        });
        return `${name.join(" | ")}`;
      }
      case FILTER_TYPES.MIN_MAX_DROPDOWN:
        const minOptions = filterPayload?.meta_data?.min_options;
        const maxOptions = filterPayload?.meta_data?.max_options;
        if (!minOptions && !maxOptions) return "";
        const minName = minOptions?.find((option) => option.value === Number(filterValue.min))?.name || "";
        const maxName = maxOptions?.find((option) => option.value === Number(filterValue.max))?.name || "";
        return `${minName} - ${maxName}`;
      // return `${filterValue.min} - ${filterValue.max}`;
      case FILTER_TYPES.MIN_MAX_DROPDOWN_STEPS_SLIDER:
        return `${filterValue.min} - ${filterValue.max}, >${filterValue.percentage_value}%`;
      case FILTER_TYPES.API_SEARCHABLE_MULTI_SELECT_DROPDOWN:
        options = filterPayload?.meta_data?.options;
        if (isEmpty(options) && filterPayload?.meta_data?.api === "locations_api")
          //  && filterSource === "url")
          getLocationsBasedOnValue({ value: filterValue, filterkey, dispatchCreatorDiscoveryWarehouse, warehouse });
        if (isEmpty(options) && filterPayload?.meta_data?.api === "lookalike_api")
          // && filterSource === "url")
          getLookalikesBasedOnValue({ value: filterValue, filterkey, dispatchCreatorDiscoveryWarehouse });
        if (isEmpty(options)) return "";
        name = [];
        filterValue.forEach((item) => {
          name.push(options?.find((option) => option.value === item)?.name);
        });

        return `${name.join(", ")}`;
      case FILTER_TYPES.TEXT:
        return `${filterValue}`;
      case FILTER_TYPES.PERCENTAGE_SLIDER:
        return `${filterValue.percentage_value}%`;
      default:
        return "";
    }
  };

// This function will insert the query param value on each user action on filters
export const upsertQueryParams = ({ filterKey, filterType, value }) => {
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);

  if (isEmptyFilterValue(filterType, value)) {
    searchParams.delete(filterKey);
    url.search = searchParams;
    window.history.pushState({}, "", url);
    return;
  }
  switch (filterType) {
    case FILTER_TYPES.BOOLEAN:
    case FILTER_TYPES.DROPDOWN:
    case FILTER_TYPES.API_SEARCHABLE_DROPDOWN:
    case FILTER_TYPES.TEXT:
      searchParams.set(filterKey, value);
      break;
    case FILTER_TYPES.DROPDOWN_STEPS_SLIDER:
    case FILTER_TYPES.PERCENTAGE_SLIDER:
    case FILTER_TYPES.API_SEARCHABLE_DROPDOWN_STEPS_SLIDER:
    case FILTER_TYPES.MIN_MAX_DROPDOWN:
    case FILTER_TYPES.API_SEARCHABLE_MULTI_SELECT_DROPDOWN:
    case FILTER_TYPES.MULTI_SELECT_TEXT:
    case FILTER_TYPES.API_SEARCHABLE_MULTI_SELECT_DROPDOWN_STEPS_SLIDER:
    case FILTER_TYPES.SEARCHABLE_MULTI_SELECT_CHECKBOX_MODAL:
    case FILTER_TYPES.MIN_MAX_DROPDOWN_STEPS_SLIDER:
      searchParams.set(filterKey, JSON.stringify(value));
      break;
    default:
      searchParams.set("");
  }
  url.search = searchParams; //.toString();
  sessionStorage.setItem("urlWithParams", JSON.stringify(url));
  window.history.pushState({}, "", url);
};

// This function will update the payload inside filterPayload (Master JSON) on each user action on filters
export const upsertPayloadInFiltersPayload = ({
  filterKey,
  value,
  creatorDiscoveryWarehouse,
  dispatchCreatorDiscoveryWarehouse,
  warehouse,
  filterSource,
}) => {
  const filtersPayload = creatorDiscoveryWarehouse.filters;
  const updatedFilterPayload = creatorDiscoveryWarehouse.filters[filterKey];
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);
  const queryParams = Object.fromEntries(searchParams);
  const selectedWorkPlatformId = workPlatformsDropdownOptions.filter((option) => option.value === queryParams.platform)[0].id;
  const filtersIdentifierBasedOnSelectedPlatform = creatorDiscoveryWarehouse.work_platforms[selectedWorkPlatformId].filters;
  const isSelectedFilterPrimary = filtersIdentifierBasedOnSelectedPlatform.filter((filter) => filter.identifier === filterKey);
  if (isEmptyFilterValue(updatedFilterPayload.type, value)) {
    updatedFilterPayload.payload = null;
    dispatchCreatorDiscoveryWarehouse({ type: UPDATE_FILTER_PAYLOAD, payload: { [filterKey]: updatedFilterPayload } });
    return;
  }
  const isFilterPayloadExist = Boolean(updatedFilterPayload.payload);
  updatedFilterPayload.payload = {
    searchPayload: getSearchPayload(filtersPayload[filterKey].type, filterKey, value),
    tags: getFilterTag({
      filterType: filtersPayload[filterKey].type,
      filterName: filtersPayload[filterKey].display_name.replace("'", ""),
      filterValue: value,
      filterPayload: updatedFilterPayload,
      dispatchCreatorDiscoveryWarehouse,
      filterkey: filterKey,
      filterSource,
      warehouse,
    }),
  };
  if (isFilterPayloadExist) {
    // If payload already exists and getting updated
    trackEvent(SEARCH_FILTER_UPDATED, {
      filter_name: filtersPayload[filterKey].display_name.replace("'", ""),
      category: filtersPayload[filterKey].category,
      type: isSelectedFilterPrimary[0]?.is_primary || isSelectedFilterPrimary[0]?.is_super_primary ? "primary" : "secondary",
      values: updatedFilterPayload.payload.tags,
      source: isSelectedFilterPrimary[0]?.is_primary || isSelectedFilterPrimary[0]?.is_super_primary ? "primary_filters" : "all_filters",
      platform: queryParams.platform,
    });
  } else {
    // If payload does not exists and getting added
    trackEvent(SEARCH_FILTER_ADDED, {
      filter_name: filtersPayload[filterKey].display_name.replace("'", ""),
      category: filtersPayload[filterKey].category,
      type: isSelectedFilterPrimary[0]?.is_primary || isSelectedFilterPrimary[0]?.is_super_primary ? "primary" : "secondary",
      values: updatedFilterPayload.payload.tags,
      source: isSelectedFilterPrimary[0]?.is_primary || isSelectedFilterPrimary[0]?.is_super_primary ? "primary_filters" : "all_filters",
      platform: queryParams.platform,
    });
  }
  dispatchCreatorDiscoveryWarehouse({ type: UPDATE_FILTER_PAYLOAD, payload: { [filterKey]: updatedFilterPayload } });
};

// This function will be called to update the search payload at once from the query params (to be called once in useEffect while rendering for the first time)
export const upsertPayloadFromQueryParams = ({ queryParams, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse }) => {
  const updatedFiltersPayload = { ...creatorDiscoveryWarehouse.filters };
  Object.keys(queryParams).forEach((key) => {
    if (updatedFiltersPayload[key]) {
      let selectedPayloadFromURl = queryParams[key];
      try {
        selectedPayloadFromURl = JSON.parse(selectedPayloadFromURl);
      } catch (e) {}
      updatedFiltersPayload[key].payload = {
        searchPayload: getSearchPayload(updatedFiltersPayload[key].type, key, selectedPayloadFromURl),
        tags: getFilterTag({
          filterType: updatedFiltersPayload[key].type,
          filterName: updatedFiltersPayload[key].display_name,
          filterValue: selectedPayloadFromURl,
          filterPayload: updatedFiltersPayload[key],
          dispatchCreatorDiscoveryWarehouse,
          filterkey: key,
          filterSource: "url",
          warehouse,
        }),
      };
    }
  });
  dispatchCreatorDiscoveryWarehouse({ type: UPDATE_FILTER_PAYLOAD, payload: updatedFiltersPayload });
};

// This function will be used to extract the search payload from fitlers payload to be sent in the search API
export const createSearchPayloadFromFiltersPayload = (filtersPayload) => {
  const searchPayload = {};
  const tags = {};
  Object.keys(filtersPayload).forEach((key) => {
    // Special Handling For payload of contact type filter when we select ANY Option
    if (filtersPayload[key].payload) {
      if (key === CONTACT_TYPE_FILTER_KEY && filtersPayload[key].payload.searchPayload[0].type === ANY_CONTACT_OPTION_VALUE) {
        searchPayload[HAS_CONTACT_DETAILS_FILTER_KEY] = true;
      } else {
        searchPayload[key] = filtersPayload[key].payload.searchPayload;
      }
      tags[key] = filtersPayload[key].payload.tags;
    }
  });
  // append tags
  searchPayload.filter_tags = tags;

  return searchPayload;
};

// This function updates the filter tags when the API gets resolved and the options are available from the API response
export const updateFilterTags = ({ queryParams, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse }) => {
  const updatedFiltersPayload = { ...creatorDiscoveryWarehouse.filters };
  Object.keys(queryParams).forEach((key) => {
    if (updatedFiltersPayload[key]) {
      let selectedPayloadFromURl = queryParams[key];
      try {
        selectedPayloadFromURl = JSON.parse(selectedPayloadFromURl);
      } catch (e) {}
      const tags = getFilterTag({
        filterType: updatedFiltersPayload[key].type,
        filterName: updatedFiltersPayload[key].display_name,
        filterValue: selectedPayloadFromURl,
        filterPayload: updatedFiltersPayload[key],
        dispatchCreatorDiscoveryWarehouse,
        filterkey: key,
        warehouse,
      });
      dispatchCreatorDiscoveryWarehouse({ type: UPDATE_FILTER_TAG, payload: { key, tags } });
    }
  });
};

// This function is used to generate query params and payload from the API response - recent & featured
export const createQueryParamsFromSearchPayload = (searchPayload, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse, warehouse) => {
  deleteAllQueryParamsAndFilterPayload(creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse);
  Object.keys(searchPayload).forEach((key) => {
    if (creatorDiscoveryWarehouse.filters[key]) {
      const filterType = creatorDiscoveryWarehouse.filters[key].type;
      const value = searchPayload[key];
      upsertQueryParams({ filterKey: key, filterType, value });
      upsertPayloadInFiltersPayload({
        filterKey: key,
        filterType,
        value,
        creatorDiscoveryWarehouse,
        dispatchCreatorDiscoveryWarehouse,
        filterSource: "url",
        warehouse,
      });
    }
  });
  dispatchCreatorDiscoveryWarehouse({
    type: SET_SHOULD_RENDER_TRUE,
  });
};

// This function will be used to add the platform value to the query params
export const upsertPlatformToQueryParams = (platform) => {
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);
  searchParams.set("platform", platform);
  url.search = searchParams;
  window.history.pushState({}, "", url);
};
// This function will be used to delete the query params and filter payload on each user action on each filter
export const deleteQueryParamsAndFilterPayload = (filterKey, creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse) => {
  const filtersPayload = creatorDiscoveryWarehouse.filters;

  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);
  const queryParams = Object.fromEntries(searchParams);
  const selectedWorkPlatformId = workPlatformsDropdownOptions.filter((option) => option.value === queryParams.platform)[0].id;
  const filtersIdentifierBasedOnSelectedPlatform = creatorDiscoveryWarehouse.work_platforms[selectedWorkPlatformId].filters;
  const isSelectedFilterPrimary = filtersIdentifierBasedOnSelectedPlatform.filter((filter) => filter.identifier === filterKey);
  const valueToBeDeleted = creatorDiscoveryWarehouse.filters[filterKey].payload.searchPayload;
  trackEvent(SEARCH_FILTER_REMOVED, {
    filter_name: filtersPayload[filterKey].display_name.replace("'", ""),
    category: filtersPayload[filterKey].category,
    type: isSelectedFilterPrimary[0].is_primary || isSelectedFilterPrimary[0].is_super_primary ? "primary" : "secondary",
    values: creatorDiscoveryWarehouse.filters[filterKey].payload.tags,
    platform: queryParams.platform,
  });
  searchParams.delete(filterKey);
  url.search = searchParams;
  sessionStorage.setItem("urlWithParams", JSON.stringify(url));
  window.history.pushState({}, "", url);
  const updatedFilterPayload = creatorDiscoveryWarehouse.filters[filterKey];
  updatedFilterPayload.payload = null;
  dispatchCreatorDiscoveryWarehouse({ type: UPDATE_FILTER_PAYLOAD, payload: { [filterKey]: updatedFilterPayload } });
  dispatchCreatorDiscoveryWarehouse({
    type: SET_SHOULD_RENDER_TRUE,
  });
};

// This function will be used to delete all the query params and filter payload when user clicks on Remove All Filters
export const deleteAllQueryParamsAndFilterPayload = (creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse) => {
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);
  Object.keys(creatorDiscoveryWarehouse.filters).forEach((key) => {
    searchParams.delete(key);
  });
  url.search = searchParams;
  sessionStorage.setItem("urlWithParams", JSON.stringify(url));
  window.history.pushState({}, "", url);
  const updatedFiltersPayload = { ...creatorDiscoveryWarehouse.filters };
  Object.keys(updatedFiltersPayload).forEach((key) => {
    updatedFiltersPayload[key].payload = null;
  });
  dispatchCreatorDiscoveryWarehouse({ type: UPDATE_FILTER_PAYLOAD, payload: updatedFiltersPayload, shouldRender: true });
  dispatchCreatorDiscoveryWarehouse({
    type: SET_SHOULD_RENDER_TRUE,
  });
};

// This function checks for the Empty values inside the filter as per filter type. This also checks the null/blank values selected for a filter
const isEmptyFilterValue = (filterType, value) => {
  switch (filterType) {
    case FILTER_TYPES.BOOLEAN:
      return !value;
    case FILTER_TYPES.PERCENTAGE_SLIDER:
      return !(value?.percentage_value >= 0);
    case FILTER_TYPES.DROPDOWN:
    case FILTER_TYPES.API_SEARCHABLE_DROPDOWN:
    case FILTER_TYPES.TEXT:
      if (isEmpty(value)) return true;
      return false;
    case FILTER_TYPES.DROPDOWN_STEPS_SLIDER:
    case FILTER_TYPES.API_SEARCHABLE_DROPDOWN_STEPS_SLIDER:
      if (isEmpty(value.selected_value) || isEmpty(value.percentage_value)) return true;
      return false;
    case FILTER_TYPES.MIN_MAX_DROPDOWN:
    case FILTER_TYPES.MIN_MAX_DROPDOWN_STEPS_SLIDER:
      if (isEmpty(value.min) && isEmpty(value.max)) return true;
      return false;
    case FILTER_TYPES.API_SEARCHABLE_MULTI_SELECT_DROPDOWN:
    case FILTER_TYPES.MULTI_SELECT_TEXT:
      if (value.length === 0) return true;
      return false;
    case FILTER_TYPES.API_SEARCHABLE_MULTI_SELECT_DROPDOWN_STEPS_SLIDER:
      if (value.length === 0) return true;
      if (value[0].selected_value === "" && value[0].percentage_value === "") return true;
      return false;
    case FILTER_TYPES.SEARCHABLE_MULTI_SELECT_CHECKBOX_MODAL:
      if (value.length === 0) return true;
      return false;
    default:
      return true;
  }
};

const getLocationsBasedOnValue = async ({ value, filterkey, dispatchCreatorDiscoveryWarehouse, warehouse }) => {
  let response;
  if (Array.isArray(value)) {
    let promises = [];
    for (let i = 0; i < value.length; i++) {
      promises.push(creatorDiscoveryAPI.getLocationByID({ warehouse, id: value[i] }));
    }
    response = await Promise.all(promises);
  } else response = await creatorDiscoveryAPI.getLocationByID({ warehouse, id: value[0].value });

  const options = [];
  response.forEach((item) => {
    options.push({
      name: item.display_name,
      value: item.id,
    });
  });
  dispatchCreatorDiscoveryWarehouse({ type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS, payload: { key: filterkey, options } });
};

const getLookalikesBasedOnValue = async ({ value, filterkey, dispatchCreatorDiscoveryWarehouse, selectedWorkPlatformId, warehouse }) => {
  let response = await creatorDiscoveryAPI.getUsersHandles({
    searchQuery: value,
    workplatformId: selectedWorkPlatformId,
    type: "lookalike",
    is_search: false,
    warehouse,
  });
  dispatchCreatorDiscoveryWarehouse({ type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS, payload: { key: filterkey, options: response } });
};

const getTopicTagsBasedOnValue = async ({ value, filterkey, dispatchCreatorDiscoveryWarehouse, selectedWorkPlatformId, warehouse }) => {
  let response;
  if (Array.isArray(value)) {
    let promises = [];
    for (let i = 0; i < value.length; i++) {
      promises.push(
        creatorDiscoveryAPI.getTopicTags({
          searchQuery: value[i],
          workplatformId: selectedWorkPlatformId,
          warehouse,
        })
      );
    }
    response = await Promise.all(promises);
  } else
    response = await creatorDiscoveryAPI.getTopicTags({
      searchQuery: value,
      workplatformId: selectedWorkPlatformId,
      warehouse,
    });

  const options = [];
  response.forEach((item) => {
    options.push(item.data[0]);
  });
  dispatchCreatorDiscoveryWarehouse({ type: ADD_OPTIONS_TO_API_SEARCHABLE_FILTERS, payload: { key: filterkey, options } });
};
