import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Icon from "../Icons/Icons";
import Icons from "../Icons/Icons";

// We have a different icon for checkbox, so we need to use a different component
export default function FilterCheckBox({
  defaultChecked = true,
  handleMenuItemClick,
  item,
  ...props
}) {

  return (
    <div className={`check-box ${item.text}-checkbox`} onClick={(e) => handleMenuItemClick(e)}>
      {item.checked ? <i className="ri-checkbox-line checked-box-icon"></i> : <i className="ri-checkbox-blank-line unchecked-box-icon"></i>}
    </div>
  );
}