import Loader from "../Loader/Loader";
import { Box } from "@mui/material";
import { exportingCsv, fileDownloadWillStart } from "../../constants/constants";

export default function ExportLoadingModal({ open, title, onClose, showDisc = true }) {
    if (!open) {
        return null;
    }
    return (
        <div className="modal" onClick={onClose}>
            <Box className="export-results-overlay-loading" onClick={(e) => e.stopPropagation()}>
                <div className="export-result-loader-container">
                    <Loader className={"campaign-loader-container"}/>
                    <div className="export-result-loader-header">{title}</div>
                </div>
                {showDisc ? <div className="export-result-loader-description">{fileDownloadWillStart}</div> : null}
            </Box>
        </div>
    );
}
