import React, { useContext, useEffect, useState } from "react";
import "./LogOutPopUp.scss";
import Icons from "../Icons/Icons";
import PropTypes from "prop-types";
import { RESET_PASSWORD_LINK_CLICK, trackEvent } from "../../analytics";
import UserContext from "../../storage/context/userProfileContext";
import ResetPasswordDialog from "./ResetPasswordDialog/ResetPasswordDialog";
import { LOGOUT, RESET_PASSWORD } from "../../constants/constants";

const LogOutPopUp = ({ open, onClose, header, description, buttonAction }) => {
    const { userProfile } = useContext(UserContext);
    const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);

    function sendUserResetPasswordAction() {
        setShowResetPasswordDialog(true);
        trackEvent(RESET_PASSWORD_LINK_CLICK, {
            email: userProfile.user.email,
        });
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const modalContainer = document.querySelector(".log-out-pop-up-container");
            const elements = document.getElementsByClassName("sidebar-bottom-menu");
            if (modalContainer && !modalContainer.contains(event.target) && !elements[elements.length - 1].contains(event.target)) {
                elements[elements.length - 1].className = elements[elements.length - 1].className.replace(" active", "");
                onClose();
            }
        };

        if (open) {
            document.addEventListener("click", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [open, onClose]);

    if (!open) {
        return null;
    }

    return (
        <div
            className="log-out-pop-up-container"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div className="log-out-pop-up-icon">
                <Icons.log_out_icon/>
            </div>
            <div className={'log-out-user-text-container'}>
                <div className="section-heading color-neutrals-primary-grey">
                    {header}
                </div>
                <div className="body-r color-neutrals-secondary-grey">
                    {description}
                </div>
            </div>
            <hr className={'log-out-separator'}/>
            <div className={'log-out-text-container'}>
                <div className={'log-out-text body-m'} onClick={buttonAction}>
                    <i className="ri-logout-box-r-line"></i>
                    <span>{LOGOUT}</span>
                </div>
                <div className={'log-out-text body-m'} onClick={sendUserResetPasswordAction}>
                    <i className="ri-key-2-line"></i>
                    <span>{RESET_PASSWORD}</span>
                </div>
            </div>
            <ResetPasswordDialog
                email={userProfile.user.email}
                open={showResetPasswordDialog}
                handleClose={(e) => {
                    setShowResetPasswordDialog(false);
                    // onClose(e);
                }}/>
        </div>
    );
};

LogOutPopUp.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    header: PropTypes.string,
    description: PropTypes.string,
    buttonAction: PropTypes.func,
};

export default LogOutPopUp;
