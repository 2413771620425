import React, { useState, useRef } from "react";
import {
  Creators,
  Developers,
  ENVIRONMENT,
  MEETING_SCHEDULE_LINK,
  PRODUCTION_ACCESS_LABEL,
  environmentSelectionColorPallets,
} from "../../constants/constants";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControl, ListItemIcon } from "@mui/material";
import { SCHEDULE_A_CALL_CLICK, trackEvent } from "../../analytics";
import "./EnvironmentSelectionDropdown.scss";

const EnvironmentSelectionDropdown = ({
  environmentList,
  selectedEnvironment,
  setSelectedEnvironment,
  currentTab,
  isProductionKeysAvailable,
  isComplianceFormFilled,
  showCreatorLinkage,
  showCreatorSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const { REQUESTED, REQUEST_ACCESS } = PRODUCTION_ACCESS_LABEL;

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleChange = (event) => {
    setSelectedEnvironment(event.target.value);
    if (event.target.value === ENVIRONMENT.PRODUCTION) {
      if (currentTab === Developers && !isProductionKeysAvailable) {
        trackEvent(SCHEDULE_A_CALL_CLICK, { link: MEETING_SCHEDULE_LINK, source: "PRODUCTION_DROPDOWN" });
        window.open(MEETING_SCHEDULE_LINK, "_blank");
      } else if (currentTab === Creators && !showCreatorLinkage && !showCreatorSearch) {
        trackEvent(SCHEDULE_A_CALL_CLICK, { link: MEETING_SCHEDULE_LINK, source: "PRODUCTION_DROPDOWN" });
        window.open(MEETING_SCHEDULE_LINK, "_blank");
      }
    }
  };

  const CustomArrowIcon = () => {
    return isOpen ? (
      <i className="ri-arrow-up-s-line arrow-up" style={environmentSelectionColorPallets(selectedEnvironment, true)}></i>
    ) : (
      <i className="ri-arrow-down-s-line arrow-down" style={environmentSelectionColorPallets(selectedEnvironment, true)}></i>
    );
  };

  return (
    <div className={`sidebar-dropdown-container`} onClick={handleClick} style={environmentSelectionColorPallets(selectedEnvironment, false)}>
      <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
        <Select
          value={selectedEnvironment}
          onChange={handleChange}
          displayEmpty
          disableUnderline
          MenuProps={{
            classes: { paper: "sidebar-selection-menu" },
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" },
            getContentAnchorEl: null,
          }}
          IconComponent={CustomArrowIcon}
          ref={selectRef}
          open={isOpen}
          renderValue={(selected) => (
            <div className="sidebar-list-container">
              <ListItemIcon sx={{ minWidth: 0 }}>{environmentList.find((option) => option.key === selected)?.icon}</ListItemIcon>
              <div className="sidebar-list-value" style={environmentSelectionColorPallets(selected, true)}>
                {environmentList.find((option) => option.key === selected)?.key}
              </div>
            </div>
          )}
        >
          {environmentList.map((option) => (
            <MenuItem
              key={option.key}
              value={option.key}
              classes={{ root: "sidebar-selection-menu-item" }}
              style={{ width: !isProductionKeysAvailable ? "291px" : "196px" }}
            >
              <div className="sidebar-list-container">
                <ListItemIcon sx={{ minWidth: 0 }}>{option.icon}</ListItemIcon>
                <div className="sidebar-list-value">{option.key}</div>
                {currentTab === Developers
                  ? option.key &&
                    option.key === ENVIRONMENT.PRODUCTION &&
                    !isProductionKeysAvailable && <div className="request-access-tag">{!isComplianceFormFilled ? REQUEST_ACCESS : REQUESTED}</div>
                  : option.key &&
                    option.key === ENVIRONMENT.PRODUCTION &&
                    !showCreatorLinkage &&
                    !showCreatorSearch && <div className="request-access-tag">{!isComplianceFormFilled ? REQUEST_ACCESS : REQUESTED}</div>}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default EnvironmentSelectionDropdown;
