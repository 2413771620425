import React from "react";
import { Empty } from "antd";
import Typography from "../Typography/Typography";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import "./EmptyTableScreen.scss";

const EmptyTableScreen = ({ icon, header, content, isActionButton, actionButtonContent, actionButtonHandler, actionButtonClassName='', showBasicDetails=false }) => {
  const emptyContainerStyle = {
    display: showBasicDetails ? "flex" : "",
    textAlign: showBasicDetails ? "left !important" : "",
    width: showBasicDetails ? "fit-content" : "",
    gap: showBasicDetails ? "32px" : "",
    alignItems: showBasicDetails ? "center" : ""
  };

  const emptyHeaderStyle = {
    color: showBasicDetails ? "var(--Neutrals-Primary-Grey, #121B2E)" : "",
    fontWeight: showBasicDetails ? "700" : "",
    fontSize: showBasicDetails ? "22px" : "",
    textAlign: showBasicDetails ? "left" : "",
  }

  const emptyContentStyle = {
    color: showBasicDetails ? "var(--Neutrals-Secondary-grey, #5A5A67)" : "",
    fontWeight: showBasicDetails ? "400" : "",
    fontSize: showBasicDetails ? "16px" : "",
    textAlign: showBasicDetails ? "left" : "",
  }

  return (
    <Empty
      image={icon}
      imageStyle={{
        height: showBasicDetails ? 100:70,
        marginBottom:32,
      }}
      style={emptyContainerStyle}
      description={
        <div style={{width: showBasicDetails ? "600px" : ""}}>
          <Typography h4 className="empty-table-header section-heading" style={emptyHeaderStyle}>
            {header}
          </Typography>
            {content ? <div className="empty-table-content body-r" style={emptyContentStyle}>{content}</div> : null}
        </div>
      }
    >
      {isActionButton && <PrimaryButton className={actionButtonClassName} label={actionButtonContent} onClick={actionButtonHandler} />}
    </Empty>
  );
};

export default EmptyTableScreen;
