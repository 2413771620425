import React, { useContext, useState } from "react";
import "./LinkText.scss";
import { LOGOUT_CLICK, SCHEDULE_A_CALL_CLICK, trackEvent, USAGE_VIEW_CLICKED } from "../../analytics";
import Typography from "../Typography/Typography";
import Icons from "../Icons/Icons";
import LogOutPopUp from "../LogOutPopUp/LogOutPopUp";
import { UserContext } from "../../storage/context";
import {
    EMAIL_COPIED,
    MEETING_SCHEDULE_LINK,
    SCHEDULE_A_CALL,
    SIDEBAR_MENUS,
    TALK_TO_US,
    TALK_TO_US_DESC
} from "../../constants/constants";
import { loginApi } from "../../api";
import useProfile from "../../hooks/useSetUserProfile";
import { getAuth, signOut } from "firebase/auth";
import { GenericPopup, SupportEmail, ToastMessage } from "../index";
import { ADD_LIST_TOAST_MESSAGE_DURATION } from "../../constants/creatorDiscovery";
import { isEmpty } from "../../utils/util";

export default function LinkText({
                                     title,
                                     event,
                                     to,
                                     isLinkIconPresent,
                                     children,
                                     isPopUpRequired,
                                     isActive,
                                     setIsActive,
                                     usageInfo
                                 }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isToastOpen, setToastOpen] = useState(false);
    const { userProfile } = useContext(UserContext);
    const { logoutUserProfile } = useProfile();
    const { prodTenantAppId, amberfloUsageUrl, email } = usageInfo;

    const handleLogout = async () => {
        // setIsLoading(true);
        trackEvent(LOGOUT_CLICK);
        try {
            signOut(getAuth());
            await loginApi.signOutUser();
            logoutUserProfile();

        } catch (e) {
            // do nothing
            // setIsLoading(true);

            console.log(e, "logout error");
        }
    };

    function onLinkTextClick() {
        if (title === SIDEBAR_MENUS.USAGE && !isEmpty(amberfloUsageUrl)) {
            window.open(amberfloUsageUrl, '_blank', 'noopener noreferrer');
            trackEvent(USAGE_VIEW_CLICKED, {
                tenant_app_id: prodTenantAppId,
                email,
            });
            return;
        }
        if (isPopUpRequired) {
            setIsModalOpen(true);
        } else {
            trackEvent(event, {
                url: to,
                page_name: window.location.pathname.substring(1).toUpperCase(),
                source: "TOP_NAV"
            });
            window.open(to, "_blank", "noopener");
        }
    }

    return (
        <div
            className="link-header"
            onClick={onLinkTextClick}
        >
            {children}
            <Typography h4 style={{ marginBottom: "0px", fontSize: "16px" }}>
                {title}
            </Typography>
            <div className="link-icon">{isLinkIconPresent && <Icons.link_to_icon style={{ marginLeft: "-6px" }}/>}</div>
            {title === SIDEBAR_MENUS.TALK_TO_US && (
                <GenericPopup
                    open={isModalOpen}
                    icon={<span
                        className={'schedule-call-access-pop-up-icon'}><Icons.schedule_call_pop_up_icon/></span>}
                    padding={"48px 48px 48px 48px"}
                    height={"308px"}
                    onClose={(e) => {
                        e.stopPropagation();
                        setIsModalOpen(false);
                        setToastOpen(false);
                    }}
                    header={TALK_TO_US}
                    description={TALK_TO_US_DESC}
                    isModal
                    actionButtonLabel={<span className={'span-action-button-label'}><i className="ri-calendar-line"
                                                                                       style={{ fontSize: "20px" }}></i>{SCHEDULE_A_CALL}</span>}
                    handleButtonClick={() => {
                        trackEvent(SCHEDULE_A_CALL_CLICK, { link: MEETING_SCHEDULE_LINK, source: "SIDEBAR" });
                        window.open(MEETING_SCHEDULE_LINK, "_blank");
                    }}
                    actionButtonSubtext={<div style={{ marginTop: 24 }}><SupportEmail/></div>}
                />
            )}
            <LogOutPopUp
                open={isActive}
                onClose={(e) => {
                    setIsActive(false);
                }}
                header={userProfile.user.firstName + " " + (userProfile.user.lastName ? userProfile.user.lastName : "")}
                description={userProfile.user.email}
                buttonAction={handleLogout}
            />
            <ToastMessage
                openToast={isToastOpen}
                message={EMAIL_COPIED}
                duration={ADD_LIST_TOAST_MESSAGE_DURATION}
                onCloseToast={() => {
                    setToastOpen(false);
                }}
                type={'success'}
            />
        </div>
    );
}
