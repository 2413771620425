import { Box } from "@material-ui/core";
import { clearAll } from "../../constants/campaignTrackingConstants";
import { Tooltip } from "@mui/material";
import Chip from "@material-ui/core/Chip";
import { Icons } from "../index";
import React from "react";
import './ChipsContainer.scss';

export default function ChipsContainer({
    onClearAllClick,
    totalCountMessage,
    totalCountClass = '',
    items,
    getItemDetails,
    onItemDelete,
    isSingleColumn = true,
    containerClass,
}) {
    return (
        <Box className={`postURLS-chip-container ${containerClass}`}>
            <Box className='posturl-clearAll-container'>
                <Box className={`subtitle-m ${totalCountClass}`}>{totalCountMessage}</Box>
                <Box className='clear-all-but body-m' onClick={onClearAllClick}>{clearAll}</Box>
            </Box>
            {items.map((item, key) => {
                const { tooltipTitle, rootClass = '', label } = getItemDetails(item);
                return (
                    <span key={key}>
                        <Tooltip placement='right-end' title={tooltipTitle}>
                            <Chip
                                classes={{
                                    root: `url-chip-container ${rootClass}`,
                                    label: `url-chip-label body-r`,
                                    deleteIcon: "url-chip-delete-icon",
                                }}
                                key={label}
                                label={label}
                                onDelete={(k) => {
                                    onItemDelete(item)
                                }}
                                deleteIcon={<Icons.close_icon_small_16 />}
                            />
                        </Tooltip>
                        {isSingleColumn ? <br /> : null}
                    </span>);
            })}
        </Box>
    );
}