import "./DonutChartLegend.scss";
import "../../styles/insightIq.scss";

export default function DonutChartLegend({ color, title, subTitle, totalCount }) {
    return (
        <div className={"div-donut-chart-legend"}>
            <div className={"row-1"}>
                <svg width={16} height={16}>
                    <circle cx={8} cy={8} r={8} fill={color}/>
                </svg>
                <div className={"sub-section-heading legend-title"}>{title}</div>
                <div className={"body-r legend-subtitle"}>{`${subTitle} (${totalCount})`}</div>
            </div>
        </div>
    );
}
