import "./InvitedUsersPage.scss";
import { Input, Modal } from "antd";
import {
  ButtonWithTooltip,
  Card,
  CreatorInviteForm,
  EmptyTableScreen,
  Icons,
  IntermediateLoader,
  Loader,
  PrimaryButton,
  TableListingRecords,
  TrialAccessPopUp,
} from "../../components/index";
import moment from "moment-timezone";
import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Box, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getTabSelectedAPI, getTabSelectedTableColumns } from "../../utils/util";
import {
  CONNECTED,
  creatorLinkageInfoText,
  creatorLinkageProductID,
  DATE_FORMAT,
  FAILEDREASONMAPPING,
  failedReasons,
  failedReasonsTooltipContentMapping,
  getHeaderDescription,
  getPageTitle,
  MEETING_SCHEDULE_LINK,
  RECORDS_PER_PAGE,
  Sandbox,
  Staging,
  TabValues,
  TRIAL,
  WORKPLATFORM_ID_MAPPING,
} from "../../constants/constants";
import PaginateRecords from "../../components/PaginateRecords/PaginateRecords";
import { useToast } from "../../hooks/useToast";
import { resendInvitation } from "../../api/creatorinvitations/creatorinvitations";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import {
  COPY_INVITE_LINK,
  LINK_ALL_PAGE_VIEW,
  LINK_CONNECTED_ACCOUNT_PAGE_VIEW,
  LINK_CREATE_INITIATED,
  LINK_FAILED_ACCOUNT_PAGE_VIEW,
  LINK_LANDING_PAGE_VIEW,
  LINK_SEARCH_INITIATED,
  RESEND_INVITE_LINK,
  trackEvent,
} from "../../analytics";
import { UserContext, WarehouseContext } from "../../storage/context";
import { analyticsReducer } from "../../storage/reducers";
import { ADD_USAGE_QUOTA, HIDE_TOP_HEADER, SHOW_TOP_HEADER, UPDATE_ANALYTICS } from "../../storage/reducers/types";
import getModal from "../../constants/modal";
import { getTopHeaderNavTextAsPerUsage } from "../../utils/tenantProductHelpers";
import { Colors } from "../../styles/colors";
import { tenantUsageQuotaInfoAPI } from "../../api";
import { useFetchTenantInformation } from "../../hooks/useFetchTenantInformation";
import { useFetchTenantUsageInfo } from "../../hooks/useFetchTenantUsageInfo";

export default function InvitedUsersPage() {
  const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
  const [invitationsData, setInvitationsData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currTab, setCurrTab] = useState(TabValues.all);

  const [appliedPlatforms, setAppliedPlatforms] = useState(null);
  const [appliedPlatformStatuses, setAppliedPlatformStatuses] = useState(null);
  const [isPlatformsFilterVisible, setPlatformsFilterVisible] = useState(false);

  const [dateRange, setDateRange] = useState({ fromDate: null, toDate: null });
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);

  const [columns, setColumns] = useState(
    getTabSelectedTableColumns(
      TabValues.all,
      null,
      null,
      {
        appliedPlatforms,
        setAppliedPlatforms,
        appliedPlatformStatuses,
        setAppliedPlatformStatuses,
        isPlatformsFilterVisible,
        setPlatformsFilterVisible,
      },
      {
        dateRange,
        setDateRange,
        isDatePickerVisible,
        setDatePickerVisible,
      }
    )
  );
  const [fetchedInvitationsData, setFetchedInvitationsData] = useState({});
  const [paginationData, setPaginationData] = useState({
    currPage: 1,
    totalPages: 1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [perPageRecord, setPerPageRecord] = useState(0);
  const [isTableDataLoaded, setIsTableDataLoaded] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchedResponse, setSearchedResponse] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [isCreatorOnboarded, setIsCreatorOnboarded] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const inputRef = useRef(null);
  const toast = useToast();
  const [showInviteButton, setShowInviteButton] = useState(true);
  const fetchTenantUsageInformation = useFetchTenantUsageInfo();
  const { Search } = Input;

  const checkConnectedAccountUsageLimit = () => {
    let connectedAccounts;
    let maxConnectedAccount;
    if (warehouse.environment.current === Staging) {
      connectedAccounts = warehouse.tenantInfo[0]?.apps[0]?.total_connected_accounts;
      maxConnectedAccount = warehouse.tenantInfo[0]?.apps[0]?.max_connected_accounts;
    }
    return connectedAccounts >= maxConnectedAccount;
  };

  const linkageStagingInfoModal = {
    title: "You are in staging mode",
    content: ` <p>✦  Connect <b>25 live creator accounts.</b></p>
                  <p>✦  Staging mode allows you to <b>try insightIQ’s products.</b></p>
                  <p>✦  <a
                  href=${MEETING_SCHEDULE_LINK}
                  target="_blank"
                  rel="noreferrer"
                > <u>Schedule a meeting</u></a> with us to <b>get production access.</b></p>`,
    buttonLabel: "Got it!",
    closable: false,
  };

  let modalObj = getModal(linkageStagingInfoModal);

  const navigate = useNavigate();
  const onlyOnce = useRef(true);
  const [sortDesc, setSortDesc] = useState(true);
  const isTableDataFetched = useRef(false);
  const [analytics, dispatchAnalytics] = useReducer(analyticsReducer, JSON.parse(localStorage.getItem("insightiq_analytics")));

  const { userProfile } = useContext(UserContext);

  useEffect(() => {
    if (analytics?.tenant_id && analytics?.tenant_name) {
      trackEvent(LINK_LANDING_PAGE_VIEW);
      trackEvent(LINK_ALL_PAGE_VIEW); // default tab
    }
  }, [analytics]);

  useEffect(() => {
    // Condition to check the Trial status of Creator Linkage and Add the TopHeader | Also fetch the usge info
    (async () => {
      if (warehouse.tenantAppProducts[creatorLinkageProductID] === TRIAL) {
        // This is to add a Loader state of Header
        dispatchWarehouse({
          type: SHOW_TOP_HEADER,
          payload: {
            title: <Loader />,
            backgroundColor: Colors.semanticsSecondaryAlert,
          },
        });
        const usageInfo = await fetchTenantUsageInformation();
        if (usageInfo["ACCOUNTS_CONNECTED"].usage >= usageInfo["ACCOUNTS_CONNECTED"].max_limit) setShowInviteButton(false);
        dispatchWarehouse({ type: ADD_USAGE_QUOTA, payload: usageInfo });
        dispatchWarehouse({
          type: SHOW_TOP_HEADER,
          payload: {
            title: getTopHeaderNavTextAsPerUsage(usageInfo, creatorLinkageProductID),
            backgroundColor: Colors.semanticsSecondaryAlert,
          },
        });
      } else dispatchWarehouse({ type: HIDE_TOP_HEADER });
    })();
  }, []);
  useEffect(() => {
    if (!isTableDataFetched.current || warehouse.environmentChanged) {
      if (warehouse.environment.current !== Sandbox) {
        setIsTableDataLoaded(false);
        getSelectedTableData({ currTab });
      }
      isTableDataFetched.current = true;
    }

    dispatchAnalytics({
      type: UPDATE_ANALYTICS,
      payload: {
        tenant_id: warehouse?.tenantInfo[0]?.id,
        tenant_name: warehouse?.tenantInfo[0]?.apps[0]?.name,
        tenant_email: userProfile?.user?.email,
      },
    });
  }, [warehouse.environmentChanged]);

  useEffect(() => {
    getSelectedTableData({ currPage: 1, currTab, searchInput });
  }, [sortDesc, warehouse.tenantInfo]);

  useEffect(() => {
    setIsPaginationLoading(true);
    getSelectedTableData({ currTab, searchInput })
      .then(() => {})
      .finally(() => setIsPaginationLoading(false));
  }, [appliedPlatforms, appliedPlatformStatuses, isPlatformsFilterVisible, dateRange, isDatePickerVisible]);

  const getSelectedTableData = async (tableConfigParams = {}) => {
    const { currPage = 1, currTab = TabValues.all, searchInput = "" } = tableConfigParams;
    const tableColumns = getTabSelectedTableColumns(
      currTab,
      sortDesc,
      setSortDesc,
      {
        appliedPlatforms,
        setAppliedPlatforms,
        appliedPlatformStatuses,
        setAppliedPlatformStatuses,
        isPlatformsFilterVisible,
        setPlatformsFilterVisible,
      },
      {
        dateRange,
        setDateRange,
        isDatePickerVisible,
        setDatePickerVisible,
      }
    );
    const invitationsData = await getTabSelectedAPI(
      currTab,
      RECORDS_PER_PAGE * (currPage - 1),
      searchInput,
      sortDesc,
      warehouse,
      appliedPlatforms,
      appliedPlatformStatuses,
      dateRange
    );
    if (invitationsData?.results?.length > 0) {
      isCreatorOnboarded === null && setIsCreatorOnboarded(true);
    }
    setPageLoading(false);
    setIsTableDataLoaded(true);
    setColumns(tableColumns);
    setTotalRecords(invitationsData.total);
    setPerPageRecord(invitationsData.results.length);
    const obj = {
      ...paginationData,
      currPage: 1,
      totalPages: Math.ceil(invitationsData.total / RECORDS_PER_PAGE),
    };
    setPaginationData(obj);
    constructTableDataFromInvitationsResponse(invitationsData.results, currTab);
    return invitationsData;
  };

  const handlePageChange = async (event, value) => {
    setIsPaginationLoading(true);
    let obj = {
      ...paginationData,
      currPage: value,
    };
    setPaginationData(obj);
    let invitationsData = await getSelectedTableData({ currPage: value, currTab, searchInput });
    obj = {
      ...paginationData,
      currPage: value,
      totalPages: Math.ceil(invitationsData.total / RECORDS_PER_PAGE),
    };
    setPaginationData(obj);
    setIsPaginationLoading(false);
  };

  const handleTabChange = async (event, newValue) => {
    setSearchInput("");
    setIsTableDataLoaded(false);
    setIsPaginationLoading(true);
    setIsCreatorOnboarded(true);
    setCurrTab(newValue);
    setAppliedPlatforms(null);
    setAppliedPlatformStatuses(null);
    setDateRange({ fromDate: null, toDate: null });
    let invitationsData = await getSelectedTableData({ currPage: 1, currTab: newValue, searchInput: "" });
    setIsTableDataLoaded(true);
    const obj = {
      ...paginationData,
      currPage: 1,
      totalPages: Math.ceil(invitationsData.total / RECORDS_PER_PAGE),
    };
    setPaginationData(obj);
    setIsPaginationLoading(false);
    if (TabValues.all === newValue) {
      trackEvent(LINK_ALL_PAGE_VIEW);
    } else if (TabValues.connectedAccounts === newValue) {
      trackEvent(LINK_CONNECTED_ACCOUNT_PAGE_VIEW);
    }
  };

  const handleSearch = async (searchValue) => {
    setIsTableDataLoaded(false);
    let invitationsData = await getSelectedTableData({
      currPage: 1,
      currTab,
      searchInput: searchValue.trim(),
    });
    setIsTableDataLoaded(true);
    const obj = {
      ...paginationData,
      currPage: 1,
      totalPages: Math.ceil(invitationsData.total / RECORDS_PER_PAGE),
    };
    setPaginationData(obj);
    if (searchValue !== "") {
      let path;
      if (TabValues.all === currTab) path = "all";
      else if (TabValues.connectedAccounts === currTab) path = "connected_accounts";
      trackEvent(LINK_SEARCH_INITIATED, {
        path,
        search_input: searchValue.trim(),
      });
    }
  };

  const handleInputChange = (value) => {
    if (value === "") {
      getSelectedTableData({ currPage: 1, currTab, searchInput: value });
    }
    setSearchInput(value);
  };

  const truncateUsername = (username) => {
    return username.length > 27 ? username.substring(0, 27) + "..." : username;
  };

  const constructTableDataFromInvitationsResponse = (invitations, currTab = TabValues.all) => {
    const tableData = [];
    if (currTab === TabValues.all) {
      invitations.forEach((invitation, idx) => {
        tableData.push({
          key: idx,
          name: invitation.name || "no_name",
          email: invitation.email,
          status: [invitation.status],
          accounts: invitation.accounts,
          invited_on: invitation.created_at,
          buttons: (
            <div className="webhook-table-button">
              <button
                className="edit-icon icon-button"
                onClick={(e) => {
                  e.stopPropagation();
                  toast("Invitation link copied");
                  navigator.clipboard.writeText(invitation.invite_link);
                  trackEvent(COPY_INVITE_LINK, {
                    invite_id: invitation?.id,
                    path: "all",
                  });
                }}
              >
                <Tooltip title="Copy invite link">
                  <Icons.link_icon name="copy_icon " />
                </Tooltip>
              </button>
              <button
                className="edit-icon icon-button"
                onClick={(e) => {
                  e.stopPropagation();
                  Modal.warn({
                    title: "Resend invitation?",
                    className: "resend-modal-container",
                    content: `Are you sure you want to resend the invite to ${invitation.name} (${invitation.email})?`,
                    onOk: async () => {
                      const apiResult = await resendInvitation(invitation.id, warehouse.environment.current);
                      if (apiResult.status === 200) {
                        toast("Invitation resent successfully");
                      }
                      trackEvent(RESEND_INVITE_LINK, {
                        invite_id: invitation?.id,
                        path: "all",
                      });
                    },
                    onCancel: () => {},
                    closable: true,
                    okText: "Resend invite",
                    closeIcon: <Icons.close_circle_fill />,
                    icon: false,
                  });
                }}
              >
                <Tooltip title="Resend invitation">
                  <Icons.share_icon name="delete_icon" />
                </Tooltip>
              </button>
            </div>
          ),
          link: invitation.invite_link,
          creator_invite_id: invitation.id,
          core_schema_user_id: invitation.user_id,
        });
      });
    } else if (currTab === TabValues.connectedAccounts) {
      invitations.forEach((invitation, idx) => {
        tableData.push({
          key: idx,
          name: invitation.name || "no_name",
          email: invitation.email,
          username: truncateUsername(invitation.username),
          accounts: [
            {
              id: invitation.account_id,
              work_platform_id: invitation.work_platform_id,
              status: invitation.status,
            },
          ],
          platform: (
            <div className="platform-image-container">
              <img width="13%" style={{ marginRight: "3px" }} src={WORKPLATFORM_ID_MAPPING[invitation.work_platform_id].platformLogo} />
              <Tooltip title={failedReasonsTooltipContentMapping[invitation.status]}>
                <div className={failedReasons.includes(invitation.status) ? "inactive" : "active"}>
                  {WORKPLATFORM_ID_MAPPING[invitation.work_platform_id].platformName}
                  {failedReasons.includes(invitation.status) && <Icons.info_icon className="connected-account-info-icon" />}
                </div>
              </Tooltip>
            </div>
          ),
          connected_on: invitation.connected_at,
          buttons:
            invitation.status === CONNECTED ? null : (
              <div className="webhook-table-button">
                <button
                  className="edit-icon icon-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    toast("Invitation link copied");
                    navigator.clipboard.writeText(invitation.invite_link);
                    trackEvent(COPY_INVITE_LINK, {
                      invite_id: invitation?.link_id,
                      path: "connected_accounts",
                    });
                  }}
                >
                  <Tooltip title="Copy invite link">
                    <Icons.link_icon name="copy_icon " />
                  </Tooltip>
                </button>
                <button
                  className="edit-icon icon-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    Modal.warn({
                      title: "Resend invitation?",
                      className: "resend-modal-container",
                      content: `Are you sure you want to resend the invite to ${invitation.name} (${invitation.email})?`,
                      onOk: async () => {
                        const apiResult = await resendInvitation(invitation.link_id, warehouse.environment.current);
                        if (apiResult.status === 200) {
                          toast("Invitation resent successfully");
                        }
                        trackEvent(RESEND_INVITE_LINK, {
                          invite_id: invitation?.link_id,
                          path: "connected_accounts",
                        });
                      },
                      onCancel: () => {},
                      closable: true,
                      okText: "Resend invite",
                      closeIcon: <Icons.close_circle_fill />,
                      icon: false,
                    });
                  }}
                >
                  <Tooltip title="Resend invitation">
                    <Icons.share_icon name="delete_icon" />
                  </Tooltip>
                </button>
              </div>
            ),
          link: invitation.invite_link,
          creator_invite_id: invitation.link_id,
          core_schema_user_id: invitation.user_id,
          invited_on: moment(invitation.invited_at).format(DATE_FORMAT),
          account_id: invitation.account_id,
        });
      });
    } else {
      invitations.forEach((invitation, idx) => {
        tableData.push({
          key: idx,
          name: invitation.name || "no_name",
          email: invitation.email,
          platform: (
            <div className="platform-image-container">
              <img width="16%" style={{ marginRight: "3px" }} src={WORKPLATFORM_ID_MAPPING[invitation.work_platform_id].platformLogo} />
              <div>{WORKPLATFORM_ID_MAPPING[invitation.work_platform_id].platformName}</div>
            </div>
          ),
          reason: (
            <Tooltip title={parse(FAILEDREASONMAPPING[invitation.status].toolTipText)}>{FAILEDREASONMAPPING[invitation.status].reason}</Tooltip>
          ),
          attempted_at: moment(invitation.created_at).format(DATE_FORMAT) + " at " + moment(invitation.created_at).format("hh:mm A"),
          account_id: invitation.account_id,
        });
      });
    }
    setInvitationsData(tableData);
    // setInvitationsData([]);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ padding: "0px", height: "100%" }}>
            <Typography sx={{ padding: "0px", height: "100%" }}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  let allTableEmptyScreen = {
    emptyText: (
      <EmptyTableScreen
        icon={<Icons.invitation_empty_icon />}
        header="Your list is empty!"
        content="Invite your creators to connect their social accounts. This will enable you to view their profile, audience, and content details."
        isActionButton={true}
        actionButtonContent="Invite Creators"
        actionButtonHandler={() => {
          setIsModalVisible(true);
        }}
      />
    ),
  };

  let connectedAccountsTableEmptyScreen = {
    emptyText: (
      <EmptyTableScreen
        icon={<Icons.connected_accounts_empty_icon />}
        header="No connections yet!"
        content="Accounts appear here once they are connected. Invite your creators to connect their social accounts."
        isActionButton={false}
      />
    ),
  };

  let emptyInputScreen = {
    emptyText: <EmptyTableScreen icon={<Icons.empty_search_icon />} header={`No results found for ‘${searchInput.trim()}’`} />,
  };

  let selectedTabEmptyScreen = {
    All: allTableEmptyScreen,
    "Connected accounts": connectedAccountsTableEmptyScreen,
  };

  const handleNavigation = (record, rowIndex) => {
    const obj = {
      name: record?.name,
      email: record?.email,
      invitedOn: record?.invited_on,
      link: record?.link,
      creatorInviteId: record?.creator_invite_id,
      selectedPlatform: record?.account_id,
    };
    if (currTab === TabValues.all && record?.status?.[0] !== "INVITED") {
      sessionStorage.setItem("creatorProfile", JSON.stringify(obj));
      navigate(`../profile/${record?.core_schema_user_id}`);
    } else if (currTab === TabValues.connectedAccounts) {
      sessionStorage.setItem("creatorProfile", JSON.stringify(obj));
      navigate(`../profile/${record?.core_schema_user_id}`);
    }
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  function getLocale() {
    if (isPaginationLoading) {
      return { emptyText: null };
    }
    return searchInput.length > 0 && searchedResponse.length === 0 ? emptyInputScreen : selectedTabEmptyScreen[currTab];
  }

  return (
    <div className="invitation-page-container">
      <Helmet>
        <title>{getPageTitle("CREATOR_INVITATION_PAGE")}</title>
      </Helmet>
      <div className="invited-users-header">
        <div>
          <div className="invited-users-header-text">Onboard creators</div>
          <div className="empty-webhook-description">
            {isTableDataLoaded && getHeaderDescription(isCreatorOnboarded, invitationsData)}
            {isCreatorOnboarded !== null && (
              <Tooltip title={creatorLinkageInfoText()} placement="bottom-end" classes={{ tooltip: "MuiTooltip-tooltip linkage-info-tooltip" }}>
                <div>
                  <u>Learn more</u>
                  <i class="ri-information-line tooltip-icon"></i>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        {(invitationsData?.length > 0 || isCreatorOnboarded) && (
          <ButtonWithTooltip
            tooltipText={
              !showInviteButton &&
              `You have reached the ${warehouse.tenantUsageQuota.quotaInfoCreatorModule["ACCOUNTS_CONNECTED"].max_limit} account connection limit.`
            }
            label="Invite creators"
            disabled={!showInviteButton}
            onClick={() => {
              if (showInviteButton) {
                setIsModalVisible(true);
                trackEvent(LINK_CREATE_INITIATED);
              }
            }}
            className="invite-creators-button"
          />
        )}
      </div>
      {invitationsData?.length > 0 && !pageLoading ? (
        <>
          <div className="table-navigation-container">
            <div className="table-navigation-tabs-container">
              <Tabs className="tabsGroup" value={currTab} onChange={handleTabChange} aria-label="icon position tabs example">
                <Tab
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  label={TabValues.all}
                  value={TabValues.all}
                />
                <Tab
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  label={TabValues.connectedAccounts}
                  value={TabValues.connectedAccounts}
                />
              </Tabs>
            </div>
            <div className="search-input-container">
              {searchInput.length === 0 ? (
                <Input
                  placeholder="Search using name or email"
                  className="search-input"
                  value={searchInput}
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                  }}
                  ref={inputRef}
                  autoFocus={isTyping}
                />
              ) : (
                <Search
                  placeholder="Search using name or email"
                  onSearch={handleSearch}
                  enterButton
                  allowClear
                  value={searchInput}
                  onChange={(e) => {
                    setIsTyping(true);
                    handleInputChange(e.target.value);
                  }}
                  autoFocus
                />
              )}
            </div>
          </div>
          {isTableDataLoaded ? (
            <div className="table-container" style={{ height: "calc(100% - 120px)" }}>
              <TabPanel index={currTab} value={currTab} className="tab-body-container" style={{ height: "100%" }}>
                <TableListingRecords
                  loading={isPaginationLoading}
                  scroll={true}
                  columns={columns}
                  size="small"
                  dataSource={invitationsData}
                  border={false}
                  locale={getLocale()}
                  rowClickHandler={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        handleNavigation(record, rowIndex);
                      },
                    };
                  }}
                />
                {invitationsData.length > 0 && (
                  <div className="main-pagination-container">
                    <PaginateRecords
                      size={"small"}
                      totalPageCount={paginationData.totalPages}
                      color="#8A8894"
                      page={paginationData.currPage}
                      onPageClick={handlePageChange}
                      disabled={isPaginationLoading}
                    />
                    <div className="results-container">
                      Showing <span>{RECORDS_PER_PAGE * (paginationData?.currPage - 1) + 1}</span> -{" "}
                      <span> {RECORDS_PER_PAGE * (paginationData?.currPage - 1) + perPageRecord} </span> of <span>{totalRecords}</span> results
                    </div>
                  </div>
                )}
              </TabPanel>
            </div>
          ) : (
            <IntermediateLoader />
          )}
        </>
      ) : pageLoading ? (
        <IntermediateLoader />
      ) : isCreatorOnboarded === null ? (
        <div>
          <Card className="info-card-container">
            <div className="info-card-content-container">
              <div className="info-card-header">
                <div>
                  <i class="ri-information-line info-icon"></i>
                </div>
                <div className="info-card-header-text">Please note</div>
              </div>
              <div className="info-card-description">
                <ul>
                  <li>This dashboard will show creator accounts that you onboard by sending email invitations from here.</li>
                  <li>
                    It will <b className="bold-font">NOT</b> show accounts connected via <b className="bold-font">Connect APIs.</b>
                  </li>
                </ul>
              </div>
            </div>
          </Card>
          <PrimaryButton
            label="Onboard creators"
            className="onboard-creators-button"
            onClick={() => {
              isCreatorOnboarded === null && setIsCreatorOnboarded(true);
            }}
          />
        </div>
      ) : (
        <>
          <div className="table-navigation-container">
            <div className="table-navigation-tabs-container">
              <Tabs className="tabsGroup" value={currTab} onChange={handleTabChange} aria-label="icon position tabs example">
                <Tab
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  label={TabValues.all}
                  value={TabValues.all}
                />
                <Tab
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  label={TabValues.connectedAccounts}
                  value={TabValues.connectedAccounts}
                />
              </Tabs>
            </div>
            <div className="search-input-container">
              {searchInput.length === 0 ? (
                <Input
                  placeholder="Search using name or email"
                  className="search-input"
                  value={searchInput}
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                  }}
                  ref={inputRef}
                  autoFocus={isTyping}
                />
              ) : (
                <Search
                  placeholder="Search using name or email"
                  onSearch={handleSearch}
                  enterButton
                  allowClear
                  value={searchInput}
                  onChange={(e) => {
                    setIsTyping(true);
                    handleInputChange(e.target.value);
                  }}
                  autoFocus
                />
              )}
            </div>
          </div>
          {isTableDataLoaded ? (
            <div className="table-container" style={{ height: "calc(100% - 120px)" }}>
              <TabPanel index={currTab} value={currTab} className="tab-body-container" style={{ height: "100%" }}>
                <TableListingRecords
                  loading={isPaginationLoading}
                  scroll={true}
                  columns={columns}
                  size="small"
                  dataSource={invitationsData}
                  border={false}
                  locale={getLocale()}
                  rowClickHandler={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        handleNavigation(record, rowIndex);
                      },
                    };
                  }}
                />
                {invitationsData.length > 0 && (
                  <div className="main-pagination-container">
                    <PaginateRecords
                      size={"small"}
                      totalPageCount={paginationData.totalPages}
                      color="#8A8894"
                      page={paginationData.currPage}
                      onPageClick={handlePageChange}
                      disabled={isPaginationLoading}
                    />
                    <div className="results-container">
                      Showing <span>{RECORDS_PER_PAGE * (paginationData?.currPage - 1) + 1}</span> -{" "}
                      <span> {RECORDS_PER_PAGE * (paginationData?.currPage - 1) + perPageRecord} </span> of <span>{totalRecords}</span> results
                    </div>
                  </div>
                )}
              </TabPanel>
            </div>
          ) : (
            <IntermediateLoader />
          )}
        </>
      )}
      <CreatorInviteForm
        open={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
          //For refreshing the table data after closing the modal and adding the latest records to the table
          if (RECORDS_PER_PAGE * (paginationData.currPage - 1) < RECORDS_PER_PAGE) handleTabChange(null, TabValues.all);
        }}
      />
    </div>
  );
}
