import { Avatar, Box, CircularProgress, Grid } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { EXPORT_PROFILE_CLICKED, trackEvent } from "../../analytics";
import { getProfileInfo } from "../../api/profileView/profileView";

import { POLL_DURARION, POLL_INTERVAL } from "../../constants/profileViewConstants";
import { convertDate, formatNumber, timeConvert } from "../../utils/profileHelpers";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import "./AccountIdentityData.scss";
import { getUserName } from "../../utils/profileHelpers";
import { profileExportToCSV } from "../../api/creatorinvitations/creatorinvitations";
import { WarehouseContext } from "../../storage/context";
import { export_profile, last_updated } from "../../constants/constants";

const IDENTITY_ATTRIBUTES = {
  profile_pic: "profile_pic",
  platform_profile_name: "platform_profile_name",
  subscriber_count: "subscriber_count",
  name: "name",
  email_id: "email_id",
  username: "username",
  playlists: "playlists",
  videos: "videos",
  total_watch_time: "total_watch_time",
  follower_count: "follower_count",
  posts: "posts",
  tweets: "tweets",
};

const IDENTITY_WORKPLATFORMS_MAPPING = {
  YouTube: ["profile_pic", "platform_profile_name", "subscriber_count", "name", "email_id", "username", "playlists", "videos", "total_watch_time"],
  Instagram: ["profile_pic", "platform_profile_name", "follower_count", "name", "email_id", "username", "posts"],
  "Instagram Lite": ["profile_pic", "platform_profile_name", "follower_count", "name", "email_id", "username", "posts"],
  TikTok: ["profile_pic", "platform_profile_name", "follower_count", "email_id", "username", "posts"],
  Twitter: ["profile_pic", "platform_profile_name", "follower_count", "name", "username", "tweets"],
  Facebook: ["profile_pic", "platform_profile_name", "follower_count", "name", "email_id", "username"],
  others: ["profile_pic", "platform_profile_name", "subscriber_count", "name", "email_id", "username"],
};

function AccountIdentityData({ identityData, lastSyncAt, creatorInviteId }) {
  const [currWorkPlatformMetrics, setCurrWorkPlatformMetrics] = useState(null);
  const isCompMounted = useRef();
  const pollId = useRef();
  const pollStartTime = useRef();
  const { warehouse } = useContext(WarehouseContext);
  const [exportButtonLoading, setExportButtonLoading] = useState(false);

  const onExportHandler = async () => {
    setExportButtonLoading(true);
    try {
      const response = await profileExportToCSV(warehouse?.environment?.current, creatorInviteId, identityData?.account?.id);
      // extract csv data and filename
      const csvData = response?.data;
      const filename = response?.headers["content-disposition"]?.split("=")[1];
      downloadCSV(csvData, filename);
      trackEvent(EXPORT_PROFILE_CLICKED, {
        work_platform_id: identityData?.work_platform?.id,
        work_platform_name: identityData?.work_platform?.name,
        connected_account_id: identityData?.account?.id,
      });
    } catch (error) {
      setExportButtonLoading(false);
      console.log(error);
    }
  };

  function downloadCSV(data, filename) {
    const csvData = new Blob([data], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);

    const downloadLink = document.createElement("a");
    downloadLink.href = csvURL;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(csvURL);
    setExportButtonLoading(false);
  }

  useEffect(() => {
    if (identityData) {
      const work_platform_name = identityData.work_platform.name;
      if (IDENTITY_WORKPLATFORMS_MAPPING.hasOwnProperty(work_platform_name)) {
        setCurrWorkPlatformMetrics(IDENTITY_WORKPLATFORMS_MAPPING[work_platform_name]);
      } else {
        setCurrWorkPlatformMetrics(IDENTITY_WORKPLATFORMS_MAPPING["others"]);
      }
    }
  }, [identityData]);

  return (
    <>
      {identityData && currWorkPlatformMetrics && (
        <Box className="identity-container">
          <Box
            sx={{
              height: "80px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "32px",
            }}
          >
            <Box>
              <Avatar alt="Profile Pic" src={identityData.image_url} className="profilePic" />
            </Box>
            <Box className="username-subs-logo-container">
              <Box className="username-subs-container">
                <Box className="userName">{identityData.platform_profile_name !== null ? identityData.platform_profile_name : "-"}</Box>
                {currWorkPlatformMetrics.includes(IDENTITY_ATTRIBUTES.subscriber_count) && (
                  <Box className="userSubcribers">
                    {identityData.reputation.subscriber_count !== null ? formatNumber(identityData.reputation.subscriber_count) : "-"}
                    <p>Subscribers</p>
                  </Box>
                )}
                {currWorkPlatformMetrics.includes(IDENTITY_ATTRIBUTES.follower_count) && (
                  <Box className="userSubcribers">
                    {identityData.reputation.follower_count !== null ? formatNumber(identityData.reputation.follower_count) : "-"}
                    <p>Followers</p>
                  </Box>
                )}
              </Box>
              <Box className="platformlogo">
                <img width="40px" height="40px" src={identityData?.work_platform?.logo_url} alt={`${identityData?.work_platform?.name} logo`} />
              </Box>
            </Box>
            <Box className="right-header">
              <label>
                {last_updated} {convertDate(lastSyncAt)}
              </label>
              <PrimaryButton label={export_profile} onClick={onExportHandler} loading={exportButtonLoading} />
            </Box>
          </Box>
          <Grid
            rowSpacing={{ xs: 4 }}
            columnSpacing={{ xs: 15 }}
            container
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 2, sm: 8, md: 14 }}
            className="dataGrid"
          >
            <Grid className="gridKey" item xs={2} sm={4} md={4}>
              <Box className="userInfoKey">Name</Box>
              <Box>{identityData.full_name !== null ? identityData.full_name : "-"}</Box>
            </Grid>
            <Grid className="gridKey" item xs={2} sm={4} md={4}>
              <Box className="userInfoKey">Username</Box>
              <Box className="text-truncate">
                {identityData.platform_username !== null ? (
                  <a href={identityData.url} target="_blank" style={{ color: "#2262ED" }}>
                    {getUserName(identityData)}
                  </a>
                ) : (
                  "-"
                )}
              </Box>
            </Grid>
            {currWorkPlatformMetrics.includes(IDENTITY_ATTRIBUTES.videos) && (
              <Grid className="gridKey" item xs={2} sm={4} md={4}>
                <Box className="userInfoKey">Videos</Box>
                <Box>{identityData.reputation.content_count !== null ? formatNumber(identityData.reputation.content_count) : "-"}</Box>
              </Grid>
            )}
            {currWorkPlatformMetrics.includes(IDENTITY_ATTRIBUTES.posts) && (
              <Grid className="gridKey" item xs={2} sm={4} md={4}>
                <Box className="userInfoKey">Posts</Box>
                <Box>{identityData.reputation.content_count !== null ? formatNumber(identityData.reputation.content_count) : "-"}</Box>
              </Grid>
            )}
            {currWorkPlatformMetrics.includes(IDENTITY_ATTRIBUTES.tweets) && (
              <Grid className="gridKey" item xs={2} sm={4} md={4}>
                <Box className="userInfoKey">Tweets</Box>
                <Box>{identityData.reputation.content_count != null ? formatNumber(identityData.reputation.content_count) : "-"}</Box>
              </Grid>
            )}
            {currWorkPlatformMetrics.includes(IDENTITY_ATTRIBUTES.email_id) && (
              <Grid className="gridKey" item xs={2} sm={4} md={4}>
                <Box className="userInfoKey">Email ID</Box>
                <Box className="text-truncate">{(identityData.emails.length > 0 && identityData.emails[0].email_id) || "-"}</Box>
              </Grid>
            )}
            {currWorkPlatformMetrics.includes(IDENTITY_ATTRIBUTES.playlists) && (
              <Grid className="gridKey" item xs={2} sm={4} md={4}>
                <Box className="userInfoKey">Playlists</Box>
                <Box>{identityData.reputation.content_group_count !== null ? formatNumber(identityData.reputation.content_group_count) : "-"}</Box>
              </Grid>
            )}
            {currWorkPlatformMetrics.includes(IDENTITY_ATTRIBUTES.total_watch_time) && (
              <Grid className="gridKey" item xs={2} sm={4} md={4}>
                <Box className="userInfoKey">Total watch time</Box>
                <Box>{identityData.reputation.watch_time_in_hours != null ? timeConvert(identityData.reputation.watch_time_in_hours) : "-"}</Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
}

export default AccountIdentityData;
