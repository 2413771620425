import React from "react";
import { Cell, Label, Pie, PieChart } from "recharts";
import "../../styles/insightIq.scss";
import { Colors } from "../../styles/colors";

export default function DonutChart(props) {
    if (!props.data) return;
    const { variant, data, labels, height, width, isActive } = props;
    let colors = Object.values(Colors);
    const outerRadius = Math.min(height, width) / 2;
    const innerRadius = outerRadius - 7;
    const cornerRadius = 4;
    let startAngle = 270,
        endAngle = -270;
    let labelYOffset = 5, labelXOffset = 5,
        subLabelYOffset = 18;
    let paddingAngle = null;

    switch (variant) {
        case "dual-connected":
            colors = [Colors.accentCaribbeanGreen, Colors.accentWhiteIce];
            break;
        case "triple-disconnected":
            paddingAngle = 4;
            break;
        case "half-connected":
            colors = [Colors.mainPrimary, Colors.mainSecondary];
            startAngle = 190;
            endAngle = -10;
            labelYOffset = -20;
            subLabelYOffset = 3;
            // marginTop = 24;
            break;
        case "dual-connected-primary":
            colors = [Colors.mainPrimary, Colors.mainSecondary];
            break;
    }

    function CustomLabel(props) {
        const { cx, cy } = props.viewBox;
        const textX = props.subLabel ? cx : (cx + labelXOffset);
        const textY = props.subLabel ?
            (variant === 'half-connected' ? (cy + labelYOffset) : (cy - labelYOffset)) :
            (cy + labelYOffset);
        return (
            <>
                <text x={textX}
                      y={textY}
                      className={"recharts-text recharts-label"} textAnchor={"middle"}
                      dominantBaseline={"central"}>
                    <tspan alignmentBaseline={"middle"} className={"title"} fill={Colors.neutralsPrimaryGrey}>
                        {props.labels}
                    </tspan>
                </text>
                <text x={cx} y={cy + subLabelYOffset} className={"recharts-text recharts-label"} textAnchor={"middle"}
                      dominantBaseline={"central"}>
                    <tspan className={"body-r"} fill={Colors.neutralsSecondaryGrey}>
                        {props.subLabel}
                    </tspan>
                </text>
            </>
        );
    }

    return (
        <PieChart width={width} height={height}>
            <Pie
                cornerRadius={cornerRadius}
                data={data}
                dataKey={"value"}
                endAngle={endAngle}
                innerRadius={innerRadius}
                isAnimationActive={isActive ? isActive : false}
                outerRadius={outerRadius}
                startAngle={startAngle}
                paddingAngle={paddingAngle}
                stroke={"none"}
            >
                {data.map((entry, index) => {
                    const { color } = entry;
                    return <Cell key={`cell-${index}`} fill={color ?? colors[index % colors.length]}/>;
                })}
                {props.labels &&
                    <Label width={30} content={<CustomLabel labels={labels[0]} subLabel={labels[1]}/>}></Label>}
            </Pie>
        </PieChart>
    );
}
