import {
  tenantInfoEndpoint,
  currentDashboardURL,
  rotateCredentialsEndpoint,
  deleteCreatedUserEndpoint,
  deleteCredentialsEndpoint,
  URL_GET_TENANT_PRODUCTS,
} from "../endpoints";
import axiosInstance from "../axiosInstance";
import {
  OLD_SECRET_DELETE_FAILURE,
  OLD_SECRET_DELETE_SUCCESS,
  SECRET_ROTATION_NEW_SECRET_GENERATE_FAILURE,
  SECRET_ROTATION_NEW_SECRET_GENERATE_SUCCESS,
  trackEvent,
} from "../../analytics";
import { INSIGHTIQ_TENANT_API_MAX_ATTEMPTS, INSIGHTIQ_TENANT_API_POLLING_INTERVAL } from "../../constants/constants";
import { isEmpty } from "../../utils/util";

const getTenantInfo = async (environment) => {
  try {
    for (let attempt = 1; attempt <= INSIGHTIQ_TENANT_API_MAX_ATTEMPTS; attempt++) {
      const response = await axiosInstance.get(`${currentDashboardURL}/${tenantInfoEndpoint}?environment=${environment.toUpperCase()}`);
      if (response.status === 200) {
        if (response.data.data.length > 0) return response.data.data;
        await new Promise((resolve) => setTimeout(resolve, INSIGHTIQ_TENANT_API_POLLING_INTERVAL));
      } else {
        throw new Error("No API credentials found");
      }
    }
  } catch (error) {
    throw error.message;
  }
};

const rotateClientSecret = async ({ tenantId, clientId, tenantCredentialId, password, environment }) => {
  try {
    const response = await axiosInstance.post(`${currentDashboardURL}/${rotateCredentialsEndpoint}`, {
      tenant_id: tenantId,
      client_id: clientId,
      tenant_credential_id: tenantCredentialId,
      password: password,
      environment: environment.toUpperCase(),
    });
    if (response.status === 200) {
      trackEvent(SECRET_ROTATION_NEW_SECRET_GENERATE_SUCCESS);
      return response;
    }
  } catch (error) {
    trackEvent(SECRET_ROTATION_NEW_SECRET_GENERATE_FAILURE, {
      failure_reason: error?.response?.data?.error?.message,
    });
    throw error.response.data.error;
  }
};

const deleteClientSecret = async ({ tenantId, clientId, tenantCredentialId, environment, timeLeftToExpiry }) => {
  try {
    const response = await axiosInstance.post(`${currentDashboardURL}/${deleteCredentialsEndpoint}`, {
      tenant_id: tenantId,
      client_id: clientId,
      tenant_credential_id: tenantCredentialId,
      environment: environment.toUpperCase(),
    });
    trackEvent(OLD_SECRET_DELETE_SUCCESS, { old_secret_time_to_expiry: timeLeftToExpiry });
    return response.data;
  } catch (error) {
    trackEvent(OLD_SECRET_DELETE_FAILURE, {
      old_secret_time_to_expiry: timeLeftToExpiry,
      failure_reason: error?.response?.data?.error?.message,
    });
    throw new Error(error);
  }
};

const checkProductsEnabledForTenant = async (environment) => {
  try {
    for (let attempt = 1; attempt <= INSIGHTIQ_TENANT_API_MAX_ATTEMPTS; attempt++) {
      const response = await axiosInstance.get(
        `${currentDashboardURL}/${URL_GET_TENANT_PRODUCTS}?environment=${environment.toUpperCase()}&is_enabled=true`
      );
      if (response.status === 200) {
        if (!isEmpty(response.data)) return response.data;
      }
      await new Promise((resolve) => setTimeout(resolve, INSIGHTIQ_TENANT_API_POLLING_INTERVAL));
    }
  } catch (err) {
    throw err.message;
  }

};

export { rotateClientSecret, deleteClientSecret, getTenantInfo, checkProductsEnabledForTenant };
