import React from 'react';
import { Icons } from '../../components';
import './CommentAnalysisNotSupported.scss';
import {
    COMMENT_ANALYSIS_ADD_POST_INSTAGRAM,
    COMMENT_ANALYSIS_ADD_POST_INSTAGRAM_MESSAGE
} from "../../constants/constants";

export default function CommentAnalysisNotSupported() {
    return (
        <div className={'div-comment-analysis-not-supported'}>
            <div><Icons.comment_analysis_not_supported/></div>
            <div className={'div-column-2'}>
                <div className={'section-heading'}>{COMMENT_ANALYSIS_ADD_POST_INSTAGRAM}</div>
                <div className={'body-r div-ca-message'}>{COMMENT_ANALYSIS_ADD_POST_INSTAGRAM_MESSAGE}</div>
            </div>
        </div>
    );
};

