import React, { useEffect, useState } from 'react';
import {
    CustomDatePicker,
    EmptyTableScreen,
    ExportLoadingModal,
    HeaderTopNav,
    ProductPageHeader,
    TabSwitch
} from "../index";
import { downloadFile, isEmpty } from "../../utils/util";
import { CUSTOM_DATE_LABEL, docs, EXPORT_CSV, exportingCsv } from "../../constants/constants";
import { Colors } from "../../styles/colors";
import TopMetrics from "./TopMetrics/TopMetrics";
import PropTypes from "prop-types";
import './MeasurementsPageTemplate.scss';
import Grid from "./Grid/Grid";
import TextBox from "../InsightIQ/TextBox/TextBox";
import SideDrawer from "./SideDrawer/SideDrawer";
import GridDropdown from "./GridDropdown/GridDropdown";
import { Skeleton } from "@mui/material";

/**
 * See PropTypes for documentation
 * @param headerProps - isLoading, title, subtitle
 * @param infoBannerProps - lastUpdatedAt, updateFrequency
 * @param topMetricsProps - isLoading, topMetricsArray: [{metricsRemixIcon, subtitle, title}]
 * @param gridTitleProps - title, subtitle
 * @param gridProps - columns, handlePageChange, handleRowClick, loading, pageNumber, pageSize,
 *  rowHeight, rows, setSortModel, sortModel, totalRows
 * @param gridSearchProps - onClear, onEnter, placeholder, value
 * @param gridDropdownProps - label, menuItems, onItemSelect, selectedValueText, rowRenderer
 * @param sideDrawerProps - isOpen, onClose, drawerData
 * @param exportCsvProps - onExportCsvClick, defaultFileName
 * @param datePickerProps - selectedDayTab, customDate, setSelectedDayTab, daysArray, onDateChanged
 * @param showEmptyScreen - boolean
 * @returns {JSX.Element} - The Measurements Page Template
 * @constructor
 */
export default function MeasurementsPageTemplate({
                                                     headerProps,
                                                     infoBannerProps,
                                                     topMetricsProps,
                                                     gridTitleProps,
                                                     gridSearchProps,
                                                     gridDropdownProps,
                                                     gridProps,
                                                     sideDrawerProps,
                                                     exportCsvProps,
                                                     datePickerProps,
                                                     showEmptyScreen
                                                 }) {
    const {
        title: headerTitle,
        subtitle: headerSubtitle,
        docsLink,
        isLoading: isHeaderLoading,
        onBackClick
    } = headerProps || {};
    const { isLoading: isMetricsLoading, topMetricsArray } = topMetricsProps || {};
    const { isOpen, onClose, drawerData } = sideDrawerProps || {};
    const { title: gridTitle, subtitle: gridSubtitle } = gridTitleProps || {};
    const { onExportCsvClick, defaultFileName } = exportCsvProps || {};
    const {
        onClear: onSearchClear,
        onEnter: onSearchEnter,
        placeholder: searchPlaceholder,
        value: searchText
    } = gridSearchProps || {};
    const { selectedDayTab, customDate, setSelectedDayTab, daysArray, onDateChanged } = datePickerProps || {};
    const [isExportLoadingModalVisible, setExportLoadingModalVisible] = useState(false);
    const [isCustomDatePickerVisible, setIsCustomDatePickerVisible] = useState(false);

    useEffect(() => {
        if (!isExportLoadingModalVisible) {
            return;
        }
        onExportCsvClick && onExportCsvClick().then((response) => {
            downloadFile(response, defaultFileName ?? 'export.csv');
        })
            .catch(console.error)
            .finally(() => {
                setExportLoadingModalVisible(false);
            });
    }, [isExportLoadingModalVisible]);

    function Header() {
        return isEmpty(headerProps) ? null : (
            <>
                <div className='measurement-back-icon'>
                    <i className="ri-arrow-left-line back-icon" onClick={onBackClick}></i>
                </div>
                <ProductPageHeader
                    isLoading={isHeaderLoading}
                    title={headerTitle}
                    additionalSubtitle={headerSubtitle}
                    docsLink={docsLink}
                    buttonLabel={docs}
                />
            </>
        );
    }

    function InfoBanner() {
        return isEmpty(infoBannerProps) ? null : (
            <HeaderTopNav
                title={infoBannerProps?.message}
                backgroundColor={Colors.neutralsBackgroundGrey}
                textClass={"body-r color-neutrals-primary-grey"}
                style={{ marginLeft: -50 }}
            />
        );
    }

    function DateFilter() {
        return (
            <TabSwitch
                currentTab={selectedDayTab}
                handleTabClick={(event) => {
                    if (event.target.textContent === CUSTOM_DATE_LABEL || customDate === event.target.textContent) {
                        setIsCustomDatePickerVisible(true);
                    }
                }}
                handleTabChange={(event, newValue) => {
                    if (newValue === CUSTOM_DATE_LABEL || customDate === newValue) {
                        setIsCustomDatePickerVisible(true);
                    }
                    setSelectedDayTab(newValue);
                }}
                tabs={daysArray}
                height="50px"
                width={"max-content"}
            />
        );
    }

    function TemplateGrid() {
        return (
            <div className={'div-data-grid-container'}>
                {showEmptyScreen ?
                    <EmptyTableScreen
                        icon={null}
                        header={<span className={'section-heading'}>{gridProps?.customProps?.emptyTitle}</span>}
                        content={<span className={'body-r'}>{gridProps?.customProps?.emptySubtitle}</span>}
                        isActionButton={false}
                    /> :
                    <Grid {...{ ...defaultGridProps, ...gridProps }} />
                }
            </div>
        );
    }

    function GridSearch() {
        return isEmpty(gridSearchProps) ? null : (
            <TextBox
                variant={'default-with-search-icon'}
                placeholder={searchPlaceholder}
                onEnter={onSearchEnter}
                value={searchText}
                onClear={onSearchClear}
            />
        )
    }

    function onExportDivClick() {
        setExportLoadingModalVisible(true);
    }

    const defaultGridProps = {
        loading: false,
        pageSize: 10,
        getRowHeight: () => 132,
    };

    return (
        <div>

            <Header/>
            <InfoBanner/>
            {showEmptyScreen ? null : (
                <div className={'div-date-filter-container'}>
                    <DateFilter/>
                    {onExportCsvClick ?
                        <div className={'div-button-text body-m color-neutrals-secondary-grey'}
                             onClick={onExportDivClick}>
                            <i className="ri-share-forward-line export-icon"></i>
                            {EXPORT_CSV}
                        </div>
                        : null
                    }
                </div>
            )}
            {showEmptyScreen || isEmpty(topMetricsProps) ?
                null :
                <div className="div-top-metrics-container">
                    {isMetricsLoading ?
                        <Skeleton variant="text" className={"loading-skeleton"} animation={"pulse"}/>
                        : <TopMetrics metricsArray={topMetricsArray}/>}
                </div>}
            {isEmpty(gridTitleProps) ? null : <div className={'div-grid-title-container'}>
                <div className={'section-heading color-neutrals-primary-grey'}>{gridTitle}</div>
                <div className={'body-r grid-subtitle color-neutrals-secondary-grey'}>{gridSubtitle}</div>
            </div>}
            <div className={'div-grid-container'}>
                {showEmptyScreen ?
                    null :
                    <div className={'div-grid-header'}>
                        <GridSearch/>
                        {isEmpty(gridDropdownProps) ? null : <GridDropdown {...gridDropdownProps} />}
                    </div>}
                {isEmpty(gridProps) ?
                    null :
                    <TemplateGrid/>}
                {isEmpty(sideDrawerProps) ?
                    null :
                    <SideDrawer
                        open={isOpen}
                        onClose={onClose}
                        drawerData={drawerData}
                    />}
            </div>
            {isEmpty(exportCsvProps) ?
                null :
                <ExportLoadingModal
                    open={isExportLoadingModalVisible}
                    title={exportingCsv}
                    onClose={() => setExportLoadingModalVisible(false)}/>}
            {isEmpty(datePickerProps) ?
                null :
                <CustomDatePicker
                    open={isCustomDatePickerVisible}
                    onClose={() => setIsCustomDatePickerVisible(false)}
                    onValueChanged={onDateChanged}
                />}
        </div>
    )
}

MeasurementsPageTemplate.propTypes = {
    headerProps: PropTypes.exact({
        /**
         * @param isLoading - Boolean to set the loading state of the header
         */
        isLoading: PropTypes.bool,
        /**
         * @param docsLink - The link to the documentation
         */
        docsLink: PropTypes.string,
        /**
         * @param subtitle - The subtitle of the header
         */
        subtitle: PropTypes.string,
        /**
         * @param title - The title of the header
         */
        title: PropTypes.string.isRequired,
        /**
         * @function onBackClick - Callback function to handle the back button press
         */
        onBackClick: PropTypes.func,
    }).isRequired,
    infoBannerProps: PropTypes.exact({
        /**
         * @param message - The message to be displayed in the info banner
         */
        message: PropTypes.string,
    }),
    topMetricsProps: PropTypes.shape({
        /**
         * @param isLoading - Boolean to set the loading state of the top metrics
         */
        isLoading: PropTypes.bool.isRequired,
        /**
         * @param topMetricsArray - The array of top metrics to be displayed
         */
        topMetricsArray: PropTypes.arrayOf(PropTypes.shape({
            /**
             * @param metricsRemixIcon - The icon to be displayed in the metric
             */
            metricsRemixIcon: PropTypes.node,
            /**
             * @param subtitle - The subtitle of the metric
             */
            subtitle: PropTypes.string,
            /**
             * @param - The title of the metric
             */
            title: PropTypes.string,
        })),
    }),
    gridTitleProps: PropTypes.exact({
        /**
         * @param subtitle - The subtitle of the grid title
         */
        subtitle: PropTypes.string,
        /**
         * @param title - The title of the grid
         */
        title: PropTypes.string,
    }),
    gridProps: PropTypes.shape({
        /**
         * @param columns - Array of columns to be displayed in the grid.
         * Common props are: align, field, flex, headerAlign, headerClassName, headerName, maxWidth, minWidth, renderCell, sortable
         * Notes:
         *          (1) renderCell = (params) => <div>{params.row.productName}</div>
         *          (2) Add `hideRightSeparator` class to headerClassName if you want to hide the right separator of this column header
         * See https://v5.mui.com/x/api/data-grid/grid-col-def/
         */
        columns: PropTypes.array.isRequired,
        /**
         * @param customProps - Object to set custom properties for the grid
         */
        customProps: PropTypes.shape({
            /**
             * @param emptyTitle - The title to be displayed when the grid is empty
             */
            emptyTitle: PropTypes.string,
            /**
             * @param emptySubtitle - The subtitle to be displayed when the grid is empty
             */
            emptySubtitle: PropTypes.string,
            /**
             * @param emptyGridMessage - The message to be displayed when the grid is empty
             */
            noDataMessage: PropTypes.string,
        }),
        /**
         * @function getRowHeight - Function to get the height of a row. Defaults to () => 132.
         */
        getRowHeight: PropTypes.func,
        /**
         * @param loading - Boolean to set the loading state of the grid
         */
        loading: PropTypes.bool.isRequired,
        /**
         * @function onPageChange - Callback function to handle page change.
         * Takes no arguments and returns nothing
         */
        onPageChange: PropTypes.func.isRequired,
        /**
         * @function onRowClick - Callback function to handle row click.
         * @param {{id: number, row: object}} params - The id and row of the clicked row
         */
        onRowClick: PropTypes.func,
        /**
         * @function onSortModelChange - Function to set the sort model
         */
        onSortModelChange: PropTypes.func,
        /**
         * @param page - The current page number
         */
        page: PropTypes.number.isRequired,
        /**
         * @param pageSize - The number of rows to be displayed per page. Defaults to 10.
         */
        pageSize: PropTypes.number,
        /**
         * @param rowCount - The total number of rows in the grid
         */
        rowCount: PropTypes.oneOfType([
            PropTypes.oneOf([null]),
            PropTypes.number
        ]),
        /**
         * @param rows - The data to be displayed in the grid.
         * For example [{id: 1, productName: "Product 1"}, {id: 2, productName: "Product 2"}]
         */
        rows: PropTypes.array.isRequired,
        /**
         * @param sortModel - The current sort model. Array of one or more (commonly one) objects with the following properties:
         * {field: "<name-of-field>", sort: "asc" | "desc"}
         */
        sortModel: PropTypes.arrayOf(PropTypes.shape({
            /**
             * @param field - The name of the field to sort by
             */
            field: PropTypes.string,
            /**
             * @param sort - The sort order. Either "asc" or "desc"
             */
            sort: PropTypes.string,
        })),
    }).isRequired,
    gridSearchProps: PropTypes.shape({
        /**
         * @function onClear - Function to clear the search text
         */
        onClear: PropTypes.func.isRequired,
        /**
         * @function onEnter - Function to handle the search event
         */
        onEnter: PropTypes.func.isRequired,
        /**
         * @param placeholder - The placeholder text for the search input
         */
        placeholder: PropTypes.string.isRequired,
        /**
         * @param value - The current value of the search input
         */
        value: PropTypes.string.isRequired,
    }).isRequired,
    gridDropdownProps: PropTypes.shape({
        /**
         * @param label - The label of the dropdown
         */
        label: PropTypes.string.isRequired,
        /**
         * @param menuItems - The items to be displayed in the dropdown
         */
        menuItems: PropTypes.array.isRequired,
        /**
         * @function onItemSelect - Callback function to handle the selection of a menu item
         * @param item - The menu item that was selected
         */
        onItemSelect: PropTypes.func.isRequired,
        /**
         * @param selectedValueText - The text of the selected value to be displayed when dropdown is collapsed
         */
        selectedValueText: PropTypes.string,
        /**
         * @function rowRenderer -Callback function to render the row
         * @param index - The index of the menu item
         */
        rowRenderer: PropTypes.func,
    }),
    sideDrawerProps: PropTypes.shape({
        /**
         * @param isOpen - Boolean to set the open state of the side drawer
         */
        isOpen: PropTypes.bool.isRequired,
        /**
         * @function onClose - Callback function to handle the close event of the side drawer.
         * Normally this will do setState({isOpen: false})
         */
        onClose: PropTypes.func.isRequired,
        /**
         * @param drawerData - The data to be displayed in the side drawer
         */
        drawerData: PropTypes.shape({
            /**
             * @param title - The title of the side drawer
             */
            title: PropTypes.string,
            /**
             * @param subtitleLink - The link of the subtitle
             */
            subtitleLink: PropTypes.string,
            /**
             * @param metrics - The metrics to be displayed in the side drawer
             */
            metrics: PropTypes.arrayOf(PropTypes.shape({
                /**
                 * @param title - The title of the metric
                 */
                title: PropTypes.string,
                /**
                 * @param value - The value of the metric
                 */
                value: PropTypes.string,
            })).isRequired,
            shortLink: PropTypes.string,
            /**
             * @param tags - The tags to be displayed in the side drawer
             */
            tags: PropTypes.arrayOf(PropTypes.string),
        }),
    }),
    exportCsvProps: PropTypes.shape({
        /**
         * @function onExportCsvClick - Callback function to handle the export csv event
         */
        onExportCsvClick: PropTypes.func,
        /**
         * @param defaultFileName - The default file name for the exported csv.
         * Usually the server decides this so this is just a backup.
         */
        defaultFileName: PropTypes.string,
    }),
    datePickerProps: PropTypes.shape({
        /**
         * @param selectedDayTab - Selected day or month tab in tab switch
         */
        selectedDayTab: PropTypes.string,
        /**
         * @param customDate - Custom date to be selected in date picker
         */
        customDate: PropTypes.string,
        /**
         * @param setSelectedDayTab - State to set selected day or month tab in tab switch
         */
        setSelectedDayTab: PropTypes.func,
        /**
         * @param daysArray - Days or month array to be displayed in the tab switch
         */
        daysArray: PropTypes.arrayOf(PropTypes.shape({
            /**
             * @param label - Days or month to be displayed in a particular tab
             */
            label: PropTypes.string,
        })),
        /**
         * @param onDateChanged - Callback function to handle the date change of the date picker
         */
        onDateChanged: PropTypes.func,
    }),
    /**
     * @param showEmptyScreen - Boolean to show the empty screen
     */
    showEmptyScreen: PropTypes.bool,
};
