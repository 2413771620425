import moment from "moment-timezone";
import { getAllInvitations, getConnectedAccounts } from "../api/creatorinvitations/creatorinvitations";
import {
  AccountStatus, AUTHENTICATED_DATA,
  CREATOR_COLUMNS,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_SMALL,
  HTTPS_TEXT,
  PLATFORM_DOMAIN_MAPPING,
  PLATFORM_REGEX,
  products, PUBLIC_DATA,
  sandboxProducts,
  statusContentMapping,
  TabValues,
  tagColorsMapping,
  WORKPLATFORM_ID_MAPPING,
  WORKPLATFORM_IDS,
  WORKPLATFORM_USER_HANDLE_MAPPING,
  WORKPLATFROMPRODUCTMAPPING,
} from "../constants/constants";
import { CreatorDatePicker, Icons, PlatformFilter, Tag } from "../components";
import { getBrands, getInterests, getLanguages } from "../api/creatorDiscoveryAPI/creatorDiscoveryAPI";
import { checkRemoved, getNewImageUrl, getUserName, getValidLinks, getWorkPlatformIdByPlatformName } from "./profileHelpers";
import { LogosWithStatus } from "./iconHelper";
import { Tooltip } from "@mui/material";
import "./util.scss";
import {
  collaboratorTooltipText,
  creatorProfileLinkCSVHeader,
  IDENTIFIER_TYPES,
  PostTypes,
  PROFILE_ADD_STATUS,
} from "../constants/campaignTrackingConstants";
import { addProfilesToCampaign, deleteProfileFromCampaign, getAllBrands } from "../api/CampaignTrackingAPI/CampaignTrackingAPI";
import {
  CLEAR_CAMPAIGN_FORM,
  CLEAR_STORE_DETAILS,
  SET_CSV_CREATORS_LIST,
  SET_POST_IDENTIFIERS,
  SET_PROFILES_CSV,
  SET_TRACKED_CREATORS,
} from "../storage/reducers/types";
import { getWorkPlatformDetails } from "../api/sdkConnect/sdkConnectApi";

export const getExpiryTime = (date) => {
  // const date = moment("2022-08-25T21:15:11.129994");

  const currentDate = moment.utc(new Date()).format();
  const expiryDate = moment.utc(date).format();
  const duration = moment.duration(moment(expiryDate).diff(moment(currentDate)));
  // const duration = moment.duration(date.diff(currentDate));
  const pendingMinutes = duration.asMinutes();
  const pendingHours = duration.asHours();
  const pendingDays = Math.round(pendingHours / 24);

  if (pendingDays > 0) return pendingDays + `${pendingDays === 1 ? " day" : " days"}`;
  else {
    const roundedPendingHours = Math.round(pendingHours);
    if (roundedPendingHours > 0) return roundedPendingHours + `${roundedPendingHours === 1 ? " hour" : " hours"}`;
    else {
      const roundedPendingMinutes = Math.round(pendingMinutes);
      return roundedPendingMinutes + `${roundedPendingMinutes === 1 ? " minute" : " minutes"}`;
    }
  }
};

export const getClientIDAndSecret = async (warehouse) => {
  let tenantInfo = warehouse.tenantInfo;
  let clientSecret;
  const clientId = tenantInfo[0]?.apps[0].id;
  const clientSecrets = tenantInfo[0]?.apps[0].credentials;
  clientSecret = clientSecrets[0]?.client_secret;
  return { clientId, clientSecret };
};

export function getSdkEnv(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const getAuthKey = (CLIENT_ID, CLIENT_SECRET) => {
  const AUTH_KEY = window.btoa(CLIENT_ID + ":" + CLIENT_SECRET);
  if (!JSON.parse(localStorage.getItem("SDK_USER_CREDS"))) {
    const SDK_USER_CREDS = {};
    SDK_USER_CREDS.authKey = AUTH_KEY;
    localStorage.setItem("SDK_USER_CREDS", JSON.stringify(SDK_USER_CREDS));
  }
  if (localStorage.getItem("SDK_USER_CREDS") === "{}") {
    localStorage.removeItem("SDK_USER_CREDS");
    const SDK_USER_CREDS = {};
    SDK_USER_CREDS.authKey = AUTH_KEY;
    localStorage.setItem("SDK_USER_CREDS", JSON.stringify(SDK_USER_CREDS));
  } else {
    let user_sdk_creds = JSON.parse(localStorage.getItem("SDK_USER_CREDS"));
    if (user_sdk_creds) {
      user_sdk_creds.authKey = AUTH_KEY;
    }
  }
  return AUTH_KEY;
};

export const flattenObj = (ob) => {
  let result = {};
  for (const i in ob) {
    if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        result[i + "." + j] = temp[j];
      }
    } else {
      result[i] = ob[i];
    }
  }
  return result;
};

export const createWorkplatformMapping = (obj) => {
  const mappedObj = {};
  Object.keys(obj).map((item) => {
    if (item in WORKPLATFROMPRODUCTMAPPING) mappedObj[WORKPLATFROMPRODUCTMAPPING[item]] = obj[item];
  });
  return mappedObj;
};

export const getProductsList = (environment) => {
  if (environment === "sandbox") {
    localStorage.setItem("INSIGHTIQ_DD_PRDS", JSON.stringify(sandboxProducts));
  } else localStorage.setItem("INSIGHTIQ_DD_PRDS", JSON.stringify(products));
};

export const runPollingLogicAPIInLocal = async (tenantInfo, environment) => {
  if (environment === "staging") {
    var clientId = tenantInfo[0]?.apps[0].id;
    let clientSecrets = tenantInfo[0]?.apps[0].credentials;
    var clientSecret = clientSecrets[0]?.client_secret;
  } else if (environment === "production") {
    var clientId = "3e3e6361-dda5-4d07-b12e-90f63551b7ff";
    var clientSecret = "b5a77e38-16ea-4d7c-b121-b848599caf22";
  } else {
    var clientId = "0b8e3c34-9c0b-414d-8282-7f6e20d2132f";
    var clientSecret = "6321aad7-9eb3-46c6-85f2-a094262a39a6";
  }
  return { clientId, clientSecret };
};

export const runAllEnvsAPIsInLocal = async (environment) => {
  if (environment === "staging") {
    var { clientId, clientSecret } = await getClientIDAndSecret(environment);
  } else if (environment === "production") {
    var clientId = "3e3e6361-dda5-4d07-b12e-90f63551b7ff";
    var clientSecret = "b5a77e38-16ea-4d7c-b121-b848599caf22";
  } else {
    var clientId = "0b8e3c34-9c0b-414d-8282-7f6e20d2132f";
    var clientSecret = "6321aad7-9eb3-46c6-85f2-a094262a39a6";
  }
  return { clientId, clientSecret };
};

export const getEnvBaseURL = (environment) => {
  const envObj = JSON.parse(process.env.REACT_APP_ENV_CONNECT_API_ENDPOINTS);
  return envObj[environment];
};

export const getTabSelectedAPI = async (tab, offset, searchValue, sortDesc, warehouse, work_platform_ids, account_status, dateRange) => {
  if (tab === TabValues.all) {
    let allInvitationsResponse = await getAllInvitations(offset, searchValue, sortDesc, warehouse, work_platform_ids, account_status, dateRange);
    return allInvitationsResponse;
  } else if (tab === TabValues.connectedAccounts) {
    let allConnectedAccountsResponse = await getConnectedAccounts(
      offset,
      searchValue,
      sortDesc,
      warehouse,
      work_platform_ids,
      account_status,
      dateRange
    );
    return allConnectedAccountsResponse;
  }
};
function getTooltipText(acc) {
  const { work_platform_id, platform_username, platform_profile_name, status } = acc;
  const userName = getUserName({
    work_platform: { id: work_platform_id },
    platform_username,
    platform_profile_name,
  });
  const platformName = WORKPLATFORM_ID_MAPPING[work_platform_id].platformName;
  const statusLabel = checkRemoved(acc) ? AccountStatus.REMOVED.label : AccountStatus[status]?.label;
  const tooltipText = userName && userName.length > 0 ? `${platformName} - ${userName} - ${statusLabel}` : `${platformName} - ${statusLabel}`;
  return <div>{tooltipText}</div>;
}
function getIcon({ work_platform_id, status }) {
  const platformName = WORKPLATFORM_ID_MAPPING[work_platform_id].platformName;
  const platformComponent = LogosWithStatus[platformName.toLowerCase()];
  const Component = platformComponent ? platformComponent[status.toLowerCase()] : null;
  if (!Component) {
    return null;
  }
  return <Component />;
}
function getFilterIcon(isFiltered) {
  return isFiltered ? <i className={"ri-filter-3-line filter-active"}></i> : <i className={"ri-filter-3-line filter-default"}></i>;
}

function getPlatformColumn(dataIndex, displayString, platformFilterConfig, otherProps = {}) {
  const { appliedPlatforms, appliedPlatformStatuses, isPlatformsFilterVisible, setPlatformsFilterVisible } = platformFilterConfig;
  const { width } = otherProps;

  function render(accounts) {
    if (!accounts || accounts.length === 0) {
      return null;
    }
    const tooltipText = accounts.map(getTooltipText);
    return (
      <Tooltip title={tooltipText} placement={"right-start"}>
        <div className={"div-platform-icons"}>
          {accounts.slice(0, 4).map(getIcon)}
          {accounts.length - 4 > 0 ? (
            <div className={"circle"}>
              {"+"}
              {accounts.length - 4}
            </div>
          ) : null}
        </div>
      </Tooltip>
    );
  }

  return {
    dataIndex: dataIndex,
    filterDropdown: <PlatformFilter platformFilterConfig={platformFilterConfig} />,
    filterDropdownOpen: isPlatformsFilterVisible,
    filterIcon: () => getFilterIcon(appliedPlatforms?.length > 0 || appliedPlatformStatuses?.length > 0),
    filterMultiple: true,
    id: dataIndex,
    key: dataIndex,
    onFilterDropdownOpenChange: setPlatformsFilterVisible,
    render,
    title: displayString,
    width: width || "13%",
  };
}

function getDateColumn(dataIndex, displayString, sortConfig, dateFilterConfig, otherProps = {}) {
  const { dateRange, isDatePickerVisible, setDatePickerVisible } = dateFilterConfig;
  const { sortDesc, setSortDesc } = sortConfig;
  const { width } = otherProps;

  return {
    dataIndex,
    filterDropdown: <CreatorDatePicker dateFilterConfig={dateFilterConfig} />,
    filterDropdownOpen: isDatePickerVisible,
    filterIcon: () => getFilterIcon(dateRange?.fromDate || dateRange?.toDate),
    key: dataIndex,
    onFilterDropdownOpenChange: setDatePickerVisible,
    onHeaderCell: (column) => {
      return {
        onClick: () => {
          setSortDesc((sortDesc) => {
            return !sortDesc;
          });
        },
      };
    },
    render: (date) => moment(date).format(DATE_TIME_FORMAT),
    title: () => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {displayString}
          {sortDesc ? (
            <Icons.down_arrow_icon alt="down_arrow_icon" />
          ) : (
            <Icons.down_arrow_icon alt="down_arrow_icon" style={{ transform: "rotateZ(180deg)" }} />
          )}
        </div>
      );
    },
    width: width || "15%",
  };
}

export const getTabSelectedTableColumns = (tab, sortDesc, setSortDesc, platformFilterConfig, dateFilterConfig) => {
  const allInvitedTableColumns = [
    {
      title: CREATOR_COLUMNS.NAME,
      dataIndex: "name",
      width: "15%",
      id: "name",
      ellipsis: true,
    },
    {
      title: CREATOR_COLUMNS.EMAIL,
      dataIndex: "email",
      width: "20%",
      id: "email",
      ellipsis: true,
    },
    {
      title: CREATOR_COLUMNS.STATUS,
      key: "status",
      id: "status",
      dataIndex: "status",
      width: "8%",
      render: (_, { status }) => (
        <>
          {status.map((tag) => {
            return (
              <Tag color={statusContentMapping[tag]?.color} key={tag} height="28px" width="110px" fontSize="12px">
                {statusContentMapping[tag]?.label}
              </Tag>
            );
          })}
        </>
      ),
    },
    getPlatformColumn("accounts", CREATOR_COLUMNS.PLATFORM, platformFilterConfig),
    getDateColumn("invited_on", CREATOR_COLUMNS.INVITED_ON, { sortDesc, setSortDesc }, dateFilterConfig),
    {
      title: CREATOR_COLUMNS.ACTIONS,
      dataIndex: "buttons",
      width: "5%",
      id: "buttons",
    },
  ];

  const connectedAccountsTableColumns = [
    {
      title: CREATOR_COLUMNS.NAME,
      dataIndex: "name",
      width: "15%",
      id: "name",
      ellipsis: true,
    },
    {
      title: CREATOR_COLUMNS.EMAIL,
      dataIndex: "email",
      width: "20%",
      id: "email",
      ellipsis: true,
    },
    {
      title: CREATOR_COLUMNS.USERNAME,
      dataIndex: "username",
      width: "15%",
      id: "username",
      ellipsis: true,
    },
    getPlatformColumn("accounts", CREATOR_COLUMNS.PLATFORM, platformFilterConfig, { width: "5%" }),
    getDateColumn("connected_on", CREATOR_COLUMNS.CONNECTED_ON, { sortDesc, setSortDesc }, dateFilterConfig),
    {
      title: CREATOR_COLUMNS.ACTIONS,
      dataIndex: "buttons",
      width: "5%",
    },
  ];

  const tableColumnsMappingWithTabSelected = {
    All: allInvitedTableColumns,
    "Connected accounts": connectedAccountsTableColumns,
  };

  return tableColumnsMappingWithTabSelected[tab];
};

export const getSearchableDropdownOptionsForLanguages = async ({ warehouse }) => {
  let getLanguagesAPIResponse = await getLanguages({ warehouse });
  let options = getLanguagesAPIResponse.languages;
  return options.map((option) => {
    return {
      name: option.name,
      value: option.code,
    };
  });
};

export const getSearchableDropdownOptionsForInterest = async ({ warehouse }) => {
  let getInterestsAPIResponse = await getInterests({ warehouse });
  let options = getInterestsAPIResponse.interests;
  return options.map((option) => {
    return {
      name: option.name,
      value: option.name,
    };
  });
};

export const getSearchableDropdownOptionsForBrands = async ({ warehouse }) => {
  let getCategoriesAPIResponse = await getBrands({ warehouse });
  let options = getCategoriesAPIResponse.brands;
  return options.map((option) => {
    return {
      name: option.name,
      value: option.name,
    };
  });
};

// Write a function to convert string "999,556" to "999.6k"
export function convertToShortNumber(str) {
  const number = parseInt(commaReplacer(str));
  if (number >= 1e6) {
    return (number / 1e6).toFixed(1) + "M";
  }
  if (number >= 1e3) {
    return (number / 1e3).toFixed(1) + "K";
  }
  return number.toString();
}
export const convertToShortNumberChart = (value) => {
  if (value >= 1e6) {
    return (value / 1e6).toFixed(1) + "M";
  }
  if (value >= 1e3) {
    return (value / 1e3).toFixed(1) + "K";
  }
  return value.toString();
};

export function commaReplacer(str) {
  return typeof str === "string" ? str.replace(/,/g, "") : str;
}

export const workPlatformsDropdownOptions = [
  {
    value: "Instagram",
    icon: <img width="100%" alt="Instagram" src={WORKPLATFORM_ID_MAPPING["9bb8913b-ddd9-430b-a66a-d74d846e6c66"].platformLogo} />,
    label: "Instagram",
    id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
    columnName: "Followers",
  },
  {
    value: "YouTube",
    icon: <img width="100%" alt="YouTube" src={WORKPLATFORM_ID_MAPPING["14d9ddf5-51c6-415e-bde6-f8ed36ad7054"].platformLogo} />,
    label: "YouTube",
    id: "14d9ddf5-51c6-415e-bde6-f8ed36ad7054",
    columnName: "Subscribers",
  },
  {
    value: "TikTok",
    icon: <img width="100%" alt="TikTok" src={WORKPLATFORM_ID_MAPPING["de55aeec-0dc8-4119-bf90-16b3d1f0c987"].platformLogo} />,
    label: "TikTok",
    id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
    columnName: "Followers",
  },
];

export const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};
export const snakeCaseToSentenceCase = (snakeCase) => {
  // Split the snake case string into an array of words
  var words = snakeCase.split("_");

  // Capitalize the first letter of each word and join them with a space
  var titleCase = words
    .map((word, idx) => {
      return idx === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word;
    })
    .join(" ");

  return titleCase;
};

// function to add +00:00 in time adn return local time
export const convertTimeToLocale = (time, dateTimeFormat = DATE_TIME_FORMAT_SMALL) => {
  if (!time || typeof time !== "string") {
    return time;
  }
  let updatedTime = time;
  if (time?.slice(-6) !== "+00:00") {
    updatedTime = `${time}+00:00`;
  }
  return moment(updatedTime).format(dateTimeFormat);
};

export function getProfileUrls(trackedCreators) {
  return trackedCreators
    .filter((trackedCreator) => trackedCreator["identifier_type"] === IDENTIFIER_TYPES.PROFILE_URL)
    .map((trackedCreator) => trackedCreator["identifier"]);
}

export function getProfileIds(trackedCreators) {
  return trackedCreators.map((trackedCreator) => trackedCreator["id"]);
}

export function getIdentifiers(postIdentifiers) {
  if (postIdentifiers === null) {
    return {};
  }
  return {
    hashtags: postIdentifiers.filter((pi) => pi.startsWith("#")),
    mentions: postIdentifiers.filter((pi) => pi.startsWith("@")),
    keywords: postIdentifiers.filter((pi) => !pi.startsWith("#") && !pi.startsWith("@")),
  };
}

export function getBrandMapping(campaignBrands) {
  if (!!!campaignBrands) {
    return [];
  }
  return campaignBrands.map(({ id, name }) => ({ name, value: id }));
}

export const convertDateToUtc = ({ date, endDate }) => {
  const hasTimezoneOffset = date.slice(-8) === "00:00:00" || date.slice(-8) === "23:59:59";

  if (hasTimezoneOffset) {
    return date;
  }

  let timeZone = "00:00:00";
  if (endDate) timeZone = "23:59:59";
  const utcDate = moment.utc(`${date} ${timeZone}`);
  return utcDate.format("YYYY-MM-DD HH:mm:ss");
};

export function clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse) {
  dispatchCampaignTrackingWarehouse({
    type: SET_TRACKED_CREATORS,
    payload: [],
  });
  dispatchCampaignTrackingWarehouse({
    type: SET_CSV_CREATORS_LIST,
    payload: [],
  });
  dispatchCampaignTrackingWarehouse({
    type: SET_PROFILES_CSV,
    payload: null,
  });
  dispatchCampaignTrackingWarehouse({
    type: SET_POST_IDENTIFIERS,
    payload: null,
  });
  dispatchCampaignTrackingWarehouse({
    type: CLEAR_CAMPAIGN_FORM,
    payload: [],
  });
  dispatchCampaignTrackingWarehouse({
    type: CLEAR_STORE_DETAILS,
    payload: [],
  });
}

export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export function isCampaignEnded(campaign) {
  return moment(campaign?.end_date).toDate() < new Date();
}

export const handleHeightandWidthOfCardImage = (category) => {
  switch (category) {
    case "FASTEST_GROWING":
      return {
        height: "163px",
        width: "177.26px",
      };
    case "INTEREST":
      return {
        height: "174.24px",
        width: "157.49px",
      };
    case "LOOK_ALIKE":
      return {
        height: "178px",
        width: "165.93px",
      };
    case "MOST_FOLLOWED":
      return {
        height: "176.2px",
        width: "151.4px",
      };
    default:
      return {};
  }
};

// Create/update/profile URLs will use the `PROFILE_URL` type
export function createAddableProfiles(trackedCreator) {
  const { identifier_type, identifier, work_platform_id, platform_username } = trackedCreator;
  let url = "";
  switch (identifier_type) {
    case IDENTIFIER_TYPES.PROFILE_URL:
      url = identifier;
      break;
    case IDENTIFIER_TYPES.ACCOUNT_ID: {
      const { platformName } = WORKPLATFORM_ID_MAPPING[work_platform_id];
      url = WORKPLATFORM_USER_HANDLE_MAPPING[platformName];
      url += platform_username;
      break;
    }
  }

  return {
    identifier: url,
    identifier_type: IDENTIFIER_TYPES.PROFILE_URL,
  };
}

export function getAddableProfilePromises(warehouse, campaignId, trackedCreators, csvCreatorUrls) {
  const newProfiles = trackedCreators.filter((trackedCreator) => trackedCreator.addStatus === PROFILE_ADD_STATUS.NEW).map(createAddableProfiles);
  if (isEmpty(newProfiles) && isEmpty(csvCreatorUrls)) {
    return new Promise((resolve) => resolve("Could not find new profiles. Not an error, continuing."));
  }

  return addProfilesToCampaign({
    warehouse,
    profiles: newProfiles,
    campaignId,
    file: getValidLinks(csvCreatorUrls, creatorProfileLinkCSVHeader),
  });
}

export function getDeletableProfilePromises(warehouse, campaignId, trackedCreators) {
  const toBeDeletedProfiles = trackedCreators
    ?.filter((trackedCreator) => trackedCreator.addStatus === PROFILE_ADD_STATUS.TO_BE_REMOVED)
    .map((trackedCreator) => trackedCreator.id)
    .filter((trackedCreator) => !isEmpty(trackedCreator));
  if (isEmpty(toBeDeletedProfiles)) {
    return new Promise((resolve) => resolve("Could not find deletable profiles. Not an error, continuing."));
  }
  return deleteProfileFromCampaign({
    warehouse,
    ids: toBeDeletedProfiles,
    campaignId,
  });
}
export async function editProfiles(warehouse, campaignId, trackedCreators, csvCreatorUrls) {
  try {
    const editProfileResponses = [];
    editProfileResponses.push(await getDeletableProfilePromises(warehouse, campaignId, trackedCreators));
    editProfileResponses.push(await getAddableProfilePromises(warehouse, campaignId, trackedCreators, csvCreatorUrls));
    return editProfileResponses;
  } catch (e) {
    console.error(e);
  }
}

export function setAddedProfiles(creator) {
  creator.addStatus = PROFILE_ADD_STATUS.ADDED;
}
export function getNonDeletedCreators(creator) {
  return creator.addStatus !== PROFILE_ADD_STATUS.TO_BE_REMOVED;
}

export function downloadContent(content, fileName, contentType = "application/octet-stream") {
  let fileURL = "";
  if (contentType === "application/pdf") {
    fileURL = content.url;
  } else {
    const blob = new Blob([content], { type: contentType });
    fileURL = URL.createObjectURL(blob);
  }

  const downloadLink = document.createElement("a");
  downloadLink.href = fileURL;
  downloadLink.download = fileName;
  downloadLink.dispatchEvent(new MouseEvent("click"));
}

export function downloadFile(apiResponse, defaultFileName, defaultContentType = null) {
  const { headers, data } = apiResponse;
  const fileName = headers["content-disposition"]?.split("filename=")[1]?.split(";")[0] || data?.filename || defaultFileName;

  const contentType = defaultContentType || headers["content-type"];

  downloadContent(data, fileName, contentType);
}

export function extractUsername(url) {
  url = url.replace(/\/$/, "");
  const urlParts = url.split("/");
  const username = urlParts[urlParts.length - 1]; // Extract the username
  return username;
}

export function formatProfileLink(url) {
  // Check if the URL contains "instagram" and the UTM source part

  const index = url.indexOf("?");
  if (index !== -1) {
    return url.substring(0, index);
  }
  return url;
}

export const generateCampaignTitle = (workPlatformID, type, username, collaborators) => {
  function renderCollaborators() {
    return (
      <div>
        <p className="body-r collaborator-tooltip-title">{collaboratorTooltipText}</p>
        <div>
          {collaborators.map((collaborator, index) => {
            let { image_url, platform_username } = collaborator;
            return (
              <div className="collaborator-image-username" key={index}>
                <img
                  src={image_url}
                  onError={(e) => {
                    e.target.src = getNewImageUrl(image_url);
                  }}
                  alt={""}
                />
                <div className="body-r">{platform_username}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  type = type ? type.toLowerCase() : "";
  if (type === "feed") {
    type = PostTypes.post.toLowerCase();
  } else if (type === "reels") {
    type = PostTypes.reel.toLowerCase();
  } else {
    type = PostTypes[type] ? PostTypes[type].toLowerCase() : "";
  }
  if (workPlatformID === getWorkPlatformIdByPlatformName("Youtube")) {
    return (
      <div className="campaign-content-title">
        {WORKPLATFORM_ID_MAPPING[workPlatformID]?.platformName} {type} {username ? username : "-"}
        {collaborators?.length > 0 && ` with `}
        {collaborators?.length > 0 && (
          <Tooltip title={renderCollaborators()}>
            <div className="collaborators-length-text">{collaborators.length} others </div>
          </Tooltip>
        )}
      </div>
    );
  }

  return (
    <div className="campaign-content-title">
      {WORKPLATFORM_ID_MAPPING[workPlatformID]?.platformName} {type} by {username ? username : "-"}
      {collaborators?.length > 0 && ` with `}
      {collaborators?.length > 0 && (
        <Tooltip title={renderCollaborators()}>
          <div className="collaborators-length-text">{collaborators.length} others </div>
        </Tooltip>
      )}
    </div>
  );
};

export function downloadSampleCSVFile(sampleCsvUrls, fileHeader) {
  const urls = sampleCsvUrls.filter((item) => !item.isValid);

  // Prepare CSV content
  const csvContent = `${fileHeader}\n${sampleCsvUrls.map((item) => `${item.url}`).join("\n")}`;

  // Trigger the download using the general download function
  downloadContent(csvContent, `${fileHeader}.csv`, "text/csv");
}

export function convertURLToPlatformURL(input, platformId) {
  const trimmedInput = input.trim();

  if (platformId && PLATFORM_DOMAIN_MAPPING.hasOwnProperty(platformId)) {
    const domain = PLATFORM_DOMAIN_MAPPING[platformId];

    // Match platform-specific URL with or without a username
    const platformRegex = PLATFORM_REGEX(domain);

    // If the input matches the platform-specific URL format
    const platformMatch = trimmedInput.match(platformRegex);

    if (platformMatch) {
      const username = platformMatch[2];
      const restOfURL = platformMatch[3] || "";

      const finalURL = `${HTTPS_TEXT}${domain}/${username}${restOfURL}`;
      return finalURL;
    }
  }

  // Otherwise, proceed with the original logic
  let username = trimmedInput;
  if (platformId === WORKPLATFORM_IDS.InstagramPlatformId) {
    username = trimmedInput.replace(/^@/, "");
  }

  const urlWithoutProtocol = username.replace(/^https?:\/\//, "");
  const urlWithoutWww = urlWithoutProtocol.replace(/^www\./, "");
  const url = urlWithoutWww.includes(`${PLATFORM_DOMAIN_MAPPING[platformId].toLowerCase()}.com`)
    ? urlWithoutWww
    : `${PLATFORM_DOMAIN_MAPPING[platformId].toLowerCase()}/${urlWithoutWww}`;
  const finalURL = `${HTTPS_TEXT}${url}`;
  return finalURL;
}

export function calculateEngagementRateDifference(engagementRate, engagementRateHistogram) {
  let medianRange = null;
  for (let entry of engagementRateHistogram) {
    if (entry.is_median === "True") {
      medianRange = entry;
      break;
    }
  }

  if (medianRange === null) {
    return ["No median range found", null, null];
  }

  let medianAverage;
  if (medianRange.min !== null && medianRange.max !== null) {
    medianAverage = (medianRange.min + medianRange.max) / 2;
  } else if (medianRange.min !== null) {
    medianAverage = medianRange.min * 2;
  } else if (medianRange.max !== null) {
    medianAverage = medianRange.max / 2;
  } else {
    return ["Unable to calculate", "same"];
  }

  const difference = engagementRate * 100 - medianAverage;

  let textValue, chipClass;
  if (difference > 0) {
    textValue = `${difference.toFixed(1)}% higher than median`;
    chipClass = "higher";
  } else if (difference < 0) {
    textValue = `${Math.abs(difference).toFixed(1)}% lower than median`;
    chipClass = "lower";
  } else {
    textValue = "Same as median";
    chipClass = "same";
  }

  return [textValue, chipClass];
}

export function getDataCategoryText(dataCategory) {
  let subTitle = '';
  switch (dataCategory.toLowerCase()) {
    case '1p':
      subTitle = AUTHENTICATED_DATA;
      break;
    case '3p':
    default:
      subTitle = PUBLIC_DATA;
  }
  return subTitle;
}

export const getShortURL = (url) => {
  return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
}

export const compareCreatorWorkPlatformsDropdownOptions = [
  {
    value: "Instagram",
    icon: <img width="100%" alt="Instagram" src={WORKPLATFORM_ID_MAPPING[WORKPLATFORM_IDS.InstagramPlatformId].platformLogo} />,
    label: "Instagram",
    id: WORKPLATFORM_IDS.InstagramPlatformId,
    columnName: "Followers",
  },
  {
    value: "YouTube",
    icon: <img width="100%" alt="YouTube" src={WORKPLATFORM_ID_MAPPING[WORKPLATFORM_IDS.YouTubePlatformId].platformLogo} />,
    label: "YouTube",
    id: WORKPLATFORM_IDS.YouTubePlatformId,
    columnName: "Subscribers",
  }
];

export const shortNumberFormatter = (value) => {
  if (value >= 1e6) {
    return (value / 1e6).toFixed(3) + "M";
  }
  if (value >= 1e3) {
    return (value / 1e3).toFixed(3) + "K";
  }
  return value.toString();
};