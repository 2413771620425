import { useEffect, useRef, useState } from "react";
import { calculateTotalDays, formatNumber, isImageExist } from "../../utils/profileHelpers";
import Icons from "../Icons/Icons";
import "./ContentItem.scss";
import fallbackImg from "./content_img_fallback.png";
import PropTypes from "prop-types";
import { Box, Skeleton } from "@mui/material";
import { trackEvent } from "../../analytics";
import { WORKPLATFORM_ID_MAPPING } from "../../constants/constants";
import moment from 'moment-timezone';

const ContentItem = ({
  key,
  profileImageURL,
  workplatformId,
  loading,
  img,
  likes,
  comments,
  desc,
  published_date,
  url,
  event,
  platform,
  username,
  type,
}) => {
  const [contentImg, setContentImg] = useState(null);
  const onlyOnce = useRef(true);
  useEffect(() => {
    if (onlyOnce.current) {
      onlyOnce.current = false;
      isImageExist(img)
        .then((res) => {
          setContentImg(img);
        })
        .catch((rej) => {
          setContentImg(fallbackImg);
        });
    }
  }, []);

  const handleContentClick = (e) => {
    trackEvent(event, { username: username, platform: platform, type: type, number: key });
    window.open(url, "_blank");
  };

  if (loading) {
    return <SkeletonComp />;
  } else
    return (
      <div className="main-content-container">
        <div className="content-header-container">
          <img className="content-item-img-container" alt="" src={profileImageURL} />
          <div className="published-at-date">
            {published_date && <div className="k">{moment(published_date).format("MMM DD, YYYY, hh:mm a")}</div>}
          </div>
          <img className="content-item-img-container" alt="" src={WORKPLATFORM_ID_MAPPING[workplatformId].platformLogo} />
        </div>
        <div className="content" onClick={handleContentClick}>
          <img className="content-item-img" alt="" src={contentImg} />
          <div className="content-desc">{desc && desc.length > 80 ? desc.substring(0, 80) + "..." : desc}</div>
          {/* <div className="content-desc">{desc?.substring(0, 100) + "..."}</div> */}
          <div className="content-likes-comments-container">
            {likes && (
              <div className="icon-like-parent">
                <i className="ri-heart-3-line"></i>
                <div className="k">{formatNumber(likes)}</div>
              </div>
            )}
            {(comments || comments === 0) && (
              <div className="icon-comments-parent">
                <i className="ri-chat-3-line"></i>
                <div className="k">{formatNumber(comments)}</div>
              </div>
            )}
          </div>
        </div>
        {/* moment(date).format("MMM DD, YYYY") */}
      </div>
    );
};

export default ContentItem;

ContentItem.propTypes = {
  loading: PropTypes.bool,
  img: PropTypes.string,
  likes: PropTypes.string,
  comments: PropTypes.string,
  desc: PropTypes.string,
  published_date: PropTypes.instanceOf(Date),
};

// ContentItem.defaultProps = {
//   img: "https://picsum.photos/200/300",
//   likes: "12.5k",
//   comments: "1.5k",
//   desc: "In the eighteenth century the German philosopher Immanuel Kant developed a In the eighteenth century the German philosopher Immanuel Kant developed a",
//   published_date: "2 days ago",
// };

function SkeletonComp() {
  return (
    <div className="main-content-container">
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"12px"}>
        <Skeleton animation="wave" variant="circular" width={36} height={36} />
        <Skeleton animation="wave" variant="text" width={208} height={20} />
        <Skeleton animation="wave" variant="circular" width={32} height={32} />
      </Box>
      <Skeleton animation="wave" variant="rounded" width={300} height={300} />
      <div>
        <Skeleton animation="wave" variant="text" width={300} height={57} />
      </div>
      <div>
        <Skeleton animation="wave" variant="text" width={168} height={24} />
      </div>
    </div>
  );
}
