import { Alert, Box, IconButton, Snackbar, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LINK_SENT_FAILED, LINK_SENT_SUCCESS, LINK_UPLOAD_CONTACTS, LINK_UPLOAD_CONTACTS_FAILED, trackEvent } from "../../analytics";
import { postBulkInvites } from "../../api/creatorinvitations/creatorinvitations";
import { EMAIL_REGEX } from "../../constants/constants";
import { useToast } from "../../hooks/useToast";
import { WarehouseContext } from "../../storage/context";
import Icons from "../Icons/Icons";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import "./MultipleUpload.scss";
function MultipleUpload() {
  const [csvFile, SetCSVFile] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [totalEntries, setTotalEntries] = useState(null);
  const [errorEntries, setErrorEntries] = useState(null);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const toast = useToast();
  const [openInviteBar, setOpenInviteBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const { warehouse } = useContext(WarehouseContext);

  const sendBulkInvites = async () => {
    setLoading(true);
    try {
      const response = await postBulkInvites(csvFile, warehouse.environment.current);
      //change tp 201
      if (response.status === 201) {
        setOpenInviteBar(true);
        setLoading(false);
        setErrorMsg(null);
        trackEvent(LINK_SENT_SUCCESS, {
          type: "bulk",
        });
      } else {
        setLoading(false);
        setErrorMsg(`${response?.res?.data?.error?.message ? response?.res?.data?.error?.message : "Something went wrong. Please try again."}`);
        trackEvent(LINK_SENT_FAILED);
      }

      //clean fields
      SetCSVFile(null);
      // setErrorMsg(null);
      setTotalEntries(null);
      setErrorEntries(null);
      setIsBtnActive(false);
    } catch (err) {
      console.error(err);
    }
  };

  function validateEmail(email) {
    return EMAIL_REGEX.test(email);
  }

  function validateHeaders(header) {
    if (header[0].toLowerCase() !== "name" || header[1].toLowerCase() !== "email") {
      return false;
    }
    return true;
  }

  function validateName(name) {
    return name.length <= 100;
  }
  function readCSV(ev) {
    const csvdata = ev.target.result;
    let errorRows = 0;
    let totalRows = 0;

    let rowsData = csvdata.split("\r\n");

    rowsData = rowsData.filter((row) => row.trim()); // remove empty rows

    let header = rowsData[0].trim();
    header = header.split(",");
    header = header.filter((col) => col.trim());
    header = header.map((col) => col.trim());
    if (header.length !== 2 || !validateHeaders(header)) {
      setErrorMsg("Structure of the file is incorrect. Check the sample file.");
      SetCSVFile(null);
      trackEvent(LINK_UPLOAD_CONTACTS_FAILED, {
        reason: "invalid_csv",
      });
      return;
    }
    if (rowsData.length > 101) {
      setErrorMsg("File is exceeding maximum row limit.");
      SetCSVFile(null);
      trackEvent(LINK_UPLOAD_CONTACTS_FAILED, {
        reason: "limit_exceeded",
      });
      return;
    }

    if (rowsData.length < 2) {
      setErrorMsg("File is empty.");
      SetCSVFile(null);
      trackEvent(LINK_UPLOAD_CONTACTS_FAILED, {
        reason: "empty_csv",
      });
      return;
    }

    for (let row = 1; row < rowsData.length; row++) {
      if (rowsData[row].trim().length < 1) {
        continue;
      }
      const data = rowsData[row].split(",");
      let name = data[0];
      name = name.replace("\n", "");
      name = name.replace("\r", "");
      name = name.trim();
      let email = data[1];
      email = email.replace("\n", "");
      email = email.replace("\r", "");
      email = email.trim();
      if (name === "" || email === "" || !validateName(name) || !validateEmail(email)) {
        errorRows++;
        totalRows++;
      } else {
        totalRows++;
      }
    }

    if (errorRows > 0) {
      setErrorMsg("Please update the incorrect entries and re-upload the file.");
      SetCSVFile(null);
      trackEvent(LINK_UPLOAD_CONTACTS_FAILED, {
        reason: "invalid_csv",
      });
    } else {
      setIsBtnActive(true);
    }
    setErrorEntries(errorRows);
    setTotalEntries(totalRows);
  }

  function validateCSV(file) {
    if (file.type !== "text/csv") {
      setErrorMsg(`File type is incorrect. Upload a .csv file.`);
      trackEvent(LINK_UPLOAD_CONTACTS_FAILED, {
        reason: "invalid_fileformat",
      });
      return false;
    }

    return true;
  }
  function handleCSVUpload(ev) {
    setErrorMsg(null);
    setErrorEntries(null);
    setTotalEntries(null);

    trackEvent(LINK_UPLOAD_CONTACTS);
    const [file] = ev.target.files;
    if (file && validateCSV(file)) {
      SetCSVFile(file);

      //read csv
      const fr = new FileReader();
      fr.onload = readCSV;
      fr.readAsText(file);
    }
  }

  function dropHandler(ev) {
    ev.preventDefault();
    // if file already uploaded return
    if (csvFile) {
      return;
    }
    let file = ev.dataTransfer.files[0];

    setErrorMsg(null);
    setErrorEntries(null);
    setTotalEntries(null);
    trackEvent(LINK_UPLOAD_CONTACTS);
    if (file && validateCSV(file)) {
      SetCSVFile(file);

      //read csv
      const fr = new FileReader();
      fr.onload = readCSV;
      fr.readAsText(file);
    }
  }

  useEffect(() => {
    window.addEventListener(
      "dragover",
      function (e) {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
      },
      false
    );
  });

  const closeInviteBar = () => {
    setOpenInviteBar(false);
  };
  return (
    <div className="bulk-upload-container">
      <Box
        className="upload-csv-container"
        draggable="true"
        onDrop={dropHandler}
        onClick={(e) => {
          if (!csvFile && e.currentTarget === e.target) document.getElementById("upload-csv-input").click();
        }}
      >
        {csvFile && (
          <>
            <IconButton
              color="primary"
              aria-label="csv-upload-preview"
              component="label"
              className="upload-csv-preview-container"
              sx={{
                width: "48px",
                height: "48px",
                border: "2px solid #EBEBEF",
                backgroundColor: "#fffefe",
                marginBottom: "24px",
              }}
            >
              <Icons.CSVFile />
            </IconButton>
            <IconButton
              aria-label="delete"
              sx={{
                position: "absolute",
                top: "0px",
                right: "0px",
              }}
              onClick={() => {
                SetCSVFile(null);
                setErrorEntries(null);
                setTotalEntries(null);
                setErrorMsg(null);
                setIsBtnActive(false);
              }}
            >
              <Icons.close_circle_fill />
            </IconButton>
            <label className="upload-csv-preview-label">{csvFile.name}</label>
          </>
        )}
        {!csvFile && (
          <>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              className="upload-csv-icon-container"
              sx={{
                width: "48px",
                height: "48px",
                border: "2px solid #EBEBEF",
                backgroundColor: "#fffefe",
                marginBottom: "24px",
              }}
            >
              <input
                hidden
                accept={".csv"}
                type="file"
                id="upload-csv-input"
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.target.value = null;
                }}
                onChange={(ev) => handleCSVUpload(ev)}
                name="media"
              />

              <Icons.upload_icon />
            </IconButton>
            <label className="upload-csv-label" htmlFor="publish-post-file">
              Drag and drop or click here to upload CSV file
            </label>
          </>
        )}
      </Box>
      <Tooltip
        classes={{
          tooltipPlacementBottom: "csv-tooltip",
        }}
        PopperProps={{ style: { marginTop: 0 } }}
        title={
          <>
            <Icons.csv_tool_tip />
          </>
        }
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "transparent",
            },
          },
        }}
      >
        <label className="upload-csv-info-label">
          File should have [name, email] as rows. Max 100 rows allowed.
          <Icons.info_icon_16x16 />
          <a href="https://cdn.insightiq.ai/files/sample_upload.csv" target="_blank">
            Download CSV template.
          </a>
        </label>
      </Tooltip>
      <Box className="csv-file-details">
        {totalEntries !== null && <label>Total entries: {totalEntries}</label>}
        {errorEntries !== null && <label>Incorrect entries: {errorEntries}</label>}
      </Box>
      <Box className="bulk-upload-footer">
        {errorMsg && <label>{errorMsg}</label>}
        <PrimaryButton label={"Send invites"} disabled={!isBtnActive} onClick={sendBulkInvites} loading={loading} />
      </Box>
      <div className="invitationBar">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openInviteBar}
          autoHideDuration={60000}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpenInviteBar(false);
          }}
          message="Invitations are in the queue and will be sent in a minute."
          action={
            <>
              {" "}
              <IconButton size="small" aria-label="close" color="inherit" onClick={closeInviteBar}>
                <Icons.close_icon />
              </IconButton>
            </>
          }
        />
      </div>
    </div>
  );
}

export default MultipleUpload;
