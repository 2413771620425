import React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Colors } from "../../styles/colors";
import "./DropdownIconMenu.scss";
import { Box } from "@mui/material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    classes={{
      paper: props.showAppBar && "sticky-position-menu",
    }}
    sx={{ minWidth: props.width, right: props.dropDownRightPosition }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    gap: 16,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow: "0px 5px 15px 0px rgba(18, 27, 46, 0.15)",
    "& .MuiMenu-list": {
      padding: "0",
    },
    background: Colors.white,
    "& .MuiMenuItem-root": {
      padding: 16,
      gap: 24,
      borderBottom: `1px solid ${Colors.neutralsBorderGrey}`,
      color: Colors.neutralsPrimaryGrey,
      fontSize: 16,
      fontWeight: 500,
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
      "&:hover": {
        backgroundColor: Colors.mainSecondary,
      },
      justifyContent: "space-between",
    },
  },
}));

const DropdownIconMenu = ({ anchorEl, open, handleClose, menuOptions, showAppBar, isLogoContainsImage, width, dropDownRightPosition }) => {
  return (
    <StyledMenu
      id="demo-customized-menu"
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      showAppBar={showAppBar}
      width={width}
      dropDownRightPosition={dropDownRightPosition}
    >
      {menuOptions.map((option) => (
        <MenuItem
          key={option}
          onClick={() => {
            option?.actionHandler && option?.actionHandler();
            handleClose();
          }}
          disableRipple
        >
          <Box display={"flex"} gap={"12px"} alignItems={"center"}>
            <Box>{isLogoContainsImage ? <img src={option.startIcon} height={32} width={32} /> : option.startIcon}</Box>
            <Box className="menu-item-label">{option.label}</Box>
          </Box>
          {option.endIcon}
        </MenuItem>
      ))}
    </StyledMenu>
  );
};

export default DropdownIconMenu;
