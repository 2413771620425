import React, { useState, useEffect } from "react";
import { Autocomplete, Avatar, Box, Chip, InputAdornment, TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import "./ApiSearchableDropdown.scss";
import Icons from "../Icons/Icons";
import { formatNumber } from "../../utils/profileHelpers";
import { isEmpty } from "../../utils/util";
import { AUDIENCE_LOCATIONS, AUDIENCE_LOOKALIKES, CREATOR_LOCATIONS, CREATOR_LOOKALIKES, ERROR_FETCHING_RESULTS, ERROR_RESULTS_FOUND, ERROR_RESULTS_FOUND_DESC, FILTER_KEY_MAPPING, INVALID_REQUEST_PARAMETERS, RECORD_NOT_FOUND, START_TYPING } from "../../constants/creatorDiscovery";
import { FILTER_SEARCHED, trackEvent } from "../../analytics";
import { creatorSearchTabs, WORKPLATFORM_ID_MAPPING } from "../../constants/constants";

const delay = 500;
function ApiSearchableDropdown({
  defaultValue = [],
  getFilterValue,
  placeholder,
  showTags = true,
  showTagsInTextfield = false,
  handlePostSearch,
  multi = true,
  filterKey,
  filterName = "",
  disableClearable = true,
  variant,
  searchLabel = "",
  dropDownVariant,
  workPlatformId,
  searchType
}) {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cancelToken, setCancelToken] = useState(null);
  const [selectedValues, setSelectedValue] = useState(defaultValue);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (searchType === creatorSearchTabs.compareCreators) {
      setSelectedValue(defaultValue);
    } else {
      if (defaultValue && defaultValue.length >= 0) {
        setSelectedValue(defaultValue);
      }
    }
  }, [defaultValue]);

  const handleSearch = debounce((searchQuery) => {
    setSearchQuery(searchQuery);
    // Cancel the previous API call, if any
    if (cancelToken) {
      cancelToken.cancel("Canceled due to new search query");
    }

    // Create a new cancel token for the current API call
    const newCancelToken = axios.CancelToken.source();
    setCancelToken(newCancelToken);

    // Make API call to search for repositories on GitHub
    if (searchQuery.trim() !== "") {
      // Only make API call if searchQuery is not empty
      setLoading(true);
      handlePostSearch({ searchQuery, newCancelToken })
        .then((response) => {
          setSearchResults(response);
          setLoading(false);
          if (filterKey === CREATOR_LOOKALIKES || filterKey === AUDIENCE_LOOKALIKES || filterKey === CREATOR_LOCATIONS || filterKey === AUDIENCE_LOCATIONS) {
            trackEvent(FILTER_SEARCHED, {
              query: searchQuery,
              source: FILTER_KEY_MAPPING[filterKey],
              result_count: response.length,
            });
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("Canceled due to new search query");
          } else {
            setError(error);
            setLoading(false);
          }
        });
    } else {
      // If searchQuery is empty, clear the search results
      setSearchResults([]);
      setLoading(false);
      setError(null);
    }
  }, delay);

  const handleChange = (event, values) => {
    event.stopPropagation();
    setSelectedValue(values);
    if (getFilterValue) getFilterValue(values);
  };

  const handleOpen = () => {
    if (showTagsInTextfield) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDelete = (id) => () => {
    const updatedValues = selectedValues.filter((v) => v.id !== id);
    setSelectedValue(updatedValues);
    if (getFilterValue) getFilterValue(updatedValues);
  };

  useEffect(() => {
    // Cleanup function to cancel the API call when component unmounts
    return () => {
      if (cancelToken) {
        cancelToken.cancel("Component unmounted");
      }
    };
  }, [cancelToken]);

  function RenderDropdownTextField(params) {
    switch (variant) {
      case 'textfield-with-label':
        return (
          <TextField
            sx={{
              padding: '0px',
            }}
            {...params}
            size='small'
            variant='outlined'
            placeholder={showTagsInTextfield ? (selectedValues?.length > 0 ? '' : placeholder) : placeholder}
            label={searchLabel}
            classes={{
              root: 'searchable-label-textfield',
            }}
            InputProps={{
              ...params.InputProps,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                }
              },
              classes: {
                root: 'searchable-label-textfield-input-root',
                notchedOutline: 'searchable-label-textfield-notched-outline',
                focused: 'searchable-label-textfield-focused',
                input: 'searchable-label-textfield-input',
              },
            }}
            InputLabelProps={{
              classes: {
                root: 'label-textfield-root',
                shrink: 'label-textfield-shrink',
              },
            }}
          />
        );

      default:
        return (
          <TextField
            sx={{
              padding: '0px',
            }}
            {...params}
            className='my-searchable-textfield'
            size='small'
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                }
              },
              startAdornment: (
                <>
                  {showTagsInTextfield && (
                    <InputAdornment position='start' sx={{ height: 'auto' }}>
                      <div className='filter-tag-label'>{filterName}</div>
                    </InputAdornment>
                  )}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            placeholder={showTagsInTextfield ? (selectedValues?.length > 0 ? '' : placeholder) : placeholder}
          />
        );
    }
  }

  function RenderDropdownOptions({ option, props }) {
    switch (dropDownVariant) {
      case "dropdown-option-with-workPlatform":
        return (
          <li
            {...props}
            key={option.id}
            style={{ borderBottom: "1px solid var(--neutrals-border-grey)", height: option?.username ? "70px" : "56px" }}
          >
            <Box className="searchable-options-container">
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                {option?.username && (
                  <Box className="searchable-options-profile-container" >
                    <Avatar alt="Profile Pic" src={option?.picture} sx={{ width: 50, height: 50 }} />
                    {workPlatformId ? (
                      <div className="searchable-options-user-platform">
                        <img width={24} height={24} src={WORKPLATFORM_ID_MAPPING[workPlatformId]?.platformLogo} alt={""} />
                      </div>
                    ) : null}
                  </Box>
                )}
                <Box>
                  <Box className="searchable-options">
                    {option.username ? option.username : option.display_name}
                    {option?.is_verified && (
                      <div className="verified-logo-container">
                        <Icons.verified_logo className="is-verified-logo" />
                      </div>
                    )}
                  </Box>
                  {option?.followers && <Box className="searchable-options">{formatNumber(option?.followers)}</Box>}
                </Box>
              </Box>
            </Box>
          </li>);
      default:
        return (
          // TODO: Need to add type here for different types (location, lookalike)
          <li
            {...props}
            key={option.id}
            style={{ borderBottom: "1px solid var(--neutrals-border-grey)", height: option?.username ? "70px" : "56px" }}
          >
            {/* <div className="searchable-options">{option.username ? option.username : option.display_name}</div>
                {option?.followers && <div className="searchable-options">{formatNumber(option?.followers)}</div>} */}
            <Box className="searchable-options-container">
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                {option?.username && (
                  <Box>
                    <Avatar alt="Profile Pic" src={option?.picture} sx={{ width: 50, height: 50 }} />
                  </Box>
                )}
                <Box>
                  <Box className="searchable-options">
                    {option.username ? option.username : option.display_name}
                    {option?.is_verified && (
                      <div className="verified-logo-container">
                        <Icons.verified_logo className="is-verified-logo" />
                      </div>
                    )}
                  </Box>
                  {option?.followers && <Box className="searchable-options">{formatNumber(option?.followers)}</Box>}
                </Box>
              </Box>
            </Box>
          </li>);
    }
  }

  function RenderNoOptionsText() {
    switch (dropDownVariant) {
      case "dropdown-option-with-workPlatform":
        return (
          loading ? (
            <div className="searchable-options">
              <Skeleton variant="text" width={"100%"} />
            </div>
          ) : error && error?.response?.data?.error?.type !== RECORD_NOT_FOUND && error?.response?.data?.error?.type !== INVALID_REQUEST_PARAMETERS ? (
            <div className="searchable-options">{ERROR_FETCHING_RESULTS}</div>
          ) : searchResults.length === 0 && !isEmpty(searchQuery) ? (
            <div className="searchable-options no-result-found-error">
              <div className="body-b" >{ERROR_RESULTS_FOUND}</div>
              <div className="subtitle-r" >{ERROR_RESULTS_FOUND_DESC(WORKPLATFORM_ID_MAPPING[workPlatformId]?.platformName)} </div>
            </div>
          ) : (
            <div className="searchable-options">{START_TYPING}</div>
          ));
      default:
        return (
          loading ? (
            <div className="searchable-options">
              <Skeleton variant="text" width={"100%"} />
            </div>
          ) : error && error?.response?.data?.error?.type !== RECORD_NOT_FOUND ? (
            <div className="searchable-options">{ERROR_FETCHING_RESULTS}</div>
          ) : searchResults.length === 0 && !isEmpty(searchQuery) ? (
            <div className="searchable-options">{ERROR_RESULTS_FOUND}</div>
          ) : (
            <div className="searchable-options">{START_TYPING}</div>
          ));
    }
  }

  return (
    <>
      <Autocomplete
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        disableClearable={disableClearable}
        multiple={multi}
        classes={{
          root: "api-searchable-dropdown",
          option: "api-searchable-drop-down-option",
          paper: "api-searchable-drop-down-option-container",
        }}
        filterOptions={(options, params) => {
          return options;
        }}
        value={selectedValues}
        options={searchResults}
        getOptionLabel={(result) => result.display_name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(event, value) => {
          handleSearch(value);
          if (value.length === 0 && showTagsInTextfield) {
            setOpen(false);
          } else if (value.length === 0) {
            setOpen(false);
          } else {
            setLoading(true);
            setOpen(true);
          }
        }}
        renderTags={(value, props) =>
          showTagsInTextfield
            ? value.map((option, index) => (
              <Chip
                label={option.display_name}
                classes={{
                  root: "searchable-drop-down-chip-tag-container",
                  label: "searchable-drop-down-chip-label",
                  deleteIcon: "searchable-drop-down-chip-delete-icon",
                }}
                deleteIcon={
                  <Icons.icon_close
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                }
                {...props({ index })}
              />
            ))
            : null
        }
        popupIcon={showTagsInTextfield ? null : <Icons.down_arrow />}
        id="custom-autocomplete"
        // loading={loading}
        onChange={handleChange}
        noOptionsText={
          RenderNoOptionsText()
        }
        style={{ width: "auto", height: "inherit" }}
        renderInput={RenderDropdownTextField}
        renderOption={(props, option) => {
          if (loading) {
            return (
              <li {...props}>
                <Skeleton variant="text" width={"100%"} />
                <Skeleton variant="text" width={"100%"} />
              </li>
            );
          } else {
            return (<RenderDropdownOptions option={option} props={props} />);
          }
        }}
      />
      {showTags && (
        <Box
          sx={{
            marginLeft: "4px",
          }}
        >
          {selectedValues?.length > 0 &&
            selectedValues.map((v) => (
              <Chip
                classes={{
                  root: "searchable-drop-down-chip-contianer",
                  label: "searchable-drop-down-chip-label",
                  deleteIcon: "searchable-drop-down-chip-delete-icon",
                }}
                key={v.id}
                label={v.display_name}
                onDelete={onDelete(v.id)}
                deleteIcon={<Icons.icon_close />}
              />
            ))}
        </Box>
      )}
    </>
  );
}

export default ApiSearchableDropdown;
