import { SIDEBAR_GO_LIVE_CLICK, trackEvent } from "../../analytics";
import axiosInstance from "../axiosInstance";
import { currentDashboardURL, contactSalesEndpoint } from "../endpoints";

const contactSales = async () => {
  try {
    const response = await axiosInstance.post(`${currentDashboardURL}/${contactSalesEndpoint}`);
    return response;
  } catch (e) {
    throw e;
  }
};

export { contactSales };
