import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PLAYGROUND } from '../../constants/constants';
import { getDataCategoryText } from '../../utils/util';
import './ProductsCard.scss';
import { APIPRODUCT_CLICKED, PLAYGROUND_CLICKED, trackEvent } from '../../analytics';
import { isEmpty } from "../../utils/util";

export default function ProductsCard({ product }) {
    const navigate = useNavigate();
    const {
        title,
        description,
        dataCategory,
        redirectToDocs,
        pathToPage,
        thumbnailUrl,
        tags,
        hasPlayground,
        productId,
    } = product;

    function onCardClick() {
        if (redirectToDocs) {
            window.open(new URL(pathToPage), '_blank');
        } else {
            navigate('../' + productId);
        }
        trackEvent(APIPRODUCT_CLICKED, {
            product_name: title,
        });
    }

    function ProductThumbnail() {
        return (
            <div className={'div-product-thumbnail'}>
                <img src={thumbnailUrl} alt={'thumbnail'}/>
                <div className={'products-tags-container'}>
                    {tags?.map((tag, index) => {
                        const { color, backgroundColor, title } = tag;
                        return (
                            <div key={index} className={`product-variant small-labels`}
                                 style={{ backgroundColor, color }}>
                                {title}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    function ProductTitle() {
        return (
            <div className='sub-section-heading product-card-title'>
                {title}
                {redirectToDocs ? <i className='ri-arrow-right-up-line redirect-arrow'></i> : null}
            </div>
        );
    }

    function ProductSubtitle() {
        let subTitle = getDataCategoryText(dataCategory);
        if (!hasPlayground) {
            subTitle = <div className={'body-r span-subtitle'}>{subTitle}</div>;
        } else {
            subTitle = (
                <div className={'body-r div-subtitle'}>
                    <span className={'span-subtitle'}>{subTitle}</span>
                    <span className={'span-subtitle bullet-separator'}>•</span>
                    <div className={'div-playground-link'}
                         onClick={() => trackEvent(PLAYGROUND_CLICKED, {
                             product_name: title,
                         })}>
                        {PLAYGROUND}
                    </div>
                </div>
            );
        }
        return <div className='product-card-dataType'>{subTitle}</div>;
    }

    function ProductDescription() {
        return <div className='subtitle-r product-card-description'>{description}</div>;
    }

    return (
        <div onClick={onCardClick} className='product-card-container'>
            <ProductThumbnail/>
            <ProductTitle/>
            {isEmpty(dataCategory) ? null : <ProductSubtitle/>}
            <ProductDescription/>
        </div>
    );
}
