import React, { useContext, useState } from "react";
import "./SearchableCheckBoxModal.scss";
import Icons from "../Icons/Icons";
import { TextField } from "@mui/material";
import CheckBox from "../CheckBox/CheckBox";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { ANY_CONTACT_TYPE } from "../../constants/creatorDiscovery";
import { isEmpty } from "../../utils/util";
import { SET_LAST_INTERACTED_FILTER_NULL, SET_SHOULD_RENDER_TRUE } from "../../storage/reducers/types";
import { CreatorDiscoveryWarehouseContext } from "../../storage/context";
import { TWITTER, XCOM } from "../../constants/constants";

const SearchableCheckBoxModal = ({ contactTypeList, open, getFilterValue, defaultSelectedItems }) => {
  const [isModalOpen, setIsModalOpen] = useState(open);
  const { dispatchCreatorDiscoveryWarehouse } = useContext(CreatorDiscoveryWarehouseContext);
  let initialContactTypeList = [];
  if (!isEmpty(defaultSelectedItems)) {
    defaultSelectedItems.forEach((item) => {
      const foundObject = contactTypeList.find((obj) => obj.value === item);
      if (foundObject) {
        initialContactTypeList.push(foundObject?.name);
      }
    });
  }
  const [selectedItems, setSelectedItems] = useState(initialContactTypeList);
  const [filteredContactList, setFilteredContactList] = useState(contactTypeList);

  if (!isModalOpen) {
    return null;
  }

  const handleContactTypeFilterSearch = (e) => {
    if (e.target.value === "") {
      setFilteredContactList(contactTypeList);
      return;
    }
    const filteredValues = contactTypeList.filter((item) => {
      const searchTerm = e.target.value.toLowerCase();
  
      // Special case for x.com mapping to twitter
      if (searchTerm === TWITTER.toLowerCase()) {
        return item?.name.toLowerCase().indexOf(XCOM.toLowerCase()) !== -1;
      }

      // General filtering logic
      return item?.name.toLowerCase().indexOf(searchTerm) !== -1;
    });
    setFilteredContactList(filteredValues);
  };

  const isAnyOptionSelected = () => {
    return selectedItems.includes(ANY_CONTACT_TYPE);
  };

  const handleCheckboxClick = (selectedItems, contactList) => {
    const selectedValues = [];
    selectedItems.forEach((item) => {
      const foundObject = contactList.find((obj) => obj.name === item);
      if (foundObject) {
        selectedValues.push(foundObject?.value);
      }
    });
    getFilterValue && getFilterValue(selectedValues);
  };

  const handleRowClick = (idx, contactItem) => {
    if (idx === 0) {
      setSelectedItems(selectedItems.includes(contactItem?.name) ? [] : [contactItem?.name]);
    } else {
      setSelectedItems((prevSelectedItems) => {
        if (prevSelectedItems.includes(contactItem?.name)) {
          return prevSelectedItems.filter((item) => item !== contactItem?.name);
        } else {
          return [...prevSelectedItems, contactItem?.name];
        }
      });
    }
  };

  const ContactListItem = ({ idx, contactItem }) => {
    return (
      <>
        <div
          className={`searchable-checkbox-modal-contact-list-item ${
            isAnyOptionSelected() && idx > 0 ? "disabled-rest" : selectedItems.includes(contactItem?.name) ? "highlighted" : ""
          }`}
          style={idx === 0 && contactItem?.name === ANY_CONTACT_TYPE ? { padding: "16px 20px 16px 16px" } : { padding: "12px 20px 12px 16px" }}
          onClick={() => {
            handleRowClick(idx, contactItem);
          }}
        >
          <CheckBox
            blackCheck={false}
            defaultChecked={isAnyOptionSelected() && idx > 0 ? false : selectedItems.includes(contactItem?.name)}
            disabled={isAnyOptionSelected() && idx > 0}
          />
          {idx === 0 && contactItem?.name === ANY_CONTACT_TYPE ? (
            <div
              className="searchable-checkbox-modal-contact-list-item-text"
              style={{ flexDirection: "column", gap: "4px", alignItems: "flex-start" }}
            >
              <div className="searchable-checkbox-modal-contact-list-item-label">{contactItem?.name}</div>
              <div className="searchable-checkbox-modal-contact-list-item-label-description">{contactItem?.description}</div>
            </div>
          ) : (
            <div className="searchable-checkbox-modal-contact-list-item-text">
              <div className="searchable-checkbox-modal-contact-list-item-icon">
                <img src={contactItem?.icon} height={32} width={32} />
              </div>
              <div className="searchable-checkbox-modal-contact-list-item-label">{contactItem?.name}</div>
            </div>
          )}
        </div>
        <div className="item-divider" />
      </>
    );
  };

  const handleFilterAction = () => {
    handleCheckboxClick(selectedItems, contactTypeList);
    setIsModalOpen(false);
    dispatchCreatorDiscoveryWarehouse({ type: SET_LAST_INTERACTED_FILTER_NULL });
    dispatchCreatorDiscoveryWarehouse({ type: SET_SHOULD_RENDER_TRUE });
  };

  return (
    <div className="modal" style={{ cursor: "default" }}>
      <div
        className="searchable-checkbox-modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="searchable-checkbox-modal-container">
          <div className="searchable-checkbox-modal-header">Which contact options should creators have?</div>
          <div className="searchable-checkbox-modal-content">
            <div className="searchable-checkbox-modal-textbox">
              <TextField
                className="my-textfield"
                onKeyDown={handleContactTypeFilterSearch}
                variant="outlined"
                fullWidth
                hiddenLabel
                placeholder={"Search contact options"}
                size="small"
                InputProps={{ className: "tag-filter-input", startAdornment: <i class="ri-search-line"></i> }}
                onClick={(e) => e.stopPropagation()}
                onChange={handleContactTypeFilterSearch}
                sx={{
                  borderColor: "var(--main-primary)",
                }}
              />
            </div>
            <div
              className="searchable-checkbox-modal-contact-list-container"
              style={filteredContactList?.length > 5 ? { background: "linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 20%)" } : {}}
            >
              {filteredContactList &&
                filteredContactList.map((contact, idx) => {
                  return <ContactListItem key={idx} idx={idx} contactItem={contact} />;
                })}
            </div>
          </div>
          <div className="searchable-checkbox-modal-footer">
            <div className="searchable-checkbox-modal-action">
              <PrimaryButton label={"Done"} onClick={handleFilterAction} disabled={selectedItems.length === 0} />
            </div>
            {selectedItems.length > 1 && (
              <div className="searchable-checkbox-modal-footer-text">We’ll get creators who have at least 1 of the selected contact options.</div>
            )}
          </div>
        </div>
        <div className="close-icon-container">
          <Icons.close_icon onClick={handleFilterAction} cursor="pointer" />
        </div>
      </div>
    </div>
  );
};

export default SearchableCheckBoxModal;
