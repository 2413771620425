import React from "react";
import { Route, Routes } from "react-router-dom";
import { CampaignLinks, Measurement, UserLogsAnalytics } from "../pages";
import Promocode from "../pages/ProductsPage/Promocode";

export const RouteKeys = {
    CAMPAIGN_LINKS: 'campaign-links',
    PROMOCODES: 'promocodes',
    // USER_LOGS: 'user-logs'
};

export default function MeasurementRoutes() {
    return (
        <Routes path="">
            <Route path="" element={<Measurement/>}/>
            <Route path="*" element={<Measurement/>}/>
            <Route path={`/${RouteKeys.CAMPAIGN_LINKS}`} element={<CampaignLinks/>}/>
            <Route path={`/${RouteKeys.PROMOCODES}`} element={<Promocode/>}/>
            {/*<Route path="/user-logs" element={<UserLogsAnalytics />} />*/}
        </Routes>
    );
}
