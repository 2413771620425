import React from "react";
import PropTypes from 'prop-types';
import "./InvertedPrimaryButton.scss";
import Loader from "../Loader/Loader";

export default function InvertedPrimaryButton({
  label,
  className = "",
  icon,
  loading = false,
  ...otherProps
}) {
  return (
    <button
      type="primary"
      className={`inverted-button-container ${className}`}
      {...otherProps}
    >
        <>
          {loading && <Loader />}
          {icon}
          {label}
        </>
    </button>
  );
}


InvertedPrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
};

InvertedPrimaryButton.defaultProps = {
  className: "InvertedPrimaryButton",
  onClick: () => { },
};