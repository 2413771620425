import React, { useState, useRef } from "react";
import "./PlatformSelectionDropdown.scss";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControl, ListItemIcon } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
    width: 38,
  },
}));

const PlatformSelectionDropdown = ({ platformList, selectedPlatform, setSelectedPlatform, onChangeHandle = () => {}, selectContainerClass = '' }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleChange = (event) => {
    setSelectedPlatform(event.target.value);
    sessionStorage.setItem(`${selectedPlatform}Url`, JSON.stringify(window.location.href))
    onChangeHandle(event.target.value);
  };

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  }

  const CustomArrowIcon = () => {
    return isOpen ? (
      <i className="ri-arrow-up-s-line" style={{ cursor: "pointer", fontSize: "20px", color: "var(--neutrals-secondary-grey)" }}></i>
    ) : (
      <i className="ri-arrow-down-s-line" style={{ cursor: "pointer", fontSize: "20px", color: "var(--neutrals-secondary-grey)" }}></i>
    );
  };

  return (
    <div className={`dropdown-container ${selectContainerClass}`} onClick={handleClick}>
      <FormControl variant="filled" sx={{ width: "100%", height: "100%" }}>
        <Select
          value={selectedPlatform}
          label="Age"
          onChange={handleChange}
          displayEmpty
          disableUnderline
          MenuProps={{
            classes: { paper: "platform-selection-menu" },
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" },
            getContentAnchorEl: null,
          }}
          renderValue={(selected) => (
            <ListItemIcon className={classes.icon} sx={{ minWidth: 0 }}>
              {platformList.find((option) => option.value === selected)?.icon}
            </ListItemIcon>
          )}
          IconComponent={CustomArrowIcon}
          ref={selectRef}
          open={isOpen}
        >
          {platformList.map((option) => (
            <MenuItem key={option.value} value={option.value} classes={{ root: "platform-selection-menu-item" }}>
              <div className="list-container">
                <ListItemIcon className={classes.icon} sx={{ minWidth: 0 }}>
                  {option.icon}
                </ListItemIcon>
                <div className="list-value">{option.label}</div>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

PlatformSelectionDropdown.propTypes = {
  platformList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      icon: PropTypes.element,
      label: PropTypes.string,
    })
  ),
  selectedPlatform: PropTypes.string,
  setSelectedPlatform: PropTypes.func,
};

export default PlatformSelectionDropdown;
