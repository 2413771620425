import { DataGrid } from '@mui/x-data-grid';
import React, { useContext, useEffect, useState } from 'react';
import { getAllCampaignsContents } from '../../api/CampaignTrackingAPI/CampaignTrackingAPI';
import { WarehouseContext } from '../../storage/context';
import { ContextualMenu, ModalDialog, PostsImage, UnlockFullAccessPopUp } from '../index';
import {
    connectionsStatus,
    DATE_TIME_FORMAT,
    DONT_REMOVE,
    REMOVE_POST_MESSAGE,
    REMOVE_POST_TITLE,
    REMOVE_TEXT,
    WORKPLATFORM_ID_MAPPING,
} from '../../constants/constants';
import './ContentGrid.scss';
import { formatNumber, getNewImageUrl } from '../../utils/profileHelpers';
import {
    CONTACT_US,
    ContentGridColumns,
    LAST_UPDATE_FAILED,
    MORE_OPTIONS,
    NO_POSTS_FOUND,
    REMOVE_FROM_CAMPAIGN
} from '../../constants/campaignTrackingConstants';
import { CustomFooter, getSortedHeaderClass } from '../../utils/DataGridUtils';
import { convertTimeToLocale, isEmpty } from '../../utils/util';
import { Colors } from '../../styles/colors';
import { trackEvent, TRACKING_CAMPAIGN_NEW_PAGE_CLICKED } from '../../analytics';

export default function ContentGrid({
                                        onRowClick,
                                        onRemoveContent,
                                        campaignId,
                                        filterBody,
                                        setTotalContentCount,
                                        isTrialCampaign,
                                        isCampaignEnded,
                                        sortModel,
                                        setSortModel,
                                        defaultSortModel
                                    }) {
    const allowedSorts = ['desc', 'asc'];
    const PAGE_SIZE = 10;
    const ROW_HEIGHT = 132;

    const { warehouse } = useContext(WarehouseContext);
    const [rows, setRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(0); // Adjusted to 0-based indexing
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [showRemoveFromCampaign, setShowRemoveFromCampaign] = useState(false);
    const [selectedContentId, setSelectedContentId] = useState(null);
    const [modalDetails, setModalDetails] = useState({
        isModalVisible: false,
        modalInfo: '',
    });

    useEffect(() => {
        fetchData().then(() => {
        });
    }, [pageNumber, sortModel]);

    useEffect(() => {
        if (!!!filterBody) {
            fetchData().then(() => {
            });
        }
    }, [filterBody]);

    async function fetchData() {
        try {
            let sort_by = null,
                sort_desc = null;
            if (sortModel.length === 1) {
                sort_by = sortModel[0].field;
                sort_desc = sortModel[0].sort === 'desc';
            }
            setLoading(true);

            const res = await getAllCampaignsContents({
                offset: pageNumber * PAGE_SIZE,
                limit: PAGE_SIZE,
                campaignId,
                filterBody,
                sort_by,
                sort_desc,
                warehouse,
            });
            const { data, total_count } = res;
            setTotalContentCount(total_count);
            // id, published_at, like_count, comment_count, view_count, engagement_rate, work_platform_id,
            // thumbnail_url, profile_username, profile_is_verified, sync_status, synced_at
            const filteredData = data.filter((d) => {
                return !(d.sync_status === connectionsStatus.IN_PROGRESS && d.synced_at === null);
            });
            setRows(filteredData);
            setTotalRows(total_count);
        } catch (e) {
            // TODO show error screen
            console.error('Error fetching data:', e);
        } finally {
            setLoading(false);
        }
    }

    const handlePageChange = (newPageNumber) => {
        setPageNumber(newPageNumber); // Adjusted to 0-based indexing
        trackEvent(TRACKING_CAMPAIGN_NEW_PAGE_CLICKED, { campaign_Id: campaignId, new_page_number: newPageNumber })
    };

    // const handlePageSizeChange = (event) => {
    //     setPageSize(+event.target.value);
    //     setPageNumber(0); // Reset page number to 0 when page size changes
    // };

    function hasSyncFailed(row) {
        return row.sync_status === connectionsStatus.FAILED && !!!row.synced_at;
    }

    function renderPostedOnCell(params) {
        const {
            persistent_thumbnail_url,
            profile_username = '',
            thumbnail_url,
            title = '',
            work_platform_id,
            published_at,
            type
        } = params.row;

        const date = (
            <span className={'post-subtitle'}>
                {!!published_at ?
                    <span className={'body-r subtitle-date'}>
                        {convertTimeToLocale(published_at, DATE_TIME_FORMAT)}
                    </span> : ''}
                {hasSyncFailed(params.row) ? (
                    <span className={'subtitle-m span-sync-status'}>
                    <i className='ri-alert-fill' style={{ width: 20, height: 20 }}></i>
                        {LAST_UPDATE_FAILED}
                  </span>
                ) : null}
            </span>
        );

        const t_url = isEmpty(persistent_thumbnail_url) ? getNewImageUrl(thumbnail_url) : getNewImageUrl(persistent_thumbnail_url);

        return (
            <PostsImage
                titleIcon={WORKPLATFORM_ID_MAPPING[work_platform_id].platformLogo}
                title={<div className={'body-m posted-on-title'}>{profile_username ?? title}</div> || ''}
                subTitle={date}
                thumbnail_url={t_url}
                type={type}
            />
        );
    }

    function handleRowClick(params) {
        const { id, row } = params;
        const { url, work_platform_id, synced_at } = row;
        if (hasSyncFailed(row)) {
            return;
        }
        // Get the ID value of the clicked row
        // Call your callback function and pass the 'id' value
        // For example, if you have a callback prop named 'onRowClick':
        if (onRowClick) {
            onRowClick(id, url, work_platform_id, synced_at);
        }
    }

    function onRemovePost() {
        if (!!!campaignId) {
            console.error(`Trying to delete with no campaignId ${campaignId}`);
            onClose();
            return;
        }
        if (!!!selectedContentId) {
            console.error(`Trying to delete with no selectedContentId ${selectedContentId}`);
            onClose();
            return;
        }
        onRemoveContent(campaignId, selectedContentId);
        onClose();
    }

    function onClose() {
        setShowRemoveFromCampaign(false);
        setSelectedContentId(null);
    }

    function renderLikesCell(params) {
        return (
            <div className={'cell-content body-r'}>
                {!isEmpty(params.row[ContentGridColumns.LIKES.field]) ? (
                    <>
                        <i className='ri-heart-3-line metric-icon'></i>
                        {formatNumber(params.row[ContentGridColumns.LIKES.field])}
                    </>
                ) : (
                    '-'
                )}
            </div>
        );
    }

    function renderCommentsCell(params) {
        return (
            <div className={'cell-content body-r'}>
                {!isEmpty(params.row[ContentGridColumns.COMMENTS.field]) ? (
                    <>
                        <i className='ri-chat-3-line metric-icon'></i>
                        {formatNumber(params.row[ContentGridColumns.COMMENTS.field])}
                    </>
                ) : (
                    '-'
                )}
            </div>
        );
    }

    function renderViewsCell(params) {
        return (
            <div className={'cell-content body-r'}>
                {!isEmpty(params.row[ContentGridColumns.VIEWS.field]) ? (
                    <>
                        <i className='ri-eye-line metric-icon'></i>
                        {formatNumber(params.row[ContentGridColumns.VIEWS.field])}
                    </>
                ) : (
                    '-'
                )}
            </div>
        );
    }

    function renderEngagementRateCell(params) {
        return <div
            className={'cell-content body-r'}>
            {!!params.row[ContentGridColumns.ENGAGEMENT_RATE.field] ? <>{`${params.row[ContentGridColumns.ENGAGEMENT_RATE.field]}%`}</> : '-'}
        </div>;
    }

    function renderActionsCell(params) {
        let firstItem = MORE_OPTIONS;
        let menuItems = [
            {
                icon: (
                    <span className={'body-m'} style={{ color: Colors.semanticsPrimaryError }}>
            <i className='ri-delete-bin-fill'></i>
          </span>
                ),
                text: (
                    <span className={'body-m'} style={{ color: Colors.semanticsPrimaryError }}>
            {REMOVE_FROM_CAMPAIGN}
          </span>
                ),
                disabled: isCampaignEnded,
                onClick: () => {
                    setSelectedContentId(params.id);
                    setShowRemoveFromCampaign(true);
                },
            },
        ];

        return (
            <div
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <ContextualMenu firstItem={firstItem} menuItems={menuItems}/>
            </div>
        );
    }

    const commonHeaderProps = {
        flex: 1,
        headerClassName: 'subtitle-m mui-data-grid-header hideRightSeparator',
    };

    const columns = [
        {
            field: 'id',
            headerName: ContentGridColumns.ID.header,
            hide: true,
        },
        {
            ...commonHeaderProps,
            align: 'left',
            field: ContentGridColumns.POSTED_ON.field,
            headerAlign: 'left',
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, ContentGridColumns.POSTED_ON.field)}`,
            headerName: ContentGridColumns.POSTED_ON.header,
            minWidth: 380,
            renderCell: renderPostedOnCell,
            sortable: true,
        },
        {
            ...commonHeaderProps,
            align: 'left',
            field: ContentGridColumns.LIKES.field,
            headerAlign: 'left',
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, ContentGridColumns.LIKES.field)}`,
            headerName: ContentGridColumns.LIKES.header,
            minWidth: 20,
            renderCell: renderLikesCell,
            sortable: true,
        },
        {
            ...commonHeaderProps,
            align: 'left',
            field: ContentGridColumns.COMMENTS.field,
            headerAlign: 'left',
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, ContentGridColumns.COMMENTS.field)}`,
            headerName: ContentGridColumns.COMMENTS.header,
            minWidth: 10,
            renderCell: renderCommentsCell,
            sortable: true,
        },
        {
            ...commonHeaderProps,
            align: 'left',
            field: ContentGridColumns.VIEWS.field,
            headerAlign: 'left',
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, ContentGridColumns.VIEWS.field)}`,
            headerName: ContentGridColumns.VIEWS.header,
            minWidth: 20,
            renderCell: renderViewsCell,
            sortable: true,
        },
        {
            ...commonHeaderProps,
            align: 'right',
            field: ContentGridColumns.ENGAGEMENT_RATE.field,
            headerAlign: 'right',
            headerClassName: `${commonHeaderProps.headerClassName} ${getSortedHeaderClass(sortModel, ContentGridColumns.ENGAGEMENT_RATE.field)}`,
            headerName: ContentGridColumns.ENGAGEMENT_RATE.header,
            minWidth: 20,
            renderCell: renderEngagementRateCell,
            sortable: true,
        }
    ];

    !isTrialCampaign && columns.push(
        {
            ...commonHeaderProps,
            align: 'right',
            field: ContentGridColumns.ACTIONS.field,
            headerAlign: 'right',
            headerName: ContentGridColumns.ACTIONS.header,
            minWidth: 160,
            renderCell: renderActionsCell,
            sortable: false,
        }
    );

    return (
        <div className={'content-data-grid-container'}>
            <DataGrid
                className={'mui-data-grid'}
                columns={columns}
                components={{
                    Footer: (props) => <CustomFooter totalRows={totalRows} pageSize={PAGE_SIZE}
                                                     handlePageChange={handlePageChange} pageNumber={pageNumber}/>,
                }}
                disableColumnMenu
                getRowHeight={() => ROW_HEIGHT}
                getRowId={(row) => row.id}
                initialState={{
                    sorting: {
                        sortModel: defaultSortModel,
                    },
                }}
                loading={isLoading}
                onPageChange={handlePageChange}
                // onPageSizeChange={handlePageSizeChange}
                onRowClick={handleRowClick}
                onSortModelChange={setSortModel}
                page={pageNumber}
                pageSize={PAGE_SIZE}
                pagination
                paginationMode={'server'}
                rowCount={totalRows}
                // rowsPerPageOptions={[5, 10, 25, 50, 100]}
                sortModel={sortModel}
                sortingMode={'server'}
                sortingOrder={allowedSorts}
                sx={{
                    '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                }}
                rows={rows}
                localeText={{
                    noRowsLabel: (
                        <span className={'body-m'} style={{ color: Colors.neutralsSecondaryGrey }}>
              {NO_POSTS_FOUND}
            </span>
                    ),
                }}
            />
            {showRemoveFromCampaign ? (
                <ModalDialog
                    variant={'remove'}
                    cancelText={DONT_REMOVE}
                    buttonText={REMOVE_TEXT}
                    title={REMOVE_POST_TITLE}
                    content={REMOVE_POST_MESSAGE}
                    onAction={onRemovePost}
                    onClose={onClose}
                    onDont={onClose}
                />
            ) : null}
            <UnlockFullAccessPopUp
                open={modalDetails.isModalVisible}
                onClose={() => {
                    setModalDetails({ ...modalDetails, isModalVisible: false });
                }}
                header={modalDetails.modalInfo.header}
                description={modalDetails.modalInfo.description}
                actionButtonLabel={CONTACT_US}
                height='264px'
                padding='52px 52px 48px 48px'
                isModal
            />
        </div>
    );
}
