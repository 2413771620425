import { Avatar, Box, Divider, Grid, LinearProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./AccountAudienceData.scss";
import { Chart, PieSeries, Title } from "@devexpress/dx-react-chart-material-ui";
import { getAudienceDemographics } from "../../api/profileView/profileView";
import { Palette } from "@devexpress/dx-react-chart";
import { POLL_DURARION, POLL_INTERVAL } from "../../constants/profileViewConstants";
import Icons from "../Icons/Icons";

const scheme = ["#f9b90b", "#2262ed", "#ff7d50"];

function AccountAudienceData({ demographics }) {
  const [topCountries, setTopCountries] = useState([]);
  const [topCities, setTopCities] = useState([]);
  const [genderDetails, setGenderDetails] = useState([]);
  const [ageDetails, setAgeDetails] = useState([]);
  // const [demographics, setDemoGraphics] = useState(null);
  const isCompMounted = useRef(true);
  const pollId = useRef();
  const pollStartTime = useRef();
  // get top 3 countries
  function getTopCountries() {
    let topCountries = demographics.countries
      .sort(function (a, b) {
        return b.value - a.value;
      })
      .slice(0, 3);
    setTopCountries(topCountries);
  }

  // get top 3 cities
  function getTopCities() {
    let topCities = demographics.cities
      .sort(function (a, b) {
        return b.value - a.value;
      })
      .slice(0, 3);
    setTopCities(topCities);
  }

  function getGenderDetails() {
    let genderDetails = [
      { argument: "Male", value: 0, color: "#F9B90B" },
      { argument: "Female", value: 0, color: "#2262ED" },
      { argument: "Others", value: 0, color: "#FF7D50" },
    ];
    let ageDetails = [
      { argument: "13 - 17", value: 0 },
      { argument: "18 - 24", value: 0 },
      { argument: "25 - 34", value: 0 },
      { argument: "> 35", value: 0 },
    ];
    demographics.gender_age_distribution.map((info) => {
      info.gender === "MALE"
        ? (genderDetails[0].value += info.value)
        : info.gender === "FEMALE"
        ? (genderDetails[1].value += info.value)
        : (genderDetails[2].value += info.value);
    });
    demographics.gender_age_distribution.map((info) => {
      info.age_range === "13-17"
        ? (ageDetails[0].value += info.value)
        : info.age_range === "18-24"
        ? (ageDetails[1].value += info.value)
        : info.age_range === "25-34"
        ? (ageDetails[2].value += info.value)
        : (ageDetails[3].value += info.value);
    });
    setAgeDetails(ageDetails);
    setGenderDetails(genderDetails);
  }

  // async function getDemographics() {
  //   const currDemographics = await getAudienceDemographics(selectedPlatform);
  //   if (isCompMounted.current) setDemoGraphics(currDemographics);
  // }

  // useEffect(() => {
  //   isCompMounted.current = true;
  //   if (selectedPlatform) {
  //     getDemographics();
  //     pollStartTime.current = Date.now();
  //     pollId.current = window.setInterval(() => {
  //       if (Date.now() - pollStartTime.current < POLL_DURARION)
  //         getDemographics();
  //       else window.clearInterval(pollId.current);
  //     }, POLL_INTERVAL);
  //   }

  //   return () => {
  //     isCompMounted.current = false;
  //     window.clearInterval(pollId.current);
  //   };
  // }, [selectedPlatform]);

  useEffect(() => {
    if (demographics && Object.keys(demographics).length !== 0) {
      getTopCountries();
      Object.keys(demographics.cities).length !== 0 && getTopCities();
      getGenderDetails();
    }
  }, [demographics]);

  return (
    <>
      {demographics &&
        Object.keys(demographics).length !== 0 &&
        demographics.countries.length > 0 &&
        demographics.gender_age_distribution.length > 0 && (
          <Box className="audience-container">
            <Box className="mainHeading">
              <div
                style={{
                  marginRight: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icons.audience_icon />
              </div>
              Audience
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                rowGap: "16px",
                columnGap: "72px",
              }}
              className="audienceInfo"
            >
              {/* top Countries destribution  */}
              <Box>
                <Box className="tabHeading">Top countries</Box>
                {topCountries.map((country, index) => (
                  <Box key={index} className="countryInfo">
                    <Box>
                      <span className="circle-container">{index + 1}</span>
                    </Box>
                    <Box className="countryName">{new Intl.DisplayNames(["en"], { type: "region" }).of(country.code)}</Box>
                    <Box className="dottedLine"></Box>
                    <Box sx={{ color: "#2262ED", fontWeight: "600" }}>
                      {country.value.toFixed(1)}
                      <p style={{ display: "inline", fontSize: "13px" }}>%</p>
                    </Box>
                  </Box>
                ))}
                <Box className="countryInfo">
                  <Box>
                    <span className="circle-container">{4}</span>
                  </Box>
                  <Box className="countryName">Others</Box>
                  <Box className="dottedLine"></Box>
                  <Box sx={{ color: "#2262ED", fontWeight: "600" }}>
                    {Number(
                      100 -
                        topCountries.reduce((valueSum, value) => {
                          return (valueSum += value.value);
                        }, 0)
                    ).toFixed(1)}
                    <p style={{ display: "inline", fontSize: "13px" }}>%</p>
                  </Box>
                </Box>
              </Box>
              {/* top cities destribution  */}
              {topCities.length !== 0 && (
                <Box>
                  <Box className="tabHeading">Top cities</Box>
                  {topCities.map((city, index) => (
                    <Box key={index} className="countryInfo">
                      <Box>
                        <span className="circle-container">{index + 1}</span>
                      </Box>
                      <Box className="countryName">{city.name.split(",")[0]}</Box>
                      <Box className="dottedLine"></Box>
                      <Box sx={{ color: "#2262ED", fontWeight: "600" }}>
                        {city.value.toFixed(1)}
                        <p style={{ display: "inline", fontSize: "13px" }}>%</p>
                      </Box>
                    </Box>
                  ))}
                  <Box className="countryInfo">
                    <Box>
                      <span className="circle-container">{4}</span>
                    </Box>
                    <Box className="countryName">Others</Box>
                    <Box className="dottedLine"></Box>
                    <Box sx={{ color: "#2262ED", fontWeight: "600" }}>
                      {Number(
                        100 -
                          topCities.reduce((valueSum, value) => {
                            return (valueSum += value.value);
                          }, 0)
                      ).toFixed(1)}
                      <p style={{ display: "inline", fontSize: "13px" }}>%</p>
                    </Box>
                  </Box>
                </Box>
              )}
              {/* Top Age distribution  */}
              <Box style={{ marginRight: "23px" }}>
                <Box className="tabHeading">Age distribution</Box>
                {ageDetails.map((age, index) => (
                  <Box key={index} className="countryInfo" sx={{ justifyContent: "center" }}>
                    <Box className="countryName" sx={{ minWidth: "62px", justifyContent: "center" }}>
                      {age.argument}
                    </Box>
                    <Box sx={{ width: "189px", margin: "0px 16px" }}>
                      <LinearProgress
                        sx={{
                          borderRadius: "10px",
                          height: "6px",
                          backgroundColor: "#F5F5F6",
                          color: "#2262ED",
                        }}
                        variant="determinate"
                        value={age.value}
                      />
                    </Box>
                    <Box
                      sx={{
                        color: "#2262ED",
                        width: "33px",
                        fontWeight: "600",
                      }}
                    >
                      {Number(age.value).toFixed(1)}
                      <p style={{ display: "inline", fontSize: "13px" }}>%</p>
                    </Box>
                  </Box>
                ))}
              </Box>
              {/* Gender distribution  */}
              <Box>
                <Box className="tabHeading">Gender distribution</Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Chart data={genderDetails} className="pieChart">
                      <Palette scheme={scheme} />
                      <PieSeries valueField="value" argumentField="argument" innerRadius={0.85} />
                    </Chart>
                  </Box>

                  <Box>
                    {genderDetails.map((gender, index) => (
                      <Box key={index} className="genderInfo">
                        <Box>
                          <span
                            className="genderCircle"
                            style={{
                              backgroundColor: `${gender.color}`,
                            }}
                          ></span>
                        </Box>
                        <Box className="countryName"> {gender.argument}</Box>
                        <Box className="dottedLine"></Box>
                        <Box sx={{ color: "#51515F", fontWeight: "600" }}>
                          {Number(gender.value).toFixed(1)}
                          <p style={{ display: "inline", fontSize: "13px" }}>%</p>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      {demographics && demographics.countries.length === 0 && demographics.gender_age_distribution.length === 0 && (
        <>
          <Box className="mainHeading">
            <div
              style={{
                marginRight: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icons.audience_icon />
            </div>
            Audience
          </Box>
          <Box className="no-content-message">
            <Icons.no_content_icon />
            <p>We don’t have the details for this account.</p>
          </Box>
        </>
      )}
    </>
  );
}

export default AccountAudienceData;
