import {
  Avatar,
  Box,
  CardMedia,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Tweet } from "react-twitter-widgets";
import Icons from "../Icons/Icons";
import "./TwitterContentData.scss";

function TwitterContentData({ content, title, titleIcon, cols }) {
  const scrollContainer = useRef();
  return (
    <Box
      className="twitter-content-grid-container"
    >
      
      {content ?
        <ImageList variant="masonry" cols={cols} gap={8}>
          {content?.data.map((content) => (
            <ImageListItem key={content.url}>
              <Tweet tweetId={content.url.split("/")[5]} />
            </ImageListItem>
          ))}
        </ImageList> :
        <Box className="no-content-message">
          <Icons.no_content_icon />
          <p>Oops, no content to show here.</p>
        </Box>}
    </Box>
  );
}

export default TwitterContentData;
