import {
  campaignTrackingProductID,
  compareCreatorProductID,
  creatorLinkageProductID,
  creatorModuleProductMapping,
  creatorSearchProductID,
  trialTopHeaderInfoCreator,
} from "../constants/constants";
import parse from "html-react-parser";

export const parseTenantUsageQuotaInfoPerModule = (data) => {
  const quotaInfoCreatorModule = {};
  const quotaInfoDeveloperModule = {}; // Not required as of now
  data.forEach((item) => {
    if (item.product_category === "DASHBOARD_PRODUCT") {
      quotaInfoCreatorModule[item.product_attribute] = {
        usage: item.usage,
        max_limit: item.max_limit,
      };
    }
    if (item.product_category === "API_PRODUCT") {
      quotaInfoDeveloperModule[item.product_attribute] = {
        usage: item.usage,
        max_limit: item.max_limit,
      };
    }
  });
  return quotaInfoCreatorModule;
};

export const parseTenantAppProducts = (data) => {
  const tenantAppProductsInfo = {};
  data.forEach((item) => {
    tenantAppProductsInfo[item.product_id] = item.subscription_status;
  });
  return tenantAppProductsInfo;
};

export const parseTenantAppProductsForAnalytics = (data) => {
  const tenantAppProductsInfo = {};
  data.forEach((item) => {
    tenantAppProductsInfo[creatorModuleProductMapping[item.product_id]] = item.subscription_status;
  });
  return tenantAppProductsInfo;
};
export const getTopHeaderNavTextAsPerUsage = (usageObj, productID) => {
  if (productID === creatorSearchProductID) {
    const maxLimit = usageObj["PROFILE_ANALYTICS"].max_limit;
    const usage = usageObj["PROFILE_ANALYTICS"].usage;
    const headerText = trialTopHeaderInfoCreator.creatorSearchProductID;
    if (usage === 0) return parse(headerText.intro.replace("MAX_LIMIT", maxLimit));
    if (usage > 0 && usage < maxLimit)
      return parse(headerText.currentUsage.replace("USAGE", maxLimit - usage + " more " + (maxLimit - usage > 1 ? "creators" : "creator")));
    if (usage === maxLimit) return parse(headerText.fullUsage);
  }
  if (productID === creatorLinkageProductID) {
    const maxLimit = usageObj["ACCOUNTS_CONNECTED"].max_limit;
    const usage = usageObj["ACCOUNTS_CONNECTED"].usage;
    const headerText = trialTopHeaderInfoCreator.creatorLinkageProductID;
    if (usage === 0) return parse(headerText.intro.replace("MAX_LIMIT", maxLimit));
    if (usage > 0 && usage < maxLimit) return parse(headerText.currentUsage.replace("USAGE", maxLimit - usage));
    if (usage === maxLimit) return parse(headerText.fullUsage);
  }
  if (productID === campaignTrackingProductID) {
    const headerText = trialTopHeaderInfoCreator.campaignTrackingProductID;
    return parse(headerText.intro);
  }
  if (productID === compareCreatorProductID) {
    const headerText = trialTopHeaderInfoCreator.compareCreatorProductID;
    return parse(headerText.intro);
  }
};
