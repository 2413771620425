import React, { useEffect, useState } from "react";
import ApiSearchableDropdown from "../ApiSearchableDropdown/ApiSearchableDropdown";
import SliderFilter from "../SliderFilter/SliderFilter";
import { Box } from "@mui/material";
import { object } from "prop-types";

function ApiSearchableDropdownSlider({
  defaultValue = [],
  steps,
  multi = true,
  defaultSliderValue,
  getFilterValue,
  placeholder,
  handlePostSearch,
  filterKey,
}) {
  const [selectedValues, setSelectedValues] = useState(defaultValue);

  useEffect(() => {
    // default value is an array but selectedValues are objects with ids
    if (defaultValue && defaultValue.length > 0) {
      const val = {};
      defaultValue.forEach((element) => {
        val[element.id] = element;
      });
      setSelectedValues(val);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (Object.keys(selectedValues).length >= 0) {
      if (getFilterValue) {
        // const value = Object.values(selectedValues).map((item) => {
        //   return {
        //     location_id: item.id,
        //     percentage_value: item.percentage_value,
        //   };
        // });
        getFilterValue(selectedValues);
      }
    }
  }, [selectedValues]);

  const handleOnCloseSliderFilter = (name) => {
    const newValues = Object.values(selectedValues).filter((item) => item.name !== name);
    const updatedObj = {};
    newValues.forEach((item) => (updatedObj[item.id] = item));
    setSelectedValues(updatedObj);
  };

  const handleOnSliderGetFilter = (percentage_value, id) => {
    const updatedValues = Object.values(selectedValues).map((item) => {
      if (item.id === id) {
        item.percentage_value = percentage_value;
        return item;
      }
      return item;
    });
    const updatedObj = {};
    updatedValues.forEach((item) => (updatedObj[item.id] = item));
    setSelectedValues(updatedObj);
  };
  const getSelectedValue = (value) => {
    setSelectedValues((prev) => {
      let updatedValueObj = {};
      value.forEach((item) => {
        updatedValueObj[item.id] = item;
        if (prev[item.id]) updatedValueObj[item.id].percentage_value = prev[item.id].percentage_value;
        else updatedValueObj[item.id].percentage_value = defaultSliderValue;
      });

      return updatedValueObj;
    });
  };
  return (
    <>
      <ApiSearchableDropdown
        placeholder={placeholder}
        getFilterValue={getSelectedValue}
        showTags={false}
        handlePostSearch={handlePostSearch}
        defaultValue={Object.values(selectedValues)}
        filterKey={filterKey}
      />
      {Object.keys(selectedValues)?.length > 0 && (
        <Box sx={{ marginTop: "24px" }}>
          {Object.keys(selectedValues)?.length > 0 &&
            Object.values(selectedValues).map((v, idx) => (
              <SliderFilter
                defaultSliderValue={v?.percentage_value}
                sliderFilterHeader={v}
                sx={{ marginTop: "12px" }}
                isMultipleFilter={multi}
                onCloseFilter={handleOnCloseSliderFilter}
                filterKey={filterKey}
                steps={steps}
                getFilterValue={handleOnSliderGetFilter}
              />
            ))}
        </Box>
      )}
    </>
  );
}

export default ApiSearchableDropdownSlider;
