import React, { useContext, useEffect, useState } from "react";
import { WarehouseContext } from "../../storage/context";
import {
  CSV_EXPORT_LIMIT,
  PROMOCODES_GENERATED_TEXT,
  PROMOCODES_PLACEHOLDER,
  PROMOCODES_TABLE_CONTENT,
  PROMOCODES_USED_TEXT,
  PROMOCODE_DROPDOWN_LABEL,
  TOTAL_SALES_TEXT,
  getTagsAdditionalText,
  oncePerCustomer,
  MIN_QTY,
  PROMOCODE_STATUS_MAPPING,
  EXPIRED,
  AMOUNT,
  formatCurrency,
  PERCENTAGE,
  COLLECTIONS,
  ITEM,
  createdOnHeader,
  MINIMUM_QUANTITY,
  AMOUNT_TYPE_DESC,
  PERCENTAGE_TYPE_DESC, UPDATED_PERIODICALLY,
  calendarTabSwitchLabels,
  DATE_TIME_FORMAT_WITHOUT_TIME,
  NO_PROMOCODES_AVAILABLE,
  WE_WILL_SHOW_THEM,
  CUSTOM_DATE_LABEL,
  WE_COULDNT_FIND_ANY_PROMOCODES, MEASUREMENT_PRODUCTS_API_DATE_FORMAT
} from "../../constants/constants";
import { MeasurementsPageTemplate } from "../../components";
import { getAllPromocodes, exportPromocodeCsv, getAllStores, getMeasurementProduct, getPromocodesMetrics } from "../../api/products/products";
import "./Promocode.scss";
import { convertTimeToLocale, getShortURL, isEmpty } from "../../utils/util";
import { dotSymbol } from "../../constants/campaignTrackingConstants";
import { format, subMonths } from 'date-fns';
import { getDateRange } from "../../components/CustomDatePicker/CustomDatePicker";
import { useNavigate } from "react-router-dom";
import { RouteKeys } from "../../routes/ProductsRoutes";

const commonHeaderProps = {
  flex: 1,
  headerClassName: "subtitle-m mui-data-grid-header hideRightSeparator",
};

const Promocode = () => {
  const [totalRows, setTotalRows] = useState(null);
  const [rows, setRows] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [isMetricsLoading, setMetricsLoading] = useState(true);
  const PAGE_SIZE = 10;
  const [searchText, setSearchText] = useState("");
  const { warehouse } = useContext(WarehouseContext);
  const { PROMOCODE_HEADER, STATUS_HEADER, GROUP_HEADER, TAGS_HEADER } = PROMOCODES_TABLE_CONTENT;
  const [stores, setStores] = useState(null);
  const [selectedStore, setSelectedStore] = useState("");
  const [isHeaderLoading, setHeaderLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [promocodeMetrics, setPromocodeMetrics] = useState({});
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [customDate, setCustomDate] = useState(CUSTOM_DATE_LABEL);
  const defaultDate = calendarTabSwitchLabels(customDate)[2].label;
  const [selectedDayTab, setSelectedDayTab] = useState(defaultDate);
  const months = parseInt(defaultDate.slice(0, -1), 10);
  const [dateRange, setDateRange] = useState([subMonths(new Date(), months), format(new Date(), MEASUREMENT_PRODUCTS_API_DATE_FORMAT)]);
  const navigate = useNavigate();

  function getPromocodesDesc(currency, usage_limit, once_per_customer, type, value, collection_ids = null, product_ids = null, fromTable = false) {
    let discount;
    if (type === AMOUNT) {
      const formatedValue = formatCurrency(value, currency);
      discount = AMOUNT_TYPE_DESC(formatedValue);
    }
    if (type === PERCENTAGE) {
      const collectionLen = collection_ids?.length > 0 ? `${collection_ids.length} ${COLLECTIONS(collection_ids.length)}` : '';
      const productLen = product_ids?.length > 0 ? `${product_ids.length} ${ITEM(product_ids.length)}` : '';
      discount = PERCENTAGE_TYPE_DESC(value, collectionLen, productLen);
    }
    return <>
      <span>{discount}</span>

      {once_per_customer ? <span>{dotSymbol + oncePerCustomer}</span> : null}

      {(type === PERCENTAGE && once_per_customer) ? null : (
        <span>{usage_limit ? `${dotSymbol} ${fromTable ? MIN_QTY : MINIMUM_QUANTITY} ${usage_limit}` : null}</span>
      )}
    </>;
  };

  function renderpromocodesCell(params) {
    const { discount_code, usage_limit, once_per_customer, type, value, valid_for, currency } = params.row;
    let collection_ids, product_ids;
    if (valid_for) {
      collection_ids = valid_for.collection_ids;
      product_ids = valid_for.product_ids;
    }

    return (
      <div className="promocode-link-cell">
        <div className="body-m text-truncate">{isEmpty(discount_code) ? "-" : discount_code}</div>
        <div className="body-r text-truncate color-neutrals-secondary-grey promocode-quantities">
          {getPromocodesDesc(currency, usage_limit, once_per_customer, type, value, collection_ids, product_ids, true)}
        </div>
      </div>
    );
  };

  function getStatusValue(status, valid_till) {
    status = new Date(valid_till) < new Date() ? EXPIRED : status;
    return isEmpty(status) ? "-" : PROMOCODE_STATUS_MAPPING[status];
  };

  function renderStatusCell(params) {
    let { status, valid_till } = params.row;
    return <div className="body-r color-neutrals-secondary-grey">{getStatusValue(status, valid_till)}</div>;
  };

  function renderGroupIdCell(params) {
    const { group } = params.row;
    return <div className="body-r color-neutrals-secondary-grey">{isEmpty(group) ? "-" : group.name}</div>;
  };

  function renderTagsCell(params) {
    const { tags } = params.row;
    if (isEmpty(tags)) {
      return '-';
    }

    return (
      tags?.length > 0 ?
        <div className={"body-r campaign-link-tags"}>
          {tags[0]} <span className="color-neutrals-secondary-grey">{getTagsAdditionalText(tags?.length - 1)}</span>
        </div>
        : null
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      ...commonHeaderProps,
      align: "left",
      field: "name",
      headerAlign: "left",
      headerName: PROMOCODE_HEADER,
      minWidth: 455,
      renderCell: renderpromocodesCell,
      sortable: false,
    },
    {
      ...commonHeaderProps,
      align: "left",
      field: "status",
      headerAlign: "left",
      headerName: STATUS_HEADER,
      minWidth: 126,
      renderCell: renderStatusCell,
      sortable: false,
    },
    {
      ...commonHeaderProps,
      align: "left",
      field: "group_id",
      headerAlign: "left",
      headerName: GROUP_HEADER,
      minWidth: 126,
      renderCell: renderGroupIdCell,
      sortable: false,
    },
    {
      ...commonHeaderProps,
      align: "left",
      field: "tags",
      headerAlign: "left",
      headerName: TAGS_HEADER,
      minWidth: 176,
      renderCell: renderTagsCell,
      sortable: false,
    },
  ];

  async function getStores() {
    try {
      setLoading(true);
      const storesResponse = await getAllStores({ warehouse });
      setStores(storesResponse?.data);
      setSelectedStore(storesResponse?.data[0]?.shop_name ?? '');
      setSelectedStoreId(storesResponse?.data[0]?.store_id ?? '');
    } catch (e) {
      console.error("Error fetching data:", e);
    } finally {
      setLoading(false);
    }
  }

  async function fetchData() {
    try {
      setLoading(true);
      const res = await getAllPromocodes({
        offset: pageNumber * PAGE_SIZE,
        limit: PAGE_SIZE,
        search_string: searchText,
        warehouse,
        store_id: selectedStoreId,
        from_date: dateRange[0],
        to_date: dateRange[1],
      });
      const { data, metadata } = res;
      setRows(data);
      setTotalRows(metadata.count);
    } catch (e) {
      console.error("Error fetching data:", e);
    } finally {
      setLoading(false);
    }
  }

  async function fetchPromocodeMetrics() {
    try {
      setMetricsLoading(true);
      const res = await getPromocodesMetrics({
        warehouse,
        store_id: selectedStoreId,
        from_date: dateRange[0],
        to_date: dateRange[1],
      });
      setPromocodeMetrics(res);
    } catch (e) {
      console.error("Error fetching data:", e);
    } finally {
      setMetricsLoading(false);
    }
  }

  useEffect(() => {
    const urlParts = window.location.pathname.replace(/\/+$/, '').split('/');
    const productId = urlParts[urlParts.length - 1];
    if (!isEmpty(productId)) {
      setHeaderLoading(true);
      getMeasurementProduct(productId).then(setProduct).finally(() => setHeaderLoading(false));
    }
    getStores().then(() => { });
  }, [warehouse.environmentChanged]);

  useEffect(() => {
    if (!isEmpty(selectedStoreId)) {
      fetchPromocodeMetrics().then(() => { });
    }
  }, [selectedStoreId, dateRange, warehouse.environmentChanged]);

  function renderDropdownCell(index) {
    return stores?.filter((store, idx) => idx === index)
      .map((store) =>
        <div className="dropdown-item">
          <div>
            <img src={store?.shop_logo} height={48} width={48} />
          </div>
          <div className="dropdown-item-label">
            <div className="body-m">{store?.shop_name}</div>
            <div className="subtitle-r"><a href={store?.shop_url}>{getShortURL(store?.shop_url)}</a></div>
          </div>
        </div>
      );
  };

  function getSelectedStoreId() {
    return stores?.filter((store) => store.shop_name === selectedStore).map((store) => store.store_id)[0];
  }

  useEffect(() => {
    if (!isEmpty(selectedStoreId)) {
      fetchData().then(() => { });
    }
  }, [pageNumber, searchText, selectedStoreId, dateRange, warehouse.environmentChanged]);


  function handleRowClick(selectedRow) {
    const { row } = selectedRow;
    const { shop_url, discount_code, status, valid_till, group, tags, usage_limit, once_per_customer, type, value, valid_for, currency, created_at } = row;
    let collection_ids, product_ids;
    if (valid_for) {
      collection_ids = valid_for.collection_ids;
      product_ids = valid_for.product_ids;
    }

    const metrics = [
      {
        title: STATUS_HEADER,
        value: getStatusValue(status, valid_till)
      }, {
        title: GROUP_HEADER,
        value: group?.name
      }, {
        title: createdOnHeader,
        value: convertTimeToLocale(created_at, DATE_TIME_FORMAT_WITHOUT_TIME)
      }
    ];
    const rowData = {
      title: discount_code,
      subtitleText: getPromocodesDesc(currency, usage_limit, once_per_customer, type, value, collection_ids, product_ids),
      metrics,
      shortLink: shop_url,
      tags,
    };
    setDrawerData(rowData);
    setSidebarOpen(true);
  }

  const infoBannerProps = {
    message: UPDATED_PERIODICALLY,
  };

  useEffect(() => {
    // initial load - no need to do anything
    if (selectedDayTab === CUSTOM_DATE_LABEL) {
        return;
    }
    setDateRange(getDateRange(selectedDayTab));
  }, [selectedDayTab]);

  function handleCustomDateChange(dateLabel) {
    setCustomDate(dateLabel);
    if (dateLabel === CUSTOM_DATE_LABEL) {
        setSelectedDayTab(defaultDate);
    } else {
        setSelectedDayTab(dateLabel);
    }
  }

  return (
    <div>
      <MeasurementsPageTemplate
        headerProps={{
          isLoading: isHeaderLoading,
          title: product?.title ?? '',
          subtitle: product?.description ?? '',
          docsLink: product?.pathToPage ?? '',
          onBackClick: () => navigate(`/developers/products/${RouteKeys.ROI_MEASUREMENT}`)
        }}
        infoBannerProps={infoBannerProps}
        topMetricsProps={{
          topMetricsArray: [
            {
              metricsRemixIcon: <i className="ri-coupon-3-line promocode-metric-icon" />,
              title: promocodeMetrics.discount_code_usage_summary?.count_of_discount_codes ?? "-",
              subtitle: PROMOCODES_GENERATED_TEXT,
            },
            {
              metricsRemixIcon: <i className="ri-shopping-bag-3-line promocode-metric-icon" />,
              title: promocodeMetrics.discount_code_usage_summary?.usage_count_of_discount_codes ?? "-",
              subtitle: PROMOCODES_USED_TEXT,
            },
            {
              metricsRemixIcon: <i className="ri-money-dollar-circle-line promocode-metric-icon" />,
              title: promocodeMetrics.order_summary?.total_orders_amount_fulfilled ? formatCurrency(promocodeMetrics.order_summary?.total_orders_amount_fulfilled, promocodeMetrics.currency) : "-",
              subtitle: TOTAL_SALES_TEXT,
            },
          ],
          isLoading: isMetricsLoading
        }}
        gridProps={{
          columns,
          customProps: {
            emptyTitle: NO_PROMOCODES_AVAILABLE,
            emptySubtitle: WE_WILL_SHOW_THEM,
            noDataMessage: WE_COULDNT_FIND_ANY_PROMOCODES,
          },
          rows,
          rowCount: totalRows,
          loading: isLoading,
          page: pageNumber,
          onPageChange: setPageNumber,
          pageSize: PAGE_SIZE,
          getRowHeight: () => 100,
          onRowClick: handleRowClick,
        }}
        gridSearchProps={{
          value: searchText,
          onEnter: setSearchText,
          onClear: () => {
            setSearchText("");
            setTotalRows(null);
          },
          placeholder: PROMOCODES_PLACEHOLDER,
        }}
        sideDrawerProps={{
          isOpen: isSidebarOpen,
          onClose: () => setSidebarOpen(false),
          drawerData: drawerData,
        }}
        gridDropdownProps={{
          label: PROMOCODE_DROPDOWN_LABEL,
          menuItems: stores ?? [],
          selectedValueText: selectedStore,
          rowRenderer: renderDropdownCell,
          onItemSelect: (item) => {
            setSelectedStore(item.shop_name);
            setSelectedStoreId(item.store_id);
          }
        }}
        exportCsvProps={{
          onExportCsvClick: async () => {
            const selectedStoreId = getSelectedStoreId();
            const exportPayload = {
              limit: CSV_EXPORT_LIMIT,
              offset: 0,
              from_date: dateRange[0],
              to_date: dateRange[1],
              search_string: searchText,
            };
            return await exportPromocodeCsv({
              warehouse,
              store_id: selectedStoreId,
              exportPayload
            });
          },
          defaultFileName: 'promocodes.csv',
        }}
        datePickerProps={{
          selectedDayTab,
          customDate,
          setSelectedDayTab,
          daysArray: calendarTabSwitchLabels(customDate),
          onDateChanged : handleCustomDateChange
        }}
        showEmptyScreen={stores?.length === 0}
      />
    </div>
  );
};

export default Promocode;
