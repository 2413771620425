import { Box, Skeleton } from '@mui/material';
import React from 'react';
import './CreatorProfileSectionBar.scss';

function CreatorProfileSectionBar({
  sectionBarLabels,
  handleTabHover,
  handleMouseTabLeave,
  handleTabClick,
  activeTabIndex,
  activeTabHoveredState,
  isLoading,
  isSticky,
}) {

  function handleTabHoverState(idx) {
    if (activeTabIndex === idx || activeTabHoveredState === idx) {
      return {
        visibility: 'visible',
        color: 'var(--main-primary)',
        class: 'active',
      };
    } else {
      return {
        visibility: 'hidden',
      };
    }
  }

  return (
    <div className='creator-discovery-profile-sections' id={isSticky ? 'sticky-element' : ''}>
      {isLoading ? (
        <div className='creator-profile-loading-state-container'>
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} width={133} height={60} variant='rectangular' />
          ))}
        </div>
      ) : (
        sectionBarLabels?.map((profileSection, idx) => {
          return (
            <div
              className={`creator-discovery-profile-section-item ${handleTabHoverState(idx)?.class}`}
              key={idx}
              onMouseEnter={() => handleTabHover(idx)}
              onMouseLeave={handleMouseTabLeave}
              onClick={() => handleTabClick(profileSection.label, idx)}
            >
              {profileSection.label}
              <div
                style={{
                  width: profileSection.footerWidth,
                  ...handleTabHoverState(idx),
                }}
                className='creator-discovery-profile-section-footer'
              />
            </div>
          );
        })
      )}
    </div>
  );
}

export default CreatorProfileSectionBar;
