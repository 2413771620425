import { resolve } from "@sentry/utils";
import { tenantInfoApi } from "..";
import { GETTING_STARTED_ACCOUNT_CONNECT_SUCCESS, GETTING_STARTED_STEP_3_DATA_LOAD, trackEvent } from "../../analytics";
import { ADD_TENANT_INFORMATION } from "../../storage/reducers/types";
import openPhylloSDK from "../../utils/phylloSdk";
import { createWorkplatformMapping, flattenObj, getClientIDAndSecret } from "../../utils/util";
import { getBasicAuthInstance } from "../axiosInstance";
import { URL_CREATE_USER, URL_CREATE_USER_TOKEN, URL_GET_COMMENTS, URL_GET_CONTENTS, URL_GET_INCOME, URL_GET_PROFILE } from "../endpoints";
let env;

const getAllUsers = async (tenantInfo, environmentBaseURL, environment) => {
  try {
    const clientId = tenantInfo[0]?.apps[0].id;
    const clientSecrets = tenantInfo[0]?.apps[0].credentials;
    const clientSecret = clientSecrets[0]?.client_secret;
    // var { clientId, clientSecret } = await runPollingLogicAPIInLocal(tenantInfo, environment.current);
    const api = getBasicAuthInstance(clientId, clientSecret, environmentBaseURL);
    let response = await api.get(`${URL_CREATE_USER}?limit=10`);
    return response;
  } catch (err) {
    throw err;
  }
};

const handleCreateUser = async (environment, warehouse, dispatchWarehouse) => {
  let isUserIdAlreadyPresent = false;
  var { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
  // var { clientId, clientSecret } = await runAllEnvsAPIsInLocal(environment);
  if (!JSON.parse(localStorage.getItem("SDK_USER_CREDS"))) {
    const SDK_USER_CREDS = {};
    localStorage.setItem("SDK_USER_CREDS", JSON.stringify(SDK_USER_CREDS));
  }
  env = environment;
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const username = userDetails.firstName;
  const timeStamp = new Date();
  let userId;
  const user_sdk_creds = JSON.parse(localStorage.getItem("SDK_USER_CREDS"));
  if (Boolean(user_sdk_creds.userId)) {
    userId = user_sdk_creds.userId;
    isUserIdAlreadyPresent = true;
  } else {
    userId = await createUser(username, timeStamp.getTime(), clientId, clientSecret, warehouse, dispatchWarehouse);
    user_sdk_creds.userId = userId;
  }
  const userToken = await createUserToken(userId, clientId, clientSecret, warehouse, dispatchWarehouse);
  user_sdk_creds.userToken = userToken;
  localStorage.setItem("SDK_USER_CREDS", JSON.stringify(user_sdk_creds));
  return isUserIdAlreadyPresent;
};

const handleGenerateSDK = (environment, setAccountId, setLoader, setButtonLoading, dispatchWarehouse, setCurrStep, warehouse) => {
  let userCreds = JSON.parse(localStorage.getItem("user"));
  let user_sdk_creds = JSON.parse(localStorage.getItem("SDK_USER_CREDS"));
  let appName = warehouse.tenantInfo[0]?.apps[0]?.name ? warehouse.tenantInfo[0]?.apps[0]?.name : userCreds.companyName;
  openPhylloSDK(
    appName,
    environment,
    user_sdk_creds.userId,
    user_sdk_creds.userToken,
    setAccountId,
    setLoader,
    setButtonLoading,
    dispatchWarehouse,
    setCurrStep,
    warehouse
  );
};

const createUser = async (username, externalId, CLIENT_ID, CLIENT_SECRET, warehouse, dispatchWarehouse) => {
  try {
    const api = getBasicAuthInstance(CLIENT_ID, CLIENT_SECRET, warehouse.environmentBaseURL);
    let response = await api.post(URL_CREATE_USER, {
      name: username,
      external_id: externalId,
    });
    return response.data.id;
  } catch (err) {
    return err.body;
  }
};

const createUserToken = async (userId, CLIENT_ID, CLIENT_SECRET, warehouse, dispatchWarehouse) => {
  try {
    // const user_sdk_creds = JSON.parse(localStorage.getItem("SDK_USER_CREDS"));
    // if (Boolean(user_sdk_creds.userToken)) {
    //   return user_sdk_creds.userToken;
    // }
    const api = getBasicAuthInstance(CLIENT_ID, CLIENT_SECRET, warehouse.environmentBaseURL);
    let response = await api.post(URL_CREATE_USER_TOKEN, {
      user_id: userId,
      // TODO: Static change for Sandbox dropdown
      products:
        warehouse.environment.current === "sandbox"
          ? ["IDENTITY", "IDENTITY.AUDIENCE", "ENGAGEMENT", "ENGAGEMENT.AUDIENCE", "INCOME", "ACTIVITY"]
          : ["IDENTITY", "IDENTITY.AUDIENCE", "ENGAGEMENT", "ENGAGEMENT.AUDIENCE", "INCOME"],
      //products: ["IDENTITY", "IDENTITY.AUDIENCE", "ENGAGEMENT", "ENGAGEMENT.AUDIENCE", "INCOME", "ACTIVITY"],
    });
    return response.data.sdk_token;
  } catch (err) {
    return err.body;
  }
};

const getProfile = async (accountId, warehouse) => {
  try {
    var { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
    // var { clientId, clientSecret } = await runAllEnvsAPIsInLocal(warehouse.environment.current);
    const api = getBasicAuthInstance(clientId, clientSecret, warehouse.environmentBaseURL);
    let response = await api.get(`${URL_GET_PROFILE}?account_id=${accountId}`);
    return response;
  } catch (err) {
    return err.body;
  }
};

const getGenericInfoAPI = async (path = URL_GET_PROFILE, accountId, setContentId, warehouse) => {
  var { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
  // var { clientId, clientSecret } = await runAllEnvsAPIsInLocal(warehouse.environment.current);
  const api = getBasicAuthInstance(clientId, clientSecret, warehouse.environmentBaseURL);

  if (path === URL_GET_COMMENTS) {
    let firstContentId;
    try {
      let engagementResponse = await api.get(`/v1/${URL_GET_CONTENTS}?account_id=${accountId}`);
      firstContentId = engagementResponse.data.data[0].id;
      setContentId(firstContentId);
    } catch (err) {
      return err;
    }
    try {
      let CommentsResponse = await api.get(`/v1/${URL_GET_COMMENTS}?account_id=${accountId}&content_id=${firstContentId}`);
      return CommentsResponse;
    } catch (err) {
      return err;
    }
  }

  if (path === URL_GET_INCOME) {
    let toDate = new Date().toISOString().slice(0, 10);
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 30);
    try {
      let response = await api.get(
        `/v1/${URL_GET_INCOME}?account_id=${accountId}&transaction_to_date=${toDate}&transaction_from_date=${fromDate.toISOString().slice(0, 10)}`
      );
      return response;
    } catch (err) {
      return err.body;
    }
  }

  try {
    let response = await api.get(`/v1/${path}?account_id=${accountId}`);
    return response;
  } catch (err) {
    return err;
  }
};

const getWorkPlatformDetails = async (workplatformId, warehouse) => {
  try {
    var { clientId, clientSecret } = await getClientIDAndSecret(warehouse);
    // var { clientId, clientSecret } = await runAllEnvsAPIsInLocal(warehouse.environment.current);
    const api = getBasicAuthInstance(clientId, clientSecret, warehouse.environmentBaseURL);
    let response = await api.get(`/v1/work-platforms/${workplatformId}`);
    return response;
  } catch (err) {
    return err.body;
  }
};

const fetchAccountConnectedDetails = async (accountId, workplatformId, userId, warehouse, dispatchWarehouse) => {
  // gives the successfully connected account ID and work platform ID for the given user ID
  trackEvent(GETTING_STARTED_ACCOUNT_CONNECT_SUCCESS, { account_id: accountId });
  let user_sdk_creds = JSON.parse(localStorage.getItem("SDK_USER_CREDS"));
  user_sdk_creds.accountId = accountId;
  console.log(`onAccountConnected: ${accountId}, ${workplatformId}, ${userId}`);

  let workplatformResponse = await getWorkPlatformDetails(workplatformId, warehouse);
  user_sdk_creds.workplatformName = workplatformResponse.data.name;
  let flattenedObj = flattenObj(workplatformResponse.data.products);
  let mappingObj = createWorkplatformMapping(flattenedObj);
  user_sdk_creds.mappingObj = mappingObj;
  localStorage.setItem("SDK_USER_CREDS", JSON.stringify(user_sdk_creds));
  let response = await getProfile(user_sdk_creds.accountId, warehouse);
  await localStorage.setItem("User_Response", JSON.stringify({ Identity: response }));
  trackEvent(GETTING_STARTED_STEP_3_DATA_LOAD, {
    product: "Identity",
  });
  const data = await tenantInfoApi.getTenantInfo(env);
  dispatchWarehouse({
    type: ADD_TENANT_INFORMATION,
    payload: data,
  });
  return Promise.resolve(accountId);
};

const getAccountIdFromLS = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let user_sdk_creds = JSON.parse(localStorage.getItem("SDK_USER_CREDS"));
      if (user_sdk_creds.accountId) resolve(user_sdk_creds.accountId);
      else resolve(getAccountIdFromLS());
    }, 1000);
  });
};

export {
  createUser,
  createUserToken,
  getProfile,
  handleCreateUser,
  getClientIDAndSecret,
  getGenericInfoAPI,
  handleGenerateSDK,
  getAllUsers,
  getWorkPlatformDetails,
  fetchAccountConnectedDetails,
  getAccountIdFromLS,
};
