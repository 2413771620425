import {
  trackEvent,
  WEBHOOK_CREATE_FAILURE,
  WEBHOOK_CREATE_SUCCESS,
  WEBHOOK_DELETE_FAILURE,
  WEBHOOK_DELETE_SUCCESS,
  WEBHOOK_EDIT_FAILURE,
  WEBHOOK_EDIT_SUCCESS,
} from "../../analytics";
import axiosInstance, { environmentBaseInstance } from "../axiosInstance";
import { webhooksEndpoint, currentDashboardURL } from "../endpoints";

const getAllWebhooks = async (tenantId, clientId, environment) => {
  const limit = 10;
  const offset = 0;
  try {
    const response = await axiosInstance.get(`/${webhooksEndpoint}`, {
      params: {
        tenant_id: tenantId,
        client_id: clientId,
        environment: environment,
        limit: limit,
        offset: offset,
      },
    });
    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error("Webhooks not found");
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const createWebhook = async (createWebhookObj) => {
  const { tenantId, clientId, environment, name, url, events = [] } = createWebhookObj;
  try {
    const response = await axiosInstance.post(`/${webhooksEndpoint}`, {
      tenant_id: tenantId,
      client_id: clientId,
      environment: environment,
      url,
      events,
      name,
    });
    trackEvent(WEBHOOK_CREATE_SUCCESS, response.data);
    return response;
  } catch (error) {
    trackEvent(WEBHOOK_CREATE_FAILURE, { webhook: { name, url, events }, failure_reason: error?.response?.data?.error?.message });
    console.log(error);
    throw error.response.data.error;
  }
};

const updateWebhook = async (updatewebhookObj) => {
  const { tenantId, clientId, environment, webhookId, name, url, is_active, events = [], webhook } = updatewebhookObj;
  try {
    const response = await axiosInstance.put(`/${webhooksEndpoint}/${webhookId}`, {
      tenant_id: tenantId,
      client_id: clientId,
      environment: environment,
      url,
      events,
      name,
      is_active,
    });
    trackEvent(WEBHOOK_EDIT_SUCCESS, response.data);
    return response;
  } catch (error) {
    trackEvent(WEBHOOK_EDIT_FAILURE, { webhook, failure_reason: error?.response?.data?.error });
    console.log(error, webhook);
    throw error.response.data.error;
  }
};

const deleteWebhook = async (deleteWebhookObj) => {
  const { tenantId, clientId, environment, webhookId, webhook } = deleteWebhookObj;
  try {
    const response = await axiosInstance.delete(`/${webhooksEndpoint}/${webhookId}`, {
      params: {
        tenant_id: tenantId,
        client_id: clientId,
        environment: environment,
      },
    });
    trackEvent(WEBHOOK_DELETE_SUCCESS, webhook);
    return response.data;
  } catch (error) {
    trackEvent(WEBHOOK_DELETE_FAILURE, { webhook, failure_reason: error?.response?.data?.error });
    console.log(error);
  }
};
export { getAllWebhooks, createWebhook, updateWebhook, deleteWebhook };
