import PropTypes from 'prop-types';
import React from "react";
import Card from "../Card/Card";
import Icons from "../Icons/Icons";
import "./CardLink.scss";
import Typography from "../Typography/Typography";
import {
  CONTEXT_HELP_CARD_CLICK,
  HELP_LINK_CLICK,
  trackEvent,
} from "../../analytics";

export default function CardLink({
  backgroundColor = "white",
  link,
  event = "help",
  title,
  description,
  className = "",
}) {

  const cardClickHandler = () => {
    trackEvent(
      event === "help"
        ? HELP_LINK_CLICK
        : event === "contextual"
        ? CONTEXT_HELP_CARD_CLICK
        : event,
      { url: link, page_name: window.location.pathname.substring(1).toUpperCase(), source: "HELP_PAGE" }
    );
    window.open(link, "_blank");
  };
  return (
    <Card
      className={`card-link-container ${className}`}
      style={{ backgroundColor }}
      onClick={cardClickHandler}
    >
      <div className="card-header">
        <Typography h4>{title}</Typography>
        <Icons.link_to_icon />
      </div>
      <span className="card-link-description">{description}</span>
    </Card>
  );
}


CardLink.propTypes = {
  backgroundColor: PropTypes.string,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CardLink.defaultProps = {
  backgroundColor: 'lightblue',
  event: 'help',
  className: '',
};
