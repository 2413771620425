import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import SearchProfileIdentity from "../SearchProfileIdentity/SearchProfileIdentity";

const SearchProfileHeaderSkeleton = () => {
  return (
    <>
      <Box
        sx={{
          height: "max-content !important",
          backgroundColor: "#ffffff",
          paddingLeft: "8px",
          paddingRight: "8px",
        }}
      >
        <Box
          sx={{
            height: "160px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "48px",
          }}
        >
          <Box marginTop={"20px"}>
            <Skeleton className="profilePic" variant="circular" sx={{ height: "160px !important", width: "160px !important" }}></Skeleton>
          </Box>
          <Box className="username-logo-container">
            <Box className="username-container">
              <Skeleton width="214px" className="fullName" variant="text"></Skeleton>
              <Skeleton width="214px" className="userName" variant="text"></Skeleton>
              <Skeleton width="453px" height={48} className="userName" variant="text"></Skeleton>
            </Box>
          </Box>
          <Box className="identity-right-header">
            <label>
              <Skeleton width="89px" height={20} variant="text"></Skeleton>
            </label>
            <Skeleton width="117px" height={56} variant="rectangular"></Skeleton>
          </Box>
        </Box>
        <Box display={"flex"} sx={{ gap: "20px !important", marginBottom: "48px" }} width={700}>
          <Skeleton width="240px" height={59} variant="text"></Skeleton>
          <Skeleton width="240px" height={59} variant="text"></Skeleton>
          <Skeleton width="240px" height={59} variant="text"></Skeleton>
        </Box>
      </Box>
    </>
  );
};

export default SearchProfileHeaderSkeleton;
