import { Skeleton } from '@mui/material';
import React from 'react';
import AudienceOverlapData from '../AudienceOverlapData/AudienceOverlapData';
import CompareCreatorsPageHeader from '../CompareCreatorsPageHeader/CompareCreatorsPageHeader';
import CompareCreatorsProfile from '../CompareCreatorsProfile/CompareCreatorsProfile';
import CreatorChartDistribution from '../CreatorChartDistribution/CreatorChartDistribution';
import CreatorProfileSectionBar from '../CreatorProfileSectionBar/CreatorProfileSectionBar';
import PerformanceMetric from '../PerformanceMetric/PerformanceMetric';
import "./CompareCreatorReportSkeleton.scss";

function CompareCreatorReportSkeleton({ isLoading }) {
  if (!isLoading) return;
  const renderTimes = [0, 1];

  return (
    <div className='compare-creator-report-skeleton-container' >
      <CompareCreatorsPageHeader isComparisonLoading={isLoading} />
      <div className='compare-creator-skeleton-render' >
        {renderTimes.map((_, index) => (
          <CompareCreatorsProfile key={index} isProfileLoading={isLoading} />
        ))}
      </div>
      <CreatorProfileSectionBar isLoading={isLoading} />
      <Skeleton width="100px" height={"50px"} variant="text"></Skeleton>
      <div className='compare-creator-skeleton-render' >
        {renderTimes.map((_, index) => (
          <PerformanceMetric isLoading={isLoading} />
        ))}
      </div>
      <AudienceOverlapData isLoading={isLoading} />
      <div className='compare-creator-skeleton-render' >
        {renderTimes.map((_, index) => (
          <CreatorChartDistribution isLoading={isLoading} />
        ))}
      </div>
    </div>
  );
}

export default CompareCreatorReportSkeleton;