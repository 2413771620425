import './TopMetrics.scss';

export default function TopMetrics({ metricsArray }) {
    const CircleIcon = ({ children }) => {
        return (
            <div className="circle">
                {children}
            </div>
        );
    }
    return (
        <div className="top-metrics">
            {metricsArray?.map(({ metricsRemixIcon, title, subtitle }, index) => {
                return (
                    <div key={index} className="top-metrics-content">
                        <div className="top-metrics-icon">
                            <CircleIcon>{metricsRemixIcon}</CircleIcon>
                        </div>
                        <div className="top-metrics-text">
                            <span className={'section-heading'}>{title}</span>
                            <span className={'body-r'}>{subtitle}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

