import React, { useEffect } from "react";
import { Box, Slider, Typography } from "@mui/material";
import "./SliderFilter.scss";
import Icons from "../Icons/Icons";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "../../utils/util";

const SliderFilter = ({
  sliderFilterHeader,
  isMultipleFilter = false,
  onCloseFilter = () => {},
  defaultSliderValue,
  steps,
  getFilterValue,
  sliderMin = null,
  sliderMax = null,
  sliderStepInc = null,
  filterKey,
}) => {
  const [value, setValue] = React.useState(defaultSliderValue);
  useEffect(() => {
    setValue(defaultSliderValue);
  }, [defaultSliderValue]);

  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setValue(newValue);
      // if (getFilterValue) getFilterValue(newValue);
    }
  };
  const handleMouseUp = (event, value) => {
    // call your function here
    if (getFilterValue) getFilterValue(value, sliderFilterHeader?.id);
  };

  const createFilterHeader = (sliderFilterHeader, value) => {
    const sliderHeaderName = sliderFilterHeader.name;
    if (sliderHeaderName) {
      const filterPrimaryType = filterKey.split("_")[0] + "'s "; // audience or creator
      const filterSecondayType = filterKey.split("_").slice(1).join(" ");
      return (
        <>
          More than <span className="highlight-slider-header">{value}</span>% {filterPrimaryType} {filterSecondayType} is{" "}
          <span className="highlight-slider-header">{capitalizeFirstLetter(sliderHeaderName)}</span>
        </>
      );
    }
    return " > " + value + "%";
  };
  return (
    <div className="slider-filter-wrapper" onClick={(e) => e.stopPropagation()}>
      <div className="slider-filter-header">
        <Typography id="non-linear-slider">{sliderFilterHeader ? createFilterHeader(sliderFilterHeader, value) : " > " + value + "%"}</Typography>
        <Box sx={{ height: 24, width: 24 }}>
          {isMultipleFilter && (
            <Icons.close_icon
              className="close-icon"
              onClick={(e) => {
                e.stopPropagation();
                onCloseFilter(sliderFilterHeader.name);
              }}
            />
          )}
        </Box>
      </div>
      <Slider
        value={value}
        defaultValue={defaultSliderValue}
        sx={{ color: "var(--main-primary)" }}
        onChange={handleChange}
        size="medium"
        marks={steps}
        step={sliderStepInc ? sliderStepInc : null}
        {...(sliderMin && { min: sliderMin })}
        {...(sliderMax && { max: sliderMax })}
        onMouseUp={(event) => handleMouseUp(event, value)}
        className="custom-slider-comp"
      />
    </div>
  );
};

SliderFilter.propTypes = {
  defaultValues: PropTypes.object,
  isMultipleFilter: PropTypes.bool,
  onCloseFilter: PropTypes.func,
  getFilterValue: PropTypes.func,
};

export default SliderFilter;
