import { Box } from "@material-ui/core";
import { EmptyTableScreen, FilledTextField, Icons, Loader, PrimaryButton } from "../index";
import { isEmpty } from "../../utils/util";
import { useNavigate } from "react-router-dom";
import { CONNECTED } from "../../constants/constants";
import {
  ADD_CREATORS_ENTRY_SOURCE,
  MEASURE_ROI_DESC,
  MEASURE_ROI_TITLE,
  measureROISteps,
  shopifyStoreWorkplatformId,
  invalidUrlMsg,
} from "../../constants/campaignTrackingConstants";
import Step from "../Step/Step";
import { useContext, useEffect, useState } from "react";
import { createShopifyAuthorisationURL, getShopifyStores } from "../../api/CampaignTrackingAPI/CampaignTrackingAPI";
import { WarehouseContext } from "../../storage/context";

export default function ConnectShopifyMainContent({ entrySource, campaignId, isStoreFetched, shopifyStores, setShopifyStores, hasCampaignEnded=false }) {
  const { warehouse } = useContext(WarehouseContext);
  const navigate = useNavigate();
  const [isShopifyLinkValid, setIsShopifyLinkValid] = useState(true);
  const [shopifyStoreLink, setShopifyStoreLink] = useState("");
  const { step1, step2 } = measureROISteps;
  const [shopifyStoresLoading, setShopifyStoreLoading] = useState(false)
  async function postOAuthEventHandlerCallback(event) {
    if (event.origin.indexOf(process.env.REACT_APP_INSIGHTIQ_DOMAIN) === -1 || !event.data.insightiq_status) return;
    window.removeEventListener("message", postOAuthEventHandlerCallback, false);
    try {
      const response = await getShopifyStores({ warehouse, status: 'CONNECTED' });
      if (response.length > 0) {
        setShopifyStores(response);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function connectShopifyOAuth(shopifyStoreLink) {
    let shopSlug = shopifyStoreLink.split(".")[0];
    if (shopSlug.includes("https://")) {
      shopSlug = shopSlug.split("https://")[1];
    }
    try {
      let windowPopup = window.open("", "_blank");
      const postOAuthredirectURL = `${process.env.REACT_APP_POST_OAUTH_REDIRECT_URL}?shop_slug=${shopSlug}`;
      const response = await createShopifyAuthorisationURL({
        shopifyStoreWorkplatformId,
        shopSlug,
        postOAuthredirectURL,
        warehouse,
      });
      let popupURL = new URL(response.authorization_url);
      //   let popupURL = new URL(process.env.REACT_APP_POST_OAUTH_REDIRECT_URL);
      popupURL.searchParams.append(
        "storeURL",
        encodeURIComponent(shopifyStoreLink.includes("https://") ? shopifyStoreLink : "https://" + shopifyStoreLink)
      );
      windowPopup.location = popupURL;
      const timer = setInterval(() => {
        if (windowPopup.closed) {
          windowPopup.close();
          window.removeEventListener("message", postOAuthEventHandlerCallback, false);
          clearInterval(timer);
          postOAuthEventHandlerCallback({ origin: process.env.REACT_APP_INSIGHTIQ_DOMAIN });
        }
      }, 1500);
      window.addEventListener("message", postOAuthEventHandlerCallback, false);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchShopifyStores() {
    try {
      setShopifyStoreLoading(true)
      const response = await getShopifyStores({ warehouse, status: 'CONNECTED' });
      if (response.length > 0) {
        setShopifyStores(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShopifyStoreLoading(false)
    }
  }

  useEffect(() => {
      if(isStoreFetched.current){
        fetchShopifyStores().then(() => { isStoreFetched.current=false  });
      }
  }, []);

  const step1Content = () => {
    if(shopifyStoresLoading){
      return <Loader style={{margin:"70px auto"}} />
    }
    return (
      <>
        {shopifyStores.length === 0 ? (
          <Box>
            <FilledTextField
              label={isEmpty(shopifyStores) ? step1.inputPlaceholder : shopifyStores[0].name}
              value={shopifyStoreLink}
              onChange={(e) => {
                setShopifyStoreLink(e.target.value.trim());
                setIsShopifyLinkValid(true);
              }}
              inputProps={{
                maxLength: 100,
              }}
              hasError={!isShopifyLinkValid}
              errorMessage={<> <Icons.caution_red/> {invalidUrlMsg} </>}
            />
            <Box className="body-r sampleInput">{step1.sampleInput}</Box>
            <PrimaryButton
              className="ant-btn-primary connect-shopify-button"
              label={step1.buttonCTA}
              onClick={() => {
                if (shopifyStoreLink.endsWith("myshopify.com/") || shopifyStoreLink.endsWith("myshopify.com")) {
                  connectShopifyOAuth(shopifyStoreLink).then(() => { });
                }
                else {
                  setIsShopifyLinkValid(false);
                }
              }}
              disabled={!isShopifyLinkValid || shopifyStoreLink.length===0 || hasCampaignEnded}
            />
          </Box>
        ) : (
          <Box className="shopify-store-connected-container">
            <Box>
              <Box className="body-m">{shopifyStores[0].name}</Box>
              <Box className="subtitle-r" color={"var(--neutrals-secondary-grey)"}>
                {shopifyStores[0].url}
              </Box>
            </Box>
            <Box className="body-m">
              {" "}
              <Icons.tick /> {CONNECTED.toLocaleLowerCase()}
            </Box>
          </Box>
        )}
      </> 
    );
  }
  function Step2Content() {
    if (isEmpty(shopifyStores)) return;
    return (
      <Box>
        <PrimaryButton
          style={{ margin: "0px" }}
          className="ant-btn-primary connect-shopify-button"
          label={step2.buttonCTA}
          onClick={() => {
            if (entrySource === ADD_CREATORS_ENTRY_SOURCE.EDIT_CAMPAIGN_CAMPAIGNPAGE) {
              navigate(`/creators/campaigns/create-utm-links/${campaignId}/${shopifyStores[0].id}`);
            } else {
              navigate(`/creators/campaigns/create-utm-links/${shopifyStores[0].id}`);
            }
          }}
          disabled={hasCampaignEnded}
        />
      </Box>
    );
  }

  return (
    <div className={"add-shopify-link-main-content"}>
      <Box className="campaign-error-content ">
        <EmptyTableScreen icon={<Icons.measure_roi_setup_logo />} header={MEASURE_ROI_TITLE} content={MEASURE_ROI_DESC} isActionButton={false} />
      </Box>
      <div className={"add-shopify-link-action-container"}>
        <Step
          icon={<Icons.store_icon />}
          title={step1.title}
          message={step1.desc}
          stepCount={1}
          content={step1Content()}
          variant={"StepWithIcon"}
        />
        <Step
          icon={<Icons.links_icon />}
          title={step2.title}
          message={step2.desc}
          stepCount={2}
          content={<Step2Content />}
          variant={"StepWithIcon"}
        />
      </div>
    </div>
  );
}
