import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CustomDropDown from "../CustomDropDown/CustomDropDown";
import PropTypes from "prop-types";
import "./MinMaxDropdownFilter.scss";

function MinMaxDropdownFilter({ minValuesOptions, maxValuesOptions, defaultMinValue, defaultMaxValue, getFilterValue, filterKey }) {
  // const [selectedValue, SetSelectedValue] = useState({
  //   minValue: defaultMinValue,
  //   maxValue: defaultMaxValue,
  // });
  const [min, setMin] = useState(defaultMinValue);
  const [max, setMax] = useState(defaultMaxValue);
  const minRef = useRef(min);
  const maxRef = useRef(max);

  minRef.current = min;
  maxRef.current = max;

  //   console.log("prev", selectedValue);
  //   if (min !== undefined) {
  //     const updatedValue = {
  //       minValue: min,
  //       maxValue: selectedValue.maxValue,
  //     };
  //     console.log("updated", updatedValue);
  //     SetSelectedValue(updatedValue);
  //     if (getFilterValue) getFilterValue(updatedValue);
  //   }
  //   if (max !== undefined) {
  //     const updatedValue = {
  //       minValue: selectedValue.minValue,
  //       maxValue: max,
  //     };
  //     console.log("updated", updatedValue);
  //     SetSelectedValue(updatedValue);
  //     if (getFilterValue) getFilterValue(updatedValue);
  //   }
  // };
  return (
    <Box className="dropdown-filter-container">
      <CustomDropDown
        getFilterValue={(value) => {
          setMin(value);
          if (getFilterValue) getFilterValue({ min: value, max: maxRef.current });
        }}
        defaultValue={min}
        options={minValuesOptions}
        label="From"
      />
      <CustomDropDown
        getFilterValue={(value) => {
          setMax(value);
          if (getFilterValue) getFilterValue({ max: value, min: minRef.current });
        }}
        defaultValue={max}
        options={maxValuesOptions}
        label="To"
      />
    </Box>
  );
}

MinMaxDropdownFilter.propTypes = {
  // minValuesOptions: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     name: PropTypes.string.isRequired,
  //     value: PropTypes.string.isRequired,
  //   })
  // ).isRequired,
  // maxValuesOptions: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     name: PropTypes.string.isRequired,
  //     value: PropTypes.string.isRequired,
  //   })
  // ).isRequired,
  // defaultMinValue: PropTypes.string,
  // defaultMaxValue: PropTypes.string,
  getFilterValue: PropTypes.func,
};

MinMaxDropdownFilter.defaultProps = {
  // defaultMinValue: "",
  // defaultMaxValue: "",
};

export default React.memo(MinMaxDropdownFilter);
