import React from "react";
import "./ExportPDFPopUp.scss";
import Icons from "../Icons/Icons";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

const ExportPDFPopUp = ({ open, onClose, header, exportPDFLink, actionButtonLabel, actionButtonHandler }) => {
  if (!open) {
    return null;
  }
  return (
    <div className="modal">
      <div className="export-modal-container">
        <div className="export-pop-up-content-container">
          <div className="export-pop-up-icon">
            <Icons.export_icon />
          </div>
          <div className="export-pop-up-description-container">
            <div className="export-pop-up-heading">
              <h3 className="export-pop-up-info-header">{header}</h3>
            </div>
            <div className="export-modal-description">{exportPDFLink}</div>
            <div className="export-modal-action">
              <PrimaryButton
                className="export-modal-action-button"
                label={actionButtonLabel}
                onClick={actionButtonHandler}
                icon={<i className="ri-file-copy-line" style={{ fontSize: "20px" }}></i>}
              />
            </div>
          </div>
        </div>
        <div className="close-icon-container">
          <Icons.close_icon onClick={onClose} cursor="pointer" />
        </div>
      </div>
    </div>
  );
};

export default ExportPDFPopUp;
