import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {
    ERROR_TIMEOUT_API_ACTIVATION_DELAY,
    GETTING_STARTED_DOCUMENTATION_CLICK,
    GETTING_STARTED_PAGE_VIEW,
    GETTING_STARTED_VIEW_API_CREDENTIALS_CLICK,
    trackEvent,
} from "../../analytics";
import { sdkConnectApi } from "../../api";
import {
    Icons,
    IntermediateLoader,
    InvertedPrimaryButton,
    PrimaryButton,
    ProductPageHeader,
    Separator,
    Typography
} from "../../components";
import Steps from "../../components/GettingStarted/GettingStarted";
import {
    docs,
    getPageTitle,
    INSIGHTIQ_USERS_API_TIMEOUT,
    INSIGHTIQ_USERS_POLLING_INTERVAL,
} from "../../constants/constants";
import { WarehouseContext } from "../../storage/context";
import { ADD_USER_STEPS, UPDATE_TENANT_STATUS } from "../../storage/reducers/types";
import { getDataCategoryText, getProductsList, isEmpty } from "../../utils/util";
import { Modal } from "antd";
import "./GettingStartedPage.scss";
import { useFetchTenantInformation } from "../../hooks/useFetchTenantInformation";
import { getProduct } from "../../api/products/products";

let expiryTimer = new Date().getTime() + INSIGHTIQ_USERS_API_TIMEOUT;
let timer;

const GettingStartedPage = () => {
    const [accountId, setAccountId] = useState();
    // const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
    const {
        warehouse,
        warehouse: { tenantInfo, tenantStatus, environmentBaseURL, environmentChanged, environment },
        dispatchWarehouse,
    } = useContext(WarehouseContext);
    let navigate = useNavigate();
    const [currStep, setCurrStep] = useState(warehouse.userCurrentStep);
    const [loader, setLoader] = useState(false);
    const [userResponse, setUserResponse] = useState({});
    const [changeEnvLoader, setChangeEnvLoader] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const fetchTenantInformation = useFetchTenantInformation();
    const isMounted = useRef(false);
    const [product, setProduct] = useState({});
    const [isProductLoading, setProductLoading] = useState(false);

    useEffect(() => {
        const urlParts = window.location.pathname.replace(/\/+$/, '').split("/");
        const productId = urlParts[urlParts.length - 1];
        if (!isEmpty(productId)) {
            setProductLoading(true);
            getProduct(productId).then(setProduct).finally(() => setProductLoading(false));
        }
    }, []);

    const ErrorModalConfig = {
        title: (
            <Typography h3 style={{ fontWeight: "600" }}>
                Uh-oh! We are unable to reach our servers at this moment
            </Typography>
        ),
        content: (
            <div>
                <p className="contact-sales-card-description">
                    It's not you, it's us. We are working on fixing it. Please refresh the page in some time or send a
                    report to{" "}
                    <a href="mailto:support@insightiq.ai" style={{ color: "#2262ED" }}>
                        <u>support@insightiq.ai</u>
                    </a>{" "}
                    if the issue persists.
                </p>
            </div>
        ),
        closeIcon: <Icons.close_circle_fill onClick={() => navigate(-1)}/>,
        closable: true,
        icon: false,
        cancelText: null,
        okType: "text",
        okButtonProps: {
            style: { backgroundColor: "transparent", height: "65px" },
        },
        okText: (
            <InvertedPrimaryButton
                label="Got it!"
                onClick={() => {
                    // TODO: Add active tab to Getting started ONLY

                    navigate(-1);
                }}
            />
        ),
    };

    const updateTenantStatus = async (tenantInfo) => {
        try {
            if (!warehouse.environment.current) return;
            const response = await sdkConnectApi.getAllUsers(tenantInfo, environmentBaseURL, environment);
            if (response.status === 200) {
                clearTimeout(timer);
                dispatchWarehouse({ type: UPDATE_TENANT_STATUS });
            }
        } catch (e) {
            if (new Date().getTime() > expiryTimer) {
                if (isModalOpen) {
                    Modal.info(ErrorModalConfig);
                    trackEvent(ERROR_TIMEOUT_API_ACTIVATION_DELAY);
                    expiryTimer = new Date().getTime() + INSIGHTIQ_USERS_API_TIMEOUT;
                }
                setIsModalOpen(true);
            } else {
                if (isMounted.current) {
                    timer = setTimeout(() => {
                        clearTimeout(timer);
                        updateTenantStatus(tenantInfo);
                    }, INSIGHTIQ_USERS_POLLING_INTERVAL);
                }
            }
        }
    };

    useEffect(() => {
        try {
            if (!tenantStatus && tenantInfo.length !== 0) updateTenantStatus(tenantInfo);
        } catch (e) {
            throw e;
        }
    }, [isModalOpen, tenantInfo]);

    useEffect(() => {
        if (environmentChanged) {
            clearTimeout(timer);
            expiryTimer = new Date().getTime() + INSIGHTIQ_USERS_API_TIMEOUT;
            fetchTenantInformation();
            // Creating Product list based on environment
            getProductsList(warehouse.environment.current);
            if (localStorage.getItem("SDK_USER_CREDS")) {
                localStorage.removeItem("SDK_USER_CREDS");
                localStorage.removeItem("User_Response");
                dispatchWarehouse({ type: ADD_USER_STEPS, payload: 1 });
                setChangeEnvLoader(false);
                setCurrStep(1);
                setAccountId();
                setChangeEnvLoader(true);
            }
        }
    }, [environmentChanged]);

    useEffect(() => {
        expiryTimer = new Date().getTime() + INSIGHTIQ_USERS_API_TIMEOUT;
        trackEvent(GETTING_STARTED_PAGE_VIEW);
    }, []);

    // useEffect(() => {
    //   localStorage.removeItem("SDK_USER_CREDS");
    //   localStorage.removeItem("User_Response");
    // }, []);
    useEffect(() => {
        isMounted.current = true;

        // Creating Product list based on environment
        getProductsList(warehouse.environment.current);

        // To ensure clearing user creds with page refresh.
        window.onbeforeunload = function () {
            localStorage.removeItem("SDK_USER_CREDS");
            localStorage.removeItem("User_Response");
        };
        return () => {
            window.onbeforeunload = null;
            isMounted.current = false;
            clearTimeout(timer);
        };
    }, []);

    return (
        <div>
            <div className="measurement-back-icon">
                <i
                    className="ri-arrow-left-line back-icon"
                    onClick={() => {
                        navigate(-1);
                    }}
                ></i>
            </div>
            <ProductPageHeader
                isLoading={isProductLoading}
                title={product?.title}
                mainSubtitle={isEmpty(product?.dataCategory) ? '' : getDataCategoryText(product?.dataCategory)}
                additionalSubtitle={product?.description}
                docsLink={product?.pathToPage}
                buttonLabel={docs}
            />
            <div className="getting-started-separator">
                <Separator/>
            </div>
            {warehouse.tenantStatus && changeEnvLoader ? (
                <>
                    <div>
                        <div className="getting-started-header">Playground</div>
                        <Steps step={1} setCurrStep={setCurrStep} setLoader={setLoader}
                               dispatchWarehouse={dispatchWarehouse}/>
                        {currStep >= 2 && (
                            <Steps
                                step={2}
                                setLoader={setLoader}
                                setCurrStep={setCurrStep}
                                accountId={accountId}
                                setAccountId={setAccountId}
                                userResponse={userResponse}
                                setUserResponse={setUserResponse}
                                dispatchWarehouse={dispatchWarehouse}
                            />
                        )}
                        {currStep === 3 && (
                            <div>
                                <Steps
                                    step={3}
                                    loader={loader}
                                    setLoader={setLoader}
                                    accountId={accountId}
                                    setAccountId={setAccountId}
                                    userResponse={userResponse}
                                    setUserResponse={setUserResponse}
                                    dispatchWarehouse={dispatchWarehouse}
                                />
                                {!loader && (
                                    <>
                                        <h1 className="done_heading">Done!</h1>
                                        <p className="done_text">
                                            That's how you use insightIQ APIs to fetch data of your users. Can't wait to
                                            see what you build with insightIQ! 🎉
                                        </p>
                                        <div className="done_button">
                                            <PrimaryButton
                                                label={"View API credentials"}
                                                htmlType="submit"
                                                onClick={() => {
                                                    navigate("/developers/api-credentials");
                                                    trackEvent(GETTING_STARTED_VIEW_API_CREDENTIALS_CLICK, {
                                                        url: window.location.href,
                                                    });
                                                }}
                                            />
                                            <a href="https://docs.insightiq.ai/docs/api-reference" target="_blank">
                                                <InvertedPrimaryButton
                                                    className="open_doc_but"
                                                    label={"Go to the documentation"}
                                                    style={{ backgroundColor: "white", width: "215px" }}
                                                    htmlType="submit"
                                                    onClick={() => {
                                                        trackEvent(GETTING_STARTED_DOCUMENTATION_CLICK, {
                                                            url: "https://docs.insightiq.ai/docs/api-reference",
                                                        });
                                                    }}
                                                />
                                            </a>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <Helmet>
                        <title>{getPageTitle("GETTING_STARTED_PAGE")}</title>
                    </Helmet>
                </>
            ) : (
                <IntermediateLoader/>
            )}
        </div>
    );
};

export default GettingStartedPage;
