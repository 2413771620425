import axios from "axios";
import { URL_AMBERFLO_USAGE } from "../../constants/constants";

export async function getAmberfloUsageUrl({ prodTenantAppId }) {
    try {
        const data = {
            customerId: prodTenantAppId,
        };

        const config = {
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'x-api-key': process.env.REACT_APP_AMBERFLO_API_KEY
            },

        };
        const response = await axios.post(URL_AMBERFLO_USAGE, data, config);
        return response?.data?.url;
    } catch (error) {
        throw error;
    }
}
