import { ADD_ANALYTICS, UPDATE_ANALYTICS } from "./types";

export const analyticsReducer = (state, action) => {
  switch (action.type) {
    case ADD_ANALYTICS:
      localStorage.setItem("insightiq_analytics", JSON.stringify(action.payload));
      return action.payload;
    case UPDATE_ANALYTICS:
      const currStateFromLocalStorage = JSON.parse(localStorage.getItem("insightiq_analytics"));
      const currState = {
        ...currStateFromLocalStorage,
        ...action.payload,
      };
      localStorage.setItem("insightiq_analytics", JSON.stringify(currState));
      return currState;
    default:
      localStorage.setItem("insightiq_analytics", JSON.stringify(state));
      return state;
  }
};
