import React, { useState } from "react";
import "./EmailPreview.scss";

const EmailPreview = ({ inviteName, tenantAppName }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <div className="email-preview-container">
      <h3 className="email-preview-heading">Email preview</h3>
      <div className="email-content">
        <h2 className="email-text-heading">Connect your accounts</h2>
        <p className="email-text">Hey {inviteName},</p>
        <p className="email-text">
          <b>{tenantAppName ? tenantAppName : "S.H.I.E.L.D"}</b> has requested you to connect your social accounts via insightIQ. The account
          connection is securely handled by insightIQ. Connecting your accounts helps {tenantAppName ? tenantAppName : "S.H.I.E.L.D"} to view your
          profile and track campaign performance.
        </p>
        <p className="email-text">You can safely start connecting your accounts using the button below.</p>
        <p className="email-text">[Button to connect account]</p>
        <p className="email-text">
          If you have any questions, please get in touch with {tenantAppName ? tenantAppName : "S.H.I.E.L.D"} or reply to this email.
        </p>
        <p className="email-text">
          Thank you,<p className="email-text">{user ? user?.firstName + " " + (user?.lastName ? user?.lastName : "") : "Nick Fury"}</p>
        </p>
      </div>
    </div>
  );
};

export default EmailPreview;
