import { Tooltip } from "antd";
import { useContext, useState } from "react";
import {
  accountConnectedMessage,
  accountConnectedMessageForLinkage,
  connectedAccountReachedLimit,
  connectedAccountReachedLimitForLinkage,
} from "../../constants/tooltipMessages";
import Icons from "../Icons/Icons";
import "./ConnectedAccountsCard.scss";
import parse from "html-react-parser";
import { UserContext, WarehouseContext } from "../../storage/context";
import { useEffect } from "react";
const ConnectedAccountsCard = ({ currentTab }) => {
  const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
  const { userProfile } = useContext(UserContext);
  const [connectedAccount, setConnectedAccount] = useState(warehouse.tenantInfo[0]?.apps[0]?.total_connected_accounts);
  const maxConnectedAccount = warehouse.tenantInfo[0]?.apps[0]?.max_connected_accounts;

  const currentTabTooltipMessage = {
    Creators: {
      accountConnectedMessage: accountConnectedMessageForLinkage,
      connectedAccountReachedLimit: connectedAccountReachedLimitForLinkage,
    },
    Developers: {
      accountConnectedMessage: accountConnectedMessage,
      connectedAccountReachedLimit: connectedAccountReachedLimit,
    },
  };

  useEffect(() => {
    setConnectedAccount(warehouse.tenantInfo[0]?.apps[0]?.total_connected_accounts);
  }, [warehouse.tenantInfo]);

  return (
    <div className="connectedAccountContainer">
      {connectedAccount >= maxConnectedAccount ? (
        <Tooltip
          placement="right"
          title={parse(currentTabTooltipMessage[currentTab].connectedAccountReachedLimit.replace("100", maxConnectedAccount))}
          color="#222838"
        >
          <div className="connectedAccountsError">
            <div>
              <p>You have reached the account connection limit!</p>
            </div>
            <div>
              <Icons.info_error_icon />
            </div>
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          placement="right"
          title={parse(currentTabTooltipMessage[currentTab].accountConnectedMessage.replace("100", maxConnectedAccount))}
          color="#222838"
          // key="#FFFFFF"
        >
          <div className="connectedAccountsCard">
            <div>
              <p>Account connections</p>
            </div>
            <div>
              <div>
                <p className="connectedNumbers">
                  {connectedAccount} <span>/ {maxConnectedAccount}</span>
                </p>
              </div>
              <div>
                <Icons.info_blue_icon />
              </div>
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default ConnectedAccountsCard;
