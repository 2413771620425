import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./CheckBox.scss";
import Icon from "../Icons/Icons";

// We have a different icon for checkbox, so we need to use a different component
export default function CheckBox({ className = "", defaultChecked = false, onClick = () => {}, blackCheck = false, disabled = false, checkedValue, ...props }) {
  const [checked, setChecked] = useState(defaultChecked);
  const onCheck = (e) => {
    const currentValue = !checked;
    onClick(currentValue);
    setChecked(currentValue);
  };
  useEffect(()=>{
    setChecked(defaultChecked)
  },[defaultChecked])
  return (
    <div className={`check-box ${className} ${disabled && 'checkbox-disabled'}`} {...props} onClick={disabled ? () => {} : onCheck}>
      {checked ? blackCheck ? <Icon.checkbox_filled_black /> : <Icon.checkbox_filled /> : <Icon.checkbox_unfilled />}
    </div>
  );
}

CheckBox.propTypes = {
  defaultChecked: PropTypes.bool,
  onClick: PropTypes.func,
};

CheckBox.defaultProps = {
  defaultChecked: false,
  onClick: () => {},
};
