import axiosInstance from "../axiosInstance";
import { getAllWebhookEventsEndpoint } from "../endpoints";

const getAllWebhookEvents = async (environment) => {
  try {
    const response = await axiosInstance.get(
      `/${getAllWebhookEventsEndpoint}`,
      {
        params: {
          environment,
        },
      }
    );
    if (response.status === 200) {
      // return response.data.data;
      return response.data;
    } else {
      throw new Error("Webhook-events not found");
    }
  } catch (e) {
    console.log(e);
  }
};

export { getAllWebhookEvents };
