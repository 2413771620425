import moment from 'moment-timezone';
import {
  AccountStatus,
  DisconnectionSource,
  instagram_URL_REGEX,
  SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING,
  SEARCH_ATTRIBUTES_TOOLTIP_TEXT,
  tiktok_URL_REGEX,
  URL_REGEX,
  WORKPLATFORM_ID_MAPPING,
  youtube_URL_REGEX,
} from "../constants/constants";
import { Chip, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import parse from "html-react-parser";
import { ABOUT, AI_POWERED, AUDIENCE, COMMENT_ANALYSIS, GROWTH_AND_INTEREST, PERFORMANCE, SIMILAR_CREATORS } from '../constants/creatorDiscovery';
import { Colors } from '../styles/colors';

// to format num ex 12000 -> 12K
export function formatNumber(num, decimalPrecision = 2) {
  if (num === null) {
    return null;
  }
  function rnd(num, precision) {
    const prec = 10 ** precision;
    return Math.round(num * prec) / prec;
  }
  const abbRev = ["K", "M", "B"];
  let base = Math.floor(Math.log(Math.abs(num)) / Math.log(1000));
  const suffix = abbRev[Math.min(abbRev.length - 1, base - 1)];
  base = abbRev.indexOf(suffix) + 1;
  return suffix ? rnd(num / 1000 ** base, decimalPrecision) + suffix : "" + Math.round((num + Number.EPSILON) * 100) / 100;
}


// convert time to hrs min
export const timeConvert = (time) => {
  let hours = Math.floor(time / 60);
  let minutes = time % 60;
  return Math.floor(hours) + " hrs " + Math.floor(minutes) + " mins ";
};

// returns boolean if image exists or not
export async function isImageExist(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;

    img.onload = function () {
      resolve(true);
    };

    img.onerror = function () {
      reject(false);
    };
  });
}

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

// to calculate differnce b etween dates
export const calculateTotalDays = (firstDate) => {
  let date = moment(firstDate);
  let differenceInDays = moment().diff(date, "days");
  return differenceInDays < 1
    ? "Today"
    : differenceInDays < 2
      ? "1 day ago"
      : differenceInDays < 3
        ? "2 day ago"
        : differenceInDays < 4
          ? "3 day ago"
          : moment(firstDate).format("MMM DD, YYYY");
};
// moment(clientSecrets[0]?.created_at).format("MMM DD, YYYY")
// function to convert 2023-02-08T22:09:40.659106 data to Month in text just 3 letters Day, Year format
export const convertDate = (date) => {
  let formattedDate = moment(date).format("MMM D, YYYY");
  return formattedDate;
};

//write a function to find latest date from array of dates
export const latestDate = (dates) => {
  const latest = new Date(Math.max.apply(null, dates));
  return latest;
};

export function removeDuplicates(arr, prop) {
  return Object.values(
    arr.reduce((uniqueObjs, obj) => {
      uniqueObjs[obj[prop]] = obj;
      return uniqueObjs;
    }, {})
  );
}

export function getUserName(identityData) {
  if (!identityData) {
    return "";
  }
  const {
    work_platform: { id },
  } = identityData;
  return WORKPLATFORM_ID_MAPPING[id] && WORKPLATFORM_ID_MAPPING[id].platformName.toLowerCase() === "youtube" // for youtube profile name shown as username
    ? identityData.platform_profile_name || ""
    : identityData.platform_username || "";
}

export function checkRemoved(account) {
  return account?.status === AccountStatus.NOT_CONNECTED.key && account?.disconnection_source === DisconnectionSource.DEVELOPER_API;
}

export const getNewImageUrl = (url) => {
  if (!url && url === null) return;
  const urlParts = url.split("/");
  var newUrl = "";
  for (let i = 0; i < urlParts.length; i++) {
    if (i === 2) {
      newUrl += urlParts[i].replaceAll("-", "--").replaceAll(".", "-") + atob("LnRyYW5zbGF0ZS5nb29n") + "/";
    } else {
      if (i !== urlParts.length - 1) {
        newUrl += urlParts[i] + "/";
      } else {
        newUrl += urlParts[i];
      }
    }
  }
  return encodeURI(newUrl);
};

export function getWorkPlatformIdByPlatformName(platformName) {
  // Find the entry in the mapping object with the matching platformName
  const entry = Object.entries(WORKPLATFORM_ID_MAPPING).find(
    ([_, platformData]) => platformData.platformName.toLowerCase() === platformName.toLowerCase()
  );

  // If a matching entry is found, return the workPlatformId; otherwise, return null
  return entry ? entry[0] : null;
}

export const getWorkPlatformIDFromURL = (url) => {
  const prefixedUrl = url.startsWith("http") ? url : `https://${url}`;
  const urlObject = new URL(prefixedUrl);
  const hostname = urlObject.hostname;
  if (hostname.includes("youtube") || hostname.includes("youtu.be")) {
    return getWorkPlatformIdByPlatformName("youtube");
  } else if (hostname.includes("instagram") || hostname.includes("instagr.am")) {
    return getWorkPlatformIdByPlatformName("instagram");
  } else if (hostname.includes("tiktok")) {
    return getWorkPlatformIdByPlatformName("tiktok");
  } else {
    return "unknown";
  }
};

export const percentFormatter = Intl.NumberFormat("en", {
  style: "percent",
  // maximumSignificantDigits: 4,   // To show total of 4 digits
  maximumFractionDigits: 2,         // To show maximum of 2 fractions, which is closer to the UI reqs
  roundingPriority: "lessPrecision",
});

export const validatePostUrl = (url) => {
  return URL_REGEX.test(url) && (youtube_URL_REGEX.test(url) || instagram_URL_REGEX.test(url) || tiktok_URL_REGEX.test(url));
};

export const PROFILE_ANALYTICS_TOP_HEADER_INFO = (infoUpdatedAt) => `Last updated on ${infoUpdatedAt}`;

export const creatorContactType = {
  email: "email",
  phone: "phone",
};

export const creatorBioType = {
  location: "location",
  age_group: "age_group",
  language: "language",
  gender: "gender",
  platform_account_type: "platform_account_type",
};

export const profileSectionsLabel = {
  overview: {
    label: PERFORMANCE,
    footerWidth: "102px",
    sectionID: "overview-section",
  },
  commentAnalysis: {
    label: (
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"8px"}>
        {COMMENT_ANALYSIS} <Chip label={AI_POWERED} classes={{ root: `comment-analysis-chip` }} />
      </Box>
    ),
    footerWidth: "250px",
    sectionID: "comment-analysis-section",
  },
  growth: {
    label: GROWTH_AND_INTEREST,
    footerWidth: "145px",
    sectionID: "growth-section",
  },
  audience: {
    label: AUDIENCE,
    footerWidth: "74px",
    sectionID: "audience-section",
  },
  similarCreators: {
    label: SIMILAR_CREATORS,
    footerWidth: "122px",
    sectionID: "similar-creators-section",
  },
  about: {
    label: ABOUT,
    footerWidth: "50px",
    sectionID: "about-section",
  }
};

export const creatorBioIconMapping = {
  location: <i className="ri-map-pin-line contact-icon"></i>,
  age_group: <i className="ri-cake-2-line contact-icon"></i>,
  language: <i className="ri-translate-2 contact-icon"></i>,
};

export const creatorBioHeaders = {
  location: "Location",
  age_group: "Creator's age",
  language: "Language spoken",
  gender: "Gender",
  platform_account_type: "Account type",
};

export const followerTypes = {
  real: {
    label: "real",
    class: "real",
  },
  influencers: { label: "influencers", class: "influencers" },
  suspicious: { label: "suscpicious", class: "suspicious" },
  mass_followers: { label: "mass followers", class: "mass-followers" },
};

export const creatorBioSectionItems = ["location", "age_group", "language", "gender", "platform_account_type"];

export const getValidLinks = (csvPostUrls, header) => {
  if (!csvPostUrls || csvPostUrls.length === 0) return null;

  // Filter invalid URLs
  const validUrls = csvPostUrls.filter(item => item.isValid);

  // Prepare CSV content
  const csvContent = `${header}\n${validUrls.map(item => `${item.url}`).join('\n')}`;

  // Create a Blob containing the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv' });

  return blob;
};

export function renderEngagementRateMetrics(engagementRateHistogram, engagementRate, engagementRateChipData) {
  return (
    <div className='engagement-rate-metrics-container'>
      <div>
        {SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.engagement_rate}{' '}
        <Tooltip title={parse(SEARCH_ATTRIBUTES_TOOLTIP_TEXT.engagement_rate)} placement='top-start'>
          <i className='ri-information-line'></i>
        </Tooltip>
      </div>
      {engagementRateHistogram != null && engagementRate !== 0 && (
        <Chip label={engagementRateChipData.textValue} classes={{ root: `metric-chip ${engagementRateChipData.chipClass}` }} />
      )}
    </div>
  );
}

export const instagramProfilePerfomanceMetrics = [
  {
    name: (engagementRateHistogram, engagementRate, engagementRateChipData) => {
      return renderEngagementRateMetrics(engagementRateHistogram, engagementRate, engagementRateChipData);
    },
  },
  {
    name: SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.sponsored_posts_performance,
  },
  {
    name: SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.average_likes,
    icon: <i className="ri-heart-3-line metric-icon" />,
    variant: "metricIcon",
  },
  {
    name: SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.average_comments,
    icon: <i className="ri-chat-3-line metric-icon" />,
    variant: "metricIcon",
  },
  {
    name: SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.average_views,
    icon: <i className="ri-eye-line metric-icon" />,
    variant: "metricIcon",
  },
  {
    name: SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.average_reels_views,
    icon: <i className="ri-eye-line metric-icon" />,
    variant: "metricIcon",
  }
];

export const profilePerfomanceMetrics = [
  {
    name: (engagementRateHistogram, engagementRate, engagementRateChipData) => {
      return renderEngagementRateMetrics(engagementRateHistogram, engagementRate, engagementRateChipData);
    },
  },
  {
    name: SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.sponsored_posts_performance,
  },
  {
    name: SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.average_likes,
    icon: <i className="ri-heart-3-line metric-icon" />,
    variant: "metricIcon",
  },
  {
    name: SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.average_comments,
    icon: <i className="ri-chat-3-line metric-icon" />,
    variant: "metricIcon",
  },
  {
    name: SEARCH_ATTRIBUTES_DISCRIPTION_MAPPING.average_views,
    icon: <i className="ri-eye-line metric-icon" />,
    variant: "metricIcon",
  },
];

export const PERFORMANCE_METRICS_WORKPLATFORMS_MAPPING = {
  YouTube: profilePerfomanceMetrics,
  Instagram: instagramProfilePerfomanceMetrics,
  TikTok: profilePerfomanceMetrics,
};

export const REAL_LABEL = "Real";
export const INFLUENCER_LABEL = "Influencers";
export const SUSPICIOUS_LABEL = "Suspicious";
export const MASS_FOLLOWERS_LABEL = "Mass followers";
export const MALE = "MALE";
export const MALE_LABEL = "Male";
export const FEMALE = "FEMALE";
export const FEMALE_LABEL = "Female";
export const OTHERS_LABEL = "Others";

export function getGenderDetails({ data }) {
  let genderDetails = [
    { name: MALE_LABEL, value: 0, color: Colors.mainPrimary },
    { name: FEMALE_LABEL, value: 0, color: Colors.accentCaribbeanGreen },
    { name: OTHERS_LABEL, value: 0, color: Colors.neutralsBorderGrey },
  ];

  data?.map((info) => {
    info?.gender === MALE
      ? (genderDetails[0].value += info.value)
      : info.gender === FEMALE
        ? (genderDetails[1].value += info.value)
        : (genderDetails[2].value += info.value);
  });

  return genderDetails.sort((a, b) => {
    return b.value - a.value;
  });
}

export function getFollowerTypes({ data }) {
  let followerDetails = [
    { name: REAL_LABEL, value: 0, color: Colors.accentCaribbeanGreen },
    { name: INFLUENCER_LABEL, value: 0, color: Colors.mainPrimary },
    { name: SUSPICIOUS_LABEL, value: 0, color: Colors.accentAmaranth },
    { name: MASS_FOLLOWERS_LABEL, value: 0, color: Colors.accentLime },
  ];

  data?.map((info) => {
    info.name === "real"
      ? (followerDetails[0].value += info.value)
      : info.name === "influencers"
        ? (followerDetails[1].value += info.value)
        : info.name === "suspicious"
          ? (followerDetails[2].value += info.value)
          : (followerDetails[3].value += info.value);
  });

  return followerDetails.sort((a, b) => {
    return b.value - a.value;
  });
}

export function creatorBioAgeGroupText(value) { return `Between ${value} years`; }

export const constructValueForCreatorBio = (type, value) => {
  if (type === creatorBioType.location) {
    if (value?.state && value?.country) {
      return `${value?.state}, ${value?.country}`;
    } else if (value?.state || value?.country) {
      return value?.state || value?.country;
    }
  } else if (type === creatorBioType.age_group) {
    return value ? creatorBioAgeGroupText(value) : value;
  } else if (type === creatorBioType.language) {
    if (value) {
      return new Intl.DisplayNames(["en"], { type: "language" }).of(value);
    } else {
      return null;
    }
  } else {
    return value;
  }
};

export const getCredibilityScorePercentage = (score) => {
  return Math.ceil(score * 100);
};

export const getLargestValueInFollowerTypes = (followerType) => {
  if (followerType?.length === 0) return null;
  return followerType?.reduce((max, item) => (item.value > max.value ? item : max), followerType[0]);
};

// convert month from 2024-Jan to Jan ‘22
export function formatMonth(dateString) {
  if (!dateString) return dateString;
  const [year, month] = dateString.split("-");
  const date = new Date(year, month - 1); // Month is zero-indexed
  const options = { year: '2-digit', month: 'short' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  const [monthName, yearShort] = formattedDate.split(" ");
  return `${monthName} ‘${yearShort}`;
}