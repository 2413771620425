import React from 'react';
import './CompareCreatorsProfile.scss';
import { Icons } from '../../components';
import { Avatar, Skeleton } from '@mui/material';
import { CREATOR_PROFILE_LINK, FULL_PROFILE_LINK_TEXT, PROFILE_BIO_TEXT, WORKPLATFORM_ID_MAPPING } from '../../constants/constants';
import { isEmpty } from '../../utils/util';

function CompareCreatorsProfile({
  profileURL,
  profileImage,
  fullName,
  isProfileVerified,
  workPlatformHandle,
  workPlatformName,
  workPlatformLogo,
  userName,
  profileBio,
  workPlatformId,
  isProfileLoading,
  showAppBar
}) {

  if (isProfileLoading) {
    return (
      <div className='compare-creators-profile'>
        <div className='creator-profile-image-container'>
          <Skeleton className="profilePic" variant="circular" sx={{ height: "160px !important", width: "160px !important" }}></Skeleton>
        </div>
        <div className='creator-profile-identity-container'>
          <Skeleton width="214px" className="fullName" variant="text"></Skeleton>
          <Skeleton width="214px" className="userName" variant="text"></Skeleton>
          <Skeleton width="300px" height={'48px'} className="userName" variant="text"></Skeleton>
        </div>
        <div className='creator-full-profile-container body-m'>
          <Skeleton width="214px" className="userName" variant="text"></Skeleton>
        </div>
      </div>
    );
  }

  if (showAppBar) {
    return (
      <div className='sticky-compare-creators-profile'>
        <div className='sticky-creator-profile-image-container'>
          <a target='_blank' href={profileURL}>
            {profileImage ? <Avatar alt='Profile Pic' src={profileImage} className='sticky-creator-profile-image' /> :
              <div className='creator-default-profile-image' ><Icons.male_profile_icon /></div>}
            {workPlatformId ? (
              <div className="sticky-creator-platform">
                <img width={24} height={24} src={WORKPLATFORM_ID_MAPPING[workPlatformId]?.platformLogo} alt={""} />
              </div>
            ) : null}
          </a>
        </div>
        <div className='creator-profile-identity-container'>
          <div className='creator-profile-container'>
            <div className='creator-profile-full-name'>
              <div className='sub-section-heading'>{fullName}</div>
              {isProfileVerified && (
                <div className='verified-logo-container'>
                  <Icons.verified_logo />
                </div>
              )}
            </div>
            <div className='creator-profile-user-name subtitle-r'>
              {!isEmpty(userName) ? (
                <a target='_blank' href={profileURL}>
                  @{userName}
                </a>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='compare-creators-profile'>
      <div className='creator-profile-image-container'>
        <a target='_blank' href={profileURL}>
          {profileImage ? <Avatar alt='Profile Pic' src={profileImage} className='creator-profile-image' /> :
            <div className='creator-default-profile-image' ><Icons.male_profile_icon /></div>}
        </a>
      </div>
      <div className='creator-profile-identity-container'>
        <div className='creator-profile-container'>
          <div className='creator-profile-full-name'>
            <div className='sub-section-heading'>{fullName}</div>
            {isProfileVerified && (
              <div className='verified-logo-container'>
                <Icons.verified_logo />
              </div>
            )}
          </div>
          <div className='creator-profile-user-name body-r'>
            <div className='work-platform-logos-container'>
              <a href={workPlatformHandle} target='_blank'>
                <div className='work-platform-logos-container'>
                  <img width='24px' height='24px' alt={workPlatformName} src={workPlatformLogo} />
                </div>
              </a>
            </div>
            {!isEmpty(userName) ? (
              <a target='_blank' href={profileURL}>
                @{userName}
              </a>
            ) : (
              '-'
            )}
          </div>
        </div>
        {profileBio && (
          <div className='compare-creator-profile-introduction'>
            {PROFILE_BIO_TEXT(profileBio)}
          </div>
        )}
      </div>
      <div className='creator-full-profile-container body-m'>
        <div onClick={() => window.open(CREATOR_PROFILE_LINK(userName, workPlatformId), '_blank')}>
          {FULL_PROFILE_LINK_TEXT}
        </div>
        <i className='ri-arrow-right-line color-neutrals-secondary-grey ri-lg' />
      </div>
    </div>
  );
}

export default CompareCreatorsProfile;
