import Icon from "../Icons/Icons";
import { InputField } from "../../components";
import PropTypes from 'prop-types';

import "./InputFieldWithCopyIcon.scss";
import React from "react";
import { useToast } from "../../hooks/useToast";
import { Tooltip } from "antd";
import { CREDENTIAL_COPY, trackEvent } from "../../analytics";
import _ from "lodash";
export default function InputFieldWithCopyIcon({ inputLabel, placeholder, errorMessage = "", ...otherParams }) {
  const toast = useToast();
  return (
    <div className="input-icons">
      <InputField inputLabel={inputLabel} placeholder={placeholder} errorMessage={errorMessage} {...otherParams} />
      <Tooltip title="Copy">
        <Icon.copy_icon
          className="icon-container"
          style={{ cursor: "pointer" }}
          onClick={() => {
            trackEvent(CREDENTIAL_COPY, { credential_type: _.toLower(inputLabel.split(" ").join("_")) });
            navigator.clipboard.writeText(otherParams.value);
            toast(inputLabel + " copied");
          }}
        />
      </Tooltip>
    </div>
  );
}


InputFieldWithCopyIcon.propTypes = {
  inputLabel: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.string.isRequired,
};

InputFieldWithCopyIcon.defaultProps = {
  placeholder: "",
  errorMessage: "",
};