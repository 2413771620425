import { Chip, TextField } from "@mui/material";
import Icons from "../Icons/Icons";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./TagsFilter.scss";
import { HASHTAGS, MENTIONS } from "../../constants/creatorDiscovery";

const TagsFilter = ({ type, selectedTags, getFilterValue, placeholder, isSuperPrimaryFilter, filterKey }) => {
  const [tags, setTags] = useState(selectedTags);

  // useEffect(() => {
  //   if (getFilterValue) getFilterValue(tags);
  // }, [tags]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      
      let tagsArray = event.target.value
        .split(',')
        .map(tag => tag.trim())
        .filter(tag => tag !== "");
  
      // Special handling for hashtags and Mentions filter
      tagsArray = tagsArray.map(tag => {
        if (filterKey === HASHTAGS && tag.charAt(0) === "#") {
          return tag.substring(1);
        } else if (filterKey === MENTIONS && tag.charAt(0) === "@") {
          return tag.substring(1);
        }
        return tag;
      });
  
      // Remove duplicate tags
      tagsArray = Array.from(new Set(tagsArray));
  
      if (tagsArray.length > 0) {
        if (type === "multi_select_text") {
          const uniqueTags = Array.from(new Set([...tags, ...tagsArray]));
          setTags(uniqueTags);
          if (getFilterValue) getFilterValue([...tags, ...tagsArray]);
        } else {
          if (tagsArray[0] !== tags) {
            setTags(tagsArray[0]);
            if (getFilterValue) getFilterValue(tagsArray[0]);
          }
        }
        event.target.value = "";
      }
    }
  };

  const handleDelete = (e, tagToDelete) => {
    e.preventDefault();
    if (type === "multi_select_text") {
      const newTags = tags.filter((tag) => tag !== tagToDelete);
      setTags(newTags);
      if (getFilterValue) getFilterValue(newTags);
    } else {
      setTags("");
      if (getFilterValue) getFilterValue("");
    }
  };

  return (
    <div className="tag-filter-container">
      <TextField
        className="my-textfield"
        onKeyDown={handleKeyDown}
        variant="outlined"
        fullWidth
        hiddenLabel
        placeholder={isSuperPrimaryFilter ? "Enter a keyword e.g. Baking" : placeholder}
        size="small"
        InputProps={{ className: "tag-filter-input" }}
        onClick={(e) => e.stopPropagation()}
        sx={{
          borderColor: "var(--main-primary)",
        }}
      />
      <div className="chip-container">
        {type === "multi_select_text"
          ? tags.map((tag, index) => (
              <Chip
                key={index}
                label={
                  filterKey === HASHTAGS && tag.charAt(0) !== "#" ? "#" + tag : filterKey === MENTIONS && tag.charAt(0) !== "@" ? "@" + tag : tag
                }
                sx={{ backgroundColor: "#F5F5F7", margin: "0px 4px 4px 0px" }}
                onDelete={(e) => {
                  handleDelete(e, tag);
                }}
                deleteIcon={
                  <Icons.icon_close
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                }
              />
            ))
          : tags && (
              <Chip
                label={tags}
                sx={{ backgroundColor: "#F5F5F7", margin: "0px 4px 4px 0px" }}
                onDelete={(e) => {
                  handleDelete(e, tags);
                }}
                deleteIcon={
                  <Icons.icon_close
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                }
              />
            )}
      </div>
    </div>
  );
};

TagsFilter.propTypes = {
  type: PropTypes.string,
  selectedTags: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  getFilterValue: PropTypes.func,
};

TagsFilter.defaultProps = {
  type: "string",
  selectedTags: "",
  getFilterValue: () => {},
};

export default TagsFilter;
