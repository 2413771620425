import React, { useState } from "react";

import { Background, Card, Typography, PasswordField, PrimaryButton, NavigationHeader } from "../../components";
import { Form } from "antd";
import "./SetNewPasswordPage.scss";
import {
  EMPTY_REQUIRED_FIELD_MESSAGE,
  INVALID_EMAIL_MESSAGE,
  AGREE_TERMS_AND_CONDITIONS,
  INVALID_PASSWORD_MESSAGE,
  PASSWORD_REGEX,
  pageTitle,
  getPageTitle,
  UPPER_LETTER_CHECK_REGEX,
  LOWER_LETTER_CHECK_REGEX,
  NUMBER_CHECK_REGEX,
} from "../../constants/constants";
import { setNewPasswordApi } from "../../api";
import useProfile from "../../hooks/useSetUserProfile";
import { useToast } from "../../hooks/useToast";
import { SET_NEW_PASSWORD_SUCCESS } from "../../constants/toastMessages";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import { AnalyticsContext, UserContext } from "../../storage/context";
import { useEffect } from "react";
import { RESET_PASSWORD_PAGE_VIEW, trackEvent } from "../../analytics";

export default function ResetPasswordPage() {
  const [formInstance] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const passwordRules = [
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject(EMPTY_REQUIRED_FIELD_MESSAGE);
        }
        const errors = [];
        if (!UPPER_LETTER_CHECK_REGEX.test(value)) {
          errors.push("one uppercase letter");
        }
        if (!LOWER_LETTER_CHECK_REGEX.test(value)) {
          errors.push("one lowercase letter");
        }
        if (!NUMBER_CHECK_REGEX.test(value)) {
          errors.push("one number");
        }
        if (!(value.length >= 8)) {
          errors.push("at least 8 characters");
        }
        if (errors.length > 0) {
          let errorMessage = `The password should contain at least `;
          if (errors.length === 1) {
            if (errors[0].indexOf("8 characters") !== -1) errorMessage += "8 characters";
            else errorMessage += `${errors[0]} `;
          } else {
            errors.forEach((e, i) => {
              if (i === errors.length - 1) {
                errorMessage = errorMessage.slice(0, -2);
                errorMessage += ` and ${e}`;
              } else {
                errorMessage += `${e}, `;
              }
            });
          }
          return Promise.reject(errorMessage);
        }
        return Promise.resolve();
      },
    },
  ];

  const passwordResponseHandler = (event) => {
    if (!event.data.password_changed) return;
    toast(SET_NEW_PASSWORD_SUCCESS);
  };

  const handleSetNewPassword = async () => {
    setIsLoading(true);
    const { password } = formInstance.getFieldsValue();
    try {
      const response = await setNewPasswordApi.setNewPassword({
        password,
        passwordResponseHandler,
      });
      // if (response.status === 200) {
      //   setUserProfile(response);
      //   verifyEmailIfNot(response);
      // }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Background className="set-new-password-page">
      <Helmet>
        <title>{getPageTitle("SET_NEW_PASSWORD_PAGE", "")}</title>
      </Helmet>
      <NavigationHeader isAuthenticated={false} isClickable={false} />

      <Form form={formInstance} className="container">
        <Card className="set-new-password-card">
          <Typography h2>Set new password</Typography>
          <Form.Item className="set-new-password-container" name="password" rules={passwordRules}>
            <PasswordField inputLabel="password" type="password" placeholder="Set new password" />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {/* Disabled button depends on the state of form input */}
            {() => (
              <PrimaryButton
                label="Proceed"
                className="proceed-button"
                onClick={handleSetNewPassword}
                htmlType="submit"
                loading={isLoading}
                disabled={!formInstance.isFieldsTouched(true) || formInstance.getFieldsError().filter(({ errors }) => errors.length).length > 0}
              />
            )}
          </Form.Item>
        </Card>
      </Form>
    </Background>
  );
}
