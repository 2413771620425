import Icon from "../Icons/Icons";
import { message, Tooltip } from "antd";
import "./InputFieldPassWithCopyIcon.scss";

import { PasswordField } from "../../components";
import { useToast } from "../../hooks/useToast";
import { CREDENTIAL_COPY, trackEvent } from "../../analytics";
import _ from "lodash";

export default function InputFieldPassWithCopyIcon({ inputLabel, placeholder, errorMessage = "", ...otherParams }) {
  const name = inputLabel.toLowerCase().replaceAll(" ", "_");
  const toast = useToast();
  return (
    <div className="input-icons-main-container">
      <PasswordField showTags={false} inputLabel={inputLabel} placeholder={placeholder} name={name} {...otherParams} id="password" />

      <Tooltip title="Copy">
        <Icon.copy_icon
          className="icon"
          style={{ cursor: "pointer" }}
          onClick={() => {
            trackEvent(CREDENTIAL_COPY, { credential_type: _.toLower(inputLabel.split(" ").join("_")) });
            toast(placeholder + " copied");
            navigator.clipboard.writeText(otherParams.value);
          }}
        />
      </Tooltip>
    </div>
  );
}
