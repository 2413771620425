import React from "react";
import { Route, Routes } from "react-router-dom";
import { AddPostLinks, AnalysisPage, CampaignPage, CreateCampaignPage, TrackingPage } from "../pages";
import SelectCreatorsPage from "../pages/CampaignTrackingPage/SelectCreatorsPage";
import AutomaticallyAddCreatorsPage from "../pages/CampaignTrackingPage/AutomaticallyAddCreatorsPage";
import CreateUtmLinks from "../pages/CampaignTrackingPage/CreateUtmLinks";
import CreatorCostPage from "../pages/CampaignTrackingPage/CreatorCostPage";
import CreatorUtmLinksPage from "../pages/CampaignTrackingPage/CreatorUtmLinksPage";

export default function CampaignTrackingRoutes() {
    return (
        <Routes path="">
            <Route path="" element={<TrackingPage/>}/>
            <Route path="*" element={<TrackingPage/>}/>
            <Route path="/add-post-links" element={<AddPostLinks/>}/>
            <Route path="/add-post-links/:campaignId" element={<AddPostLinks/>}/>
            <Route path="/automatically-add-creators" element={<AutomaticallyAddCreatorsPage/>}/>
            <Route path="/automatically-add-creators/:campaignId" element={<AutomaticallyAddCreatorsPage/>}/>
            <Route path="/campaign-content-view/:campaignId/:contentId" element={<AnalysisPage/>}/>
            <Route path="/campaign-view/:campaignId" element={<CampaignPage/>}/>
            <Route path="/create-campaign" element={<CreateCampaignPage/>}/>
            <Route path="/create-utm-links/:storeId" element={<CreateUtmLinks/>}/>
            <Route path="/create-utm-links/:campaignId/:storeId" element={<CreateUtmLinks/>}/>
            <Route path="/creator-cost-page/:campaignId" element={<CreatorCostPage/>}/>
            <Route path="/edit-campaign/:campaignId" element={<CreateCampaignPage/>}/>
            <Route path="/edit-post-links/:campaignId" element={<AddPostLinks/>}/>
            <Route path="/edit-utm-links/:campaignId" element={<CreateUtmLinks/>}/>
            <Route path="/edit-utm-links/:campaignId/:storeId" element={<CreateUtmLinks/>}/>
            <Route path="/manage-creator-cost/:campaignId" element={<CreatorCostPage/>}/>
            <Route path="/manage-utm-links/:campaignId" element={<CreatorUtmLinksPage/>}/>
            <Route path="/select-creators" element={<SelectCreatorsPage/>}/>
            <Route path="/utm-links/:campaignId" element={<CreatorUtmLinksPage/>}/>
            <Route path="/content-view" element={<AnalysisPage/>}/>
        </Routes>
    );
}
