import { Alert, Box, IconButton, Snackbar, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { trackEvent, TRACKING_CAMPAIGN_CONTENTCSV_UPLOADED } from "../../analytics";
import { postBulkInvites } from "../../api/creatorinvitations/creatorinvitations";
import { csvUploadContent, csvUploadErrors, dragAndDropButtonCTA, dragAndDropFile, getCSVfileDetails, invalidLink, issue, sampleCsvUrls } from "../../constants/campaignTrackingConstants";
import { csvRowsLimit, EMAIL_REGEX } from "../../constants/constants";
import { useToast } from "../../hooks/useToast";
import { WarehouseContext } from "../../storage/context";
import { validatePostUrl } from "../../utils/profileHelpers";
import { downloadContent, downloadSampleCSVFile } from "../../utils/util";
import Icons from "../Icons/Icons";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import "./CsvUpload.scss"

function CsvUpload({ csvFile, SetCSVFile, setCsvUrls, csvUrls, fileHeader, showTooltip=false }) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorUrlsCount, setErrorUrlsCount] = useState(0)
  const toast = useToast();
  const [openInviteBar, setOpenInviteBar] = useState(false);

  function validateHeaders(header) {
    if (header[0].toLowerCase() !== fileHeader) {
      return false;
    }
    return true;
  }

  function readCSV(ev) {
    const csvdata = ev.target.result;

    let rowsData = csvdata.split("\n");
    if (rowsData.length < 2) {
      setErrorMsg(csvUploadErrors.emptyFile);
      SetCSVFile(null);
      return;
    }
    rowsData = rowsData.filter((row) => row.trim()); // remove empty rows
    let header = rowsData[0].trim();
    header = header.split(",");
    header = header.filter((col) => col.trim());
    header = header.map((col) => col.trim());
    if (header.length !== 1 || !validateHeaders(header)) {
      // Todo :  will update the errors once get clarity from design on error msg
      setErrorMsg(<>{csvUploadErrors.structureError}  <div className="check-details-but" onClick={() => downloadSampleCSVFile(sampleCsvUrls, fileHeader)} ><u>file template</u></div></>);
      SetCSVFile(null);
      return;
    }

    // limit the urls to 100 + 1(for header)
    if (rowsData.length > csvRowsLimit) {
      setErrorMsg(csvUploadErrors.csvLimitExceded);
      SetCSVFile(null);
      return;
    }


    const csvUrlObjects = [];
    for (let row = 1; row < rowsData.length; row++) {
      if (rowsData[row].trim().length < 1) {
        continue;
      }
      const data = rowsData[row].split(",");
      let url = data[0];
      url = url.replace(/"/g, "").replace(/\n/g, "").replace(/\r/g, "");
      url = url.trim();


      const urlExists = csvUrlObjects.some((urlObject) => urlObject.url === url);

      // If the URL doesn't exist, create and push the URL object
      if (!urlExists) {
        const isValid = validatePostUrl(url);
        const urlObject = {
          url: url,
          isValid: isValid
        };

        csvUrlObjects.push(urlObject);
      }

    }

    let invalidCount = 0;

    csvUrlObjects.forEach(entry => {
      if (!entry.isValid) {
        invalidCount++;
      }
    });
    setErrorUrlsCount(invalidCount)

    setCsvUrls(csvUrlObjects)
  }

  function validateCSV(file) {
    if (file.type !== "text/csv") {
      setErrorMsg(csvUploadErrors.fileTypeIncorrect);
      return false;
    }
    return true;
  }
  function handleCSVUpload(file) {
    setErrorMsg(null);


    if (file && validateCSV(file)) {

      trackEvent(TRACKING_CAMPAIGN_CONTENTCSV_UPLOADED, { file_name: file.name })

      SetCSVFile(file);

      //read csv
      const fr = new FileReader();
      fr.onload = readCSV;
      fr.readAsText(file);
    }
  }

  function dropHandler(ev) {
    ev.preventDefault();
    // if file already uploaded return
    if (csvFile) {
      return;
    }
    let file = ev.dataTransfer.files[0];
    handleCSVUpload(file)
  }

  useEffect(() => {
    window.addEventListener(
      "dragover",
      function (e) {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
      },
      false
    );
  });

  const closeInviteBar = () => {
    setOpenInviteBar(false);
  };


  function downloadInvalidLinks() {
    const invalidUrls = csvUrls.filter(item => !item.isValid);

    // Prepare CSV content
    const csvContent = `${fileHeader},${issue}\n${csvUrls.map(item => `${item.url},${item.isValid ? "-" : invalidLink}`).join('\n')}`;

    // Trigger the download using the general download function
    downloadContent(csvContent, `Invalid ${fileHeader}.csv`, 'text/csv');
  }



  return (
    <div className="campaign-csv-upload-container">
      <Box
        className={!csvFile && `upload-csv-container`}
        draggable="true"
        onDrop={dropHandler}
        onClick={(e) => {
          if (!csvFile && e.currentTarget === e.target) document.getElementById("upload-csv-input").click();
        }}
      >
        {csvFile && (
          <Box className={`csv-file-uploaded-container ${errorUrlsCount > 0 && "error-csv-uploaded"}`}  >
            <Box className="file-name-icon-container">
              <Box className="csv-upload-icon" > {errorUrlsCount > 0 ? <i className="ri-upload-2-line"></i> : <i class="ri-check-fill"></i>}</Box>
              <Box className="csv-filename">
                <Box className="subtitle-r" >{csvUploadContent.fileUploaded}</Box>
                <Box className="body-m" >{csvFile.name}</Box>
              </Box>
            </Box>
            <Box>
              <Box onClick={() => {
                SetCSVFile(null);
                setErrorUrlsCount(null);
                setErrorMsg(null);
                setCsvUrls([])
              }}
                className="remove-csv body-m" ><i className="ri-delete-bin-line"></i> {csvUploadContent.removeFile}</Box>
            </Box>
          </Box>
        )}
        {!csvFile && (
          <>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              className="upload-csv-icon-container"
            >
              <input
                hidden
                accept={".csv"}
                type="file"
                id="upload-csv-input"
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.target.value = null;
                }}
                onChange={(ev) => handleCSVUpload(ev.target.files[0])}
                name="media"
              />
              <PrimaryButton
                label={<><i className="ri-upload-2-line"></i> {dragAndDropButtonCTA}</>}
                className="csv-upload-but body-m"
                onClick={() => {
                  const fileInput = document.getElementById("upload-csv-input");
                  if (fileInput) {
                    fileInput.click(); // Trigger the file input click event
                  }
                }}
              />
            </IconButton>
            <Box className="body-r csv-desc-tooltip " >{dragAndDropFile} {showTooltip ? <Tooltip placement='right-end' title={<>
              <Icons.campaign_csv /> </>} ><Box><i className="ri-information-line info-icon"></i></Box></Tooltip> : null} </Box>
          </>
        )}
      </Box>
      {csvFile ? <Box className={`uploaded-csv-file-details subtitle-m ${errorUrlsCount > 0 && "uploaded-csv-file-error"}`}>
        {errorUrlsCount > 0 ? <Icons.caution_red /> : null}{getCSVfileDetails(errorUrlsCount, csvUrls.length)}
        {errorUrlsCount > 0 ? <div className="check-details-but" onClick={downloadInvalidLinks} ><u>Check details</u></div> : null}
      </Box> : null}
      {errorMsg ? <Box className={`uploaded-csv-file-details subtitle-m uploaded-csv-file-error`}>
        <Icons.caution_red /> {errorMsg}
      </Box> : null}
    </div>
  );
}

export default CsvUpload;
