import React, { useContext, useReducer } from "react";

import "./MainLayout.scss";
import { Divider, Layout, Menu, Popover, Modal, Tooltip } from "antd";
import Icon from "../Icons/Icons";
import { Route, useLocation, useNavigate } from "react-router-dom";
import Typography from "../Typography/Typography";
import Card from "../Card/Card";
import InvertedPrimaryButton from "../InvertedPrimaryButton/InvertedPrimaryButton";
import { Box, Tabs, Tab } from "@mui/material";

// import { userProfileContext } from "../../context/states/index";
import useProfile from "../../hooks/useSetUserProfile";
import Icons from "../Icons/Icons";

import { contactSalesApi, loginApi } from "../../api/index";
import UserContext from "../../storage/context/userProfileContext";
import { WarehouseContext } from "../../storage/context";
import { useState } from "react";
import { useEffect } from "react";
import Sidebar from "./Sidebar";
import { API_DOCS_CLICK, DISCORD_CLICK, LOGOUT_CLICK, SIDEBAR_GO_LIVE_CLICK, SCHEDULE_A_CALL_CLICK, trackEvent } from "../../analytics";
import {
  creatorLinkageProductID,
  creatorModuleModalContent,
  creatorModuleSpecificTenantID,
  Creators,
  creatorSearchProductID,
  developerModuleModalContent,
  Developers,
  MEETING_SCHEDULE_LINK,
  Production,
  Sandbox,
  Staging,
  TRIAL,
  trialTopHeaderInfoCreatorDeveloper,
} from "../../constants/constants";
import { getEnvBaseURL } from "../../utils/util";
import { ADD_ENVIRONMENT, SHOW_TOP_HEADER, UPDATE_ANALYTICS, UPDATE_ENVIRONMENT_BASE_URL } from "../../storage/reducers/types";
import LinkText from "../LinkText/LinkText";
import HeaderTopNav from "../HeaderTopNav/HeaderTopNav";
import TrialAccessPopUp from "../TrialAccessPopUp/TrialAccessPopUp";
import { analyticsReducer } from "../../storage/reducers";
import { Colors } from "../../styles/colors";

// import WebhookPage from "../WebhookPage/WebhookPage";
// import HelpPage from "../HelpPage/HelpPage";

const { Header, Content, Sider } = Layout;

const ContactSalesModalConfig = {
  title: (
    <Typography h3 style={{ fontWeight: "600" }}>
      Thank you for applying for production access to insightIQ APIs! Our team will be in touch with you shortly. Thank you for applying for
      production access to insightIQ APIs! Our team will be in touch with you shortly.
    </Typography>
  ),
  content: (
    <div>
      <p className="contact-sales-card-description">
        In the meanwhile, if you have any questions feel free to drop a line to{" "}
        <a href="mailto:support@insightiq.ai">
          <u>support@insightiq.ai</u>
        </a>{" "}
        or
        <a href={MEETING_SCHEDULE_LINK} target="_blank" rel="noreferrer">
          {" "}
          <u>book a meeting</u>
        </a>{" "}
        with our product experts.{" "}
      </p>
      <p className="contact-sales-card-description">Happy building!</p>
    </div>
  ),
  closeIcon: <Icons.close_circle_fill />,
  closable: true,
  icon: false,
  cancelText: null,
  okType: "text",
  okButtonProps: { style: { backgroundColor: "transparent", height: "65px" } },
  okText: <InvertedPrimaryButton label="Got it!" />,
};

const TabValues = ["Creators", "Developers"];
const indexToTabName = {
  creators: 0,
  developers: 1,
};
const tabNameToIndex = {
  0: "creators",
  1: "developers",
};

const getPathName = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

const MainLayout = ({ children, className = "" }) => {
  const navigate = useNavigate();
  const { logoutUserProfile } = useProfile();
  const location = useLocation();
  const [showHeaderInfo, setShowHeaderInfo] = useState(false);
  // If true, makes the top info bar scrollable, false otherwise.
  const [isHeaderScrollable, setHeaderScrollable] = useState(false);

  // const [loading, setIsLoading] = useState(false);
  const {
    warehouse: { tenantInfo, productionKeys },
    warehouse,
    dispatchWarehouse,
  } = useContext(WarehouseContext);
  const { userProfile } = useContext(UserContext);

  //setting the default tab value based on the route value
  const [value, setValue] = useState(
    location.pathname.includes(`/${getPathName(Developers)}`) ? indexToTabName["developers"] : indexToTabName["creators"]
  );
  const [analytics, dispatchAnalytics] = useReducer(analyticsReducer, JSON.parse(localStorage.getItem("inisightiq_analytics")));
  const [isCreatorModalLoaded, setIsCreatorModalLoaded] = useState(false);
  const [isDeveloperModalLoaded, setIsDeveloperModalLoaded] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(() => {
      return newValue;
    });
    navigate(`/${tabNameToIndex[newValue]}`);
  };

  const onContactSalesClick = async () => {
    try {
      trackEvent(SIDEBAR_GO_LIVE_CLICK, { email: userProfile.user.email });
      Modal.info(ContactSalesModalConfig);
      await contactSalesApi.contactSales();
    } catch (e) {
      console.log(e);
      // do nothing for now
    }
  };

  const handleLogout = async () => {
    // setIsLoading(true);
    trackEvent(LOGOUT_CLICK);
    try {
      await loginApi.signOutUser();
      logoutUserProfile();
    } catch (e) {
      // do nothing
      // setIsLoading(true);

      console.log(e, "logout error");
    }
  };

  useEffect(() => {
    let containsTrialValue = true;
    for (const product in warehouse.tenantAppProducts) {
      if (warehouse.tenantAppProducts[product] !== TRIAL) {
        containsTrialValue = false;
        break;
      }
    }
    let isLoaded = JSON.parse(localStorage.getItem("isLoaded"));
    if (isLoaded) {
      if (TabValues[value] === Creators && containsTrialValue && !isLoaded.creator) setIsCreatorModalLoaded(true);
      if (TabValues[value] === Developers && !productionKeys.isAvailable && !isLoaded.developer) setIsDeveloperModalLoaded(true);
    } else {
      let isLoaded = {
        creator: false,
        developer: false,
      };
      localStorage.setItem("isLoaded", JSON.stringify(isLoaded));
      if (TabValues[value] === Creators && containsTrialValue) setIsCreatorModalLoaded(true);
      if (TabValues[value] === Developers && !productionKeys.isAvailable) setIsDeveloperModalLoaded(true);
    }

    // This is to Check and set the default environment for Developer module
    // This also sets the TopHeader Info
    if (TabValues[value] === Developers && !productionKeys.isAvailable) {
      // Update env only when it is Production
      if (warehouse.environment.current === Production) {
        dispatchWarehouse({ type: ADD_ENVIRONMENT, payload: Staging });
        dispatchWarehouse({
          type: UPDATE_ENVIRONMENT_BASE_URL,
          payload: getEnvBaseURL(Staging),
        });
      }
      dispatchWarehouse({
        type: SHOW_TOP_HEADER,
        payload: {
          title: trialTopHeaderInfoCreatorDeveloper[warehouse.environment.current],
          backgroundColor: Colors.semanticsSecondaryAlert,
        },
      });
    }
  }, []);

  const handleCloseModal = () => {
    let isLoaded = JSON.parse(localStorage.getItem("isLoaded"));
    if (TabValues[value] === Creators) {
      setIsCreatorModalLoaded(false);
      isLoaded.creator = true;
      localStorage.setItem("isLoaded", JSON.stringify(isLoaded));
    } else {
      setIsDeveloperModalLoaded(false);
      isLoaded.developer = true;
      localStorage.setItem("isLoaded", JSON.stringify(isLoaded));
    }
  };

  useEffect(() => {
    if (!userProfile.user.token) {
      navigate("/sign-in");
      // For updating the analytics in localStorage
      navigate(0);
    }
  }, [userProfile]);

  useEffect(() => {
    setShowHeaderInfo(!!warehouse.headerTopNavInfo.showHeader);
    setHeaderScrollable(!!warehouse.headerTopNavInfo.scrollable);
  }, [warehouse.headerTopNavInfo]);

  useEffect(() => {
    if (TabValues[value] === Developers && !productionKeys.isAvailable) {
      if (warehouse.environment.current === Staging) {
        dispatchWarehouse({
          type: SHOW_TOP_HEADER,
          payload: {
            title: trialTopHeaderInfoCreatorDeveloper.Staging,
            backgroundColor: Colors.semanticsSecondaryAlert,
          },
        });
      } else if (warehouse.environment.current === Sandbox) {
        dispatchWarehouse({
          type: SHOW_TOP_HEADER,
          payload: {
            title: trialTopHeaderInfoCreatorDeveloper.Sandbox,
            backgroundColor: Colors.semanticsSecondaryAlert,
          },
        });
      }
    }
  }, [warehouse.environmentChanged]);

  const headerInfoStyles =
    showHeaderInfo && !isHeaderScrollable
      ? {}
      : {
          width: "260px",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "1",
        };

  function showHeaderTopNav() {
    return showHeaderInfo ? (
      <HeaderTopNav
        title={warehouse.headerTopNavInfo.title}
        backgroundColor={warehouse.headerTopNavInfo.backgroundColor}
        textClass={warehouse.headerTopNavInfo.textClass}
      />
    ) : null;
  }

  return (
    <Layout style={{ height: "100vh" }}>
      <Header className="main-header" style={headerInfoStyles}>
        {/* <Modal okButtonProps=/> */}
        <div className="header-sub-container">
          <div className="insight_iq_icon_with_name" >
            <Icon.insight_iq_icon_with_name className="main-logo" />
          </div>
          <div>
            {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="tabs">
              <Tab className="tab" label="Creators" value={0} />
              <Tab className="tab" label="For Developers" value={1} />
            </Tabs> */}
          </div>
        </div>
        {!isHeaderScrollable ? showHeaderTopNav() : null}
        <div className="header-right-title-section">
          {/* <LinkText title="Schedule a call" event={SCHEDULE_A_CALL_CLICK} to={MEETING_SCHEDULE_LINK} isLinkIconPresent /> */}
          {/* <LinkText title="Discord Community" event={DISCORD_CLICK} to="https://discord.gg/FqPgyeWu37" /> */}
          {/* <LinkText
            title="Docs"
            event={API_DOCS_CLICK}
            to="https://docs.insightiq.ai/docs/developer-docs/API/getting-started-with-APIs"
            isLinkIconPresent
          /> */}
          {/* <Popover
            arrowPointAtCenter={false}
            placement="bottomRight"
            overlayInnerStyle={{
              backgroundColor: "#222838",
              borderRadius: "4px",
              padding: "8px",
              textOverflow: "ellipsis",
            }}
            content={
              <LogoutPopoverContent
                email={userProfile.user.email}
                name={userProfile.user.firstName + " " + (userProfile.user.lastName ? userProfile.user.lastName : "")}
                onPressLogout={handleLogout}
              />
            }
          >
            <Icon.profile className="profile-icon" />
          </Popover> */}
        </div>
      </Header>

      <Layout style={{ height: "100%" }}>
        <Sidebar onContactSalesClick={onContactSalesClick} currentTab={TabValues[value]} headerInfo={{ showHeaderInfo, isHeaderScrollable }} />

        <Content className={`site-layout-background ${className} content-section`}>
          {/* {TabValues[value] === Developers && (
            <div
              className="site-layout-environment-specifier"
              style={
                warehouse.environment.current === Staging
                  ? {
                      backgroundColor: "#BEE4FF",
                    }
                  : warehouse.environment.current === Production
                  ? {
                      backgroundColor: "#96EAC6",
                      height: "2px",
                    }
                  : { backgroundColor: "#FDEDBE" }
              }
            >
              {warehouse.environment.current !== Production && <span>You are in {warehouse.environment.current.toLowerCase()} mode.</span>}
            </div>
          )} */}
          <div className="scrolling-page-content">
            {isHeaderScrollable ? showHeaderTopNav() : null}
            <div className="page-content">{children}</div>
          </div>
        </Content>
        <TrialAccessPopUp
          open={isCreatorModalLoaded}
          description={creatorModuleModalContent}
          onClose={handleCloseModal}
          header={`Welcome, ${userProfile.user.firstName}!`}
          subheader="Get started for free"
          icon={<Icons.marketer_pop_up_icon />}
          buttonAction={handleCloseModal}
          height="570px"
        />
        <TrialAccessPopUp
          open={isDeveloperModalLoaded}
          description={developerModuleModalContent}
          onClose={handleCloseModal}
          header={"Welcome to InsightIQ developers"}
          subheader={"Get started for free"}
          icon={<Icons.developer_pop_up_icon />}
          buttonAction={handleCloseModal}
          height="520px"
        />
      </Layout>
    </Layout>
  );
};

const LogoutPopoverContent = ({ name = "", email = "", onPressLogout }) => {
  return (
    <Card className="logout-popover">
      <Typography h1 className="profile-popup-user-name">
        {name}
      </Typography>
      <p className="profile-email">{email}</p>
      <Divider
        style={{
          background: "#414251",
          marginTop: "10px",
          marginBottom: "16px",
        }}
      />
      <button onClick={onPressLogout} className="logout-button-container">
        <Icon.exit_logout />
        Logout
      </button>
    </Card>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ padding: "0px" }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default MainLayout;
