import "./CreatorListSidebar.scss";
import { Box, Drawer, Skeleton, Tooltip } from "@mui/material";

import {
  clearSelection,
  CONTACT_US,
  creatorsSelected,
  DONE,
  listSelected,
  unlockAccessPopupDetails,
} from "../../constants/campaignTrackingConstants";
import TextBox from "../InsightIQ/TextBox/TextBox";
import {
  CheckBox,
  EmptyTableScreen,
  ExportCSVPopUp,
  ExportLoadingModal,
  Icons,
  ModalDialog,
  PrimaryButton,
  SelectedCreatorList,
  ToastMessage,
  UnlockFullAccessPopUp,
  UserRow,
} from "../index";
import { useContext, useRef, useState } from "react";
import { CreatorDiscoveryWarehouseContext, UserContext, WarehouseContext } from "../../storage/context";
import {
  createNewList,
  createNewListButtonText,
  Creators,
  creatorSearchProductID,
  DATE_FORMAT,
  DATE_TIME_FORMAT_WITHOUT_TIME,
  EMAIL_REGEX,
  NoCreatorListDesc,
  NoCreatorListTittle,
  NoCreatorsDesc,
  NoCreatorsTitle,
  REMOVE_TEXT,
  TRIAL,
} from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  createList,
  exportCreatorList,
  exportFavouriteList,
  getAllCreatorsInList,
  getAllFavouriteCreators,
} from "../../api/creatorDiscoveryAPI/creatorDiscoveryAPI";
import {
  ADD_LIST_TOAST_MESSAGE_DURATION,
  createNewListDesc,
  createNewListHeader,
  createNewListPlaceholder,
  deleteText,
  dublicateListError,
  enterMail,
  exportListPopupDesc,
  favourite,
  listNameText,
  maximumCharError,
  removeFromList,
  SEARCH_CREATORS,
  SEARCH_LIST,
} from "../../constants/creatorDiscovery";
import { TryCatch } from "@sentry/react";
import moment from "moment";
import { isEmpty } from "../../utils/util";
import { CREATOR_EXPORTED, CREATOR_IN_LIST_SEARCHED, LIST_EXPORTED, LIST_VIEWED, NEW_LIST_CREATED, trackEvent } from "../../analytics";
import { UPDATE_CREATOR_LISTS_SELECTED_CREATORS } from "../../storage/reducers/types";

export default function CreatorListSidebar({
  onClose,
  isOpen,
  heading,
  onAddMoreAccounts,
  onSearch,
  rows,
  csvProfileUrls,
  onCheckBoxClick,
  clearAllSelection,
  onDeleteCreatorLists,
  onCreatorDelete,
  creatorListLoading,
  handleCreatorListClick,
  setIsCreatorDrawerOpen,
}) {
  const DRAWER_WIDTH = 590;
  const { warehouse } = useContext(WarehouseContext);
  const [searchText, setSearchText] = useState("");
  const [creatorsSearchText, setCreatorsSearchText] = useState("");
  const [creators, setCreators] = useState([]);
  const [filteredCreators, setFilteredCreators] = useState([]);
  const listsCheckedCount = rows?.filter((item) => item.checked).length;
  const creatorsCheckedCount = filteredCreators?.filter((item) => item.checked).length;
  const { creatorDiscoveryWarehouse, dispatchCreatorDiscoveryWarehouse } = useContext(CreatorDiscoveryWarehouseContext);
  let selectedListCreatorInfo = creatorDiscoveryWarehouse.selectedListCreatorData;
  const [selectedList, setSelectedList] = useState({
    isListSelected: isEmpty(selectedListCreatorInfo?.selectedListData) ? false : true,
    list: selectedListCreatorInfo?.selectedListData,
  });
  const [loadingCreators, setLoadingCreators] = useState(false);
  const [exportCreatorsState, setExportCreatorsState] = useState({});
  const { userProfile } = useContext(UserContext);
  const [exportMail, setExportMail] = useState(sessionStorage.getItem("exportMail") ? sessionStorage.getItem("exportMail") : userProfile.user.email);
  const [exportMailError, setExportMailError] = useState("");
  const [openToast, setOpenToast] = useState({
    open: false,
    message: "",
    duration: ADD_LIST_TOAST_MESSAGE_DURATION,
    type: "",
  });
  const [createNewListPopup, setCreateNewListPopup] = useState(false);
  const [listName, setListName] = useState("");
  const [listNameError, setListNameError] = useState({
    hasError: false,
    errorMessage: "",
  });
  const navigate = useNavigate();
  const [modalDetails, setModalDetails] = useState({
    isModalVisible: false,
    modalInfo: "",
  });
  const isTrialMode = warehouse.tenantAppProducts[creatorSearchProductID] === TRIAL;
  const [createListLoading, setCreateListLoading] = useState(false);
  const [selectedAllChecked, setSelectedAllChecked] = useState(false);

  const NoCreatorListsState = () => {
    return (
      <div style={{ margin: "48px" }}>
        <span className={"section-heading selected-creator-title"}>{heading}</span>
        <div className="close-icon-container" style={{ top: "3%", right: "5%" }}>
          <Icons.close_icon onClick={onDrawerClose} cursor="pointer" />
        </div>
        <Box className="campaign-error-content no-creator-list-state ">
          <EmptyTableScreen
            icon={<Icons.no_creator_list_icon />}
            header={NoCreatorListTittle}
            content={NoCreatorListDesc}
            actionButtonContent={
              <div className="create-new-list-button-text">
                {" "}
                <i class="ri-add-line"></i> {createNewList}{" "}
              </div>
            }
            actionButtonClassName={"secondary-button"}
            isActionButton={true}
            actionButtonHandler={() => {
              if (isTrialMode) {
                setModalDetails({
                  ...modalDetails,
                  isModalVisible: true,
                  modalInfo: unlockAccessPopupDetails.addCreatorList,
                });
              } else {
                setCreateNewListPopup(true);
              }
            }}
          />
        </Box>
      </div>
    );
  };
  const NoCreatorsState = () => {
    return (
      <div>
        <Box className="campaign-error-content no-creator-list-state ">
          <EmptyTableScreen
            icon={<Icons.start_adding_creator />}
            header={NoCreatorsTitle}
            content={NoCreatorsDesc}
            actionButtonClassName={"secondary-button"}
            isActionButton={false}
          />
        </Box>
      </div>
    );
  };

  const getAllCreatorsFromList = async (listId) => {
    if (!rows.find((item) => item.id === listId)) {
      setSelectedList({ list: {}, isListSelected: false });
    }
    setLoadingCreators(true);
    try {
      let creators;
      if (selectedList.list?.id == favourite.toLocaleLowerCase()) {
        creators = await getAllFavouriteCreators({ warehouse });
      } else {
        creators = await getAllCreatorsInList({ warehouse, listId });
      }
      const updatedList = creators?.data?.map((item) => {
        item.thumbnail_url = item.image_url;
        item.isDeleteAllowed = true;
        item.checked = false;
        return item;
      });
      dispatchCreatorDiscoveryWarehouse({
        type: UPDATE_CREATOR_LISTS_SELECTED_CREATORS,
        payload: { ...creatorDiscoveryWarehouse.selectedListCreatorData, selectedListCreators: updatedList },
      });
      setCreators(updatedList);
      setFilteredCreators(updatedList);
      return creators;
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingCreators(false);
    }
  };

  useEffect(() => {
    if (isEmpty(rows)) {
      setLoadingCreators(true);
      return;
    }
    if (selectedList.isListSelected && selectedList?.list?.id) {
      let selectedListCreatorInfo = creatorDiscoveryWarehouse.selectedListCreatorData;
      if (!isEmpty(selectedListCreatorInfo?.selectedListData)) {
        getAllCreatorsFromList(selectedListCreatorInfo?.selectedListData?.id);
      } else {
        getAllCreatorsFromList(selectedList?.list?.id);
      }
      trackEvent(LIST_VIEWED, { name: selectedList?.list?.name });
    }
  }, [selectedList, rows]);

  function onCreatorSearch(searchText) {
    let filteredCreators = creators?.filter((list) =>
      (isEmpty(list["full_name"]) ? list["platform_username"] : list["full_name"])?.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredCreators(filteredCreators);
    trackEvent(CREATOR_IN_LIST_SEARCHED, { query: searchText, result_count: filteredCreators?.length });
  }
  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast({
      open: false,
      message: "",
      duration: ADD_LIST_TOAST_MESSAGE_DURATION,
      type: "success",
    });
  };

  const onCreatorExport = async () => {
    if (!EMAIL_REGEX.test(exportMail)) {
      setExportMailError({
        hasError: true,
        errorMessage: "Please enter a valid email",
      });
      return;
    }
    const { listId, creatorIds } = exportCreatorsState;
    try {
      setExportCreatorsState({ isExportModalOpen: false, listId: "", creatorIds: [] });
      const payload = {
        receiver_info: {
          name: `${userProfile.user.firstName + " " + (userProfile.user.lastName ? userProfile.user.lastName : "")}`,
          email: exportMail,
        },
        creator_ids: creatorIds,
      };

      if (listId == favourite.toLocaleLowerCase()) {
        await exportFavouriteList({ warehouse, payload });
      } else {
        await exportCreatorList({ warehouse, listId, payload });
      }
      sessionStorage.setItem("exportMail", exportMail);
      setOpenToast({
        open: true,
        message: (
          <>
            <b>List has been sent to your email</b>
          </>
        ),
        duration: ADD_LIST_TOAST_MESSAGE_DURATION,
        type: "success",
      });
      if (creatorIds.length > 0) {
        trackEvent(CREATOR_EXPORTED, { name: filteredCreators.find((item) => item.id === creatorIds[0])?.platform_username });
      } else {
        trackEvent(LIST_EXPORTED, { name: rows.find((item) => item.id === listId)?.name });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onBackClick = () => {
    setSelectedList({ list: {}, isListSelected: false });
    setCreators([]);
    setFilteredCreators([]);
    setSelectedAllChecked(false);
  };

  const renderCreatorsFromList = () => {
    if (!selectedList.isListSelected) return;

    const { id, name, count_of_creators, created_at, isDeleteAllowed } = selectedList?.list;

    return (
      <>
        {loadingCreators ? (
          <CreatorListLoading />
        ) : (
          <div style={{ height: "100%" }} className={"side-drawer-custom-class"}>
            <Box sx={{ padding: "0px 0px 24px 0px" }} id="overview-section">
              {<i className="ri-arrow-left-line sidebar-back-icon" onClick={onBackClick} />}
            </Box>
            <div className="close-icon-container" style={{ top: "3%", right: "5%" }}>
              <Icons.close_icon onClick={onDrawerClose} cursor="pointer" />
            </div>

            {creatorsCheckedCount === 0 ? (
              <div className="creator-list-top-bar">
                <div>
                  <span className={"title selected-creator-title"}>{name}</span>
                  <span className={"body-r selected-creator-count-and-date"}>
                    {creators.length} {creators.length == 1 ? "creator" : "creators"}
                    {created_at ? " · Created " + moment.utc(created_at).format(DATE_TIME_FORMAT_WITHOUT_TIME) : ""}
                  </span>
                </div>

                <div className="list-operations">
                  {isDeleteAllowed && (
                    <Tooltip title="Delete list" placement="top-start">
                      <div>
                        <i
                          className="ri-delete-bin-line"
                          onClick={(e) => {
                            e.stopPropagation();
                            onDeleteCreatorLists([id]);
                          }}
                          style={{ fontSize: 24, cursor: "pointer" }}
                        ></i>
                      </div>
                    </Tooltip>
                  )}
                  <Tooltip title="Export list as CSV" placement="top-end" classes={{ tooltip: "MuiTooltip-tooltip custom-tooltip" }}>
                    <div>
                      <i
                        onClick={(e) => {
                          e.stopPropagation();
                          setExportCreatorsState({ isExportModalOpen: true, listId: id, creatorIds: [] });
                        }}
                        className="ri-share-forward-line"
                        style={{ fontSize: 24, cursor: "pointer" }}
                      ></i>
                    </div>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <>
                <div className="clear-selection-check-count-div">
                  <span className={"section-heading title "}>{creatorsCheckedCount + " " + creatorsSelected(creatorsCheckedCount)}</span>
                  <div
                    onClick={() => {
                      const updatedCreatorList = filteredCreators?.map((item) => {
                        return { ...item, checked: false };
                      });
                      setFilteredCreators(updatedCreatorList);
                      setSelectedAllChecked(false);
                    }}
                    className={"section-heading clear-selection "}
                  >
                    {clearSelection}
                  </div>
                </div>
                <div style={{ marginTop: "32px" }}>
                  <RenderDeleteButton
                    buttonText={removeFromList}
                    onDeleteClick={() => {
                      let creatorIds = filteredCreators.filter((item) => item.checked).map((item) => item.id);
                      let deletedCreators = filteredCreators
                        .filter((creator) => creatorIds.includes(creator.id))
                        .map((creator) => creator.platform_username);
                      onCreatorDelete({
                        listId: selectedList.list.id,
                        creatorIds: creatorIds,
                        creatorNames: deletedCreators,
                      });
                    }}
                  />
                </div>
              </>
            )}

            {filteredCreators.length == 0 && creatorsSearchText.length === 0 ? (
              <NoCreatorsState />
            ) : (
              <>
                <div style={{ margin: "24px 0px" }}>
                  <TextBox
                    variant={"short"}
                    placeholder={SEARCH_CREATORS}
                    value={creatorsSearchText}
                    onEnter={onCreatorSearch}
                    onClear={() => {
                      setCreatorsSearchText("");
                      onCreatorSearch("");
                    }}
                    containerClass={"hundred-percent-width"}
                    onChange={(e) => {
                      e.preventDefault();
                      if (e.target.value.length === 0) {
                        onCreatorSearch("");
                      }
                      setCreatorsSearchText(e.target.value);
                      onCreatorSearch(e.target.value);
                    }}
                  />
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="creator-list-table-header"
                >
                  <CheckBox
                    defaultChecked={selectedAllChecked}
                    onClick={(value) => {
                      const updatedData = filteredCreators.map((obj) => ({ ...obj, checked: value }));
                      setFilteredCreators(updatedData);
                      setSelectedAllChecked(true);
                    }}
                  />
                  <div className="sub-section-heading">{filteredCreators.length + " " + (filteredCreators.length > 1 ? "creators" : "creator")}</div>
                </div>
                <div
                  style={{
                    height: creatorsCheckedCount > 0 ? "calc(100% - 291px)" : "calc(100% - 225px)",
                    background: filteredCreators?.length > 7 ? "linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 20%)" : "",
                  }}
                  className={`creator-list-container ${creatorsCheckedCount > 0 ? "hide-operations" : ""}`}
                >
                  <SelectedCreatorList
                    rows={filteredCreators}
                    onDeleteRow={(id) => {
                      onCreatorDelete({
                        listId: selectedList.list.id,
                        creatorIds: [id],
                        creatorNames: filteredCreators.find((item) => item.id === id).platform_username,
                      });
                    }}
                    variant="listWithCheckBox"
                    onCheckBoxClick={(id, value) => {
                      const updatedCreatorList = filteredCreators?.map((item) => {
                        if (item?.id === id) {
                          return { ...item, checked: value };
                        }
                        return item;
                      });
                      const creatorsCheckedCount = updatedCreatorList?.filter((item) => item.checked).length;
                      if (creatorsCheckedCount === filteredCreators.length) {
                        setSelectedAllChecked(true);
                      } else setSelectedAllChecked(false);
                      setFilteredCreators(updatedCreatorList);
                    }}
                    onListClick={(row) => {
                      navigate(`/creators/creator-search/profile?identifier=${row?.platform_username}&workplatformId=${row?.work_platform_id}`);
                    }}
                    onCreatorExport={(id) => {
                      setExportCreatorsState({ isExportModalOpen: true, listId: selectedList.list.id, creatorIds: [id] });
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const renderCreatorLists = (searchText) => {
    if (creatorListLoading)
      return (
        <div>
          <CreatorListLoading />
        </div>
      );
    if (
      (rows.length === 0 && searchText.length === 0) ||
      (rows.length === 1 && rows[0].id === favourite.toLocaleLowerCase() && rows[0].count_of_creators === 0)
    ) {
      return (
        <>
          <NoCreatorListsState />
        </>
      );
    }

    return (
      <div style={{ height: "100%" }} className={"side-drawer-custom-class"}>
        <div>
          {listsCheckedCount === 0 ? (
            <>
              <span className={"title selected-creator-title"}>{heading}</span>
              <div className="close-icon-container" style={{ top: "3%", right: "5%" }}>
                <Icons.close_icon onClick={onDrawerClose} cursor="pointer" />
              </div>
            </>
          ) : (
            <div className="clear-selection-check-count-div" style={{ marginTop: "40px" }}>
              <span className={"title"}>{listsCheckedCount + " " + listSelected(listsCheckedCount)}</span>
              <div onClick={clearAllSelection} className={"section-heading clear-selection "}>
                {clearSelection}
              </div>
              <div className="close-icon-container" style={{ top: "3%", right: "5%" }}>
                <Icons.close_icon onClick={onDrawerClose} cursor="pointer" />
              </div>
            </div>
          )}

          <div style={{ marginTop: "32px" }}>
            {listsCheckedCount > 0 ? (
              <RenderDeleteButton
                buttonText={deleteText}
                onDeleteClick={() => {
                  let selectedLists = rows.filter((item) => item.checked).map((item) => item.id);
                  onDeleteCreatorLists(selectedLists);
                }}
              />
            ) : (
              <RenderCreateNewListButton setCreateNewListPopup={setCreateNewListPopup} />
            )}
          </div>

          <div style={{ margin: "32px 0px 24px" }}>
            <TextBox
              variant={"short"}
              placeholder={SEARCH_LIST}
              value={searchText}
              onEnter={onSearch}
              onClear={() => {
                setSearchText("");
                onSearch("");
              }}
              containerClass={"hundred-percent-width"}
              onChange={(e) => {
                e.preventDefault();
                if (e.target.value.length === 0) {
                  onSearch("");
                }
                setSearchText(e.target.value);
                onSearch(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          style={{
            height: listsCheckedCount > 0 ? "calc(100% - 213px)" : "calc(100% - 162px)",
            background: rows?.length > 7 ? "linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 20%)" : "",
          }}
          className={`creator-list-container ${listsCheckedCount > 0 ? "hide-operations" : ""}`}
        >
          <SelectedCreatorList
            rows={rows}
            onDeleteRow={(id) => {
              onDeleteCreatorLists([id]);
            }}
            variant="listWithCheckBox"
            onCheckBoxClick={onCheckBoxClick}
            onListClick={(row) => {
              setSelectedList({
                isListSelected: true,
                list: row,
              });
              dispatchCreatorDiscoveryWarehouse({
                type: UPDATE_CREATOR_LISTS_SELECTED_CREATORS,
                payload: { ...creatorDiscoveryWarehouse.selectedListCreatorData, selectedListData: row },
              });
            }}
            onCreatorExport={(id) => {
              setExportCreatorsState({ isExportModalOpen: true, listId: id, creatorIds: [] });
            }}
          />
        </div>
      </div>
    );
  };

  const handleCreateNewList = async () => {
    if (listName.length > 50) {
      setListNameError({ hasError: true, errorMessage: maximumCharError });
      return;
    }

    try {
      setCreateListLoading(true);
      const response = await createList({
        warehouse,
        payload: {
          title: listName,
        },
      });
      if (response?.status === 200 && !isEmpty(response?.data)) {
        let list = await handleCreatorListClick();
        // open new created list
        const filterList = list.filter((list) => list.id === response?.data?.id);
        if (filterList.length > 0) {
          setSelectedList({
            isListSelected: true,
            list: filterList[0] || {},
          });
        }
        setCreateNewListPopup(false);
        setCreateListLoading(false);
        setListName("");
        trackEvent(NEW_LIST_CREATED, { source: "Search results page", name: listName });
      }
    } catch (error) {
      if (error.response.status === 400) {
        setListName("");
        setCreateListLoading(false);
        setListNameError({ hasError: true, errorMessage: dublicateListError });
      }
    }
  };

  const onDrawerClose = () => {
    onClose();
    setSearchText("");
    onSearch("");
    setCreatorsSearchText("");
    onBackClick();
  };
  const handleListNameError = () => {
    if (listName.length > 50) {
      setListNameError({ hasError: true, errorMessage: maximumCharError });
    } else {
      setListNameError({ hasError: false, errorMessage: "" });
    }
  };

  const RenderCreateNewListButton = ({ setCreateNewListPopup }) => {
    return (
      <PrimaryButton
        label={
          <div className="create-new-list-button-text">
            {" "}
            <i class="ri-add-line"></i> {createNewList}{" "}
          </div>
        }
        className="button secondary-button createNewListButton"
        onClick={() => {
          setCreateListLoading(false);
          setListNameError({});
          setCreateNewListPopup(true);
        }}
      />
    );
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: DRAWER_WIDTH,
            overflowY: "hidden",
            boxShadow: "none",
          },
        }}
        anchor="right"
        open={isOpen}
        onClose={onDrawerClose}
      >
        {selectedList.isListSelected ? renderCreatorsFromList() : renderCreatorLists(searchText)}
        <ExportCSVPopUp
          open={exportCreatorsState.isExportModalOpen}
          onClose={() => {
            setExportCreatorsState({ isExportModalOpen: false, listId: "", creatorIds: [] });
            setExportMail(sessionStorage.getItem("exportMail"));
            setExportMailError({
              hasError: false,
              errorMessage: "",
            });
          }}
          icon={<Icons.export_icon />}
          header={enterMail}
          description={exportListPopupDesc}
          actionButtonLabel={DONE}
          actionButtonHandler={onCreatorExport}
          exportCount={exportMail}
          setExportCount={setExportMail}
          exportCountError={exportMailError}
          setExportCountError={setExportMailError}
          textboxType={"string"}
          textboxPlaceholder={"Email"}
          onTextBoxChangeHandler={() => {
            setExportMailError({
              hasError: false,
              errorMessage: "",
            });
          }}
        />
        <ExportCSVPopUp
          open={createNewListPopup}
          onClose={() => {
            setCreateNewListPopup(false);
          }}
          icon={<Icons.add_list_icon />}
          header={createNewList}
          description={createNewListDesc}
          actionButtonLabel={DONE}
          actionButtonHandler={handleCreateNewList}
          exportCount={listName}
          setExportCount={setListName}
          exportCountError={listNameError}
          setExportCountError={setListNameError}
          textboxType={"string"}
          textboxPlaceholder={createNewListPlaceholder}
          buttonLoading={createListLoading}
          onTextBoxChangeHandler={handleListNameError}
          focusedInputText={listNameText}
        />
        <ToastMessage
          openToast={openToast.open}
          message={openToast.message}
          onCloseToast={handleToastClose}
          duration={openToast.duration}
          position={{ vertical: "top", horizontal: "center" }}
          type={openToast.type}
        />
        <UnlockFullAccessPopUp
          open={modalDetails.isModalVisible}
          onClose={() => {
            setModalDetails({ ...modalDetails, isModalVisible: false });
          }}
          header={modalDetails.modalInfo.header}
          description={modalDetails.modalInfo.description}
          actionButtonLabel={CONTACT_US}
          height="264px"
          padding="52px 52px 48px 48px"
          isModal
        />
      </Drawer>
    </>
  );
}

const RenderDeleteButton = ({ onDeleteClick, buttonText }) => {
  return (
    <PrimaryButton
      label={
        <div className="create-new-list-button-text">
          {" "}
          <i className="ri-delete-bin-line" style={{ fontSize: 20 }}></i> {buttonText}{" "}
        </div>
      }
      className="button secondary-button createNewListButton deleteListButton"
      onClick={onDeleteClick}
    />
  );
};

const CreatorListLoading = () => {
  return (
    <div className="creator-list-loading">
      <Skeleton animation="wave" variant="text" width={"40%"} height={70} />
      <Skeleton animation="wave" variant="text" width={"100%"} height={70} style={{ marginBottom: "24px" }} />
      {[1, 2, 3, 4, 5, 6, 7].map(() => {
        return (
          <Box className="table-row-skeleton" mb={"10px"}>
            <Skeleton animation="wave" variant="text" width={25} height={35} />
            <Skeleton animation="wave" variant="circular" width={50} height={45} />
            <Skeleton animation="wave" variant="text" width={"100%"} height={70} />
          </Box>
        );
      })}
    </div>
  );
};
