import React from "react";
import { Avatar, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

function ButtonWithImg({ icon, color, text, active, click }) {
  const styles = {
    backgroundColor: active ? "#f9b90b" : "#F7F7FB",
    textTransform: "none",
    color: active ? "#313544" : "#757480",
    height: "50px",
    padding: "0px",
    width: "max-content",
    height: "50px",
    justifyContent: "start",
    boxShadow: "none",
    borderRadius: "25px 8px 8px 25px",
    padding: "0px 24px 0px 0px",
    fontWeight: active ? "600" : "500",
    minWidth: "max-content !important",
    "& .MuiButton-startIcon": {
      padding: "0px",
      margin: "0px",
      padding: "1px 2px",
      width: "48px",
      height: "46px",
      marginRight: "14px",
      "& .MuiAvatar-root ": {
        width: "100%",
        height: "100%",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiAvatar-img": {
        width: "70%",
        height: "70%",
      },
    },
    "&:hover": {
      backgroundColor: active ? "#f9b90b" : "#F7F7FB",
      boxShadow: "none",
    },
  };
  const mobileStyles = {
    backgroundColor: active ? "#f9b90b" : "#F7F7FB",
    textTransform: "none",
    color: active ? "#313544" : "#757480",
    padding: "0px",
    width: "max-content",
    height: "48px",
    justifyContent: "start",
    boxShadow: "none",
    borderRadius: "22px 8px 8px 22px",
    padding: "0px 24px 0px 0px",
    "& .MuiButton-startIcon": {
      padding: "0px",
      margin: "0px",
      padding: "1px 2px",
      width: "48px",
      height: "46px",
      marginRight: "14px",
      "& .MuiAvatar-root ": {
        width: "100%",
        height: "100%",
        backgroundColor: "#FFFFFF",
      },
      "& .MuiAvatar-img": {
        width: "70%",
        height: "70%",
      },
    },
  };
  const mobileBreakPoint = useMediaQuery("(max-width:430px)");
  return (
    <Button
      variant="contained"
      sx={styles}
      size="medium"
      startIcon={<Avatar src={icon}></Avatar>}
      onClick={() => click()}
    >
      {text}
    </Button>
  );
}

export default ButtonWithImg;
