import axiosInstance from "../axiosInstance";
import { URL_GET_TENANT_QUOTA_INFO, currentDashboardURL } from "../endpoints";
export const getTenantUsageQuotaInfo = async (environment) => {
  try {
    const response = await axiosInstance.get(`${currentDashboardURL}/${URL_GET_TENANT_QUOTA_INFO}?environment=${environment.toUpperCase()}`);
    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error("No details found in tenant App Quota");
    }
  } catch (error) {
    throw error.message;
  }
};
