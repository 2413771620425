import axiosInstance from "../axiosInstance";
import { currentDashboardURL, validateEmailEndpoint } from "../endpoints";

const isValidEmail = async (email) => {
  try {
    const response = await axiosInstance.post(`${currentDashboardURL}/${validateEmailEndpoint}`, {
      email,
    });
    return response.data.is_allowed;
  } catch (e) {
    throw e;
  }
};

export { isValidEmail };
