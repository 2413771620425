import React, { useContext, useRef } from "react";
import { Box } from "@mui/material";
import "./RecentSearchCard.scss";
import { formatNumber } from "../../utils/profileHelpers";
import PropTypes from "prop-types";
import Icons from "../Icons/Icons";
import { useState } from "react";
import { useEffect } from "react";
import { Tooltip } from "@material-ui/core";

const RecentSearchCard = ({ tags, results, date, logo, onClickHandler, recentSearchCardId, deleteRecentSearchCardHandler, searchType }) => {
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  let [filters, setFilters] = useState("");
  let filtersLength = Object.values(tags).length;
  const onlyOnce = useRef(true);

  useEffect(() => {
    if (searchType !== "PROFILE") {
      if (filtersLength > 0 && onlyOnce.current) {
        onlyOnce.current = false;
        for (const [key, value] of Object.entries(tags)) {
          filters += `${value} • `;
        }
        setFilters(filters.slice(0, -3));
      }
    } else {
      setFilters(tags);
    }
  }, [tags]);

  return (
    <Box
      className="recent-search-container"
      onMouseOver={(e) => {
        setShowSearchIcon(true);
      }}
      onMouseOut={(e) => {
        setShowSearchIcon(false);
      }}
      onClick={onClickHandler}
    >
      <Tooltip title={filters} placement="top-end">
        <Box className="text-logo-box">
          <Box>
            <img className="recent-search-img" src={logo} alt="" />
          </Box>
          <Box className="text-truncate filter-text ">
            <>
              {searchType === "PROFILE" ? (
                filters
              ) : (
                <span>
                  {filters.slice(0, 30)} {filters.length > 30 && (!(filtersLength - 2 > 0) ? "+1 filters" : `...+${filtersLength - 2} filters`)}
                </span>
              )}
            </>
          </Box>
          <Box
            className="icon-box"
            onClick={(e) => {
              e.stopPropagation();
              deleteRecentSearchCardHandler(recentSearchCardId);
            }}
          >
            <i className="ri-close-line" />
          </Box>
        </Box>
      </Tooltip>
      {/* <Box className="result-date-container">
        <Box>{formatNumber(results)} results</Box>
        <Box>{date}</Box>
      </Box> */}
    </Box>
  );
};

export default RecentSearchCard;

RecentSearchCard.propTypes = {
  filters: PropTypes.string.isRequired,
  results: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
};

RecentSearchCard.defaultProps = {
  filters: "Subscribers: 12k+ • India, >10% • Art/Artist + 2 filters",
  results: 2000,
  date: "19 Mar",
};
