import { handleCreateUser, handleGenerateSDK } from "../api/sdkConnect/sdkConnectApi";
import { getProductsList } from "../utils/util";
import { products } from "./constants";
import { create_identifier, product, generateToken, web_integration, ios_integration, product_APIs } from "./urlConstants";
const workplatformId = "workplatformId";
const accountId = "accountId";
const reason = "reason";
const userId = "userId";

let toDate = new Date().toISOString().slice(0, 10);
let fromDate = new Date();
fromDate.setDate(fromDate.getDate() - 30);
const timeStamp = new Date();

export const codeBlock = {
  step1: {
    heading: `1. Create a user and an SDK token for the user`,
    description: [
      `Before you can get your users to connect their accounts,`,
      `<li><a target="_blank" href=${create_identifier}> <u><b>Create an identifier (user_id) </b></u><u><b>for the user.</b></u> </a></u></li>`,
      `<li>Use the ‘user_id’ to <a target="_blank" href=${generateToken} > <u><b>generate an SDK token (token) </b></u><u><b>for that user.</b></u> </a></li>`,
      `<li>While generating the ‘token’ specify the <a target="_blank" href=${product} ><u><b>products</b></u></a> you want to activate for this user.</li></ul>`,
    ],
    subStep: [
      {
        heading: `Creating a user`,
        code: `# to create a user, refer https://docs.insightiq.ai/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1users/post
curl --request POST \\
  --url baseURL/v1/users \\
  --header 'Authorization: Basic AUTH_KEY' \\
  --header 'Content-Type: application/json' \\
  --data '{
  "name": "firstNamelastName",
  "external_id": "firstName-${timeStamp.getTime()}"
}'`,
      },
      {
        heading: `Creating an SDK token`,
        code: `# to create an SDK token, refer https://docs.insightiq.ai/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1sdk-tokens/post
curl --request POST \\
  --url baseURL/v1/sdk-tokens \\
  --header 'Authorization: Basic AUTH_KEY' \\
  --header 'Content-Type: application/json' \\
  --data '{
  "user_id": "UserId",
  "products": [  
    "IDENTITY",
    "IDENTITY.AUDIENCE",
    "ENGAGEMENT",
    "ENGAGEMENT.AUDIENCE",
    "INCOME",
    "ACTIVITY"
  ]
}'`,
      },
    ],
    preActionHeading: `See it in action!`,
    preActionText: `Use the button below to simulate user creation and SDK token generation.`,
    CTA: "Simulate step 1",
    buttonAction: async (environment, setAccountId, setLoader, setButtonLoading, dispatchWarehouse, setCurrStep, warehouse) => {
      return await handleCreateUser(environment, warehouse, dispatchWarehouse);
    },
    success: [
      {
        content: `user_id created: UserId`,
      },
      {
        content: `sdk_token created: truncatedSdkToken`,
      },
    ],
    proceedText: "Please proceed to Step 2.",
    errorText: "User with given external id already exists. Please refresh the page and try again.",
  },
  step2: {
    heading: `2. Connect accounts using Connect SDK`,
    description: [
      `After you have the SDK token (token),`,
      `<li><b>Initiate the Connect SDK on your app.</b> Here is how to do it for a <a target="_blank" href=${web_integration} ><u>web app</u></a> or a <a target="_blank" href=${ios_integration} ><u>mobile app</u></a>.`,
    ],
    subHeading: `Initiating the Connect SDK (Web app)`,
    code: `<script src="https://cdn.getphyllo.com/connect/v2/phyllo-connect.js"></script>

<script>
const config = {
  clientDisplayName: "companyName", // the name of your app that you want the creators to see while granting access
  environment: "Environment", // the mode in which you want to use the SDK,  'sandbox' or 'production'
  userId: "UserId", // the unique user_id parameter returned by the API when you create a user (see https://docs.insightiq.ai/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1users/post)
  token: "sdkToken"
};
    
const phylloConnect = PhylloConnect.initialize(config);
    
phylloConnect.on("accountConnected", (accountId, workplatformId, userId) => {  // gives the successfully connected account ID and work platform ID for the given user ID
  console.log(\`onAccountConnected: \${accountId}\, \${workplatformId}\, \${userId}\`);
})
phylloConnect.on("accountDisconnected", (accountId, workplatformId, userId) => {  // gives the successfully disconnected account ID and work platform ID for the given user ID
  console.log(\`onAccountDisconnected: \${accountId}\, \${workplatformId}\, \${userId}\`);
})
phylloConnect.on("tokenExpired", (userId) => {  // gives the user ID for which the token has expired
  console.log(\`onTokenExpired: \${userId}\`);  // the SDK closes automatically in case the token has expired, and you need to handle this by showing an appropriate UI and messaging to the users
})
phylloConnect.on("exit", (reason, userId) => {  // indicates that the user with given user ID has closed the SDK and gives an appropriate reason for it
  console.log(\`onExit: \${reason}\, \${userId}\`);
})
phylloConnect.on("connectionFailure", (reason, workplatformId, userId) => {  // optional, indicates that the user with given user ID has attempted connecting to the work platform but resulted in a failure and gives an appropriate reason for it
  console.log(\`onConnectionFailure: \${reason}\, \${workplatformId}\, \${userId}\`);
})

your_button.onclick = phylloConnect.open();
</script>`,
    preActionHeading: `See it in action!`,
    preActionText: `Use the button below to simulate the account connection using the Connect SDK. It shows how your users will connect their accounts with your app.`,
    note: `(Note: The accounts that you connect and their data are dummies as you are in sandbox mode.)`,
    CTA: "Connect an account",
    buttonAction: (environment, setAccountId, setLoader, setButtonLoading, dispatchWarehouse, setCurrStep, warehouse) => {
      handleGenerateSDK(environment, setAccountId, setLoader, setButtonLoading, dispatchWarehouse, setCurrStep, warehouse);
    },
    success: [
      {
        content: `Account connected and account_id created: AccountId`,
      },
    ],
    proceedText: "Please proceed to Step 3.",
  },
  step3: {
    heading: `3. Fetch data for an account`,
    description: [
      `After the account connection,`,
      `<li>You have the ‘account_id’ of the freshly connected account. </li>`,
      `<li><b>Start fetching different types of data</b> using our <a target="_blank" href=${product_APIs}><u>product-specific APIs</u></a> for the ‘account_id’.</li>`,
    ],
    subHeading: `See it in action!`,
    preActionText: `Use the dropdown below to <b>select an API.</b> We will automatically send a request to the our server with the selected API and show the response below.`,
    products: `Products`,
    requestSnippetHeader: `Request`,
    responseSnippetHeader: `Response`,
    code: {
      Identity: `# to fetch profile info, refer https://docs.insightiq.ai/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1profiles/get
curl --request GET \\
  --url 'baseURL/v1/profiles?account_id=AccountId' \\
  --header 'Authorization: Basic AUTH_KEY' \\
  --header 'Content-Type: application/json'`,
      "Audience demographics": `# to fetch audience demographics info, refer https://docs.insightiq.ai/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1audience/get
curl --request GET \\
  --url 'baseURL/v1/audience?account_id=AccountId' \\
  --header 'Authorization: Basic AUTH_KEY' \\
  --header 'Content-Type: application/json'`,
      Engagement: `# to fetch content info, refer https://docs.insightiq.ai/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1social~1contents/get
curl --request GET \\
  --url 'baseURL/v1/social/contents?account_id=AccountId' \\
  --header 'Authorization: Basic AUTH_KEY' \\
  --header 'Content-Type: application/json'`,
      Income: `# to fetch income transactions info, refer https://docs.insightiq.ai/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1social~1income~1transactions/get
curl --request GET \\
  --url 'baseURL/v1/social/income/transactions?account_id=AccountId&transaction_to_date=${toDate}&transaction_from_date=${fromDate
        .toISOString()
        .slice(0, 10)}' \\
  --header 'Authorization: Basic AUTH_KEY' \\
  --header 'Content-Type: application/json'`,
      Comments: `# to fetch comments info, refer https://docs.insightiq.ai/docs/api-reference/reference/openapi.v1.yml/paths/~1v1~1social~1comments/get
curl --request GET \\
  --url 'baseURL/v1/social/comments?account_id=AccountId&content_id=contentId' \\
  --header 'Authorization: Basic AUTH_KEY' \\
  --header 'Content-Type: application/json'`,
      "Activity contents": `# to fetch activity contents info, refer https://docs.insightiq.ai/docs/api-reference/api/ref/operations/list-v-1-media-activity-contents
curl --request GET \\
  --url 'baseURL/v1/media/activity/contents?account_id=AccountId' \\
  --header 'Authorization: Basic AUTH_KEY' \\
  --header 'Content-Type: application/json'`,
    },
    errorInfo: "You have connected {{WorkplatformName}} account, which doesn't support the {{Product}} APIs.",
  },
};
