import React, { useEffect, useState, useContext } from "react";

import { Background, Card, Typography, OtpInputField, PrimaryButton, NavigationHeader, TextLink } from "../../components";
import { UpsertWebhookForm } from "../../containers/index";
import { webhooksApi } from "../../api/index";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import useFetchWebhooks from "../../hooks/useFetchWebhooks";
import "./CreateWebhookPage.scss";
import { UserContext, WarehouseContext } from "../../storage/context";
import { useToast } from "../../hooks/useToast";
import { WEBHOOK_CREATED } from "../../constants/toastMessages";
import { getPageTitle, pageTitle } from "../../constants/constants";
import { Helmet } from "react-helmet";
import { webhooksErrorMessage } from "../../constants/errorMessages";
import { trackEvent, WEBHOOK_CREATE_PAGE_VIEW } from "../../analytics";

function CreateWebhookPage() {
  const { warehouse } = useContext(WarehouseContext);
  const { fetchWebhooks } = useFetchWebhooks();
  const navigate = useNavigate();
  const [error, setError] = useState({ errorMessage: "" });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onSubmitForm = async ({ url, events, name }) => {
    try {
      setLoading(true);
      const tenantId = warehouse.tenantInfo[0].id;
      const clientId = warehouse.tenantInfo[0].apps[0].id;
      const environment = warehouse.environment.current.toUpperCase();
      const response = await webhooksApi.createWebhook({
        tenantId,
        clientId,
        environment,
        url,
        events,
        name,
      });
      if (response.status === 201) {
        toast(WEBHOOK_CREATED);
        fetchWebhooks();
        navigate("../");
      }
    } catch (e) {
      setError({ errorMessage: webhooksErrorMessage[e.error_code] });
      setLoading(false);
    }
  };

  const onCancel = () => {
    navigate("../");
  };
  useEffect(() => {
    trackEvent(WEBHOOK_CREATE_PAGE_VIEW);
  }, []);
  return (
    <div>
      <Helmet>
        <title>{getPageTitle("WEBHOOK_CREATION_PAGE", warehouse.environment.current)}</title>
      </Helmet>
      <UpsertWebhookForm onSubmitForm={onSubmitForm} onCancel={onCancel} errors={error} buttonLoading={loading} />
    </div>
  );
}

export default CreateWebhookPage;
