import React, { useState } from "react";
import "./ExportCSVPopUp.scss";
import Icons from "../Icons/Icons";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import FilledTextField from "../FilledTextField/FilledTextField";
import PropTypes from "prop-types";

const ExportCSVPopUp = ({
  open,
  onClose,
  header,
  description,
  actionButtonLabel,
  actionButtonHandler,
  exportCount,
  setExportCount,
  exportCountError,
  setExportCountError,
  icon,
  textboxType,
  textboxPlaceholder,
  buttonLoading,
  onTextBoxChangeHandler,
  focusedInputText = textboxPlaceholder
}) => {
    const [isInputFocused, setIsInputFocused] = useState(false);
  if (!open) {
    return null;
  }
  return (
    <div className="modal" onClick={onClose}>
      <div className="export-pop-up-container"onClick={(e) => e.stopPropagation()}>
        <div className="export-pop-up-content-container">
          <div className="export-pop-up-icon">{icon}</div>
          <div className="export-pop-up-description-container">
            <div className="export-pop-up-heading">
              <h3 className="export-pop-up-info-header">{header}</h3>
            </div>
            <div className="export-pop-up-description">
              <p>{description}</p>
            </div>
            <div className="export-input-container">
              <FilledTextField
                label={ (isInputFocused || buttonLoading) ? focusedInputText : textboxPlaceholder}
                value={exportCount}
                inputProps={{
                  maxLength: 100,
                  onFocus: () => setIsInputFocused(true),
                  onBlur: () => setIsInputFocused(false),
                }}
                hasError={exportCountError.hasError}
                errorMessage={
                  <div className="export-pop-up-error">
                    {exportCountError.hasError ? (
                      <div>
                        <Icons.caution_red />
                      </div>
                    ) : null}
                    {exportCountError.errorMessage}
                  </div>
                }
                onChange={(e) => {
                  setExportCount(e.target.value);
                  onTextBoxChangeHandler(e.target.value);
                }}
                type={textboxType}
              />
            </div>
            <div className="export-pop-up-action" style={exportCountError.hasError ? { marginTop: "16px" } : {}}>
              <PrimaryButton
                className="export-pop-up-action-button"
                label={actionButtonLabel}
                onClick={actionButtonHandler}
                disabled={exportCountError.hasError || exportCount.length === 0}
                loading={buttonLoading}
              />
            </div>
          </div>
        </div>
        <div className="close-icon-container">
          <Icons.close_icon onClick={onClose} cursor="pointer" />
        </div>
      </div>
    </div>
  );
};

ExportCSVPopUp.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  header: PropTypes.string,
  description: PropTypes.string,
  actionButtonLabel: PropTypes.string,
  actionButtonHandler: PropTypes.func,
  exportCount: PropTypes.string,
  setExportCount: PropTypes.func,
  exportCountError: PropTypes.object,
  setExportCountError: PropTypes.func,
};

export default ExportCSVPopUp;
