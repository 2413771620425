import { Button, Skeleton } from "@mui/material";
import { Box } from "@material-ui/core";
import {
    trackEvent,
    TRACKING_CAMPAIGN_ADD_CONTENT_CLICKED,
    TRACKING_CAMPAIGN_CREATE_CAMPAIGN_CLICKED,
    TRACKING_CAMPAIGN_MANAGE_CREATORS_CLICKED,
    TRACKING_CAMPAIGNS_SELECT_CREATORS_CLICKED
} from "../../analytics";
import {
    ADD_CREATORS_ENTRY_SOURCE,
    ADD_SELECTED_ACCOUNTS,
    ADD_THEM_DIRECTLY_OR_CSV,
    AUTO_ADD_CREATOR_MESSAGE,
    AUTO_ADD_CREATORS_TITLE,
    AUTO_ADD_STEP1_TEXT,
    AUTOMATICALLY_ADD_TITLE,
    CAMPAIGN_EDIT_FLOW,
    CAMPAIGN_PROFILES_CREATED_VIA,
    campaignPageTabValues,
    CREATE_CAMPAIGN,
    CREATE_UTM_LINKS,
    creatorProfileLinkCSVHeader,
    duplicate_campaign,
    HAVE_POST_LINKS_HANDY,
    MANAGE,
    POST_IDENTIFIERS_TITLE,
    POST_IDENTIFIERS_TITLE_MESSAGE,
    PROFILE_ADD_STATUS,
    SAVE_CHANGES,
    SIDE_DRAWER_TITLE,
    SIMPLY_ADD_CREATOR_ACCOUNTS
} from "../../constants/campaignTrackingConstants";
import InfoActionBanner from "../InfoActionBanner/InfoActionBanner";
import { CampaignPostIdentifiers, EditCreatorDrawer, EmptyTableScreen, Icons, PrimaryButton } from "../index";
import {
    ADD_USAGE_QUOTA,
    SET_CAMPAIGN_ACTIVE_TAB,
    SET_CAMPAIGN_FORM,
    SET_POST_IDENTIFIERS,
    SET_TRACKED_CREATORS,
    SHOW_CAMPAIGN_ERROR
} from "../../storage/reducers/types";
import Step from "../Step/Step";
import { CampaignTrackingContext, WarehouseContext } from "../../storage/context";
import { useContext, useEffect, useState } from "react";
import {
    clearCampaignTrackingWarehouse,
    convertDateToUtc,
    createAddableProfiles,
    editProfiles,
    getIdentifiers,
    getNonDeletedCreators,
    isEmpty,
    setAddedProfiles
} from "../../utils/util";
import { useLocation, useNavigate } from "react-router-dom";
import {
    createCampaign,
    getAllCampaignProfiles,
    getCampaignById,
    updateCampaign
} from "../../api/CampaignTrackingAPI/CampaignTrackingAPI";
import { useFetchTenantUsageInfo } from "../../hooks/useFetchTenantUsageInfo";
import { Colors } from "../../styles/colors";
import { getValidLinks } from "../../utils/profileHelpers";

export default function TrackPostsMainContent({ campaignId, entrySource, isBackClicked, hasUnsavedContent }) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { warehouse, dispatchWarehouse } = useContext(WarehouseContext);
    const { campaignTrackingWarehouse, dispatchCampaignTrackingWarehouse } = useContext(CampaignTrackingContext);
    const fetchTenantUsageInformation = useFetchTenantUsageInfo();

    const [filteredCreators, setFilteredCreators] = useState([]);
    const [isEditMenuOpen, setEditMenuOpen] = useState(false);
    const [submitButtonText, setSubmitButtonText] = useState(SAVE_CHANGES);

    let {
        trackedCreators,
        postIdentifiers,
        csvCreatorsList,
        campaignForm: {
            brand: { name: brand_name, value: brand_id },
            name,
            cost,
            date: { fromDate: start_date, toDate: end_date },
        },
    } = campaignTrackingWarehouse;
    const [campaignProfilesLoaded, setCampaignProfilesLoaded] = useState(!!trackedCreators);
    const [campaignIdentifiersLoaded, setCampaignIdentifiersLoaded] = useState(postIdentifiers && postIdentifiers.length >= 0);

    function onSearch(searchText) {
        if (isEmpty(searchText)) {
            setFilteredCreators(campaignTrackingWarehouse?.trackedCreators?.filter(getNonDeletedCreators));
            return;
        }
        setFilteredCreators(campaignTrackingWarehouse?.trackedCreators?.filter((tc) => tc["platform_username"].includes(searchText)));
    }

    useEffect(onSearch, [campaignTrackingWarehouse]);

    useEffect(() => {
        setCampaignProfilesLoaded(!!trackedCreators);
        if (isEmpty(trackedCreators)) {
            setEditMenuOpen(false);
        }
    }, [trackedCreators]);

    useEffect(() => {
        if (isEmpty(entrySource)) {
            return;
        }
        if (entrySource === ADD_CREATORS_ENTRY_SOURCE.CREATE_CAMPAIGN) {
            if (isEmpty(name)) {
                navigate("/creators/campaigns");
                return;
            }
            setCampaignIdentifiersLoaded(true);
            setCampaignProfilesLoaded(true);
            setSubmitButtonText(CREATE_CAMPAIGN);
        } else {
            setSubmitButtonText(SAVE_CHANGES);

            if (
                trackedCreators?.some(
                    (trackedCreator) => trackedCreator.addStatus === PROFILE_ADD_STATUS.NEW || trackedCreator.addStatus === PROFILE_ADD_STATUS.TO_BE_REMOVED
                )
            ) {
                return;
            }

            if (isEmpty(trackedCreators)) {
                setCampaignProfilesLoaded(false);
                getAllCampaignProfiles({
                    warehouse,
                    limit: 100,
                    campaignId,
                    created_via: CAMPAIGN_PROFILES_CREATED_VIA.DIRECT,
                })
                    .then((res) => {
                        const { data } = res;
                        if (data?.length > 0) {
                            data.forEach(setAddedProfiles);
                            dispatchCampaignTrackingWarehouse({
                                type: SET_TRACKED_CREATORS,
                                payload: data,
                            });
                        }
                    })
                    .finally(() => setCampaignProfilesLoaded(true));
            }

            if (postIdentifiers === null || campaignTrackingWarehouse?.campaignForm?.name === null) {
                setCampaignIdentifiersLoaded(false);
                getCampaignById({ warehouse, campaignId })
                    .then((res) => {
                        if (!res) {
                            return;
                        }
                        const { hashtags, keywords, mentions, name } = res;

                        dispatchCampaignTrackingWarehouse({
                            type: SET_POST_IDENTIFIERS,
                            payload: [...hashtags, ...keywords, ...mentions],
                        });


                        dispatchCampaignTrackingWarehouse({
                            type: SET_CAMPAIGN_FORM,
                            payload: {
                                ...campaignTrackingWarehouse.campaignForm,
                                name,
                            },
                        });

                    }).finally(() => setCampaignIdentifiersLoaded(true));
            }
        }
    }, [entrySource]);

    function onDone() {
        const { hashtags, keywords, mentions } = getIdentifiers(postIdentifiers);

        const newProfiles = trackedCreators
            .filter((trackedCreator) => trackedCreator.addStatus === PROFILE_ADD_STATUS.NEW)
            .map(createAddableProfiles)
            .map((obj) => obj["identifier"]);
        if (entrySource === ADD_CREATORS_ENTRY_SOURCE.CREATE_CAMPAIGN) {
            createCampaign({
                warehouse,
                profile_urls: newProfiles,
                brand_id,
                name,
                cost,
                start_date: convertDateToUtc({ date: start_date }),
                end_date: convertDateToUtc({ date: end_date, endDate: true }),
                hashtags,
                mentions,
                keywords,
                profileFile: getValidLinks(csvCreatorsList, creatorProfileLinkCSVHeader)
            })
                .then((response) => {
                    if (response.id) {
                        clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
                        navigate(`/creators/campaigns/campaign-view/${response.id}`);
                        fetchTenantUsageInformation().then((usageInfo) => {
                            dispatchWarehouse({ type: ADD_USAGE_QUOTA, payload: usageInfo });
                        });
                    } else {
                        if (response.error?.code === duplicate_campaign) {
                            dispatchCampaignTrackingWarehouse({
                                type: SHOW_CAMPAIGN_ERROR,
                                payload: duplicate_campaign,
                            });
                        }
                        navigate(`/creators/campaigns/create-campaign`);
                    }
                })
                .catch((e) => {
                    console.error("Could not create a campaign", e);
                })
                .finally(() => {
                    !isEmpty(trackedCreators) &&
                    trackEvent(TRACKING_CAMPAIGN_CREATE_CAMPAIGN_CLICKED, {
                        name,
                        brand_name,
                        start_date: convertDateToUtc({ date: start_date }),
                        end_date: convertDateToUtc({ date: end_date, endDate: true }),
                        campaign_cost: cost,
                        keywords,
                        mentions,
                        hashtags,
                        list_of_selected_accounts: trackedCreators,
                    });
                });
        } else {
            const savePromises = [];
            updateCampaign({
                warehouse,
                campaignId,
                hashtags,
                mentions,
                keywords,
            }).then(() => {
                editProfiles(warehouse, campaignId, trackedCreators, csvCreatorsList).then(() => {
                    clearCampaignTrackingWarehouse(dispatchCampaignTrackingWarehouse);
                    switch (entrySource) {
                        case ADD_CREATORS_ENTRY_SOURCE.EDIT_CAMPAIGN_CAMPAIGNPAGE:
                            navigate(0);
                            break;
                        case ADD_CREATORS_ENTRY_SOURCE.EDIT_CAMPAIGN:
                        default:
                            navigate(`/creators/campaigns/campaign-view/${campaignId}`);
                            break;
                    }
                });
            }).catch((e) => {
                console.error(`Could not update campaign / profiles in campaign`, e);
            });
        }

        dispatchCampaignTrackingWarehouse({
            type: SET_CAMPAIGN_ACTIVE_TAB,
            payload: campaignPageTabValues.trackPosts,
        });
    }

    function Step1Content() {
        if (!campaignProfilesLoaded) {
            return <Skeleton className={"loading-skeleton"} animation={"wave"} variant="rounded" height={88}/>;
        }
        return (isEmpty(trackedCreators) || trackedCreators?.filter(getNonDeletedCreators).length === 0) && csvCreatorsList?.length === 0 ? (
            <Button
                onClick={() => {
                    trackEvent(TRACKING_CAMPAIGNS_SELECT_CREATORS_CLICKED);
                    onSelectCreatorDone();
                }}
            >
                <div className={"action-container-button"}>
                    <i className="ri-user-search-line"></i>
                    {AUTO_ADD_CREATORS_TITLE}
                </div>
            </Button>
        ) : (
            <InfoActionBanner
                variant={"creator-addition"}
                title={AUTO_ADD_STEP1_TEXT(
                    trackedCreators?.filter((trackedCreator) => trackedCreator.addStatus !== PROFILE_ADD_STATUS.TO_BE_REMOVED).length + csvCreatorsList?.length
                )}
                icon={<Icons.Added_creator_accounts/>}
                actionIcon={<i className="ri-edit-line"></i>}
                actionText={MANAGE}
                actionClick={() => {
                    trackEvent(TRACKING_CAMPAIGN_MANAGE_CREATORS_CLICKED);
                    setEditMenuOpen(!isEditMenuOpen);
                }}
            />
        );
    }

    function Step2Content() {
        if (!campaignIdentifiersLoaded) {
            return <Skeleton className={"loading-skeleton"} animation={"wave"} variant="rounded" height={56}/>;
        }
        return (
            <CampaignPostIdentifiers
                trackedPostIdentifiers={postIdentifiers}
                onItemDelete={onPostIdentifierDelete}
                onClearAllClick={onPostIdentifierClearAllClick}
                onEnter={onPostIdentifierEnter}
            />
        );
    }

    function autoAddMessage() {
        let path = '';
        if (entrySource === ADD_CREATORS_ENTRY_SOURCE.CREATE_CAMPAIGN) {
            path = `/creators/campaigns/add-post-links`;
        } else {
            path = `/creators/campaigns/edit-post-links/${campaignId}`;
        }
        const link = (
            <span
                className={"add-post-link"}
                onClick={() => {
                    trackEvent(TRACKING_CAMPAIGN_ADD_CONTENT_CLICKED);
                    navigate(path);
                }}
            >
              {ADD_THEM_DIRECTLY_OR_CSV}
            </span>
        );
        return (
            <span>
              {SIMPLY_ADD_CREATOR_ACCOUNTS} {HAVE_POST_LINKS_HANDY} {link}
            </span>
        );
    }

    function onSelectCreatorDone() {
        let selectCreatorsState = {};
        switch (state?.flow) {
            // TODO Uncomment when ROI flow is to be implemented
            case CAMPAIGN_EDIT_FLOW.MEASURE_ROI:
                selectCreatorsState = {
                    navigateDoneRoute: "/creators/campaigns/utm-links",
                    doneButton: CREATE_UTM_LINKS,
                };
                break;
            case CAMPAIGN_EDIT_FLOW.TRACK_POSTS:
            default:
                selectCreatorsState = {
                    navigateDoneRoute: -1,
                    doneButton: ADD_SELECTED_ACCOUNTS,
                };
                break;
        }
        navigate(`/creators/campaigns/select-creators`, { state: selectCreatorsState });
    }

    function onPostIdentifierDelete(label) {
        const newPostIdentifiers = postIdentifiers?.filter((item) => item !== label);
        dispatchCampaignTrackingWarehouse({
            type: SET_POST_IDENTIFIERS,
            payload: newPostIdentifiers,
        });
    }

    function onPostIdentifierClearAllClick() {
        dispatchCampaignTrackingWarehouse({
            type: SET_POST_IDENTIFIERS,
            payload: [],
        });
    }

    function onPostIdentifierEnter(text) {
        function classifyTag(text) {
            return text
                .trim()
                .split(",")
                .flatMap((str) => {
                    if (str.startsWith("#") || str.startsWith("@")) {
                        return str.split(" ").filter((entry) => entry.trim() !== "");
                    }
                    return str;
                });
        }

        if (postIdentifiers === null) {
            postIdentifiers = [];
        }
        let newPostIdentifiers = [...postIdentifiers, ...classifyTag(text).flat()];
        newPostIdentifiers = [...new Set(newPostIdentifiers)];
        dispatchCampaignTrackingWarehouse({
            type: SET_POST_IDENTIFIERS,
            payload: newPostIdentifiers,
        });
    }


    return (
        <>
            <div className={"add-creators-main-content"}>
                <Box className="campaign-error-content">
                    <EmptyTableScreen
                        icon={<Icons.automatically_add_creators/>}
                        header={AUTOMATICALLY_ADD_TITLE}
                        content={autoAddMessage()}
                        isActionButton={false}
                    />
                </Box>
                <div className={"add-creators-action-container"}>
                    <Step title={AUTO_ADD_CREATORS_TITLE} message={AUTO_ADD_CREATOR_MESSAGE} stepCount={1}
                          content={<Step1Content/>}/>
                    <Step title={POST_IDENTIFIERS_TITLE} message={POST_IDENTIFIERS_TITLE_MESSAGE} stepCount={2}
                          content={<Step2Content/>}/>
                    <PrimaryButton style={{ height: 56 }}
                                   label={
                                       <span className={'body-m'}
                                             style={{ color: Colors.white }}>{submitButtonText}
                                       </span>
                                   }
                                   block
                                   onClick={onDone}
                                   disabled={!hasUnsavedContent}/>
                </div>
            </div>
            <EditCreatorDrawer
                onClose={() => setEditMenuOpen(false)}
                isOpen={isEditMenuOpen}
                heading={SIDE_DRAWER_TITLE(filteredCreators?.length)}
                onAddMoreAccounts={onSelectCreatorDone}
                onSearch={onSearch}
                rows={filteredCreators}
                csvProfileUrls={csvCreatorsList}
            />
        </>
    );
}