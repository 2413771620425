import { Alert, Slide, Snackbar } from "@mui/material";
import "./ToastMessage.scss";

const ToastMessage = ({
                          type,
                          message,
                          onCloseToast,
                          openToast,
                          position = { vertical: "top", horizontal: "center" },
                          duration
                      }) => {
    function TransitionDown(props) {
        return <Slide {...props} direction="down"/>;
    }

    if (type === "success") {
        return (
            <Snackbar
                open={openToast}
                autoHideDuration={duration}
                onClose={onCloseToast}
                anchorOrigin={position}
                classes={{
                    root: "success-toast",
                }}
                TransitionComponent={TransitionDown}
            >
                <Alert onClose={onCloseToast} icon={<i className="ri-check-line success-toast-icon"></i>}
                       sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        );
    }

    if (type === "failure") {
        return (
            <Snackbar
                open={openToast}
                autoHideDuration={duration}
                onClose={onCloseToast}
                anchorOrigin={position}
                classes={{
                    root: "failure-toast",
                }}
                TransitionComponent={TransitionDown}
            >
                <Alert onClose={onCloseToast} icon={<i className="ri-alert-line failure-toast-icon"></i>}
                       sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        );
    }

        if (type === "delete") {
        return (
            <Snackbar
                open={openToast}
                autoHideDuration={duration}
                onClose={onCloseToast}
                anchorOrigin={position}
                classes={{
                    root: "delete-toast",
                }}
                TransitionComponent={TransitionDown}
            >
                <Alert onClose={onCloseToast} icon={<i className="ri-delete-bin-line delete-toast-icon"></i>}
                       sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        );
    }

    return (
        <Snackbar
            open={openToast}
            autoHideDuration={duration}
            onClose={onCloseToast}
            anchorOrigin={position}
            classes={{
                root: "default-toast",
            }}
            TransitionComponent={TransitionDown}
        >
            <Alert onClose={onCloseToast} icon={<i className="ri-check-line default-toast-icon"></i>}
                   sx={{ width: "100%" }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default ToastMessage;
