import { Tooltip } from "@mui/material";
import moment from "moment-timezone";
import { MEETING_SCHEDULE_LINK } from "./constants";

export const FILTER_TYPES = {
  BOOLEAN: "boolean",
  DROPDOWN: "dropdown",
  DROPDOWN_STEPS_SLIDER: "dropdown_steps_slider",
  MIN_MAX_DROPDOWN: "min_max_dropdown",
  API_SEARCHABLE_DROPDOWN: "api_searchable_dropdown",
  API_SEARCHABLE_DROPDOWN_STEPS_SLIDER: "api_searchable_dropdown_steps_slider",
  API_SEARCHABLE_MULTI_SELECT_DROPDOWN: "api_searchable_multi_select_dropdown",
  API_SEARCHABLE_MULTI_SELECT_DROPDOWN_STEPS_SLIDER: "api_searchable_multi_select_dropdown_steps_slider",
  PERCENTAGE_SLIDER: "percentage_slider",
  TEXT: "text",
  MULTI_SELECT_TEXT: "multi_select_text",
  MIN_MAX_DROPDOWN_STEPS_SLIDER: "min_max_dropdown_steps_slider",
  SEARCHABLE_MULTI_SELECT_CHECKBOX_MODAL: "searchable_multi_select_checkbox_modal",
};

export const TOP_CONTENT = "Top content";
export const SPONSORED_CONTENT = "Sponsored content";
export const COUNTRIES = "Countries";
export const CITIES = "Cities";
export const AGE = "Age";
export const LANGUAGE = "Language";
export const ETHNICITY = "Ethnicity";
export const COUNTRIES_HEADER = "Top audience location by countries";
export const CITIES_HEADER = "Top audience location by cities";

export const audienceDemographicsHeaders = {
  Age: "Audience age",
  Language: "Audience language",
  Ethnicity: "Audience ethnicity",
};

export const contentTypeTabs = {
  TopContent: { label: TOP_CONTENT, icon: "" },
  SponsoredContent: { label: SPONSORED_CONTENT, icon: "" },
};
export const audienceLocationTabs = { Countries: { label: COUNTRIES, icon: "" }, Cities: { label: CITIES, icon: "" } };
export const audienceDemographicsTabs = {
  Age: { label: AGE, icon: "" },
  Language: { label: LANGUAGE, icon: "" },
  Ethnicity: { label: ETHNICITY, icon: "" },
};
export const CREATOR_LOOKALIKES = "creator_lookalikes";
export const HASHTAGS = "hashtags";
export const MENTIONS = "mentions";
export const DESCRIPTION_KEYWORDS = "description_keywords";
export const DESCRIPTION_KEYWORDS_LABEL = "Keyword";
export const REELS_VIEWS = "REELS_VIEWS";
export const REEL_VIEWS = "REEL_VIEWS";
export const REEL_VIEWS_DISPLAY_NAME = "Reel views";

export const TOPIC_RELEVANCE_KEY = "topic_relevance";
export const TOPIC_RELEVANCE_FILTER_NAME = "Topics";

// Limit and offset for the first search results
export const SEARCH_RESULTS_TABLE_OFFSET = 10;
export const SEARCH_RESULTS_LIMIT = 15;

// Limit and offset for load more results
export const SEARCH_RESULTS_LOAD_MORE_TABLE_OFFSET = 5;
export const SEARCH_RESULTS_LOAD_MORE_LIMIT = 5;

export const LEARN_HOW_AI_TEXT =
  "We go through the comments on posts and use AI to analyse their relevance to the post/brand, sentiment, and intent to buy.";
export const LEARN_HOW_AI = "Learn how we calculate this with AI";
export const ANALYZING_COMMENTS = "Analyzing comments...";
export const THIS_MIGHT_TAKE_SOME_TIME = (email) => (
  <>
    This might take 30-40 mins. We’ll email you on <span className="email-link-text">{email}</span> when it’s ready
  </>
);
export const COMMENTS_ANALYSIS_MESSAGE_SUCCESS = (numComments, updatedAt) =>
  `We analyzed ${numComments} comments across the most relevant posts by this creator. Last updated ${updatedAt}`;
export const FAILURE_MESSAGE = (
  <>
    {"Please try again in some time or"}
    &nbsp;
    <u className="no-comments-message">
      <a href={"https://getphyllo.freshdesk.com/support/home"} target="_blank">
        contact our support team
      </a>
    </u>
  </>
);
export const FAILURE_COMMENTS = "Couldn’t get these details at the moment";
export const OOPS_POST_HAS_NO_COMMENTS_MESSAGE = "Comments analysis generally works on creators with who have";
export const OOPS_POST_HAS_NO_COMMENTS = "This creator doesn’t have enough comments on their posts";
export const NEW_DATA_LOADING_MESSAGE = (email, updatedAt) => (
  <div>
    Getting latest info. We’ll email you on <span className="email-link-text">{email}</span> when it’s ready in about 30-40 mins. Below info was last
    updated {updatedAt}
  </div>
);
export const NEW_DATA_NOT_AVAILABLE_MESSAGE = (email, updatedAt) => (
  <div>
    We ran into some issues while getting the latest info. We’ll notify you on <span className="email-link-text">{email}</span> when an update is
    available. Below info last updated {updatedAt}
  </div>
);
export const DEFAULT_SELECTED_FILTERS = (work_platform_id, selectedWorkplatform) => {
  let search_query = {
    sort_by: {
      field: "AVERAGE_LIKES",
      order: "DESCENDING",
    },
    filter_tags: {
      follower_count: "Followers: 5k - 100k",
      creator_locations: "Creator's location: United States",
    },
    ...((selectedWorkplatform === "Instagram" || selectedWorkplatform === "TikTok") && {
      follower_count: {
        max: 100000,
        min: 5000,
      },
    }),
    ...(selectedWorkplatform === "YouTube" && {
      subscriber_count: {
        max: 100000,
        min: 5000,
      },
    }),
    work_platform_id: work_platform_id,
    // creator_locations: ["3b54a9ef-6200-4e9d-ad30-0b067ecbe790"], // dev-4,
    creator_locations: ["cb8c4bd2-7661-4761-971a-c27322e2f209"], // prod,
    // creator_locations: ["3b54a9ef-6200-4e9d-ad30-0b067ecbe790"], // dev-3
  };
  return search_query;
};

export const PURCHASE_INTENT_TOOLTIP_TEXT = "Percentage of audience who intend to buy the product/service advertised by the creator. Powered by AI";
export const STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};
export const CONTACT_US = "Contact us";
export const ANY_CONTACT_TYPE = "Any contact option";
export const CONTACT_TYPE_FILTER_KEY = "specific_contact_details";
export const ANY_CONTACT_OPTION_VALUE = "ANY";
export const HAS_CONTACT_DETAILS_FILTER_KEY = "has_contact_details";
export const CONTACT_TYPE_ICONS_MAPPING = {
  email: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/email_icon.png",
    label: "Email",
  },
  phone: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/phone.png",
    label: "Phone",
  },
  facebook: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/facebook.png",
    label: "Facebook",
  },
  instagram: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/instagram.png",
    label: "Instagram",
  },
  itunes: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/applemusicaccount.png",
    label: "Apple Music Account",
  },
  kakao: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/kakao.png",
    label: "KaKao",
  },
  kik: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/kik.png",
    label: "Kik",
  },
  lineid: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/line.png",
    label: "Line",
  },
  linktree: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/linktree.png",
    label: "Linktree",
  },
  pinterest: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/pinterest.png",
    label: "Pinterest",
  },
  skype: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/skype.png",
    label: "Skype",
  },
  snapchat: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/snapchat.png",
    label: "Snapchat",
  },
  telegram: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/telegram.png",
    label: "Telegram",
  },
  tiktok: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/tiktok.png",
    label: "TikTok",
  },
  tumblr: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/tumblr.png",
    label: "Tumblr",
  },
  twitchtv: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/twitch.png",
    label: "Twitch",
  },
  twitter: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/x.com.png",
    label: "X.com",
  },
  viber: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/viber.png",
    label: "Viber",
  },
  vk: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/vk.png",
    label: "VK",
  },
  wechat: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/wechat.png",
    label: "WeChat",
  },
  weibo: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/weibo.png",
    label: "Weibo",
  },
  whatsapp: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/whatsapp.png",
    label: "WhatsApp",
  },
  youtube: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/youtube.png",
    label: "YouTube",
  },
  bbm: {
    logo: "https://cdn.insightiq.ai/platforms_logo/logo_contact_filters/bbm.png",
    label: "BBM",
  },
};
export const DEFAULT_SEARCH = "default";
export const FILTER_BASED_SEARCH = "filter_search";
export const CREATOR_BRAND_AFFINITIES = "creator_brand_affinities";
export const CREATOR_INTERESTS = "creator_interests";
export const AUDIENCE_BRAND_AFFINITIES = "audience_brand_affinities";
export const AUDIENCE_INTERESTS = "audience_interest_affinities";
export const AUDIENCE_LOOKALIKES = "audience_lookalikes";
export const CREATOR_LOCATIONS = "creator_locations";
export const AUDIENCE_LOCATIONS = "audience_locations";
export const TOPIC_RELEVANCE_FILTER_SORTING_OPTION = "Topic relevance";
export const TOPIC_RELEVANCE_FILTER_DISPLAY_NAME = "Topics";
export const FILTER_KEY_MAPPING = {
  creator_brand_affinities: "Creator Brands",
  audience_brand_affinities: "Audience Brands",
  creator_interests: "Creator Interests",
  audience_interest_affinities: "Audience Interests",
  creator_lookalikes: "Creator Lookalikes",
  audience_lookalikes: "Audience Lookalikes",
  creator_locations: "Creator Locations",
  audience_locations: "Audience Locations",
};

export const SEARCH_LIST = "Search your lists";
export const SEARCH_CREATORS = "Search creators";

export function getUniqueElements(array1, array2) {
  // Create Sets for each array
  const set1 = new Set(array1);
  const set2 = new Set(array2);

  // Find elements unique to each array
  const uniqueToSet1 = [...new Set([...set1].filter((x) => !set2.has(x)))];
  const uniqueToSet2 = [...new Set([...set2].filter((x) => !set1.has(x)))];

  // Combine the unique elements from both arrays
  const resultArray = uniqueToSet1.concat(uniqueToSet2);

  return resultArray;
}

export const ADD_TO_LIST_TOOLTIP_TEXT = "Add to list";
export const LISTS_TOOLTIP_TEXT = (
  <div className="list-tooltip-container">
    <div className="list-tooltip-header">📄 Save your favorite creators in one place</div>
    <div className="list-tooltip-description">Shortlist creators for a campaign, share with your team, or just bookmark them for later.</div>
  </div>
);
export const CREATE_LIST_POPUP_ACTIONS_LABEL = (favoriteListCount) => {
  return [
    {
      label: (
        <span className="list-button-label">
          <div>
            <i class="ri-add-line" />
          </div>
          <div>Create new list</div>
        </span>
      ),
    },
    {
      label: (
        <span className="list-button-label">
          <div>
            <i class="ri-heart-line" />
          </div>
          <div>
            Add to favorites{" "}
            {favoriteListCount !== 0 &&
              `(${favoriteListCount}
            ${favoriteListCount > 1 ? "creators" : favoriteListCount !== 0 && "creator"})`}
          </div>
        </span>
      ),
    },
  ];
};

export const CREATE_LIST_POPUP_HEADER = (isMultipleCreatorSelected, selectedRows) => {
  return `Add ${isMultipleCreatorSelected ? selectedRows.length : ""} ${isMultipleCreatorSelected && selectedRows.length > 1 ? "creators" : "creator"
    } to list`;
};

export const ADD_LIST_TOAST_MESSAGE_DURATION = 2500;
export const favourite = "Favorites";
export const confirmDelete = "Confirm delete";
export const YOUR_CREATOR_LISTS_TITLE = (
  <span className="list-button-label">
    <div>
      <i class="ri-list-check list-button-icon" />
    </div>
    <div>Your creator lists</div>
  </span>
);

export const ADD_CREATOR_TO_LIST_TITLE = (
  <span className="list-button-label">
    <div>
      <i class="ri-play-list-add-line list-button-icon" />
    </div>
    <div>Add to list</div>
  </span>
);

export const handleAddListLabel = (selectedItems, isMultipleCreatorSelected) => {
  return selectedItems.length > 1
    ? `${isMultipleCreatorSelected ? "Update" : `Add to ${selectedItems.length} lists`}`
    : `${isMultipleCreatorSelected ? "Update" : "Add"}`;
};

export const enterMail = "Enter your email";
export const exportListPopupDesc = "We’ll send you this list in an email";

export const createNewListDesc = "Lists you create will be visible in campaigns";
export const createNewListPlaceholder = "Enter list name e.g. Beauty creators";
export const dublicateListError = "List name already exists. Please enter a different name.";
export const maximumCharError = "Please enter a name with maximum 50 characters";

export const removeFromList = "Remove from list";
export const deleteText = "Delete";
export const yesRemove = "Yes, remove";
export const dontRemove = "Don’t remove";
export const listNameText = "List name";
export const TOAST_MESSAGE_DELAY_DURATION = 2500;
export const EXPORT_LIMIT_ON_SEARCH = 500;
export const BULK_EXPORT_LIMIT_ON_SEARCH = 65000;
export const EXPORT_POPUP_HEADER = "Export results as CSV";
export const EXPORT_CREATOR_FOUND = (total_creators) =>
  `${total_creators} ${parseFormattedNumber(total_creators) > 1 ? "creators" : "creator"} found`;
export const EXPORT_CREATOR_SELECTED = (total_creators) => `${total_creators} ${total_creators > 1 ? "creators" : "creator"} selected`;
export const SEND_CSV = "Send CSV";
export const BULK_EXPORT_HEADER = "How many creators do you want to export?";
export const CREATOR_NEEDED = "Number of creators needed";
export const BULK_EXPORT_EMAIL_INPUT_TITLE = "We’ll send you the results in 5-30 minutes, via email";
export const YOUR_MAIL = "Your email";
export const EXPORT_LIMIT_ERROR_MSG = (Limit) => `You can export between 1 to ${Limit} creator profiles at a time`;
export const SEARCH_EXPORT_LIMIT_ERROR_MSG = (Limit) => `This search result only has ${Limit} creators. Please enter a number up to ${Limit}`;

export const EXPORT_SUCCESS_HEADER = "You will receive the results in your inbox, in 5-30 minutes.";
export const EXPORT_SUCCESS_DESC = (email) => {
  return [
    `The CSV link you’ll receive on ${email} will only be valid for 7 days. Please download the file before the link expires.`,
    "Please don’t forget to check the spam folder in case you can’t find it in your inbox.",
  ];
};
export const EXPORT_FAILURE_HEADER = "Sorry, something went wrong on our end";
export const EXPORT_FAILURE_DESC = "Trying again should fix it. If it doesn’t, please email us.";
export const GOT_IT = "Got it";
export const TRY_AGAIN = "Try again";
export const BULK_EXPORT_EXPIRE_INFO =
  "The CSV link sent to your email will only be valid for 7 days. Please download the file before the link expires.";
export const LOADING = "Loading...";
export const EMAIL_CONFIRMATION_TITLE = "I confirm the above email address.";
export const EMAIL_CONFIRMATION_DESC = "Please confirm to ensure that the email is sent to the correct address.";

/* Brand safety */
export const BRAND_SAFETY_BANNER_TITLE_TRIAL = (profileName) => `Upgrade to check ${profileName}'s content safety report`;
export const BRAND_SAFETY_BANNER_REQUEST_DESCRIPTION =
  "The Safety Report reviews creators' content to ensure you select those who won't harm your brand's reputation.";

export const BRAND_SAFETY_BANNER_REQUEST_TITLE  = (profileName) =>  `Check ${profileName}'s content safety report`;

export const BRAND_SAFETY_BANNER_REPORT_GENERATING_TITLE = (profileName) => `Generating safety report for ${profileName}`;
export const BRAND_SAFETY_BANNER_REPORT_GENERATING_DESCRIPTION = (email) =>
  <div>This might take 10-15 mins. Your report will be available here and emailed to you at <span class="brand-safety-banner-email" >{email}</span> when it’s ready.</div>;

export const BRAND_SAFETY_BANNER_TITLE_DOWNLOAD = (profileName) => `Download safety report for ${profileName}`;
export const BRAND_SAFETY_BANNER_DESCRIPTION_DOWNLOAD =
  "This in-depth analysis will help you determine if they align with your brand values and meets your safety standards.";

export const BRAND_SAFETY_UNLOCK_MODAL_TITLE = "Upgrade to create safety report";
export const BRAND_SAFETY_UNLOCK_MODAL_DESCRIPTION =
  "The Safety Report reviews creators' content to ensure you select those who won't harm your brand's reputation.";

export const BRAND_SAFETY_SUCCESS_BUTTON_CTA = "Download safety report";
export const UPGRADE = "Upgrade";
export const BRAND_SAFETY_REQUEST = "Request safety report";

export const NOT_REQUESTED = "NOT_REQUESTED";
export const FAILURE = "FAILURE";
export const GENERATING_TITLE = "Generating link...";


export function parseFormattedNumber(formattedStr) {
  if (typeof formattedStr !== "string") {
    formattedStr = String(formattedStr);
  }

  const suffixes = { K: 1000, M: 1000000, B: 1000000000 };
  const regex = /^(\d+(\.\d+)?)([KMB])?$/i;
  const match = formattedStr.match(regex);

  if (!match) {
    console.error("Invalid format");
  }

  const number = parseFloat(match[1]);
  const suffix = match[3];

  if (!suffix) {
    return number;
  }

  const multiplier = suffixes[suffix.toUpperCase()];
  return number * multiplier;
}

export const CREATORS = "creators";
export const COMPARE_CREATOR_SEARCH_PLACEHOLDER = 'Enter creator’s username or handle';

export const RECENT_COMPARISON = 'Recent comparisons';
export const YES_DELETE = 'Yes, delete';
export const DELETE_COMPARISON = 'Delete comparison?';
export const DELETED_COMPARISON = 'Deleted comparison';
export const GET_COMPARE_CREATORS_DELETE_DESC = (creators) => {
  return `Comparing: ${creators.map(creator => creator.full_name).join(', ')}`;
};
export const SHARE_CREATOR_COMPARISON = "Share creator comparison";
export const SHARED_AUDIENCE_TOOLTIP = "Audience that follows all the compared creators";
export const UNIQUE_AUDIENCE_TOOLTIP = "Audience that follows only one of the creators";
export const CompareCreatorGridColumns = {
  ID: { header: 'ID' },
  CREATORS: { header: 'Creators', field: "creators" },
  UNIQUE_AUDIENCE: {
    header: <> Unique audience
      <Tooltip placement={"right-start"} title={UNIQUE_AUDIENCE_TOOLTIP}>
        <span> <i class="ri-information-line"></i> </span>
      </Tooltip> </>,
    field: "unique_audience"
  },
  SHARED_AUDIENCE: {
    header: <>Shared audience
      <Tooltip placement={"right-start"} title={SHARED_AUDIENCE_TOOLTIP}>
        <span> <i class="ri-information-line"></i> </span>
      </Tooltip> </>,
    field: "shared_audience"
  },
  CREATED_ON: { header: "Created on", field: "created_at" },
  ACTIONS: { header: "Actions", field: "actions" },
};


export const compareCreatorsTabValues = {
  genderDistribution: "Gender distribution",
  followerTypes: "Follower types",
  followerGrowth: "Followers growth",
  likesGrowth: "Likes growth",
  subscribersGrowth: "Subscribers growth",
};

export const AUDIENCE = "Audience";
export const FOLLOWERS = "Followers";
export const SUBSCRIBERS = "Subscribers";
export const ABOUT = "About";
export const PERFORMANCE = "Performance";
export const GROWTH_AND_INTEREST = "Growth & interests";
export const AUDIENCE_OVERLAP = "Audience overlap";
export const SIMILAR_CREATORS = "Similar creators";
export const COMMENT_ANALYSIS = "Comment analysis";
export const totalFollowerChartDesc = "Monthly trend of total followers";
export const totalSubscriberChartDesc = "Monthly trend of total subscriber";
export const averageLikesChartDesc = "Monthly trend of average likes per post";
export const SHARED_FOLLOWERS = "Shared followers";
export function SHARED_FOLLOWERS_DESC(creatorCount) { return `Audience that follows ${creatorCount === 2 ? "the both" : "all three"}  creators`; }
export const UNIQUE_FOLLOWERS = "Unique followers";
export const UNIQUE_FOLLOWERS_DESC = "Audience that follows only one of these creators";
export const AI_POWERED = "AI powered";
export const COMPARE_CREATOR_REPORT_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};
export const COMPARE_CREATOR_ERROR_STATE = {
  IN_PROGRESS: {
    title: "We’re updating creators’ details...",
    desc: "This may take up to 20 mins. You can visit this comparison later from the ‘Compare creators’ section, and we should have the info ready for you."
  },
  PENDING: {
    title: "We’re updating creators’ details...",
    desc: "This may take up to 20 mins. You can visit this comparison later from the ‘Compare creators’ section, and we should have the info ready for you."
  },
  FAILURE: {
    title: "Couldn’t load one or more profiles",
    desc: "Sorry, something went wrong on our end. Please try again after a while."
  },
};

export const COMPARE_CREATOR_POST_ERROR_HEADER = 'One or more of these profiles is unavailable at the moment';
export const COMPARE_CREATOR_POST_ERROR_DESC = <ul className="compare-creator-error-msg" >
  <li className="compare-creator-error-msg-point" >The creator may have asked to remove their info OR</li>
  <li className="compare-creator-error-msg-point" >The profile is hidden or private.</li>
</ul>;

export const COMPARE_CREATOR_POST_ERROR_MODAL = {
  title: COMPARE_CREATOR_POST_ERROR_HEADER,
  desc: COMPARE_CREATOR_POST_ERROR_DESC,
};

export const NO_COMPARISON_FOUND = "We couldn't find any comparison.";
export function CONVERT_TO_PERCENTAGE(value) {
  if (value === 0) return '0%';
  return value ? `${(value * 100).toFixed(2)}%` : "-";
}

export const COMPARE_CREATOR_LIMIT_REACHED_MODAL = {
  title: "Upgrade to create more reports",
  desc: <div>
    <p className="body-r compare-creator-limit-reached-desc">
      {`You have reached the limit of comparison reports available in this billing cycle. If you have any questions, please contact` + " "}
      <a href="mailto:support@insightiq.ai">
        <u>support@insightiq.ai</u>
      </a>{" "}
    </p>
  </div>,
  buttonCTA: "Upgrade"
};

export const COMPARE_CREATOR_TRIAL_MODE_MODAL = {
  title: "Find the best creator combos for your audience",
  desc: "Find the overlap percentage between creator audiences, and compare them stat by stat.",
  buttonCTA: "Check a sample",
  additionalText: <div>or <u className="trial-upgrade-button" onClick={() => window.open(MEETING_SCHEDULE_LINK, "_blank")} >upgrade</u> to compare your choice of creators</div>
};

export const COMPARE_CREATOR_UPGRADE_MODAL = {
  LIMIT_REACHED: {
    title: "Upgrade to compare creators",
    desc: <div>
      <p className="body-r compare-creator-limit-reached-desc">
        {`You have reached the limit of comparison reports available in this billing cycle. If you have any questions, please contact ` + " "}
        <a href="mailto:support@insightiq.ai">
          <u>support@insightiq.ai</u>
        </a>{" "}
      </p>
    </div>,
    buttonCTA: "Upgrade",
    height: "283px"
  },
  TRIAL_MODE: {
    title: "Upgrade to compare creators",
    desc: "Find the overlap percentage between creator audiences, and compare them stat by stat.",
    buttonCTA: "Upgrade",
    height: "259px"
  }
};

export const AUDIENCE_GENDER_DISTRIBUTION = "Audience gender distribution";
export const ERROR_FETCHING_RESULTS = "Error fetching results";
export const ERROR_RESULTS_FOUND = "No results found";
export const START_TYPING = "Start typing...";
export const RECORD_NOT_FOUND = "RECORD_NOT_FOUND";
export function ERROR_RESULTS_FOUND_DESC(platformName) { return `Please ensure the username or URL is correct and belongs to ${platformName} `; }

export const SAMPLE_REPORT = "Sample report: ";
export function CREATED_ON(date, format) { return `Created on ${moment.utc(date).format(format)}`; }

export const extractIdFromErrorMessage = (msg) => {
  const regex = /`([a-f0-9-]+)`/;
  const match = msg.match(regex);
  return match ? match[1] : null;
};

export const GENERATING_LINK = "Generating link...";
export const COPY_LINK = "Copy link";
export const CREATORS_COMPARED = "CREATORS_COMPARED";
export const LIMIT_REACHED = "LIMIT_REACHED";
export const TRIAL_MODE = "TRIAL_MODE";
export const CHECK_SAMPLE_REPORT = "check a sample report";

export const unlockPopupDetails = {
  addCreatorToList: {
    header: "Upgrade to save all your favourite creators in one place",
    description: "Create lists to shortlist creators for a campaign, share with your team, or just bookmark for later.",
  },
  fetchCreator: {
    header: "Upgrade to analyze more creators",
    description: "Get detailed insights on each creator, and share them with your team or clients."
  }
};
export const INVALID_REQUEST_PARAMETERS = "INVALID_REQUEST_PARAMETERS";
export const DELETE_TOOLTIP_TEXT = "Delete"