import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.variable.min.css";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "remixicon/fonts/remixicon.css";

// ConfigProvider.config({
//   // theme: {
//   //   errorColor: "#AF0606",
//   // },
// });

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_API_KEY,
        integrations: [
            new BrowserTracing(),
            Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5,

        // Session Replay
        // This sets the sample rate at 10%.
        // You may want to change it to 100% while in development
        // and then sample at a lower rate in production.
        replaysSessionSampleRate: 0.1,

        // If you're not already sampling the entire session,
        // change the sample rate to 100% when sampling sessions where errors occur.
        replaysOnErrorSampleRate: 1.0,
    });
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <ConfigProvider prefixCls="custom">
    // <React.StrictMode>
    <BrowserRouter>
        <App/>
    </BrowserRouter>
    // </React.StrictMode>
    // </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
