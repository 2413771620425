import React from "react";
import { DEFAULT_CREATOR_DISCOVERY_WAREHOUSE_STATE } from "../reducers/creatorDiscoveryWarehouseReducer";

const CreatorDiscoveryWarehouseContext = React.createContext({
  creatorDiscoveryWarehouse: DEFAULT_CREATOR_DISCOVERY_WAREHOUSE_STATE,
  dispatchCreatorDiscoveryWarehouse: () => {},
});

export const CreatorDiscoveryWarehouseContextProvider = CreatorDiscoveryWarehouseContext.Provider;
export default CreatorDiscoveryWarehouseContext;
