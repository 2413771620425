import {Button} from "antd";
import React, {useState} from "react";
import './CreatorDatePicker.scss';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker as MuiDatePicker} from "@mui/x-date-pickers/DatePicker";
import {APPLY, DATE_FORMAT, FROM_DATE, RESET, TO_DATE} from "../../constants/constants";
import moment from 'moment-timezone';
import {ApplyButton, ResetButton} from "../StyledComponents/Buttons";

export default function CreatorDatePicker(props) {
    const {dateFilterConfig: {dateRange, setDateRange, setDatePickerVisible}} = props;
    const [fromDateLocal, setFromDateLocal] = useState(dateRange?.fromDate);
    const [toDateLocal, setToDateLocal] = useState(dateRange?.toDate);

    function handleDateFilterConfirm() {
        setDateRange({
            fromDate: fromDateLocal,
            toDate: toDateLocal,
        });
        setDatePickerVisible(false);
    }

    function handleDateFilterReset() {
        setFromDateLocal(null);
        setToDateLocal(null);
        setDateRange({
            fromDate: null,
            toDate: null,
        });
        setDatePickerVisible(false);
    }

    function renderDatePickerInput(params) {
        return <TextField
            {...params}
            className={'creator-date-picker-text'}
            size={'small'}
            onKeyDown={e => e.preventDefault()}
        />;
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={'creator-date-picker-component'}>
                    <div className={'creator-date-picker-items'}>
                        <MuiDatePicker
                            className={'creator-date-picker'}
                            inputFormat={DATE_FORMAT}
                            label={FROM_DATE}
                            value={fromDateLocal}
                            onChange={setFromDateLocal}
                            maxDate={moment()}
                            renderInput={renderDatePickerInput}
                            componentsProps={{
                                actionBar: {
                                    actions: ["clear"]
                                }
                            }}
                        />
                        <MuiDatePicker
                            className={'creator-date-picker'}
                            inputFormat={DATE_FORMAT}
                            label={TO_DATE}
                            value={toDateLocal}
                            onChange={setToDateLocal}
                            maxDate={moment()}
                            renderInput={renderDatePickerInput}
                            componentsProps={{
                                actionBar: {
                                    actions: ["clear"]
                                }
                            }}
                        />
                    </div>
                    <div className={'creator-date-picker-items'}>
                        <ApplyButton type="primary" onClick={handleDateFilterConfirm}>
                            {APPLY}
                        </ApplyButton>
                        <ResetButton onClick={handleDateFilterReset}>{RESET}</ResetButton>
                    </div>
                </div>
            </LocalizationProvider>


        </>
    )
}