import { height } from "@mui/system";
import { Form, Input, InputNumber } from "antd";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { EMPTY_REQUIRED_FIELD_MESSAGE, INVALID_EMAIL_MESSAGE } from "../../constants/constants";
import InputField from "../InputField/InputField";
import Typography from "../Typography/Typography";
import InvertedPrimaryButton from "../InvertedPrimaryButton/InvertedPrimaryButton";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import useFormSubmitDisabled from "../../hooks/useFormSubmitDisable";
import { useToast } from "../../hooks/useToast";
import "./IndividualUpload.scss";
import { sendIndividualInvite } from "../../api/creatorinvitations/creatorinvitations";
import { WarehouseContext } from "../../storage/context";
import { Box } from "@mui/material";
import { LINK_SENT_FAILED, LINK_SENT_SUCCESS, trackEvent } from "../../analytics";

const IndividualUpload = () => {
  const [formInstance] = Form.useForm();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { warehouse } = useContext(WarehouseContext);
  const requiredFormFields = ["name", "email"];
  const disableSubmit = useFormSubmitDisabled(formInstance, requiredFormFields);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    errorMessage: "",
  });

  const onInviteSubmit = async () => {
    setLoading(true);
    const { name, email } = formInstance.getFieldsValue();
    const response = await sendIndividualInvite(name, email, warehouse.environment.current);
    if (response.status === 201) {
      toast("Invitation sent!");
      formInstance.resetFields();
      setLoading(false);
      trackEvent(LINK_SENT_SUCCESS, {
        type: "individual",
      });
    } else if (response.status === 200) {
      setLoading(false);
      setErrors({
        name: true,
        email: true,
        errorMessage: "This email already exists, try using another email.",
      });
      trackEvent(LINK_SENT_FAILED, {
        message: "This email already exists, try using another email.",
      });
    }
  };

  const emptyInputRule = [
    {
      validator: (_, value) => {
        if (value != undefined) {
          if (value.trim() === "") {
            return Promise.reject(EMPTY_REQUIRED_FIELD_MESSAGE);
          }
          Promise.resolve();
        }
      },
    },
  ];
  const resetError = (name) => {
    setErrors({ ...errors, [name]: false });
    setErrors({ errorMessage: "" });
  };
  return (
    <div>
      <div className="invite-form">
        <Form form={formInstance}>
          <Form.Item rules={emptyInputRule} name="name">
            <InputField
              placeholder="Peter Parker"
              inputLabel="Name"
              showRequiredIcon
              maxLength={100}
              hasError={errors.name}
              style={{
                width: "100%",
                borderRadius: "4px",
                borderColor: errors.name && "#af0606",
              }}
              // autoFocus
              onChange={() => {
                resetError("name");
                resetError("email");
              }}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: EMPTY_REQUIRED_FIELD_MESSAGE },
              {
                type: "email",
                message: INVALID_EMAIL_MESSAGE,
              },
            ]}
          >
            <InputField
              placeholder="spidey.clicks@shield.com"
              inputLabel="Email"
              showRequiredIcon
              hasError={errors.email}
              maxLength={78}
              style={{
                width: "100%",
                borderRadius: "4px",
                borderColor: errors.email && "#af0606",
              }}
              onChange={() => {
                resetError("email");
                resetError("name");
              }}
            />
          </Form.Item>
          <Box className="send-invite-footer">
            <Box sx={{ width: "67.5%" }}>
              <p className="error-message-text">{errors.errorMessage}</p>
            </Box>
            <Box>
              <Form.Item shouldUpdate>
                {() => (
                  <PrimaryButton
                    disabled={disableSubmit() || formInstance.getFieldValue("is_active")}
                    onClick={onInviteSubmit}
                    htmlType="Submit"
                    label="Send invite"
                    className=""
                    loading={loading}
                  />
                )}
              </Form.Item>
            </Box>
          </Box>
        </Form>
      </div>
    </div>
  );
};

export default IndividualUpload;
