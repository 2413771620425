import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import './ContextualMenu.scss';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { isEmpty } from "../../utils/util";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Colors } from "../../styles/colors";

/**
 *
 * @param onClickOverride Called when the menu button is clicked. Should return true if menu items should not be shown
 * @param firstItem
 * @param menuItems
 * @param buttonLabel
 * @param variant
 * @returns {JSX.Element}
 * @constructor
 */
export default function ContextualMenu({
                                           onClickOverride = () => false,
                                           firstItem,
                                           menuItems,
                                           buttonLabel = null,
                                           variant = 'no-button',
                                       }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    let button;
    let anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    };
    const transformOrigin = {
        vertical: 'top',
        horizontal: 'right',
    };

    switch (variant) {
        case 'button':
            button = (
                <PrimaryButton
                    label={buttonLabel}
                    className="secondary-button"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    aria-controls="dropdown-menu"
                    disableRipple
                />
            );
            anchorOrigin = {
                vertical: 'bottom',
                horizontal: 'right',
            };
            break;
        case 'text-button':
            button = (
                <Button className={'text-button'} variant={'text'} onClick={handleMenuClick}>
                    <i className="ri-share-forward-line header-icon"></i>
                    {buttonLabel}
                    <KeyboardArrowDownIcon />
                </Button>
            );
            anchorOrigin = {
                vertical: 'bottom',
                horizontal: 'right',
            };
            break;
        case 'text-button-with-border':
            button = (
                <Button className={'text-button-with-border'} variant={'text'} onClick={handleMenuClick}>
                    {buttonLabel}
                    <KeyboardArrowDownIcon />
                </Button>
            );
            anchorOrigin = {
                vertical: 'bottom',
                horizontal: 'right',
            };
            break;
        case 'no-button':
        default:
            button = (
                <Button
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    className={'dropdown-button'}
                    disableRipple
                >
                    <i className="ri-more-2-line dropdown-button-icon"></i>
                </Button>
            );
            break;
    }

    function handleMenuClick(event) {
        if (onClickOverride && !onClickOverride()) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleMenuItemClick(item) {
        handleMenuClose();
        if (item.onClick) {
            item.onClick();
        }
    }

    return (
        <div>
            {button}
            <Menu
                className="dropdown-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                keepMounted
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                {firstItem && <MenuItem className={'first-item'} disabled><span
                    className="subtitle-r">{firstItem}</span></MenuItem>}
                {!isEmpty(menuItems) ? menuItems.map((item, index) => {
                    const { text, icon, disabled: itemDisabled } = item;
                    if (isEmpty(text)) {
                        return null;
                    }

                    return <MenuItem key={index}
                                     disabled={Boolean(itemDisabled)}
                                     onClick={() => handleMenuItemClick(item)}
                                     style={index !== menuItems.length - 1 ? { borderBottom: '1px solid var(--neutrals-border-grey)' } : {}}
                    >
                        <div className="body-m menu-item-content">
                            {icon}
                            {text}
                        </div>
                    </MenuItem>;
                }) : null}
            </Menu>
        </div>
    );
}
