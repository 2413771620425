import React from "react";
import { Link } from "react-router-dom";
import "./TextLink.scss";

// Reason for this component is to provide custom style for the text link
export default function TextLink({ className = "", children, to, ...props }) {
  return (
    <Link className={`text-link ${className}`} to={to} {...props}>
      {children}
    </Link>
  );
}
