import { useMemo } from "react";

/* Disabled button depends on the state of form input, Since we use antd form to handle this, we have to use custom rendering */

const useFormSubmitDisabled = (formInstance, requiredFields = []) => {
  const disableFormSubmit = useMemo(
    () =>
      (skipTouchCheck = false) => {
        const errorValidateResult = formInstance.getFieldsError().filter(({ errors }) => errors.length).length > 0;
        if (skipTouchCheck) {
          return errorValidateResult;
        }
        return !requiredFields.every((field) => formInstance.isFieldTouched(field)) || errorValidateResult;
      },

    [requiredFields, formInstance]
  );
  return disableFormSubmit;
};
export default useFormSubmitDisabled;
