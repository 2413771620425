import platform from "platform";
import { ADD_ANALYTICS } from "../storage/reducers/types";

export const REGISTRATION_PAGE_VIEW = "REGISTRATION_PAGE_VIEW";
export const REGISTRATION_LINK_CLICK = "REGISTRATION_LINK_CLICK";
export const SIGN_IN_LINK_CLICK = "SIGN_IN_LINK_CLICK";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAILURE = "REGISTRATION_FAILURE";
export const VERIFY_EMAIL_PAGE_VIEW = "VERIFY_EMAIL_PAGE_VIEW";

export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";
export const SIGN_IN_PAGE_VIEW = "SIGN_IN_PAGE_VIEW";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const RESET_PASSWORD_LINK_CLICK = "RESET_PASSWORD_LINK_CLICK";
export const RESET_PASSWORD_PAGE_VIEW = "RESET_PASSWORD_PAGE_VIEW";
export const RESET_PASSWORD_EMAIL_SUBMIT_SUCCESS = "RESET_PASSWORD_EMAIL_SUBMIT_SUCCESS";
export const RESET_PASSWORD_EMAIL_SUBMIT_FAILURE = "RESET_PASSWORD_EMAIL_SUBMIT_FAILURE";
export const RESET_PASSWORD_CODE_SUBMIT_SUCCESS = "RESET_PASSWORD_CODE_SUBMIT_SUCCESS";
export const RESET_PASSWORD_CODE_SUBMIT_FAILURE = "RESET_PASSWORD_CODE_SUBMIT_FAILURE";
export const RESET_PASSWORD_NEW_PASSWORD_SUBMIT_SUCCESS = "RESET_PASSWORD_NEW_PASSWORD_SUBMIT_SUCCESS";
export const RESET_PASSWORD_NEW_PASSWORD_SUBMIT_FAILURE = "RESET_PASSWORD_NEW_PASSWORD_SUBMIT_FAILURE";

export const CREDENTIALS_PAGE_VIEW = "CREDENTIALS_PAGE_VIEW";
export const CREDENTIALS_GENERATE_SUCCESS = "CREDENTIALS_GENERATE_SUCCESS";
export const CREDENTIALS_GENERATE_FAILURE = "CREDENTIALS_GENERATE_FAILURE";
export const CREDENTIAL_COPY = "CREDENTIAL_COPY";
export const SECRET_ROTATION_START = "SECRET_ROTATION_START";
export const SECRET_ROTATION_PASSWORD_SUBMIT = "SECRET_ROTATION_PASSWORD_SUBMIT";
export const SECRET_ROTATION_PASSWORD_CANCEL = "SECRET_ROTATION_PASSWORD_CANCEL";
export const SECRET_ROTATION_PASSWORD_SUCCESS = "SECRET_ROTATION_PASSWORD_SUCCESS";
export const SECRET_ROTATION_PASSWORD_FAILURE = "SECRET_ROTATION_PASSWORD_FAILURE";
export const SECRET_ROTATION_NEW_SECRET_GENERATE_SUCCESS = "SECRET_ROTATION_NEW_SECRET_GENERATE_SUCCESS";
export const SECRET_ROTATION_NEW_SECRET_GENERATE_FAILURE = "SECRET_ROTATION_NEW_SECRET_GENERATE_FAILURE";
export const OLD_SECRET_DELETE_SUCCESS = "OLD_SECRET_DELETE_SUCCESS";
export const OLD_SECRET_DELETE_FAILURE = "OLD_SECRET_DELETE_FAILURE";

export const WEBHOOK_LIST_PAGE_VIEW = "WEBHOOK_LIST_PAGE_VIEW";
export const WEBHOOK_CREATE_PAGE_VIEW = "WEBHOOK_CREATE_PAGE_VIEW";
export const WEBHOOK_CREATE_SUCCESS = "WEBHOOK_CREATE_SUCCESS";
export const WEBHOOK_CREATE_FAILURE = "WEBHOOK_CREATE_FAILURE";
export const WEBHOOK_DELETE_CANCEL = "WEBHOOK_DELETE_CANCEL";
export const WEBHOOK_DELETE_SUCCESS = "WEBHOOK_DELETE_SUCCESS";
export const WEBHOOK_DELETE_FAILURE = "WEBHOOK_DELETE_FAILURE";
export const WEBHOOK_EDIT_PAGE_VIEW = "WEBHOOK_EDIT_PAGE_VIEW";
export const WEBHOOK_EDIT_CANCEL = "WEBHOOK_EDIT_CANCEL";
export const WEBHOOK_EDIT_SUCCESS = "WEBHOOK_EDIT_SUCCESS";
export const WEBHOOK_EDIT_FAILURE = "WEBHOOK_EDIT_FAILURE";
export const WEBHOOK_SEARCH = "WEBHOOK_SEARCH";
export const WEBHOOK_LEARN_CLICKED = "WEBHOOK_LEARN_CLICKED";

export const SIDEBAR_GO_LIVE_CLICK = "SIDEBAR_GO_LIVE_CLICK";

export const HELP_PAGE_VIEW = "HELP_PAGE_VIEW";
export const HELP_GO_LIVE_CLICK = "HELP_GO_LIVE_CLICK";
export const HELP_DISCORD_CLICK = "HELP_DISCORD_CLICK";
export const HELP_LINK_CLICK = "HELP_LINK_CLICK";
export const CONTEXT_HELP_CARD_CLICK = "CONTEXT_HELP_CARD_CLICK";
export const SCHEDULE_A_CALL_CLICK = "SCHEDULE_A_CALL_CLICK";
export const DISCORD_CLICK = "DISCORD_CLICK";
export const API_DOCS_CLICK = "API_DOCS_CLICK";
export const LOGOUT_CLICK = "LOGOUT_CLICK";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const FOOTER_LINK_CLICK = "FOOTER_LINK_CLICK";
export const EMAIL_SUPPORT_LINK_CLICKED = "EMAIL_SUPPORT_LINK_CLICKED";
export const NON_WORK_EMAIL_ADDED = "NON_WORK_EMAIL_ADDED";
export const USAGE_VIEW_CLICKED = 'USAGE_VIEW_CLICKED';

export const VERIFY_EMAIL_RESEND_CLICK = "VERIFY_EMAIL_RESEND_CLICK";
export const VERIFY_EMAIL_RESEND_SUCCESS = "VERIFY_EMAIL_RESEND_SUCCESS";
export const VERIFY_EMAIL_RESEND_FAILURE = "VERIFY_EMAIL_RESEND_FAILURE";
export const RESET_PASSWORD_EMAIL_RESEND_CLICK = "RESET_PASSWORD_EMAIL_RESEND_CLICK";
export const RESET_PASSWORD_EMAIL_RESEND_SUCCESS = "RESET_PASSWORD_EMAIL_RESEND_SUCCESS";
export const RESET_PASSWORD_EMAIL_RESEND_FAILURE = "RESET_PASSWORD_EMAIL_RESEND_FAILURE";

export const GETTING_STARTED_PAGE_VIEW = "GETTING_STARTED_PAGE_VIEW";
export const GETTING_STARTED_STEP_1_CLICK = "GETTING_STARTED_STEP_1_CLICK";
export const GETTING_STARTED_STEP_2_CLICK = "GETTING_STARTED_STEP_2_CLICK";
export const GETTING_STARTED_ACCOUNT_CONNECT_SUCCESS = "GETTING_STARTED_ACCOUNT_CONNECT_SUCCESS";
export const GETTING_STARTED_STEP_3_DATA_LOAD = "GETTING_STARTED_STEP_3_DATA_LOAD";
export const GETTING_STARTED_VIEW_API_CREDENTIALS_CLICK = "GETTING_STARTED_VIEW_API_CREDENTIALS_CLICK";
export const GETTING_STARTED_DOCUMENTATION_CLICK = "GETTING_STARTED_DOCUMENTATION_CLICK";
export const ERROR_TIMEOUT_API_ACTIVATION_DELAY = "ERROR_TIMEOUT_API_ACTIVATION_DELAY";

export const ENVIRONMENT_CLICKED = "ENVIRONMENT_CLICKED";
export const MAINMENU_CLICKED = "MAINMENU_CLICKED";
export const COMPLIANCE_FORM_VIEW = "COMPLIANCE_FORM_VIEW";
export const COMPLIANCE_FORM_SUBMIT = "COMPLIANCE_FORM_SUBMIT";

// Creator Module
export const LINK_LANDING_PAGE_VIEW = "LINK_LANDING_PAGE_VIEW";
export const LINK_CREATE_INITIATED = "LINK_CREATE_INITIATED";
export const LINK_SENT_SUCCESS = "LINK_SENT_SUCCESS";
export const LINK_SENT_FAILED = "LINK_SENT_FAILED";
export const LINK_UPLOAD_CONTACTS = "LINK_UPLOAD_CONTACTS";
export const LINK_UPLOAD_CONTACTS_FAILED = "LINK_UPLOAD_CONTACTS_FAILED";
export const LINK_ALL_PAGE_VIEW = "LINK_ALL_PAGE_VIEW";
export const LINK_CONNECTED_ACCOUNT_PAGE_VIEW = "LINK_CONNECTED_ACCOUNT_PAGE_VIEW";
export const LINK_FAILED_ACCOUNT_PAGE_VIEW = "LINK_FAILED_ACCOUNT_PAGE_VIEW";
export const COPY_INVITE_LINK = "COPY_INVITE_LINK";
export const RESEND_INVITE_LINK = "RESEND_INVITE_LINK";
export const LINK_SEARCH_INITIATED = "LINK_SEARCH_INITIATED";
export const PROFILE_PAGE_VIEW = "PROFILE_PAGE_VIEW";
export const CONTENT_ITEM_CLICKED = "CONTENT_ITEM_CLICKED";
export const EXPORT_PROFILE_CLICKED = "EXPORT_PROFILE_CLICKED";
export const CONTENT_THUMBNAIL_EXPIRED = "CONTENT_THUMBNAIL_EXPIRED";

// Creator Discovery Module Events
export const SEARCH_OTHERS_PROFILES_CLICKED = "SEARCH_OTHERS_PROFILES_CLICKED";
export const SEARCH_PROFILE_DOWNLOADED = "SEARCH_PROFILE_DOWNLOADED";
export const SEARCH_CONTENT_VIEWED = "SEARCH_CONTENT_VIEWED";
export const SEARCH_PROFILE_SEARCHED = "SEARCH_PROFILE_SEARCHED";
export const SEARCH_PROFILE_VIEWED = "SEARCH_PROFILE_VIEWED";
export const SEARCH_PLATFORM_PROFILE_VIEWED = "SEARCH_PLATFORM_PROFILE_VIEWED";
export const SEARCH_PROFILE_SEARCH_SELECTED = "SEARCH_PROFILE_SEARCH_SELECTED";
export const SEARCH_FILTERS_SEARCH_SELECTED = "SEARCH_FILTERS_SEARCH_SELECTED";
export const SEARCH_MORE_RESULTS_LOADED = "SEARCH_MORE_RESULTS_LOADED";
export const SEARCH_CONTACT_INFORMATION_CLICKED = "SEARCH_CONTACT_INFORMATION_CLICKED";
export const SEARCH_PAGE_OPENED = "SEARCH_PAGE_OPENED";
export const SEARCH_PLATFORM_CHANGED = "SEARCH_PLATFORM_CHANGED";
export const SEARCH_FILTER_ADDED = "SEARCH_FILTER_ADDED";
export const SEARCH_FILTER_REMOVED = "SEARCH_FILTER_REMOVED";
export const SEARCH_FILTER_UPDATED = "SEARCH_FILTER_UPDATED";
export const SEARCH_MORE_FILTERS_CLICKED = "SEARCH_MORE_FILTERS_CLICKED";
export const SEARCH_RESULTS_VIEWED = "SEARCH_RESULTS_VIEWED";
export const SEARCH_RESULTS_EXPORTED = "SEARCH_RESULTS_EXPORTED";
export const SEARCH_PROFILE_LINK_COPIED = "SEARCH_PROFILE_LINK_COPIED";
export const BRAND_SEARCHED = "BRAND_SEARCHED";
export const CREATOR_INTEREST_SEARCHED = "CREATOR_INTEREST_SEARCHED";
export const AUDIENCE_INTEREST_SEARCHED = "AUDIENCE_INTEREST_SEARCHED";
export const FILTER_SEARCHED = "FILTER_SEARCHED";
export const CHECK_PURCHASE_INTENT_CLICKED = "CHECK_PURCHASE_INTENT_CLICKED";
export const COMMENT_ANALYSIS_CLICKED = "COMMENT_ANALYSIS_CLICKED";
export const PURCHASE_INTENT_BANNER_CLICKED = "PURCHASE_INTENT_BANNER_CLICKED";
export const YOUR_LIST_CLICKED = "YOUR_LIST_CLICKED";
export const CREATOR_ADDED_TO_LIST = "CREATOR_ADDED_TO_LIST";
export const NEW_LIST_CREATED = "NEW_LIST_CREATED";
export const LIST_VIEWED = "LIST_VIEWED";
export const LIST_EXPORTED = "LIST_EXPORTED";
export const LIST_DELETED = "LIST_DELETED";
export const CREATOR_EXPORTED = "CREATOR_EXPORTED";
export const CREATOR_DELETED = "CREATOR_DELETED";
export const LIST_SEARCHED = "LIST_SEARCHED";
export const CREATOR_IN_LIST_SEARCHED = "CREATOR_IN_LIST_SEARCHED";

// Campaign Trackign events
export const TRACKING_PAGE_OPENED = "TRACKING_PAGE_OPENED";
export const TRACKING_CAMPAIGN_CREATED = "TRACKING_CAMPAIGN_CREATED";
export const TRACKING_CAMPAIGN_NEXT_CLICKED = "TRACKING_CAMPAIGN_NEXT_CLICKED";
export const TRACKING_URL_CHECK_EXAMPLE = "TRACKING_URL_CHECK_EXAMPLE";

// CT - Single Content
export const TRACKING_URL_ANALYSIS_CLICKED = "TRACKING_URL_ANALYSIS_CLICKED";
export const TRACKING_URL_ANALYSIS_SUCCESS = "TRACKING_URL_ANALYSIS_SUCCESS";
export const TRACKING_URL_ANALYSIS_ERROR = "TRACKING_URL_ANALYSIS_ERROR";
export const TRACKING_URL_ADD_TO_CAMPAIGN_CLICKED = "TRACKING_URL_ADD_TO_CAMPAIGN_CLICKED";
export const TRACKING_URL_SAVED_TO_CAMPAIGNS_CLICKED = "TRACKING_URL_SAVED_TO_CAMPAIGNS_CLICKED";
export const TRACKING_URL_CREATE_A_CAMPAIGN_CLICKED = "TRACKING_URL_CREATE_A_CAMPAIGN_CLICKED";

// CT - Campaign List
export const TRACKING_CAMPAIGNLIST_SEARCHED = "TRACKING_CAMPAIGNLIST_SEARCHED";
export const TRACKING_CAMPAIGNLIST_ADD_POST_CLICKED = "TRACKING_CAMPAIGNLIST_ADD_POST_CLICKED";
export const TRACKING_CAMPAIGNLIST_NEW_CAMPAIGN_CLICKED = "TRACKING_CAMPAIGNLIST_NEW_CAMPAIGN_CLICKED";
export const TRACKING_CAMPAIGNLIST_CLICKED = "TRACKING_CAMPAIGNLIST_CLICKED";

// CT - New Campaign
export const TRACKING_CAMPAIGN_NEW_CAMPAIGN_BACK_CLICKED = "TRACKING_CAMPAIGN_NEW_CAMPAIGN_BACK_CLICKED";

// CT - New Campaign - List Config
export const TRACKING_CAMPAIGN_ADD_CONTENT_CLICKED = "TRACKING_CAMPAIGN_ADD_CONTENT_CLICKED";
export const TRACKING_CAMPAIGN_CONTENT_ADDED = "TRACKING_CAMPAIGN_CONTENT_ADDED";
export const TRACKING_CAMPAIGN_CONTENTCSV_UPLOADED = "TRACKING_CAMPAIGN_CONTENTCSV_UPLOADED";
export const TRACKING_CAMPAIGN_ADD_TO_CAMPAIGN_CLICKED = "TRACKING_CAMPAIGN_ADD_TO_CAMPAIGN_CLICKED";

// Campaign View - Creator Config
export const TRACKING_CAMPAIGNS_SELECT_CREATORS_CLICKED = "TRACKING_CAMPAIGNS_SELECT_CREATORS_CLICKED";
export const TRACKING_CAMPAIGN_ACCOUNTS_SEARCHED = "TRACKING_CAMPAIGN_ACCOUNTS_SEARCHED";
export const TRACKING_CAMPAIGN_ADD_ACCOUNTS_TO_CAMPAIGN_CLICKED = "TRACKING_CAMPAIGN_ADD_ACCOUNTS_TO_CAMPAIGN_CLICKED";
export const TRACKING_CAMPAIGN_MANAGE_CREATORS_CLICKED = "TRACKING_CAMPAIGN_MANAGE_CREATORS_CLICKED";
export const TRACKING_CAMPAIGN_CREATE_CAMPAIGN_CLICKED = "TRACKING_CAMPAIGN_CREATE_CAMPAIGN_CLICKED";

// Campaign View- Reporting
export const TRACKING_CAMPAIGN_PERFORMANCE_CLICKED = "TRACKING_CAMPAIGN_PERFORMANCE_CLICKED";
export const TRACKING_CAMPAIGN_AUDIENCE_REPORT_CLICKED = "TRACKING_CAMPAIGN_AUDIENCE_REPORT_CLICKED";
export const TRACKING_CAMPAIGN_PLATFORM_FILTERED = "TRACKING_CAMPAIGN_PLATFORM_FILTERED";
export const TRACKING_CAMPAIGN_POST_FILTERED = "TRACKING_CAMPAIGN_POST_FILTERED";
export const TRACKING_CAMPAIGN_CONTENT_CLICKED = "TRACKING_CAMPAIGN_CONTENT_CLICKED";
export const TRACKING_CAMPAIGN_PDF_EXPORTED = "TRACKING_CAMPAIGN_PDF_EXPORTED";
export const TRACKING_CAMPAIGN_CSV_EXPORTED = "TRACKING_CAMPAIGN_CSV_EXPORTED";

export const TRACKING_CAMPAIGN_MANAGE_POST_CLICKED = "TRACKING_CAMPAIGN_MANAGE_POST_CLICKED";
export const TRACKING_CAMPAIGN_EDIT_CREATORS_CLICKED = "TRACKING_CAMPAIGN_EDIT_CREATORS_CLICKED";
export const TRACKING_CAMPAIGN_EDIT_LINKS_CLICKED = "TRACKING_CAMPAIGN_EDIT_LINKS_CLICKED";
export const TRACKING_CAMPAIGN_EDIT_CAMPAIGNS_CLICKED = "TRACKING_CAMPAIGN_EDIT_CAMPAIGNS_CLICKED";
export const TRACKING_CAMPAIGN_DELETE_CAMPAIGNS_CLICKED = "TRACKING_CAMPAIGN_DELETE_CAMPAIGNS_CLICKED";
export const TRACKING_CAMPAIGN_NEW_PAGE_CLICKED = "TRACKING_CAMPAIGN_NEW_PAGE_CLICKED";

// Campaign Tracking - Trial Flow
export const TRACKING_UPGRADE_BANNER_CLICKED = "TRACKING_UPGRADE_BANNER_CLICKED";
export const TRACKING_UPGRADE_POPUP_OPENED = "TRACKING_UPGRADE_POPUP_OPENED";
export const TRACKING_UPGRADE_POPUP_CLOSED = "TRACKING_UPGRADE_POPUP_CLOSED";
export const TRACKING_UPGRADE_POPUP_CLICKED = "TRACKING_UPGRADE_POPUP_CLICKED";
export const TRACKING_CAMPAIGN_BACK_CLICKED = "TRACKING_CAMPAIGN_BACK_CLICKED";

// Campaign Tracking - Common Content View
export const TRACKING_CONTENT_BACK_CLICKED = "TRACKING_CONTENT_BACK_CLICKED";
export const TRACKING_CONTENT_VIEW_POST_CLICKED = "TRACKING_CONTENT_VIEW_POST_CLICKED";

// Self sign up events
export const TOP_BANNER_CLICKED = "TOP_BANNER_CLICKED";
export const DEVELOPER_CLICKED = "DEVELOPER_CLICKED";
export const MARKETER_CLICKED = "MARKETER_CLICKED";

// Dev dashboard events
export const APIPRODUCT_CLICKED = "APIPRODUCT_CLICKED";
export const PLAYGROUND_CLICKED = "PLAYGROUND_CLICKED";
export const SEARCH_CLICKED = "SEARCH_CLICKED";
export const PRODUCT_DOCS_CLICKED = "PRODUCT_DOCS_CLICKED";

// Account events
export const ACCOUNT_CLICKED = 'ACCOUNT_CLICKED';
export const RESET_PWD_CLICKED = 'RESET_PWD_CLICKED';
export const RESEND_PWD_CLICKED = 'RESEND_PWD_CLICKED';

export const trackEvent = (eventName, eventObj = {}) => {
  const analytics = JSON.parse(localStorage.getItem("insightiq_analytics"));
  const eventToSend = { ...eventObj, ...analytics };
  window.analytics.track(eventName, eventToSend);
};
export const logUser = (userId, userObj) => {
  window.analytics.identify(userId, userObj);
};
// page
export const changePage = (page, params = {}) => {
  window.analytics.page(page, params);
};
export const getUTMParams = () => {
  const url = new URL(window.location);
  const searchParams = new URLSearchParams(url.search);
  const queryParams = Object.fromEntries(searchParams);
  if (!localStorage.getItem("utm-params-insightiq")) {
    const utmParams = {
      utm_source: queryParams["utm_source"] ?? null,
      utm_medium: queryParams["utm_medium"] ?? null,
      utm_campaign: queryParams["utm_campaign"] ?? null,
      utm_term: queryParams["utm_term"] ?? null,
      utm_content: queryParams["utm_content"] ?? null,
    };
    localStorage.setItem("utm-params-insightiq", JSON.stringify(utmParams));
  } else {
    // This is to ensure that the new UTM params gets updated if newer URL params comes
    if (
      queryParams["utm_source"] ||
      queryParams["utm_medium"] ||
      queryParams["utm_campaign"] ||
      queryParams["utm_term"] ||
      queryParams["utm_content"]
    ) {
      const utmParams = {
        utm_source: queryParams["utm_source"] ?? null,
        utm_medium: queryParams["utm_medium"] ?? null,
        utm_campaign: queryParams["utm_campaign"] ?? null,
        utm_term: queryParams["utm_term"] ?? null,
        utm_content: queryParams["utm_content"] ?? null,
      };
      localStorage.setItem("utm-params-insightiq", JSON.stringify(utmParams));
    }
  }
};

export const updateAnalyics = async (userProfile, dispatchAnalytics, environment) => {
  try {
    const deviceLocale = window.navigator.languages;
    const browserLocale = window.navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
    const { userAgent } = window.navigator;
    const currPath = window.location.host;
    const {
      product,
      manufacturer,
      os: { family: deviceOs },
      os: { version: deviceOsVersion },
    } = platform;
    const { name: browser, version: browserVersion } = platform.parse(userAgent);
    let IPAddress;
    try {
      const res = await fetch("https://geolocation-db.com/json/");
      const response = await res.json();
      IPAddress = response?.IPv4;
    } catch (e) {
      IPAddress = "Not found";
    }
    let utm_params = await localStorage.getItem("utm-params-insightiq");
    utm_params = JSON.parse(utm_params);

    const analyticsPayload = {
      environment: environment,
      device_manufacturer: manufacturer,
      device_name: product,
      device_model: product,
      device_os: deviceOs,
      device_os_version: deviceOsVersion,
      device_locale: deviceLocale,
      device_language: window.navigator.languages,
      device_timezone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
      device_dimensions_width: window.screen.width,
      device_dimensions_height: window.screen.height,
      viewport_width: window.innerWidth,
      viewport_height: window.innerHeight,
      ip_address: IPAddress,
      network_type: navigator?.connection?.effectiveType,
      browser_name: browser,
      browser_version: browserVersion,
      browser_locale: browserLocale,
      browser_user_agent: userAgent,
      tenant_name: userProfile && userProfile?.companyName,
      user_id: userProfile?.email,
      user_email: userProfile?.email,
      registered_from: currPath,
      utm_source: utm_params["utm_source"] ?? null,
      utm_medium: utm_params["utm_medium"] ?? null,
      utm_campaign: utm_params["utm_campaign"] ?? null,
      utm_term: utm_params["utm_term"] ?? null,
      utm_content: utm_params["utm_content"] ?? null,
    };
    dispatchAnalytics({ type: ADD_ANALYTICS, payload: analyticsPayload });
  } catch (e) {
    console.log(e);
  }
};
